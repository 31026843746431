
import './SelectPlantVarieties.scss';
import React from 'react'

import {StandardList, StandardListRow} from '../components/StandardList/StandardList.js'


const SelectPlantVarieties = ({selectedPlantVariety, onPlantVarietySelection}) => {

  const [plantVarieties, SetPlantTypes] = React.useState([
    {id: 1, name: "Crimson Queen", description: "Disgusting horribly delicious fruit"},
    {id: 2, name: "Bitter Blue", description: "Disgusting horribly delicious fruit"},
    {id: 3, name: "Blah blah yuck", description: "The only plant we can grow"}
  ])

  const [plantVarietiesList, SetPlantVaritiesList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedPlantVariety, SetSelectedPlantVariety] = React.useState(undefined)
  React.useEffect(() => {
    if (currentSelectedPlantVariety !== selectedPlantVariety) {
      SetSelectedPlantVariety(selectedPlantVariety)
    }
  }, [selectedPlantVariety])
  

  React.useEffect(() => {
    let currentPlantVarietiesList = {}

    let currentPlantVarieties = []
    

    if (currentSearchInput === "")  {
      currentPlantVarieties = [...plantVarieties]
    }else {
      currentPlantVarieties.push(...plantVarieties.filter((plantVariety) => {
        if (plantVariety.name.indexOf(currentSearchInput) !== -1 && currentPlantVarieties.indexOf(plantVariety) === -1)  {
          return plantVariety
        }
      }))
    }
    
    
    for (let plantVariety of currentPlantVarieties) {
      currentPlantVarietiesList[plantVariety.id] = {
        data: plantVariety,
        columns: {
          icon: {
            render: (<div className="StandardList-Row-Column">
              <div style={{height:20}}></div>
            </div>)
          },
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{plantVariety.name}</div>
              <div className="StandardList-Row-Column-Secondary">{plantVariety.description}</div>
            </div>),
          },
        }
      }
      if (currentSelectedPlantVariety !== undefined && currentSelectedPlantVariety.id === plantVariety.id) {
        currentPlantVarietiesList[plantVariety.id].selected = true
      }
    }


    SetPlantVaritiesList(currentPlantVarietiesList)
  }, [plantVarieties, currentSearchInput, currentSelectedPlantVariety])




  
  const columns = {
    icon: {widthType: "px", width: 20, resizable: false, sort_by: false},
    name: {render: (<div className="StandardList-Header-Column">Name</div>), width: "grow", resizable: true, sort_by: false},
  }

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }


  const rowSelected = (plantVariety) => {
    SetSelectedPlantVariety(plantVariety.data)
    if (onPlantVarietySelection !== undefined) {
      onPlantVarietySelection(plantVariety.data)
    }
  }

  return (
    <>
      <div id="SelectPlantVarieties">
        <StandardList 
          columns={columns} 
          showHeader={false}
          columnSpacing={10}
          rows={plantVarietiesList}
          isSearchable={true} 
          onSearchInput={onSearchInput}
          onRowClicked={rowSelected}/>
      </div>
    </>
  )
} 

export default SelectPlantVarieties