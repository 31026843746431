
import './SelectRecipes.scss';
import React from 'react'

import {StandardList, StandardListRow} from '../components/StandardList/StandardList.js'
import { selectAllRecipes, getAllRecipes } from '../redux/entities/Recipes'
import { useSelector, useDispatch } from 'react-redux'
import { FormatDate } from '../helpers'


const SelectRecipes = ({selectedRecipe, onRecipeSelection}) => {

  const dispatch = useDispatch()
  React.useEffect( () =>  { 
    //fetchTasks
    dispatch(getAllRecipes())
  }, [])
  
  let recipes = useSelector(selectAllRecipes)
  let haveInitialData = useSelector((state) => state.recipes.haveInitialData)

  const [loadingRecipes, SetLoadingRecipes] = React.useState(haveInitialData)
  const [recipesList, SetRecipesList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedRecipe, SetSelectedRecipe] = React.useState(undefined)
  React.useEffect(() => {
    if (currentSelectedRecipe !== selectedRecipe) {
      SetSelectedRecipe(selectedRecipe)
    }
  }, [selectedRecipe])
  

  React.useEffect(() => {
    let currentRecipesList = {}

    let currentRecipes = []
    let availableRecipes = [...recipes.filter((recipe) => recipe.is_active_version)]
    

    if (currentSearchInput === "")  {
      currentRecipes = [...availableRecipes]
    }else {
      currentRecipes.push(...availableRecipes.filter((recipe) => {
        if (recipe.name.indexOf(currentSearchInput) !== -1 && currentRecipes.indexOf(recipe) === -1)  {
          return recipe
        }
      }))
    }
    
    
    for (let recipe of currentRecipes) {
      currentRecipesList[recipe.id] = {
        data: recipe,
        columns: {
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{recipe.name}</div>
              <div className="StandardList-Row-Column-Secondary">v{recipe.version} {FormatDate(new Date(recipe.modified_on), "MM/d/yyyy")}</div>
            </div>),
          },
          creator: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{recipe.created_by}</div>
            </div>),
          },
          sharedWith: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">Noone</div>
            </div>),
          },
        }
      }
      if (currentSelectedRecipe !== undefined && currentSelectedRecipe.id === recipe.id) {
        currentRecipesList[recipe.id].selected = true
      }
    }

    SetRecipesList(currentRecipesList)
  }, [recipes, currentSearchInput, currentSelectedRecipe])




  
  const columns = {
    name: {render: (<div className="StandardList-Header-Column">Name</div>), width: 20, resizable: true, sort_by: false},
    creator: {render: (<div className="StandardList-Header-Column">Creator</div>), width: 20, resizable: true, sort_by: false},
    sharedWith: {render: (<div className="StandardList-Header-Column">Shared With</div>), width: 20, resizable: true, sort_by: false},
  }

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }


  const rowSelected = (recipe) => {
    SetSelectedRecipe(recipe.data)
    if (onRecipeSelection !== undefined) {
      onRecipeSelection(recipe.data)
    }
  }

  return (
    <>
      <div id="SelectRecipes">
        <StandardList 
          columns={columns} 
          showHeader={true}
          columnSpacing={10}
          rows={recipesList}
          isSearchable={true} 
          onSearchInput={onSearchInput}
          onRowClicked={rowSelected}
          loadingData={loadingRecipes}/>
      </div>
    </>
  )
} 

export default SelectRecipes