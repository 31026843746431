
import './SelectPlantTypes.scss';
import React from 'react'

import {StandardList, StandardListRow} from '../components/StandardList/StandardList.js'


const SelectPlantTypes = ({selectedPlantType, onPlantTypeSelection}) => {
  const [plantGroups, SetPlantGroups] = React.useState([
    {id: 1, name: "Berries"}, {id: 2, name: "Lettuce"}
  ])
  const [plantTypes, SetPlantTypes] = React.useState([
    {id: 1, name: "Blackberries", group_id: 1, description: "Disgusting horribly delicious fruit"},
    {id: 2, name: "Strawberries", group_id: 1, description: "Disgusting horribly delicious fruit"},
    {id: 3, name: "Butterhead", group_id: 2, description: "The only plant we can grow"}
  ])

  const [plantTypesList, SetPlantTypesList] = React.useState({})
  const [plantGroupsList, SetPlantGroupsList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedPlantType, SetSelectedPlantType] = React.useState(undefined)
  React.useEffect(() => {
    if (currentSelectedPlantType !== selectedPlantType) {
      SetSelectedPlantType(selectedPlantType)
    }
  }, [selectedPlantType])
  
  const [currentGroupByTypeFilter, SetGroupByTypeFilter] = React.useState('*')
  


  React.useEffect(() => {
    let currentPlantTypesList = {}
    let currentPlantGroupsList = {}

    let currentPlantTypes = []
    let currentPlantGroups = []
    
    let availablePlantGroups = []

    if (currentGroupByTypeFilter === "*") {
      availablePlantGroups = [...plantGroups]
    }else {
      availablePlantGroups = [plantGroups.find((plantGroup) => plantGroup.id === currentGroupByTypeFilter)]
    }

    if (currentSearchInput === "")  {
      currentPlantGroups = [...availablePlantGroups]
    }else {
      currentPlantGroups.push(...availablePlantGroups.filter((plantGroup) => {
        if (plantGroup.name.indexOf(currentSearchInput) !== -1)  {
          currentPlantTypes.push(...plantTypes.filter((plantType) => {
            if (plantType.group_id === plantGroup.id) {
              return plantType
            }
          }))
          return plantGroup
        }
      }))
    }
    currentPlantTypes.push(...plantTypes.filter((plantType) => {

      if ((currentSearchInput === "" || plantType.name.indexOf(currentSearchInput) !== -1) && currentPlantTypes.indexOf(plantType) === -1)  {
        const foundPlantGroup = availablePlantGroups.find((plantGroup) => plantGroup.id === plantType.group_id)
        if (foundPlantGroup !== undefined)  {
          if (currentPlantGroups.indexOf(foundPlantGroup) === -1) {
            currentPlantGroups.push(foundPlantGroup)
          }
          return plantType
        }
      }
    }))
    
    for (let plantType of currentPlantTypes) {
      if (currentPlantGroupsList[plantType.group_id] === undefined) {
        let foundPlantGroup = currentPlantGroups.find((plantGroup) => plantGroup.id === plantType.group_id)
        if (foundPlantGroup !== undefined)  {
          currentPlantGroupsList[plantType.group_id] = {
            data: foundPlantGroup,
            render: (<div className="StandardList-Group-Header">{foundPlantGroup.name}</div>)
          }
        }
      }
      currentPlantTypesList[plantType.id] = {
        data: plantType,
        columns: {
          icon: {
            render: (<div className="StandardList-Row-Column">
              <div style={{height:20}}></div>
            </div>)
          },
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{plantType.name}</div>
              <div className="StandardList-Row-Column-Secondary">{plantType.description}</div>
            </div>),
          },
        },
        groupKey: plantType.group_id
      }
      if (currentSelectedPlantType !== undefined && currentSelectedPlantType.id === plantType.id) {
        currentPlantTypesList[plantType.id].selected = true
      }
    }
    SetPlantTypesList(currentPlantTypesList)
    SetPlantGroupsList(currentPlantGroupsList)
  }, [plantGroups, plantTypes, currentSearchInput, currentGroupByTypeFilter, currentSelectedPlantType])




  
  const columns = {
    icon: {widthType: "px", width: 20, resizable: false, sort_by: false},
    name: {render: (<div className="StandardList-Header-Column">Name</div>), width: "grow", resizable: true, sort_by: false},
  }

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }

  const onGroupFilter = (value) =>  {
    SetGroupByTypeFilter(value)
  }
  

  const rowSelected = (plantType) => {
    SetSelectedPlantType(plantType.data)
    if (onPlantTypeSelection !== undefined) {
      onPlantTypeSelection(plantType.data)
    }
  }

  return (
    <>
      <div id="SelectPlantTypes">
        <StandardList 
          columns={columns} 
          showHeader={false}
          columnSpacing={10}
          groups={plantGroupsList} 
          rows={plantTypesList}
          isSearchable={true} 
          onSearchInput={onSearchInput}
          isGroupFiltered={true}
          groupFilterOptions={[{value: "*", label: "All Types"}, ...plantGroups.map((plantGroup) => {
            return {value: plantGroup.id, label: plantGroup.name}
          })]}
          onGroupFilterChange={onGroupFilter}
          filteredByGroup={currentGroupByTypeFilter}
          onRowClicked={rowSelected}/>
      </div>
    </>
  )
} 

export default SelectPlantTypes