import './Badge.scss';


const Badge = ({content, status, size}) => {

  return (
    <div className={"Badge Badge-" + status + " Badge-" + size}>
      <div><span>{content}</span></div>
    </div>
  )
} 

Badge.defaultProps = {
  content: "",
  status: "Neutral",
  size: "Medium"
}


export default Badge