import './GrowManagerPage.scss';
import variables from '../../globals.scss';
 
import React from 'react'

import { selectAllGrows, selectAllGrowGroups, getAllGrows, growUpdate, getGrowLiveData, scheduleNewGrow, scheduleNewGrowGroup} from '../../redux/entities/Grow'
import { getGrowPlanById, selectAllGrowPlans} from '../../redux/entities/GrowPlans'
import { selectAllRecipes, getRecipeById, GetCurrentGrowZoneCycle, GetCLIForCycle } from '../../redux/entities/Recipes';
import { useSelector, useDispatch } from 'react-redux'

import { animated, useSpring } from 'react-spring'
import { useMeasure, useMeasureWithPosition } from '../../helpers'
import { Routes, Route, Navigate } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';
import {GanttChart} from '../../components/GanttChart';

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import Button from '../../components/Button.js'
import {BsPinFill, BsPin, BsChevronDown, BsClockHistory, BsAlignStart, BsAlignEnd} from 'react-icons/bs'

import Badge from '../../components/Badge.js'
import StandardList from '../../components/StandardList/StandardList';
import { FormatDate } from '../../helpers';

import GrowManagerChartsPage from './GrowManagerChartsPage';

import {useLocation} from 'react-router-dom';
import StandardListRowColumn from '../../components/StandardList/StandardListRowColumn';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { RecipeTimeline } from '../RecipesAndPlans/Recipes/RecipeTimeline';
import CircularGauge from '../../components/CircularGauge';
import ScheduleGrowPopup from './ScheduleGrowPopup';
import { MdArrowDownward, MdInfoOutline } from 'react-icons/md';
import {AiOutlineDownCircle, AiOutlineRightCircle} from 'react-icons/ai'
import NotificationIcon from '../../components/NotificationIcon';





const GrowManagerPage = ({pageHeaderHelper}) => { 
  
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const activePath = splitLocation[2]

  

  const managerTabs = [new TabControlTab("Status", "status", true),
    new TabControlTab("Planning", "planning"),
    new TabControlTab("KPI", "kpi"),
    new TabControlTab("Charts", "charts")];
  const managerTabControlTabClickedRef = React.useRef(null);
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Grow Manager")
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              {activePath === "planning" &&
                <Button content={"Schedule Grow"} onClick={() => {SetSchedulingGrow(true)}}/>
              }
            </div>
          </div>
        </>
      )
    })
    pageHeaderHelper.current.SetTabControlTabs(managerTabs);
    pageHeaderHelper.current.SetTabControlTabClickCallback((tab) => {
      if (managerTabControlTabClickedRef.current) {
        managerTabControlTabClickedRef.current(tab);
      }
    });
  }, [pageHeaderHelper, activePath])


  const isPlanningPage = (activePath === "planning" ? true : false)
  const isStatusPage = (activePath === "status" ? true : false)
  const isChartsPage = (activePath === "charts" ? true : false)

  const [growRepoDimensions, SetGrowRepoDimensions] = React.useState({})
  const handleGrowRepoDimensions = React.useCallback((ref) => {
    SetGrowRepoDimensions({...ref.current})
  }, [])

  const growRepoRowDimensions = React.useRef({})
  const forceListScroll = React.useRef(null);



  const [scoreColors, setScoreColors] = React.useState({
    good: {min: 90, max:100, color: "rgba(90, 195, 100, 1)"},
    operational: {min: 75, max:90, color: "rgba(255, 220, 0, 1)"},
    critical: {min: 50, max:75, color: "rgba(255, 122, 44, 1)"},
    fail: {min: 0, max:50, color: "rgba(0, 255, 0, 1)"},
    neutral: {color: "rgba(120, 122, 12, 1)"},
  })

  
  let grows = useSelector(selectAllGrows)
  let growGroups = useSelector(selectAllGrowGroups)
  let growPlans = useSelector(selectAllGrowPlans)
  let recipes = useSelector(selectAllRecipes)
  let haveInitialData = useSelector((state) => state.grows.haveInitialData)
  let loadingRecipeStatus = useSelector((state) => state.recipes.status)
  let loadingGrowLiveDataStatus = useSelector((state) => state.grows.loadingGrowLiveDataStatus)
  const dispatch = useDispatch()
  React.useEffect( () =>  { 
    if (!haveInitialData) {
      dispatch(getAllGrows())
    }
  }, [haveInitialData])

  const handleGrowUpdate = (row, prop, value) => {
    dispatch(growUpdate({rowID: row.id, prop: prop, value: value}))
  }

  const [schedulingGrow, SetSchedulingGrow] = React.useState(false)
  const closeSchedulingGrowPopup = () =>  {
    SetSchedulingGrow(false)
  }

  const schedulingGrowFinalized = (finalizedGrow) =>  {
    dispatch(scheduleNewGrow({grow: finalizedGrow}))
    SetSchedulingGrow(false)
  }
  const schedulingGrowGroupFinalized = (finalizedGroup, finalizedGrows) =>  {
    dispatch(scheduleNewGrowGroup({growGroup: finalizedGroup, grows: finalizedGrows}))
    SetSchedulingGrow(false)
  }


  const [growsList, SetGrowsList] = React.useState({})
  const [growGroupsList, SetGrowGroupsList] = React.useState({})
  const [growGroupsListProcessed, SetGrowGroupsListProcessed] = React.useState({})
  
  const [currentSearchInput, SetSearchInput] = React.useState("")

  const [contentBind, { width: contentWidth }] = useMeasure()
  let contentProps = {style: {}}
  if (!isPlanningPage)  {
    contentProps.style.gap = "10"
  }
  contentProps = {...contentProps, ...contentBind}
  const [repoBind, { width: repoWidth }] = useMeasure()
  
  
  const repoWidthAnimation = useSpring({
    from: { minWidth: 0},
    to: { minWidth: !isStatusPage ? 0 : contentWidth}
  })

  const [columns, SetColumns] = React.useState({})

  
  const [expandedGrows, SetExpandedGrows] = React.useState({})
  const allowMultipleExpandedGrows = false

  const growExpansionToggled = (rowKey, forColumn) =>  {
    if (expandedGrows[rowKey] === undefined)  {
      if (allowMultipleExpandedGrows)  {
        SetExpandedGrows({...expandedGrows, [rowKey]: forColumn})
      }else {
        SetExpandedGrows({[rowKey]: forColumn})
      }
    }else {
      if (forColumn === expandedGrows[rowKey])  {
        delete expandedGrows[rowKey]
        SetExpandedGrows({...expandedGrows})
      }else {
        SetExpandedGrows({[rowKey]: forColumn})
      }
    }
  }

  
 
const toggleExpandGrowGroup = React.useCallback((groupKey) =>  {
  growGroupsList[groupKey].isExpanded = !growGroupsList[groupKey].isExpanded 
  SetGrowGroupsList({...growGroupsList})
  
  if (growGroupsList[groupKey].isExpanded)  {
    SetExpandedGrows({...expandedGrows, [groupKey]: "grows"})
  }else {
    delete expandedGrows[groupKey]
    SetExpandedGrows({...expandedGrows})
  }

  //growExpansionToggled(groupKey, "grows")
})


  const requestLoop = React.useCallback(() => {
    let growPlanDataToRequest = []
    let recipeDataToRequest = []
    let growLiveDataToRequest = []

    for (let grow of grows) {
      if (grow.initialSave !== false) {
        if (grow.grow_plans_info !== undefined && grow.grow_plans_info)  {
          for (let growPlanIdAsString of Object.keys(grow.grow_plans_info))  {
            let growPlanId = parseInt(growPlanIdAsString)
            let foundGrowPlan = growPlans.find((gP) => gP.id === growPlanId)
            if (foundGrowPlan === undefined)  {
              if (growPlanDataToRequest.indexOf(growPlanId) === -1)  {
                growPlanDataToRequest.push(growPlanId)
              }
            }
          }
        }
        if (grow.recipe_id !== undefined && grow.recipe_id)  {
          let foundRecipe = recipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
          if (foundRecipe === undefined)  {
            recipeDataToRequest.push({id: grow.recipe_id, version: grow.recipe_version})
          }
        }

        if (grow.lastLiveDataUpdateOn + 1000 < new Date().getTime())  {
          growLiveDataToRequest.push(grow.id)
        }
      }      
    }

    if (growPlanDataToRequest.length > 0) {
      dispatch(getGrowPlanById({growPlans: growPlanDataToRequest}))
    }

    
    if (recipeDataToRequest.length > 0) {
      if (loadingRecipeStatus !== "pending")  {
        dispatch(getRecipeById({recipes: recipeDataToRequest}))
      }
    }

    if (growLiveDataToRequest.length > 0) {
      if (loadingGrowLiveDataStatus !== "pending")  {
        dispatch(getGrowLiveData({growIds: growLiveDataToRequest}))
      }
    }

  })
  const requestRef = React.useRef({
    lastRequestLoopCompletedOn: 0
  })
  React.useEffect(() => {
    const requestInterval = setInterval(() => {
        if (requestRef.current.lastRequestLoopCompletedOn + 1000 < new Date().getTime()) {
            requestLoop()
            requestRef.current.lastRequestLoopCompletedOn = new Date().getTime()
        }
    }, 100);
    

    return () => {
        clearInterval(requestInterval)
    };
}, [requestRef, grows, growPlans, recipes]);



  React.useEffect(() => {
    let currentGrowsList = {}
    let currentGrowGroupsList = {}
    let groupsChanged = false

    let currentGrows = []
    let currentGrowGroups = {}
    

    if (currentSearchInput === "")  {
      currentGrows = [...grows]
    }else {
      currentGrows.push(...grows.filter((grow) => {
        if (grow.name.indexOf(currentSearchInput) !== -1 && currentGrows.indexOf(grow) === -1)  {
          return grow
        }
      }))
    }
    
    for (let grow of currentGrows) {
      if (grow.group_id)  {
        if (currentGrowGroups[grow.group_id] === undefined) {
          currentGrowGroups[grow.group_id] = [grow]
        }else {
          currentGrowGroups[grow.group_id].push(grow)
        }
      }
    }

    for (let [groupId, growList] of Object.entries(currentGrowGroups))  {
      let growGroup = growGroups.find((gG) => gG.id.toString() === groupId)
      if (growGroup !== undefined) {
        let groupKey = "gg-" + growGroup.id.toString()
        if (growGroupsList[groupKey] === undefined)  {
          currentGrowGroupsList[groupKey] = {
            isGroup: true,
            isExpandable: true,
            isExpanded: false,
            showExpander: true
          }
          let foundRecipe = recipes.find((r) => r.id === growGroup.recipe_id && r.id === growGroup.recipe_version)
          if (foundRecipe !== undefined)  {
            currentGrowGroupsList[groupKey].duration = foundRecipe.expected_duration
            if (growGroup.scheduled_type === "nad" || growGroup.scheduled_type === "start") {
              currentGrowGroupsList[groupKey].startDate = new Date(growGroup.scheduled_for).getTime()
              currentGrowGroupsList[groupKey].endDate = currentGrowGroupsList[groupKey].startDate + (currentGrowGroupsList[groupKey].duration * 1000) 
            }else {
              currentGrowGroupsList[groupKey].endDate = new Date(growGroup.scheduled_for).getTime()
              currentGrowGroupsList[groupKey].startDate = currentGrowGroupsList[groupKey].endDate - (currentGrowGroupsList[groupKey].duration * 1000)
            }
          }else {
            currentGrowGroupsList[groupKey].startDate = new Date(growGroup.scheduled_for).getTime()
          }
          currentGrowGroupsList[groupKey].key = currentGrowGroupsList[groupKey].startDate


          groupsChanged = true
        }else {
          currentGrowGroupsList[groupKey] = {...growGroupsList[groupKey]}
        }
        currentGrowGroupsList[groupKey].data = {...growGroup}
        currentGrowGroupsList[groupKey].rowData = {}

        for (let grow of growList) {
          let growKey = "g-" + grow.id.toString()
          if (currentGrowGroupsList[groupKey] === undefined || currentGrowGroupsList[groupKey].rowData[growKey] === undefined)  {
            currentGrowGroupsList[groupKey].rowData[growKey] = {
              key: new Date(grow.scheduled_for).getTime(),
              isExpandable: isStatusPage,
              isExpanded: expandedGrows[growKey] !== undefined,
              showExpander: false
            }
          }else if (currentGrowGroupsList[groupKey] !== undefined && currentGrowGroupsList[groupKey].rowData[growKey] !== undefined)  {
            currentGrowGroupsList[groupKey].rowData[growKey] = {...currentGrowGroupsList[groupKey].rowData[growKey]}
          }
          currentGrowGroupsList[groupKey].rowData[growKey].data = grow
        }
      }
    }
    
    for (let grow of currentGrows) {
      if (grow.group_id === undefined || grow.group_id === null)  {
        let foundRecipe = recipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
        let foundGrowPlans = []
        if (grow.grow_plans_info !== undefined && grow.grow_plans_info) {
          for (let growPlanId of Object.keys(grow.grow_plans_info))  {
            let foundGrowPlan = growPlans.find((gP) => gP.id.toString() === growPlanId)
            if (foundGrowPlan !== undefined)  {
              foundGrowPlans.push(foundGrowPlan)
            }
          }
        }
        
        let growKey = "g-" + grow.id.toString()
        currentGrowsList[growKey] = {
          data: grow,
          isGroup: false,
          isExpandable: isStatusPage,
          isExpanded: expandedGrows[growKey] !== undefined,
          showExpander: false
        }


        if (foundRecipe !== undefined)  {
          currentGrowsList[growKey].duration = foundRecipe.expected_duration
          if (grow.started_on)  {
            currentGrowsList[growKey].startDate = new Date(grow.started_on).getTime()
            if (grow.completed) {
              currentGrowsList[growKey].endDate =  new Date(grow.finished_on).getTime()
            }else {
              currentGrowsList[growKey].endDate =  currentGrowsList[growKey].startDate + currentGrowsList[growKey].duration * 1000
            }
          }else {
            if (grow.scheduled_type === "nad" || grow.scheduled_type === "start") {
              currentGrowsList[growKey].startDate = new Date(grow.scheduled_for).getTime()
              currentGrowsList[growKey].endDate = currentGrowsList[growKey].startDate + (currentGrowsList[growKey].duration * 1000) 
            }else {
              currentGrowsList[growKey].endDate = new Date(grow.scheduled_for).getTime()
              currentGrowsList[growKey].startDate = currentGrowsList[growKey].endDate - (currentGrowsList[growKey].duration * 1000)
            }
          }
        }else {
          currentGrowsList[growKey].startDate = new Date(grow.scheduled_for).getTime()
        }
        currentGrowsList[growKey].key = currentGrowsList[growKey].startDate
        currentGrowsList[growKey].name = "#G" + grow.id
        
        switch (grow.stage)    {
          case "completed":
              currentGrowsList[growKey].statusColor = variables.neutral300
              currentGrowsList[growKey].status = "Neutral"
              break
            case "growing":
              currentGrowsList[growKey].statusColor = variables.surfaceSuccess
              currentGrowsList[growKey].status = "Success"
              break
          default:
            currentGrowsList[growKey].statusColor = variables.neutral400
            currentGrowsList[growKey].status = grow.stage
        }



        currentGrowsList[growKey].columns = {
          grow_card: {
            buildRender: (props) => {
              return (
                <div className={(isStatusPage ? "GrowItem_Tile" : "")} onClick={() => {growExpansionToggled(growKey, "info")}}>
                  <div className="GrowItem_RepositoryTile">
                    <div className="GrowItem_RepositoryTile-GrowInfo">
                      {props.renderContent}
                    </div>
                    
                  </div>
                </div>
              )
            }
          },
          title: {
            buildRender: (props) => {
              return (
                <div className="StandardList-Row-Column StandardList-Row-Column-NoTruncate">
                  {foundGrowPlans.length > 1 && 
                    <div style={{display:"flex", gap: 8}}>
                      <div className="StandardList-Row-Column-Primary StandardList-Row-Column-Truncate">
                        <span>Multiple Plans</span> {/*<NotificationIcon color={variables.interactive} content={foundGrowPlans.length} size={18}/>*/}
                      </div>
                      <div>
                        {!isStatusPage && 
                          <MdInfoOutline size={20}/>
                        }
                      </div>
                    </div>
                  }
                  {foundGrowPlans.length == 1 && 
                    <div style={{display:"flex", gap: 8}}>
                      <div className="StandardList-Row-Column-Primary StandardList-Row-Column-Truncate">
                        <span>{foundGrowPlans[0].name}</span>
                      </div>
                      <div>
                        {!isStatusPage && 
                          <MdInfoOutline size={20}/>
                        }
                      </div>
                    </div>
                  }
                  {foundGrowPlans.length == 0 && 
                    <div className="StandardList-Row-Column-Primary">Unknown Grow Plan</div>
                  }
                  
                  <div className="StandardList-Row-Column-Secondary">{currentGrowsList[growKey].name}</div>
                  
                  {isStatusPage && <div className="StandardList-Row-Column-Primary">
                    
                  </div>}
                </div>
              )
            },
          },
          status: {
            buildRender: (props) => {
              let growStatus = "Success"
              switch (grow.stage) {
                case "completed":
                  growStatus = "Neutral"
                  break
                default:
                  break
              }
              let dateToDisplay = (grow.completed ? grow.finished_on : grow.started_on)
              return (<div className="StandardList-Row-Column">
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  {isStatusPage && <div className="StandardList-Row-Column-Primary">{FormatDate(new Date(grow.started_on), "MM/dd/yyyy")}</div>}
                  <div className="StandardList-Row-Column-Primary"><Badge content={grow.stage} status={growStatus}/></div>
                </div>
              </div>)
            },
          },
          
          timing: {
            buildRender: (props) => {
              return (<div className="StandardList-Row-Column GrowItem_DateColumnItem">
                <div style={{textAlign:"center"}}>
                  <div className="StandardList-Row-Column-Primary"><BsAlignStart/>{FormatDate(new Date(currentGrowsList[growKey].startDate), "MM/dd/yyyy")}</div>
                  <div className="StandardList-Row-Column-Primary"><BsAlignEnd/>{currentGrowsList[growKey].endDate ? FormatDate(new Date(currentGrowsList[growKey].endDate), "MM/dd/yyyy") : "N/A"}</div>
                </div>
              </div>)
              
            },
          },
          shared_with: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">S/W</div>
            </div>),
          },
          timeline: {
            buildRender: (props) => {
              let currentProgress = 0
              if (!grow.completed) {
                currentProgress = new Date().getTime() - new Date(grow.started_on).getTime()
              }
              //let currentRevision = 
              return (
                <div className="GrowItem_Tile">
                  <div className="GrowItem-TimelineTile">
                    {foundRecipe && 
                      <RecipeTimeline 
                        recipe={foundRecipe}
                        showProgress={!grow.completed}
                        currentProgress={currentProgress}
                      />}
                    {!foundRecipe &&
                      <>
                        Recipe not found
                      </>
                    }
                  </div>
                </div>
              )
            },
          },
          variance: {
            buildRender: (props) => {
              let tileProps = {style: {}}
              if (props.bounds.innerWidth !== undefined) {
                tileProps.style = {
                  height: props.bounds.innerWidth
                }
              }else {
                tileProps.style = {
                  height: 0
                }
              }
              let havePercentage = false
              let percentage = 0 
              if (Object.entries(grow.liveData).length > 0) {
                havePercentage = true
                percentage = 100
                if (grow.liveData["at-ei"] !== undefined) {
                  percentage -= parseFloat(grow.liveData["at-ei"].value)
                }
                if (grow.liveData["vpd-ei"] !== undefined) {
                  percentage -= parseFloat(grow.liveData["vpd-ei"].value)
                }
                if (grow.liveData["ec-ei"] !== undefined) {
                  percentage -= parseFloat(grow.liveData["ec-ei"].value)
                }
                if (grow.liveData["ph-ei"] !== undefined) {
                  percentage -= parseFloat(grow.liveData["ph-ei"].value)
                }
                if (percentage < 0) {
                  percentage = 0
                }
                percentage = (Math.round(percentage * 10) / 10)
              }


              return (
                <div className="GrowItem_Tile" {...tileProps} onClick={() => {growExpansionToggled(growKey, "variance")}}>
                  <div className="GrowItem_TileContent GrowItem-VarianceTile">
                    <CircularProgressbar value={percentage} text={havePercentage ? percentage + "%" : "N/A"} />
                  </div>
                </div>
              )
            }
          },
        }
        currentGrowsList[growKey].expandedRender = (props) =>  {
          if (expandedGrows[growKey] === "info")  {
            return (
              <>
                Info
              </>
            )
          }else if (expandedGrows[growKey] === "variance")  {
            let tileProps = {style: {justifyContent:"flex-start"}}
            if (props.bounds.innerWidth !== undefined) {
              tileProps.style.height = props.bounds.innerWidth
            }else {
              tileProps.style.margin = 8
            }

            const limitPercentage = (p) => p < 0 ? 0 : p > 100 ? 100 : p

            let havePercentages = false

            let haveAirTempSetpoint = false, airTempPercentage = 100, airTempTrailingPercentage = 100, airTempValue = "N/A", airTempSetpoint = "N/A", airTempScoreColor = scoreColors.neutral.color
            let haveAirRHSetpoint = false, airRHPercentage = 100, airRHTrailingPercentage = 100, airRHValue = "N/A", airRHSetpoint = "N/A", airRHScoreColor = scoreColors.neutral.color
            let haveVPDSetpoint = false, VPDPercentage = 100, VPDTrailingPercentage = 100, VPDValue = "N/A", VPDSetpoint = "N/A", VPDScoreColor = scoreColors.neutral.color
            let haveWaterTempSetpoint = false, waterTempPercentage = 100, waterTempTrailingPercentage = 100, waterTempValue = "N/A", waterTempSetpoint = "N/A", waterTempScoreColor = scoreColors.neutral.color
            let haveECSetpoint = false, ECPercentage = 100, ECTrailingPercentage = 100, ECValue = "N/A", ECSetpoint = "N/A", ECScoreColor = scoreColors.neutral.color
            let havePHSetpoint = false, pHPercentage = 100, pHTrailingPercentage = 100, pHValue = "N/A", pHSetpoint = "N/A", pHScoreColor = scoreColors.neutral.color
            let haveLightingSetpoint = false, lightingPercentage = 100, lightingValue = "N/A", lightingSetpoint = "N/A", lightingScoreColor = scoreColors.neutral.color
            let haveSprayRateSetpoint = false, sprayRatePercentage = 100, sprayRateValue = "N/A", sprayRateSetpoint = "N/A", sprayRateScoreColor = scoreColors.neutral.color
            
            let currentProgress = 0
            let currentCycleCLI = 0
            let foundRecipe = recipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
            if (!grow.completed && foundRecipe) {
              currentProgress = new Date().getTime() - new Date(grow.started_on).getTime()
              currentProgress = 1
              //TODO determine that we are in grow zone somehow
              let currentTimelineItem = GetCurrentGrowZoneCycle(foundRecipe.timeline_items, currentProgress)
              if (currentTimelineItem.item) {
                currentCycleCLI = GetCLIForCycle(currentTimelineItem.item)
              }
            }
          

            if (Object.entries(grow.liveData).length > 0) {
              havePercentages = true
              
              airTempPercentage = grow.liveData["at-ei"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["at-ei"].value)) : 100
              airTempTrailingPercentage = grow.liveData["at-eia"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["at-eia"].value)) : 100
              airTempScoreColor = Object.values(scoreColors).find((s) => s.min <= airTempTrailingPercentage && s.max >= airTempTrailingPercentage).color
              airTempValue = grow.liveData["airt"] !== undefined ? parseFloat(grow.liveData["airt"].value) : "N/A"
              if (grow.liveData["at-sp"] !== undefined) {
                airTempSetpoint = parseFloat(grow.liveData["at-sp"].value)
                haveAirTempSetpoint = true
              }

              airRHPercentage = grow.liveData["rh-ei"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["rh-ei"].value)) : 100
              airRHTrailingPercentage = grow.liveData["rh-eia"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["rh-eia"].value)) : 100
              airRHScoreColor = Object.values(scoreColors).find((s) => s.min <= airRHTrailingPercentage && s.max >= airRHTrailingPercentage).color
              airRHValue = grow.liveData["airrh"] !== undefined ? parseFloat(grow.liveData["airrh"].value) : "N/A"
              if (grow.liveData["rh-sp"] !== undefined) {
                airRHSetpoint = parseFloat(grow.liveData["rh-sp"].value)
                haveAirRHSetpoint = true
              }

              VPDPercentage = grow.liveData["vpd-ei"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["vpd-ei"].value)) : 100
              VPDTrailingPercentage = grow.liveData["vpd-eia"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["vpd-eia"].value)) : 100
              VPDScoreColor = Object.values(scoreColors).find((s) => s.min <= VPDTrailingPercentage && s.max >= VPDTrailingPercentage).color
              VPDValue = grow.liveData["airvpd"] !== undefined ? parseFloat(grow.liveData["airvpd"].value) : "N/A"
              if (grow.liveData["vpd-sp"] !== undefined) {
                VPDSetpoint = parseFloat(grow.liveData["vpd-sp"].value)
                haveVPDSetpoint = true
              }


              waterTempPercentage = grow.liveData["wt-ei"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["wt-ei"].value)) : 100
              waterTempTrailingPercentage = grow.liveData["wt-eia"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["wt-eia"].value)) : 100
              waterTempScoreColor = Object.values(scoreColors).find((s) => s.min <= waterTempTrailingPercentage && s.max >= waterTempTrailingPercentage).color
              waterTempValue = grow.liveData["watert"] !== undefined ? parseFloat(grow.liveData["watert"].value) : "N/A"
              if (grow.liveData["wt-sp"] !== undefined) {
                waterTempSetpoint = parseFloat(grow.liveData["wt-sp"].value)
                haveWaterTempSetpoint = true
              }

              ECPercentage = grow.liveData["ec-ei"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["ec-ei"].value)) : 100
              ECTrailingPercentage = grow.liveData["ec-eia"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["ec-eia"].value)) : 100
              ECScoreColor = Object.values(scoreColors).find((s) => s.min <= ECTrailingPercentage && s.max >= ECTrailingPercentage).color
              ECValue = grow.liveData["waterec"] !== undefined ? parseFloat(grow.liveData["waterec"].value) : "N/A"
              if (grow.liveData["ec-sp"] !== undefined) {
                ECSetpoint = parseFloat(grow.liveData["ec-sp"].value)
                haveECSetpoint = true
              }

              pHPercentage = grow.liveData["ph-ei"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["ph-ei"].value)) : 100
              pHTrailingPercentage = grow.liveData["ph-eia"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["ph-eia"].value)) : 100
              pHScoreColor = Object.values(scoreColors).find((s) => s.min <= pHTrailingPercentage && s.max >= pHTrailingPercentage).color
              pHValue = grow.liveData["waterph"] !== undefined ? parseFloat(grow.liveData["waterph"].value) : "N/A"
              if (grow.liveData["ph-sp"] !== undefined) {
                pHSetpoint = parseFloat(grow.liveData["ph-sp"].value)
                havePHSetpoint = true
              }


              if (grow.liveData["cli-a"] !== undefined && grow.liveData["cli-sp"] !== undefined) {
                let expectedUpUntilNowValue = parseFloat(grow.liveData["cli-sp"].value)
                lightingValue = currentCycleCLI - (expectedUpUntilNowValue - parseFloat(grow.liveData["cli-a"].value))

                lightingPercentage = limitPercentage((lightingValue / currentCycleCLI) * 100)
                lightingScoreColor = Object.values(scoreColors).find((s) => s.min <= lightingPercentage && s.max >= lightingPercentage).color

                lightingSetpoint = 0
                haveLightingSetpoint = true
              }

              sprayRatePercentage = grow.liveData["srate-ei"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["srate-ei"].value)) : 100
              //sprayRateTrailingPercentage = grow.liveData["ph-eia"] !== undefined ? limitPercentage(100 - parseFloat(grow.liveData["ph-eia"].value)) : 100
              sprayRateScoreColor = Object.values(scoreColors).find((s) => s.min <= sprayRatePercentage && s.max >= sprayRatePercentage).color
              sprayRateValue = grow.liveData["srate"] !== undefined ? parseFloat(grow.liveData["srate"].value) : "N/A"
              if (grow.liveData["srate-sp"] !== undefined) {
                sprayRateSetpoint = parseFloat(grow.liveData["srate-sp"].value)
                haveSprayRateSetpoint = true
              }
              
            }

            return (
              <div className="GrowItem_ExpandedContent">
                <div className="GrowItem_WrappingTiles">
                  <div className="GrowItem_Tile" {...tileProps}>
                    <div className="GrowItem_TileContent GrowItem-GaugeTile GrowItem-AirTempTile">
                      <div className="GrowItem_Tile-Header">
                        <div className="GrowItem_Tile-Title">Air Temp</div>
                        <div className="GrowItem_Tile-StatusIndicator" style={{backgroundColor:airTempScoreColor}}/>
                      </div>
                      <CircularGauge value={airTempTrailingPercentage} applicable={haveAirTempSetpoint}/>
                      <div className="GrowItem_Tile-Title"><span style={{width:"6ch"}}>Current: {(Math.round(airTempValue * 10) / 10)}°C</span>{haveAirTempSetpoint && <>&nbsp;&nbsp;<span> {airTempPercentage}%</span></>}</div>
                      {haveAirTempSetpoint && <div className="GrowItem_Tile-Title">Setpoint: {airTempSetpoint}°C</div>}
                    </div>
                  </div>

                  <div className="GrowItem_Tile" {...tileProps}>
                    <div className="GrowItem_TileContent GrowItem-GaugeTile GrowItem-AirRHTile">
                      <div className="GrowItem_Tile-Header">
                        <div className="GrowItem_Tile-Title">Air RH</div>
                        <div className="GrowItem_Tile-StatusIndicator" style={{backgroundColor:airRHScoreColor}}/>
                      </div>
                      <CircularGauge value={airRHTrailingPercentage} applicable={haveAirRHSetpoint}/>
                      <div className="GrowItem_Tile-Title"><span style={{width:"6ch"}}>Current: {(Math.round(airRHValue * 10) / 10)}%</span>{haveAirRHSetpoint && <>&nbsp;&nbsp;<span> {airRHPercentage}%</span></>}</div>
                      {haveAirRHSetpoint && <div className="GrowItem_Tile-Title">Setpoint: {airRHSetpoint}%</div>}
                    </div>
                  </div>

                  <div className="GrowItem_Tile" {...tileProps}>
                    <div className="GrowItem_TileContent GrowItem-GaugeTile GrowItem-VPDTile">
                      <div className="GrowItem_Tile-Header">
                        <div className="GrowItem_Tile-Title">VPD</div>
                        <div className="GrowItem_Tile-StatusIndicator" style={{backgroundColor:VPDScoreColor}}/>
                      </div>
                      <CircularGauge value={VPDTrailingPercentage} applicable={haveVPDSetpoint}/>
                      <div className="GrowItem_Tile-Title"><span style={{width:"6ch"}}>Current: {(Math.round(VPDValue * 10) / 10)}kPa</span>{haveVPDSetpoint && <>&nbsp;&nbsp;<span> {VPDPercentage}%</span></>}</div>
                      {haveVPDSetpoint && <div className="GrowItem_Tile-Title">Setpoint: {VPDSetpoint}kPa</div>}
                    </div>
                  </div>

                  <div className="GrowItem_Tile" {...tileProps}>
                    <div className="GrowItem_TileContent GrowItem-GaugeTile GrowItem-WaterTempTile">
                      <div className="GrowItem_Tile-Header">
                        <div className="GrowItem_Tile-Title">Water Temp</div>
                        <div className="GrowItem_Tile-StatusIndicator" style={{backgroundColor:waterTempScoreColor}}/>
                      </div>
                      <CircularGauge value={waterTempTrailingPercentage} applicable={haveWaterTempSetpoint}/>
                      <div className="GrowItem_Tile-Title"><span style={{width:"6ch"}}>Current: {(Math.round(waterTempValue * 10) / 10)}°C</span>{haveWaterTempSetpoint && <>&nbsp;&nbsp;<span> {waterTempPercentage}%</span></>}</div>
                      {haveWaterTempSetpoint && <div className="GrowItem_Tile-Title">Setpoint: {waterTempSetpoint}°C</div>}
                    </div>
                  </div>
                  
                  <div className="GrowItem_Tile" {...tileProps}>
                    <div className="GrowItem_TileContent GrowItem-GaugeTile GrowItem-ECTile">
                      <div className="GrowItem_Tile-Header">
                        <div className="GrowItem_Tile-Title">EC</div>
                        <div className="GrowItem_Tile-StatusIndicator" style={{backgroundColor:ECScoreColor}}/>
                      </div>
                      <CircularGauge value={ECPercentage} applicable={haveECSetpoint}/>
                      <div className="GrowItem_Tile-Title"><span style={{width:"6ch"}}>Current: {(Math.round(ECValue * 10) / 10)}S/m</span></div>
                      {haveECSetpoint && <div className="GrowItem_Tile-Title">Setpoint: {ECSetpoint}S/m</div>}
                    </div>
                  </div>

                  <div className="GrowItem_Tile" {...tileProps}>
                    <div className="GrowItem_TileContent GrowItem-GaugeTile GrowItem-pHTile">
                      <div className="GrowItem_Tile-Header">
                        <div className="GrowItem_Tile-Title">pH</div>
                        <div className="GrowItem_Tile-StatusIndicator" style={{backgroundColor:pHScoreColor}}/>
                      </div>
                      <CircularGauge value={pHPercentage} applicable={havePHSetpoint}/>
                      <div className="GrowItem_Tile-Title"><span style={{width:"6ch"}}>Current: {(Math.round(pHValue * 10) / 10)}°C</span></div>
                      {havePHSetpoint && <div className="GrowItem_Tile-Title">Setpoint: {pHSetpoint}°C</div>}
                    </div>
                  </div>

                  <div className="GrowItem_Tile" {...tileProps}>
                    <div className="GrowItem_TileContent GrowItem-GaugeTile GrowItem-SprayRateTile">
                      <div className="GrowItem_Tile-Header">
                        <div className="GrowItem_Tile-Title">Aeroponics</div>
                        <div className="GrowItem_Tile-StatusIndicator" style={{backgroundColor:sprayRateScoreColor}}/>
                      </div>
                      <CircularGauge value={sprayRatePercentage} applicable={haveSprayRateSetpoint}/>
                      <div className="GrowItem_Tile-Title"><span style={{width:"6ch"}}>Current: {(Math.round(sprayRateValue * 10) / 10)}%</span></div>
                      {haveSprayRateSetpoint && <div className="GrowItem_Tile-Title">Setpoint: {sprayRateSetpoint}%</div>}
                    </div>
                  </div>

                  <div className="GrowItem_Tile" {...tileProps}>
                    <div className="GrowItem_TileContent GrowItem-GaugeTile GrowItem-LightingTile">
                      <div className="GrowItem_Tile-Header">
                        <div className="GrowItem_Tile-Title">Lighting</div>
                        <div className="GrowItem_Tile-StatusIndicator" style={{backgroundColor:lightingScoreColor}}/>
                      </div>
                      <CircularGauge value={lightingPercentage} applicable={haveLightingSetpoint}/>
                      <div className="GrowItem_Tile-Title"><span style={{width:"6ch"}}>Current: {(Math.round(lightingValue * 10) / 10)}mols</span></div>
                      {haveLightingSetpoint && <div className="GrowItem_Tile-Title">Setpoint: {Math.round(currentCycleCLI * 10) / 10}mols</div>}
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        }
      }
    }
    
    SetGrowsList(currentGrowsList)
    SetGrowGroupsList(currentGrowGroupsList)
  }, [grows, growGroups, growPlans, recipes, currentSearchInput, isStatusPage, expandedGrows])


  React.useEffect(() => {

    for (const [groupKey, growGroup] of Object.entries(growGroupsList))  {
      let foundRecipe = recipes.find((r) => r.id === growGroup.data.recipe_id)
      let foundGrowPlans = []
      if (growGroup.data.grow_plans_info !== undefined && growGroup.data.grow_plans_info) {
        for (let growPlanId of Object.keys(growGroup.data.grow_plans_info))  {
          let foundGrowPlan = growPlans.find((gP) => gP.id.toString() === growPlanId)
          if (foundGrowPlan !== undefined)  {
            foundGrowPlans.push(foundGrowPlan)
          }
        }
      }

      growGroup.name = "x" + Object.entries(growGroup.rowData).length + " Grows"
      switch (growGroup.stage)    {
        case "completed":
            growGroup.statusColor = variables.neutral300
            growGroup.status = "Neutral"
            break
          case "growing":
            growGroup.statusColor = variables.surfaceSuccess
            growGroup.status = "Success"
            break
        default:
          growGroup.statusColor = variables.neutral400
          growGroup.status = growGroup.data.stage
      }


      if (foundRecipe !== undefined)  {
        growGroup.duration = foundRecipe.expected_duration
        if (growGroup.data.scheduled_type === "nad" || growGroup.data.scheduled_type === "start") {
          growGroup.startDate = new Date(growGroup.data.scheduled_for).getTime()
          growGroup.endDate = growGroup.startDate + (growGroup.duration * 1000) 
        }else {
          growGroup.endDate = new Date(growGroup.data.scheduled_for).getTime()
          growGroup.startDate = growGroup.endDate - (growGroup.duration * 1000)
        }

        for (let [growKey, grow] of Object.entries(growGroup.rowData))  {
          grow.startDate = new Date(grow.data.scheduled_for).getTime()
          grow.duration = foundRecipe.expected_duration
          grow.name = "#G" + grow.data.id

          switch (grow.stage)    {
            case "completed":
              grow.statusColor = variables.neutral300
              grow.status = "Neutral"
              break
            case "growing":
              grow.statusColor = variables.surfaceSuccess
              grow.status = "Success"
              break
            default:
              grow.statusColor = variables.neutral400
              grow.status = grow.data.stage
          }
        }
      }else {
        growGroup.startDate = new Date(growGroup.data.scheduled_for).getTime()
      }
      growGroup.key = growGroup.startDate



      
      


      growGroup.columns = {
        grow_card: {
          buildRender: (props) => {
            return (
              <div className={(isStatusPage ? "GrowItem_Tile" : "")} /*onClick={() => {growExpansionToggled(groupKey, "info")}}*/>
                <div className="GrowItem_RepositoryTile">
                  <div className="GrowItem_RepositoryTile-GrowInfo">
                    {props.renderContent}
                  </div>
                  
                </div>
              </div>
            )
          }
        },
        title: {
          buildRender: (props) => {
            return (
              <div className="StandardList-Row-Column">
                {foundGrowPlans.length > 1 && 
                  <div style={{display:"flex", gap: 8}}>
                    <div className="StandardList-Row-Column-Primary StandardList-Row-Column-Truncate">
                      <span>Multiple Plans</span> {/*<NotificationIcon color={variables.interactive} content={foundGrowPlans.length} size={18}/>*/}
                    </div>
                    <div>
                      {!isStatusPage && 
                        <MdInfoOutline size={20}/>
                      }
                    </div>
                  </div>
                }
                {foundGrowPlans.length == 1 && 
                  <div style={{display:"flex", gap: 8}}>
                    <div className="StandardList-Row-Column-Primary StandardList-Row-Column-Truncate">
                      {foundGrowPlans[0].name}
                    </div>
                  </div>
                }
                {foundGrowPlans.length == 0 && 
                  <div className="StandardList-Row-Column-Primary">Unknown Grow Plan</div>
                }
                
                <div className="StandardList-Row-Column-Secondary">
                  <div style={{display:"flex", gap: 8, alignItems: "center"}}>
                    
                    {/* style={{cursor:"pointer"}}
                    onClick={() => {toggleExpandGrowGroup(groupKey)}}>{!isStatusPage && 
                    <>
                      {growGroup.isExpanded && <AiOutlineDownCircle size={20}/>}
                      {!growGroup.isExpanded && <AiOutlineRightCircle size={20}/>}
                    </>
                    }*/}

                    x{Object.entries(growGroup.rowData).length} Grows</div>
                      
                    {!isStatusPage && 
                      <div style={{display:"flex", alignItems: "center"}}>
                        <MdInfoOutline size={20}/>
                      </div>
                    }
                </div>  
              </div>
            )
          },
        },
        status: {
          buildRender: (props) => {
            let growStatus = "Success"
            switch (growGroup.data.stage) {
              case "completed":
                growStatus = "Neutral"
                break
              default:
                break
            }
            let dateToDisplay = (growGroup.data.completed ? growGroup.data.finished_on : growGroup.data.started_on)
            return (<div className="StandardList-Row-Column">
              <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                {isStatusPage && <div className="StandardList-Row-Column-Primary">{FormatDate(new Date(growGroup.data.started_on), "MM/dd/yyyy")}</div>}
                <div className="StandardList-Row-Column-Primary"><Badge content={growGroup.data.stage} status={growStatus}/></div>
              </div>
            </div>)
          },
        },
        
        timing: {
          buildRender: (props) => {
            return (<div className="StandardList-Row-Column GrowItem_DateColumnItem">
              <div style={{textAlign:"center"}}>
                <div className="StandardList-Row-Column-Primary"><BsAlignStart/>{FormatDate(new Date(growGroup.startDate), "MM/dd/yyyy")}</div>
                <div className="StandardList-Row-Column-Primary"><BsAlignEnd/>{growGroup.endDate ? FormatDate(new Date(growGroup.endDate), "MM/dd/yyyy") : "N/A"}</div>
              </div>
            </div>)
            
          },
        },
        shared_with: {
          render: (<div className="StandardList-Row-Column">
            <div className="StandardList-Row-Column-Primary">S/W</div>
          </div>),
        },
        timeline: {
          buildRender: (props) => {
            let currentProgress = 0
            if (growGroup.data.started_on && !growGroup.data.completed) {
              currentProgress = new Date().getTime() - new Date(groupKey.started_on).getTime()
            }
            //let currentRevision = 
            return (
              <div className="GrowItem_Tile">
                <div className="GrowItem-TimelineTile">
                  {foundRecipe && 
                    <RecipeTimeline 
                      currentRevision={foundRecipe.active_revision}
                      showProgress={!growGroup.data.completed}
                      currentProgress={currentProgress}
                    />}
                </div>
              </div>
            )
          },
        },
        variance: {
          buildRender: (props) => {
            let tileProps = {style: {}}
            if (props.bounds.innerWidth !== undefined) {
              tileProps.style = {
                height: props.bounds.innerWidth
              }
            }else {
              tileProps.style = {
                height: 0
              }
            }
            let havePercentage = false
            let percentage = 0 

            return (
              <div className="GrowItem_Tile" {...tileProps} onClick={() => {growExpansionToggled(groupKey, "variance")}}>
                <div className="GrowItem_TileContent GrowItem-VarianceTile">
                  <CircularProgressbar value={percentage} text={havePercentage ? percentage + "%" : "N/A"} />
                </div>
              </div>
            )
          }
        },
      }
      growGroup.expandedRender = (props) =>  {
        if (expandedGrows[groupKey] === "grows")  {
          return (
            <div className="GrowGroup-GrowItems">
              {Object.entries(growGroup.rowData).map(([growKey, grow]) => {
                return (
                  <GrowGroupRow 
                    growKey={growKey} grow={grow} key={growKey}
                    bounds={props.bounds} columnPadding={props.columnPadding} columnSpacing={props.columnSpacing}
                    rowContainerRef={props.rowContainerRef} 
                    growRepoDimensions={growRepoDimensions} growRepoRowDimensionsRef={growRepoRowDimensions}/>
                )
              })}
            </div>
          )
        }else if (expandedGrows[groupKey] === "variance")  {
          return (<>Scores</>)
        }
      }

      const expandButtonPressed = () => {
        toggleExpandGrowGroup(groupKey)
      }
      growGroup.expandButtonRender = () => {
        return (<div className="GrowGroup-ToggleAllGrowsButton">
          {!growGroup.isExpanded && <>
            Show {Object.entries(growGroup.rowData).length} Grows
          </>}
          {growGroup.isExpanded && <>
            Hide {Object.entries(growGroup.rowData).length} Grows
          </>}
        </div>)
      }
      growGroup.onExpandToggled = expandButtonPressed
    }

    SetGrowGroupsListProcessed({...growGroupsList})

  }, [growGroupsList])


  const onSearchInput = (value) => {
    SetSearchInput(value)
  }
  

  React.useEffect(() => {
    let currentColumns = {
      grow_card: {isGroupColumn: true, groupedColumns: {
        title: {render: (<div className="StandardList-Header-Column">Title</div>), width: 15, resizable: true, sort_by: false},
        status: {render: (<div className="StandardList-Header-Column">Status</div>), width: 12, resizable: true, sort_by: false}
        /*shared_with: {render: (<div className="StandardList-Header-Column">Shared</div>), width: 5, resizable: true, sort_by: false},*/
      }}
    }
    if (isPlanningPage) {
      
      currentColumns.timing = {render: (<div className="StandardList-Header-Column">Timing</div>), width: 12, resizable: true, sort_by: false}//, hidden: !isPlanningPage
    }
    if (isStatusPage) {
      currentColumns.timeline = {render: (<div className="StandardList-Header-Column"></div>), minWidth: 40, width: "grow", resizable: true, sort_by: false}
      currentColumns.variance = {render: (<div className="StandardList-Header-Column"></div>), minWidth: 10, width: 10, resizable: true, sort_by: false}
    }
    SetColumns({...currentColumns})
    
  }, [isStatusPage, isPlanningPage])

  const growItemToggled = (row) =>  {
    if (isChartsPage) {
      dispatch(growUpdate({growId: row.data.id, prop: "selected", value: !row.data.selected}))
    }

  }


  return (
    <div className="PageWrapper_Standard">
      <TabControl 
        tabs={managerTabs}
        tabClickedRef={(ref) => {managerTabControlTabClickedRef.current=ref;}}
        hideTabs={true}
        content={
          <div id="GrowManager">
            <div className="ControlBar_Horizontal">
              <div className="ControlBar_Horizontal-Left ControlBar_Horizontal-Overflow">
                <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
                <DropDownInput options={[{ value: 'all', label: 'All Categories' }]} defaultValue="all"/>
                <DropDownInput options={[{ value: 'all', label: 'All Years' }]} defaultValue="all"/>
                <DropDownInput options={[{ value: 'grouped_by_crop_name', label: 'Grouped By Crop Name' }]} defaultValue="grouped_by_crop_name"/>
                <DropDownInput options={[{ value: 'name', label: 'Name' }]} defaultValue="name" prefix="Sort By"/>
              </div>
            </div>


            <div id="GrowManager_Content" {...contentProps}>
          

              <animated.div id="GrowManager_GrowRepository" style={repoWidthAnimation} {...repoBind}>
                <StandardList 
                  showHeader={isPlanningPage}
                  columns={columns} 
                  rows={{...growsList, ...growGroupsListProcessed}}
                  rowPadding={8}
                  orderRowsBy="key"
                  columnPadding={4}
                  columnSpacing={4}
                  groupedColumnPadding={4}
                  isolateRows={true}
                  dynamicRowClassName={(row) => {
                    return "GrowItem" + (row.data.pinned ? " GrowItem_Pinned" : " GrowItem_Unpinned") + (isChartsPage ? " GrowItem_Selectable": "") + (row.data.selected && isChartsPage ? " GrowItem_Selected" : "")
                  }}
                  onRowClicked={growItemToggled}
                  isSearchable={false}
                  onSearchInput={onSearchInput}
                  resourcesAttached={false}
                  getDimensions={handleGrowRepoDimensions}
                  getRowDimensions={(rowKey, ref) => growRepoRowDimensions.current[rowKey] = ref.current}
                  getForceScroll={(forceScroll) => forceListScroll.current = forceScroll}/>

              </animated.div>

              <div id="GrowManager_TabContent" className={(isStatusPage ? "CollapseForStatus" : "")}>
                <Routes>
                  <Route exact path="/planning" element={
                    <>
                      <div id="GrowManager-Timeline-Content">
                        <GanttChart 
                          id="GrowManager-Timeline-GanttChart" 
                          axisHeight={growRepoDimensions ? growRepoDimensions.headerHeight : 0} 
                          tableHeight={growRepoDimensions ? growRepoDimensions.contentHeight : 0}
                          //listScrollPosition={growRepoDimensions ? growRepoDimensions.contentScrollY : 0}
                          data={{...growsList, ...growGroupsListProcessed}}
                          dataBounds={growRepoRowDimensions.current}
                          dataStartDateKey="startDate"
                          updateRow={handleGrowUpdate}
                          barMargin={75}
                          onScroll={(offset) => {forceListScroll.current && forceListScroll.current(offset)}}/>
                      </div>
                      {schedulingGrow && 
                        <ScheduleGrowPopup closeCallback={closeSchedulingGrowPopup} growCompleteCallback={schedulingGrowFinalized} growGroupCompleteCallback={schedulingGrowGroupFinalized}/>
                      }
                    </>
                  }/>
                  <Route path="/kpi" element={
                    <div>
                      KPI
                    </div>
                  }/>
                  <Route path="/charts" element={
                    <GrowManagerChartsPage pageHeaderHelper={pageHeaderHelper}/>
                  }/>

                  <Route path="/status" element={<></>}/>
                  <Route path="*" element={<Navigate to="status" replace />} />
                </Routes>
              </div>
            </div>
          </div>
        }/>
    </div>
  )
}

const GrowGroupRow = ({
    growKey, grow,
    bounds, columnPadding, columnSpacing,
    rowContainerRef, growRepoDimensions, growRepoRowDimensionsRef
  }) =>  {
  
  let growStatus = "Success"
  switch (grow.data.stage) {
    case "completed":
      growStatus = "Neutral"
      break
    default:
      break
  }

  const dimensionsRef = React.useRef({})

  const [rowBind, forceUpdate, {}] = useMeasureWithPosition({boundingRef: rowContainerRef.current}, (bounds) => {
    if (bounds === undefined)
      return
    dimensionsRef.current.width = bounds.width
    dimensionsRef.current.height = bounds.height
    dimensionsRef.current.left = bounds.left
    dimensionsRef.current.top = bounds.top
    dimensionsRef.current.right = bounds.right
    dimensionsRef.current.bottom = bounds.bottom
    dimensionsRef.current.offsetY = bounds.documentTop - (growRepoDimensions.y ?? 0)

    growRepoRowDimensionsRef.current[growKey] = dimensionsRef.current
  })
  forceUpdate()

  const growItemProps = {style: {
    padding: columnSpacing / 2
  }}
  const titleColumnsProps = {style: {
    width: bounds.title.width,
    padding: columnPadding / 2
  }}
  const statusColumnsProps = {style: {
    width: bounds.status.width,
    padding: columnPadding / 2
  }}
  
  return (
    <div key={growKey} className="GrowGroup-GrowItem_Tile" {...rowBind}>
      <div className="StandardList-Row-ColumnsContent"{...growItemProps}>
        <div className="StandardList-Row-ColumnContainer" {...titleColumnsProps}>
          <div className="StandardList-Row-Column">
            <div className="StandardList-Row-Column-Primary">
              #G{grow.data.id}
            </div>
          </div>
        </div>
        <div className="StandardList-Row-ColumnContainer" {...statusColumnsProps}>
          <div className="StandardList-Row-Column">
          <div className="StandardList-Row-Column-Primary"><Badge content={grow.data.stage} status={growStatus}/></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GrowManagerPage 