import './NotificationIcon.scss';


const NotificationIcon = ({content, color, size}) => {

  let iconProps = {style:{borderRadius:size + "px",width:size + "px", height:size + "px", backgroundColor: color, fontSize: (size * 0.65) + "px"}}
  

  return (
    <div className={"NotificationIcon"} {...iconProps}>
      <span>{content}</span>
    </div>
  )
} 

NotificationIcon.defaultProps = {
  content: "",
  color: "#F00",
  size: 15
}


export default NotificationIcon