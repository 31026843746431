import './../TasksPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate } from '../../../helpers'
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import Button from '../../../components/Button';
import { markTaskAsCompleted, markTaskAsStarted } from '../../../redux/entities/Tasks';
import { getGrowById } from '../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../redux/entities/GrowPlans';
import { getRecipeById } from '../../../redux/entities/Recipes'
import ControlBar from '../../../components/ControlBar';
import PopupModal from '../../../model_components/PopupModal';
import NumberInput from '../../../components/input/NumberInput';
  

const TaskPopup_PlantSeeds = ({task, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  let growsList = useSelector((state) => state.grows.grows)
  let growPlansList = useSelector((state) => state.growPlans.growPlans)
  let recipesList = useSelector((state) => state.recipes.recipes)
 
  const closePopup = () =>  {
    onClosePopup()
  }

  const [grow, SetGrow] = React.useState(null)
  const [growPlans, SetGrowPlans] = React.useState([])
  const [recipe, SetRecipe] = React.useState(null)
  
  
  React.useEffect(() => {
    let foundGrow = growsList.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }else {
      SetGrow({...foundGrow})
    }
  }, [task, growsList])

  React.useEffect(() => {
    let totalUnits = 0
    if (grow) {
      for (let growPlanInfo of Object.values(grow.grow_plans_info)) {
        totalUnits += growPlanInfo.units
      }
    }
    SetDesiredNumberOfPlugs(totalUnits)
    SetSelectedNumberOfPlugs(totalUnits)
  }, [grow])

  React.useEffect(() => {
    if (grow) {
      let foundRecipe = recipesList.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
      console.log(grow, recipesList, foundRecipe)
      if (foundRecipe === undefined)  {
        dispatch(getRecipeById({recipeId: grow.recipe_id, recipeVersion: grow.recipe_version}))
      }else {
        SetRecipe({...foundRecipe})
      }
    }
  }, [grow, recipesList])

  const [desiredNumberOfPlugs, SetDesiredNumberOfPlugs] = React.useState(0)
  const [selectedNumberOfPlugs, SetSelectedNumberOfPlugs] = React.useState(0)


  const validateNumberOfPlugsSelected = React.useCallback((plugs) =>  {
    if (plugs < 0)  {
      return 0
    }else if (plugs > desiredNumberOfPlugs) {
      return desiredNumberOfPlugs
    }
  })
  const finalizeNumberOfPlugsSelected = React.useCallback((plugs) =>  {
    SetSelectedNumberOfPlugs(plugs)
  })

  

  const startTaskClicked = React.useCallback(() => {
    dispatch(markTaskAsStarted({taskId: task.id}))
    SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
  })

  const completeTaskClicked = React.useCallback(() => {
    dispatch(markTaskAsCompleted({taskId: task.id, params: {"number_of_plants": selectedNumberOfPlugs}}))
    onClosePopup()
  })



  let footerContent = <></>
  if (!task.started) {
    footerContent = <>
       <ControlBar controls={(<>
          <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
        </>)} secondaryControls={(<>
            {<Button status="Primary" content="Start" onClick={startTaskClicked}/>}
        </>)}/>
    </>
  }else {
    footerContent = <>
      <ControlBar secondaryControls={(<>
         {<Button status="Primary" content="Complete task" onClick={completeTaskClicked}/>}
      </>)}/>
    </>
  }


  return (
    <PopupModal 
      title={task.name + (grow ? " #G" + grow.id : "")}
      closeCallback={closePopup}
      footer={footerContent}>

      {!task.started && <>
        {(grow && recipe) && <>
          <span>Plant <span style={{fontWeight:"bold"}}>{recipe.starter_product_id}</span> into {desiredNumberOfPlugs} plugs.</span>
        </>}
      </>}


      {task.started && <>
        {(grow && recipe) && <>
          <div className="PopupModal-FormItem">
            <div className="PopupModal-FormItem-Question">
              How many did you plant? (Needed {desiredNumberOfPlugs})
            </div>
            <div className="PopupModal-FormItem-Answer">
              <NumberInput 
                value={selectedNumberOfPlugs} 
                stepper={true} 
                min={0} max={desiredNumberOfPlugs}
                onChange={validateNumberOfPlugsSelected} 
                onBlur={finalizeNumberOfPlugsSelected} />
            </div>
          </div>
        </>}
      </>}

    </PopupModal>

  )
} 

export default TaskPopup_PlantSeeds