import React from 'react'

export function Close() {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 1.25L14 14.75M0.5 14.75L14 1.25" stroke="black"/>
    </svg>
  )
}


export function OpenCell()  {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0.5L4 3.5L7 0.5" stroke="#2E72D2"/>
    </svg> 
  )
}
export function CloseCell()  {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4.5L4 1.5L7 4.5" stroke="#2E72D2"/>
    </svg> 
  )
}

export function OpenDropdown() {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.09832 3.98009e-07L0.901685 -1.43717e-07C0.15069 -2.09371e-07 -0.269991 0.75351 0.193991 1.2676L3.29231 4.70055C3.65265 5.09982 4.34735 5.09982 4.70769 4.70055L7.80601 1.2676C8.26999 0.753511 7.84931 4.63664e-07 7.09832 3.98009e-07Z" fill="#4D5563"/>
    </svg> 
  )
}

export function CloseDropdown() {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.901685 5.5L7.09832 5.5C7.84931 5.5 8.26999 4.74649 7.80601 4.2324L4.70769 0.799447C4.34735 0.400184 3.65265 0.400184 3.29231 0.799447L0.19399 4.2324C-0.269991 4.74649 0.150689 5.5 0.901685 5.5Z" fill="#4D5563"/>     
    </svg> 
  )
}

export function SimpleCheck({color}) {
  return (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4.5L3.4 7.5L9 0.5" stroke={color} stroke-width="1.2"/>
    </svg> 
  )
}
SimpleCheck.defaultProps = {
  color: "#2E72D2",
}

export function AddItem()  {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 9V15.25H8.5V9H14.75V7H8.5V0.75H6.5V7H0.25V9H6.5Z" fill="#2E72D2"/>
    </svg> 
  )
}

export function Plus()  {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7 9V15.25H9V9H15.25V7H9V0.75H7V7H0.75V9H7Z" fill="#2E72D2"/>
    </svg> 
  )
}

export function ReorderItem() {
  return (
    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" y="0.5" width="4" height="4" fill="#BCBCBC"/>
      <rect y="0.5" width="4" height="4" fill="#BCBCBC"/>
      <rect x="6" y="6.5" width="4" height="4" fill="#BCBCBC"/>
      <rect y="6.5" width="4" height="4" fill="#BCBCBC"/>
      <rect x="6" y="12.5" width="4" height="4" fill="#BCBCBC"/>
      <rect y="12.5" width="4" height="4" fill="#BCBCBC"/>
    </svg> 
  )
}

export function SoftWarning() {
  return (
    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9592 1.14648C13.1894 -0.186851 11.2649 -0.186847 10.4951 1.14649L0.270932 18.8552C-0.498868 20.1886 0.463387 21.8552 2.00299 21.8552H22.4513C23.9909 21.8552 24.9531 20.1886 24.1833 18.8552L13.9592 1.14648ZM13.0508 15.0897L13.3408 6.20398H10.6426L10.9414 15.0897H13.0508ZM10.959 16.9091C10.7012 17.1552 10.5723 17.4657 10.5723 17.8407C10.5723 18.204 10.7012 18.5116 10.959 18.7635C11.2227 19.0155 11.5684 19.1415 11.9961 19.1415C12.4297 19.1415 12.7754 19.0155 13.0332 18.7635C13.291 18.5116 13.4199 18.204 13.4199 17.8407C13.4199 17.4657 13.291 17.1552 13.0332 16.9091C12.7754 16.6571 12.4297 16.5311 11.9961 16.5311C11.5684 16.5311 11.2227 16.6571 10.959 16.9091Z" fill="#DABB1A"/>
    </svg> 
  )
}
export function Trash() {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14 3.75H1V13.75C1 14.8546 1.89543 15.75 3 15.75H12C13.1046 15.75 14 14.8546 14 13.75V3.75ZM5 5.75H4V12.75H5V5.75ZM7 5.75H8V12.75H7V5.75ZM11 5.75H10V12.75H11V5.75Z" fill="#9CA6B4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.75C10 0.473858 9.77614 0.25 9.5 0.25H5.5C5.22386 0.25 5 0.473858 5 0.75C5 1.02614 4.77614 1.25 4.5 1.25H0.5C0.223858 1.25 0 1.47386 0 1.75V2.75C0 3.02614 0.223858 3.25 0.5 3.25H14.5C14.7761 3.25 15 3.02614 15 2.75V1.75C15 1.47386 14.7761 1.25 14.5 1.25H10.5C10.2239 1.25 10 1.02614 10 0.75Z" fill="#9CA6B4"/>
    </svg> 
  )
}


export function GoodCheckmark({width})  {
  return (
    <svg width={width} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19ZM9.51117 13.3714L14.8267 5.56271L13.1733 4.43729L7.85786 12.246L5.81642 9.35984L4.18358 10.5148L6.22503 13.401C7.0308 14.5401 8.726 14.5249 9.51117 13.3714Z" fill="#4DBE3B"/>
    </svg> 
  )
}
GoodCheckmark.defaultProps = {
  width: 19,
}
export function BadX()  {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19ZM14 15.4142L9.70711 11.1213L5.41421 15.4142L4 14L8.29289 9.70711L4 5.41421L5.41421 4L9.70711 8.29289L14 4L15.4142 5.41421L11.1213 9.70711L15.4142 14L14 15.4142Z" fill="#EC3C3C"/>
    </svg> 
  )
}



export function RadioUnchecked({width, height}) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="8" fill="white" stroke="#9FA9BA" stroke-width="2"/>
    </svg> 
  )
}
RadioUnchecked.defaultProps = {
  width: 20,
  height: 20,
}



export function RadioChecked({width, height}) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="5" fill="#2E72D2"/>
      <rect x="1" y="1" width="16" height="16" rx="8" stroke="#2E72D2" stroke-width="2"/>
    </svg> 
  )
}
RadioChecked.defaultProps = {
  width: 18,
  height: 18,
}



export function WiFiFull({width}) {
  return (
    <svg width={width} viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M17.8862 4.15113C12.8951 -0.465285 5.13249 -0.463595 0.143368 4.15113C-0.0442528 4.32467 -0.0479151 4.61849 0.133508 4.79851L1.09809 5.75548C1.27106 5.92733 1.54939 5.93099 1.72913 5.76619C5.83987 2.00026 12.1891 1.99941 16.3007 5.76619C16.4804 5.93099 16.7588 5.92705 16.9317 5.75548L17.8963 4.79851C18.0774 4.61849 18.0738 4.32467 17.8862 4.15113ZM9.01476 9.70424C8.01891 9.70424 7.21181 10.5113 7.21181 11.5072C7.21181 12.5031 8.01891 13.3102 9.01476 13.3102C10.0106 13.3102 10.8177 12.5031 10.8177 11.5072C10.8177 10.5113 10.0106 9.70424 9.01476 9.70424ZM14.7242 7.34941C11.4772 4.47792 6.54866 4.48102 3.3053 7.34941C3.11092 7.52126 3.10473 7.81959 3.28925 8.00157L4.25946 8.95911C4.42849 9.12589 4.70062 9.13716 4.88064 8.98165C7.24561 6.93727 10.7893 6.94177 13.1486 8.98165C13.3286 9.13716 13.6008 9.12617 13.7698 8.95911L14.74 8.00157C14.9248 7.81959 14.9183 7.52097 14.7242 7.34941Z" fill="#2E72D2"/>
    </svg>

  )
}
WiFiFull.defaultProps = {
  width: 19
}



export function DeviceTransmitting({width}) {
  return (
    <svg width={width} viewBox="0 0 123 234" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1539">
      <rect id="Phone" x="21.5" y="87.5" width="81" height="143" rx="6.5" fill="white" stroke="#191C22" stroke-width="7"/>
      <path id="Wifi signal" fill-rule="evenodd" clip-rule="evenodd" d="M118.699 27.4163C111.599 21.2546 103.542 16.2683 94.8321 12.6605C84.2161 8.26325 72.8379 6 61.3472 6C49.8566 6 38.4784 8.26326 27.8625 12.6606C19.2578 16.2247 11.2905 21.1343 4.25369 27.1933L0 22.9396C7.60198 16.3303 16.2333 10.9831 25.5663 7.11727C36.9103 2.41845 49.0687 2.78105e-06 61.3472 0C73.6258 -2.781e-06 85.7842 2.41844 97.1282 7.11726C106.566 11.0267 115.287 16.451 122.951 23.1637L118.699 27.4163ZM108.08 38.0351C102.402 33.2557 96.0144 29.3683 89.1351 26.5188C80.339 22.8753 70.9114 21 61.3905 21C51.8697 21 42.4421 22.8753 33.646 26.5187C26.8531 29.3324 20.5396 33.1581 14.9156 37.8553L19.1769 42.1165C24.2303 37.9649 29.8779 34.5738 35.9421 32.062C44.0102 28.7201 52.6576 27 61.3905 27C70.1234 27 78.7708 28.7201 86.839 32.062C92.9897 34.6097 98.7119 38.062 103.82 42.2948L108.08 38.0351ZM94.5778 51.5372C85.1775 44.1024 73.4955 40 61.3905 40C49.3801 40 37.786 44.0385 28.4239 51.3636L32.7021 55.6418C40.9105 49.4214 50.9741 46 61.3905 46C71.9017 46 82.0537 49.484 90.3026 55.8124L94.5778 51.5372ZM83.8704 62.2446C81.4686 60.5171 78.87 59.0681 76.1238 57.9306C71.4528 55.9958 66.4464 55 61.3905 55C56.3346 55 51.3282 55.9958 46.6572 57.9306C43.9985 59.0319 41.4781 60.4251 39.1407 62.0803L43.4565 66.3961C45.1868 65.2512 47.0271 64.2718 48.9533 63.4739C52.8963 61.8406 57.1225 61 61.3905 61C65.6584 61 69.8846 61.8406 73.8277 63.4739C75.8419 64.3082 77.7621 65.341 79.561 66.5541L83.8704 62.2446ZM74.432 71.683C73.3326 71.0178 72.182 70.4351 70.9894 69.9411C67.8956 68.6596 64.5797 68 61.231 68C57.8822 68 54.5663 68.6596 51.4725 69.9411C50.441 70.3683 49.441 70.8619 48.4783 71.4179L52.9201 75.8597C53.1996 75.728 53.4825 75.6029 53.7686 75.4844C56.1345 74.5044 58.6702 74 61.231 74C63.7917 74 66.3274 74.5044 68.6933 75.4843C69.1441 75.6711 69.5871 75.8744 70.0213 76.0937L74.432 71.683Z" fill="#2E72D2"/>
      </g>
    </svg>


  )
}
DeviceTransmitting.defaultProps = {
  width: 123
}



export function MenuButton({width, fill}) {
  return (
    <svg width={width} viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1096">
        <rect id="Rectangle 477" width="18" height="2" rx="1" fill={fill}/>
        <rect id="Rectangle 478" y="6" width="18" height="2" rx="1" fill={fill}/>
        <rect id="Rectangle 479" y="12" width="18" height="2" rx="1" fill={fill}/>
      </g>
    </svg>


  )
}
MenuButton.defaultProps = {
  width: 18,
  fill: "#191C22"
}







export function MenuHome({width, fill}) {
  return (
    <svg width={width} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 2944">
      <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M2 8.08163C2 7.81642 2.10536 7.56206 2.29289 7.37453L7.37453 2.29289C7.76505 1.90237 8.39822 1.90237 8.78874 2.29289L9.81924 3.32339C10.1399 3.64401 10.6881 3.41693 10.6881 2.96351C10.6881 2.68243 10.9159 2.45456 11.197 2.45456H11.5569C12.0367 2.45456 12.4257 2.84354 12.4257 3.32337V5.92977C12.4256 5.92977 12.4256 5.92977 12.4257 5.92977L13.9088 7.41295C14.0717 7.57588 14.1633 7.79687 14.1633 8.0273V14.4867C14.1633 15.0389 13.7156 15.4867 13.1633 15.4867H10.8192C10.2669 15.4867 9.81922 15.0389 9.81922 14.4867V11.2739C9.81922 10.7216 9.37151 10.2739 8.81923 10.2739H7.34401C6.79172 10.2739 6.34401 10.7216 6.34401 11.2739V14.4867C6.34401 15.0389 5.89629 15.4867 5.34401 15.4867H3C2.44772 15.4867 2 15.0389 2 14.4867V8.08163Z" 
        fill={fill}/>
      </g>
    </svg>

  )
}
MenuHome.defaultProps = {
  width: 22,
  fill: "#191C22"
}





export function MenuTasks({width, fill}) {
  return (
    <svg width={width} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 2945">
      <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M10.9598 2H12.5887V2.81451H10.9598V2ZM2 4.81641C2 3.71184 2.89543 2.81641 4 2.81641H12.2176C13.3222 2.81641 14.2176 3.71184 14.2176 4.81641V5.25992H2V4.81641ZM5.25791 2H3.62891V2.81451H5.25791V2Z" fill={fill}/>
      <path id="Vector_2" d="M2 6.26172H14.1632V11.8637C14.1632 12.9683 13.2678 13.8637 12.1632 13.8637H4C2.89543 13.8637 2 12.9683 2 11.8637V6.26172Z" fill={fill}/>
      </g>
    </svg>

  )
}
MenuTasks.defaultProps = {
  width: 22
}





export function MenuGrowManager({width, fill}) {
  return (
    <svg width={width} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 2946">
      <g id="Group 1571">
      <path id="Vector" d="M2.79375 2H2V13.9062H2.79375V2Z" fill={fill}/>
      <path id="Vector_2" d="M9.73137 2H4.58765C4.03536 2 3.58765 2.44772 3.58765 3V3.38124C3.58765 3.93352 4.03536 4.38124 4.58765 4.38124H9.73137C10.2837 4.38124 10.7314 3.93352 10.7314 3.38124V3C10.7314 2.44772 10.2837 2 9.73137 2Z" fill={fill}/>
      <path id="Vector_3" d="M12.1124 5.17188H7.76245C7.21017 5.17188 6.76245 5.61959 6.76245 6.17188V6.55312C6.76245 7.1054 7.21017 7.55311 7.76245 7.55311H12.1124C12.6647 7.55311 13.1124 7.1054 13.1124 6.55312V6.17188C13.1124 5.61959 12.6647 5.17188 12.1124 5.17188Z" fill={fill}/>
      <path id="Vector_4" d="M11.3187 8.35156H6.96875C6.41647 8.35156 5.96875 8.79928 5.96875 9.35156V9.7328C5.96875 10.2851 6.41647 10.7328 6.96875 10.7328H11.3187C11.871 10.7328 12.3187 10.2851 12.3187 9.7328V9.35156C12.3187 8.79928 11.871 8.35156 11.3187 8.35156Z" fill={fill}/>
      <path id="Vector_5" d="M13.7001 11.5234H8.5564C8.00411 11.5234 7.5564 11.9712 7.5564 12.5234V12.9047C7.5564 13.457 8.00411 13.9047 8.5564 13.9047H13.7001C14.2524 13.9047 14.7001 13.457 14.7001 12.9047V12.5234C14.7001 11.9712 14.2524 11.5234 13.7001 11.5234Z" fill={fill}/>
      </g>
      </g>
    </svg>

  )
}
MenuGrowManager.defaultProps = {
  width: 22
}


export function MenuFarms({width, fill}) {
  return (
    <svg width={width} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 2941">
      <g id="Frame 2949">
      <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M2 2C1.44772 2 1 2.44772 1 3V12.7913C1 13.3436 1.44772 13.7913 2 13.7913H15.2593C15.8116 13.7913 16.2593 13.3436 16.2593 12.7913V3C16.2593 2.44772 15.8116 2 15.2593 2H2ZM3.38715 3.38597C2.83487 3.38597 2.38715 3.83368 2.38715 4.38597V11.4028C2.38715 11.9551 2.83486 12.4028 3.38715 12.4028H12.4848C13.0371 12.4028 13.4848 11.9551 13.4848 11.4028V4.38597C13.4848 3.83368 13.0371 3.38597 12.4848 3.38597H3.38715Z" fill={fill}/>
      <path id="Vector_2" d="M12.2911 4.21484H3.71069C3.43455 4.21484 3.21069 4.4387 3.21069 4.71484V11.0844C3.21069 11.3605 3.43455 11.5844 3.71069 11.5844H12.2911C12.5672 11.5844 12.7911 11.3605 12.7911 11.0844V4.71484C12.7911 4.4387 12.5672 4.21484 12.2911 4.21484Z" fill={fill}/>
      </g>
      </g>
    </svg>

  )
}
MenuFarms.defaultProps = {
  width: 22
}

export function MenuRepo({width, fill}) {
  return (
    <svg width={width} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 2943">
      <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M8.50002 13.6956C8.50001 13.6956 8.50002 13.6955 8.50002 13.6956C10.0635 12.3363 11.8412 12.3707 13.2152 12.8014C14.0025 13.0482 14.9999 12.5151 14.9999 11.69V4.80248C14.9999 4.50026 14.8644 4.20971 14.6177 4.03509C12.3749 2.44733 10.3813 2.96905 9.21604 3.73067C8.77627 4.0181 8.18647 4.05904 7.73837 3.78477C5.34683 2.32097 3.2849 3.19755 2.28361 4.05384C2.09172 4.21794 2 4.46493 2 4.71741V11.5011C2 12.3587 3.08833 12.8837 3.92007 12.6744C5.79858 12.2016 7.63697 13.0454 8.50002 13.6956Z" fill={fill}/>
      </g>
    </svg>

  )
}
MenuRepo.defaultProps = {
  width: 22
}

export function MenuStore({width, fill}) {
  return (
    <svg width={width} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 2947">
      <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M3.0061 2H1.5C1.22386 2 1 2.22386 1 2.5C1 2.77614 1.22386 3 1.5 3H1.89576C2.31287 3 2.67478 3.2879 2.76857 3.69433L4.66998 11.9338C4.77113 12.3721 4.51873 12.8125 4.32562 13.2188C4.25127 13.3752 4.20967 13.5502 4.20967 13.7349C4.20967 14.3997 4.74856 14.9386 5.41333 14.9386C6.07809 14.9386 6.61698 14.3997 6.61698 13.7349C6.61698 13.5798 6.72982 13.4317 6.88494 13.4317H12.3672C12.5223 13.4317 12.6353 13.5798 12.6353 13.7349C12.6353 14.3997 13.1742 14.9386 13.8389 14.9386C14.5036 14.9386 15.0426 14.3997 15.0426 13.7349C15.0426 13.0869 14.5304 12.5585 13.8889 12.5323C13.8614 12.5311 13.839 12.509 13.839 12.4815C13.839 12.454 13.8167 12.4317 13.7892 12.4317H6.11052C5.93529 12.4317 5.78325 12.3107 5.74385 12.14C5.69101 11.911 5.85866 11.6901 6.09343 11.6795L13.0742 11.3622C13.5254 11.3417 13.9069 11.0211 14.0049 10.5801L15.1733 5.3224C15.312 4.69789 14.8369 4.10547 14.1972 4.10547H4.68524C4.21959 4.10547 3.81556 3.78406 3.71085 3.33033L3.49329 2.38757C3.44094 2.1607 3.23892 2 3.0061 2Z" fill={fill}/>
      </g>
    </svg>
  )
}
MenuStore.defaultProps = {
  width: 22
}

export function MenuCRM({width, fill}) {
  return (
    <svg width={width} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 2942" clip-path="url(#clip0_436_43004)">
      <g id="Clip path group">
      <mask id="mask0_436_43004" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
      <g id="clip0_436_42908">
      <path id="Vector" d="M17 0H0V17H17V0Z" fill="white"/>
      </g>
      </mask>
      <g mask="url(#mask0_436_43004)">
      <g id="Group">
      <path id="Vector_2" d="M14.4258 13.686C15.4056 13.686 16.231 12.8623 15.8561 11.957C15.629 11.4089 15.2962 10.9108 14.8767 10.4913C14.4571 10.0717 13.9591 9.73894 13.4109 9.51188C12.8628 9.28483 12.2753 9.16797 11.6819 9.16797C11.0886 9.16797 10.5011 9.28483 9.95298 9.51188C9.40483 9.73894 8.90677 10.0717 8.48723 10.4913C8.06769 10.9108 7.73489 11.4089 7.50784 11.957C7.13286 12.8623 7.95827 13.686 8.93814 13.686H11.6819H14.4258Z" fill={fill}/>
      <path id="Vector_3" d="M11.6646 8.41557C13.1201 8.41557 14.3001 7.23561 14.3001 5.78005C14.3001 4.32449 13.1201 3.14453 11.6646 3.14453C10.209 3.14453 9.02905 4.32449 9.02905 5.78005C9.02905 7.23561 10.209 8.41557 11.6646 8.41557Z" fill={fill}/>
      <path id="Vector_4" d="M4.90476 8.41646C6.15238 8.41646 7.16377 7.40506 7.16377 6.15745C7.16377 4.90983 6.15238 3.89844 4.90476 3.89844C3.65715 3.89844 2.64575 4.90983 2.64575 6.15745C2.64575 7.40506 3.65715 8.41646 4.90476 8.41646Z" fill={fill}/>
      <path id="Vector_5" fill-rule="evenodd" clip-rule="evenodd" d="M8.28107 9.65803C8.16831 9.75324 8.05943 9.85331 7.95474 9.958C7.46528 10.4475 7.07701 11.0285 6.81212 11.668C6.8032 11.6896 6.79442 11.7112 6.78579 11.7328C6.48125 12.4966 6.91851 13.2051 7.61754 13.5174H5.62439H2.77896C1.76279 13.5174 0.906813 12.6632 1.29568 11.7244C1.53114 11.1559 1.87626 10.6394 2.31134 10.2043C2.74642 9.76927 3.26293 9.42415 3.83138 9.18868C4.39984 8.95322 5.0091 8.83203 5.62439 8.83203C6.23968 8.83203 6.84895 8.95322 7.4174 9.18868C7.72168 9.31472 8.01108 9.47217 8.28107 9.65803Z" fill={fill}/>
      </g>
      </g>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_436_43004">
      <rect width="17" height="17" fill="white"/>
      </clipPath>
      </defs>
    </svg>

  )
}
MenuCRM.defaultProps = {
  width: 22
}


export function MenuSettings({width, fill}) {
  return (
    <svg width={width} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 2948">
        <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M7.03557 15C6.35288 14.8489 5.70948 14.5933 5.12303 14.2508L5.15747 13.5766C5.20444 12.657 4.4946 11.9497 3.57742 11.9965L2.83205 12.0346C2.483 11.4802 2.21328 10.8708 2.0388 10.2224L2.62379 9.69425C3.30727 9.0772 3.30546 8.07514 2.62379 7.45973L2 6.89656C2.15483 6.26189 2.40042 5.66279 2.72224 5.11379L3.57742 5.15747C4.49703 5.20444 5.20431 4.4946 5.15747 3.57742L5.11379 2.72224C5.66279 2.40042 6.26189 2.15483 6.89656 2L7.45973 2.62379C8.07677 3.30727 9.07883 3.30546 9.69425 2.62379L10.2224 2.0388C10.8708 2.21328 11.4802 2.483 12.0346 2.83205L11.9965 3.57742C11.9495 4.49703 12.6594 5.20431 13.5766 5.15747L14.2508 5.12303C14.5933 5.70948 14.8489 6.35288 15 7.03557L14.5302 7.45973C13.8467 8.07677 13.8485 9.07883 14.5302 9.69425L14.964 10.0859C14.7926 10.7818 14.512 11.4346 14.1414 12.0254L13.5766 11.9965C12.657 11.9495 11.9497 12.6594 11.9965 13.5766L12.0254 14.1414C11.4346 14.512 10.7818 14.7926 10.0859 14.964L9.69425 14.5302C9.0772 13.8467 8.07514 13.8485 7.45973 14.5302L7.03557 15ZM8.48383 11.2646C10.0196 11.2646 11.2646 10.0196 11.2646 8.48383C11.2646 6.94808 10.0196 5.70311 8.48383 5.70311C6.94808 5.70311 5.70311 6.94808 5.70311 8.48383C5.70311 10.0196 6.94808 11.2646 8.48383 11.2646Z" fill="#191C22"/>
      </g>
    </svg>

  )
}
MenuSettings.defaultProps = {
  width: 22
}

