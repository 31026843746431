import './../ManageRackWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, RackStandardGroup, RackStandardGrowOut, ScanRack } from '../../../../assets/icons/RackIcons';
import RadioButton from '../../../../components/RadioButton';
import { Plus, ReorderItem } from '../../../../assets/icons/Icons';
import { createNewVerticalRackGroup, selectAllVerticalRackGroups } from '../../../../redux/entities/service/VerticalRackGroup';
import { useDispatch, useSelector } from 'react-redux';


import { nanoid, customAlphabet  } from 'nanoid'
import LoadingBar from '../../../../components/LoadingBar';
import { selectAllFacilities } from '../../../../redux/entities/Facilities';

  

const SelectRackNameWalkthroughStep = ({rackIndex, initialRackName, facilityId, rackGroupId, transitionToPreviousStep, transitionToNextStep, rackNameSelectedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const facilities = useSelector(selectAllFacilities)
    const verticalRackGroups = useSelector(selectAllVerticalRackGroups)




    const [selectedRackName, SetSelectedRackName] = React.useState(initialRackName)
    const rackNameInputChanged = (value) => {
        SetSelectedRackName(value)
    }

    const backClicked = () =>   {
        transitionToPreviousStep(["select_rack_index", null])
    }
    const nextClicked = () =>   {
        rackNameSelectedCallback({rackName: selectedRackName})
        transitionToNextStep(["rack_type", "left"])
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Name your rack</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    <div className="FlexContent-HFill">
                        <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                            <div className="Button-Large-Icon"><RackStandardGroup width={(isTablet || isDesktop) ? 168 : 140}/></div>
                        </>}/>
                    </div>
                    <div className="FlexContent-HFill">
                        <div className="FlexContent-30 FlexContent-Center FlexContent-HFill">
                            <div className="Walkthrough-FormItem">
                                <div className="Walkthrough-FormItem-Question">Enter Name</div>
                                <div className="Walkthrough-FormItem-Answer">
                                    <TextInput value={selectedRackName} placeHolder="Rack Name" onChange={rackNameInputChanged}/>
                                    <div className="Walkthrough-FormItem-Answer-Info" style={{textAlign:"center"}}>
                                        Position {rackIndex}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next Step"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


SelectRackNameWalkthroughStep.defaultProps = {
    theme: "normal",
  }
  


export default SelectRackNameWalkthroughStep