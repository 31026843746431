import React from 'react'
import './NDSIcons.scss';


export function ScanNDS({width}) {
  
  return (
   <svg className="ScanNDSIcon" width={width} viewBox="0 0 93 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1569">
      <path id="Vector 309" d="M65.6414 1.1543L1.31379 18.0991L1 191.313L28.6138 198.844L92 181.899V8.37154L65.6414 1.1543Z" fill="white"/>
      <g id="Ellipse 609">
      <mask id="path-2-inside-1_452_45802" fill="white">
      <ellipse cx="6.0208" cy="71.7584" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 71.7584C5.27597 71.7694 5.27077 71.7132 5.3466 71.6185C5.42537 71.52 5.65101 71.3274 6.0208 71.3274V75.3274C8.21198 75.3274 9.27597 73.29 9.27597 71.7584H5.27597ZM6.0208 71.3274C6.39059 71.3274 6.61622 71.52 6.69499 71.6185C6.77083 71.7132 6.76562 71.7694 6.76562 71.7584H2.76562C2.76562 73.29 3.82962 75.3274 6.0208 75.3274V71.3274ZM6.76562 71.7584C6.76562 71.7475 6.77083 71.8036 6.69499 71.8984C6.61622 71.9968 6.39059 72.1895 6.0208 72.1895V68.1895C3.82962 68.1895 2.76562 70.2269 2.76562 71.7584H6.76562ZM6.0208 72.1895C5.65101 72.1895 5.42537 71.9968 5.3466 71.8984C5.27077 71.8036 5.27597 71.7475 5.27597 71.7584H9.27597C9.27597 70.2269 8.21198 68.1895 6.0208 68.1895V72.1895Z" fill="#2E72D2" mask="url(#path-2-inside-1_452_45802)"/>
      </g>
      <g id="Ellipse 616">
      <mask id="path-4-inside-2_452_45802" fill="white">
      <ellipse cx="6.0208" cy="89.9576" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 89.9576C5.27597 89.9686 5.27077 89.9125 5.3466 89.8177C5.42537 89.7192 5.65101 89.5266 6.0208 89.5266V93.5266C8.21198 93.5266 9.27597 91.4892 9.27597 89.9576H5.27597ZM6.0208 89.5266C6.39059 89.5266 6.61622 89.7192 6.69499 89.8177C6.77083 89.9125 6.76562 89.9686 6.76562 89.9576H2.76562C2.76562 91.4892 3.82962 93.5266 6.0208 93.5266V89.5266ZM6.76562 89.9576C6.76562 89.9467 6.77083 90.0028 6.69499 90.0976C6.61622 90.1961 6.39059 90.3887 6.0208 90.3887V86.3887C3.82962 86.3887 2.76562 88.4261 2.76562 89.9576H6.76562ZM6.0208 90.3887C5.65101 90.3887 5.42537 90.1961 5.3466 90.0976C5.27077 90.0028 5.27597 89.9467 5.27597 89.9576H9.27597C9.27597 88.4261 8.21198 86.3887 6.0208 86.3887V90.3887Z" fill="#2E72D2" mask="url(#path-4-inside-2_452_45802)"/>
      </g>
      <g id="Ellipse 622">
      <mask id="path-6-inside-3_452_45802" fill="white">
      <ellipse cx="6.0208" cy="106.903" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 106.903C5.27597 106.914 5.27077 106.858 5.3466 106.763C5.42537 106.665 5.65101 106.472 6.0208 106.472V110.472C8.21198 110.472 9.27597 108.435 9.27597 106.903H5.27597ZM6.0208 106.472C6.39059 106.472 6.61622 106.665 6.69499 106.763C6.77083 106.858 6.76562 106.914 6.76562 106.903H2.76562C2.76562 108.435 3.82962 110.472 6.0208 110.472V106.472ZM6.76562 106.903C6.76562 106.892 6.77083 106.948 6.69499 107.043C6.61622 107.141 6.39059 107.334 6.0208 107.334V103.334C3.82962 103.334 2.76562 105.371 2.76562 106.903H6.76562ZM6.0208 107.334C5.65101 107.334 5.42537 107.141 5.3466 107.043C5.27077 106.948 5.27597 106.892 5.27597 106.903H9.27597C9.27597 105.371 8.21198 103.334 6.0208 103.334V107.334Z" fill="#2E72D2" mask="url(#path-6-inside-3_452_45802)"/>
      </g>
      <g id="Ellipse 628">
      <mask id="path-8-inside-4_452_45802" fill="white">
      <ellipse cx="6.0208" cy="125.102" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 125.102C5.27597 125.113 5.27077 125.057 5.3466 124.962C5.42537 124.864 5.65101 124.671 6.0208 124.671V128.671C8.21198 128.671 9.27597 126.634 9.27597 125.102H5.27597ZM6.0208 124.671C6.39059 124.671 6.61622 124.864 6.69499 124.962C6.77083 125.057 6.76562 125.113 6.76562 125.102H2.76562C2.76562 126.634 3.82962 128.671 6.0208 128.671V124.671ZM6.76562 125.102C6.76562 125.091 6.77083 125.147 6.69499 125.242C6.61622 125.341 6.39059 125.533 6.0208 125.533V121.533C3.82962 121.533 2.76562 123.571 2.76562 125.102H6.76562ZM6.0208 125.533C5.65101 125.533 5.42537 125.341 5.3466 125.242C5.27077 125.147 5.27597 125.091 5.27597 125.102H9.27597C9.27597 123.571 8.21198 121.533 6.0208 121.533V125.533Z" fill="#2E72D2" mask="url(#path-8-inside-4_452_45802)"/>
      </g>
      <g id="Ellipse 634">
      <mask id="path-10-inside-5_452_45802" fill="white">
      <ellipse cx="6.0208" cy="143.303" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 143.303C5.27597 143.314 5.27077 143.258 5.3466 143.163C5.42537 143.065 5.65101 142.872 6.0208 142.872V146.872C8.21198 146.872 9.27597 144.835 9.27597 143.303H5.27597ZM6.0208 142.872C6.39059 142.872 6.61622 143.065 6.69499 143.163C6.77083 143.258 6.76562 143.314 6.76562 143.303H2.76562C2.76562 144.835 3.82962 146.872 6.0208 146.872V142.872ZM6.76562 143.303C6.76562 143.292 6.77083 143.349 6.69499 143.443C6.61622 143.542 6.39059 143.734 6.0208 143.734V139.734C3.82962 139.734 2.76562 141.772 2.76562 143.303H6.76562ZM6.0208 143.734C5.65101 143.734 5.42537 143.542 5.3466 143.443C5.27077 143.349 5.27597 143.292 5.27597 143.303H9.27597C9.27597 141.772 8.21198 139.734 6.0208 139.734V143.734Z" fill="#2E72D2" mask="url(#path-10-inside-5_452_45802)"/>
      </g>
      <g id="Ellipse 640">
      <mask id="path-12-inside-6_452_45802" fill="white">
      <ellipse cx="6.0208" cy="160.876" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 160.876C5.27597 160.887 5.27077 160.83 5.3466 160.736C5.42537 160.637 5.65101 160.445 6.0208 160.445V164.445C8.21198 164.445 9.27597 162.407 9.27597 160.876H5.27597ZM6.0208 160.445C6.39059 160.445 6.61622 160.637 6.69499 160.736C6.77083 160.83 6.76562 160.887 6.76562 160.876H2.76562C2.76562 162.407 3.82962 164.445 6.0208 164.445V160.445ZM6.76562 160.876C6.76562 160.865 6.77083 160.921 6.69499 161.016C6.61622 161.114 6.39059 161.307 6.0208 161.307V157.307C3.82962 157.307 2.76562 159.344 2.76562 160.876H6.76562ZM6.0208 161.307C5.65101 161.307 5.42537 161.114 5.3466 161.016C5.27077 160.921 5.27597 160.865 5.27597 160.876H9.27597C9.27597 159.344 8.21198 157.307 6.0208 157.307V161.307Z" fill="#2E72D2" mask="url(#path-12-inside-6_452_45802)"/>
      </g>
      <g id="Ellipse 613">
      <mask id="path-14-inside-7_452_45802" fill="white">
      <ellipse cx="6.0208" cy="82.4264" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 82.4264C5.27597 82.4373 5.27077 82.3812 5.3466 82.2864C5.42537 82.188 5.65101 81.9954 6.0208 81.9954V85.9954C8.21198 85.9954 9.27597 83.9579 9.27597 82.4264H5.27597ZM6.0208 81.9954C6.39059 81.9954 6.61622 82.188 6.69499 82.2864C6.77083 82.3812 6.76562 82.4373 6.76562 82.4264H2.76562C2.76562 83.9579 3.82962 85.9954 6.0208 85.9954V81.9954ZM6.76562 82.4264C6.76562 82.4154 6.77083 82.4716 6.69499 82.5664C6.61622 82.6648 6.39059 82.8574 6.0208 82.8574V78.8574C3.82962 78.8574 2.76562 80.8948 2.76562 82.4264H6.76562ZM6.0208 82.8574C5.65101 82.8574 5.42537 82.6648 5.3466 82.5664C5.27077 82.4716 5.27597 82.4154 5.27597 82.4264H9.27597C9.27597 80.8948 8.21198 78.8574 6.0208 78.8574V82.8574Z" fill="#2E72D2" mask="url(#path-14-inside-7_452_45802)"/>
      </g>
      <g id="Ellipse 617">
      <mask id="path-16-inside-8_452_45802" fill="white">
      <ellipse cx="6.0208" cy="99.9986" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 99.9987C5.27597 100.01 5.27077 99.9535 5.3466 99.8587C5.42537 99.7602 5.65101 99.5676 6.0208 99.5676V103.568C8.21198 103.568 9.27597 101.53 9.27597 99.9987H5.27597ZM6.0208 99.5676C6.39059 99.5676 6.61622 99.7602 6.69499 99.8587C6.77083 99.9535 6.76562 100.01 6.76562 99.9987H2.76562C2.76562 101.53 3.82962 103.568 6.0208 103.568V99.5676ZM6.76562 99.9987C6.76562 99.9877 6.77083 100.044 6.69499 100.139C6.61622 100.237 6.39059 100.43 6.0208 100.43V96.4297C3.82962 96.4297 2.76562 98.4671 2.76562 99.9987H6.76562ZM6.0208 100.43C5.65101 100.43 5.42537 100.237 5.3466 100.139C5.27077 100.044 5.27597 99.9877 5.27597 99.9987H9.27597C9.27597 98.4671 8.21198 96.4297 6.0208 96.4297V100.43Z" fill="#2E72D2" mask="url(#path-16-inside-8_452_45802)"/>
      </g>
      <g id="Ellipse 623">
      <mask id="path-18-inside-9_452_45802" fill="white">
      <ellipse cx="6.0208" cy="117.571" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 117.571C5.27597 117.582 5.27077 117.526 5.3466 117.431C5.42537 117.332 5.65101 117.14 6.0208 117.14V121.14C8.21198 121.14 9.27597 119.102 9.27597 117.571H5.27597ZM6.0208 117.14C6.39059 117.14 6.61622 117.332 6.69499 117.431C6.77083 117.526 6.76562 117.582 6.76562 117.571H2.76562C2.76562 119.102 3.82962 121.14 6.0208 121.14V117.14ZM6.76562 117.571C6.76562 117.56 6.77083 117.616 6.69499 117.711C6.61622 117.809 6.39059 118.002 6.0208 118.002V114.002C3.82962 114.002 2.76562 116.039 2.76562 117.571H6.76562ZM6.0208 118.002C5.65101 118.002 5.42537 117.809 5.3466 117.711C5.27077 117.616 5.27597 117.56 5.27597 117.571H9.27597C9.27597 116.039 8.21198 114.002 6.0208 114.002V118.002Z" fill="#2E72D2" mask="url(#path-18-inside-9_452_45802)"/>
      </g>
      <g id="Ellipse 629">
      <mask id="path-20-inside-10_452_45802" fill="white">
      <ellipse cx="6.0208" cy="135.772" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 135.772C5.27597 135.783 5.27077 135.727 5.3466 135.632C5.42537 135.534 5.65101 135.341 6.0208 135.341V139.341C8.21198 139.341 9.27597 137.304 9.27597 135.772H5.27597ZM6.0208 135.341C6.39059 135.341 6.61622 135.534 6.69499 135.632C6.77083 135.727 6.76562 135.783 6.76562 135.772H2.76562C2.76562 137.304 3.82962 139.341 6.0208 139.341V135.341ZM6.76562 135.772C6.76562 135.761 6.77083 135.817 6.69499 135.912C6.61622 136.011 6.39059 136.203 6.0208 136.203V132.203C3.82962 132.203 2.76562 134.241 2.76562 135.772H6.76562ZM6.0208 136.203C5.65101 136.203 5.42537 136.011 5.3466 135.912C5.27077 135.817 5.27597 135.761 5.27597 135.772H9.27597C9.27597 134.241 8.21198 132.203 6.0208 132.203V136.203Z" fill="#2E72D2" mask="url(#path-20-inside-10_452_45802)"/>
      </g>
      <g id="Ellipse 635">
      <mask id="path-22-inside-11_452_45802" fill="white">
      <ellipse cx="6.0208" cy="153.344" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 153.344C5.27597 153.355 5.27077 153.299 5.3466 153.204C5.42537 153.106 5.65101 152.913 6.0208 152.913V156.913C8.21198 156.913 9.27597 154.876 9.27597 153.344H5.27597ZM6.0208 152.913C6.39059 152.913 6.61622 153.106 6.69499 153.204C6.77083 153.299 6.76562 153.355 6.76562 153.344H2.76562C2.76562 154.876 3.82962 156.913 6.0208 156.913V152.913ZM6.76562 153.344C6.76562 153.333 6.77083 153.39 6.69499 153.484C6.61622 153.583 6.39059 153.775 6.0208 153.775V149.775C3.82962 149.775 2.76562 151.813 2.76562 153.344H6.76562ZM6.0208 153.775C5.65101 153.775 5.42537 153.583 5.3466 153.484C5.27077 153.39 5.27597 153.333 5.27597 153.344H9.27597C9.27597 151.813 8.21198 149.775 6.0208 149.775V153.775Z" fill="#2E72D2" mask="url(#path-22-inside-11_452_45802)"/>
      </g>
      <g id="Ellipse 641">
      <mask id="path-24-inside-12_452_45802" fill="white">
      <ellipse cx="6.0208" cy="170.917" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 170.917C5.27597 170.928 5.27077 170.871 5.3466 170.777C5.42537 170.678 5.65101 170.486 6.0208 170.486V174.486C8.21198 174.486 9.27597 172.448 9.27597 170.917H5.27597ZM6.0208 170.486C6.39059 170.486 6.61622 170.678 6.69499 170.777C6.77083 170.871 6.76562 170.928 6.76562 170.917H2.76562C2.76562 172.448 3.82962 174.486 6.0208 174.486V170.486ZM6.76562 170.917C6.76562 170.906 6.77083 170.962 6.69499 171.057C6.61622 171.155 6.39059 171.348 6.0208 171.348V167.348C3.82962 167.348 2.76562 169.385 2.76562 170.917H6.76562ZM6.0208 171.348C5.65101 171.348 5.42537 171.155 5.3466 171.057C5.27077 170.962 5.27597 170.906 5.27597 170.917H9.27597C9.27597 169.385 8.21198 167.348 6.0208 167.348V171.348Z" fill="#2E72D2" mask="url(#path-24-inside-12_452_45802)"/>
      </g>
      <g id="Ellipse 611">
      <mask id="path-26-inside-13_452_45802" fill="white">
      <ellipse cx="6.0208" cy="76.7779" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 76.7779C5.27597 76.7889 5.27077 76.7328 5.3466 76.638C5.42537 76.5395 5.65101 76.3469 6.0208 76.3469V80.3469C8.21198 80.3469 9.27597 78.3095 9.27597 76.7779H5.27597ZM6.0208 76.3469C6.39059 76.3469 6.61622 76.5395 6.69499 76.638C6.77083 76.7328 6.76562 76.7889 6.76562 76.7779H2.76562C2.76562 78.3095 3.82962 80.3469 6.0208 80.3469V76.3469ZM6.76562 76.7779C6.76562 76.767 6.77083 76.8231 6.69499 76.9179C6.61622 77.0164 6.39059 77.209 6.0208 77.209V73.209C3.82962 73.209 2.76562 75.2464 2.76562 76.7779H6.76562ZM6.0208 77.209C5.65101 77.209 5.42537 77.0164 5.3466 76.9179C5.27077 76.8231 5.27597 76.767 5.27597 76.7779H9.27597C9.27597 75.2464 8.21198 73.209 6.0208 73.209V77.209Z" fill="#2E72D2" mask="url(#path-26-inside-13_452_45802)"/>
      </g>
      <g id="Ellipse 618">
      <mask id="path-28-inside-14_452_45802" fill="white">
      <ellipse cx="6.0208" cy="94.9791" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 94.9791C5.27597 94.9901 5.27077 94.9339 5.3466 94.8392C5.42537 94.7407 5.65101 94.5481 6.0208 94.5481V98.5481C8.21198 98.5481 9.27597 96.5107 9.27597 94.9791H5.27597ZM6.0208 94.5481C6.39059 94.5481 6.61622 94.7407 6.69499 94.8392C6.77083 94.9339 6.76562 94.9901 6.76562 94.9791H2.76562C2.76562 96.5107 3.82962 98.5481 6.0208 98.5481V94.5481ZM6.76562 94.9791C6.76562 94.9682 6.77083 95.0243 6.69499 95.1191C6.61622 95.2176 6.39059 95.4102 6.0208 95.4102V91.4102C3.82962 91.4102 2.76562 93.4476 2.76562 94.9791H6.76562ZM6.0208 95.4102C5.65101 95.4102 5.42537 95.2176 5.3466 95.1191C5.27077 95.0243 5.27597 94.9682 5.27597 94.9791H9.27597C9.27597 93.4476 8.21198 91.4102 6.0208 91.4102V95.4102Z" fill="#2E72D2" mask="url(#path-28-inside-14_452_45802)"/>
      </g>
      <g id="Ellipse 624">
      <mask id="path-30-inside-15_452_45802" fill="white">
      <ellipse cx="6.0208" cy="111.922" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 111.922C5.27597 111.933 5.27077 111.877 5.3466 111.783C5.42537 111.684 5.65101 111.491 6.0208 111.491V115.491C8.21198 115.491 9.27597 113.454 9.27597 111.922H5.27597ZM6.0208 111.491C6.39059 111.491 6.61622 111.684 6.69499 111.783C6.77083 111.877 6.76562 111.933 6.76562 111.922H2.76562C2.76562 113.454 3.82962 115.491 6.0208 115.491V111.491ZM6.76562 111.922C6.76562 111.912 6.77083 111.968 6.69499 112.062C6.61622 112.161 6.39059 112.354 6.0208 112.354V108.354C3.82962 108.354 2.76562 110.391 2.76562 111.922H6.76562ZM6.0208 112.354C5.65101 112.354 5.42537 112.161 5.3466 112.062C5.27077 111.968 5.27597 111.912 5.27597 111.922H9.27597C9.27597 110.391 8.21198 108.354 6.0208 108.354V112.354Z" fill="#2E72D2" mask="url(#path-30-inside-15_452_45802)"/>
      </g>
      <g id="Ellipse 630">
      <mask id="path-32-inside-16_452_45802" fill="white">
      <ellipse cx="6.0208" cy="130.124" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 130.124C5.27597 130.135 5.27077 130.078 5.3466 129.984C5.42537 129.885 5.65101 129.693 6.0208 129.693V133.693C8.21198 133.693 9.27597 131.655 9.27597 130.124H5.27597ZM6.0208 129.693C6.39059 129.693 6.61622 129.885 6.69499 129.984C6.77083 130.078 6.76562 130.135 6.76562 130.124H2.76562C2.76562 131.655 3.82962 133.693 6.0208 133.693V129.693ZM6.76562 130.124C6.76562 130.113 6.77083 130.169 6.69499 130.264C6.61622 130.362 6.39059 130.555 6.0208 130.555V126.555C3.82962 126.555 2.76562 128.592 2.76562 130.124H6.76562ZM6.0208 130.555C5.65101 130.555 5.42537 130.362 5.3466 130.264C5.27077 130.169 5.27597 130.113 5.27597 130.124H9.27597C9.27597 128.592 8.21198 126.555 6.0208 126.555V130.555Z" fill="#2E72D2" mask="url(#path-32-inside-16_452_45802)"/>
      </g>
      <g id="Ellipse 636">
      <mask id="path-34-inside-17_452_45802" fill="white">
      <ellipse cx="6.0208" cy="148.323" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 148.323C5.27597 148.334 5.27077 148.278 5.3466 148.183C5.42537 148.084 5.65101 147.892 6.0208 147.892V151.892C8.21198 151.892 9.27597 149.854 9.27597 148.323H5.27597ZM6.0208 147.892C6.39059 147.892 6.61622 148.084 6.69499 148.183C6.77083 148.278 6.76562 148.334 6.76562 148.323H2.76562C2.76562 149.854 3.82962 151.892 6.0208 151.892V147.892ZM6.76562 148.323C6.76562 148.312 6.77083 148.368 6.69499 148.463C6.61622 148.561 6.39059 148.754 6.0208 148.754V144.754C3.82962 144.754 2.76562 146.791 2.76562 148.323H6.76562ZM6.0208 148.754C5.65101 148.754 5.42537 148.561 5.3466 148.463C5.27077 148.368 5.27597 148.312 5.27597 148.323H9.27597C9.27597 146.791 8.21198 144.754 6.0208 144.754V148.754Z" fill="#2E72D2" mask="url(#path-34-inside-17_452_45802)"/>
      </g>
      <g id="Ellipse 642">
      <mask id="path-36-inside-18_452_45802" fill="white">
      <ellipse cx="6.0208" cy="165.895" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M5.27597 165.895C5.27597 165.906 5.27077 165.85 5.3466 165.755C5.42537 165.657 5.65101 165.464 6.0208 165.464V169.464C8.21198 169.464 9.27597 167.427 9.27597 165.895H5.27597ZM6.0208 165.464C6.39059 165.464 6.61622 165.657 6.69499 165.755C6.77083 165.85 6.76562 165.906 6.76562 165.895H2.76562C2.76562 167.427 3.82962 169.464 6.0208 169.464V165.464ZM6.76562 165.895C6.76562 165.884 6.77083 165.94 6.69499 166.035C6.61622 166.134 6.39059 166.326 6.0208 166.326V162.326C3.82962 162.326 2.76562 164.364 2.76562 165.895H6.76562ZM6.0208 166.326C5.65101 166.326 5.42537 166.134 5.3466 166.035C5.27077 165.94 5.27597 165.884 5.27597 165.895H9.27597C9.27597 164.364 8.21198 162.326 6.0208 162.326V166.326Z" fill="#2E72D2" mask="url(#path-36-inside-18_452_45802)"/>
      </g>
      <g id="Ellipse 610">
      <mask id="path-38-inside-19_452_45802" fill="white">
      <ellipse cx="13.552" cy="73.6412" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 73.6412C12.8072 73.6522 12.802 73.5961 12.8779 73.5013C12.9566 73.4028 13.1823 73.2102 13.552 73.2102V77.2102C15.7432 77.2102 16.8072 75.1728 16.8072 73.6412H12.8072ZM13.552 73.2102C13.9218 73.2102 14.1475 73.4028 14.2262 73.5013C14.3021 73.5961 14.2969 73.6522 14.2969 73.6412H10.2969C10.2969 75.1728 11.3609 77.2102 13.552 77.2102V73.2102ZM14.2969 73.6412C14.2969 73.6303 14.3021 73.6864 14.2262 73.7812C14.1475 73.8797 13.9218 74.0723 13.552 74.0723V70.0723C11.3609 70.0723 10.2969 72.1097 10.2969 73.6412H14.2969ZM13.552 74.0723C13.1823 74.0723 12.9566 73.8797 12.8779 73.7812C12.802 73.6864 12.8072 73.6303 12.8072 73.6412H16.8072C16.8072 72.1097 15.7432 70.0723 13.552 70.0723V74.0723Z" fill="#2E72D2" mask="url(#path-38-inside-19_452_45802)"/>
      </g>
      <g id="Ellipse 619">
      <mask id="path-40-inside-20_452_45802" fill="white">
      <ellipse cx="13.552" cy="91.8404" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 91.8404C12.8072 91.8514 12.802 91.7953 12.8779 91.7005C12.9566 91.602 13.1823 91.4094 13.552 91.4094V95.4094C15.7432 95.4094 16.8072 93.372 16.8072 91.8404H12.8072ZM13.552 91.4094C13.9218 91.4094 14.1475 91.602 14.2262 91.7005C14.3021 91.7953 14.2969 91.8514 14.2969 91.8404H10.2969C10.2969 93.372 11.3609 95.4094 13.552 95.4094V91.4094ZM14.2969 91.8404C14.2969 91.8295 14.3021 91.8856 14.2262 91.9804C14.1475 92.0789 13.9218 92.2715 13.552 92.2715V88.2715C11.3609 88.2715 10.2969 90.3089 10.2969 91.8404H14.2969ZM13.552 92.2715C13.1823 92.2715 12.9566 92.0789 12.8779 91.9804C12.802 91.8856 12.8072 91.8295 12.8072 91.8404H16.8072C16.8072 90.3089 15.7432 88.2715 13.552 88.2715V92.2715Z" fill="#2E72D2" mask="url(#path-40-inside-20_452_45802)"/>
      </g>
      <g id="Ellipse 625">
      <mask id="path-42-inside-21_452_45802" fill="white">
      <ellipse cx="13.552" cy="108.786" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 108.786C12.8072 108.797 12.802 108.741 12.8779 108.646C12.9566 108.547 13.1823 108.355 13.552 108.355V112.355C15.7432 112.355 16.8072 110.317 16.8072 108.786H12.8072ZM13.552 108.355C13.9218 108.355 14.1475 108.547 14.2262 108.646C14.3021 108.741 14.2969 108.797 14.2969 108.786H10.2969C10.2969 110.317 11.3609 112.355 13.552 112.355V108.355ZM14.2969 108.786C14.2969 108.775 14.3021 108.831 14.2262 108.926C14.1475 109.024 13.9218 109.217 13.552 109.217V105.217C11.3609 105.217 10.2969 107.254 10.2969 108.786H14.2969ZM13.552 109.217C13.1823 109.217 12.9566 109.024 12.8779 108.926C12.802 108.831 12.8072 108.775 12.8072 108.786H16.8072C16.8072 107.254 15.7432 105.217 13.552 105.217V109.217Z" fill="#2E72D2" mask="url(#path-42-inside-21_452_45802)"/>
      </g>
      <g id="Ellipse 631">
      <mask id="path-44-inside-22_452_45802" fill="white">
      <ellipse cx="13.552" cy="126.985" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 126.985C12.8072 126.996 12.802 126.94 12.8779 126.845C12.9566 126.747 13.1823 126.554 13.552 126.554V130.554C15.7432 130.554 16.8072 128.517 16.8072 126.985H12.8072ZM13.552 126.554C13.9218 126.554 14.1475 126.747 14.2262 126.845C14.3021 126.94 14.2969 126.996 14.2969 126.985H10.2969C10.2969 128.517 11.3609 130.554 13.552 130.554V126.554ZM14.2969 126.985C14.2969 126.974 14.3021 127.03 14.2262 127.125C14.1475 127.223 13.9218 127.416 13.552 127.416V123.416C11.3609 123.416 10.2969 125.453 10.2969 126.985H14.2969ZM13.552 127.416C13.1823 127.416 12.9566 127.223 12.8779 127.125C12.802 127.03 12.8072 126.974 12.8072 126.985H16.8072C16.8072 125.453 15.7432 123.416 13.552 123.416V127.416Z" fill="#2E72D2" mask="url(#path-44-inside-22_452_45802)"/>
      </g>
      <g id="Ellipse 637">
      <mask id="path-46-inside-23_452_45802" fill="white">
      <ellipse cx="13.552" cy="145.186" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 145.186C12.8072 145.197 12.802 145.141 12.8779 145.046C12.9566 144.948 13.1823 144.755 13.552 144.755V148.755C15.7432 148.755 16.8072 146.718 16.8072 145.186H12.8072ZM13.552 144.755C13.9218 144.755 14.1475 144.948 14.2262 145.046C14.3021 145.141 14.2969 145.197 14.2969 145.186H10.2969C10.2969 146.718 11.3609 148.755 13.552 148.755V144.755ZM14.2969 145.186C14.2969 145.175 14.3021 145.231 14.2262 145.326C14.1475 145.425 13.9218 145.617 13.552 145.617V141.617C11.3609 141.617 10.2969 143.655 10.2969 145.186H14.2969ZM13.552 145.617C13.1823 145.617 12.9566 145.425 12.8779 145.326C12.802 145.231 12.8072 145.175 12.8072 145.186H16.8072C16.8072 143.655 15.7432 141.617 13.552 141.617V145.617Z" fill="#2E72D2" mask="url(#path-46-inside-23_452_45802)"/>
      </g>
      <g id="Ellipse 643">
      <mask id="path-48-inside-24_452_45802" fill="white">
      <ellipse cx="13.552" cy="163.385" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 163.385C12.8072 163.396 12.802 163.34 12.8779 163.245C12.9566 163.147 13.1823 162.954 13.552 162.954V166.954C15.7432 166.954 16.8072 164.917 16.8072 163.385H12.8072ZM13.552 162.954C13.9218 162.954 14.1475 163.147 14.2262 163.245C14.3021 163.34 14.2969 163.396 14.2969 163.385H10.2969C10.2969 164.917 11.3609 166.954 13.552 166.954V162.954ZM14.2969 163.385C14.2969 163.374 14.3021 163.431 14.2262 163.525C14.1475 163.624 13.9218 163.816 13.552 163.816V159.816C11.3609 159.816 10.2969 161.854 10.2969 163.385H14.2969ZM13.552 163.816C13.1823 163.816 12.9566 163.624 12.8779 163.525C12.802 163.431 12.8072 163.374 12.8072 163.385H16.8072C16.8072 161.854 15.7432 159.816 13.552 159.816V163.816Z" fill="#2E72D2" mask="url(#path-48-inside-24_452_45802)"/>
      </g>
      <g id="Ellipse 615">
      <mask id="path-50-inside-25_452_45802" fill="white">
      <ellipse cx="13.552" cy="83.6822" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 83.6822C12.8072 83.6932 12.802 83.6371 12.8779 83.5423C12.9566 83.4438 13.1823 83.2512 13.552 83.2512V87.2512C15.7432 87.2512 16.8072 85.2138 16.8072 83.6822H12.8072ZM13.552 83.2512C13.9218 83.2512 14.1475 83.4438 14.2262 83.5423C14.3021 83.6371 14.2969 83.6932 14.2969 83.6822H10.2969C10.2969 85.2138 11.3609 87.2512 13.552 87.2512V83.2512ZM14.2969 83.6822C14.2969 83.6713 14.3021 83.7274 14.2262 83.8222C14.1475 83.9207 13.9218 84.1133 13.552 84.1133V80.1133C11.3609 80.1133 10.2969 82.1507 10.2969 83.6822H14.2969ZM13.552 84.1133C13.1823 84.1133 12.9566 83.9207 12.8779 83.8222C12.802 83.7274 12.8072 83.6713 12.8072 83.6822H16.8072C16.8072 82.1507 15.7432 80.1133 13.552 80.1133V84.1133Z" fill="#2E72D2" mask="url(#path-50-inside-25_452_45802)"/>
      </g>
      <g id="Ellipse 620">
      <mask id="path-52-inside-26_452_45802" fill="white">
      <ellipse cx="13.552" cy="101.881" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 101.881C12.8072 101.892 12.802 101.836 12.8779 101.742C12.9566 101.643 13.1823 101.45 13.552 101.45V105.45C15.7432 105.45 16.8072 103.413 16.8072 101.881H12.8072ZM13.552 101.45C13.9218 101.45 14.1475 101.643 14.2262 101.742C14.3021 101.836 14.2969 101.892 14.2969 101.881H10.2969C10.2969 103.413 11.3609 105.45 13.552 105.45V101.45ZM14.2969 101.881C14.2969 101.871 14.3021 101.927 14.2262 102.021C14.1475 102.12 13.9218 102.312 13.552 102.312V98.3125C11.3609 98.3125 10.2969 100.35 10.2969 101.881H14.2969ZM13.552 102.312C13.1823 102.312 12.9566 102.12 12.8779 102.021C12.802 101.927 12.8072 101.871 12.8072 101.881H16.8072C16.8072 100.35 15.7432 98.3125 13.552 98.3125V102.312Z" fill="#2E72D2" mask="url(#path-52-inside-26_452_45802)"/>
      </g>
      <g id="Ellipse 626">
      <mask id="path-54-inside-27_452_45802" fill="white">
      <ellipse cx="13.552" cy="119.454" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 119.454C12.8072 119.465 12.802 119.409 12.8779 119.314C12.9566 119.215 13.1823 119.023 13.552 119.023V123.023C15.7432 123.023 16.8072 120.985 16.8072 119.454H12.8072ZM13.552 119.023C13.9218 119.023 14.1475 119.215 14.2262 119.314C14.3021 119.409 14.2969 119.465 14.2969 119.454H10.2969C10.2969 120.985 11.3609 123.023 13.552 123.023V119.023ZM14.2969 119.454C14.2969 119.443 14.3021 119.499 14.2262 119.594C14.1475 119.692 13.9218 119.885 13.552 119.885V115.885C11.3609 115.885 10.2969 117.922 10.2969 119.454H14.2969ZM13.552 119.885C13.1823 119.885 12.9566 119.692 12.8779 119.594C12.802 119.499 12.8072 119.443 12.8072 119.454H16.8072C16.8072 117.922 15.7432 115.885 13.552 115.885V119.885Z" fill="#2E72D2" mask="url(#path-54-inside-27_452_45802)"/>
      </g>
      <g id="Ellipse 632">
      <mask id="path-56-inside-28_452_45802" fill="white">
      <ellipse cx="13.552" cy="137.655" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 137.655C12.8072 137.666 12.802 137.61 12.8779 137.515C12.9566 137.416 13.1823 137.224 13.552 137.224V141.224C15.7432 141.224 16.8072 139.186 16.8072 137.655H12.8072ZM13.552 137.224C13.9218 137.224 14.1475 137.416 14.2262 137.515C14.3021 137.61 14.2969 137.666 14.2969 137.655H10.2969C10.2969 139.186 11.3609 141.224 13.552 141.224V137.224ZM14.2969 137.655C14.2969 137.644 14.3021 137.7 14.2262 137.795C14.1475 137.893 13.9218 138.086 13.552 138.086V134.086C11.3609 134.086 10.2969 136.123 10.2969 137.655H14.2969ZM13.552 138.086C13.1823 138.086 12.9566 137.893 12.8779 137.795C12.802 137.7 12.8072 137.644 12.8072 137.655H16.8072C16.8072 136.123 15.7432 134.086 13.552 134.086V138.086Z" fill="#2E72D2" mask="url(#path-56-inside-28_452_45802)"/>
      </g>
      <g id="Ellipse 638">
      <mask id="path-58-inside-29_452_45802" fill="white">
      <ellipse cx="13.552" cy="155.227" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 155.227C12.8072 155.238 12.802 155.182 12.8779 155.087C12.9566 154.989 13.1823 154.796 13.552 154.796V158.796C15.7432 158.796 16.8072 156.759 16.8072 155.227H12.8072ZM13.552 154.796C13.9218 154.796 14.1475 154.989 14.2262 155.087C14.3021 155.182 14.2969 155.238 14.2969 155.227H10.2969C10.2969 156.759 11.3609 158.796 13.552 158.796V154.796ZM14.2969 155.227C14.2969 155.216 14.3021 155.272 14.2262 155.367C14.1475 155.466 13.9218 155.658 13.552 155.658V151.658C11.3609 151.658 10.2969 153.696 10.2969 155.227H14.2969ZM13.552 155.658C13.1823 155.658 12.9566 155.466 12.8779 155.367C12.802 155.272 12.8072 155.216 12.8072 155.227H16.8072C16.8072 153.696 15.7432 151.658 13.552 151.658V155.658Z" fill="#2E72D2" mask="url(#path-58-inside-29_452_45802)"/>
      </g>
      <g id="Ellipse 644">
      <mask id="path-60-inside-30_452_45802" fill="white">
      <ellipse cx="13.552" cy="173.426" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 173.426C12.8072 173.437 12.802 173.381 12.8779 173.286C12.9566 173.188 13.1823 172.995 13.552 172.995V176.995C15.7432 176.995 16.8072 174.958 16.8072 173.426H12.8072ZM13.552 172.995C13.9218 172.995 14.1475 173.188 14.2262 173.286C14.3021 173.381 14.2969 173.437 14.2969 173.426H10.2969C10.2969 174.958 11.3609 176.995 13.552 176.995V172.995ZM14.2969 173.426C14.2969 173.415 14.3021 173.472 14.2262 173.566C14.1475 173.665 13.9218 173.857 13.552 173.857V169.857C11.3609 169.857 10.2969 171.895 10.2969 173.426H14.2969ZM13.552 173.857C13.1823 173.857 12.9566 173.665 12.8779 173.566C12.802 173.472 12.8072 173.415 12.8072 173.426H16.8072C16.8072 171.895 15.7432 169.857 13.552 169.857V173.857Z" fill="#2E72D2" mask="url(#path-60-inside-30_452_45802)"/>
      </g>
      <g id="Ellipse 612">
      <mask id="path-62-inside-31_452_45802" fill="white">
      <ellipse cx="13.552" cy="78.6608" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 78.6608C12.8072 78.6717 12.802 78.6156 12.8779 78.5208C12.9566 78.4223 13.1823 78.2297 13.552 78.2297V82.2297C15.7432 82.2297 16.8072 80.1923 16.8072 78.6608H12.8072ZM13.552 78.2297C13.9218 78.2297 14.1475 78.4223 14.2262 78.5208C14.3021 78.6156 14.2969 78.6717 14.2969 78.6608H10.2969C10.2969 80.1923 11.3609 82.2297 13.552 82.2297V78.2297ZM14.2969 78.6608C14.2969 78.6498 14.3021 78.7059 14.2262 78.8007C14.1475 78.8992 13.9218 79.0918 13.552 79.0918V75.0918C11.3609 75.0918 10.2969 77.1292 10.2969 78.6608H14.2969ZM13.552 79.0918C13.1823 79.0918 12.9566 78.8992 12.8779 78.8007C12.802 78.7059 12.8072 78.6498 12.8072 78.6608H16.8072C16.8072 77.1292 15.7432 75.0918 13.552 75.0918V79.0918Z" fill="#2E72D2" mask="url(#path-62-inside-31_452_45802)"/>
      </g>
      <g id="Ellipse 621">
      <mask id="path-64-inside-32_452_45802" fill="white">
      <ellipse cx="13.552" cy="96.8619" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 96.8619C12.8072 96.8729 12.802 96.8168 12.8779 96.722C12.9566 96.6235 13.1823 96.4309 13.552 96.4309V100.431C15.7432 100.431 16.8072 98.3935 16.8072 96.8619H12.8072ZM13.552 96.4309C13.9218 96.4309 14.1475 96.6235 14.2262 96.722C14.3021 96.8168 14.2969 96.8729 14.2969 96.8619H10.2969C10.2969 98.3935 11.3609 100.431 13.552 100.431V96.4309ZM14.2969 96.8619C14.2969 96.851 14.3021 96.9071 14.2262 97.0019C14.1475 97.1004 13.9218 97.293 13.552 97.293V93.293C11.3609 93.293 10.2969 95.3304 10.2969 96.8619H14.2969ZM13.552 97.293C13.1823 97.293 12.9566 97.1004 12.8779 97.0019C12.802 96.9071 12.8072 96.851 12.8072 96.8619H16.8072C16.8072 95.3304 15.7432 93.293 13.552 93.293V97.293Z" fill="#2E72D2" mask="url(#path-64-inside-32_452_45802)"/>
      </g>
      <g id="Ellipse 627">
      <mask id="path-66-inside-33_452_45802" fill="white">
      <ellipse cx="13.552" cy="113.805" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 113.805C12.8072 113.816 12.802 113.76 12.8779 113.665C12.9566 113.567 13.1823 113.374 13.552 113.374V117.374C15.7432 117.374 16.8072 115.337 16.8072 113.805H12.8072ZM13.552 113.374C13.9218 113.374 14.1475 113.567 14.2262 113.665C14.3021 113.76 14.2969 113.816 14.2969 113.805H10.2969C10.2969 115.337 11.3609 117.374 13.552 117.374V113.374ZM14.2969 113.805C14.2969 113.794 14.3021 113.85 14.2262 113.945C14.1475 114.044 13.9218 114.236 13.552 114.236V110.236C11.3609 110.236 10.2969 112.274 10.2969 113.805H14.2969ZM13.552 114.236C13.1823 114.236 12.9566 114.044 12.8779 113.945C12.802 113.85 12.8072 113.794 12.8072 113.805H16.8072C16.8072 112.274 15.7432 110.236 13.552 110.236V114.236Z" fill="#2E72D2" mask="url(#path-66-inside-33_452_45802)"/>
      </g>
      <g id="Ellipse 633">
      <mask id="path-68-inside-34_452_45802" fill="white">
      <ellipse cx="13.552" cy="132.006" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 132.006C12.8072 132.017 12.802 131.961 12.8779 131.867C12.9566 131.768 13.1823 131.575 13.552 131.575V135.575C15.7432 135.575 16.8072 133.538 16.8072 132.006H12.8072ZM13.552 131.575C13.9218 131.575 14.1475 131.768 14.2262 131.867C14.3021 131.961 14.2969 132.017 14.2969 132.006H10.2969C10.2969 133.538 11.3609 135.575 13.552 135.575V131.575ZM14.2969 132.006C14.2969 131.996 14.3021 132.052 14.2262 132.146C14.1475 132.245 13.9218 132.438 13.552 132.438V128.438C11.3609 128.438 10.2969 130.475 10.2969 132.006H14.2969ZM13.552 132.438C13.1823 132.438 12.9566 132.245 12.8779 132.146C12.802 132.052 12.8072 131.996 12.8072 132.006H16.8072C16.8072 130.475 15.7432 128.438 13.552 128.438V132.438Z" fill="#2E72D2" mask="url(#path-68-inside-34_452_45802)"/>
      </g>
      <g id="Ellipse 639">
      <mask id="path-70-inside-35_452_45802" fill="white">
      <ellipse cx="13.552" cy="150.206" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 150.206C12.8072 150.217 12.802 150.161 12.8779 150.066C12.9566 149.967 13.1823 149.775 13.552 149.775V153.775C15.7432 153.775 16.8072 151.737 16.8072 150.206H12.8072ZM13.552 149.775C13.9218 149.775 14.1475 149.967 14.2262 150.066C14.3021 150.161 14.2969 150.217 14.2969 150.206H10.2969C10.2969 151.737 11.3609 153.775 13.552 153.775V149.775ZM14.2969 150.206C14.2969 150.195 14.3021 150.251 14.2262 150.346C14.1475 150.444 13.9218 150.637 13.552 150.637V146.637C11.3609 146.637 10.2969 148.674 10.2969 150.206H14.2969ZM13.552 150.637C13.1823 150.637 12.9566 150.444 12.8779 150.346C12.802 150.251 12.8072 150.195 12.8072 150.206H16.8072C16.8072 148.674 15.7432 146.637 13.552 146.637V150.637Z" fill="#2E72D2" mask="url(#path-70-inside-35_452_45802)"/>
      </g>
      <g id="Ellipse 645">
      <mask id="path-72-inside-36_452_45802" fill="white">
      <ellipse cx="13.552" cy="168.407" rx="1.25517" ry="1.56897"/>
      </mask>
      <path d="M12.8072 168.407C12.8072 168.418 12.802 168.362 12.8779 168.267C12.9566 168.168 13.1823 167.976 13.552 167.976V171.976C15.7432 171.976 16.8072 169.938 16.8072 168.407H12.8072ZM13.552 167.976C13.9218 167.976 14.1475 168.168 14.2262 168.267C14.3021 168.362 14.2969 168.418 14.2969 168.407H10.2969C10.2969 169.938 11.3609 171.976 13.552 171.976V167.976ZM14.2969 168.407C14.2969 168.396 14.3021 168.452 14.2262 168.547C14.1475 168.645 13.9218 168.838 13.552 168.838V164.838C11.3609 164.838 10.2969 166.875 10.2969 168.407H14.2969ZM13.552 168.838C13.1823 168.838 12.9566 168.645 12.8779 168.547C12.802 168.452 12.8072 168.396 12.8072 168.407H16.8072C16.8072 166.875 15.7432 164.838 13.552 164.838V168.838Z" fill="#2E72D2" mask="url(#path-72-inside-36_452_45802)"/>
      </g>
      <path id="Vector 306" d="M65.6345 1.1543L1.11125 18.1813L1 191.547L28.478 198.844L92 181.928V8.23044L65.6345 1.1543Z" stroke="#2E72D2" stroke-width="2"/>
      <path id="Rectangle 2141" d="M29.4928 197.225L29.8056 26.1361L90.1827 9.95256L90.6271 181.079L29.4928 197.225Z" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2142" d="M77.5664 22.3996C77.5664 21.5216 78.1391 20.7463 78.9782 20.488L83.1369 19.2085C84.423 18.8127 85.725 19.7744 85.725 21.12V173.18C85.725 174.088 85.1138 174.882 84.2363 175.114L80.0777 176.214C78.809 176.549 77.5664 175.592 77.5664 174.28V22.3996Z" fill="#2E72D2"/>
      <path id="Vector 310" d="M1 18.4125L28.6138 25.6297M28.6138 25.6297L91.6862 8.37109M28.6138 25.6297V198.844" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2143" d="M84.5 20C85.0199 20 85.4413 20.4215 85.4412 20.9414L85.4101 173.815L84.4688 173.501L84.5 20Z" fill="white"/>
      <path id="Rectangle 2144" d="M78.4395 175.547L84 174.268L84.8816 174.605L79.6256 176.024C79.2801 176.117 78.911 176.019 78.6579 175.766L78.4395 175.547Z" fill="white"/>
      </g>
    </svg>

   
  )
}
ScanNDS.defaultProps = {
  width: "auto"
}











export function NDSLoading({width})  {
  return(
    <svg className="NDSLoadingIcon" width={width} viewBox="0 0 120 232" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1580">
      <g id="Group 1571">
      <path id="Vector 309" d="M81.5862 2L7.36207 21.5517L7 221.414L38.8621 230.103L112 210.552V10.3276L81.5862 2Z" fill="white"/>
      <g id="Ellipse 609">
      <mask id="path-2-inside-1_458_54231" fill="white">
      <ellipse cx="12.794" cy="83.4646" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 83.4646C12.2423 83.5423 12.2154 83.5465 12.2563 83.4954C12.3002 83.4406 12.4775 83.275 12.794 83.275V87.275C15.0918 87.275 16.2423 85.1295 16.2423 83.4646H12.2423ZM12.794 83.275C13.1104 83.275 13.2878 83.4406 13.3316 83.4954C13.3725 83.5465 13.3457 83.5423 13.3457 83.4646H9.3457C9.3457 85.1295 10.4962 87.275 12.794 87.275V83.275ZM13.3457 83.4646C13.3457 83.387 13.3725 83.3828 13.3316 83.4339C13.2878 83.4887 13.1104 83.6543 12.794 83.6543V79.6543C10.4962 79.6543 9.3457 81.7998 9.3457 83.4646H13.3457ZM12.794 83.6543C12.4775 83.6543 12.3002 83.4887 12.2563 83.4339C12.2154 83.3828 12.2423 83.387 12.2423 83.4646H16.2423C16.2423 81.7998 15.0918 79.6543 12.794 79.6543V83.6543Z" fill="#2E72D2" mask="url(#path-2-inside-1_458_54231)"/>
      </g>
      <g id="Ellipse 616">
      <mask id="path-4-inside-2_458_54231" fill="white">
      <ellipse cx="12.794" cy="104.465" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 104.465C12.2423 104.542 12.2154 104.546 12.2563 104.495C12.3002 104.441 12.4775 104.275 12.794 104.275V108.275C15.0918 108.275 16.2423 106.13 16.2423 104.465H12.2423ZM12.794 104.275C13.1104 104.275 13.2878 104.441 13.3316 104.495C13.3725 104.546 13.3457 104.542 13.3457 104.465H9.3457C9.3457 106.13 10.4962 108.275 12.794 108.275V104.275ZM13.3457 104.465C13.3457 104.387 13.3725 104.383 13.3316 104.434C13.2878 104.489 13.1104 104.654 12.794 104.654V100.654C10.4962 100.654 9.3457 102.8 9.3457 104.465H13.3457ZM12.794 104.654C12.4775 104.654 12.3002 104.489 12.2563 104.434C12.2154 104.383 12.2423 104.387 12.2423 104.465H16.2423C16.2423 102.8 15.0918 100.654 12.794 100.654V104.654Z" fill="#2E72D2" mask="url(#path-4-inside-2_458_54231)"/>
      </g>
      <g id="Ellipse 622">
      <mask id="path-6-inside-3_458_54231" fill="white">
      <ellipse cx="12.794" cy="124.017" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 124.017C12.2423 124.095 12.2154 124.099 12.2563 124.048C12.3002 123.993 12.4775 123.828 12.794 123.828V127.828C15.0918 127.828 16.2423 125.682 16.2423 124.017H12.2423ZM12.794 123.828C13.1104 123.828 13.2878 123.993 13.3316 124.048C13.3725 124.099 13.3457 124.095 13.3457 124.017H9.3457C9.3457 125.682 10.4962 127.828 12.794 127.828V123.828ZM13.3457 124.017C13.3457 123.94 13.3725 123.936 13.3316 123.987C13.2878 124.041 13.1104 124.207 12.794 124.207V120.207C10.4962 120.207 9.3457 122.353 9.3457 124.017H13.3457ZM12.794 124.207C12.4775 124.207 12.3002 124.041 12.2563 123.987C12.2154 123.936 12.2423 123.94 12.2423 124.017H16.2423C16.2423 122.353 15.0918 120.207 12.794 120.207V124.207Z" fill="#2E72D2" mask="url(#path-6-inside-3_458_54231)"/>
      </g>
      <g id="Ellipse 628">
      <mask id="path-8-inside-4_458_54231" fill="white">
      <ellipse cx="12.794" cy="145.017" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 145.017C12.2423 145.095 12.2154 145.099 12.2563 145.048C12.3002 144.993 12.4775 144.828 12.794 144.828V148.828C15.0918 148.828 16.2423 146.682 16.2423 145.017H12.2423ZM12.794 144.828C13.1104 144.828 13.2878 144.993 13.3316 145.048C13.3725 145.099 13.3457 145.095 13.3457 145.017H9.3457C9.3457 146.682 10.4962 148.828 12.794 148.828V144.828ZM13.3457 145.017C13.3457 144.94 13.3725 144.936 13.3316 144.987C13.2878 145.041 13.1104 145.207 12.794 145.207V141.207C10.4962 141.207 9.3457 143.353 9.3457 145.017H13.3457ZM12.794 145.207C12.4775 145.207 12.3002 145.041 12.2563 144.987C12.2154 144.936 12.2423 144.94 12.2423 145.017H16.2423C16.2423 143.353 15.0918 141.207 12.794 141.207V145.207Z" fill="#2E72D2" mask="url(#path-8-inside-4_458_54231)"/>
      </g>
      <g id="Ellipse 634">
      <mask id="path-10-inside-5_458_54231" fill="white">
      <ellipse cx="12.794" cy="166.017" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 166.017C12.2423 166.095 12.2154 166.099 12.2563 166.048C12.3002 165.993 12.4775 165.828 12.794 165.828V169.828C15.0918 169.828 16.2423 167.682 16.2423 166.017H12.2423ZM12.794 165.828C13.1104 165.828 13.2878 165.993 13.3316 166.048C13.3725 166.099 13.3457 166.095 13.3457 166.017H9.3457C9.3457 167.682 10.4962 169.828 12.794 169.828V165.828ZM13.3457 166.017C13.3457 165.94 13.3725 165.936 13.3316 165.987C13.2878 166.041 13.1104 166.207 12.794 166.207V162.207C10.4962 162.207 9.3457 164.353 9.3457 166.017H13.3457ZM12.794 166.207C12.4775 166.207 12.3002 166.041 12.2563 165.987C12.2154 165.936 12.2423 165.94 12.2423 166.017H16.2423C16.2423 164.353 15.0918 162.207 12.794 162.207V166.207Z" fill="#2E72D2" mask="url(#path-10-inside-5_458_54231)"/>
      </g>
      <g id="Ellipse 640">
      <mask id="path-12-inside-6_458_54231" fill="white">
      <ellipse cx="12.794" cy="186.293" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 186.293C12.2423 186.37 12.2154 186.375 12.2563 186.323C12.3002 186.269 12.4775 186.103 12.794 186.103V190.103C15.0918 190.103 16.2423 187.958 16.2423 186.293H12.2423ZM12.794 186.103C13.1104 186.103 13.2878 186.269 13.3316 186.323C13.3725 186.375 13.3457 186.37 13.3457 186.293H9.3457C9.3457 187.958 10.4962 190.103 12.794 190.103V186.103ZM13.3457 186.293C13.3457 186.215 13.3725 186.211 13.3316 186.262C13.2878 186.317 13.1104 186.482 12.794 186.482V182.482C10.4962 182.482 9.3457 184.628 9.3457 186.293H13.3457ZM12.794 186.482C12.4775 186.482 12.3002 186.317 12.2563 186.262C12.2154 186.211 12.2423 186.215 12.2423 186.293H16.2423C16.2423 184.628 15.0918 182.482 12.794 182.482V186.482Z" fill="#2E72D2" mask="url(#path-12-inside-6_458_54231)"/>
      </g>
      <g id="Ellipse 613">
      <mask id="path-14-inside-7_458_54231" fill="white">
      <ellipse cx="12.794" cy="95.7752" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 95.7752C12.2423 95.8528 12.2154 95.857 12.2563 95.8059C12.3002 95.7511 12.4775 95.5855 12.794 95.5855V99.5855C15.0918 99.5855 16.2423 97.4401 16.2423 95.7752H12.2423ZM12.794 95.5855C13.1104 95.5855 13.2878 95.7511 13.3316 95.8059C13.3725 95.857 13.3457 95.8528 13.3457 95.7752H9.3457C9.3457 97.4401 10.4962 99.5855 12.794 99.5855V95.5855ZM13.3457 95.7752C13.3457 95.6976 13.3725 95.6934 13.3316 95.7445C13.2878 95.7993 13.1104 95.9648 12.794 95.9648V91.9648C10.4962 91.9648 9.3457 94.1103 9.3457 95.7752H13.3457ZM12.794 95.9648C12.4775 95.9648 12.3002 95.7993 12.2563 95.7445C12.2154 95.6934 12.2423 95.6976 12.2423 95.7752H16.2423C16.2423 94.1103 15.0918 91.9648 12.794 91.9648V95.9648Z" fill="#2E72D2" mask="url(#path-14-inside-7_458_54231)"/>
      </g>
      <g id="Ellipse 617">
      <mask id="path-16-inside-8_458_54231" fill="white">
      <ellipse cx="12.794" cy="116.051" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 116.051C12.2423 116.128 12.2154 116.132 12.2563 116.081C12.3002 116.027 12.4775 115.861 12.794 115.861V119.861C15.0918 119.861 16.2423 117.715 16.2423 116.051H12.2423ZM12.794 115.861C13.1104 115.861 13.2878 116.027 13.3316 116.081C13.3725 116.132 13.3457 116.128 13.3457 116.051H9.3457C9.3457 117.715 10.4962 119.861 12.794 119.861V115.861ZM13.3457 116.051C13.3457 115.973 13.3725 115.969 13.3316 116.02C13.2878 116.075 13.1104 116.24 12.794 116.24V112.24C10.4962 112.24 9.3457 114.386 9.3457 116.051H13.3457ZM12.794 116.24C12.4775 116.24 12.3002 116.075 12.2563 116.02C12.2154 115.969 12.2423 115.973 12.2423 116.051H16.2423C16.2423 114.386 15.0918 112.24 12.794 112.24V116.24Z" fill="#2E72D2" mask="url(#path-16-inside-8_458_54231)"/>
      </g>
      <g id="Ellipse 623">
      <mask id="path-18-inside-9_458_54231" fill="white">
      <ellipse cx="12.794" cy="136.328" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 136.328C12.2423 136.406 12.2154 136.41 12.2563 136.359C12.3002 136.304 12.4775 136.138 12.794 136.138V140.138C15.0918 140.138 16.2423 137.993 16.2423 136.328H12.2423ZM12.794 136.138C13.1104 136.138 13.2878 136.304 13.3316 136.359C13.3725 136.41 13.3457 136.406 13.3457 136.328H9.3457C9.3457 137.993 10.4962 140.138 12.794 140.138V136.138ZM13.3457 136.328C13.3457 136.25 13.3725 136.246 13.3316 136.297C13.2878 136.352 13.1104 136.518 12.794 136.518V132.518C10.4962 132.518 9.3457 134.663 9.3457 136.328H13.3457ZM12.794 136.518C12.4775 136.518 12.3002 136.352 12.2563 136.297C12.2154 136.246 12.2423 136.25 12.2423 136.328H16.2423C16.2423 134.663 15.0918 132.518 12.794 132.518V136.518Z" fill="#2E72D2" mask="url(#path-18-inside-9_458_54231)"/>
      </g>
      <g id="Ellipse 629">
      <mask id="path-20-inside-10_458_54231" fill="white">
      <ellipse cx="12.794" cy="157.328" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 157.328C12.2423 157.406 12.2154 157.41 12.2563 157.359C12.3002 157.304 12.4775 157.138 12.794 157.138V161.138C15.0918 161.138 16.2423 158.993 16.2423 157.328H12.2423ZM12.794 157.138C13.1104 157.138 13.2878 157.304 13.3316 157.359C13.3725 157.41 13.3457 157.406 13.3457 157.328H9.3457C9.3457 158.993 10.4962 161.138 12.794 161.138V157.138ZM13.3457 157.328C13.3457 157.25 13.3725 157.246 13.3316 157.297C13.2878 157.352 13.1104 157.518 12.794 157.518V153.518C10.4962 153.518 9.3457 155.663 9.3457 157.328H13.3457ZM12.794 157.518C12.4775 157.518 12.3002 157.352 12.2563 157.297C12.2154 157.246 12.2423 157.25 12.2423 157.328H16.2423C16.2423 155.663 15.0918 153.518 12.794 153.518V157.518Z" fill="#2E72D2" mask="url(#path-20-inside-10_458_54231)"/>
      </g>
      <g id="Ellipse 635">
      <mask id="path-22-inside-11_458_54231" fill="white">
      <ellipse cx="12.794" cy="177.603" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 177.603C12.2423 177.681 12.2154 177.685 12.2563 177.634C12.3002 177.579 12.4775 177.414 12.794 177.414V181.414C15.0918 181.414 16.2423 179.268 16.2423 177.603H12.2423ZM12.794 177.414C13.1104 177.414 13.2878 177.579 13.3316 177.634C13.3725 177.685 13.3457 177.681 13.3457 177.603H9.3457C9.3457 179.268 10.4962 181.414 12.794 181.414V177.414ZM13.3457 177.603C13.3457 177.526 13.3725 177.521 13.3316 177.573C13.2878 177.627 13.1104 177.793 12.794 177.793V173.793C10.4962 173.793 9.3457 175.938 9.3457 177.603H13.3457ZM12.794 177.793C12.4775 177.793 12.3002 177.627 12.2563 177.573C12.2154 177.521 12.2423 177.526 12.2423 177.603H16.2423C16.2423 175.938 15.0918 173.793 12.794 173.793V177.793Z" fill="#2E72D2" mask="url(#path-22-inside-11_458_54231)"/>
      </g>
      <g id="Ellipse 641">
      <mask id="path-24-inside-12_458_54231" fill="white">
      <ellipse cx="12.794" cy="197.879" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 197.879C12.2423 197.956 12.2154 197.961 12.2563 197.909C12.3002 197.855 12.4775 197.689 12.794 197.689V201.689C15.0918 201.689 16.2423 199.544 16.2423 197.879H12.2423ZM12.794 197.689C13.1104 197.689 13.2878 197.855 13.3316 197.909C13.3725 197.961 13.3457 197.956 13.3457 197.879H9.3457C9.3457 199.544 10.4962 201.689 12.794 201.689V197.689ZM13.3457 197.879C13.3457 197.801 13.3725 197.797 13.3316 197.848C13.2878 197.903 13.1104 198.068 12.794 198.068V194.068C10.4962 194.068 9.3457 196.214 9.3457 197.879H13.3457ZM12.794 198.068C12.4775 198.068 12.3002 197.903 12.2563 197.848C12.2154 197.797 12.2423 197.801 12.2423 197.879H16.2423C16.2423 196.214 15.0918 194.068 12.794 194.068V198.068Z" fill="#2E72D2" mask="url(#path-24-inside-12_458_54231)"/>
      </g>
      <g id="Ellipse 611">
      <mask id="path-26-inside-13_458_54231" fill="white">
      <ellipse cx="12.794" cy="89.2576" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 89.2576C12.2423 89.3352 12.2154 89.3394 12.2563 89.2883C12.3002 89.2335 12.4775 89.068 12.794 89.068V93.068C15.0918 93.068 16.2423 90.9225 16.2423 89.2576H12.2423ZM12.794 89.068C13.1104 89.068 13.2878 89.2335 13.3316 89.2883C13.3725 89.3394 13.3457 89.3352 13.3457 89.2576H9.3457C9.3457 90.9225 10.4962 93.068 12.794 93.068V89.068ZM13.3457 89.2576C13.3457 89.18 13.3725 89.1758 13.3316 89.2269C13.2878 89.2817 13.1104 89.4473 12.794 89.4473V85.4473C10.4962 85.4473 9.3457 87.5927 9.3457 89.2576H13.3457ZM12.794 89.4473C12.4775 89.4473 12.3002 89.2817 12.2563 89.2269C12.2154 89.1758 12.2423 89.18 12.2423 89.2576H16.2423C16.2423 87.5927 15.0918 85.4473 12.794 85.4473V89.4473Z" fill="#2E72D2" mask="url(#path-26-inside-13_458_54231)"/>
      </g>
      <g id="Ellipse 618">
      <mask id="path-28-inside-14_458_54231" fill="white">
      <ellipse cx="12.794" cy="110.258" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 110.258C12.2423 110.335 12.2154 110.339 12.2563 110.288C12.3002 110.234 12.4775 110.068 12.794 110.068V114.068C15.0918 114.068 16.2423 111.922 16.2423 110.258H12.2423ZM12.794 110.068C13.1104 110.068 13.2878 110.234 13.3316 110.288C13.3725 110.339 13.3457 110.335 13.3457 110.258H9.3457C9.3457 111.922 10.4962 114.068 12.794 114.068V110.068ZM13.3457 110.258C13.3457 110.18 13.3725 110.176 13.3316 110.227C13.2878 110.282 13.1104 110.447 12.794 110.447V106.447C10.4962 106.447 9.3457 108.593 9.3457 110.258H13.3457ZM12.794 110.447C12.4775 110.447 12.3002 110.282 12.2563 110.227C12.2154 110.176 12.2423 110.18 12.2423 110.258H16.2423C16.2423 108.593 15.0918 106.447 12.794 106.447V110.447Z" fill="#2E72D2" mask="url(#path-28-inside-14_458_54231)"/>
      </g>
      <g id="Ellipse 624">
      <mask id="path-30-inside-15_458_54231" fill="white">
      <ellipse cx="12.794" cy="129.81" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 129.81C12.2423 129.888 12.2154 129.892 12.2563 129.841C12.3002 129.786 12.4775 129.621 12.794 129.621V133.621C15.0918 133.621 16.2423 131.475 16.2423 129.81H12.2423ZM12.794 129.621C13.1104 129.621 13.2878 129.786 13.3316 129.841C13.3725 129.892 13.3457 129.888 13.3457 129.81H9.3457C9.3457 131.475 10.4962 133.621 12.794 133.621V129.621ZM13.3457 129.81C13.3457 129.733 13.3725 129.729 13.3316 129.78C13.2878 129.834 13.1104 130 12.794 130V126C10.4962 126 9.3457 128.145 9.3457 129.81H13.3457ZM12.794 130C12.4775 130 12.3002 129.834 12.2563 129.78C12.2154 129.729 12.2423 129.733 12.2423 129.81H16.2423C16.2423 128.145 15.0918 126 12.794 126V130Z" fill="#2E72D2" mask="url(#path-30-inside-15_458_54231)"/>
      </g>
      <g id="Ellipse 630">
      <mask id="path-32-inside-16_458_54231" fill="white">
      <ellipse cx="12.794" cy="150.81" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 150.81C12.2423 150.888 12.2154 150.892 12.2563 150.841C12.3002 150.786 12.4775 150.621 12.794 150.621V154.621C15.0918 154.621 16.2423 152.475 16.2423 150.81H12.2423ZM12.794 150.621C13.1104 150.621 13.2878 150.786 13.3316 150.841C13.3725 150.892 13.3457 150.888 13.3457 150.81H9.3457C9.3457 152.475 10.4962 154.621 12.794 154.621V150.621ZM13.3457 150.81C13.3457 150.733 13.3725 150.729 13.3316 150.78C13.2878 150.834 13.1104 151 12.794 151V147C10.4962 147 9.3457 149.145 9.3457 150.81H13.3457ZM12.794 151C12.4775 151 12.3002 150.834 12.2563 150.78C12.2154 150.729 12.2423 150.733 12.2423 150.81H16.2423C16.2423 149.145 15.0918 147 12.794 147V151Z" fill="#2E72D2" mask="url(#path-32-inside-16_458_54231)"/>
      </g>
      <g id="Ellipse 636">
      <mask id="path-34-inside-17_458_54231" fill="white">
      <ellipse cx="12.794" cy="171.81" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 171.81C12.2423 171.888 12.2154 171.892 12.2563 171.841C12.3002 171.786 12.4775 171.621 12.794 171.621V175.621C15.0918 175.621 16.2423 173.475 16.2423 171.81H12.2423ZM12.794 171.621C13.1104 171.621 13.2878 171.786 13.3316 171.841C13.3725 171.892 13.3457 171.888 13.3457 171.81H9.3457C9.3457 173.475 10.4962 175.621 12.794 175.621V171.621ZM13.3457 171.81C13.3457 171.733 13.3725 171.729 13.3316 171.78C13.2878 171.834 13.1104 172 12.794 172V168C10.4962 168 9.3457 170.145 9.3457 171.81H13.3457ZM12.794 172C12.4775 172 12.3002 171.834 12.2563 171.78C12.2154 171.729 12.2423 171.733 12.2423 171.81H16.2423C16.2423 170.145 15.0918 168 12.794 168V172Z" fill="#2E72D2" mask="url(#path-34-inside-17_458_54231)"/>
      </g>
      <g id="Ellipse 642">
      <mask id="path-36-inside-18_458_54231" fill="white">
      <ellipse cx="12.794" cy="192.088" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M12.2423 192.088C12.2423 192.165 12.2154 192.17 12.2563 192.118C12.3002 192.064 12.4775 191.898 12.794 191.898V195.898C15.0918 195.898 16.2423 193.753 16.2423 192.088H12.2423ZM12.794 191.898C13.1104 191.898 13.2878 192.064 13.3316 192.118C13.3725 192.17 13.3457 192.165 13.3457 192.088H9.3457C9.3457 193.753 10.4962 195.898 12.794 195.898V191.898ZM13.3457 192.088C13.3457 192.01 13.3725 192.006 13.3316 192.057C13.2878 192.112 13.1104 192.277 12.794 192.277V188.277C10.4962 188.277 9.3457 190.423 9.3457 192.088H13.3457ZM12.794 192.277C12.4775 192.277 12.3002 192.112 12.2563 192.057C12.2154 192.006 12.2423 192.01 12.2423 192.088H16.2423C16.2423 190.423 15.0918 188.277 12.794 188.277V192.277Z" fill="#2E72D2" mask="url(#path-36-inside-18_458_54231)"/>
      </g>
      <g id="Ellipse 610">
      <mask id="path-38-inside-19_458_54231" fill="white">
      <ellipse cx="21.4834" cy="85.6385" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 85.6385C20.9317 85.7161 20.9049 85.7203 20.9458 85.6692C20.9896 85.6144 21.167 85.4488 21.4834 85.4488V89.4488C23.7813 89.4488 24.9317 87.3033 24.9317 85.6385H20.9317ZM21.4834 85.4488C21.7999 85.4488 21.9773 85.6144 22.0211 85.6692C22.062 85.7203 22.0352 85.7161 22.0352 85.6385H18.0352C18.0352 87.3033 19.1856 89.4488 21.4834 89.4488V85.4488ZM22.0352 85.6385C22.0352 85.5609 22.062 85.5566 22.0211 85.6078C21.9773 85.6625 21.7999 85.8281 21.4834 85.8281V81.8281C19.1856 81.8281 18.0352 83.9736 18.0352 85.6385H22.0352ZM21.4834 85.8281C21.167 85.8281 20.9896 85.6625 20.9458 85.6078C20.9049 85.5566 20.9317 85.5609 20.9317 85.6385H24.9317C24.9317 83.9736 23.7813 81.8281 21.4834 81.8281V85.8281Z" fill="#2E72D2" mask="url(#path-38-inside-19_458_54231)"/>
      </g>
      <g id="Ellipse 619">
      <mask id="path-40-inside-20_458_54231" fill="white">
      <ellipse cx="21.4834" cy="106.638" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 106.638C20.9317 106.716 20.9049 106.72 20.9458 106.669C20.9896 106.614 21.167 106.449 21.4834 106.449V110.449C23.7813 110.449 24.9317 108.303 24.9317 106.638H20.9317ZM21.4834 106.449C21.7999 106.449 21.9773 106.614 22.0211 106.669C22.062 106.72 22.0352 106.716 22.0352 106.638H18.0352C18.0352 108.303 19.1856 110.449 21.4834 110.449V106.449ZM22.0352 106.638C22.0352 106.561 22.062 106.557 22.0211 106.608C21.9773 106.663 21.7999 106.828 21.4834 106.828V102.828C19.1856 102.828 18.0352 104.974 18.0352 106.638H22.0352ZM21.4834 106.828C21.167 106.828 20.9896 106.663 20.9458 106.608C20.9049 106.557 20.9317 106.561 20.9317 106.638H24.9317C24.9317 104.974 23.7813 102.828 21.4834 102.828V106.828Z" fill="#2E72D2" mask="url(#path-40-inside-20_458_54231)"/>
      </g>
      <g id="Ellipse 625">
      <mask id="path-42-inside-21_458_54231" fill="white">
      <ellipse cx="21.4834" cy="126.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 126.189C20.9317 126.267 20.9049 126.271 20.9458 126.22C20.9896 126.165 21.167 126 21.4834 126V130C23.7813 130 24.9317 127.854 24.9317 126.189H20.9317ZM21.4834 126C21.7999 126 21.9773 126.165 22.0211 126.22C22.062 126.271 22.0352 126.267 22.0352 126.189H18.0352C18.0352 127.854 19.1856 130 21.4834 130V126ZM22.0352 126.189C22.0352 126.112 22.062 126.107 22.0211 126.159C21.9773 126.213 21.7999 126.379 21.4834 126.379V122.379C19.1856 122.379 18.0352 124.524 18.0352 126.189H22.0352ZM21.4834 126.379C21.167 126.379 20.9896 126.213 20.9458 126.159C20.9049 126.107 20.9317 126.112 20.9317 126.189H24.9317C24.9317 124.524 23.7813 122.379 21.4834 122.379V126.379Z" fill="#2E72D2" mask="url(#path-42-inside-21_458_54231)"/>
      </g>
      <g id="Ellipse 631">
      <mask id="path-44-inside-22_458_54231" fill="white">
      <ellipse cx="21.4834" cy="147.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 147.189C20.9317 147.267 20.9049 147.271 20.9458 147.22C20.9896 147.165 21.167 147 21.4834 147V151C23.7813 151 24.9317 148.854 24.9317 147.189H20.9317ZM21.4834 147C21.7999 147 21.9773 147.165 22.0211 147.22C22.062 147.271 22.0352 147.267 22.0352 147.189H18.0352C18.0352 148.854 19.1856 151 21.4834 151V147ZM22.0352 147.189C22.0352 147.112 22.062 147.107 22.0211 147.159C21.9773 147.213 21.7999 147.379 21.4834 147.379V143.379C19.1856 143.379 18.0352 145.524 18.0352 147.189H22.0352ZM21.4834 147.379C21.167 147.379 20.9896 147.213 20.9458 147.159C20.9049 147.107 20.9317 147.112 20.9317 147.189H24.9317C24.9317 145.524 23.7813 143.379 21.4834 143.379V147.379Z" fill="#2E72D2" mask="url(#path-44-inside-22_458_54231)"/>
      </g>
      <g id="Ellipse 637">
      <mask id="path-46-inside-23_458_54231" fill="white">
      <ellipse cx="21.4834" cy="168.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 168.189C20.9317 168.267 20.9049 168.271 20.9458 168.22C20.9896 168.165 21.167 168 21.4834 168V172C23.7813 172 24.9317 169.854 24.9317 168.189H20.9317ZM21.4834 168C21.7999 168 21.9773 168.165 22.0211 168.22C22.062 168.271 22.0352 168.267 22.0352 168.189H18.0352C18.0352 169.854 19.1856 172 21.4834 172V168ZM22.0352 168.189C22.0352 168.112 22.062 168.107 22.0211 168.159C21.9773 168.213 21.7999 168.379 21.4834 168.379V164.379C19.1856 164.379 18.0352 166.524 18.0352 168.189H22.0352ZM21.4834 168.379C21.167 168.379 20.9896 168.213 20.9458 168.159C20.9049 168.107 20.9317 168.112 20.9317 168.189H24.9317C24.9317 166.524 23.7813 164.379 21.4834 164.379V168.379Z" fill="#2E72D2" mask="url(#path-46-inside-23_458_54231)"/>
      </g>
      <g id="Ellipse 643">
      <mask id="path-48-inside-24_458_54231" fill="white">
      <ellipse cx="21.4834" cy="189.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 189.189C20.9317 189.267 20.9049 189.271 20.9458 189.22C20.9896 189.165 21.167 189 21.4834 189V193C23.7813 193 24.9317 190.854 24.9317 189.189H20.9317ZM21.4834 189C21.7999 189 21.9773 189.165 22.0211 189.22C22.062 189.271 22.0352 189.267 22.0352 189.189H18.0352C18.0352 190.854 19.1856 193 21.4834 193V189ZM22.0352 189.189C22.0352 189.112 22.062 189.107 22.0211 189.159C21.9773 189.213 21.7999 189.379 21.4834 189.379V185.379C19.1856 185.379 18.0352 187.524 18.0352 189.189H22.0352ZM21.4834 189.379C21.167 189.379 20.9896 189.213 20.9458 189.159C20.9049 189.107 20.9317 189.112 20.9317 189.189H24.9317C24.9317 187.524 23.7813 185.379 21.4834 185.379V189.379Z" fill="#2E72D2" mask="url(#path-48-inside-24_458_54231)"/>
      </g>
      <g id="Ellipse 615">
      <mask id="path-50-inside-25_458_54231" fill="white">
      <ellipse cx="21.4834" cy="97.2244" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 97.2244C20.9317 97.302 20.9049 97.3062 20.9458 97.2551C20.9896 97.2003 21.167 97.0348 21.4834 97.0348V101.035C23.7813 101.035 24.9317 98.8893 24.9317 97.2244H20.9317ZM21.4834 97.0348C21.7999 97.0348 21.9773 97.2003 22.0211 97.2551C22.062 97.3062 22.0352 97.302 22.0352 97.2244H18.0352C18.0352 98.8893 19.1856 101.035 21.4834 101.035V97.0348ZM22.0352 97.2244C22.0352 97.1468 22.062 97.1426 22.0211 97.1937C21.9773 97.2485 21.7999 97.4141 21.4834 97.4141V93.4141C19.1856 93.4141 18.0352 95.5595 18.0352 97.2244H22.0352ZM21.4834 97.4141C21.167 97.4141 20.9896 97.2485 20.9458 97.1937C20.9049 97.1426 20.9317 97.1468 20.9317 97.2244H24.9317C24.9317 95.5595 23.7813 93.4141 21.4834 93.4141V97.4141Z" fill="#2E72D2" mask="url(#path-50-inside-25_458_54231)"/>
      </g>
      <g id="Ellipse 620">
      <mask id="path-52-inside-26_458_54231" fill="white">
      <ellipse cx="21.4834" cy="118.224" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 118.224C20.9317 118.302 20.9049 118.306 20.9458 118.255C20.9896 118.2 21.167 118.035 21.4834 118.035V122.035C23.7813 122.035 24.9317 119.889 24.9317 118.224H20.9317ZM21.4834 118.035C21.7999 118.035 21.9773 118.2 22.0211 118.255C22.062 118.306 22.0352 118.302 22.0352 118.224H18.0352C18.0352 119.889 19.1856 122.035 21.4834 122.035V118.035ZM22.0352 118.224C22.0352 118.147 22.062 118.143 22.0211 118.194C21.9773 118.248 21.7999 118.414 21.4834 118.414V114.414C19.1856 114.414 18.0352 116.56 18.0352 118.224H22.0352ZM21.4834 118.414C21.167 118.414 20.9896 118.248 20.9458 118.194C20.9049 118.143 20.9317 118.147 20.9317 118.224H24.9317C24.9317 116.56 23.7813 114.414 21.4834 114.414V118.414Z" fill="#2E72D2" mask="url(#path-52-inside-26_458_54231)"/>
      </g>
      <g id="Ellipse 626">
      <mask id="path-54-inside-27_458_54231" fill="white">
      <ellipse cx="21.4834" cy="138.5" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 138.5C20.9317 138.577 20.9049 138.582 20.9458 138.531C20.9896 138.476 21.167 138.31 21.4834 138.31V142.31C23.7813 142.31 24.9317 140.165 24.9317 138.5H20.9317ZM21.4834 138.31C21.7999 138.31 21.9773 138.476 22.0211 138.531C22.062 138.582 22.0352 138.577 22.0352 138.5H18.0352C18.0352 140.165 19.1856 142.31 21.4834 142.31V138.31ZM22.0352 138.5C22.0352 138.422 22.062 138.418 22.0211 138.469C21.9773 138.524 21.7999 138.689 21.4834 138.689V134.689C19.1856 134.689 18.0352 136.835 18.0352 138.5H22.0352ZM21.4834 138.689C21.167 138.689 20.9896 138.524 20.9458 138.469C20.9049 138.418 20.9317 138.422 20.9317 138.5H24.9317C24.9317 136.835 23.7813 134.689 21.4834 134.689V138.689Z" fill="#2E72D2" mask="url(#path-54-inside-27_458_54231)"/>
      </g>
      <g id="Ellipse 632">
      <mask id="path-56-inside-28_458_54231" fill="white">
      <ellipse cx="21.4834" cy="159.5" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 159.5C20.9317 159.577 20.9049 159.582 20.9458 159.531C20.9896 159.476 21.167 159.31 21.4834 159.31V163.31C23.7813 163.31 24.9317 161.165 24.9317 159.5H20.9317ZM21.4834 159.31C21.7999 159.31 21.9773 159.476 22.0211 159.531C22.062 159.582 22.0352 159.577 22.0352 159.5H18.0352C18.0352 161.165 19.1856 163.31 21.4834 163.31V159.31ZM22.0352 159.5C22.0352 159.422 22.062 159.418 22.0211 159.469C21.9773 159.524 21.7999 159.689 21.4834 159.689V155.689C19.1856 155.689 18.0352 157.835 18.0352 159.5H22.0352ZM21.4834 159.689C21.167 159.689 20.9896 159.524 20.9458 159.469C20.9049 159.418 20.9317 159.422 20.9317 159.5H24.9317C24.9317 157.835 23.7813 155.689 21.4834 155.689V159.689Z" fill="#2E72D2" mask="url(#path-56-inside-28_458_54231)"/>
      </g>
      <g id="Ellipse 638">
      <mask id="path-58-inside-29_458_54231" fill="white">
      <ellipse cx="21.4834" cy="179.777" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 179.777C20.9317 179.855 20.9049 179.859 20.9458 179.808C20.9896 179.753 21.167 179.587 21.4834 179.587V183.587C23.7813 183.587 24.9317 181.442 24.9317 179.777H20.9317ZM21.4834 179.587C21.7999 179.587 21.9773 179.753 22.0211 179.808C22.062 179.859 22.0352 179.855 22.0352 179.777H18.0352C18.0352 181.442 19.1856 183.587 21.4834 183.587V179.587ZM22.0352 179.777C22.0352 179.7 22.062 179.695 22.0211 179.746C21.9773 179.801 21.7999 179.967 21.4834 179.967V175.967C19.1856 175.967 18.0352 178.112 18.0352 179.777H22.0352ZM21.4834 179.967C21.167 179.967 20.9896 179.801 20.9458 179.746C20.9049 179.695 20.9317 179.7 20.9317 179.777H24.9317C24.9317 178.112 23.7813 175.967 21.4834 175.967V179.967Z" fill="#2E72D2" mask="url(#path-58-inside-29_458_54231)"/>
      </g>
      <g id="Ellipse 644">
      <mask id="path-60-inside-30_458_54231" fill="white">
      <ellipse cx="21.4834" cy="200.775" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 200.775C20.9317 200.853 20.9049 200.857 20.9458 200.806C20.9896 200.751 21.167 200.586 21.4834 200.586V204.586C23.7813 204.586 24.9317 202.44 24.9317 200.775H20.9317ZM21.4834 200.586C21.7999 200.586 21.9773 200.751 22.0211 200.806C22.062 200.857 22.0352 200.853 22.0352 200.775H18.0352C18.0352 202.44 19.1856 204.586 21.4834 204.586V200.586ZM22.0352 200.775C22.0352 200.698 22.062 200.693 22.0211 200.744C21.9773 200.799 21.7999 200.965 21.4834 200.965V196.965C19.1856 196.965 18.0352 199.11 18.0352 200.775H22.0352ZM21.4834 200.965C21.167 200.965 20.9896 200.799 20.9458 200.744C20.9049 200.693 20.9317 200.698 20.9317 200.775H24.9317C24.9317 199.11 23.7813 196.965 21.4834 196.965V200.965Z" fill="#2E72D2" mask="url(#path-60-inside-30_458_54231)"/>
      </g>
      <g id="Ellipse 612">
      <mask id="path-62-inside-31_458_54231" fill="white">
      <ellipse cx="21.4834" cy="91.4314" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 91.4314C20.9317 91.5091 20.9049 91.5133 20.9458 91.4622C20.9896 91.4074 21.167 91.2418 21.4834 91.2418V95.2418C23.7813 95.2418 24.9317 93.0963 24.9317 91.4314H20.9317ZM21.4834 91.2418C21.7999 91.2418 21.9773 91.4074 22.0211 91.4622C22.062 91.5133 22.0352 91.5091 22.0352 91.4314H18.0352C18.0352 93.0963 19.1856 95.2418 21.4834 95.2418V91.2418ZM22.0352 91.4314C22.0352 91.3538 22.062 91.3496 22.0211 91.4007C21.9773 91.4555 21.7999 91.6211 21.4834 91.6211V87.6211C19.1856 87.6211 18.0352 89.7666 18.0352 91.4314H22.0352ZM21.4834 91.6211C21.167 91.6211 20.9896 91.4555 20.9458 91.4007C20.9049 91.3496 20.9317 91.3538 20.9317 91.4314H24.9317C24.9317 89.7666 23.7813 87.6211 21.4834 87.6211V91.6211Z" fill="#2E72D2" mask="url(#path-62-inside-31_458_54231)"/>
      </g>
      <g id="Ellipse 621">
      <mask id="path-64-inside-32_458_54231" fill="white">
      <ellipse cx="21.4834" cy="112.431" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 112.431C20.9317 112.509 20.9049 112.513 20.9458 112.462C20.9896 112.407 21.167 112.242 21.4834 112.242V116.242C23.7813 116.242 24.9317 114.096 24.9317 112.431H20.9317ZM21.4834 112.242C21.7999 112.242 21.9773 112.407 22.0211 112.462C22.062 112.513 22.0352 112.509 22.0352 112.431H18.0352C18.0352 114.096 19.1856 116.242 21.4834 116.242V112.242ZM22.0352 112.431C22.0352 112.354 22.062 112.35 22.0211 112.401C21.9773 112.456 21.7999 112.621 21.4834 112.621V108.621C19.1856 108.621 18.0352 110.767 18.0352 112.431H22.0352ZM21.4834 112.621C21.167 112.621 20.9896 112.456 20.9458 112.401C20.9049 112.35 20.9317 112.354 20.9317 112.431H24.9317C24.9317 110.767 23.7813 108.621 21.4834 108.621V112.621Z" fill="#2E72D2" mask="url(#path-64-inside-32_458_54231)"/>
      </g>
      <g id="Ellipse 627">
      <mask id="path-66-inside-33_458_54231" fill="white">
      <ellipse cx="21.4834" cy="131.982" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 131.982C20.9317 132.06 20.9049 132.064 20.9458 132.013C20.9896 131.958 21.167 131.793 21.4834 131.793V135.793C23.7813 135.793 24.9317 133.647 24.9317 131.982H20.9317ZM21.4834 131.793C21.7999 131.793 21.9773 131.958 22.0211 132.013C22.062 132.064 22.0352 132.06 22.0352 131.982H18.0352C18.0352 133.647 19.1856 135.793 21.4834 135.793V131.793ZM22.0352 131.982C22.0352 131.905 22.062 131.9 22.0211 131.952C21.9773 132.006 21.7999 132.172 21.4834 132.172V128.172C19.1856 128.172 18.0352 130.317 18.0352 131.982H22.0352ZM21.4834 132.172C21.167 132.172 20.9896 132.006 20.9458 131.952C20.9049 131.9 20.9317 131.905 20.9317 131.982H24.9317C24.9317 130.317 23.7813 128.172 21.4834 128.172V132.172Z" fill="#2E72D2" mask="url(#path-66-inside-33_458_54231)"/>
      </g>
      <g id="Ellipse 633">
      <mask id="path-68-inside-34_458_54231" fill="white">
      <ellipse cx="21.4834" cy="152.984" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 152.984C20.9317 153.062 20.9049 153.066 20.9458 153.015C20.9896 152.96 21.167 152.795 21.4834 152.795V156.795C23.7813 156.795 24.9317 154.649 24.9317 152.984H20.9317ZM21.4834 152.795C21.7999 152.795 21.9773 152.96 22.0211 153.015C22.062 153.066 22.0352 153.062 22.0352 152.984H18.0352C18.0352 154.649 19.1856 156.795 21.4834 156.795V152.795ZM22.0352 152.984C22.0352 152.907 22.062 152.902 22.0211 152.953C21.9773 153.008 21.7999 153.174 21.4834 153.174V149.174C19.1856 149.174 18.0352 151.319 18.0352 152.984H22.0352ZM21.4834 153.174C21.167 153.174 20.9896 153.008 20.9458 152.953C20.9049 152.902 20.9317 152.907 20.9317 152.984H24.9317C24.9317 151.319 23.7813 149.174 21.4834 149.174V153.174Z" fill="#2E72D2" mask="url(#path-68-inside-34_458_54231)"/>
      </g>
      <g id="Ellipse 639">
      <mask id="path-70-inside-35_458_54231" fill="white">
      <ellipse cx="21.4834" cy="173.982" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 173.982C20.9317 174.06 20.9049 174.064 20.9458 174.013C20.9896 173.958 21.167 173.793 21.4834 173.793V177.793C23.7813 177.793 24.9317 175.647 24.9317 173.982H20.9317ZM21.4834 173.793C21.7999 173.793 21.9773 173.958 22.0211 174.013C22.062 174.064 22.0352 174.06 22.0352 173.982H18.0352C18.0352 175.647 19.1856 177.793 21.4834 177.793V173.793ZM22.0352 173.982C22.0352 173.905 22.062 173.9 22.0211 173.952C21.9773 174.006 21.7999 174.172 21.4834 174.172V170.172C19.1856 170.172 18.0352 172.317 18.0352 173.982H22.0352ZM21.4834 174.172C21.167 174.172 20.9896 174.006 20.9458 173.952C20.9049 173.9 20.9317 173.905 20.9317 173.982H24.9317C24.9317 172.317 23.7813 170.172 21.4834 170.172V174.172Z" fill="#2E72D2" mask="url(#path-70-inside-35_458_54231)"/>
      </g>
      <g id="Ellipse 645">
      <mask id="path-72-inside-36_458_54231" fill="white">
      <ellipse cx="21.4834" cy="194.982" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M20.9317 194.982C20.9317 195.06 20.9049 195.064 20.9458 195.013C20.9896 194.958 21.167 194.793 21.4834 194.793V198.793C23.7813 198.793 24.9317 196.647 24.9317 194.982H20.9317ZM21.4834 194.793C21.7999 194.793 21.9773 194.958 22.0211 195.013C22.062 195.064 22.0352 195.06 22.0352 194.982H18.0352C18.0352 196.647 19.1856 198.793 21.4834 198.793V194.793ZM22.0352 194.982C22.0352 194.905 22.062 194.9 22.0211 194.952C21.9773 195.006 21.7999 195.172 21.4834 195.172V191.172C19.1856 191.172 18.0352 193.317 18.0352 194.982H22.0352ZM21.4834 195.172C21.167 195.172 20.9896 195.006 20.9458 194.952C20.9049 194.9 20.9317 194.905 20.9317 194.982H24.9317C24.9317 193.317 23.7813 191.172 21.4834 191.172V195.172Z" fill="#2E72D2" mask="url(#path-72-inside-36_458_54231)"/>
      </g>
      <path id="Vector 306" d="M81.5782 2L7.12836 21.6465L7 221.684L38.7054 230.103L112 210.585V10.1648L81.5782 2Z" stroke="#2E72D2" stroke-width="2"/>
      <path id="Rectangle 2141" d="M39.8385 228.285L40.1996 30.7949L109.942 12.101L110.455 209.635L39.8385 228.285Z" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2142" d="M95.3457 26.2878C95.3457 25.4097 95.9183 24.6344 96.7575 24.3762L102.171 22.7104C103.457 22.3147 104.759 23.2764 104.759 24.622V200.73C104.759 201.637 104.148 202.431 103.271 202.663L97.857 204.095C96.5883 204.43 95.3457 203.474 95.3457 202.161V26.2878Z" fill="#2E72D2"/>
      <path id="Vector 310" d="M7 21.9124L38.8621 30.24M38.8621 30.24L111.638 10.3262M38.8621 30.24V230.102" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2143" d="M103.311 23H103.397C103.949 23 104.397 23.4477 104.397 24V201.627L103.311 201.265V23Z" fill="white"/>
      <path id="Rectangle 2144" d="M95.7754 203.379L103.017 201.689L104.034 202.079L97.0575 203.953C96.7123 204.046 96.3438 203.947 96.091 203.695L95.7754 203.379Z" fill="white"/>
      </g>
      <g id="loading_dots">
        <rect y="103" width="120" height="40" rx="4" fill="white" fill-opacity="0.6"/>
        <circle id="dot_1" cx="20" cy="123" r="10" fill="#2E72D2"/>
        <circle id="dot_2" cx="60" cy="123" r="10" fill="#2E72D2"/>
        <circle id="dot_3" cx="100" cy="123" r="10" fill="#2E72D2"/>
      </g>
      </g>
    </svg>

  )
}
NDSLoading.defaultProps = {
  width: 227
}


export function NDSConnectionFailed({width})  {
  return(
    <svg className="NDSConnectionFailed" width={width} viewBox="0 0 108 232" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1574">
      <g id="Group 1571">
      <path id="Vector 309" d="M76.0862 2L1.86207 21.5517L1.5 221.414L33.3621 230.103L106.5 210.552V10.3276L76.0862 2Z" fill="white"/>
      <g id="Ellipse 609">
      <mask id="path-2-inside-1_452_46273" fill="white">
      <ellipse cx="7.29203" cy="83.4646" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 83.4646C6.7403 83.5423 6.71349 83.5465 6.75437 83.4954C6.7982 83.4406 6.97556 83.275 7.29203 83.275V87.275C9.58985 87.275 10.7403 85.1295 10.7403 83.4646H6.7403ZM7.29203 83.275C7.60849 83.275 7.78585 83.4406 7.82968 83.4954C7.87056 83.5465 7.84375 83.5423 7.84375 83.4646H3.84375C3.84375 85.1295 4.9942 87.275 7.29203 87.275V83.275ZM7.84375 83.4646C7.84375 83.387 7.87056 83.3828 7.82968 83.4339C7.78585 83.4887 7.60849 83.6543 7.29203 83.6543V79.6543C4.9942 79.6543 3.84375 81.7998 3.84375 83.4646H7.84375ZM7.29203 83.6543C6.97556 83.6543 6.7982 83.4887 6.75437 83.4339C6.71349 83.3828 6.7403 83.387 6.7403 83.4646H10.7403C10.7403 81.7998 9.58985 79.6543 7.29203 79.6543V83.6543Z" fill="#2E72D2" mask="url(#path-2-inside-1_452_46273)"/>
      </g>
      <g id="Ellipse 616">
      <mask id="path-4-inside-2_452_46273" fill="white">
      <ellipse cx="7.29203" cy="104.465" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 104.465C6.7403 104.542 6.71349 104.546 6.75437 104.495C6.7982 104.441 6.97556 104.275 7.29203 104.275V108.275C9.58985 108.275 10.7403 106.13 10.7403 104.465H6.7403ZM7.29203 104.275C7.60849 104.275 7.78585 104.441 7.82968 104.495C7.87056 104.546 7.84375 104.542 7.84375 104.465H3.84375C3.84375 106.13 4.9942 108.275 7.29203 108.275V104.275ZM7.84375 104.465C7.84375 104.387 7.87056 104.383 7.82968 104.434C7.78585 104.489 7.60849 104.654 7.29203 104.654V100.654C4.9942 100.654 3.84375 102.8 3.84375 104.465H7.84375ZM7.29203 104.654C6.97556 104.654 6.7982 104.489 6.75437 104.434C6.71349 104.383 6.7403 104.387 6.7403 104.465H10.7403C10.7403 102.8 9.58985 100.654 7.29203 100.654V104.654Z" fill="#2E72D2" mask="url(#path-4-inside-2_452_46273)"/>
      </g>
      <g id="Ellipse 622">
      <mask id="path-6-inside-3_452_46273" fill="white">
      <ellipse cx="7.29203" cy="124.017" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 124.017C6.7403 124.095 6.71349 124.099 6.75437 124.048C6.7982 123.993 6.97556 123.828 7.29203 123.828V127.828C9.58985 127.828 10.7403 125.682 10.7403 124.017H6.7403ZM7.29203 123.828C7.60849 123.828 7.78585 123.993 7.82968 124.048C7.87056 124.099 7.84375 124.095 7.84375 124.017H3.84375C3.84375 125.682 4.9942 127.828 7.29203 127.828V123.828ZM7.84375 124.017C7.84375 123.94 7.87056 123.936 7.82968 123.987C7.78585 124.041 7.60849 124.207 7.29203 124.207V120.207C4.9942 120.207 3.84375 122.353 3.84375 124.017H7.84375ZM7.29203 124.207C6.97556 124.207 6.7982 124.041 6.75437 123.987C6.71349 123.936 6.7403 123.94 6.7403 124.017H10.7403C10.7403 122.353 9.58985 120.207 7.29203 120.207V124.207Z" fill="#2E72D2" mask="url(#path-6-inside-3_452_46273)"/>
      </g>
      <g id="Ellipse 628">
      <mask id="path-8-inside-4_452_46273" fill="white">
      <ellipse cx="7.29203" cy="145.017" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 145.017C6.7403 145.095 6.71349 145.099 6.75437 145.048C6.7982 144.993 6.97556 144.828 7.29203 144.828V148.828C9.58985 148.828 10.7403 146.682 10.7403 145.017H6.7403ZM7.29203 144.828C7.60849 144.828 7.78585 144.993 7.82968 145.048C7.87056 145.099 7.84375 145.095 7.84375 145.017H3.84375C3.84375 146.682 4.9942 148.828 7.29203 148.828V144.828ZM7.84375 145.017C7.84375 144.94 7.87056 144.936 7.82968 144.987C7.78585 145.041 7.60849 145.207 7.29203 145.207V141.207C4.9942 141.207 3.84375 143.353 3.84375 145.017H7.84375ZM7.29203 145.207C6.97556 145.207 6.7982 145.041 6.75437 144.987C6.71349 144.936 6.7403 144.94 6.7403 145.017H10.7403C10.7403 143.353 9.58985 141.207 7.29203 141.207V145.207Z" fill="#2E72D2" mask="url(#path-8-inside-4_452_46273)"/>
      </g>
      <g id="Ellipse 634">
      <mask id="path-10-inside-5_452_46273" fill="white">
      <ellipse cx="7.29203" cy="166.017" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 166.017C6.7403 166.095 6.71349 166.099 6.75437 166.048C6.7982 165.993 6.97556 165.828 7.29203 165.828V169.828C9.58985 169.828 10.7403 167.682 10.7403 166.017H6.7403ZM7.29203 165.828C7.60849 165.828 7.78585 165.993 7.82968 166.048C7.87056 166.099 7.84375 166.095 7.84375 166.017H3.84375C3.84375 167.682 4.9942 169.828 7.29203 169.828V165.828ZM7.84375 166.017C7.84375 165.94 7.87056 165.936 7.82968 165.987C7.78585 166.041 7.60849 166.207 7.29203 166.207V162.207C4.9942 162.207 3.84375 164.353 3.84375 166.017H7.84375ZM7.29203 166.207C6.97556 166.207 6.7982 166.041 6.75437 165.987C6.71349 165.936 6.7403 165.94 6.7403 166.017H10.7403C10.7403 164.353 9.58985 162.207 7.29203 162.207V166.207Z" fill="#2E72D2" mask="url(#path-10-inside-5_452_46273)"/>
      </g>
      <g id="Ellipse 640">
      <mask id="path-12-inside-6_452_46273" fill="white">
      <ellipse cx="7.29203" cy="186.293" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 186.293C6.7403 186.37 6.71349 186.375 6.75437 186.323C6.7982 186.269 6.97556 186.103 7.29203 186.103V190.103C9.58985 190.103 10.7403 187.958 10.7403 186.293H6.7403ZM7.29203 186.103C7.60849 186.103 7.78585 186.269 7.82968 186.323C7.87056 186.375 7.84375 186.37 7.84375 186.293H3.84375C3.84375 187.958 4.9942 190.103 7.29203 190.103V186.103ZM7.84375 186.293C7.84375 186.215 7.87056 186.211 7.82968 186.262C7.78585 186.317 7.60849 186.482 7.29203 186.482V182.482C4.9942 182.482 3.84375 184.628 3.84375 186.293H7.84375ZM7.29203 186.482C6.97556 186.482 6.7982 186.317 6.75437 186.262C6.71349 186.211 6.7403 186.215 6.7403 186.293H10.7403C10.7403 184.628 9.58985 182.482 7.29203 182.482V186.482Z" fill="#2E72D2" mask="url(#path-12-inside-6_452_46273)"/>
      </g>
      <g id="Ellipse 613">
      <mask id="path-14-inside-7_452_46273" fill="white">
      <ellipse cx="7.29203" cy="95.7752" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 95.7752C6.7403 95.8528 6.71349 95.857 6.75437 95.8059C6.7982 95.7511 6.97556 95.5855 7.29203 95.5855V99.5855C9.58985 99.5855 10.7403 97.4401 10.7403 95.7752H6.7403ZM7.29203 95.5855C7.60849 95.5855 7.78585 95.7511 7.82968 95.8059C7.87056 95.857 7.84375 95.8528 7.84375 95.7752H3.84375C3.84375 97.4401 4.9942 99.5855 7.29203 99.5855V95.5855ZM7.84375 95.7752C7.84375 95.6976 7.87056 95.6934 7.82968 95.7445C7.78585 95.7993 7.60849 95.9648 7.29203 95.9648V91.9648C4.9942 91.9648 3.84375 94.1103 3.84375 95.7752H7.84375ZM7.29203 95.9648C6.97556 95.9648 6.7982 95.7993 6.75437 95.7445C6.71349 95.6934 6.7403 95.6976 6.7403 95.7752H10.7403C10.7403 94.1103 9.58985 91.9648 7.29203 91.9648V95.9648Z" fill="#2E72D2" mask="url(#path-14-inside-7_452_46273)"/>
      </g>
      <g id="Ellipse 617">
      <mask id="path-16-inside-8_452_46273" fill="white">
      <ellipse cx="7.29203" cy="116.051" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 116.051C6.7403 116.128 6.71349 116.132 6.75437 116.081C6.7982 116.027 6.97556 115.861 7.29203 115.861V119.861C9.58985 119.861 10.7403 117.715 10.7403 116.051H6.7403ZM7.29203 115.861C7.60849 115.861 7.78585 116.027 7.82968 116.081C7.87056 116.132 7.84375 116.128 7.84375 116.051H3.84375C3.84375 117.715 4.9942 119.861 7.29203 119.861V115.861ZM7.84375 116.051C7.84375 115.973 7.87056 115.969 7.82968 116.02C7.78585 116.075 7.60849 116.24 7.29203 116.24V112.24C4.9942 112.24 3.84375 114.386 3.84375 116.051H7.84375ZM7.29203 116.24C6.97556 116.24 6.7982 116.075 6.75437 116.02C6.71349 115.969 6.7403 115.973 6.7403 116.051H10.7403C10.7403 114.386 9.58985 112.24 7.29203 112.24V116.24Z" fill="#2E72D2" mask="url(#path-16-inside-8_452_46273)"/>
      </g>
      <g id="Ellipse 623">
      <mask id="path-18-inside-9_452_46273" fill="white">
      <ellipse cx="7.29203" cy="136.328" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 136.328C6.7403 136.406 6.71349 136.41 6.75437 136.359C6.7982 136.304 6.97556 136.138 7.29203 136.138V140.138C9.58985 140.138 10.7403 137.993 10.7403 136.328H6.7403ZM7.29203 136.138C7.60849 136.138 7.78585 136.304 7.82968 136.359C7.87056 136.41 7.84375 136.406 7.84375 136.328H3.84375C3.84375 137.993 4.9942 140.138 7.29203 140.138V136.138ZM7.84375 136.328C7.84375 136.25 7.87056 136.246 7.82968 136.297C7.78585 136.352 7.60849 136.518 7.29203 136.518V132.518C4.9942 132.518 3.84375 134.663 3.84375 136.328H7.84375ZM7.29203 136.518C6.97556 136.518 6.7982 136.352 6.75437 136.297C6.71349 136.246 6.7403 136.25 6.7403 136.328H10.7403C10.7403 134.663 9.58985 132.518 7.29203 132.518V136.518Z" fill="#2E72D2" mask="url(#path-18-inside-9_452_46273)"/>
      </g>
      <g id="Ellipse 629">
      <mask id="path-20-inside-10_452_46273" fill="white">
      <ellipse cx="7.29203" cy="157.328" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 157.328C6.7403 157.406 6.71349 157.41 6.75437 157.359C6.7982 157.304 6.97556 157.138 7.29203 157.138V161.138C9.58985 161.138 10.7403 158.993 10.7403 157.328H6.7403ZM7.29203 157.138C7.60849 157.138 7.78585 157.304 7.82968 157.359C7.87056 157.41 7.84375 157.406 7.84375 157.328H3.84375C3.84375 158.993 4.9942 161.138 7.29203 161.138V157.138ZM7.84375 157.328C7.84375 157.25 7.87056 157.246 7.82968 157.297C7.78585 157.352 7.60849 157.518 7.29203 157.518V153.518C4.9942 153.518 3.84375 155.663 3.84375 157.328H7.84375ZM7.29203 157.518C6.97556 157.518 6.7982 157.352 6.75437 157.297C6.71349 157.246 6.7403 157.25 6.7403 157.328H10.7403C10.7403 155.663 9.58985 153.518 7.29203 153.518V157.518Z" fill="#2E72D2" mask="url(#path-20-inside-10_452_46273)"/>
      </g>
      <g id="Ellipse 635">
      <mask id="path-22-inside-11_452_46273" fill="white">
      <ellipse cx="7.29203" cy="177.603" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 177.603C6.7403 177.681 6.71349 177.685 6.75437 177.634C6.7982 177.579 6.97556 177.414 7.29203 177.414V181.414C9.58985 181.414 10.7403 179.268 10.7403 177.603H6.7403ZM7.29203 177.414C7.60849 177.414 7.78585 177.579 7.82968 177.634C7.87056 177.685 7.84375 177.681 7.84375 177.603H3.84375C3.84375 179.268 4.9942 181.414 7.29203 181.414V177.414ZM7.84375 177.603C7.84375 177.526 7.87056 177.521 7.82968 177.573C7.78585 177.627 7.60849 177.793 7.29203 177.793V173.793C4.9942 173.793 3.84375 175.938 3.84375 177.603H7.84375ZM7.29203 177.793C6.97556 177.793 6.7982 177.627 6.75437 177.573C6.71349 177.521 6.7403 177.526 6.7403 177.603H10.7403C10.7403 175.938 9.58985 173.793 7.29203 173.793V177.793Z" fill="#2E72D2" mask="url(#path-22-inside-11_452_46273)"/>
      </g>
      <g id="Ellipse 641">
      <mask id="path-24-inside-12_452_46273" fill="white">
      <ellipse cx="7.29203" cy="197.879" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 197.879C6.7403 197.956 6.71349 197.961 6.75437 197.909C6.7982 197.855 6.97556 197.689 7.29203 197.689V201.689C9.58985 201.689 10.7403 199.544 10.7403 197.879H6.7403ZM7.29203 197.689C7.60849 197.689 7.78585 197.855 7.82968 197.909C7.87056 197.961 7.84375 197.956 7.84375 197.879H3.84375C3.84375 199.544 4.9942 201.689 7.29203 201.689V197.689ZM7.84375 197.879C7.84375 197.801 7.87056 197.797 7.82968 197.848C7.78585 197.903 7.60849 198.068 7.29203 198.068V194.068C4.9942 194.068 3.84375 196.214 3.84375 197.879H7.84375ZM7.29203 198.068C6.97556 198.068 6.7982 197.903 6.75437 197.848C6.71349 197.797 6.7403 197.801 6.7403 197.879H10.7403C10.7403 196.214 9.58985 194.068 7.29203 194.068V198.068Z" fill="#2E72D2" mask="url(#path-24-inside-12_452_46273)"/>
      </g>
      <g id="Ellipse 611">
      <mask id="path-26-inside-13_452_46273" fill="white">
      <ellipse cx="7.29203" cy="89.2576" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 89.2576C6.7403 89.3352 6.71349 89.3394 6.75437 89.2883C6.7982 89.2335 6.97556 89.068 7.29203 89.068V93.068C9.58985 93.068 10.7403 90.9225 10.7403 89.2576H6.7403ZM7.29203 89.068C7.60849 89.068 7.78585 89.2335 7.82968 89.2883C7.87056 89.3394 7.84375 89.3352 7.84375 89.2576H3.84375C3.84375 90.9225 4.9942 93.068 7.29203 93.068V89.068ZM7.84375 89.2576C7.84375 89.18 7.87056 89.1758 7.82968 89.2269C7.78585 89.2817 7.60849 89.4473 7.29203 89.4473V85.4473C4.9942 85.4473 3.84375 87.5927 3.84375 89.2576H7.84375ZM7.29203 89.4473C6.97556 89.4473 6.7982 89.2817 6.75437 89.2269C6.71349 89.1758 6.7403 89.18 6.7403 89.2576H10.7403C10.7403 87.5927 9.58985 85.4473 7.29203 85.4473V89.4473Z" fill="#2E72D2" mask="url(#path-26-inside-13_452_46273)"/>
      </g>
      <g id="Ellipse 618">
      <mask id="path-28-inside-14_452_46273" fill="white">
      <ellipse cx="7.29203" cy="110.258" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 110.258C6.7403 110.335 6.71349 110.339 6.75437 110.288C6.7982 110.234 6.97556 110.068 7.29203 110.068V114.068C9.58985 114.068 10.7403 111.922 10.7403 110.258H6.7403ZM7.29203 110.068C7.60849 110.068 7.78585 110.234 7.82968 110.288C7.87056 110.339 7.84375 110.335 7.84375 110.258H3.84375C3.84375 111.922 4.9942 114.068 7.29203 114.068V110.068ZM7.84375 110.258C7.84375 110.18 7.87056 110.176 7.82968 110.227C7.78585 110.282 7.60849 110.447 7.29203 110.447V106.447C4.9942 106.447 3.84375 108.593 3.84375 110.258H7.84375ZM7.29203 110.447C6.97556 110.447 6.7982 110.282 6.75437 110.227C6.71349 110.176 6.7403 110.18 6.7403 110.258H10.7403C10.7403 108.593 9.58985 106.447 7.29203 106.447V110.447Z" fill="#2E72D2" mask="url(#path-28-inside-14_452_46273)"/>
      </g>
      <g id="Ellipse 624">
      <mask id="path-30-inside-15_452_46273" fill="white">
      <ellipse cx="7.29203" cy="129.81" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 129.81C6.7403 129.888 6.71349 129.892 6.75437 129.841C6.7982 129.786 6.97556 129.621 7.29203 129.621V133.621C9.58985 133.621 10.7403 131.475 10.7403 129.81H6.7403ZM7.29203 129.621C7.60849 129.621 7.78585 129.786 7.82968 129.841C7.87056 129.892 7.84375 129.888 7.84375 129.81H3.84375C3.84375 131.475 4.9942 133.621 7.29203 133.621V129.621ZM7.84375 129.81C7.84375 129.733 7.87056 129.729 7.82968 129.78C7.78585 129.834 7.60849 130 7.29203 130V126C4.9942 126 3.84375 128.145 3.84375 129.81H7.84375ZM7.29203 130C6.97556 130 6.7982 129.834 6.75437 129.78C6.71349 129.729 6.7403 129.733 6.7403 129.81H10.7403C10.7403 128.145 9.58985 126 7.29203 126V130Z" fill="#2E72D2" mask="url(#path-30-inside-15_452_46273)"/>
      </g>
      <g id="Ellipse 630">
      <mask id="path-32-inside-16_452_46273" fill="white">
      <ellipse cx="7.29203" cy="150.81" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 150.81C6.7403 150.888 6.71349 150.892 6.75437 150.841C6.7982 150.786 6.97556 150.621 7.29203 150.621V154.621C9.58985 154.621 10.7403 152.475 10.7403 150.81H6.7403ZM7.29203 150.621C7.60849 150.621 7.78585 150.786 7.82968 150.841C7.87056 150.892 7.84375 150.888 7.84375 150.81H3.84375C3.84375 152.475 4.9942 154.621 7.29203 154.621V150.621ZM7.84375 150.81C7.84375 150.733 7.87056 150.729 7.82968 150.78C7.78585 150.834 7.60849 151 7.29203 151V147C4.9942 147 3.84375 149.145 3.84375 150.81H7.84375ZM7.29203 151C6.97556 151 6.7982 150.834 6.75437 150.78C6.71349 150.729 6.7403 150.733 6.7403 150.81H10.7403C10.7403 149.145 9.58985 147 7.29203 147V151Z" fill="#2E72D2" mask="url(#path-32-inside-16_452_46273)"/>
      </g>
      <g id="Ellipse 636">
      <mask id="path-34-inside-17_452_46273" fill="white">
      <ellipse cx="7.29203" cy="171.81" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 171.81C6.7403 171.888 6.71349 171.892 6.75437 171.841C6.7982 171.786 6.97556 171.621 7.29203 171.621V175.621C9.58985 175.621 10.7403 173.475 10.7403 171.81H6.7403ZM7.29203 171.621C7.60849 171.621 7.78585 171.786 7.82968 171.841C7.87056 171.892 7.84375 171.888 7.84375 171.81H3.84375C3.84375 173.475 4.9942 175.621 7.29203 175.621V171.621ZM7.84375 171.81C7.84375 171.733 7.87056 171.729 7.82968 171.78C7.78585 171.834 7.60849 172 7.29203 172V168C4.9942 168 3.84375 170.145 3.84375 171.81H7.84375ZM7.29203 172C6.97556 172 6.7982 171.834 6.75437 171.78C6.71349 171.729 6.7403 171.733 6.7403 171.81H10.7403C10.7403 170.145 9.58985 168 7.29203 168V172Z" fill="#2E72D2" mask="url(#path-34-inside-17_452_46273)"/>
      </g>
      <g id="Ellipse 642">
      <mask id="path-36-inside-18_452_46273" fill="white">
      <ellipse cx="7.29203" cy="192.088" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.7403 192.088C6.7403 192.165 6.71349 192.17 6.75437 192.118C6.7982 192.064 6.97556 191.898 7.29203 191.898V195.898C9.58985 195.898 10.7403 193.753 10.7403 192.088H6.7403ZM7.29203 191.898C7.60849 191.898 7.78585 192.064 7.82968 192.118C7.87056 192.17 7.84375 192.165 7.84375 192.088H3.84375C3.84375 193.753 4.9942 195.898 7.29203 195.898V191.898ZM7.84375 192.088C7.84375 192.01 7.87056 192.006 7.82968 192.057C7.78585 192.112 7.60849 192.277 7.29203 192.277V188.277C4.9942 188.277 3.84375 190.423 3.84375 192.088H7.84375ZM7.29203 192.277C6.97556 192.277 6.7982 192.112 6.75437 192.057C6.71349 192.006 6.7403 192.01 6.7403 192.088H10.7403C10.7403 190.423 9.58985 188.277 7.29203 188.277V192.277Z" fill="#2E72D2" mask="url(#path-36-inside-18_452_46273)"/>
      </g>
      <g id="Ellipse 610">
      <mask id="path-38-inside-19_452_46273" fill="white">
      <ellipse cx="15.9834" cy="85.6385" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 85.6385C15.4317 85.7161 15.4049 85.7203 15.4458 85.6692C15.4896 85.6144 15.667 85.4488 15.9834 85.4488V89.4488C18.2813 89.4488 19.4317 87.3033 19.4317 85.6385H15.4317ZM15.9834 85.4488C16.2999 85.4488 16.4773 85.6144 16.5211 85.6692C16.562 85.7203 16.5352 85.7161 16.5352 85.6385H12.5352C12.5352 87.3033 13.6856 89.4488 15.9834 89.4488V85.4488ZM16.5352 85.6385C16.5352 85.5609 16.562 85.5566 16.5211 85.6078C16.4773 85.6625 16.2999 85.8281 15.9834 85.8281V81.8281C13.6856 81.8281 12.5352 83.9736 12.5352 85.6385H16.5352ZM15.9834 85.8281C15.667 85.8281 15.4896 85.6625 15.4458 85.6078C15.4049 85.5566 15.4317 85.5609 15.4317 85.6385H19.4317C19.4317 83.9736 18.2813 81.8281 15.9834 81.8281V85.8281Z" fill="#2E72D2" mask="url(#path-38-inside-19_452_46273)"/>
      </g>
      <g id="Ellipse 619">
      <mask id="path-40-inside-20_452_46273" fill="white">
      <ellipse cx="15.9834" cy="106.638" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 106.638C15.4317 106.716 15.4049 106.72 15.4458 106.669C15.4896 106.614 15.667 106.449 15.9834 106.449V110.449C18.2813 110.449 19.4317 108.303 19.4317 106.638H15.4317ZM15.9834 106.449C16.2999 106.449 16.4773 106.614 16.5211 106.669C16.562 106.72 16.5352 106.716 16.5352 106.638H12.5352C12.5352 108.303 13.6856 110.449 15.9834 110.449V106.449ZM16.5352 106.638C16.5352 106.561 16.562 106.557 16.5211 106.608C16.4773 106.663 16.2999 106.828 15.9834 106.828V102.828C13.6856 102.828 12.5352 104.974 12.5352 106.638H16.5352ZM15.9834 106.828C15.667 106.828 15.4896 106.663 15.4458 106.608C15.4049 106.557 15.4317 106.561 15.4317 106.638H19.4317C19.4317 104.974 18.2813 102.828 15.9834 102.828V106.828Z" fill="#2E72D2" mask="url(#path-40-inside-20_452_46273)"/>
      </g>
      <g id="Ellipse 625">
      <mask id="path-42-inside-21_452_46273" fill="white">
      <ellipse cx="15.9834" cy="126.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 126.189C15.4317 126.267 15.4049 126.271 15.4458 126.22C15.4896 126.165 15.667 126 15.9834 126V130C18.2813 130 19.4317 127.854 19.4317 126.189H15.4317ZM15.9834 126C16.2999 126 16.4773 126.165 16.5211 126.22C16.562 126.271 16.5352 126.267 16.5352 126.189H12.5352C12.5352 127.854 13.6856 130 15.9834 130V126ZM16.5352 126.189C16.5352 126.112 16.562 126.107 16.5211 126.159C16.4773 126.213 16.2999 126.379 15.9834 126.379V122.379C13.6856 122.379 12.5352 124.524 12.5352 126.189H16.5352ZM15.9834 126.379C15.667 126.379 15.4896 126.213 15.4458 126.159C15.4049 126.107 15.4317 126.112 15.4317 126.189H19.4317C19.4317 124.524 18.2813 122.379 15.9834 122.379V126.379Z" fill="#2E72D2" mask="url(#path-42-inside-21_452_46273)"/>
      </g>
      <g id="Ellipse 631">
      <mask id="path-44-inside-22_452_46273" fill="white">
      <ellipse cx="15.9834" cy="147.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 147.189C15.4317 147.267 15.4049 147.271 15.4458 147.22C15.4896 147.165 15.667 147 15.9834 147V151C18.2813 151 19.4317 148.854 19.4317 147.189H15.4317ZM15.9834 147C16.2999 147 16.4773 147.165 16.5211 147.22C16.562 147.271 16.5352 147.267 16.5352 147.189H12.5352C12.5352 148.854 13.6856 151 15.9834 151V147ZM16.5352 147.189C16.5352 147.112 16.562 147.107 16.5211 147.159C16.4773 147.213 16.2999 147.379 15.9834 147.379V143.379C13.6856 143.379 12.5352 145.524 12.5352 147.189H16.5352ZM15.9834 147.379C15.667 147.379 15.4896 147.213 15.4458 147.159C15.4049 147.107 15.4317 147.112 15.4317 147.189H19.4317C19.4317 145.524 18.2813 143.379 15.9834 143.379V147.379Z" fill="#2E72D2" mask="url(#path-44-inside-22_452_46273)"/>
      </g>
      <g id="Ellipse 637">
      <mask id="path-46-inside-23_452_46273" fill="white">
      <ellipse cx="15.9834" cy="168.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 168.189C15.4317 168.267 15.4049 168.271 15.4458 168.22C15.4896 168.165 15.667 168 15.9834 168V172C18.2813 172 19.4317 169.854 19.4317 168.189H15.4317ZM15.9834 168C16.2999 168 16.4773 168.165 16.5211 168.22C16.562 168.271 16.5352 168.267 16.5352 168.189H12.5352C12.5352 169.854 13.6856 172 15.9834 172V168ZM16.5352 168.189C16.5352 168.112 16.562 168.107 16.5211 168.159C16.4773 168.213 16.2999 168.379 15.9834 168.379V164.379C13.6856 164.379 12.5352 166.524 12.5352 168.189H16.5352ZM15.9834 168.379C15.667 168.379 15.4896 168.213 15.4458 168.159C15.4049 168.107 15.4317 168.112 15.4317 168.189H19.4317C19.4317 166.524 18.2813 164.379 15.9834 164.379V168.379Z" fill="#2E72D2" mask="url(#path-46-inside-23_452_46273)"/>
      </g>
      <g id="Ellipse 643">
      <mask id="path-48-inside-24_452_46273" fill="white">
      <ellipse cx="15.9834" cy="189.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 189.189C15.4317 189.267 15.4049 189.271 15.4458 189.22C15.4896 189.165 15.667 189 15.9834 189V193C18.2813 193 19.4317 190.854 19.4317 189.189H15.4317ZM15.9834 189C16.2999 189 16.4773 189.165 16.5211 189.22C16.562 189.271 16.5352 189.267 16.5352 189.189H12.5352C12.5352 190.854 13.6856 193 15.9834 193V189ZM16.5352 189.189C16.5352 189.112 16.562 189.107 16.5211 189.159C16.4773 189.213 16.2999 189.379 15.9834 189.379V185.379C13.6856 185.379 12.5352 187.524 12.5352 189.189H16.5352ZM15.9834 189.379C15.667 189.379 15.4896 189.213 15.4458 189.159C15.4049 189.107 15.4317 189.112 15.4317 189.189H19.4317C19.4317 187.524 18.2813 185.379 15.9834 185.379V189.379Z" fill="#2E72D2" mask="url(#path-48-inside-24_452_46273)"/>
      </g>
      <g id="Ellipse 615">
      <mask id="path-50-inside-25_452_46273" fill="white">
      <ellipse cx="15.9834" cy="97.2244" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 97.2244C15.4317 97.302 15.4049 97.3062 15.4458 97.2551C15.4896 97.2003 15.667 97.0348 15.9834 97.0348V101.035C18.2813 101.035 19.4317 98.8893 19.4317 97.2244H15.4317ZM15.9834 97.0348C16.2999 97.0348 16.4773 97.2003 16.5211 97.2551C16.562 97.3062 16.5352 97.302 16.5352 97.2244H12.5352C12.5352 98.8893 13.6856 101.035 15.9834 101.035V97.0348ZM16.5352 97.2244C16.5352 97.1468 16.562 97.1426 16.5211 97.1937C16.4773 97.2485 16.2999 97.4141 15.9834 97.4141V93.4141C13.6856 93.4141 12.5352 95.5595 12.5352 97.2244H16.5352ZM15.9834 97.4141C15.667 97.4141 15.4896 97.2485 15.4458 97.1937C15.4049 97.1426 15.4317 97.1468 15.4317 97.2244H19.4317C19.4317 95.5595 18.2813 93.4141 15.9834 93.4141V97.4141Z" fill="#2E72D2" mask="url(#path-50-inside-25_452_46273)"/>
      </g>
      <g id="Ellipse 620">
      <mask id="path-52-inside-26_452_46273" fill="white">
      <ellipse cx="15.9834" cy="118.224" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 118.224C15.4317 118.302 15.4049 118.306 15.4458 118.255C15.4896 118.2 15.667 118.035 15.9834 118.035V122.035C18.2813 122.035 19.4317 119.889 19.4317 118.224H15.4317ZM15.9834 118.035C16.2999 118.035 16.4773 118.2 16.5211 118.255C16.562 118.306 16.5352 118.302 16.5352 118.224H12.5352C12.5352 119.889 13.6856 122.035 15.9834 122.035V118.035ZM16.5352 118.224C16.5352 118.147 16.562 118.143 16.5211 118.194C16.4773 118.248 16.2999 118.414 15.9834 118.414V114.414C13.6856 114.414 12.5352 116.56 12.5352 118.224H16.5352ZM15.9834 118.414C15.667 118.414 15.4896 118.248 15.4458 118.194C15.4049 118.143 15.4317 118.147 15.4317 118.224H19.4317C19.4317 116.56 18.2813 114.414 15.9834 114.414V118.414Z" fill="#2E72D2" mask="url(#path-52-inside-26_452_46273)"/>
      </g>
      <g id="Ellipse 626">
      <mask id="path-54-inside-27_452_46273" fill="white">
      <ellipse cx="15.9834" cy="138.5" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 138.5C15.4317 138.577 15.4049 138.582 15.4458 138.531C15.4896 138.476 15.667 138.31 15.9834 138.31V142.31C18.2813 142.31 19.4317 140.165 19.4317 138.5H15.4317ZM15.9834 138.31C16.2999 138.31 16.4773 138.476 16.5211 138.531C16.562 138.582 16.5352 138.577 16.5352 138.5H12.5352C12.5352 140.165 13.6856 142.31 15.9834 142.31V138.31ZM16.5352 138.5C16.5352 138.422 16.562 138.418 16.5211 138.469C16.4773 138.524 16.2999 138.689 15.9834 138.689V134.689C13.6856 134.689 12.5352 136.835 12.5352 138.5H16.5352ZM15.9834 138.689C15.667 138.689 15.4896 138.524 15.4458 138.469C15.4049 138.418 15.4317 138.422 15.4317 138.5H19.4317C19.4317 136.835 18.2813 134.689 15.9834 134.689V138.689Z" fill="#2E72D2" mask="url(#path-54-inside-27_452_46273)"/>
      </g>
      <g id="Ellipse 632">
      <mask id="path-56-inside-28_452_46273" fill="white">
      <ellipse cx="15.9834" cy="159.5" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 159.5C15.4317 159.577 15.4049 159.582 15.4458 159.531C15.4896 159.476 15.667 159.31 15.9834 159.31V163.31C18.2813 163.31 19.4317 161.165 19.4317 159.5H15.4317ZM15.9834 159.31C16.2999 159.31 16.4773 159.476 16.5211 159.531C16.562 159.582 16.5352 159.577 16.5352 159.5H12.5352C12.5352 161.165 13.6856 163.31 15.9834 163.31V159.31ZM16.5352 159.5C16.5352 159.422 16.562 159.418 16.5211 159.469C16.4773 159.524 16.2999 159.689 15.9834 159.689V155.689C13.6856 155.689 12.5352 157.835 12.5352 159.5H16.5352ZM15.9834 159.689C15.667 159.689 15.4896 159.524 15.4458 159.469C15.4049 159.418 15.4317 159.422 15.4317 159.5H19.4317C19.4317 157.835 18.2813 155.689 15.9834 155.689V159.689Z" fill="#2E72D2" mask="url(#path-56-inside-28_452_46273)"/>
      </g>
      <g id="Ellipse 638">
      <mask id="path-58-inside-29_452_46273" fill="white">
      <ellipse cx="15.9834" cy="179.777" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 179.777C15.4317 179.855 15.4049 179.859 15.4458 179.808C15.4896 179.753 15.667 179.587 15.9834 179.587V183.587C18.2813 183.587 19.4317 181.442 19.4317 179.777H15.4317ZM15.9834 179.587C16.2999 179.587 16.4773 179.753 16.5211 179.808C16.562 179.859 16.5352 179.855 16.5352 179.777H12.5352C12.5352 181.442 13.6856 183.587 15.9834 183.587V179.587ZM16.5352 179.777C16.5352 179.7 16.562 179.695 16.5211 179.746C16.4773 179.801 16.2999 179.967 15.9834 179.967V175.967C13.6856 175.967 12.5352 178.112 12.5352 179.777H16.5352ZM15.9834 179.967C15.667 179.967 15.4896 179.801 15.4458 179.746C15.4049 179.695 15.4317 179.7 15.4317 179.777H19.4317C19.4317 178.112 18.2813 175.967 15.9834 175.967V179.967Z" fill="#2E72D2" mask="url(#path-58-inside-29_452_46273)"/>
      </g>
      <g id="Ellipse 644">
      <mask id="path-60-inside-30_452_46273" fill="white">
      <ellipse cx="15.9834" cy="200.775" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 200.775C15.4317 200.853 15.4049 200.857 15.4458 200.806C15.4896 200.751 15.667 200.586 15.9834 200.586V204.586C18.2813 204.586 19.4317 202.44 19.4317 200.775H15.4317ZM15.9834 200.586C16.2999 200.586 16.4773 200.751 16.5211 200.806C16.562 200.857 16.5352 200.853 16.5352 200.775H12.5352C12.5352 202.44 13.6856 204.586 15.9834 204.586V200.586ZM16.5352 200.775C16.5352 200.698 16.562 200.693 16.5211 200.744C16.4773 200.799 16.2999 200.965 15.9834 200.965V196.965C13.6856 196.965 12.5352 199.11 12.5352 200.775H16.5352ZM15.9834 200.965C15.667 200.965 15.4896 200.799 15.4458 200.744C15.4049 200.693 15.4317 200.698 15.4317 200.775H19.4317C19.4317 199.11 18.2813 196.965 15.9834 196.965V200.965Z" fill="#2E72D2" mask="url(#path-60-inside-30_452_46273)"/>
      </g>
      <g id="Ellipse 612">
      <mask id="path-62-inside-31_452_46273" fill="white">
      <ellipse cx="15.9834" cy="91.4314" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 91.4314C15.4317 91.5091 15.4049 91.5133 15.4458 91.4622C15.4896 91.4074 15.667 91.2418 15.9834 91.2418V95.2418C18.2813 95.2418 19.4317 93.0963 19.4317 91.4314H15.4317ZM15.9834 91.2418C16.2999 91.2418 16.4773 91.4074 16.5211 91.4622C16.562 91.5133 16.5352 91.5091 16.5352 91.4314H12.5352C12.5352 93.0963 13.6856 95.2418 15.9834 95.2418V91.2418ZM16.5352 91.4314C16.5352 91.3538 16.562 91.3496 16.5211 91.4007C16.4773 91.4555 16.2999 91.6211 15.9834 91.6211V87.6211C13.6856 87.6211 12.5352 89.7666 12.5352 91.4314H16.5352ZM15.9834 91.6211C15.667 91.6211 15.4896 91.4555 15.4458 91.4007C15.4049 91.3496 15.4317 91.3538 15.4317 91.4314H19.4317C19.4317 89.7666 18.2813 87.6211 15.9834 87.6211V91.6211Z" fill="#2E72D2" mask="url(#path-62-inside-31_452_46273)"/>
      </g>
      <g id="Ellipse 621">
      <mask id="path-64-inside-32_452_46273" fill="white">
      <ellipse cx="15.9834" cy="112.431" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 112.431C15.4317 112.509 15.4049 112.513 15.4458 112.462C15.4896 112.407 15.667 112.242 15.9834 112.242V116.242C18.2813 116.242 19.4317 114.096 19.4317 112.431H15.4317ZM15.9834 112.242C16.2999 112.242 16.4773 112.407 16.5211 112.462C16.562 112.513 16.5352 112.509 16.5352 112.431H12.5352C12.5352 114.096 13.6856 116.242 15.9834 116.242V112.242ZM16.5352 112.431C16.5352 112.354 16.562 112.35 16.5211 112.401C16.4773 112.456 16.2999 112.621 15.9834 112.621V108.621C13.6856 108.621 12.5352 110.767 12.5352 112.431H16.5352ZM15.9834 112.621C15.667 112.621 15.4896 112.456 15.4458 112.401C15.4049 112.35 15.4317 112.354 15.4317 112.431H19.4317C19.4317 110.767 18.2813 108.621 15.9834 108.621V112.621Z" fill="#2E72D2" mask="url(#path-64-inside-32_452_46273)"/>
      </g>
      <g id="Ellipse 627">
      <mask id="path-66-inside-33_452_46273" fill="white">
      <ellipse cx="15.9834" cy="131.982" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 131.982C15.4317 132.06 15.4049 132.064 15.4458 132.013C15.4896 131.958 15.667 131.793 15.9834 131.793V135.793C18.2813 135.793 19.4317 133.647 19.4317 131.982H15.4317ZM15.9834 131.793C16.2999 131.793 16.4773 131.958 16.5211 132.013C16.562 132.064 16.5352 132.06 16.5352 131.982H12.5352C12.5352 133.647 13.6856 135.793 15.9834 135.793V131.793ZM16.5352 131.982C16.5352 131.905 16.562 131.9 16.5211 131.952C16.4773 132.006 16.2999 132.172 15.9834 132.172V128.172C13.6856 128.172 12.5352 130.317 12.5352 131.982H16.5352ZM15.9834 132.172C15.667 132.172 15.4896 132.006 15.4458 131.952C15.4049 131.9 15.4317 131.905 15.4317 131.982H19.4317C19.4317 130.317 18.2813 128.172 15.9834 128.172V132.172Z" fill="#2E72D2" mask="url(#path-66-inside-33_452_46273)"/>
      </g>
      <g id="Ellipse 633">
      <mask id="path-68-inside-34_452_46273" fill="white">
      <ellipse cx="15.9834" cy="152.984" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 152.984C15.4317 153.062 15.4049 153.066 15.4458 153.015C15.4896 152.96 15.667 152.795 15.9834 152.795V156.795C18.2813 156.795 19.4317 154.649 19.4317 152.984H15.4317ZM15.9834 152.795C16.2999 152.795 16.4773 152.96 16.5211 153.015C16.562 153.066 16.5352 153.062 16.5352 152.984H12.5352C12.5352 154.649 13.6856 156.795 15.9834 156.795V152.795ZM16.5352 152.984C16.5352 152.907 16.562 152.902 16.5211 152.953C16.4773 153.008 16.2999 153.174 15.9834 153.174V149.174C13.6856 149.174 12.5352 151.319 12.5352 152.984H16.5352ZM15.9834 153.174C15.667 153.174 15.4896 153.008 15.4458 152.953C15.4049 152.902 15.4317 152.907 15.4317 152.984H19.4317C19.4317 151.319 18.2813 149.174 15.9834 149.174V153.174Z" fill="#2E72D2" mask="url(#path-68-inside-34_452_46273)"/>
      </g>
      <g id="Ellipse 639">
      <mask id="path-70-inside-35_452_46273" fill="white">
      <ellipse cx="15.9834" cy="173.982" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 173.982C15.4317 174.06 15.4049 174.064 15.4458 174.013C15.4896 173.958 15.667 173.793 15.9834 173.793V177.793C18.2813 177.793 19.4317 175.647 19.4317 173.982H15.4317ZM15.9834 173.793C16.2999 173.793 16.4773 173.958 16.5211 174.013C16.562 174.064 16.5352 174.06 16.5352 173.982H12.5352C12.5352 175.647 13.6856 177.793 15.9834 177.793V173.793ZM16.5352 173.982C16.5352 173.905 16.562 173.9 16.5211 173.952C16.4773 174.006 16.2999 174.172 15.9834 174.172V170.172C13.6856 170.172 12.5352 172.317 12.5352 173.982H16.5352ZM15.9834 174.172C15.667 174.172 15.4896 174.006 15.4458 173.952C15.4049 173.9 15.4317 173.905 15.4317 173.982H19.4317C19.4317 172.317 18.2813 170.172 15.9834 170.172V174.172Z" fill="#2E72D2" mask="url(#path-70-inside-35_452_46273)"/>
      </g>
      <g id="Ellipse 645">
      <mask id="path-72-inside-36_452_46273" fill="white">
      <ellipse cx="15.9834" cy="194.982" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 194.982C15.4317 195.06 15.4049 195.064 15.4458 195.013C15.4896 194.958 15.667 194.793 15.9834 194.793V198.793C18.2813 198.793 19.4317 196.647 19.4317 194.982H15.4317ZM15.9834 194.793C16.2999 194.793 16.4773 194.958 16.5211 195.013C16.562 195.064 16.5352 195.06 16.5352 194.982H12.5352C12.5352 196.647 13.6856 198.793 15.9834 198.793V194.793ZM16.5352 194.982C16.5352 194.905 16.562 194.9 16.5211 194.952C16.4773 195.006 16.2999 195.172 15.9834 195.172V191.172C13.6856 191.172 12.5352 193.317 12.5352 194.982H16.5352ZM15.9834 195.172C15.667 195.172 15.4896 195.006 15.4458 194.952C15.4049 194.9 15.4317 194.905 15.4317 194.982H19.4317C19.4317 193.317 18.2813 191.172 15.9834 191.172V195.172Z" fill="#2E72D2" mask="url(#path-72-inside-36_452_46273)"/>
      </g>
      <path id="Vector 306" d="M76.0782 2L1.62836 21.6465L1.5 221.684L33.2054 230.103L106.5 210.585V10.1648L76.0782 2Z" stroke="#2E72D2" stroke-width="2"/>
      <path id="Rectangle 2141" d="M34.3365 228.285L34.6977 30.7949L104.44 12.101L104.953 209.635L34.3365 228.285Z" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2142" d="M89.8477 26.2878C89.8477 25.4097 90.4203 24.6344 91.2595 24.3762L96.6733 22.7104C97.9594 22.3147 99.2614 23.2764 99.2614 24.622V200.73C99.2614 201.637 98.6502 202.431 97.7728 202.663L92.359 204.095C91.0902 204.43 89.8477 203.474 89.8477 202.161V26.2878Z" fill="#2E72D2"/>
      <path id="Vector 310" d="M1.5 21.9124L33.3621 30.24M33.3621 30.24L106.138 10.3262M33.3621 30.24V230.102" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2143" d="M97.8086 23H97.8948C98.4471 23 98.8948 23.4477 98.8948 24V201.627L97.8086 201.265V23Z" fill="white"/>
      <path id="Rectangle 2144" d="M90.2773 203.379L97.5187 201.689L98.536 202.079L91.5595 203.953C91.2142 204.046 90.8457 203.947 90.5929 203.695L90.2773 203.379Z" fill="white"/>
      </g>
      <g id="Red X">
      <circle id="Ellipse 577" cx="51" cy="115.5" r="39.5" fill="#EB3223"/>
      <path id="Vector 251" d="M32.5 97L69.5 134M32.5 134L69.5 97" stroke="white" stroke-width="8"/>
      </g>
      </g>
    </svg>

  )
}
NDSConnectionFailed.defaultProps = {
  width: 227
}

 

export function NDSConnectionSuccess({width, maxWidth})  {
  return(
    <svg className="NDSConnectionSuccess" width={width} maxWidth={maxWidth} viewBox="0 0 108 232" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1573">
      <g id="Group 1571">
      <path id="Vector 309" d="M76.0862 2L1.86207 21.5517L1.5 221.414L33.3621 230.103L106.5 210.552V10.3276L76.0862 2Z" fill="white"/>
      <g id="Ellipse 609">
      <mask id="path-2-inside-1_452_46177" fill="white">
      <ellipse cx="7.29398" cy="83.4646" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 83.4646C6.74225 83.5423 6.71544 83.5465 6.75633 83.4954C6.80015 83.4406 6.97751 83.275 7.29398 83.275V87.275C9.59181 87.275 10.7423 85.1295 10.7423 83.4646H6.74225ZM7.29398 83.275C7.61044 83.275 7.7878 83.4406 7.83163 83.4954C7.87252 83.5465 7.8457 83.5423 7.8457 83.4646H3.8457C3.8457 85.1295 4.99615 87.275 7.29398 87.275V83.275ZM7.8457 83.4646C7.8457 83.387 7.87252 83.3828 7.83163 83.4339C7.7878 83.4887 7.61044 83.6543 7.29398 83.6543V79.6543C4.99615 79.6543 3.8457 81.7998 3.8457 83.4646H7.8457ZM7.29398 83.6543C6.97751 83.6543 6.80015 83.4887 6.75633 83.4339C6.71544 83.3828 6.74225 83.387 6.74225 83.4646H10.7423C10.7423 81.7998 9.59181 79.6543 7.29398 79.6543V83.6543Z" fill="#2E72D2" mask="url(#path-2-inside-1_452_46177)"/>
      </g>
      <g id="Ellipse 616">
      <mask id="path-4-inside-2_452_46177" fill="white">
      <ellipse cx="7.29398" cy="104.465" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 104.465C6.74225 104.542 6.71544 104.546 6.75633 104.495C6.80015 104.441 6.97751 104.275 7.29398 104.275V108.275C9.59181 108.275 10.7423 106.13 10.7423 104.465H6.74225ZM7.29398 104.275C7.61044 104.275 7.7878 104.441 7.83163 104.495C7.87252 104.546 7.8457 104.542 7.8457 104.465H3.8457C3.8457 106.13 4.99615 108.275 7.29398 108.275V104.275ZM7.8457 104.465C7.8457 104.387 7.87252 104.383 7.83163 104.434C7.7878 104.489 7.61044 104.654 7.29398 104.654V100.654C4.99615 100.654 3.8457 102.8 3.8457 104.465H7.8457ZM7.29398 104.654C6.97751 104.654 6.80015 104.489 6.75633 104.434C6.71544 104.383 6.74225 104.387 6.74225 104.465H10.7423C10.7423 102.8 9.59181 100.654 7.29398 100.654V104.654Z" fill="#2E72D2" mask="url(#path-4-inside-2_452_46177)"/>
      </g>
      <g id="Ellipse 622">
      <mask id="path-6-inside-3_452_46177" fill="white">
      <ellipse cx="7.29398" cy="124.017" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 124.017C6.74225 124.095 6.71544 124.099 6.75633 124.048C6.80015 123.993 6.97751 123.828 7.29398 123.828V127.828C9.59181 127.828 10.7423 125.682 10.7423 124.017H6.74225ZM7.29398 123.828C7.61044 123.828 7.7878 123.993 7.83163 124.048C7.87252 124.099 7.8457 124.095 7.8457 124.017H3.8457C3.8457 125.682 4.99615 127.828 7.29398 127.828V123.828ZM7.8457 124.017C7.8457 123.94 7.87252 123.936 7.83163 123.987C7.7878 124.041 7.61044 124.207 7.29398 124.207V120.207C4.99615 120.207 3.8457 122.353 3.8457 124.017H7.8457ZM7.29398 124.207C6.97751 124.207 6.80015 124.041 6.75633 123.987C6.71544 123.936 6.74225 123.94 6.74225 124.017H10.7423C10.7423 122.353 9.59181 120.207 7.29398 120.207V124.207Z" fill="#2E72D2" mask="url(#path-6-inside-3_452_46177)"/>
      </g>
      <g id="Ellipse 628">
      <mask id="path-8-inside-4_452_46177" fill="white">
      <ellipse cx="7.29398" cy="145.017" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 145.017C6.74225 145.095 6.71544 145.099 6.75633 145.048C6.80015 144.993 6.97751 144.828 7.29398 144.828V148.828C9.59181 148.828 10.7423 146.682 10.7423 145.017H6.74225ZM7.29398 144.828C7.61044 144.828 7.7878 144.993 7.83163 145.048C7.87252 145.099 7.8457 145.095 7.8457 145.017H3.8457C3.8457 146.682 4.99615 148.828 7.29398 148.828V144.828ZM7.8457 145.017C7.8457 144.94 7.87252 144.936 7.83163 144.987C7.7878 145.041 7.61044 145.207 7.29398 145.207V141.207C4.99615 141.207 3.8457 143.353 3.8457 145.017H7.8457ZM7.29398 145.207C6.97751 145.207 6.80015 145.041 6.75633 144.987C6.71544 144.936 6.74225 144.94 6.74225 145.017H10.7423C10.7423 143.353 9.59181 141.207 7.29398 141.207V145.207Z" fill="#2E72D2" mask="url(#path-8-inside-4_452_46177)"/>
      </g>
      <g id="Ellipse 634">
      <mask id="path-10-inside-5_452_46177" fill="white">
      <ellipse cx="7.29398" cy="166.017" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 166.017C6.74225 166.095 6.71544 166.099 6.75633 166.048C6.80015 165.993 6.97751 165.828 7.29398 165.828V169.828C9.59181 169.828 10.7423 167.682 10.7423 166.017H6.74225ZM7.29398 165.828C7.61044 165.828 7.7878 165.993 7.83163 166.048C7.87252 166.099 7.8457 166.095 7.8457 166.017H3.8457C3.8457 167.682 4.99615 169.828 7.29398 169.828V165.828ZM7.8457 166.017C7.8457 165.94 7.87252 165.936 7.83163 165.987C7.7878 166.041 7.61044 166.207 7.29398 166.207V162.207C4.99615 162.207 3.8457 164.353 3.8457 166.017H7.8457ZM7.29398 166.207C6.97751 166.207 6.80015 166.041 6.75633 165.987C6.71544 165.936 6.74225 165.94 6.74225 166.017H10.7423C10.7423 164.353 9.59181 162.207 7.29398 162.207V166.207Z" fill="#2E72D2" mask="url(#path-10-inside-5_452_46177)"/>
      </g>
      <g id="Ellipse 640">
      <mask id="path-12-inside-6_452_46177" fill="white">
      <ellipse cx="7.29398" cy="186.293" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 186.293C6.74225 186.37 6.71544 186.375 6.75633 186.323C6.80015 186.269 6.97751 186.103 7.29398 186.103V190.103C9.59181 190.103 10.7423 187.958 10.7423 186.293H6.74225ZM7.29398 186.103C7.61044 186.103 7.7878 186.269 7.83163 186.323C7.87252 186.375 7.8457 186.37 7.8457 186.293H3.8457C3.8457 187.958 4.99615 190.103 7.29398 190.103V186.103ZM7.8457 186.293C7.8457 186.215 7.87252 186.211 7.83163 186.262C7.7878 186.317 7.61044 186.482 7.29398 186.482V182.482C4.99615 182.482 3.8457 184.628 3.8457 186.293H7.8457ZM7.29398 186.482C6.97751 186.482 6.80015 186.317 6.75633 186.262C6.71544 186.211 6.74225 186.215 6.74225 186.293H10.7423C10.7423 184.628 9.59181 182.482 7.29398 182.482V186.482Z" fill="#2E72D2" mask="url(#path-12-inside-6_452_46177)"/>
      </g>
      <g id="Ellipse 613">
      <mask id="path-14-inside-7_452_46177" fill="white">
      <ellipse cx="7.29398" cy="95.7752" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 95.7752C6.74225 95.8528 6.71544 95.857 6.75633 95.8059C6.80015 95.7511 6.97751 95.5855 7.29398 95.5855V99.5855C9.59181 99.5855 10.7423 97.4401 10.7423 95.7752H6.74225ZM7.29398 95.5855C7.61044 95.5855 7.7878 95.7511 7.83163 95.8059C7.87252 95.857 7.8457 95.8528 7.8457 95.7752H3.8457C3.8457 97.4401 4.99615 99.5855 7.29398 99.5855V95.5855ZM7.8457 95.7752C7.8457 95.6976 7.87252 95.6934 7.83163 95.7445C7.7878 95.7993 7.61044 95.9648 7.29398 95.9648V91.9648C4.99615 91.9648 3.8457 94.1103 3.8457 95.7752H7.8457ZM7.29398 95.9648C6.97751 95.9648 6.80015 95.7993 6.75633 95.7445C6.71544 95.6934 6.74225 95.6976 6.74225 95.7752H10.7423C10.7423 94.1103 9.59181 91.9648 7.29398 91.9648V95.9648Z" fill="#2E72D2" mask="url(#path-14-inside-7_452_46177)"/>
      </g>
      <g id="Ellipse 617">
      <mask id="path-16-inside-8_452_46177" fill="white">
      <ellipse cx="7.29398" cy="116.051" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 116.051C6.74225 116.128 6.71544 116.132 6.75633 116.081C6.80015 116.027 6.97751 115.861 7.29398 115.861V119.861C9.59181 119.861 10.7423 117.715 10.7423 116.051H6.74225ZM7.29398 115.861C7.61044 115.861 7.7878 116.027 7.83163 116.081C7.87252 116.132 7.8457 116.128 7.8457 116.051H3.8457C3.8457 117.715 4.99615 119.861 7.29398 119.861V115.861ZM7.8457 116.051C7.8457 115.973 7.87252 115.969 7.83163 116.02C7.7878 116.075 7.61044 116.24 7.29398 116.24V112.24C4.99615 112.24 3.8457 114.386 3.8457 116.051H7.8457ZM7.29398 116.24C6.97751 116.24 6.80015 116.075 6.75633 116.02C6.71544 115.969 6.74225 115.973 6.74225 116.051H10.7423C10.7423 114.386 9.59181 112.24 7.29398 112.24V116.24Z" fill="#2E72D2" mask="url(#path-16-inside-8_452_46177)"/>
      </g>
      <g id="Ellipse 623">
      <mask id="path-18-inside-9_452_46177" fill="white">
      <ellipse cx="7.29398" cy="136.328" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 136.328C6.74225 136.406 6.71544 136.41 6.75633 136.359C6.80015 136.304 6.97751 136.138 7.29398 136.138V140.138C9.59181 140.138 10.7423 137.993 10.7423 136.328H6.74225ZM7.29398 136.138C7.61044 136.138 7.7878 136.304 7.83163 136.359C7.87252 136.41 7.8457 136.406 7.8457 136.328H3.8457C3.8457 137.993 4.99615 140.138 7.29398 140.138V136.138ZM7.8457 136.328C7.8457 136.25 7.87252 136.246 7.83163 136.297C7.7878 136.352 7.61044 136.518 7.29398 136.518V132.518C4.99615 132.518 3.8457 134.663 3.8457 136.328H7.8457ZM7.29398 136.518C6.97751 136.518 6.80015 136.352 6.75633 136.297C6.71544 136.246 6.74225 136.25 6.74225 136.328H10.7423C10.7423 134.663 9.59181 132.518 7.29398 132.518V136.518Z" fill="#2E72D2" mask="url(#path-18-inside-9_452_46177)"/>
      </g>
      <g id="Ellipse 629">
      <mask id="path-20-inside-10_452_46177" fill="white">
      <ellipse cx="7.29398" cy="157.328" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 157.328C6.74225 157.406 6.71544 157.41 6.75633 157.359C6.80015 157.304 6.97751 157.138 7.29398 157.138V161.138C9.59181 161.138 10.7423 158.993 10.7423 157.328H6.74225ZM7.29398 157.138C7.61044 157.138 7.7878 157.304 7.83163 157.359C7.87252 157.41 7.8457 157.406 7.8457 157.328H3.8457C3.8457 158.993 4.99615 161.138 7.29398 161.138V157.138ZM7.8457 157.328C7.8457 157.25 7.87252 157.246 7.83163 157.297C7.7878 157.352 7.61044 157.518 7.29398 157.518V153.518C4.99615 153.518 3.8457 155.663 3.8457 157.328H7.8457ZM7.29398 157.518C6.97751 157.518 6.80015 157.352 6.75633 157.297C6.71544 157.246 6.74225 157.25 6.74225 157.328H10.7423C10.7423 155.663 9.59181 153.518 7.29398 153.518V157.518Z" fill="#2E72D2" mask="url(#path-20-inside-10_452_46177)"/>
      </g>
      <g id="Ellipse 635">
      <mask id="path-22-inside-11_452_46177" fill="white">
      <ellipse cx="7.29398" cy="177.603" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 177.603C6.74225 177.681 6.71544 177.685 6.75633 177.634C6.80015 177.579 6.97751 177.414 7.29398 177.414V181.414C9.59181 181.414 10.7423 179.268 10.7423 177.603H6.74225ZM7.29398 177.414C7.61044 177.414 7.7878 177.579 7.83163 177.634C7.87252 177.685 7.8457 177.681 7.8457 177.603H3.8457C3.8457 179.268 4.99615 181.414 7.29398 181.414V177.414ZM7.8457 177.603C7.8457 177.526 7.87252 177.521 7.83163 177.573C7.7878 177.627 7.61044 177.793 7.29398 177.793V173.793C4.99615 173.793 3.8457 175.938 3.8457 177.603H7.8457ZM7.29398 177.793C6.97751 177.793 6.80015 177.627 6.75633 177.573C6.71544 177.521 6.74225 177.526 6.74225 177.603H10.7423C10.7423 175.938 9.59181 173.793 7.29398 173.793V177.793Z" fill="#2E72D2" mask="url(#path-22-inside-11_452_46177)"/>
      </g>
      <g id="Ellipse 641">
      <mask id="path-24-inside-12_452_46177" fill="white">
      <ellipse cx="7.29398" cy="197.879" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 197.879C6.74225 197.956 6.71544 197.961 6.75633 197.909C6.80015 197.855 6.97751 197.689 7.29398 197.689V201.689C9.59181 201.689 10.7423 199.544 10.7423 197.879H6.74225ZM7.29398 197.689C7.61044 197.689 7.7878 197.855 7.83163 197.909C7.87252 197.961 7.8457 197.956 7.8457 197.879H3.8457C3.8457 199.544 4.99615 201.689 7.29398 201.689V197.689ZM7.8457 197.879C7.8457 197.801 7.87252 197.797 7.83163 197.848C7.7878 197.903 7.61044 198.068 7.29398 198.068V194.068C4.99615 194.068 3.8457 196.214 3.8457 197.879H7.8457ZM7.29398 198.068C6.97751 198.068 6.80015 197.903 6.75633 197.848C6.71544 197.797 6.74225 197.801 6.74225 197.879H10.7423C10.7423 196.214 9.59181 194.068 7.29398 194.068V198.068Z" fill="#2E72D2" mask="url(#path-24-inside-12_452_46177)"/>
      </g>
      <g id="Ellipse 611">
      <mask id="path-26-inside-13_452_46177" fill="white">
      <ellipse cx="7.29398" cy="89.2576" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 89.2576C6.74225 89.3352 6.71544 89.3394 6.75633 89.2883C6.80015 89.2335 6.97751 89.068 7.29398 89.068V93.068C9.59181 93.068 10.7423 90.9225 10.7423 89.2576H6.74225ZM7.29398 89.068C7.61044 89.068 7.7878 89.2335 7.83163 89.2883C7.87252 89.3394 7.8457 89.3352 7.8457 89.2576H3.8457C3.8457 90.9225 4.99615 93.068 7.29398 93.068V89.068ZM7.8457 89.2576C7.8457 89.18 7.87252 89.1758 7.83163 89.2269C7.7878 89.2817 7.61044 89.4473 7.29398 89.4473V85.4473C4.99615 85.4473 3.8457 87.5927 3.8457 89.2576H7.8457ZM7.29398 89.4473C6.97751 89.4473 6.80015 89.2817 6.75633 89.2269C6.71544 89.1758 6.74225 89.18 6.74225 89.2576H10.7423C10.7423 87.5927 9.59181 85.4473 7.29398 85.4473V89.4473Z" fill="#2E72D2" mask="url(#path-26-inside-13_452_46177)"/>
      </g>
      <g id="Ellipse 618">
      <mask id="path-28-inside-14_452_46177" fill="white">
      <ellipse cx="7.29398" cy="110.258" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 110.258C6.74225 110.335 6.71544 110.339 6.75633 110.288C6.80015 110.234 6.97751 110.068 7.29398 110.068V114.068C9.59181 114.068 10.7423 111.922 10.7423 110.258H6.74225ZM7.29398 110.068C7.61044 110.068 7.7878 110.234 7.83163 110.288C7.87252 110.339 7.8457 110.335 7.8457 110.258H3.8457C3.8457 111.922 4.99615 114.068 7.29398 114.068V110.068ZM7.8457 110.258C7.8457 110.18 7.87252 110.176 7.83163 110.227C7.7878 110.282 7.61044 110.447 7.29398 110.447V106.447C4.99615 106.447 3.8457 108.593 3.8457 110.258H7.8457ZM7.29398 110.447C6.97751 110.447 6.80015 110.282 6.75633 110.227C6.71544 110.176 6.74225 110.18 6.74225 110.258H10.7423C10.7423 108.593 9.59181 106.447 7.29398 106.447V110.447Z" fill="#2E72D2" mask="url(#path-28-inside-14_452_46177)"/>
      </g>
      <g id="Ellipse 624">
      <mask id="path-30-inside-15_452_46177" fill="white">
      <ellipse cx="7.29398" cy="129.81" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 129.81C6.74225 129.888 6.71544 129.892 6.75633 129.841C6.80015 129.786 6.97751 129.621 7.29398 129.621V133.621C9.59181 133.621 10.7423 131.475 10.7423 129.81H6.74225ZM7.29398 129.621C7.61044 129.621 7.7878 129.786 7.83163 129.841C7.87252 129.892 7.8457 129.888 7.8457 129.81H3.8457C3.8457 131.475 4.99615 133.621 7.29398 133.621V129.621ZM7.8457 129.81C7.8457 129.733 7.87252 129.729 7.83163 129.78C7.7878 129.834 7.61044 130 7.29398 130V126C4.99615 126 3.8457 128.145 3.8457 129.81H7.8457ZM7.29398 130C6.97751 130 6.80015 129.834 6.75633 129.78C6.71544 129.729 6.74225 129.733 6.74225 129.81H10.7423C10.7423 128.145 9.59181 126 7.29398 126V130Z" fill="#2E72D2" mask="url(#path-30-inside-15_452_46177)"/>
      </g>
      <g id="Ellipse 630">
      <mask id="path-32-inside-16_452_46177" fill="white">
      <ellipse cx="7.29398" cy="150.81" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 150.81C6.74225 150.888 6.71544 150.892 6.75633 150.841C6.80015 150.786 6.97751 150.621 7.29398 150.621V154.621C9.59181 154.621 10.7423 152.475 10.7423 150.81H6.74225ZM7.29398 150.621C7.61044 150.621 7.7878 150.786 7.83163 150.841C7.87252 150.892 7.8457 150.888 7.8457 150.81H3.8457C3.8457 152.475 4.99615 154.621 7.29398 154.621V150.621ZM7.8457 150.81C7.8457 150.733 7.87252 150.729 7.83163 150.78C7.7878 150.834 7.61044 151 7.29398 151V147C4.99615 147 3.8457 149.145 3.8457 150.81H7.8457ZM7.29398 151C6.97751 151 6.80015 150.834 6.75633 150.78C6.71544 150.729 6.74225 150.733 6.74225 150.81H10.7423C10.7423 149.145 9.59181 147 7.29398 147V151Z" fill="#2E72D2" mask="url(#path-32-inside-16_452_46177)"/>
      </g>
      <g id="Ellipse 636">
      <mask id="path-34-inside-17_452_46177" fill="white">
      <ellipse cx="7.29398" cy="171.81" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 171.81C6.74225 171.888 6.71544 171.892 6.75633 171.841C6.80015 171.786 6.97751 171.621 7.29398 171.621V175.621C9.59181 175.621 10.7423 173.475 10.7423 171.81H6.74225ZM7.29398 171.621C7.61044 171.621 7.7878 171.786 7.83163 171.841C7.87252 171.892 7.8457 171.888 7.8457 171.81H3.8457C3.8457 173.475 4.99615 175.621 7.29398 175.621V171.621ZM7.8457 171.81C7.8457 171.733 7.87252 171.729 7.83163 171.78C7.7878 171.834 7.61044 172 7.29398 172V168C4.99615 168 3.8457 170.145 3.8457 171.81H7.8457ZM7.29398 172C6.97751 172 6.80015 171.834 6.75633 171.78C6.71544 171.729 6.74225 171.733 6.74225 171.81H10.7423C10.7423 170.145 9.59181 168 7.29398 168V172Z" fill="#2E72D2" mask="url(#path-34-inside-17_452_46177)"/>
      </g>
      <g id="Ellipse 642">
      <mask id="path-36-inside-18_452_46177" fill="white">
      <ellipse cx="7.29398" cy="192.088" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.74225 192.088C6.74225 192.165 6.71544 192.17 6.75633 192.118C6.80015 192.064 6.97751 191.898 7.29398 191.898V195.898C9.59181 195.898 10.7423 193.753 10.7423 192.088H6.74225ZM7.29398 191.898C7.61044 191.898 7.7878 192.064 7.83163 192.118C7.87252 192.17 7.8457 192.165 7.8457 192.088H3.8457C3.8457 193.753 4.99615 195.898 7.29398 195.898V191.898ZM7.8457 192.088C7.8457 192.01 7.87252 192.006 7.83163 192.057C7.7878 192.112 7.61044 192.277 7.29398 192.277V188.277C4.99615 188.277 3.8457 190.423 3.8457 192.088H7.8457ZM7.29398 192.277C6.97751 192.277 6.80015 192.112 6.75633 192.057C6.71544 192.006 6.74225 192.01 6.74225 192.088H10.7423C10.7423 190.423 9.59181 188.277 7.29398 188.277V192.277Z" fill="#2E72D2" mask="url(#path-36-inside-18_452_46177)"/>
      </g>
      <g id="Ellipse 610">
      <mask id="path-38-inside-19_452_46177" fill="white">
      <ellipse cx="15.9834" cy="85.6385" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 85.6385C15.4317 85.7161 15.4049 85.7203 15.4458 85.6692C15.4896 85.6144 15.667 85.4488 15.9834 85.4488V89.4488C18.2813 89.4488 19.4317 87.3033 19.4317 85.6385H15.4317ZM15.9834 85.4488C16.2999 85.4488 16.4773 85.6144 16.5211 85.6692C16.562 85.7203 16.5352 85.7161 16.5352 85.6385H12.5352C12.5352 87.3033 13.6856 89.4488 15.9834 89.4488V85.4488ZM16.5352 85.6385C16.5352 85.5609 16.562 85.5566 16.5211 85.6078C16.4773 85.6625 16.2999 85.8281 15.9834 85.8281V81.8281C13.6856 81.8281 12.5352 83.9736 12.5352 85.6385H16.5352ZM15.9834 85.8281C15.667 85.8281 15.4896 85.6625 15.4458 85.6078C15.4049 85.5566 15.4317 85.5609 15.4317 85.6385H19.4317C19.4317 83.9736 18.2813 81.8281 15.9834 81.8281V85.8281Z" fill="#2E72D2" mask="url(#path-38-inside-19_452_46177)"/>
      </g>
      <g id="Ellipse 619">
      <mask id="path-40-inside-20_452_46177" fill="white">
      <ellipse cx="15.9834" cy="106.638" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 106.638C15.4317 106.716 15.4049 106.72 15.4458 106.669C15.4896 106.614 15.667 106.449 15.9834 106.449V110.449C18.2813 110.449 19.4317 108.303 19.4317 106.638H15.4317ZM15.9834 106.449C16.2999 106.449 16.4773 106.614 16.5211 106.669C16.562 106.72 16.5352 106.716 16.5352 106.638H12.5352C12.5352 108.303 13.6856 110.449 15.9834 110.449V106.449ZM16.5352 106.638C16.5352 106.561 16.562 106.557 16.5211 106.608C16.4773 106.663 16.2999 106.828 15.9834 106.828V102.828C13.6856 102.828 12.5352 104.974 12.5352 106.638H16.5352ZM15.9834 106.828C15.667 106.828 15.4896 106.663 15.4458 106.608C15.4049 106.557 15.4317 106.561 15.4317 106.638H19.4317C19.4317 104.974 18.2813 102.828 15.9834 102.828V106.828Z" fill="#2E72D2" mask="url(#path-40-inside-20_452_46177)"/>
      </g>
      <g id="Ellipse 625">
      <mask id="path-42-inside-21_452_46177" fill="white">
      <ellipse cx="15.9834" cy="126.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 126.189C15.4317 126.267 15.4049 126.271 15.4458 126.22C15.4896 126.165 15.667 126 15.9834 126V130C18.2813 130 19.4317 127.854 19.4317 126.189H15.4317ZM15.9834 126C16.2999 126 16.4773 126.165 16.5211 126.22C16.562 126.271 16.5352 126.267 16.5352 126.189H12.5352C12.5352 127.854 13.6856 130 15.9834 130V126ZM16.5352 126.189C16.5352 126.112 16.562 126.107 16.5211 126.159C16.4773 126.213 16.2999 126.379 15.9834 126.379V122.379C13.6856 122.379 12.5352 124.524 12.5352 126.189H16.5352ZM15.9834 126.379C15.667 126.379 15.4896 126.213 15.4458 126.159C15.4049 126.107 15.4317 126.112 15.4317 126.189H19.4317C19.4317 124.524 18.2813 122.379 15.9834 122.379V126.379Z" fill="#2E72D2" mask="url(#path-42-inside-21_452_46177)"/>
      </g>
      <g id="Ellipse 631">
      <mask id="path-44-inside-22_452_46177" fill="white">
      <ellipse cx="15.9834" cy="147.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 147.189C15.4317 147.267 15.4049 147.271 15.4458 147.22C15.4896 147.165 15.667 147 15.9834 147V151C18.2813 151 19.4317 148.854 19.4317 147.189H15.4317ZM15.9834 147C16.2999 147 16.4773 147.165 16.5211 147.22C16.562 147.271 16.5352 147.267 16.5352 147.189H12.5352C12.5352 148.854 13.6856 151 15.9834 151V147ZM16.5352 147.189C16.5352 147.112 16.562 147.107 16.5211 147.159C16.4773 147.213 16.2999 147.379 15.9834 147.379V143.379C13.6856 143.379 12.5352 145.524 12.5352 147.189H16.5352ZM15.9834 147.379C15.667 147.379 15.4896 147.213 15.4458 147.159C15.4049 147.107 15.4317 147.112 15.4317 147.189H19.4317C19.4317 145.524 18.2813 143.379 15.9834 143.379V147.379Z" fill="#2E72D2" mask="url(#path-44-inside-22_452_46177)"/>
      </g>
      <g id="Ellipse 637">
      <mask id="path-46-inside-23_452_46177" fill="white">
      <ellipse cx="15.9834" cy="168.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 168.189C15.4317 168.267 15.4049 168.271 15.4458 168.22C15.4896 168.165 15.667 168 15.9834 168V172C18.2813 172 19.4317 169.854 19.4317 168.189H15.4317ZM15.9834 168C16.2999 168 16.4773 168.165 16.5211 168.22C16.562 168.271 16.5352 168.267 16.5352 168.189H12.5352C12.5352 169.854 13.6856 172 15.9834 172V168ZM16.5352 168.189C16.5352 168.112 16.562 168.107 16.5211 168.159C16.4773 168.213 16.2999 168.379 15.9834 168.379V164.379C13.6856 164.379 12.5352 166.524 12.5352 168.189H16.5352ZM15.9834 168.379C15.667 168.379 15.4896 168.213 15.4458 168.159C15.4049 168.107 15.4317 168.112 15.4317 168.189H19.4317C19.4317 166.524 18.2813 164.379 15.9834 164.379V168.379Z" fill="#2E72D2" mask="url(#path-46-inside-23_452_46177)"/>
      </g>
      <g id="Ellipse 643">
      <mask id="path-48-inside-24_452_46177" fill="white">
      <ellipse cx="15.9834" cy="189.189" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 189.189C15.4317 189.267 15.4049 189.271 15.4458 189.22C15.4896 189.165 15.667 189 15.9834 189V193C18.2813 193 19.4317 190.854 19.4317 189.189H15.4317ZM15.9834 189C16.2999 189 16.4773 189.165 16.5211 189.22C16.562 189.271 16.5352 189.267 16.5352 189.189H12.5352C12.5352 190.854 13.6856 193 15.9834 193V189ZM16.5352 189.189C16.5352 189.112 16.562 189.107 16.5211 189.159C16.4773 189.213 16.2999 189.379 15.9834 189.379V185.379C13.6856 185.379 12.5352 187.524 12.5352 189.189H16.5352ZM15.9834 189.379C15.667 189.379 15.4896 189.213 15.4458 189.159C15.4049 189.107 15.4317 189.112 15.4317 189.189H19.4317C19.4317 187.524 18.2813 185.379 15.9834 185.379V189.379Z" fill="#2E72D2" mask="url(#path-48-inside-24_452_46177)"/>
      </g>
      <g id="Ellipse 615">
      <mask id="path-50-inside-25_452_46177" fill="white">
      <ellipse cx="15.9834" cy="97.2244" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 97.2244C15.4317 97.302 15.4049 97.3062 15.4458 97.2551C15.4896 97.2003 15.667 97.0348 15.9834 97.0348V101.035C18.2813 101.035 19.4317 98.8893 19.4317 97.2244H15.4317ZM15.9834 97.0348C16.2999 97.0348 16.4773 97.2003 16.5211 97.2551C16.562 97.3062 16.5352 97.302 16.5352 97.2244H12.5352C12.5352 98.8893 13.6856 101.035 15.9834 101.035V97.0348ZM16.5352 97.2244C16.5352 97.1468 16.562 97.1426 16.5211 97.1937C16.4773 97.2485 16.2999 97.4141 15.9834 97.4141V93.4141C13.6856 93.4141 12.5352 95.5595 12.5352 97.2244H16.5352ZM15.9834 97.4141C15.667 97.4141 15.4896 97.2485 15.4458 97.1937C15.4049 97.1426 15.4317 97.1468 15.4317 97.2244H19.4317C19.4317 95.5595 18.2813 93.4141 15.9834 93.4141V97.4141Z" fill="#2E72D2" mask="url(#path-50-inside-25_452_46177)"/>
      </g>
      <g id="Ellipse 620">
      <mask id="path-52-inside-26_452_46177" fill="white">
      <ellipse cx="15.9834" cy="118.224" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 118.224C15.4317 118.302 15.4049 118.306 15.4458 118.255C15.4896 118.2 15.667 118.035 15.9834 118.035V122.035C18.2813 122.035 19.4317 119.889 19.4317 118.224H15.4317ZM15.9834 118.035C16.2999 118.035 16.4773 118.2 16.5211 118.255C16.562 118.306 16.5352 118.302 16.5352 118.224H12.5352C12.5352 119.889 13.6856 122.035 15.9834 122.035V118.035ZM16.5352 118.224C16.5352 118.147 16.562 118.143 16.5211 118.194C16.4773 118.248 16.2999 118.414 15.9834 118.414V114.414C13.6856 114.414 12.5352 116.56 12.5352 118.224H16.5352ZM15.9834 118.414C15.667 118.414 15.4896 118.248 15.4458 118.194C15.4049 118.143 15.4317 118.147 15.4317 118.224H19.4317C19.4317 116.56 18.2813 114.414 15.9834 114.414V118.414Z" fill="#2E72D2" mask="url(#path-52-inside-26_452_46177)"/>
      </g>
      <g id="Ellipse 626">
      <mask id="path-54-inside-27_452_46177" fill="white">
      <ellipse cx="15.9834" cy="138.5" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 138.5C15.4317 138.577 15.4049 138.582 15.4458 138.531C15.4896 138.476 15.667 138.31 15.9834 138.31V142.31C18.2813 142.31 19.4317 140.165 19.4317 138.5H15.4317ZM15.9834 138.31C16.2999 138.31 16.4773 138.476 16.5211 138.531C16.562 138.582 16.5352 138.577 16.5352 138.5H12.5352C12.5352 140.165 13.6856 142.31 15.9834 142.31V138.31ZM16.5352 138.5C16.5352 138.422 16.562 138.418 16.5211 138.469C16.4773 138.524 16.2999 138.689 15.9834 138.689V134.689C13.6856 134.689 12.5352 136.835 12.5352 138.5H16.5352ZM15.9834 138.689C15.667 138.689 15.4896 138.524 15.4458 138.469C15.4049 138.418 15.4317 138.422 15.4317 138.5H19.4317C19.4317 136.835 18.2813 134.689 15.9834 134.689V138.689Z" fill="#2E72D2" mask="url(#path-54-inside-27_452_46177)"/>
      </g>
      <g id="Ellipse 632">
      <mask id="path-56-inside-28_452_46177" fill="white">
      <ellipse cx="15.9834" cy="159.5" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 159.5C15.4317 159.577 15.4049 159.582 15.4458 159.531C15.4896 159.476 15.667 159.31 15.9834 159.31V163.31C18.2813 163.31 19.4317 161.165 19.4317 159.5H15.4317ZM15.9834 159.31C16.2999 159.31 16.4773 159.476 16.5211 159.531C16.562 159.582 16.5352 159.577 16.5352 159.5H12.5352C12.5352 161.165 13.6856 163.31 15.9834 163.31V159.31ZM16.5352 159.5C16.5352 159.422 16.562 159.418 16.5211 159.469C16.4773 159.524 16.2999 159.689 15.9834 159.689V155.689C13.6856 155.689 12.5352 157.835 12.5352 159.5H16.5352ZM15.9834 159.689C15.667 159.689 15.4896 159.524 15.4458 159.469C15.4049 159.418 15.4317 159.422 15.4317 159.5H19.4317C19.4317 157.835 18.2813 155.689 15.9834 155.689V159.689Z" fill="#2E72D2" mask="url(#path-56-inside-28_452_46177)"/>
      </g>
      <g id="Ellipse 638">
      <mask id="path-58-inside-29_452_46177" fill="white">
      <ellipse cx="15.9834" cy="179.777" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 179.777C15.4317 179.855 15.4049 179.859 15.4458 179.808C15.4896 179.753 15.667 179.587 15.9834 179.587V183.587C18.2813 183.587 19.4317 181.442 19.4317 179.777H15.4317ZM15.9834 179.587C16.2999 179.587 16.4773 179.753 16.5211 179.808C16.562 179.859 16.5352 179.855 16.5352 179.777H12.5352C12.5352 181.442 13.6856 183.587 15.9834 183.587V179.587ZM16.5352 179.777C16.5352 179.7 16.562 179.695 16.5211 179.746C16.4773 179.801 16.2999 179.967 15.9834 179.967V175.967C13.6856 175.967 12.5352 178.112 12.5352 179.777H16.5352ZM15.9834 179.967C15.667 179.967 15.4896 179.801 15.4458 179.746C15.4049 179.695 15.4317 179.7 15.4317 179.777H19.4317C19.4317 178.112 18.2813 175.967 15.9834 175.967V179.967Z" fill="#2E72D2" mask="url(#path-58-inside-29_452_46177)"/>
      </g>
      <g id="Ellipse 644">
      <mask id="path-60-inside-30_452_46177" fill="white">
      <ellipse cx="15.9834" cy="200.775" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 200.775C15.4317 200.853 15.4049 200.857 15.4458 200.806C15.4896 200.751 15.667 200.586 15.9834 200.586V204.586C18.2813 204.586 19.4317 202.44 19.4317 200.775H15.4317ZM15.9834 200.586C16.2999 200.586 16.4773 200.751 16.5211 200.806C16.562 200.857 16.5352 200.853 16.5352 200.775H12.5352C12.5352 202.44 13.6856 204.586 15.9834 204.586V200.586ZM16.5352 200.775C16.5352 200.698 16.562 200.693 16.5211 200.744C16.4773 200.799 16.2999 200.965 15.9834 200.965V196.965C13.6856 196.965 12.5352 199.11 12.5352 200.775H16.5352ZM15.9834 200.965C15.667 200.965 15.4896 200.799 15.4458 200.744C15.4049 200.693 15.4317 200.698 15.4317 200.775H19.4317C19.4317 199.11 18.2813 196.965 15.9834 196.965V200.965Z" fill="#2E72D2" mask="url(#path-60-inside-30_452_46177)"/>
      </g>
      <g id="Ellipse 612">
      <mask id="path-62-inside-31_452_46177" fill="white">
      <ellipse cx="15.9834" cy="91.4314" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 91.4314C15.4317 91.5091 15.4049 91.5133 15.4458 91.4622C15.4896 91.4074 15.667 91.2418 15.9834 91.2418V95.2418C18.2813 95.2418 19.4317 93.0963 19.4317 91.4314H15.4317ZM15.9834 91.2418C16.2999 91.2418 16.4773 91.4074 16.5211 91.4622C16.562 91.5133 16.5352 91.5091 16.5352 91.4314H12.5352C12.5352 93.0963 13.6856 95.2418 15.9834 95.2418V91.2418ZM16.5352 91.4314C16.5352 91.3538 16.562 91.3496 16.5211 91.4007C16.4773 91.4555 16.2999 91.6211 15.9834 91.6211V87.6211C13.6856 87.6211 12.5352 89.7666 12.5352 91.4314H16.5352ZM15.9834 91.6211C15.667 91.6211 15.4896 91.4555 15.4458 91.4007C15.4049 91.3496 15.4317 91.3538 15.4317 91.4314H19.4317C19.4317 89.7666 18.2813 87.6211 15.9834 87.6211V91.6211Z" fill="#2E72D2" mask="url(#path-62-inside-31_452_46177)"/>
      </g>
      <g id="Ellipse 621">
      <mask id="path-64-inside-32_452_46177" fill="white">
      <ellipse cx="15.9834" cy="112.431" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 112.431C15.4317 112.509 15.4049 112.513 15.4458 112.462C15.4896 112.407 15.667 112.242 15.9834 112.242V116.242C18.2813 116.242 19.4317 114.096 19.4317 112.431H15.4317ZM15.9834 112.242C16.2999 112.242 16.4773 112.407 16.5211 112.462C16.562 112.513 16.5352 112.509 16.5352 112.431H12.5352C12.5352 114.096 13.6856 116.242 15.9834 116.242V112.242ZM16.5352 112.431C16.5352 112.354 16.562 112.35 16.5211 112.401C16.4773 112.456 16.2999 112.621 15.9834 112.621V108.621C13.6856 108.621 12.5352 110.767 12.5352 112.431H16.5352ZM15.9834 112.621C15.667 112.621 15.4896 112.456 15.4458 112.401C15.4049 112.35 15.4317 112.354 15.4317 112.431H19.4317C19.4317 110.767 18.2813 108.621 15.9834 108.621V112.621Z" fill="#2E72D2" mask="url(#path-64-inside-32_452_46177)"/>
      </g>
      <g id="Ellipse 627">
      <mask id="path-66-inside-33_452_46177" fill="white">
      <ellipse cx="15.9834" cy="131.982" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 131.982C15.4317 132.06 15.4049 132.064 15.4458 132.013C15.4896 131.958 15.667 131.793 15.9834 131.793V135.793C18.2813 135.793 19.4317 133.647 19.4317 131.982H15.4317ZM15.9834 131.793C16.2999 131.793 16.4773 131.958 16.5211 132.013C16.562 132.064 16.5352 132.06 16.5352 131.982H12.5352C12.5352 133.647 13.6856 135.793 15.9834 135.793V131.793ZM16.5352 131.982C16.5352 131.905 16.562 131.9 16.5211 131.952C16.4773 132.006 16.2999 132.172 15.9834 132.172V128.172C13.6856 128.172 12.5352 130.317 12.5352 131.982H16.5352ZM15.9834 132.172C15.667 132.172 15.4896 132.006 15.4458 131.952C15.4049 131.9 15.4317 131.905 15.4317 131.982H19.4317C19.4317 130.317 18.2813 128.172 15.9834 128.172V132.172Z" fill="#2E72D2" mask="url(#path-66-inside-33_452_46177)"/>
      </g>
      <g id="Ellipse 633">
      <mask id="path-68-inside-34_452_46177" fill="white">
      <ellipse cx="15.9834" cy="152.984" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 152.984C15.4317 153.062 15.4049 153.066 15.4458 153.015C15.4896 152.96 15.667 152.795 15.9834 152.795V156.795C18.2813 156.795 19.4317 154.649 19.4317 152.984H15.4317ZM15.9834 152.795C16.2999 152.795 16.4773 152.96 16.5211 153.015C16.562 153.066 16.5352 153.062 16.5352 152.984H12.5352C12.5352 154.649 13.6856 156.795 15.9834 156.795V152.795ZM16.5352 152.984C16.5352 152.907 16.562 152.902 16.5211 152.953C16.4773 153.008 16.2999 153.174 15.9834 153.174V149.174C13.6856 149.174 12.5352 151.319 12.5352 152.984H16.5352ZM15.9834 153.174C15.667 153.174 15.4896 153.008 15.4458 152.953C15.4049 152.902 15.4317 152.907 15.4317 152.984H19.4317C19.4317 151.319 18.2813 149.174 15.9834 149.174V153.174Z" fill="#2E72D2" mask="url(#path-68-inside-34_452_46177)"/>
      </g>
      <g id="Ellipse 639">
      <mask id="path-70-inside-35_452_46177" fill="white">
      <ellipse cx="15.9834" cy="173.982" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 173.982C15.4317 174.06 15.4049 174.064 15.4458 174.013C15.4896 173.958 15.667 173.793 15.9834 173.793V177.793C18.2813 177.793 19.4317 175.647 19.4317 173.982H15.4317ZM15.9834 173.793C16.2999 173.793 16.4773 173.958 16.5211 174.013C16.562 174.064 16.5352 174.06 16.5352 173.982H12.5352C12.5352 175.647 13.6856 177.793 15.9834 177.793V173.793ZM16.5352 173.982C16.5352 173.905 16.562 173.9 16.5211 173.952C16.4773 174.006 16.2999 174.172 15.9834 174.172V170.172C13.6856 170.172 12.5352 172.317 12.5352 173.982H16.5352ZM15.9834 174.172C15.667 174.172 15.4896 174.006 15.4458 173.952C15.4049 173.9 15.4317 173.905 15.4317 173.982H19.4317C19.4317 172.317 18.2813 170.172 15.9834 170.172V174.172Z" fill="#2E72D2" mask="url(#path-70-inside-35_452_46177)"/>
      </g>
      <g id="Ellipse 645">
      <mask id="path-72-inside-36_452_46177" fill="white">
      <ellipse cx="15.9834" cy="194.982" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M15.4317 194.982C15.4317 195.06 15.4049 195.064 15.4458 195.013C15.4896 194.958 15.667 194.793 15.9834 194.793V198.793C18.2813 198.793 19.4317 196.647 19.4317 194.982H15.4317ZM15.9834 194.793C16.2999 194.793 16.4773 194.958 16.5211 195.013C16.562 195.064 16.5352 195.06 16.5352 194.982H12.5352C12.5352 196.647 13.6856 198.793 15.9834 198.793V194.793ZM16.5352 194.982C16.5352 194.905 16.562 194.9 16.5211 194.952C16.4773 195.006 16.2999 195.172 15.9834 195.172V191.172C13.6856 191.172 12.5352 193.317 12.5352 194.982H16.5352ZM15.9834 195.172C15.667 195.172 15.4896 195.006 15.4458 194.952C15.4049 194.9 15.4317 194.905 15.4317 194.982H19.4317C19.4317 193.317 18.2813 191.172 15.9834 191.172V195.172Z" fill="#2E72D2" mask="url(#path-72-inside-36_452_46177)"/>
      </g>
      <path id="Vector 306" d="M76.0782 2L1.62836 21.6465L1.5 221.684L33.2054 230.103L106.5 210.585V10.1648L76.0782 2Z" stroke="#2E72D2" stroke-width="2"/>
      <path id="Rectangle 2141" d="M34.3385 228.285L34.6996 30.7949L104.442 12.101L104.955 209.635L34.3385 228.285Z" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2142" d="M89.8457 26.2878C89.8457 25.4097 90.4183 24.6344 91.2575 24.3762L96.6713 22.7104C97.9574 22.3147 99.2595 23.2764 99.2595 24.622V200.73C99.2595 201.637 98.6483 202.431 97.7708 202.663L92.357 204.095C91.0883 204.43 89.8457 203.474 89.8457 202.161V26.2878Z" fill="#2E72D2"/>
      <path id="Vector 310" d="M1.5 21.9124L33.3621 30.24M33.3621 30.24L106.138 10.3262M33.3621 30.24V230.102" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2143" d="M97.8105 23H97.8968C98.449 23 98.8968 23.4477 98.8968 24V201.627L97.8105 201.265V23Z" fill="white"/>
      <path id="Rectangle 2144" d="M90.2754 203.379L97.5168 201.689L98.534 202.079L91.5575 203.953C91.2123 204.046 90.8438 203.947 90.591 203.695L90.2754 203.379Z" fill="white"/>
      </g>
      <g id="Group 1572">
      <circle id="Ellipse 577" cx="51" cy="115.5" r="39.5" fill="#2ED25C"/>
      <path id="Vector 251" d="M33.2422 117.272L43.1622 129.632L68.7559 97.7422" stroke="white" stroke-width="8"/>
      </g>
      </g>
    </svg>

  )
}
NDSConnectionSuccess.defaultProps = {
  width: 227,
  maxWidth: 227,
}








export function NDSConnectionPortSide({width, maxWidth})  {
  return(
    <svg className="NDSConnectionPortSide" width={width} maxWidth={maxWidth} viewBox="0 0 107 232" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1569">
      <path id="Vector 309" d="M75.5862 1.94922L1.36207 21.5009L1 221.363L32.8621 230.053L106 210.501V10.2768L75.5862 1.94922Z" fill="white"/>
      <g id="Ellipse 609">
      <mask id="path-2-inside-1_474_46057" fill="white">
      <ellipse cx="6.79398" cy="83.4139" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 83.4139C6.24225 83.4915 6.21544 83.4957 6.25633 83.4446C6.30015 83.3898 6.47751 83.2242 6.79398 83.2242V87.2242C9.09181 87.2242 10.2423 85.0787 10.2423 83.4139H6.24225ZM6.79398 83.2242C7.11044 83.2242 7.2878 83.3898 7.33163 83.4446C7.37252 83.4957 7.3457 83.4915 7.3457 83.4139H3.3457C3.3457 85.0787 4.49615 87.2242 6.79398 87.2242V83.2242ZM7.3457 83.4139C7.3457 83.3362 7.37252 83.332 7.33163 83.3831C7.2878 83.4379 7.11044 83.6035 6.79398 83.6035V79.6035C4.49615 79.6035 3.3457 81.749 3.3457 83.4139H7.3457ZM6.79398 83.6035C6.47751 83.6035 6.30015 83.4379 6.25633 83.3831C6.21544 83.332 6.24225 83.3362 6.24225 83.4139H10.2423C10.2423 81.749 9.09181 79.6035 6.79398 79.6035V83.6035Z" fill="#2E72D2" mask="url(#path-2-inside-1_474_46057)"/>
      </g>
      <g id="Ellipse 616">
      <mask id="path-4-inside-2_474_46057" fill="white">
      <ellipse cx="6.79398" cy="104.414" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 104.414C6.24225 104.491 6.21544 104.496 6.25633 104.445C6.30015 104.39 6.47751 104.224 6.79398 104.224V108.224C9.09181 108.224 10.2423 106.079 10.2423 104.414H6.24225ZM6.79398 104.224C7.11044 104.224 7.2878 104.39 7.33163 104.445C7.37252 104.496 7.3457 104.491 7.3457 104.414H3.3457C3.3457 106.079 4.49615 108.224 6.79398 108.224V104.224ZM7.3457 104.414C7.3457 104.336 7.37252 104.332 7.33163 104.383C7.2878 104.438 7.11044 104.604 6.79398 104.604V100.604C4.49615 100.604 3.3457 102.749 3.3457 104.414H7.3457ZM6.79398 104.604C6.47751 104.604 6.30015 104.438 6.25633 104.383C6.21544 104.332 6.24225 104.336 6.24225 104.414H10.2423C10.2423 102.749 9.09181 100.604 6.79398 100.604V104.604Z" fill="#2E72D2" mask="url(#path-4-inside-2_474_46057)"/>
      </g>
      <g id="Ellipse 622">
      <mask id="path-6-inside-3_474_46057" fill="white">
      <ellipse cx="6.79398" cy="123.967" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 123.967C6.24225 124.044 6.21544 124.048 6.25633 123.997C6.30015 123.943 6.47751 123.777 6.79398 123.777V127.777C9.09181 127.777 10.2423 125.631 10.2423 123.967H6.24225ZM6.79398 123.777C7.11044 123.777 7.2878 123.943 7.33163 123.997C7.37252 124.048 7.3457 124.044 7.3457 123.967H3.3457C3.3457 125.631 4.49615 127.777 6.79398 127.777V123.777ZM7.3457 123.967C7.3457 123.889 7.37252 123.885 7.33163 123.936C7.2878 123.991 7.11044 124.156 6.79398 124.156V120.156C4.49615 120.156 3.3457 122.302 3.3457 123.967H7.3457ZM6.79398 124.156C6.47751 124.156 6.30015 123.991 6.25633 123.936C6.21544 123.885 6.24225 123.889 6.24225 123.967H10.2423C10.2423 122.302 9.09181 120.156 6.79398 120.156V124.156Z" fill="#2E72D2" mask="url(#path-6-inside-3_474_46057)"/>
      </g>
      <g id="Ellipse 628">
      <mask id="path-8-inside-4_474_46057" fill="white">
      <ellipse cx="6.79398" cy="144.967" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 144.967C6.24225 145.044 6.21544 145.048 6.25633 144.997C6.30015 144.943 6.47751 144.777 6.79398 144.777V148.777C9.09181 148.777 10.2423 146.631 10.2423 144.967H6.24225ZM6.79398 144.777C7.11044 144.777 7.2878 144.943 7.33163 144.997C7.37252 145.048 7.3457 145.044 7.3457 144.967H3.3457C3.3457 146.631 4.49615 148.777 6.79398 148.777V144.777ZM7.3457 144.967C7.3457 144.889 7.37252 144.885 7.33163 144.936C7.2878 144.991 7.11044 145.156 6.79398 145.156V141.156C4.49615 141.156 3.3457 143.302 3.3457 144.967H7.3457ZM6.79398 145.156C6.47751 145.156 6.30015 144.991 6.25633 144.936C6.21544 144.885 6.24225 144.889 6.24225 144.967H10.2423C10.2423 143.302 9.09181 141.156 6.79398 141.156V145.156Z" fill="#2E72D2" mask="url(#path-8-inside-4_474_46057)"/>
      </g>
      <g id="Ellipse 634">
      <mask id="path-10-inside-5_474_46057" fill="white">
      <ellipse cx="6.79398" cy="165.967" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 165.967C6.24225 166.044 6.21544 166.048 6.25633 165.997C6.30015 165.943 6.47751 165.777 6.79398 165.777V169.777C9.09181 169.777 10.2423 167.631 10.2423 165.967H6.24225ZM6.79398 165.777C7.11044 165.777 7.2878 165.943 7.33163 165.997C7.37252 166.048 7.3457 166.044 7.3457 165.967H3.3457C3.3457 167.631 4.49615 169.777 6.79398 169.777V165.777ZM7.3457 165.967C7.3457 165.889 7.37252 165.885 7.33163 165.936C7.2878 165.991 7.11044 166.156 6.79398 166.156V162.156C4.49615 162.156 3.3457 164.302 3.3457 165.967H7.3457ZM6.79398 166.156C6.47751 166.156 6.30015 165.991 6.25633 165.936C6.21544 165.885 6.24225 165.889 6.24225 165.967H10.2423C10.2423 164.302 9.09181 162.156 6.79398 162.156V166.156Z" fill="#2E72D2" mask="url(#path-10-inside-5_474_46057)"/>
      </g>
      <g id="Ellipse 640">
      <mask id="path-12-inside-6_474_46057" fill="white">
      <ellipse cx="6.79398" cy="186.242" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 186.242C6.24225 186.32 6.21544 186.324 6.25633 186.273C6.30015 186.218 6.47751 186.052 6.79398 186.052V190.052C9.09181 190.052 10.2423 187.907 10.2423 186.242H6.24225ZM6.79398 186.052C7.11044 186.052 7.2878 186.218 7.33163 186.273C7.37252 186.324 7.3457 186.32 7.3457 186.242H3.3457C3.3457 187.907 4.49615 190.052 6.79398 190.052V186.052ZM7.3457 186.242C7.3457 186.164 7.37252 186.16 7.33163 186.211C7.2878 186.266 7.11044 186.432 6.79398 186.432V182.432C4.49615 182.432 3.3457 184.577 3.3457 186.242H7.3457ZM6.79398 186.432C6.47751 186.432 6.30015 186.266 6.25633 186.211C6.21544 186.16 6.24225 186.164 6.24225 186.242H10.2423C10.2423 184.577 9.09181 182.432 6.79398 182.432V186.432Z" fill="#2E72D2" mask="url(#path-12-inside-6_474_46057)"/>
      </g>
      <g id="Ellipse 613">
      <mask id="path-14-inside-7_474_46057" fill="white">
      <ellipse cx="6.79398" cy="95.7244" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 95.7244C6.24225 95.802 6.21544 95.8062 6.25633 95.7551C6.30015 95.7003 6.47751 95.5348 6.79398 95.5348V99.5348C9.09181 99.5348 10.2423 97.3893 10.2423 95.7244H6.24225ZM6.79398 95.5348C7.11044 95.5348 7.2878 95.7003 7.33163 95.7551C7.37252 95.8062 7.3457 95.802 7.3457 95.7244H3.3457C3.3457 97.3893 4.49615 99.5348 6.79398 99.5348V95.5348ZM7.3457 95.7244C7.3457 95.6468 7.37252 95.6426 7.33163 95.6937C7.2878 95.7485 7.11044 95.9141 6.79398 95.9141V91.9141C4.49615 91.9141 3.3457 94.0595 3.3457 95.7244H7.3457ZM6.79398 95.9141C6.47751 95.9141 6.30015 95.7485 6.25633 95.6937C6.21544 95.6426 6.24225 95.6468 6.24225 95.7244H10.2423C10.2423 94.0595 9.09181 91.9141 6.79398 91.9141V95.9141Z" fill="#2E72D2" mask="url(#path-14-inside-7_474_46057)"/>
      </g>
      <g id="Ellipse 617">
      <mask id="path-16-inside-8_474_46057" fill="white">
      <ellipse cx="6.79398" cy="116" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 116C6.24225 116.077 6.21544 116.082 6.25633 116.031C6.30015 115.976 6.47751 115.81 6.79398 115.81V119.81C9.09181 119.81 10.2423 117.665 10.2423 116H6.24225ZM6.79398 115.81C7.11044 115.81 7.2878 115.976 7.33163 116.031C7.37252 116.082 7.3457 116.077 7.3457 116H3.3457C3.3457 117.665 4.49615 119.81 6.79398 119.81V115.81ZM7.3457 116C7.3457 115.922 7.37252 115.918 7.33163 115.969C7.2878 116.024 7.11044 116.189 6.79398 116.189V112.189C4.49615 112.189 3.3457 114.335 3.3457 116H7.3457ZM6.79398 116.189C6.47751 116.189 6.30015 116.024 6.25633 115.969C6.21544 115.918 6.24225 115.922 6.24225 116H10.2423C10.2423 114.335 9.09181 112.189 6.79398 112.189V116.189Z" fill="#2E72D2" mask="url(#path-16-inside-8_474_46057)"/>
      </g>
      <g id="Ellipse 623">
      <mask id="path-18-inside-9_474_46057" fill="white">
      <ellipse cx="6.79398" cy="136.277" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 136.277C6.24225 136.355 6.21544 136.359 6.25633 136.308C6.30015 136.253 6.47751 136.087 6.79398 136.087V140.087C9.09181 140.087 10.2423 137.942 10.2423 136.277H6.24225ZM6.79398 136.087C7.11044 136.087 7.2878 136.253 7.33163 136.308C7.37252 136.359 7.3457 136.355 7.3457 136.277H3.3457C3.3457 137.942 4.49615 140.087 6.79398 140.087V136.087ZM7.3457 136.277C7.3457 136.2 7.37252 136.195 7.33163 136.246C7.2878 136.301 7.11044 136.467 6.79398 136.467V132.467C4.49615 132.467 3.3457 134.612 3.3457 136.277H7.3457ZM6.79398 136.467C6.47751 136.467 6.30015 136.301 6.25633 136.246C6.21544 136.195 6.24225 136.2 6.24225 136.277H10.2423C10.2423 134.612 9.09181 132.467 6.79398 132.467V136.467Z" fill="#2E72D2" mask="url(#path-18-inside-9_474_46057)"/>
      </g>
      <g id="Ellipse 629">
      <mask id="path-20-inside-10_474_46057" fill="white">
      <ellipse cx="6.79398" cy="157.277" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 157.277C6.24225 157.355 6.21544 157.359 6.25633 157.308C6.30015 157.253 6.47751 157.087 6.79398 157.087V161.087C9.09181 161.087 10.2423 158.942 10.2423 157.277H6.24225ZM6.79398 157.087C7.11044 157.087 7.2878 157.253 7.33163 157.308C7.37252 157.359 7.3457 157.355 7.3457 157.277H3.3457C3.3457 158.942 4.49615 161.087 6.79398 161.087V157.087ZM7.3457 157.277C7.3457 157.2 7.37252 157.195 7.33163 157.246C7.2878 157.301 7.11044 157.467 6.79398 157.467V153.467C4.49615 153.467 3.3457 155.612 3.3457 157.277H7.3457ZM6.79398 157.467C6.47751 157.467 6.30015 157.301 6.25633 157.246C6.21544 157.195 6.24225 157.2 6.24225 157.277H10.2423C10.2423 155.612 9.09181 153.467 6.79398 153.467V157.467Z" fill="#2E72D2" mask="url(#path-20-inside-10_474_46057)"/>
      </g>
      <g id="Ellipse 635">
      <mask id="path-22-inside-11_474_46057" fill="white">
      <ellipse cx="6.79398" cy="177.553" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 177.553C6.24225 177.63 6.21544 177.634 6.25633 177.583C6.30015 177.528 6.47751 177.363 6.79398 177.363V181.363C9.09181 181.363 10.2423 179.217 10.2423 177.553H6.24225ZM6.79398 177.363C7.11044 177.363 7.2878 177.528 7.33163 177.583C7.37252 177.634 7.3457 177.63 7.3457 177.553H3.3457C3.3457 179.217 4.49615 181.363 6.79398 181.363V177.363ZM7.3457 177.553C7.3457 177.475 7.37252 177.471 7.33163 177.522C7.2878 177.577 7.11044 177.742 6.79398 177.742V173.742C4.49615 173.742 3.3457 175.888 3.3457 177.553H7.3457ZM6.79398 177.742C6.47751 177.742 6.30015 177.577 6.25633 177.522C6.21544 177.471 6.24225 177.475 6.24225 177.553H10.2423C10.2423 175.888 9.09181 173.742 6.79398 173.742V177.742Z" fill="#2E72D2" mask="url(#path-22-inside-11_474_46057)"/>
      </g>
      <g id="Ellipse 641">
      <mask id="path-24-inside-12_474_46057" fill="white">
      <ellipse cx="6.79398" cy="197.828" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 197.828C6.24225 197.906 6.21544 197.91 6.25633 197.859C6.30015 197.804 6.47751 197.638 6.79398 197.638V201.638C9.09181 201.638 10.2423 199.493 10.2423 197.828H6.24225ZM6.79398 197.638C7.11044 197.638 7.2878 197.804 7.33163 197.859C7.37252 197.91 7.3457 197.906 7.3457 197.828H3.3457C3.3457 199.493 4.49615 201.638 6.79398 201.638V197.638ZM7.3457 197.828C7.3457 197.75 7.37252 197.746 7.33163 197.797C7.2878 197.852 7.11044 198.018 6.79398 198.018V194.018C4.49615 194.018 3.3457 196.163 3.3457 197.828H7.3457ZM6.79398 198.018C6.47751 198.018 6.30015 197.852 6.25633 197.797C6.21544 197.746 6.24225 197.75 6.24225 197.828H10.2423C10.2423 196.163 9.09181 194.018 6.79398 194.018V198.018Z" fill="#2E72D2" mask="url(#path-24-inside-12_474_46057)"/>
      </g>
      <g id="Ellipse 611">
      <mask id="path-26-inside-13_474_46057" fill="white">
      <ellipse cx="6.79398" cy="89.2068" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 89.2068C6.24225 89.2844 6.21544 89.2887 6.25633 89.2375C6.30015 89.1828 6.47751 89.0172 6.79398 89.0172V93.0172C9.09181 93.0172 10.2423 90.8717 10.2423 89.2068H6.24225ZM6.79398 89.0172C7.11044 89.0172 7.2878 89.1828 7.33163 89.2375C7.37252 89.2887 7.3457 89.2844 7.3457 89.2068H3.3457C3.3457 90.8717 4.49615 93.0172 6.79398 93.0172V89.0172ZM7.3457 89.2068C7.3457 89.1292 7.37252 89.125 7.33163 89.1761C7.2878 89.2309 7.11044 89.3965 6.79398 89.3965V85.3965C4.49615 85.3965 3.3457 87.542 3.3457 89.2068H7.3457ZM6.79398 89.3965C6.47751 89.3965 6.30015 89.2309 6.25633 89.1761C6.21544 89.125 6.24225 89.1292 6.24225 89.2068H10.2423C10.2423 87.542 9.09181 85.3965 6.79398 85.3965V89.3965Z" fill="#2E72D2" mask="url(#path-26-inside-13_474_46057)"/>
      </g>
      <g id="Ellipse 618">
      <mask id="path-28-inside-14_474_46057" fill="white">
      <ellipse cx="6.79398" cy="110.207" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 110.207C6.24225 110.284 6.21544 110.289 6.25633 110.238C6.30015 110.183 6.47751 110.017 6.79398 110.017V114.017C9.09181 114.017 10.2423 111.872 10.2423 110.207H6.24225ZM6.79398 110.017C7.11044 110.017 7.2878 110.183 7.33163 110.238C7.37252 110.289 7.3457 110.284 7.3457 110.207H3.3457C3.3457 111.872 4.49615 114.017 6.79398 114.017V110.017ZM7.3457 110.207C7.3457 110.129 7.37252 110.125 7.33163 110.176C7.2878 110.231 7.11044 110.396 6.79398 110.396V106.396C4.49615 106.396 3.3457 108.542 3.3457 110.207H7.3457ZM6.79398 110.396C6.47751 110.396 6.30015 110.231 6.25633 110.176C6.21544 110.125 6.24225 110.129 6.24225 110.207H10.2423C10.2423 108.542 9.09181 106.396 6.79398 106.396V110.396Z" fill="#2E72D2" mask="url(#path-28-inside-14_474_46057)"/>
      </g>
      <g id="Ellipse 624">
      <mask id="path-30-inside-15_474_46057" fill="white">
      <ellipse cx="6.79398" cy="129.76" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 129.76C6.24225 129.837 6.21544 129.841 6.25633 129.79C6.30015 129.735 6.47751 129.57 6.79398 129.57V133.57C9.09181 133.57 10.2423 131.424 10.2423 129.76H6.24225ZM6.79398 129.57C7.11044 129.57 7.2878 129.735 7.33163 129.79C7.37252 129.841 7.3457 129.837 7.3457 129.76H3.3457C3.3457 131.424 4.49615 133.57 6.79398 133.57V129.57ZM7.3457 129.76C7.3457 129.682 7.37252 129.678 7.33163 129.729C7.2878 129.784 7.11044 129.949 6.79398 129.949V125.949C4.49615 125.949 3.3457 128.095 3.3457 129.76H7.3457ZM6.79398 129.949C6.47751 129.949 6.30015 129.784 6.25633 129.729C6.21544 129.678 6.24225 129.682 6.24225 129.76H10.2423C10.2423 128.095 9.09181 125.949 6.79398 125.949V129.949Z" fill="#2E72D2" mask="url(#path-30-inside-15_474_46057)"/>
      </g>
      <g id="Ellipse 630">
      <mask id="path-32-inside-16_474_46057" fill="white">
      <ellipse cx="6.79398" cy="150.76" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 150.76C6.24225 150.837 6.21544 150.841 6.25633 150.79C6.30015 150.735 6.47751 150.57 6.79398 150.57V154.57C9.09181 154.57 10.2423 152.424 10.2423 150.76H6.24225ZM6.79398 150.57C7.11044 150.57 7.2878 150.735 7.33163 150.79C7.37252 150.841 7.3457 150.837 7.3457 150.76H3.3457C3.3457 152.424 4.49615 154.57 6.79398 154.57V150.57ZM7.3457 150.76C7.3457 150.682 7.37252 150.678 7.33163 150.729C7.2878 150.784 7.11044 150.949 6.79398 150.949V146.949C4.49615 146.949 3.3457 149.095 3.3457 150.76H7.3457ZM6.79398 150.949C6.47751 150.949 6.30015 150.784 6.25633 150.729C6.21544 150.678 6.24225 150.682 6.24225 150.76H10.2423C10.2423 149.095 9.09181 146.949 6.79398 146.949V150.949Z" fill="#2E72D2" mask="url(#path-32-inside-16_474_46057)"/>
      </g>
      <g id="Ellipse 636">
      <mask id="path-34-inside-17_474_46057" fill="white">
      <ellipse cx="6.79398" cy="171.76" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 171.76C6.24225 171.837 6.21544 171.841 6.25633 171.79C6.30015 171.735 6.47751 171.57 6.79398 171.57V175.57C9.09181 175.57 10.2423 173.424 10.2423 171.76H6.24225ZM6.79398 171.57C7.11044 171.57 7.2878 171.735 7.33163 171.79C7.37252 171.841 7.3457 171.837 7.3457 171.76H3.3457C3.3457 173.424 4.49615 175.57 6.79398 175.57V171.57ZM7.3457 171.76C7.3457 171.682 7.37252 171.678 7.33163 171.729C7.2878 171.784 7.11044 171.949 6.79398 171.949V167.949C4.49615 167.949 3.3457 170.095 3.3457 171.76H7.3457ZM6.79398 171.949C6.47751 171.949 6.30015 171.784 6.25633 171.729C6.21544 171.678 6.24225 171.682 6.24225 171.76H10.2423C10.2423 170.095 9.09181 167.949 6.79398 167.949V171.949Z" fill="#2E72D2" mask="url(#path-34-inside-17_474_46057)"/>
      </g>
      <g id="Ellipse 642">
      <mask id="path-36-inside-18_474_46057" fill="white">
      <ellipse cx="6.79398" cy="192.037" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M6.24225 192.037C6.24225 192.115 6.21544 192.119 6.25633 192.068C6.30015 192.013 6.47751 191.847 6.79398 191.847V195.847C9.09181 195.847 10.2423 193.702 10.2423 192.037H6.24225ZM6.79398 191.847C7.11044 191.847 7.2878 192.013 7.33163 192.068C7.37252 192.119 7.3457 192.115 7.3457 192.037H3.3457C3.3457 193.702 4.49615 195.847 6.79398 195.847V191.847ZM7.3457 192.037C7.3457 191.959 7.37252 191.955 7.33163 192.006C7.2878 192.061 7.11044 192.227 6.79398 192.227V188.227C4.49615 188.227 3.3457 190.372 3.3457 192.037H7.3457ZM6.79398 192.227C6.47751 192.227 6.30015 192.061 6.25633 192.006C6.21544 191.955 6.24225 191.959 6.24225 192.037H10.2423C10.2423 190.372 9.09181 188.227 6.79398 188.227V192.227Z" fill="#2E72D2" mask="url(#path-36-inside-18_474_46057)"/>
      </g>
      <g id="Ellipse 610">
      <mask id="path-38-inside-19_474_46057" fill="white">
      <ellipse cx="15.4834" cy="85.5877" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 85.5877C14.9317 85.6653 14.9049 85.6695 14.9458 85.6184C14.9896 85.5636 15.167 85.398 15.4834 85.398V89.398C17.7813 89.398 18.9317 87.2526 18.9317 85.5877H14.9317ZM15.4834 85.398C15.7999 85.398 15.9773 85.5636 16.0211 85.6184C16.062 85.6695 16.0352 85.6653 16.0352 85.5877H12.0352C12.0352 87.2526 13.1856 89.398 15.4834 89.398V85.398ZM16.0352 85.5877C16.0352 85.5101 16.062 85.5059 16.0211 85.557C15.9773 85.6118 15.7999 85.7773 15.4834 85.7773V81.7773C13.1856 81.7773 12.0352 83.9228 12.0352 85.5877H16.0352ZM15.4834 85.7773C15.167 85.7773 14.9896 85.6118 14.9458 85.557C14.9049 85.5059 14.9317 85.5101 14.9317 85.5877H18.9317C18.9317 83.9228 17.7813 81.7773 15.4834 81.7773V85.7773Z" fill="#2E72D2" mask="url(#path-38-inside-19_474_46057)"/>
      </g>
      <g id="Ellipse 619">
      <mask id="path-40-inside-20_474_46057" fill="white">
      <ellipse cx="15.4834" cy="106.588" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 106.588C14.9317 106.665 14.9049 106.67 14.9458 106.618C14.9896 106.564 15.167 106.398 15.4834 106.398V110.398C17.7813 110.398 18.9317 108.253 18.9317 106.588H14.9317ZM15.4834 106.398C15.7999 106.398 15.9773 106.564 16.0211 106.618C16.062 106.67 16.0352 106.665 16.0352 106.588H12.0352C12.0352 108.253 13.1856 110.398 15.4834 110.398V106.398ZM16.0352 106.588C16.0352 106.51 16.062 106.506 16.0211 106.557C15.9773 106.612 15.7999 106.777 15.4834 106.777V102.777C13.1856 102.777 12.0352 104.923 12.0352 106.588H16.0352ZM15.4834 106.777C15.167 106.777 14.9896 106.612 14.9458 106.557C14.9049 106.506 14.9317 106.51 14.9317 106.588H18.9317C18.9317 104.923 17.7813 102.777 15.4834 102.777V106.777Z" fill="#2E72D2" mask="url(#path-40-inside-20_474_46057)"/>
      </g>
      <g id="Ellipse 625">
      <mask id="path-42-inside-21_474_46057" fill="white">
      <ellipse cx="15.4834" cy="126.138" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 126.138C14.9317 126.216 14.9049 126.22 14.9458 126.169C14.9896 126.114 15.167 125.949 15.4834 125.949V129.949C17.7813 129.949 18.9317 127.803 18.9317 126.138H14.9317ZM15.4834 125.949C15.7999 125.949 15.9773 126.114 16.0211 126.169C16.062 126.22 16.0352 126.216 16.0352 126.138H12.0352C12.0352 127.803 13.1856 129.949 15.4834 129.949V125.949ZM16.0352 126.138C16.0352 126.061 16.062 126.057 16.0211 126.108C15.9773 126.163 15.7999 126.328 15.4834 126.328V122.328C13.1856 122.328 12.0352 124.474 12.0352 126.138H16.0352ZM15.4834 126.328C15.167 126.328 14.9896 126.163 14.9458 126.108C14.9049 126.057 14.9317 126.061 14.9317 126.138H18.9317C18.9317 124.474 17.7813 122.328 15.4834 122.328V126.328Z" fill="#2E72D2" mask="url(#path-42-inside-21_474_46057)"/>
      </g>
      <g id="Ellipse 631">
      <mask id="path-44-inside-22_474_46057" fill="white">
      <ellipse cx="15.4834" cy="147.138" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 147.138C14.9317 147.216 14.9049 147.22 14.9458 147.169C14.9896 147.114 15.167 146.949 15.4834 146.949V150.949C17.7813 150.949 18.9317 148.803 18.9317 147.138H14.9317ZM15.4834 146.949C15.7999 146.949 15.9773 147.114 16.0211 147.169C16.062 147.22 16.0352 147.216 16.0352 147.138H12.0352C12.0352 148.803 13.1856 150.949 15.4834 150.949V146.949ZM16.0352 147.138C16.0352 147.061 16.062 147.057 16.0211 147.108C15.9773 147.163 15.7999 147.328 15.4834 147.328V143.328C13.1856 143.328 12.0352 145.474 12.0352 147.138H16.0352ZM15.4834 147.328C15.167 147.328 14.9896 147.163 14.9458 147.108C14.9049 147.057 14.9317 147.061 14.9317 147.138H18.9317C18.9317 145.474 17.7813 143.328 15.4834 143.328V147.328Z" fill="#2E72D2" mask="url(#path-44-inside-22_474_46057)"/>
      </g>
      <g id="Ellipse 637">
      <mask id="path-46-inside-23_474_46057" fill="white">
      <ellipse cx="15.4834" cy="168.138" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 168.138C14.9317 168.216 14.9049 168.22 14.9458 168.169C14.9896 168.114 15.167 167.949 15.4834 167.949V171.949C17.7813 171.949 18.9317 169.803 18.9317 168.138H14.9317ZM15.4834 167.949C15.7999 167.949 15.9773 168.114 16.0211 168.169C16.062 168.22 16.0352 168.216 16.0352 168.138H12.0352C12.0352 169.803 13.1856 171.949 15.4834 171.949V167.949ZM16.0352 168.138C16.0352 168.061 16.062 168.057 16.0211 168.108C15.9773 168.163 15.7999 168.328 15.4834 168.328V164.328C13.1856 164.328 12.0352 166.474 12.0352 168.138H16.0352ZM15.4834 168.328C15.167 168.328 14.9896 168.163 14.9458 168.108C14.9049 168.057 14.9317 168.061 14.9317 168.138H18.9317C18.9317 166.474 17.7813 164.328 15.4834 164.328V168.328Z" fill="#2E72D2" mask="url(#path-46-inside-23_474_46057)"/>
      </g>
      <g id="Ellipse 643">
      <mask id="path-48-inside-24_474_46057" fill="white">
      <ellipse cx="15.4834" cy="189.138" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 189.138C14.9317 189.216 14.9049 189.22 14.9458 189.169C14.9896 189.114 15.167 188.949 15.4834 188.949V192.949C17.7813 192.949 18.9317 190.803 18.9317 189.138H14.9317ZM15.4834 188.949C15.7999 188.949 15.9773 189.114 16.0211 189.169C16.062 189.22 16.0352 189.216 16.0352 189.138H12.0352C12.0352 190.803 13.1856 192.949 15.4834 192.949V188.949ZM16.0352 189.138C16.0352 189.061 16.062 189.057 16.0211 189.108C15.9773 189.163 15.7999 189.328 15.4834 189.328V185.328C13.1856 185.328 12.0352 187.474 12.0352 189.138H16.0352ZM15.4834 189.328C15.167 189.328 14.9896 189.163 14.9458 189.108C14.9049 189.057 14.9317 189.061 14.9317 189.138H18.9317C18.9317 187.474 17.7813 185.328 15.4834 185.328V189.328Z" fill="#2E72D2" mask="url(#path-48-inside-24_474_46057)"/>
      </g>
      <g id="Ellipse 615">
      <mask id="path-50-inside-25_474_46057" fill="white">
      <ellipse cx="15.4834" cy="97.1736" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 97.1736C14.9317 97.2512 14.9049 97.2555 14.9458 97.2043C14.9896 97.1496 15.167 96.984 15.4834 96.984V100.984C17.7813 100.984 18.9317 98.8385 18.9317 97.1736H14.9317ZM15.4834 96.984C15.7999 96.984 15.9773 97.1496 16.0211 97.2043C16.062 97.2555 16.0352 97.2512 16.0352 97.1736H12.0352C12.0352 98.8385 13.1856 100.984 15.4834 100.984V96.984ZM16.0352 97.1736C16.0352 97.096 16.062 97.0918 16.0211 97.1429C15.9773 97.1977 15.7999 97.3633 15.4834 97.3633V93.3633C13.1856 93.3633 12.0352 95.5088 12.0352 97.1736H16.0352ZM15.4834 97.3633C15.167 97.3633 14.9896 97.1977 14.9458 97.1429C14.9049 97.0918 14.9317 97.096 14.9317 97.1736H18.9317C18.9317 95.5088 17.7813 93.3633 15.4834 93.3633V97.3633Z" fill="#2E72D2" mask="url(#path-50-inside-25_474_46057)"/>
      </g>
      <g id="Ellipse 620">
      <mask id="path-52-inside-26_474_46057" fill="white">
      <ellipse cx="15.4834" cy="118.174" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 118.174C14.9317 118.251 14.9049 118.255 14.9458 118.204C14.9896 118.15 15.167 117.984 15.4834 117.984V121.984C17.7813 121.984 18.9317 119.838 18.9317 118.174H14.9317ZM15.4834 117.984C15.7999 117.984 15.9773 118.15 16.0211 118.204C16.062 118.255 16.0352 118.251 16.0352 118.174H12.0352C12.0352 119.838 13.1856 121.984 15.4834 121.984V117.984ZM16.0352 118.174C16.0352 118.096 16.062 118.092 16.0211 118.143C15.9773 118.198 15.7999 118.363 15.4834 118.363V114.363C13.1856 114.363 12.0352 116.509 12.0352 118.174H16.0352ZM15.4834 118.363C15.167 118.363 14.9896 118.198 14.9458 118.143C14.9049 118.092 14.9317 118.096 14.9317 118.174H18.9317C18.9317 116.509 17.7813 114.363 15.4834 114.363V118.363Z" fill="#2E72D2" mask="url(#path-52-inside-26_474_46057)"/>
      </g>
      <g id="Ellipse 626">
      <mask id="path-54-inside-27_474_46057" fill="white">
      <ellipse cx="15.4834" cy="138.449" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 138.449C14.9317 138.527 14.9049 138.531 14.9458 138.48C14.9896 138.425 15.167 138.259 15.4834 138.259V142.259C17.7813 142.259 18.9317 140.114 18.9317 138.449H14.9317ZM15.4834 138.259C15.7999 138.259 15.9773 138.425 16.0211 138.48C16.062 138.531 16.0352 138.527 16.0352 138.449H12.0352C12.0352 140.114 13.1856 142.259 15.4834 142.259V138.259ZM16.0352 138.449C16.0352 138.371 16.062 138.367 16.0211 138.418C15.9773 138.473 15.7999 138.639 15.4834 138.639V134.639C13.1856 134.639 12.0352 136.784 12.0352 138.449H16.0352ZM15.4834 138.639C15.167 138.639 14.9896 138.473 14.9458 138.418C14.9049 138.367 14.9317 138.371 14.9317 138.449H18.9317C18.9317 136.784 17.7813 134.639 15.4834 134.639V138.639Z" fill="#2E72D2" mask="url(#path-54-inside-27_474_46057)"/>
      </g>
      <g id="Ellipse 632">
      <mask id="path-56-inside-28_474_46057" fill="white">
      <ellipse cx="15.4834" cy="159.449" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 159.449C14.9317 159.527 14.9049 159.531 14.9458 159.48C14.9896 159.425 15.167 159.259 15.4834 159.259V163.259C17.7813 163.259 18.9317 161.114 18.9317 159.449H14.9317ZM15.4834 159.259C15.7999 159.259 15.9773 159.425 16.0211 159.48C16.062 159.531 16.0352 159.527 16.0352 159.449H12.0352C12.0352 161.114 13.1856 163.259 15.4834 163.259V159.259ZM16.0352 159.449C16.0352 159.371 16.062 159.367 16.0211 159.418C15.9773 159.473 15.7999 159.639 15.4834 159.639V155.639C13.1856 155.639 12.0352 157.784 12.0352 159.449H16.0352ZM15.4834 159.639C15.167 159.639 14.9896 159.473 14.9458 159.418C14.9049 159.367 14.9317 159.371 14.9317 159.449H18.9317C18.9317 157.784 17.7813 155.639 15.4834 155.639V159.639Z" fill="#2E72D2" mask="url(#path-56-inside-28_474_46057)"/>
      </g>
      <g id="Ellipse 638">
      <mask id="path-58-inside-29_474_46057" fill="white">
      <ellipse cx="15.4834" cy="179.726" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 179.726C14.9317 179.804 14.9049 179.808 14.9458 179.757C14.9896 179.702 15.167 179.537 15.4834 179.537V183.537C17.7813 183.537 18.9317 181.391 18.9317 179.726H14.9317ZM15.4834 179.537C15.7999 179.537 15.9773 179.702 16.0211 179.757C16.062 179.808 16.0352 179.804 16.0352 179.726H12.0352C12.0352 181.391 13.1856 183.537 15.4834 183.537V179.537ZM16.0352 179.726C16.0352 179.649 16.062 179.645 16.0211 179.696C15.9773 179.75 15.7999 179.916 15.4834 179.916V175.916C13.1856 175.916 12.0352 178.061 12.0352 179.726H16.0352ZM15.4834 179.916C15.167 179.916 14.9896 179.75 14.9458 179.696C14.9049 179.645 14.9317 179.649 14.9317 179.726H18.9317C18.9317 178.061 17.7813 175.916 15.4834 175.916V179.916Z" fill="#2E72D2" mask="url(#path-58-inside-29_474_46057)"/>
      </g>
      <g id="Ellipse 644">
      <mask id="path-60-inside-30_474_46057" fill="white">
      <ellipse cx="15.4834" cy="200.724" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 200.724C14.9317 200.802 14.9049 200.806 14.9458 200.755C14.9896 200.7 15.167 200.535 15.4834 200.535V204.535C17.7813 204.535 18.9317 202.389 18.9317 200.724H14.9317ZM15.4834 200.535C15.7999 200.535 15.9773 200.7 16.0211 200.755C16.062 200.806 16.0352 200.802 16.0352 200.724H12.0352C12.0352 202.389 13.1856 204.535 15.4834 204.535V200.535ZM16.0352 200.724C16.0352 200.647 16.062 200.643 16.0211 200.694C15.9773 200.748 15.7999 200.914 15.4834 200.914V196.914C13.1856 196.914 12.0352 199.06 12.0352 200.724H16.0352ZM15.4834 200.914C15.167 200.914 14.9896 200.748 14.9458 200.694C14.9049 200.643 14.9317 200.647 14.9317 200.724H18.9317C18.9317 199.06 17.7813 196.914 15.4834 196.914V200.914Z" fill="#2E72D2" mask="url(#path-60-inside-30_474_46057)"/>
      </g>
      <g id="Ellipse 612">
      <mask id="path-62-inside-31_474_46057" fill="white">
      <ellipse cx="15.4834" cy="91.3807" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 91.3807C14.9317 91.4583 14.9049 91.4625 14.9458 91.4114C14.9896 91.3566 15.167 91.191 15.4834 91.191V95.191C17.7813 95.191 18.9317 93.0455 18.9317 91.3807H14.9317ZM15.4834 91.191C15.7999 91.191 15.9773 91.3566 16.0211 91.4114C16.062 91.4625 16.0352 91.4583 16.0352 91.3807H12.0352C12.0352 93.0455 13.1856 95.191 15.4834 95.191V91.191ZM16.0352 91.3807C16.0352 91.303 16.062 91.2988 16.0211 91.3499C15.9773 91.4047 15.7999 91.5703 15.4834 91.5703V87.5703C13.1856 87.5703 12.0352 89.7158 12.0352 91.3807H16.0352ZM15.4834 91.5703C15.167 91.5703 14.9896 91.4047 14.9458 91.3499C14.9049 91.2988 14.9317 91.303 14.9317 91.3807H18.9317C18.9317 89.7158 17.7813 87.5703 15.4834 87.5703V91.5703Z" fill="#2E72D2" mask="url(#path-62-inside-31_474_46057)"/>
      </g>
      <g id="Ellipse 621">
      <mask id="path-64-inside-32_474_46057" fill="white">
      <ellipse cx="15.4834" cy="112.381" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 112.381C14.9317 112.458 14.9049 112.462 14.9458 112.411C14.9896 112.357 15.167 112.191 15.4834 112.191V116.191C17.7813 116.191 18.9317 114.046 18.9317 112.381H14.9317ZM15.4834 112.191C15.7999 112.191 15.9773 112.357 16.0211 112.411C16.062 112.462 16.0352 112.458 16.0352 112.381H12.0352C12.0352 114.046 13.1856 116.191 15.4834 116.191V112.191ZM16.0352 112.381C16.0352 112.303 16.062 112.299 16.0211 112.35C15.9773 112.405 15.7999 112.57 15.4834 112.57V108.57C13.1856 108.57 12.0352 110.716 12.0352 112.381H16.0352ZM15.4834 112.57C15.167 112.57 14.9896 112.405 14.9458 112.35C14.9049 112.299 14.9317 112.303 14.9317 112.381H18.9317C18.9317 110.716 17.7813 108.57 15.4834 108.57V112.57Z" fill="#2E72D2" mask="url(#path-64-inside-32_474_46057)"/>
      </g>
      <g id="Ellipse 627">
      <mask id="path-66-inside-33_474_46057" fill="white">
      <ellipse cx="15.4834" cy="131.931" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 131.931C14.9317 132.009 14.9049 132.013 14.9458 131.962C14.9896 131.907 15.167 131.742 15.4834 131.742V135.742C17.7813 135.742 18.9317 133.596 18.9317 131.931H14.9317ZM15.4834 131.742C15.7999 131.742 15.9773 131.907 16.0211 131.962C16.062 132.013 16.0352 132.009 16.0352 131.931H12.0352C12.0352 133.596 13.1856 135.742 15.4834 135.742V131.742ZM16.0352 131.931C16.0352 131.854 16.062 131.85 16.0211 131.901C15.9773 131.956 15.7999 132.121 15.4834 132.121V128.121C13.1856 128.121 12.0352 130.267 12.0352 131.931H16.0352ZM15.4834 132.121C15.167 132.121 14.9896 131.956 14.9458 131.901C14.9049 131.85 14.9317 131.854 14.9317 131.931H18.9317C18.9317 130.267 17.7813 128.121 15.4834 128.121V132.121Z" fill="#2E72D2" mask="url(#path-66-inside-33_474_46057)"/>
      </g>
      <g id="Ellipse 633">
      <mask id="path-68-inside-34_474_46057" fill="white">
      <ellipse cx="15.4834" cy="152.933" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 152.933C14.9317 153.011 14.9049 153.015 14.9458 152.964C14.9896 152.909 15.167 152.744 15.4834 152.744V156.744C17.7813 156.744 18.9317 154.598 18.9317 152.933H14.9317ZM15.4834 152.744C15.7999 152.744 15.9773 152.909 16.0211 152.964C16.062 153.015 16.0352 153.011 16.0352 152.933H12.0352C12.0352 154.598 13.1856 156.744 15.4834 156.744V152.744ZM16.0352 152.933C16.0352 152.856 16.062 152.852 16.0211 152.903C15.9773 152.957 15.7999 153.123 15.4834 153.123V149.123C13.1856 149.123 12.0352 151.269 12.0352 152.933H16.0352ZM15.4834 153.123C15.167 153.123 14.9896 152.957 14.9458 152.903C14.9049 152.852 14.9317 152.856 14.9317 152.933H18.9317C18.9317 151.269 17.7813 149.123 15.4834 149.123V153.123Z" fill="#2E72D2" mask="url(#path-68-inside-34_474_46057)"/>
      </g>
      <g id="Ellipse 639">
      <mask id="path-70-inside-35_474_46057" fill="white">
      <ellipse cx="15.4834" cy="173.931" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 173.931C14.9317 174.009 14.9049 174.013 14.9458 173.962C14.9896 173.907 15.167 173.742 15.4834 173.742V177.742C17.7813 177.742 18.9317 175.596 18.9317 173.931H14.9317ZM15.4834 173.742C15.7999 173.742 15.9773 173.907 16.0211 173.962C16.062 174.013 16.0352 174.009 16.0352 173.931H12.0352C12.0352 175.596 13.1856 177.742 15.4834 177.742V173.742ZM16.0352 173.931C16.0352 173.854 16.062 173.85 16.0211 173.901C15.9773 173.956 15.7999 174.121 15.4834 174.121V170.121C13.1856 170.121 12.0352 172.267 12.0352 173.931H16.0352ZM15.4834 174.121C15.167 174.121 14.9896 173.956 14.9458 173.901C14.9049 173.85 14.9317 173.854 14.9317 173.931H18.9317C18.9317 172.267 17.7813 170.121 15.4834 170.121V174.121Z" fill="#2E72D2" mask="url(#path-70-inside-35_474_46057)"/>
      </g>
      <g id="Ellipse 645">
      <mask id="path-72-inside-36_474_46057" fill="white">
      <ellipse cx="15.4834" cy="194.931" rx="1.44828" ry="1.81034"/>
      </mask>
      <path d="M14.9317 194.931C14.9317 195.009 14.9049 195.013 14.9458 194.962C14.9896 194.907 15.167 194.742 15.4834 194.742V198.742C17.7813 198.742 18.9317 196.596 18.9317 194.931H14.9317ZM15.4834 194.742C15.7999 194.742 15.9773 194.907 16.0211 194.962C16.062 195.013 16.0352 195.009 16.0352 194.931H12.0352C12.0352 196.596 13.1856 198.742 15.4834 198.742V194.742ZM16.0352 194.931C16.0352 194.854 16.062 194.85 16.0211 194.901C15.9773 194.956 15.7999 195.121 15.4834 195.121V191.121C13.1856 191.121 12.0352 193.267 12.0352 194.931H16.0352ZM15.4834 195.121C15.167 195.121 14.9896 194.956 14.9458 194.901C14.9049 194.85 14.9317 194.854 14.9317 194.931H18.9317C18.9317 193.267 17.7813 191.121 15.4834 191.121V195.121Z" fill="#2E72D2" mask="url(#path-72-inside-36_474_46057)"/>
      </g>
      <path id="Vector 306" d="M75.5782 1.94922L1.12836 21.5957L1 221.633L32.7054 230.053L106 210.534V10.114L75.5782 1.94922Z" stroke="#2E72D2" stroke-width="2"/>
      <path id="Rectangle 2141" d="M33.8385 228.234L34.1996 30.7441L103.942 12.0502L104.455 209.584L33.8385 228.234Z" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2142" d="M89.3457 26.237C89.3457 25.359 89.9183 24.5836 90.7575 24.3254L96.1713 22.6596C97.4574 22.2639 98.7595 23.2256 98.7595 24.5712V200.679C98.7595 201.586 98.1483 202.38 97.2708 202.612L91.857 204.044C90.5883 204.38 89.3457 203.423 89.3457 202.11V26.237Z" fill="#2E72D2"/>
      <path id="Vector 310" d="M1 21.8616L32.8621 30.1892M32.8621 30.1892L105.638 10.2754M32.8621 30.1892V230.051" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2143" d="M97.3105 23.5H97.3968C97.949 23.5 98.3968 23.9477 98.3968 24.5V201.362L97.3105 201V23.5Z" fill="white"/>
      <path id="Rectangle 2144" d="M90.2754 203.195L96.8291 201.684L97.8463 202.073L91.558 203.769C91.2125 203.862 90.8436 203.763 90.5906 203.51L90.2754 203.195Z" fill="white"/>
      </g>
      </svg>


  )
}
NDSConnectionPortSide.defaultProps = {
  width: 227,
  maxWidth: 227,
}





export function NDSConnectionPortSideNarrow({width, maxWidth})  {
  return(
    <svg className="NDSConnectionPortSideNarrow" width={width} maxWidth={maxWidth} viewBox="0 0 60 245" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1572">
      <path id="Vector 313" d="M58.6528 3.16112L11.8016 2.38672L1.73438 21.7467V243.612H48.9728L58.6528 223.091V3.16112Z" fill="white"/>
      <path id="Vector 311" d="M1.34766 21.7472V244H48.9733M1.34766 21.7472L11.8021 2L58.6533 2.7744M1.34766 21.7472L48.5861 22.5216M48.9733 244L58.6533 223.478V2.7744M48.9733 244L48.5861 22.5216M58.6533 2.7744L48.5861 22.5216" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Rectangle 2145" d="M55.5547 20.662C55.5547 20.6111 55.5644 20.5607 55.5833 20.5134L56.3321 18.6414C56.4957 18.2325 57.1035 18.3495 57.1035 18.7899V214.838C57.1035 214.917 57.0801 214.994 57.0363 215.06L56.2875 216.183C56.068 216.513 55.5547 216.357 55.5547 215.961L55.5547 20.662Z" fill="#2E72D2"/>
      <circle id="Ellipse 646" cx="9.86617" cy="89.1201" r="2.0732" fill="#2E72D2" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 652" cx="9.86617" cy="111.577" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 658" cx="9.86617" cy="134.81" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 664" cx="9.86617" cy="157.267" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 670" cx="9.86617" cy="180.499" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 676" cx="9.86617" cy="202.956" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 648" cx="9.86617" cy="95.3154" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 653" cx="9.86617" cy="117.772" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 659" cx="9.86617" cy="141.005" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 665" cx="9.86617" cy="163.462" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 671" cx="9.86617" cy="186.694" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 677" cx="9.86617" cy="209.151" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 650" cx="9.86617" cy="102.284" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 654" cx="9.86617" cy="124.743" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 660" cx="9.86617" cy="147.974" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 666" cx="9.86617" cy="170.433" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 672" cx="9.86617" cy="193.663" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 678" cx="9.86617" cy="216.122" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 647" cx="22.2568" cy="89.1201" r="2.0732" fill="#2E72D2" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 655" cx="22.2568" cy="111.577" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 661" cx="22.2568" cy="134.81" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 667" cx="22.2568" cy="157.267" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 673" cx="22.2568" cy="180.499" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 679" cx="22.2568" cy="202.956" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 649" cx="22.2568" cy="95.3154" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 656" cx="22.2568" cy="117.772" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 662" cx="22.2568" cy="141.005" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 668" cx="22.2568" cy="163.462" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 674" cx="22.2568" cy="186.694" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 680" cx="22.2568" cy="209.151" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 651" cx="22.2568" cy="102.284" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 657" cx="22.2568" cy="124.743" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 663" cx="22.2568" cy="147.974" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 669" cx="22.2568" cy="170.433" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 675" cx="22.2568" cy="193.663" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 681" cx="22.2568" cy="216.122" r="2.0732" fill="#E4EAF5" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 312" d="M1.34766 244V21.7472L11.8021 2L58.6533 2.7744V223.478L48.9733 244H1.34766Z" stroke="#2E72D2" stroke-width="2"/>
      </g>
      </svg>


  )
}
NDSConnectionPortSideNarrow.defaultProps = {
  width: 60,
  maxWidth: 60,
}








export function NDSConnectionReservoirSide({width, maxWidth})  {
  return(
    <svg className="NDSConnectionReservoirSide" width={width} maxWidth={maxWidth} viewBox="0 0 234 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 3008" clip-path="url(#clip0_492_47524)">
      <g id="Group 1582">
      <path id="Rectangle 2148" d="M7.90157 6.69727H218.456L228.137 25.2519L226.523 112.378H15.9688L5.88477 92.2098L7.90157 6.69727Z" fill="white"/>
      <g id="Group 1575">
      <path id="Vector 314" d="M16.6333 111.897L226.075 112.472L227.757 25.1485M16.6333 111.897L6.56156 92.1302L8.44058 6.9701M16.6333 111.897L17.0473 23.8618M227.757 25.1485L218.601 7.17862L8.44058 6.9701M227.757 25.1485L17.0473 23.8618M8.44058 6.9701L17.0473 23.8618" stroke="#2E72D2" stroke-width="0.5"/>
      <circle id="Ellipse 684" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 76.8281 80.7715)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 686" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 89.4551 80.6758)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 688" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 102.082 80.582)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 690" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 114.709 80.4844)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 692" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 127.336 80.3887)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 694" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 139.963 80.293)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 696" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 152.59 80.1973)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 698" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 165.215 80.0996)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 685" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 77.168 67.4121)" fill="#DBF2D8" stroke="#4DBE3B"/>
      <circle id="Ellipse 687" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 89.7969 67.3184)" fill="#2E72D2" stroke="#2E72D2"/>
      <circle id="Ellipse 689" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 102.422 67.2207)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 691" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 115.051 67.125)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 693" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 127.676 67.0293)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 695" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 140.305 66.9336)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 697" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 152.93 66.8379)" fill="#E4EAF5" stroke="#2E72D2"/>
      <circle id="Ellipse 699" cx="3.63025" cy="3.63025" r="3.13025" transform="matrix(0.891007 -0.453991 -0.453991 -0.891007 165.559 66.7422)" fill="#E4EAF5" stroke="#2E72D2"/>
      <path id="Rectangle 2146" d="M190.321 12.0904C189.646 10.7657 190.601 9.19375 192.088 9.18247L198.439 9.13425C199.196 9.1285 199.892 9.55124 200.236 10.2262L205.164 19.8986C205.839 21.2233 204.884 22.7952 203.397 22.8065L197.046 22.8547C196.289 22.8605 195.593 22.4378 195.249 21.7628L190.321 12.0904Z" fill="#2E72D2"/>
      <path id="Vector 315" d="M5.56095 92.1071L5.55539 92.3588L5.6697 92.5831L15.7414 112.35L16.0188 112.894L16.6297 112.896L226.071 113.471L227.055 113.473L227.074 112.49L228.756 25.1667L228.761 24.9164L228.647 24.6934L219.491 6.72358L219.213 6.17817L218.601 6.17756L8.44072 5.96904L7.46157 5.96807L7.43997 6.94699L5.56095 92.1071Z" stroke="#2E72D2" stroke-width="2"/>
      <path id="Rectangle 2147" d="M17.7369 22.8867L10.1172 7.93207L217.912 8.15248L226.079 24.1822L17.7369 22.8867Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_492_47524">
      <rect width="233" height="120" fill="white" transform="translate(0.5)"/>
      </clipPath>
      </defs>
    </svg>


  )
}
NDSConnectionReservoirSide.defaultProps = {
  width: 234,
  maxWidth: 234,
}





export function NDSPortSelection({width, selected, completed})  {
  let fill = "white"
  let portStroke = "#D7DBE1"
  let stroke = "#D7DBE1"
  let portFill = "white"

  if (selected && completed) {
    fill = "#E4EAF5"
    stroke = "#2E72D2"
    portStroke = "#4DBE3B"
    portFill = "#DBF2D8"
  }else if (selected) {
    fill = "#E4EAF5"
    stroke = "#2E72D2"
    portStroke = "#2E72D2"
    portFill = "#E4EAF5"
  }else if (completed)  {
    fill = "#DBF2D8"
    stroke = "#4DBE3B"
    portStroke = "#4DBE3B"
    portFill = "#DBF2D8"
  }

  return(
    <svg className={"NDSPortSelection" + (selected ? " NDSPortSelection-Selected" : "")} width={width} viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="port_selection">
        <rect x="0.5" y="0.5" width="31" height="23" rx="3.5" fill={fill}/>
        <circle id="port" cx="16" cy="12" r="7" stroke={portStroke} fill={portFill} stroke-width="2"/>
        <rect x="0.5" y="0.5" width="31" height="23" rx="3.5" stroke={stroke}/>
      </g>
    </svg>

  )
}
NDSPortSelection.defaultProps = {
  width: 32,
}









export function NDSPortsSelection({width, selected, completed})  {
  let fill = "white"
  let portStroke = "#D7DBE1"
  let stroke = "#D7DBE1"
  let portFill = "white"

  if (selected && completed) {
    fill = "#E4EAF5"
    stroke = "#2E72D2"
    portStroke = "#4DBE3B"
    portFill = "#DBF2D8"
  }else if (selected) {
    fill = "#E4EAF5"
    stroke = "#2E72D2"
    portStroke = "#2E72D2"
    portFill = "#E4EAF5"
  }else if (completed)  {
    fill = "#DBF2D8"
    stroke = "#4DBE3B"
    portStroke = "#4DBE3B"
    portFill = "#DBF2D8"
  }

  return(
    <svg className={"NDSPortsSelection" + (selected ? " NDSPortsSelection-Selected" : "")} width={width} viewBox="0 0 52 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g id="port_selection">
      <rect x="0.5" y="0.5" width="51" height="23" rx="3.5" fill={fill}/>
      <circle id="supply_port" cx="16" cy="12" r="7" stroke={portStroke} stroke-width="2" fill={portFill}/>
      <circle id="return_port" cx="36" cy="12" r="7" stroke={portStroke} stroke-width="2" fill={portFill}/>
      <rect x="0.5" y="0.5" width="51" height="23" rx="3.5" stroke={stroke}/>
    </g>
    </svg>

  )
}
NDSPortsSelection.defaultProps = {
  width: 52,
}