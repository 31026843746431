import './AddToInventoryPopup.scss';
import React from 'react';

import Button from '../../components/Button';
import TextInput from '../../components/input/TextInput';
import NumberInput from '../../components/input/NumberInput';
import DropDownInput from '../../components/input/DropDownInput';
import ControlBar from '../../components/ControlBar';
import PopupModal from '../../model_components/PopupModal'
import { FormatDate } from '../../helpers'
import { useSelector, useDispatch} from 'react-redux'
import SelectInventoryItemType from '../../model_components/SelectInventoryItemType';
import { createNewInventoryItem, createNewInventoryItemType, getAllInventoryItemTypePrimaryGroups, getInventoryItemTypeGroupsByParentGroupId, selectAllInventoryItemTypeGroups, selectAllInventoryItemTypePrimaryGroups } from '../../redux/entities/Inventory';
import Badge from '../../components/Badge';
import DateInput from '../../components/input/DateInput';

  

const AddToInventoryPopup = ({completeCallback, closeCallback}) => {
    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        } 
    }

    const dispatch = useDispatch()
    let inventoryItemTypeGroups = useSelector(selectAllInventoryItemTypeGroups)
    let inventoryItemTypePrimaryGroups = useSelector(selectAllInventoryItemTypePrimaryGroups)
    const [inventoryItemTypePrimaryGroupOptions, SetInventoryItemTypePrimaryGroupOptions] = React.useState([])
    let haveItemTypePrimaryGroups = useSelector((state) => state.inventory.haveItemTypePrimaryGroups)

    
    let newInventoryItemCreateStatus = useSelector((state) => state.inventory.newItemCreateStatus)
    const [newInventoryItemCreateSent, SetNewInventoryItemCreateSent] = React.useState(false)

    let newInventoryItemTypeCreateStatus = useSelector((state) => state.inventory.newItemTypeCreateStatus)
    const [newInventoryItemTypeCreateSent, SetNewInventoryItemTypeCreateSent] = React.useState(false)

    React.useEffect( () =>  { 
        //fetchTasks
        if (!haveItemTypePrimaryGroups) {
            dispatch(getAllInventoryItemTypePrimaryGroups())
        }
        let currentInventoryItemTypePrimaryGroupOptions = []
        for (let inventoryItemTypePrimaryGroup of inventoryItemTypePrimaryGroups)   {
            currentInventoryItemTypePrimaryGroupOptions.push({
                value: inventoryItemTypePrimaryGroup.id, label: inventoryItemTypePrimaryGroup.name
            })
        }
        SetInventoryItemTypePrimaryGroupOptions(currentInventoryItemTypePrimaryGroupOptions)
    }, [inventoryItemTypePrimaryGroups, haveItemTypePrimaryGroups])

    React.useEffect(() => {
        if (newInventoryItemCreateStatus === "pending") {
            SetNewInventoryItemCreateSent(true)
        }else   {
            SetNewInventoryItemCreateSent(false)
            if (newInventoryItemCreateStatus === "fulfilled")   {
                if (completeCallback !== undefined) {
                    completeCallback()
                }
            }
        }
    }, [newInventoryItemCreateStatus])
    
    React.useEffect(() => {
        if (newInventoryItemTypeCreateStatus === "pending") {
            SetNewInventoryItemTypeCreateSent(true)
        }else   {
            SetNewInventoryItemTypeCreateSent(false)
            if (newInventoryItemTypeCreateStatus === "fulfilled")   {
                SetCurrentTimelineStep("find_type")
            }
        }
    }, [newInventoryItemTypeCreateStatus])


    const [currentTimelineStep, SetCurrentTimelineStep] = React.useState("find_type")
    const standardTimelineSteps = {
        find_type: { render: (<span>Type</span>) },
        details: { render: (<span>Details</span>) },
        review: { render: (<span>Review</span>) },
    }

    const createTimelineItemSteps = {
        create: { render: (<span>Create</span>) },
        create_item_category: { render: (<span>Category</span>) },
        create_item_details: { render: (<span>Details</span>) },
        create_item_inputs: { render: (<span>Inputs</span>) },
    }
    const [timelineSteps, SetTimelineSteps] = React.useState(standardTimelineSteps)
    const timelineStepChanged = (key) =>    {
        if (currentTimelineStep !== key)    {
            SetCurrentTimelineStep(key)
        }
    }
    const previousStepClicked = () =>   {
        switch (currentTimelineStep)    {
            case "units":
                SetCurrentTimelineStep("find_type")
                break
            case "review":
                SetCurrentTimelineStep("units")
                break
        
            case "create_item_category":
                SetTimelineSteps(standardTimelineSteps)
                SetCurrentTimelineStep("find_type")
                break
            case "create_item_details":
                SetCurrentTimelineStep("create_item_category")
                break
            case "create_item_inputs":
                SetCurrentTimelineStep("create_item_details")
                break
            default:
                break
        }
    }


    const [selectedInventoryItemType, SetSelectedInventoryItemType] = React.useState(null)
    const inventoryItemTypeSelected = (itemType) =>    {
        SetSelectedInventoryItemType(itemType)
        let inputs = {}
        for (let inputKey of Object.keys(itemType.inputs))    {
            inputs[inputKey] = null
        }
        SetNewIntentoryItemInputs(inputs)
    }

    const switchToUnitsToAddClicked = () =>    {
        if (selectedInventoryItemType) {
            SetCurrentTimelineStep("units")
        }
    }
    const [selectedAmountOfUnits, SetSelectedAmountOfUnits] = React.useState(0)
    const selectedNumberOfUnitsChanged = (units) => {
        SetSelectedAmountOfUnits(units)
    }

    const [newIntentoryItemInputs, SetNewIntentoryItemInputs] = React.useState({})
    const SetNewInventoryItemInputValue = (inputKey, inputValue) =>    {
        SetNewIntentoryItemInputs({...newIntentoryItemInputs, [inputKey]: inputValue})
    }



    const switchToReviewClicked = () =>    {
        if (selectedAmountOfUnits !== 0) {
            SetCurrentTimelineStep("review")
        }
    }

    /*const [completedRequiredDetails, SetCompletedRequiredDetails] = React.useState(false)
    
    React.useEffect(() => {
        
        

        SetCompletedRequiredDetails(true)
    }, [])*/

    const FinalizeAddToInventory = React.useCallback(() => {
        let newInventoryItemInfo = {
            item_type_id: selectedInventoryItemType.id,
            item_type_group_id: selectedInventoryItemType.type_group_id,
            amount: selectedAmountOfUnits,
            inputs: newIntentoryItemInputs,
        }
        dispatch(createNewInventoryItem({itemInfo: newInventoryItemInfo}))
    })






    /* CREATE TYPE HANDLERS */
    const switchToCreateInventoryItemTypeClicked = () =>    {
        SetTimelineSteps(createTimelineItemSteps)
        SetCurrentTimelineStep("create_item_category")
    }


    const [selectedNewInventoryItemTypePrimaryCategory, SetSelectedNewInventoryItemTypePrimaryCategory] = React.useState(null)
    const [selectedNewInventoryItemTypeOptionalCategories, SetSelectedNewInventoryItemTypeOptionalCategory] = React.useState([])
    const selectedNewInventoryItemTypePrimaryCategoryChanged = (categoryId) => {
        SetSelectedNewInventoryItemTypePrimaryCategory(inventoryItemTypePrimaryGroups.find((pG) => pG.id === categoryId))
    }

    const selectedNewInventoryItemTypeOptionalCategoryChanged = (categoryId, depth) => {
        console.log(categoryId, depth)
        if (categoryId === "all")   {
            if (depth === 0)    {
                SetSelectedNewInventoryItemTypeOptionalCategory([])
            }else if (selectedNewInventoryItemTypeOptionalCategories[depth] !== undefined)  {
                SetSelectedNewInventoryItemTypeOptionalCategory([...selectedNewInventoryItemTypeOptionalCategories.filter((c, i) => i < depth)])
            }
        }else if (selectedNewInventoryItemTypeOptionalCategories[depth] === undefined || selectedNewInventoryItemTypeOptionalCategories[depth].id !== categoryId)  {
            let optionalCategories = selectedNewInventoryItemTypeOptionalCategories.filter((c, i) => i <= depth)
            optionalCategories[depth] = inventoryItemTypeGroups.find((g) => g.id === categoryId)
            SetSelectedNewInventoryItemTypeOptionalCategory([...optionalCategories])
        }
    }

    React.useEffect(() => {
        if (selectedNewInventoryItemTypePrimaryCategory != null)    {

            if (!selectedNewInventoryItemTypePrimaryCategory.loadedSubgroups)   {
                dispatch(getInventoryItemTypeGroupsByParentGroupId({itemTypeParentGroupId: selectedNewInventoryItemTypePrimaryCategory.id}))
            }else {
                
            }
        }else {
            SetSelectedNewInventoryItemTypeOptionalCategory([])
        }
    }, [selectedNewInventoryItemTypePrimaryCategory])

    React.useEffect(() => {
        for (let selectedNewInventoryItemTypeOptionalCategory of selectedNewInventoryItemTypeOptionalCategories)    {
            if (!selectedNewInventoryItemTypeOptionalCategory.loadedSubgroups)   {
                dispatch(getInventoryItemTypeGroupsByParentGroupId({itemTypeParentGroupId: selectedNewInventoryItemTypeOptionalCategory.id}))
            }
        }
    }, [selectedNewInventoryItemTypeOptionalCategories])


    

    const switchToCreateInventoryTypeDetailsClicked = () => {
        SetCurrentTimelineStep("create_item_details")
    }

    const [newInventoryItemTypeName, SetNewInventoryItemTypeName] = React.useState("")
    const newInventoryItemTypeNameChanged = (name) => {
        SetNewInventoryItemTypeName(name)
    }

    const [newInventoryItemTypeUnit, SetNewInventoryItemTypeUnit] = React.useState("")
    const newInventoryItemTypeUnitChanged = (unit) => {
        SetNewInventoryItemTypeUnit(unit)
    }

    const [newInventoryItemTypeProperties, SetNewInventoryItemTypeProperties] = React.useState([])
    const newInventoryItemTypePropertyKeyChanged = React.useCallback((oldKey, newKey) => {
        let properties = newInventoryItemTypeProperties
        if (oldKey === "empty_field")   {
            properties[properties.length] = {key: newKey, value: ""}
        }else {
            for (let i = 0; i < properties.length; i++) {
                if (properties[i].key === oldKey)    {
                    properties[i].key = newKey
                    break
                }
            }
        }
        SetNewInventoryItemTypeProperties([...properties])
    })
    const newInventoryItemTypePropertyValueChanged = React.useCallback((key, value) => {
        let properties = newInventoryItemTypeProperties
        for (let i = 0; i < properties.length; i++) {
            if (properties[i].key === key)    {
                properties[i].value = value
                break
            }
        }
        SetNewInventoryItemTypeProperties([...properties])
    })
    const newInventoryItemTypePropertyDeleted = React.useCallback((key) => {
        let properties = newInventoryItemTypeProperties
        //delete properties[key]
        SetNewInventoryItemTypeProperties([...properties])
    })


    const switchToCreateInventoryTypeInputsClicked = () =>  {
        SetCurrentTimelineStep("create_item_inputs")
    }



    const [newInventoryItemTypeInputs, SetNewInventoryItemTypeInputs] = React.useState([])
    const newInventoryItemTypeInputKeyChanged = React.useCallback((oldKey, newKey) => {
        let inputs = newInventoryItemTypeInputs
        if (oldKey === "empty_field")   {
            inputs[inputs.length] = {key: newKey, type: "string"}
        }else {
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].key === oldKey)    {
                    inputs[i].key = newKey
                    break
                }
            }
        }
        SetNewInventoryItemTypeInputs([...inputs])
    })
    const newInventoryItemTypeInputTypeChanged = React.useCallback((key, type) => {
        let inputs = newInventoryItemTypeInputs
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].key === key)    {
                inputs[i].type = type
                break
            }
        }
        SetNewInventoryItemTypeInputs([...inputs])
    })
    const newInventoryItemTypeInputDeleted = React.useCallback((key) => {
        let inputs = newInventoryItemTypeInputs
        //delete properties[key]
        SetNewInventoryItemTypeInputs([...inputs])
    })

    const finalizeNewItemType = React.useCallback(() => {
        //createNewInventoryItemType
        const groupId = selectedNewInventoryItemTypeOptionalCategories.length !== 0 ? selectedNewInventoryItemTypeOptionalCategories[selectedNewInventoryItemTypeOptionalCategories.length - 1].id : selectedNewInventoryItemTypePrimaryCategory.id
        let properties = {}
        let inputs = {}

        let newInventoryItemTypeInfo = {
            item_type_group_id: groupId,
            name: newInventoryItemTypeName,
            unit: newInventoryItemTypeUnit,
            properties: {},
            inputs: {}
        }
        for (let property of newInventoryItemTypeProperties)    {
            newInventoryItemTypeInfo.properties[property.key] = property.value
        }
        for (let input of newInventoryItemTypeInputs)    {
            newInventoryItemTypeInfo.inputs[input.key] = input.type
        }

        dispatch(createNewInventoryItemType({itemTypeInfo: newInventoryItemTypeInfo}))
        SetNewInventoryItemTypeCreateSent(true)
    })







    

    let headerDescription = undefined
    let bodyContent = (<></>)
    let footerContent = (<></>)
    switch (currentTimelineStep)    {
        case "find_type": 
            bodyContent = (<>
                <div className="PopupModal-FormItem">
                    <div className="PopupModal-FormItem-Question">
                        Find your item
                    </div>
                    <div className="PopupModal-FormItem-Answer">
                        <SelectInventoryItemType selectedInventoryItemType={selectedInventoryItemType} onInventoryItemTypeSelection={inventoryItemTypeSelected}/>
                    </div>
                </div>
            </>)
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
                    <Button content={"Create New Item"} status={"Neutral"} onClick={switchToCreateInventoryItemTypeClicked}/>
                </>)} secondaryControls={(<>
                    {selectedInventoryItemType && <Button content={"Next Step: Units"} onClick={switchToUnitsToAddClicked}/>}
                </>)}/>
            )
            break
        case "units":
            console.log(selectedInventoryItemType)
            if (selectedInventoryItemType.unit === "units") {
                bodyContent = (<>
                    <div className="PopupModal-FormItem">
                        <div className="PopupModal-FormItem-Question">
                            <span>How many <span style={{fontWeight: "bold"}}>{selectedInventoryItemType.name}</span> do you want to add?</span>
                        </div>
                        <div className="PopupModal-FormItem-Answer">
                            <NumberInput label={"Units"} value={selectedAmountOfUnits} suffix={"units"} onChange={selectedNumberOfUnitsChanged}/>                            
                        </div>
                    </div>
                    {Object.keys(selectedInventoryItemType.inputs).length > 0 &&
                        <div className="PopupModal-FormItem">
                            <div className="PopupModal-FormItem-Question">
                                Inputs
                            </div>
                            <div className="PopupModal-FormItem-Answer">
                            <table className="AddToInventoryPopup-AddIntentoryItemInputs">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Value</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(selectedInventoryItemType.inputs).map(([inputKey, inputType]) => {
                                        const valueChangeFinalized = (newValue) =>  {
                                            console.log(newValue)
                                            SetNewInventoryItemInputValue(inputKey, newValue)
                                            //newInventoryItemTypePropertyValueChanged(property.key, newValue)
                                        }
                                        return (
                                            <tr key={inputKey}
                                                className="AddToInventoryPopup-AddIntentoryItemInput">
                                                <td>{inputKey}</td>
                                                <td>{(() => {
                                                    let currentInputValue
                                                    switch (inputType)  {
                                                        case "text":
                                                            currentInputValue = newIntentoryItemInputs[inputKey] ?? ""
                                                            return (
                                                                <TextInput value={currentInputValue} onBlur={valueChangeFinalized}/>
                                                            )
                                                        case "number":
                                                            currentInputValue = newIntentoryItemInputs[inputKey] ?? 0
                                                            return (
                                                                <NumberInput value={currentInputValue} onBlur={valueChangeFinalized}/>
                                                            )
                                                        case "percentage":
                                                            currentInputValue = newIntentoryItemInputs[inputKey] ?? 0
                                                            return (
                                                                <NumberInput value={currentInputValue} suffix={"%"} onBlur={valueChangeFinalized}/>
                                                            )
                                                        case "weight":
                                                            currentInputValue = newIntentoryItemInputs[inputKey] ?? 0
                                                            return (
                                                                <NumberInput value={currentInputValue} suffix={""} onBlur={valueChangeFinalized}/>
                                                            )
                                                        case "date":
                                                            currentInputValue = newIntentoryItemInputs[inputKey] ? new Date(newIntentoryItemInputs[inputKey]) : null
                                                            const prepareValueChange = (date) =>    {
                                                                valueChangeFinalized(date.toISOString())
                                                            }
                                                            return (
                                                                <DateInput value={currentInputValue} onBlur={prepareValueChange}/>
                                                            )
                                                        default:
                                                            return (
                                                                <TextInput value={currentInputValue} onBlur={valueChangeFinalized}/>
                                                            )
                                                    }
                                                })()}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> 
                            </div>
                        </div>
                    }
                </>)
            }else {
                let inputSuffix = ""
                switch (selectedInventoryItemType.unit) {
                    case "litres":
                        inputSuffix = "L"
                    default:
                        break
                }
                bodyContent = (<>
                    <div className="PopupModal-FormItem">
                        <div className="PopupModal-FormItem-Question">
                            <span>How much <span style={{fontWeight: "bold"}}>{selectedInventoryItemType.name}</span> do you want to add?</span>
                        </div>
                        <div className="PopupModal-FormItem-Answer">
                            <NumberInput label={"Volume"} value={selectedAmountOfUnits} suffix={inputSuffix} onChange={selectedNumberOfUnitsChanged}/>    
                        </div>
                    </div>
                    {Object.keys(selectedInventoryItemType.inputs).length > 0 &&
                        <div className="PopupModal-FormItem">
                            <div className="PopupModal-FormItem-Question">
                                Inputs
                            </div>
                            <div className="PopupModal-FormItem-Answer">
                                  
                            </div>
                        </div>
                    }
                </>)
            }
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Previous: Item Selection"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {!!selectedAmountOfUnits && <Button content={!newInventoryItemCreateSent ? "Finalize" : "Finalizing"} onClick={FinalizeAddToInventory}/>}
                </>)}/>
            )
            break 
        










        case "create_item_category":
            console.log(inventoryItemTypePrimaryGroupOptions)
            bodyContent = (<>
                <div className="PopupModal-FormItem">
                    <div className="PopupModal-FormItem-Question">
                        Primary Category
                    </div>
                    <div className="PopupModal-FormItem-Answer">
                        <DropDownInput
                            uid="primary_item_category" 
                            placeholder="Select Category: "
                            options={inventoryItemTypePrimaryGroupOptions}
                            value={(selectedNewInventoryItemTypePrimaryCategory ? selectedNewInventoryItemTypePrimaryCategory.id : "")}
                            onSelectionChange={(value) => {
                                selectedNewInventoryItemTypePrimaryCategoryChanged(value)
                            }}/>
                    </div>
                </div>
                {selectedNewInventoryItemTypePrimaryCategory && (() => {
                    let firstOptionalCategoryOptions = [{value: "all", label: "All"}]
                    for (let inventoryItemTypeGroup of inventoryItemTypeGroups)   {
                        if (selectedNewInventoryItemTypePrimaryCategory.id === inventoryItemTypeGroup.type_group_id)    {
                            firstOptionalCategoryOptions.push({
                                value: inventoryItemTypeGroup.id, label: inventoryItemTypeGroup.name
                            })
                        }
                    }


                    return (<>
                        
                        <div className="PopupModal-FormItem">
                            <div className="PopupModal-FormItem-Question">
                                Optional Categories
                            </div>
                        </div>
                        <div className="PopupModal-FormItem">
                            <DropDownInput
                                uid="optional_item_category_1" 
                                options={firstOptionalCategoryOptions}
                                value={selectedNewInventoryItemTypeOptionalCategories.length === 0 ? "all" : selectedNewInventoryItemTypeOptionalCategories[0].id}
                                onSelectionChange={(value) => {
                                    selectedNewInventoryItemTypeOptionalCategoryChanged(value, 0)
                                }}/>
                        </div>
                        {selectedNewInventoryItemTypeOptionalCategories.map((optionalCategory, index) => {
                            let currentOptionalCategoryOptions = [{value: "all", label: "All"}]
                            for (let inventoryItemTypeGroup of inventoryItemTypeGroups)   {
                                if (optionalCategory.id === inventoryItemTypeGroup.type_group_id)    {
                                    currentOptionalCategoryOptions.push({
                                        value: inventoryItemTypeGroup.id, label: inventoryItemTypeGroup.name
                                    })
                                }
                            }

                            return (
                                <div className="PopupModal-FormItem" key={index}>
                                    <DropDownInput
                                        uid={"optional_item_category_" + index.toString}
                                        options={currentOptionalCategoryOptions}
                                        value={selectedNewInventoryItemTypeOptionalCategories.length === index + 1 ? "all" : selectedNewInventoryItemTypeOptionalCategories[index].id}
                                        onSelectionChange={(value) => {
                                            selectedNewInventoryItemTypeOptionalCategoryChanged(value, index)
                                    }}/>
                                </div>
                            )
                        })}
                    </>)
                })()}
            </>)
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Cancel"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {selectedNewInventoryItemTypePrimaryCategory && <Button content={"Next: Details"} onClick={switchToCreateInventoryTypeDetailsClicked}/>}
                </>)}/>
            )
            break
            

        case "create_item_details":
            const availableUnitTypes = [
                {value: "units", label: "Units"},
                {value: "kilograms", label: "Kilograms"},
                {value: "litres", label: "Litres"},
            ]
            bodyContent = (<>
                <div className="PopupModal-FormItem">
                    <div className="PopupModal-FormItem-Question">
                        <div className="AddToInventoryPopup-NewIntentoryItemTypeCategoriesDisplay">
                            <Badge content={selectedNewInventoryItemTypePrimaryCategory.name} status={"Neutral"}/>
                            {selectedNewInventoryItemTypeOptionalCategories.length > 0 && <>
                                <span>-</span>
                                {selectedNewInventoryItemTypeOptionalCategories.map((itemTypeCategory, index) => {
                                
                                    return(<div key={index}>
                                        <Badge content={itemTypeCategory.name} status={"Neutral"}/>
                                        {index !== selectedNewInventoryItemTypeOptionalCategories.length - 1 &&
                                            <span>-</span>
                                        }
                                    </div>
                                )})}
                            </>}
                            

                        </div>
                    </div>
                </div>
                {/*<div className="PopupModal-FormItem-Inline">
                    
                    <div className="PopupModal-FormItem-Answer">
                        <DropDownInput 
                            uid="new_item_category" 
                            prefix="Category: "
                            options={timelineItemSelectionOptions}
                            value={(selectedNewInventoryItemTypeCategory !== undefined ? (selectedTimelineItem.id) : "")}
                            onSelectionChange={(value) => {
                                onTimelineItemSelectionChanged(value)
                            }}/>
                    </div>
                </div>*/}
                <div className="PopupModal-FormItem-Inline">
                    <div className="PopupModal-FormItem-Question">
                        Name:
                    </div>
                    <div className="PopupModal-FormItem-Answer">
                        <TextInput value={newInventoryItemTypeName} placeHolder="" onChange={newInventoryItemTypeNameChanged}/>
                    </div>
                </div>
                <div className="PopupModal-FormItem-Inline">
                    <div className="PopupModal-FormItem-Question">
                        Unit:
                    </div>
                    <div className="PopupModal-FormItem-Answer">
                        <DropDownInput 
                            uid="new_item_type_unit" 
                            options={availableUnitTypes}
                            value={(newInventoryItemTypeUnit ? (newInventoryItemTypeUnit.value) : "")}
                            onSelectionChange={(value) => {
                                newInventoryItemTypeUnitChanged(value)
                            }}/>
                    </div>
                </div>
                <div className="PopupModal-FormItem">
                    <div className="PopupModal-FormItem-Question">
                        Properties
                    </div>
                    <table className="AddToInventoryPopup-NewIntentoryItemTypeProperties">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...newInventoryItemTypeProperties, {key: "empty_field", value: "empty_field"}].map((property, index) => {
                                const keyChangeFinalized = (newKey) =>    {
                                    newInventoryItemTypePropertyKeyChanged(property.key, newKey)
                                }
                                const valueChangeFinalized = (newValue) =>  {
                                    newInventoryItemTypePropertyValueChanged(property.key, newValue)
                                }
                                return (
                                    <tr key={index}
                                        className="AddToInventoryPopup-NewIntentoryItemTypeProperty">
                                        {property.key === "empty_field" && (() => {return(<>
                                            <td><TextInput onBlur={keyChangeFinalized}/></td>
                                        </>)})()}
                                        {property.key !== "empty_field" && <>
                                            <td><TextInput value={property.key === "empty_field" ? "" : property.key} onBlur={keyChangeFinalized}/></td>
                                            <td><TextInput value={property.key === "empty_field" ? "" : property.value} onBlur={valueChangeFinalized}/></td>
                                        </>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {/*<div className="PopupModal-FormItem-Answer">
                        <Button content={"Add Property"}/>
                    </div>*/}
                </div>
            </>)
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Back: Category"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {(newInventoryItemTypeName && newInventoryItemTypeUnit) && <Button content={"Next: Inputs"} onClick={switchToCreateInventoryTypeInputsClicked}/>}
                </>)}/>
            )
            break
            case "create_item_inputs":
                const availableInputTypes = [
                    {value: "string", label: "Text"},
                    {value: "number", label: "Number"},
                    {value: "percentage", label: "Percentage"},
                    {value: "weight", label: "Weight"},
                    {value: "date", label: "Date"},
                ]


                bodyContent = (<>
                    <div className="PopupModal-FormItem">
                        <div className="PopupModal-FormItem-Question">
                            Inputs
                        </div>
                        <table className="AddToInventoryPopup-NewIntentoryItemTypeInputs">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {[...newInventoryItemTypeInputs, {key: "empty_field", type: "string"}].map((input, index) => {
                                    const keyChangeFinalized = (newKey) =>    {
                                        newInventoryItemTypeInputKeyChanged(input.key, newKey)
                                    }
                                    return (
                                        <tr key={index}
                                            className="AddToInventoryPopup-NewIntentoryItemTypeInput">
                                            {input.key === "empty_field" && (() => {return(<>
                                                <td><TextInput onBlur={keyChangeFinalized}/></td>
                                            </>)})()}
                                            {input.key !== "empty_field" && <>
                                                <td><TextInput value={input.key === "empty_field" ? "" : input.key} onBlur={keyChangeFinalized}/></td>
                                                <td>
                                                <DropDownInput
                                                    uid={"input_type_" + index.toString}
                                                    options={availableInputTypes}
                                                    value={input.type}
                                                    onSelectionChange={(value) => {
                                                        newInventoryItemTypeInputTypeChanged(input.key, value)
                                                }}/>
                                                </td>
                                            </>}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>)
                footerContent = (
                    <ControlBar controls={(<>
                        <Button content={"Back: Details"} status={"Neutral"} onClick={previousStepClicked}/>
                    </>)} secondaryControls={(<>
                        <Button content={!newInventoryItemTypeCreateSent ? "Create" : "Creating"} onClick={finalizeNewItemType}/>
                    </>)}/>
                )
                break
        default: 
            break
    }



    return (
        <PopupModal 
            title="Add To Inventory"
            closeCallback={currentTimelineStep === "find_type" ? closePopup : undefined}
            timelineSteps={timelineSteps} 
            timelineStep={currentTimelineStep} 
            onStepChange={timelineStepChanged}
            footer={footerContent}>
                {bodyContent}
        </PopupModal>
    )
} 

export default AddToInventoryPopup