import './../TasksPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate } from '../../../helpers'
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import Button from '../../../components/Button';
import { setTaskStep, completeTaskStep, markTaskAsCompleted, markTaskAsStarted } from '../../../redux/entities/Tasks';
import { getGrowById } from '../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../redux/entities/GrowPlans';
import { getRecipeById } from '../../../redux/entities/Recipes'
import ControlBar from '../../../components/ControlBar';
import PopupModal from '../../../model_components/PopupModal';
import NumberInput from '../../../components/input/NumberInput';
import TextInput from '../../../components/input/TextInput'
import DropDownInput from '../../../components/input/DropDownInput';
import { Raft } from '../../../model_components/RaftType';

  

const TaskPopup_GrowZoneCleanOut = ({task, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  let growsList = useSelector((state) => state.grows.grows)
 
  const closePopup = () =>  {
    onClosePopup()
  }

  const [grow, SetGrow] = React.useState(null)

  
  React.useEffect(() => {
    let foundGrow = growsList.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }else {
      SetGrow({...foundGrow})
    }
  }, [task, growsList])



  console.log(task)

  

  const startTaskClicked = React.useCallback(() => {
    dispatch(completeTaskStep({taskId: task.id}))
    //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
  })

  const skipStepClicked = React.useCallback(() => {
    //treat it like next step
  })

  const completeStepClicked = React.useCallback(() => {
    //treat it like next step
  })

  const completeTaskClicked = React.useCallback(() => {
    
    dispatch(markTaskAsCompleted({taskId: task.id}))
    onClosePopup()
  })



  let footerContent = <></>
  var bodyContent = <></>
  if (task.current_step === 1)  {
    const skipCleanOutClicked = () => {
      dispatch(setTaskStep({taskId: task.id, step: 3}))
      //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
    }

    const startCleanOutClicked = () => {
      dispatch(completeTaskStep({taskId: task.id, step: 1}))
      //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
    }

    bodyContent = <>
      {(grow) && <>
        <div className="PopupModal-FormItem">
          <div className="PopupModal-FormItem-Question">
            Check if the zone needs to be washed down or would you like to skip wash down?
          </div>
          <div className="PopupModal-FormItem-Answer">

          </div>
        </div>
      </>}
    </>

    footerContent = <>
        <ControlBar controls={(<>
          <Button content="Cancel" status="Neutral" onClick={closePopup}/>
        </>)} secondaryControls={(<>
            <Button status="Neutral"  content="Skip" onClick={skipCleanOutClicked}/>
            <Button status="Primary" content="Start" onClick={startCleanOutClicked}/>
        </>)}/>
    </>
  }else if (task.current_step === 2)  {
    const finishCleanOutClicked = () => {
      dispatch(completeTaskStep({taskId: task.id, step: 2}))
      //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
    }

    footerContent = <>
      <ControlBar controls={(<>
      
      </>)} secondaryControls={(<>
          <Button status="Primary" content="Complete" onClick={finishCleanOutClicked}/>
      </>)}/>
  </>

    bodyContent = <>
      {(grow) && <>
        <div className="PopupModal-FormItem">
          <div className="PopupModal-FormItem-Question">
            Confirm when you are down washing down.
          </div>
          <div className="PopupModal-FormItem-Answer">

          </div>
        </div>
      </>}
    </>



  }else if (task.current_step === 3)  {
    
    const skipFilterCheckClicked = () => {
      dispatch(setTaskStep({taskId: task.id, step: 4}))
      //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
    }

    const filterChangedClicked = () => {
      dispatch(completeTaskStep({taskId: task.id, step: 4}))
      //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
    }

    const filterCleanedClicked = () => {
      dispatch(completeTaskStep({taskId: task.id, step: 4}))
      //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
    }

    bodyContent = <>
      {(grow) && <>
        <div className="PopupModal-FormItem">
          <div className="PopupModal-FormItem-Question">
            Check the filter. Determine whether a clean or change is necessary.
          </div>
          <div className="PopupModal-FormItem-Answer">

          </div>
        </div>
      </>}
    </>

    footerContent = <>
        <ControlBar controls={(<>
          <Button content="Cancel" status="Neutral" onClick={closePopup}/>
        </>)} secondaryControls={(<>
            <Button status="Neutral"  content="Skip" onClick={skipFilterCheckClicked}/>
            <Button status="Primary" content="Change Filter" onClick={filterChangedClicked}/>
            <Button status="Primary" content="Filter Cleaned" onClick={filterCleanedClicked}/>
        </>)}/>
    </>

  }else if (task.current_step === 4)  {
    
    const skipCleanseCheckClicked = () => {
      dispatch(setTaskStep({taskId: task.id, step: 5}))
      //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
    }

    const beginCleanseClicked = () => {
      dispatch(completeTaskStep({taskId: task.id, step: 4}))
      //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
    }


    bodyContent = <>
      {(grow) && <>
        <div className="PopupModal-FormItem">
          <div className="PopupModal-FormItem-Question">
            Check the system. Determine whether an H2O2 bath is necessary.
          </div>
          <div className="PopupModal-FormItem-Answer">

          </div>
        </div>
      </>}
    </>

    footerContent = <>
        <ControlBar controls={(<>
          <Button content="Cancel" status="Neutral" onClick={closePopup}/>
        </>)} secondaryControls={(<>
            <Button status="Neutral"  content="Skip" onClick={skipCleanseCheckClicked}/>
            <Button status="Primary" content="Begin Full Cleanse" onClick={beginCleanseClicked}/>
        </>)}/>
    </>

  }

  


  return (
    <PopupModal 
      title={task.name + (grow ? " #G" + grow.id : "")}
      closeCallback={closePopup}
      footer={footerContent}>
      {bodyContent}
    </PopupModal>

  )
} 

export default TaskPopup_GrowZoneCleanOut