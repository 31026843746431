import './ManageRackWalkthrough.scss';
import React from 'react';
import PopupModal from '../../../model_components/PopupModal';
import Button from '../../../components/Button';
import ControlBar from '../../../components/ControlBar';
import ScanRackWalkthroughStep from './ManageRackWalkthroughSteps/ScanRackWalkthroughStep';
import ConnectingRackWalkthroughStep from './ManageRackWalkthroughSteps/ConnectingRackWalkthroughStep';
import SelectRackGroupWalkthroughStep from './ManageRackWalkthroughSteps/SelectRackGroupWalkthroughStep';
import { selectAllVerticalRackGroups } from '../../../redux/entities/service/VerticalRackGroup';
import { useSelector } from 'react-redux';
import SelectRackIndexWalkthroughStep from './ManageRackWalkthroughSteps/SelectRackIndexWalkthroughStep';
import SelectRackNameWalkthroughStep from './ManageRackWalkthroughSteps/SelectRackNameWalkthroughStep';
import SelectRackSidesWalkthroughStep from './ManageRackWalkthroughSteps/SelectRackSidesWalkthroughStep';
import ConfirmPowerOnWalkthroughStep from './ManageRackWalkthroughSteps/ConfirmPowerOnWalkthroughStep';
import ManageRackZoneChangesWalkthroughStep from './ManageRackWalkthroughSteps/ManageRackZoneChangesWalkthroughStep';
import Walkthrough from '../../../components/Walkthrough';
import ProgressBar from '../../../components/ProgressBar';
import ConfirmNewRackWalkthroughStep from './ManageRackWalkthroughSteps/ConfirmNewRackWalkthroughStep';


  

const AddRackWalkthrough = ({facilityId, completeCallback, closeCallback}) => {
   
    const [numberOfSteps, SetNumberOfSteps] = React.useState(7)
    const [currentStepIndex, SetCurrentStepIndex] = React.useState(1)

    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }
    const confirmedCancelClicked = (e) =>  {
        

        if (completeCallback !== undefined) {
            completeCallback()
        }
    }

    
    const currentTheme = "white"

    const [rackCode, SetRackCode] = React.useState("")
    const [codeWasScanned, SetCodeScanned] = React.useState(false)
    
    const [selectedConnectionOption, SetSelectedConnectionOption] = React.useState("provide_credentials")

    const [rackUID, SetRackUID] = React.useState("")

    const [rackGroupId, SetRackGroupId] = React.useState(null)
    const [rackIndex, SetRackIndex] = React.useState(null)
    const [rackName, SetRackName] = React.useState(null)

    const [rackSides, SetRackSides] = React.useState({"left": "empty", "right": "empty"})
    const [zoneChanges, SetZoneChanges] = React.useState([])
    
    const verticalRackGroups = useSelector(selectAllVerticalRackGroups)
    let backCallback;


    


    //Animation
    //let CurrentWalkthroughContent = walkthroughContentStack[0]
    let [currentWalkthroughContent, SetCurrentWalkthroughStep] = React.useState(["power_on", null])
    //let [currentWalkthroughContent, SetCurrentWalkthroughStep] = React.useState(["select_rack_group", "rack_group_listing"])
    let [previousWalkthroughContent, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughContent, SetNextWalkthroughStep] = React.useState(null)

    let [walkthroughTransitionToPreviousCompleted, SetWalkthroughTransitionToPreviousCompleted] = React.useState(false)
    React.useEffect(() => {
        if (walkthroughTransitionToPreviousCompleted)   {
            console.log(previousWalkthroughContent)
            SetCurrentWalkthroughStep(previousWalkthroughContent)
            SetPreviousWalkthroughStep(null)
            SetWalkthroughTransitionToPreviousCompleted(false)
            return
        }
    }, [walkthroughTransitionToPreviousCompleted, previousWalkthroughContent])

    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetWalkthroughTransitionToPreviousCompleted(true)
    })


    let [walkthroughTransitionToNextCompleted, SetWalkthroughTransitionToNextCompleted] = React.useState(false)
    React.useEffect(() => {
        if (walkthroughTransitionToNextCompleted)   {
            console.log(nextWalkthroughContent)
            SetCurrentWalkthroughStep(nextWalkthroughContent)
            SetNextWalkthroughStep(null)
            SetWalkthroughTransitionToNextCompleted(false)
            return
        }
    }, [walkthroughTransitionToNextCompleted, nextWalkthroughContent])

    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetWalkthroughTransitionToNextCompleted(true)
    })


    React.useEffect(() => {
        const stepKey = currentWalkthroughContent[0]
        switch (stepKey)  {
            case "power_on":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            case "get_rack_code":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            case "connecting_to_rack":
                if (currentStepIndex !== 2) {
                    SetCurrentStepIndex(2)
                }
                break
                
            case "select_rack_group":
                if (currentStepIndex !== 3) {
                    SetCurrentStepIndex(3)
                }
                break
                
                
            case "select_rack_index":
                if (currentStepIndex !== 4) {
                    SetCurrentStepIndex(4)
                }
                break
                
                
            case "name_rack":
                if (currentStepIndex !== 5) {
                    SetCurrentStepIndex(5)
                }
                break
                
                
            case "rack_type":
                if (currentStepIndex !== 6) {
                    SetCurrentStepIndex(6)
                }
                break
            
            case "manage_zone_changes":
                if (currentStepIndex !== 7) {
                    SetCurrentStepIndex(7)
                }
                break


            case "final_confirm":
                if (currentStepIndex !== 8) {
                    SetCurrentStepIndex(8)
                }
                break


            default:
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
        }
    }, [currentWalkthroughContent, currentStepIndex])


    let showsPopupHeader = true





    const processNewZoneChanges = () => {
        //Based on rack group, index, and rack sides selected, zones are going to be removed or added
        let zoneChanges = []

        let selectedVerticalRackGroup = verticalRackGroups.find((vRG) => vRG.id === rackGroupId)

        let previousRack = null
        let nextRack = null
        for (let existingRack of selectedVerticalRackGroup.rack_map.racks)  {
            if (existingRack.index === rackIndex - 1)   {
                previousRack = existingRack
            }else if (existingRack.index === rackIndex) {
                nextRack = existingRack
            }
        }

        for (const [side, sideType] of Object.entries(rackSides)) {
            console.log(side, "-", sideType)
            if (side === "dual")   {
                switch (sideType)   {
                    case "vine":
                        //Check for conflict on previous zone
                        if (previousRack !== null && previousRack.grow_side_type !== "empty")   {
                            //TODO: Find the zone
                            zoneChanges.push({
                                type: "decommission_zone",
                                for_rack_id: previousRack.id,
                                is_new_zone: false,
                                is_conflict: true,
                                requires_decommission: true,
                                requires_new_type: false,
                            })
                        }

                        //Check for conflict on next zone
                        if (nextRack !== null && nextRack.environment_side_type !== "empty")    {
                            zoneChanges.push({
                                type: "decommission_zone",
                                for_rack_id: nextRack.id,
                                is_new_zone: false,
                                is_conflict: true,
                                requires_decommission: true,
                                requires_new_type: false,
                            })
                        }

                        //Create new dual vine crop zone
                        zoneChanges.push({
                            type: "new_zone",
                            is_new_zone: true,
                            zone_type: "vine",
                            for_side_type: "dual",
                            zone_name: "New Zone"
                        })

                    default:
                        break
                }
                break
            }else if (side === "left")    {
                switch (sideType)   { //Can be nursery, extended nursery, environment, nothing 
                    case "nursery":
                        //Check for conflict on previous zone
                        if (previousRack !== null && previousRack.grow_side_type !== "empty")   {
                            //TODO: Find the zone
                            zoneChanges.push({
                                type: "decommission_zone",
                                for_rack_id: previousRack.id,
                                is_new_zone: false,
                                is_conflict: true,
                                requires_decommission: true,
                                requires_new_type: false,
                            })
                        }
                        //Create new dual vine crop zone
                        zoneChanges.push({
                            type: "new_zone",
                            is_new_zone: true,
                            zone_type: "standard_grow_out_env",
                            for_side_type: "nursery",
                            nursery_index: 1,
                            zone_name: "New Zone 1"
                        })
                        zoneChanges.push({
                            type: "new_zone",
                            is_new_zone: true,
                            zone_type: "standard_grow_out_env",
                            for_side_type: "nursery",
                            nursery_index: 2,
                            zone_name: "New Zone 2"
                        })
                        break

                    case "ext_nursery":
                        //Check for conflict on previous zone
                        if (previousRack !== null && previousRack.grow_side_type !== "empty")   {
                            //TODO: Find the zone
                            zoneChanges.push({
                                type: "decommission_zone",
                                for_rack_id: previousRack.id,
                                is_new_zone: false,
                                is_conflict: true,
                                requires_decommission: true,
                                requires_new_type: false,
                            })
                        }
                        //Create new dual vine crop zone
                        zoneChanges.push({
                            type: "new_zone",
                            is_new_zone: true,
                            zone_type: "standard_grow_out_env",
                            for_side_type: "extended_nursery",
                            zone_name: "New Zone"
                        })
                        break


                    case "standard_grow_out_env":
                        //Check for conflict on previous zone
                        if (previousRack !== null) {
                            if (previousRack.dual_side_type === "vine") {
                                zoneChanges.push({
                                    type: "change_zone",
                                    for_rack_id: previousRack.id,
                                    is_new_zone: false,
                                    is_conflict: true,
                                    requires_decommission: true,
                                    requires_new_type: true,
                                    available_types: ["standard_grow_out", "berries", "vine"],
                                    creates_new_zone: true,
                                    for_side_type: "standard_grow_out",
                                })

                            } else if (previousRack.grow_side_type === "standard_grow_out" || previousRack.grow_side_type === "berries" || previousRack.grow_side_type === "vine") {
                                
                                //Create new dual vine crop zone
                                zoneChanges.push({
                                    type: "new_zone",
                                    is_new_zone: true,
                                    zone_type: "standard_grow_out_env",
                                    for_side_type: "standard_environment",
                                    zone_name: "New Zone"
                                })

                            
                            
                            } else if (previousRack.grow_side_type === "empty") {
                                //TODO: Find the zone
                                zoneChanges.push({
                                    type: "new_zone",
                                    is_new_zone: true,
                                    is_conflict: true,
                                    requires_decommission: false,
                                    requires_new_type: true,
                                    available_types: ["standard_grow_out", "berries", "vine"],
                                    creates_new_zone: true,
                                    for_side_type: "standard_environment",
                                    zone_name: "New Zone"
                                })
                            }
                            
                        }else {
                            zoneChanges.push({
                                type: "new_zone_missing_rack",
                                is_new_zone: false,
                                is_conflict: true,
                                requires_decommission: false,
                                is_missing_rack: true
                            })
                        }
                        break

                    
                    case "empty":
                        if (previousRack !== null && previousRack.grow_side_type !== "empty")    {
                            zoneChanges.push({
                                type: "decommission_zone",
                                for_rack_id: previousRack.id,
                                is_new_zone: false,
                                is_conflict: false,
                                requires_decommission: true,
                                requires_new_type: false,
                            })
                        }
                        break
                    default:
                        break
                }
            }else if (side === "right")    {
                switch (sideType)   { //Can be grow out, berries, vine, nothing
                    case "standard_grow_out":                                
                        

                        //Check for conflict on next zone
                        if (nextRack !== null)  {

                            if (nextRack.environment_side_type !== "standard_grow_out_env")    {
                                zoneChanges.push({
                                    type: "change_zone",
                                    for_rack_id: nextRack.id,
                                    is_new_zone: false,
                                    is_conflict: true,
                                    requires_decommission: true,
                                    requires_new_type: ["standard_grow_out_env"],
                                    creates_new_zone: true,
                                    for_side_type: "standard_grow_out",
                                })
                            }

                        }else {
                            zoneChanges.push({
                                type: "new_zone_missing_rack",
                                is_new_zone: false,
                                is_conflict: true,
                                requires_decommission: false,
                                is_missing_rack: true
                            })
                        }
                        break

                    case "berries":                                
                        

                        //Check for conflict on next zone
                        if (nextRack !== null)  {

                            if (nextRack.environment_side_type !== "standard_grow_out_env")    {
                                zoneChanges.push({
                                    type: "change_zone",
                                    for_rack_id: nextRack.id,
                                    is_new_zone: false,
                                    is_conflict: true,
                                    requires_decommission: true,
                                    requires_new_type: ["standard_grow_out_env"],
                                    creates_new_zone: true,
                                    for_side_type: "berries",
                                })
                            }

                        }else {
                            zoneChanges.push({
                                type: "new_zone_missing_rack",
                                is_new_zone: false,
                                is_conflict: true,
                                requires_decommission: false,
                                is_missing_rack: true
                            })
                        }
                        break

                    case "vine":                                
                        

                        //Check for conflict on next zone
                        if (nextRack !== null)  {

                            if (nextRack.environment_side_type !== "standard_grow_out_env")    {
                                zoneChanges.push({
                                    type: "change_zone",
                                    for_rack_id: nextRack.id,
                                    is_new_zone: false,
                                    is_conflict: true,
                                    requires_decommission: true,
                                    requires_new_type: ["standard_grow_out_env"],
                                    creates_new_zone: true,
                                    for_side_type: "vine",
                                })
                            }

                        }else {
                            zoneChanges.push({
                                type: "new_zone_missing_rack",
                                is_new_zone: false,
                                is_conflict: true,
                                requires_decommission: false,
                                is_missing_rack: true
                            })
                        }
                        break

                    case "empty":
                        if (nextRack !== null && nextRack.environment_side_type !== "empty")    {
                            zoneChanges.push({
                                type: "decommission_zone",
                                for_rack_id: nextRack.id,
                                is_new_zone: false,
                                is_conflict: false,
                                requires_decommission: true,
                                requires_new_type: false,
                            })
                        }
                    default:
                        break
                }
            }
        }


        SetZoneChanges(zoneChanges)
    }
    
    const transitionToPreviousStep = (stepKeys) =>  {
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetNextWalkthroughStep(stepKeys)
    }
    const loadWalkthroughStep = (stepKeys) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]

        switch (stepKey)    {
            case "power_on":
                return (<ConfirmPowerOnWalkthroughStep transitionToNextStep={transitionToNextStep} />) 

            case "get_rack_code":
                const scanRackCallback = ({currentRackCode, codeWasScanned}) =>  {
                    SetRackCode(currentRackCode)
                    SetCodeScanned(codeWasScanned)
                }
                return (<ScanRackWalkthroughStep transitionToNextStep={transitionToNextStep} scanRackCallback={scanRackCallback} rackCode={rackCode}/>)  

            case "connecting_to_rack":
                const rackConnectedCallback = ({rackUID}) =>  {
                    SetRackUID(rackUID)
                }
                return (<ConnectingRackWalkthroughStep 
                            rackCode={rackCode}
                            selectedConnectionOption={selectedConnectionOption}
                            rackConnectionTypeSelectionCallback={(type) => SetSelectedConnectionOption(type)}
                            currentStep={subStepKey} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep} 
                            rackConnectedCallback={rackConnectedCallback}/>)        

            case "select_rack_group":
                const rackGroupSelectedCallback = ({rackGroupId}) =>  {
                    SetRackGroupId(rackGroupId)
                    let foundVerticalRackGroup = verticalRackGroups.find((vRG) => vRG.id === rackGroupId)
                    if (foundVerticalRackGroup !== undefined)   {
                        if (foundVerticalRackGroup.rack_map.racks.length !== 0)   {
                            return
                        }
                    }
                }
                return (<SelectRackGroupWalkthroughStep 
                            currentStep={subStepKey}
                            facilityId={facilityId} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep} 
                            rackGroupSelectedCallback={rackGroupSelectedCallback}/>)    
            
            case "select_rack_index":
                
                const rackIndexSelectedCallback = ({rackIndex}) =>  {
                    SetRackIndex(rackIndex)
                }
                let foundVerticalRackGroup = verticalRackGroups.find((vRG) => vRG.id === rackGroupId)
                let initialRackIndex = rackIndex != null ? rackIndex : foundVerticalRackGroup !== undefined ? foundVerticalRackGroup.rack_map.racks.length + 1 : 1
                return (<SelectRackIndexWalkthroughStep 
                            movingRackIndex={null} 
                            initialRackIndex={initialRackIndex} 
                            facilityId={facilityId} 
                            rackGroupId={rackGroupId} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep} 
                            rackIndexSelectedCallback={rackIndexSelectedCallback}/>)
            
            case "name_rack":
                const rackNameSelectedCallback = ({rackName}) =>  {
                    SetRackName(rackName)
                }
                let initialRackName = rackName !== null ? rackName : "Rack " + rackIndex
                
                return (<SelectRackNameWalkthroughStep 
                            rackIndex={rackIndex} 
                            initialRackName={initialRackName} 
                            facilityId={facilityId} 
                            rackGroupId={rackGroupId} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep} 
                            rackNameSelectedCallback={rackNameSelectedCallback}/>)
                
            case "rack_type":
                const rackSidesSelectedCallback = ({rackSides}) =>  {
                    SetRackSides(rackSides)
                    //SetCurrentStep("manage_zone_changes")
                }
                
                return (<SelectRackSidesWalkthroughStep 
                            facilityId={facilityId} 
                            rackGroupId={rackGroupId} 
                            rackSides={rackSides}
                            currentStep={subStepKey}
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            rackSidesSelectedCallback={rackSidesSelectedCallback}
                            processNewZoneChanges={processNewZoneChanges}/>)
            

            case "manage_zone_changes":
                const rackZonesManagedCallback = ({zoneChanges}) =>  {
                    SetZoneChanges([...zoneChanges])
                    //SetCurrentStep("confirm")
                }

                
                return (<ManageRackZoneChangesWalkthroughStep 
                            facilityId={facilityId} 
                            zoneChanges={zoneChanges} 
                            currentZoneChangeIndex={subStepKey}
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            rackZonesManagedCallback={rackZonesManagedCallback}/>)

            case "final_confirm":
                const rackConfirmedCallback = () =>  {
                    //SetCurrentStep("confirm")
                }

                
                return (<ConfirmNewRackWalkthroughStep 
                            facilityId={facilityId}
                            rackName={rackName}
                            rackGroupId={rackGroupId} 
                            rackIndex={rackIndex} 
                            rackSides={rackSides}
                            zoneChanges={zoneChanges} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            rackConfirmedCallback={rackConfirmedCallback}/>)

            default:
                return <></>
        }
    }


    const walkthroughComponentProps = {}
    if (previousWalkthroughContent !== null)    {
        walkthroughComponentProps.transitionBackToContent = loadWalkthroughStep(previousWalkthroughContent)
    }
    if (nextWalkthroughContent !== null)    {
        walkthroughComponentProps.transitionToContent = loadWalkthroughStep(nextWalkthroughContent)
    }


    return (<>
        <Walkthrough
            id={"ManageRackWalkthrough_Popup"}
            title={<>
                {showsPopupHeader && <>
                    <span>Add Rack</span>
                    {/*<span>Step {currentStepIndex}/7</span>*/}
                </>}
            </>}
            subHeaderContent={<>
                <div className="FlexContent-HFill" style={{flex:1}}>
                    <ProgressBar numberOfSteps={numberOfSteps} currentStep={currentStepIndex} completedBarFill={"#ABC7ED"}/>
                </div>
            </>}
            hideMainSeparators={true}
            closeCallback={closePopup}
            size={"full_screen"}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}
            content={<>
                {(() => {
                    return loadWalkthroughStep(currentWalkthroughContent)
                })()}
            </>} {...walkthroughComponentProps}/>
        </>)

}



export default AddRackWalkthrough