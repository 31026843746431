

//export const Host = "https://3.128.244.115:10051/" //Development Application Server Host
export const WorkingHost = "https://api.crclr.tech:10051/" //Development Application Server Host
export const DevHost = "https://api.crclr.tech:10061/" //Development Application Server Host

const getHost = () =>   {
    if (window.location.origin.indexOf("dev") !== -1)   {
        return DevHost
    }else {
        return WorkingHost
    }
}

export const Repository = {
    AppInfo: {
        GetAppInfo: getHost() + "getAppInfo"
    },
    Common: {
        GetNutrientSolutionById: getHost() + "common/getNutrientSolutionById",
    },

    Facility: {
        GetAllFacilitiesForAccount: getHost() + "facility/getAllFacilitiesForAccount",
        GetAllServicesForFacility: getHost() + "facility/getAllServicesForFacility",
    },
    VerticalRackGroup: {
        GetVerticalRackGroupById: getHost() + "verticalRackGroup/getVerticalRackGroupById",
        GetVerticalRackGroupByServiceId: getHost() + "verticalRackGroup/getVerticalRackGroupByServiceId",
        GetVerticalRackStatusById: getHost() + "verticalRackGroup/getVerticalRackStatusById",
        SetVerticalRackRuntimeProperty: getHost() + "verticalRackGroup/setVerticalRackRuntimeProperty",
        GetVerticalRackLiveDataById: getHost() + "verticalRackGroup/getVerticalRackLiveDataById",
        GetVerticalRackAnalyticsData: getHost() + "verticalRackGroup/getVerticalRackAnalyticsData",
        GetVerticalRackConfigurationMap: getHost() + "verticalRackGroup/getVerticalRackConfigurationMap",
        GetVerticalRackZoneConfigurationMap: getHost() + "verticalRackGroup/getVerticalRackZoneConfigurationMap",

        ValidateVerticalRackSystemConnection: getHost() + "verticalRackGroup/validateVerticalRackSystemConnection",
        CreateNewVerticalRackGroup: getHost() + "verticalRackGroup/createNewVerticalRackGroup"
    },
    NDS: {
        GetAllNDSForFacility: getHost() + "nds/getNDSForFacility",
        GetNDSById: getHost() + "nds/getNDSById",
        GetNDSByServiceId: getHost() + "nds/getNDSByServiceId",
        GetNDSProperty: getHost() + "nds/getNDSProperty",
        SetNDSProperty: getHost() + "nds/setNDSProperty",
    },
    Inventory: {
        GetAllInventoryItems: getHost() + "inventory/getAllInventoryItems",
        CreateNewInventoryItem: getHost() + "inventory/createNewInventoryItem",
        GetInventoryItemsByItemTypeGroupId: getHost() + "inventory/getInventoryItemsByItemTypeGroupId",
        GetAllInventoryItemTypes: getHost() + "inventory/getAllInventoryItemTypes",
        GetInventoryItemTypeById: getHost() + "inventory/getInventoryItemTypeById",
        CreateNewInventoryItemType: getHost() + "inventory/createNewInventoryItemType",
        GetAllInventoryItemTypePrimaryGroups: getHost() + "inventory/getAllInventoryItemTypePrimaryGroups",
        GetInventoryItemTypeGroupById: getHost() + "inventory/getInventoryItemTypeGroupById",
        GetInventoryItemTypeGroupsByParentGroupId: getHost() + "inventory/getInventoryItemTypeGroupsByParentGroupId",
        GetInventoryItemTypeGroupByPath: getHost() + "inventory/getInventoryItemTypeGroupByPath",
    },
    Tasks: {
        GetAllTasksForAccount: getHost() + "tasks/getAllTasksForAccount",
        MarkTaskAsStarted: getHost() + "tasks/markTaskAsStarted",
        SetTaskStep: getHost() + "tasks/setTaskStep",
        CompleteTaskStep: getHost() + "tasks/completeTaskStep",
        MarkTaskAsCompleted: getHost() + "tasks/markTaskAsCompleted",
    },
    GrowPlans: {
        GetAllGrowPlansForAccount: getHost() + "growPlans/getAllGrowPlansForAccount",
        GetGrowPlanById: getHost() + "growPlans/getGrowPlanById",
        Create: getHost() + "growPlans/create",
    },
    PlantTypes: {
        GetAllPlantTypesForAccount: getHost() + "plantTypes/getAllPlantTypesForAccount"
    },
    PlantVarieties: {
        GetAllPlantVarietiesForAccount: getHost() + "plantVarieties/getAllPlantVarietiesForAccount"
    },
    RaftTypes: {
        GetAllRaftTypesForAccount: getHost() + "raftTypes/getAllRaftTypesForAccount"
    },
    Grows: {
        GetAllGrows: getHost() + "grow/getAllGrows",
        GetGrowById: getHost() + "grow/getGrowById",
        GetGrowAnalyticsData: getHost() + "grow/getGrowAnalyticsData",
        GetGrowLiveData: getHost() + "grow/getGrowLiveData",
        GetGrowDosingHistory: getHost() + "grow/getGrowDosingHistory",
        ScheduleNewGrow: getHost() + "grow/scheduleNewGrow",
        ScheduleNewGrowGroup: getHost() + "grow/scheduleNewGrowGroup",
    },
    Recipes: {
        CreateRecipe: getHost() + "recipe/createRecipe",
        GetAllRecipes: getHost() + "recipe/getAllRecipes",
        GetRecipeById: getHost() + "recipe/getRecipeById",
        GetWorkingRecipeById: getHost() + "recipe/getWorkingRecipeById",
        PushRecipeChange: getHost() + "recipe/pushRecipeChange",
        SetRecipeRevisionIndex: getHost() + "recipe/setRecipeRevisionIndex",
        SaveRecipe: getHost() + "recipe/saveRecipe",
        DiscardRecipeChanges: getHost() + "recipe/discardRecipeChanges",
    }
}

export const FetchPost = (path, payload) =>  {
    return fetch(path, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
    }).then( (response) => response.json() );
}