import { CloseDropdown, OpenCell, OpenDropdown, SimpleCheck } from '../../assets/icons/Icons';
import { useMeasure, useMeasureWithTrigger } from '../../helpers';
import './DropDownInput.scss';

import * as React from 'react';



/*const DropDownInputPopup = ({}) => {

}*/

const DropDownInput = ({status, placeholder, isOpen, flex, disabled, displayWidth, popupMargin, screenMargin, popupBorderThickness,
                        scrollMinItems, label, uid, options, value, defaultValue, prefix, suffix, onSelectionChange, onContainerClicked}) => {


  const [isOpenState, SetIsOpenState] = React.useState()
  React.useLayoutEffect(() => {
    SetIsOpenState(isOpen)
  }, [isOpen])

  const [currentValue, SetCurrentValue] = React.useState("");

  const handleChange = (e) => {
    SetCurrentValue(e.target.value);

    if (onSelectionChange !== undefined)  {
      onSelectionChange(e.target.value, e)
    }
  };

  React.useEffect(() => {
    if (value !== undefined && value !== currentValue) {
      SetCurrentValue(value)
    }
  }, [value])

  React.useEffect(() => {
    if (value === undefined)  {
      SetCurrentValue(defaultValue)
    }
  }, [defaultValue])

  React.useEffect(() => {
    if (currentValue === "" && defaultValue !== "") {
      for (let option of options) {
        if (option.value == defaultValue) {
          SetCurrentValue(defaultValue)
          break
        }
      }
    }
  }, [options])
  let selectedOption = options.find((o) => o.value === currentValue); 
  

  const containerClicked = (e) => {
    if (disabled) {
      SetIsOpenState(false)
      return
    }
    if (onContainerClicked !== undefined) {
      onContainerClicked(e)
    }
    dropDownInputBindTrigger()
    SetIsOpenState(!isOpenState)
  }

  const closePopup = (e) => {
    SetIsOpenState(false)
  }



  const [dropDownInputContainerBind, dropDownInputBindTrigger, {documentLeft: dropDownInputLeft, documentTop: dropDownInputTop, documentRight: dropDownInputRight, documentBottom: dropDownInputBottom, width: dropDownInputWidth, height: dropDownInputHeight}] = useMeasureWithTrigger()
  const [dropDownPopupContainerBind, {width: dropDownPopupWidth, height: dropDownPopupHeight}] = useMeasure()
  const [dropDownPopupContentBind, {width: dropDownPopupContentWidth, height: dropDownPopupContentHeight}] = useMeasure()

  //const [dropDownPopupItemBind, {height: dropDownPopupItemHeight}] = useMeasure()
  let dropDownPopupItemHeight = 30
  const [dropDownPopupMinimumScrollHeight, SetDropDownPopupMinimumScrollHeight] = React.useState(0)

  React.useEffect(() => {
    SetDropDownPopupMinimumScrollHeight(scrollMinItems * dropDownPopupItemHeight)
  }, [dropDownPopupItemHeight])

  const dropDownInputStyleProps = {}
  if (flex) {
    dropDownInputStyleProps.flex = "1"
  }


  const dropDownPopupProps = {style:{}}
  if (!isOpenState) {
    dropDownPopupProps.style.height = 0
  }else {
    //dropDownPopupProps.style.height = dropDownPopupHeight
    //Calculate popup position
  }

  let minimumHeightRequired = dropDownPopupMinimumScrollHeight + ((popupBorderThickness * 2) + popupMargin + screenMargin)
  let desiredHeightRequired = dropDownPopupContentHeight + (popupBorderThickness * 2) + (popupMargin + screenMargin)
  if (dropDownInputBottom - minimumHeightRequired > 0)  {
    dropDownPopupProps.style.top = dropDownInputTop + dropDownInputHeight + popupMargin

    if (isOpenState) {
      if (dropDownInputBottom - desiredHeightRequired > 0)  {
        dropDownPopupProps.style.height = dropDownPopupContentHeight + popupBorderThickness * 2
      }else {
        dropDownPopupProps.style.height = dropDownInputBottom - (popupMargin + screenMargin)
      }
    }

  }else if (dropDownInputTop - minimumHeightRequired > 0) {
    dropDownPopupProps.style.bottom = dropDownInputTop - popupMargin

    if (isOpenState) {
      if (dropDownInputTop - desiredHeightRequired > 0)  {
        dropDownPopupProps.style.height = dropDownPopupContentHeight + popupBorderThickness * 2
      }else {
        dropDownPopupProps.style.height = minimumHeightRequired
      }
    }
  }else {
    dropDownPopupProps.style.bottom = screenMargin
    dropDownPopupProps.style.height = minimumHeightRequired
  }


  let desiredWidthRequired = dropDownPopupContentWidth + (popupBorderThickness * 2) + screenMargin
  if ((dropDownInputRight + dropDownInputWidth) - desiredWidthRequired > 0) {
    dropDownPopupProps.style.left = dropDownInputLeft
  }else if (dropDownInputLeft - desiredWidthRequired > 0) {
    dropDownPopupProps.style.right = screenMargin
  }



  const dropDownItemDisplayProps = {style:{}}
  if (displayWidth !== "auto")  {
    dropDownItemDisplayProps.style.width = displayWidth
  }

  return (<>
    <div className={"DropDownInput DropDownInput-" + status + (isOpenState ? " DropDownInput-Selected" : "")+ (disabled ? " DropDownInput-Disabled" : "")}
      onClick={containerClicked} style={dropDownInputStyleProps} {...dropDownInputContainerBind}>
      <div className="DropDownInput-Container">
        <div className="DropDownInput-Field"> 
          {selectedOption !== undefined && 
            <div className="DropDownInput-Field-SelectedItem noselect" {...dropDownItemDisplayProps}>
              {selectedOption.label}
            </div>          
          }
          {(selectedOption === undefined && placeholder !== null) && 
            <div className="DropDownInput-Field-Placeholder noselect" {...dropDownItemDisplayProps}>
              {placeholder}
            </div>          
          }

          <div className="DropDownInput-Field-Arrow">
            {isOpenState && 
              <CloseDropdown/>
            }
            {!isOpenState && 
              <OpenDropdown/>
            }
          </div>
        </div>
      </div>


    </div>

    
    <div className="DropDownInput-Popup-Wrapper" {...dropDownPopupProps}>
        <div className="DropDownInput-Popup-Container" {...dropDownPopupContainerBind}>
          <div className="DropDownInput-Popup" style={{borderWidth:popupBorderThickness}}>
            <div className="DropDownInput-Popup-ContentWrapper" {...dropDownPopupContentBind}>
              <div className="DropDownInput-Popup-Content">

                <div className="DropDownInput-Popup-Options">
                  {options.map((option) => {

                    const optionClicked = (e) => {
                      if (isDisabled) {
                        return
                      }
                      SetCurrentValue(option.value)
                      if (onSelectionChange !== undefined)  {
                        onSelectionChange(option.value, e)
                      }
                      closePopup()
                    }

                    let isSelectedOption = currentValue === option.value
                    let isDisabled = option.disabled !== undefined && option.disabled === true

                    return (<div className={"DropDownInput-Popup-Option" + (isSelectedOption ? " DropDownInput-Popup-Option-Selected" : "") + (isDisabled ? " DropDownInput-Popup-Option-Disabled" : "")} onClick={optionClicked}>

                      <div className="DropDownInput-Popup-Option-Title noselect">
                        {isSelectedOption && <SimpleCheck/>}
                        {option.label}
                      </div>

                      {(option.info !== undefined && option.info !== null) && 
                        <div className="DropDownInput-Popup-Option-Info noselect">
                          {option.info}
                        </div>
                      }

                    </div>)
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpenState &&
        <div className="DropDownInput-Popup-Closer" onClick={closePopup}></div>
      }
    </>
    
  )
} 

DropDownInput.defaultProps = {
  status: "Neutral",
  flex: false,
  disabled: false,
  popupMargin: 4,
  screenMargin: 10,
  scrollMinItems: 3,
  popupBorderThickness: 1,
  displayWidth: "auto",
  options: [],
  defaultValue: "",
  prefix: null, 
  suffix: null
}

export default DropDownInput