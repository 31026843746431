import React from "react";
import { selectAllGrows, getAllGrows, getGrowAnalyticsData, growUpdate, initializeDataRecordingTimePeriodTypes, getGrowDosingHistory} from '../../redux/entities/Grow'
import { selectAllNutrientSolutions, selectNutrientSolutionById, getNutrientSolutionById, getNutrientSolutionsByIds} from '../../redux/entities/common/NutrientSolutions'
import { selectAllDataRecordingTimePeriodTypes, selectAllChartAxisTypes,  } from '../../redux/AppInfo'
import { useSelector, useDispatch } from 'react-redux'
import {FormatTime, FormatDate, useMeasure, binaryClosestIdx, RoundToNearest} from '../../helpers'
import ResizeObserver from 'resize-observer-polyfill'
import {MdOutlineCompress} from 'react-icons/md'
import { 
    lightningChart, 
    AxisTickStrategies, 
    Themes, 
    SolidLine, 
    emptyLine,
    EmptyFill, 
    FontSettings,
    emptyTick,
    SolidFill,
    ColorHEX,
    ColorRGBA,
    UIElementBuilders,
    emptyFill,
    transparentFill,
    AutoCursorModes,
} from '@arction/lcjs'

import GroupedOptions from '../../components/input/GroupedOptions.js'

import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

//import variables from '../../globals.scss';

const mainChartTheme = {...Themes.lightNew,
    seriesBackgroundFillStyle: new SolidFill({
        color: ColorRGBA( 255, 255, 255, 255 )
    }),


    seriesBackgroundStrokeStyle: emptyLine,

    backgroundFillStyle: new SolidFill({
        color: ColorRGBA( 255, 255, 255, 255 )
    }),

    backgroundStrokeStyle: emptyLine,
    panelBackgroundFillStyle: new SolidFill({
        color: ColorRGBA( 255, 255, 255, 255 )
    }),

    dateTimeTickStrategy: Themes.lightNew.dateTimeTickStrategy
    .setFormattingSecond(
        { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' },
        { hour: '2-digit', minute: '2-digit', second: '2-digit' },
        ( value ) => new Date( value ).getUTCMilliseconds(),
    )
    .setMajorTickStyle((majorTicks) =>
      majorTicks
        .setLabelFont(new FontSettings({ size: 13, style: 'bold' }))
          .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
    )
    .setMinorTickStyle((minorTicks) =>
        minorTicks
            .setLabelFont(new FontSettings({ size: 12, style: '' }))
            .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
    )
    .setGreatTickStyle((greatTicks) =>
        emptyTick 
    ),

    timeTickStrategy: Themes.lightNew.timeTickStrategy
    
    .setMajorTickStyle((majorTicks) =>
      majorTicks
        .setLabelFont(new FontSettings({ size: 13, style: 'bold' }))
          .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
    )
    .setMinorTickStyle((minorTicks) =>
        minorTicks
            .setLabelFont(new FontSettings({ size: 12, style: '' }))
            .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
    ),

    numericTickStrategy: Themes.lightNew.numericTickStrategy
    .setMajorTickStyle((majorTicks) =>
      majorTicks
        .setLabelFont(new FontSettings({ size: 12, style: '' }))
          .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
    )
    .setMinorTickStyle((minorTicks) =>
        minorTicks
            .setLabelFont(new FontSettings({ size: 12, style: '' }))
            .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
    ),
}


const GrowManagerDataTypeToggle = ({name, info, onGroupToggle, onDataTypeToggle}) =>  {
    
    
    const groupedToggled = () =>    {
        if (onGroupToggle !== undefined)    {
            onGroupToggle(name, !info.selected)
        }
    }
    
    const onOptionChange = (option, selected) =>  {
        if (info.dataTypes[option.id] !== undefined)    {
            if (onDataTypeToggle !== undefined) {
                //onGroupToggle(name, true)
                onDataTypeToggle(info.dataTypes[option.id], selected)
            }
            //info.dataTypes[option.id].selected = selected
            //console.log(info.dataTypes[option.id])
        }
    }


    let options = []
    for (let dataType in info.dataTypes)    {
        options.push({
            id: dataType,
            value: dataType,
            label: info.dataTypes[dataType].label,
            selected: info.dataTypes[dataType].selected,
            prefixContent:(
                <div className="GrowManagerDataToggle-DataTypeColorIndicator" style={{backgroundColor:info.dataTypes[dataType].color}}/>
            ),            
        })
    }

    return (
        <GroupedOptions 
            uid={name} 
            label={info.label} 
            options={options}
            groupActive={info.selected}
            onGroupToggle={groupedToggled}
            onOptionChange={onOptionChange}/>
    )
}

const GrowManagerEnergyDataTypeToggle = ({updateEnergyDataCallback, onEnergySubselectionToggle, energySubselectionActive}) =>  {
    
    const format = (value) =>   {
        return (Math.round(value * 100) / 100).toFixed(2)
    }

    const [totals, SetTotals] = React.useState({
        power: format(0),
        cost: format(0),
    });

   


    const energyDataCallback = React.useCallback((data) =>    {
        let totalPower = 0
        let totalCost = 0
        for (let growId in data)  {
            totalPower += data[growId]["power"]
            totalCost += data[growId]["cost"]
        }
        totalPower = format(totalPower)
        totalCost = format(totalCost)
        if (totals.power != totalPower || totals.cost != totalCost) {
            SetTotals({
                power: totalPower,
                cost: totalCost
            })
        }
    })
    React.useEffect( () => {
        if (updateEnergyDataCallback !== undefined) {
            updateEnergyDataCallback(energyDataCallback)
        }
        return () => {
            updateEnergyDataCallback(undefined)
        }
    }, [])




    const energySubselectionToggled = React.useCallback(() => {
        if (onEnergySubselectionToggle !== undefined)   {
            onEnergySubselectionToggle()
        }
    })

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const prevOpen = React.useRef(open);
    const handleToggleOpen = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClickAway = () => {
        setOpen(false)
    }

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <div className="GroupedOptions">
                <div className="GroupedOptions-Content">
                    <div
                        className="GroupedOptions-Main"
                        ref={anchorRef}
                        onClick={handleToggleOpen}>
                        <div className="GroupedOptions-Display noselect">
                            <div className="GrowManagerDataToggle-EnergyDisplay">
                                <span className="GrowManagerDataToggle-EnergyDisplay-Value noselect">{totals.power}</span>
                                <span className="GrowManagerDataToggle-EnergyDisplay-P-Unit noselect">kWh</span>
                                <span className="GrowManagerDataToggle-EnergyDisplay-C-Unit noselect">$</span>
                                <span className="GrowManagerDataToggle-EnergyDisplay-Value noselect">{totals.cost}</span>
                            </div>
                        </div>
                    </div>
                    <div 
                        className={"GrowManagerDataToggle-EnergySubselectionToggle GroupedOptions-DropDown noselect" + (energySubselectionActive ? " GrowManagerDataToggle-EnergySubselectionActive" : "")}
                        onClick={energySubselectionToggled}>
                        <MdOutlineCompress/>
                    </div>
                </div>
            </div>
            <Popper 
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                    <Box>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Paper>
                        <div className="GroupedOptions-DropDownContent">
                            
                        </div>
                        </Paper>
                    </ClickAwayListener>
                    </Box>
                    </Fade>
                )}
            </Popper>
        </>
        
    )
}

const GrowManagerChartsPage = ({pageHeaderHelper}) => {

    const chartRef = React.useRef(undefined)

    const energySubSelectionCanvasRef = React.useRef(undefined)
    const [energySubselectionActive, SetEnergySubselectionActive] = React.useState(false);

    const overviewSubSelectionCanvasRef = React.useRef(undefined)
    let energyDataTypeToggleDataUpdate = React.useRef(undefined)
    

    const [activeDataSeries, SetActiveDataSeries] = React.useState({});
    
    const [dataTypeToggles, SetDataTypeToggles] = React.useState({
        air: { label: "Air", selected: true, dataTypes: {
            temp: { label: "Temp", identifier: "airt", color: "rgb(51,160,44)", yAxis: "temp", selected: true, defaultSelected: true, resolution: 0.5, unit: "°C"},
            rh: { label: "RH", identifier: "airrh", color: "rgb(135,125,185)", yAxis: "rh", selected: true, defaultSelected: true, resolution: 1, unit: "%"},
            vpd: { label: "VPD", identifier: "airvpd", color: "rgb(21,120,90)", yAxis: "vpd", selected: true, defaultSelected: true, resolution: 0.1, unit: "kPa"},
            cO2: { label: "cO2", identifier: "airc", color: "rgb(150,155,0)", yAxis: "co2", selected: false, defaultSelected: false, resolution: 1, unit: "ppm"},
            leafTemp: { label: "Leaf Temp", identifier: "leaft", color: "rgb(31,120,84)", yAxis: "temp", selected: false, defaultSelected: false, resolution: 0.5, unit: "°C"}
        } },
        water: { label: "Water", selected: false, dataTypes: {
            temp: { label: "Temp", identifier: "watert", color: "rgb(101,120,64)", yAxis: "temp", selected: true, defaultSelected: true, resolution: 0.5, unit: "°C"},
            pH: { label: "pH", identifier: "waterph", color: "rgb(255,0,86)", yAxis: "ph", selected: true, defaultSelected: true, resolution: 0.1, unit: ""},
            EC: { label: "EC", identifier: "waterec", color: "rgb(0,0,139)", yAxis: "ec", selected: true, defaultSelected: true, resolution: 1, unit: "S/m"},
            DO: { label: "DO", identifier: "waterdo", color: "rgb(158,0,142)", yAxis: "do", selected: false, defaultSelected: false, resolution: 1, unit: ""},
            ORP: { label: "ORP", identifier: "waterorp", color: "rgb(1,0,103)", yAxis: "orp", selected: false, defaultSelected: false, resolution: 1, unit: "mV"}
        } },
        lights: { label: "Lights", selected: false, dataTypes: {
            red: { label: "Red", identifier: "lightr", color: "rgb(255,100,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s"},
            green: { label: "Green", identifier: "lightg", color: "rgb(100,255,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s"},
            blue: { label: "Blue", identifier: "lightb", color: "rgb(100,100,255)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s"},
            farRed: { label: "Far Red", identifier: "lightfr", color: "rgb(255,150,150)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s"},
        } },
        condensate: { label: "Condensate", selected: false, dataTypes: {
            condensateVolume: { label: "Total", identifier: "condensate-t", color: "rgb(255,150,150)", yAxis: "litres", selected: true, defaultSelected: true, resolution: 0.1, unit: "L"},
            evapMetric: { label: "Evap Metric", identifier: "evap-avg-m", color: "rgb(213,255,0)", yAxis: "g/m2/s", selected: true, defaultSelected: true, resolution: 0.0001, unit: "g/m2/s"},
            evapImperial: { label: "Evap Imperial", identifier: "evap-avg-i", color: "rgb(70,130,180)", yAxis: "lbs/sqft/h", selected: true, defaultSelected: true, resolution: 0.0001, unit: "lbs/sqft/h"},
        }},
        dosing: { label: "Dosing", selected: false, dataTypes: {
            water: {label: "Water (Total)", identifier: "water", color: "rgb(0,19,56)", yAxis: "millilitres", selected: true, defaultSelected: true, resolution: 0.1, unit: "mL"},
            nutrients_total: {label: "Nutrients (Total)", identifier: "nutrients_total", color: "rgb(0, 0, 0)", yAxis: "litres", selected: true, defaultSelected: true, resolution: 0.1, unit: "L"},
            nutrients: {label: "Nutrient Doses", identifier: "nutrients", color: "rgb(0, 0, 0)", yAxis: "millilitres", selected: true, defaultSelected: true, resolution: 0.1, unit: "mL"},
        }},
        energy: {label: "Energy", selected: false,dataTypes: {
            power: {label: "Power", identifier: "power", isEnergyData: true, color: "rgb(21,120,90)", yAxis: "kilowatt", selected: true, defaultSelected: true, resolution: 0.5, unit: "kW"},
            //cost: {label: "Cost", identifier: "cost", color: "rgb(21,120,90)", yAxis: "cost", selected: false,defaultSelected: true},
            current: {label: "Current",identifier: "cur", color: "rgb(21,120,90)", yAxis: "current", selected: false,defaultSelected: true},
        }}
    });


    const dataTypeGroupToggled = React.useCallback((dataTypeGroupName, selected) => {
        dataTypeToggles[dataTypeGroupName].selected = selected
        SetDataTypeToggles({...dataTypeToggles})
    })
    const dataTypeToggled = React.useCallback((dataType, selected) => {
        dataType.selected = selected
        SetDataTypeToggles({...dataTypeToggles})
    })
    const energySubselectionToggled = React.useCallback(() =>   {
        SetEnergySubselectionActive(!energySubselectionActive)
        SetDataTypeToggles({...dataTypeToggles})
    })
    
    
    let grows = useSelector(selectAllGrows)
    let nutrientSolutions = useSelector(selectAllNutrientSolutions)

    const haveAppInfo = useSelector((state) => state.appInfo.haveAppInfo)
    const dataRecordingTimePeriodTypes = useSelector(selectAllDataRecordingTimePeriodTypes)
    const chartAxisTypes = useSelector(selectAllChartAxisTypes)
    const dispatch = useDispatch()

    React.useEffect( () =>  { 
        dispatch(getAllGrows())
    }, [])



    const getMainDataRecordingTimePeriodType = () =>    {
        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        const timeDelta = mainChartVisibleRange.end - mainChartVisibleRange.start
        
        let lastDataRecordingTimePeriodType = null
        for (let dataRecordingTimePeriodType of dataRecordingTimePeriodTypes)   {
            if (lastDataRecordingTimePeriodType === null)   {
                if (timeDelta < dataRecordingTimePeriodType.duration * 1000)   {
                    return dataRecordingTimePeriodType
                }
            }else {
                if (timeDelta > lastDataRecordingTimePeriodType.duration * 1000 && timeDelta < dataRecordingTimePeriodType.duration * 1000)   {
                    return dataRecordingTimePeriodType
                }
            }
            lastDataRecordingTimePeriodType = dataRecordingTimePeriodType
        }
        return lastDataRecordingTimePeriodType
    }


    const chartLoop = () =>  {
        if (!chartRef.current) 
            return

        const usingSingleDataset = false

        chartRef.current.totalGrowDuration = 1000 * 60 * 60 * 24 //default to one day

        let activeDataSeriesUpdated = false

        if (haveAppInfo)   {
            let overviewDataRecordingTimePeriodType = dataRecordingTimePeriodTypes[dataRecordingTimePeriodTypes.length - 1]

            
            const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
            const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()
            const mainDataRecordingTimePeriodType = getMainDataRecordingTimePeriodType()

            const [mainChartDeltaType, mainChartMinorDelta, mainChartMajorDelta] = getTimeDeltaType(mainChartVisibleRange.end - mainChartVisibleRange.start)

            //Make sure all y axes are properly loaded in
            //selectChartAxisTypeByIdentifier
            let currentRequiredYAxes = []
            for (const dataTypeGroupIdentifier in dataTypeToggles) {
                const dataTypeGroup = dataTypeToggles[dataTypeGroupIdentifier]
                if (dataTypeGroup.selected) {
                    for (const dataType in dataTypeGroup.dataTypes) {
                        const dataTypeInfo = dataTypeGroup.dataTypes[dataType]
                        if (dataTypeInfo.yAxis !== undefined && dataTypeInfo.selected && currentRequiredYAxes.indexOf(dataTypeInfo.yAxis) === -1)    {
                            currentRequiredYAxes.push(dataTypeInfo.yAxis)
                        }
                    }
                }
            }
            for (const yAxisIdentifier of currentRequiredYAxes) {
                if (chartRef.current.activeYAxes[yAxisIdentifier] === undefined)    {
                    //YAxis is missing, lets create it
                    const yAxisInfo = chartAxisTypes.find(type => type.identifier === yAxisIdentifier)
                    if (yAxisInfo !== undefined)    {
                        chartRef.current.activeYAxes[yAxisIdentifier] = {}
                        chartRef.current.activeYAxes[yAxisIdentifier].main = chartRef.current.mainChart.addAxisY()
                        chartRef.current.activeYAxes[yAxisIdentifier].main.setInterval(yAxisInfo.min , yAxisInfo.max, false, false)
                            .setMouseInteractions(false)
                            .setTickStrategy(AxisTickStrategies.Empty)
                            .setScrollStrategy(undefined)
                            .setStrokeStyle(emptyLine)
                            .onScaleChange((start, end) => {
                                let min = (chartRef.current.tempYAxisInterval[yAxisIdentifier] !== undefined) ? chartRef.current.tempYAxisInterval[yAxisIdentifier].min : yAxisInfo.min
                                let max = (chartRef.current.tempYAxisInterval[yAxisIdentifier] !== undefined) ? chartRef.current.tempYAxisInterval[yAxisIdentifier].max : yAxisInfo.max

                                if (start !== min || end !== max)  {
                                    chartRef.current.activeYAxes[yAxisIdentifier].main.setInterval(min, max, false, false)
                                }
                            })
                        chartRef.current.activeYAxes[yAxisIdentifier].overview = chartRef.current.overviewChart.addAxisY()
                        chartRef.current.activeYAxes[yAxisIdentifier].overview.setInterval(yAxisInfo.min , yAxisInfo.max, false, false)
                            .setMouseInteractions(false)
                            .setTickStrategy(AxisTickStrategies.Empty)
                            .setScrollStrategy(undefined)
                            .setStrokeStyle(emptyLine)
                            .onScaleChange((start, end) => {
                                if (start !== yAxisInfo.min || end !== yAxisInfo.max)  {
                                    chartRef.current.activeYAxes[yAxisIdentifier].overview.setInterval(yAxisInfo.min, yAxisInfo.max, false, false)
                                }
                            })
                    }
                }
            }
            for (const yAxisIdentifier in chartRef.current.activeYAxes) {
                //Check that we require all of the ones here
                if (currentRequiredYAxes.indexOf(yAxisIdentifier) === -1)   {
                    //We need to remove this one
                    chartRef.current.activeYAxes[yAxisIdentifier].main.dispose()
                    chartRef.current.activeYAxes[yAxisIdentifier].overview.dispose()
                    delete  chartRef.current.activeYAxes[yAxisIdentifier]
                }
            }



            let activeGrows = chartRef.current.grows.filter((g) => g.selected)
            let inactiveGrows = chartRef.current.grows.filter((g) => !g.selected)
            let activeGrowsChanged = false
            for (let grow of activeGrows)   {
                if (chartRef.current.lastSelectedGrows.indexOf(grow.id) === -1) {
                    chartRef.current.lastSelectedGrows.push(grow.id)
                    activeGrowsChanged = true
                }
                if (grow.duration * 1000 > chartRef.current.totalGrowDuration)  {
                    chartRef.current.totalGrowDuration = grow.duration * 1000
                }

            }
            for (let grow of inactiveGrows)   {
                let gIndex = chartRef.current.lastSelectedGrows.indexOf(grow.id)
                if (gIndex !== -1) {
                    chartRef.current.lastSelectedGrows.splice(gIndex, 1)
                    activeGrowsChanged = true
                }
            }
            
            const minDosingVolume = 50
            chartRef.current.nutrientDosingLargestVolume = minDosingVolume

            for (let growIndex = 0; growIndex < activeGrows.length; growIndex++) {
                let grow = activeGrows[growIndex]
                let growStartedOn = new Date(grow.started_on).getTime()
               

                if (chartRef.current.dataSeries[grow.id] === undefined)    {
                    chartRef.current.dataSeries[grow.id] = {}
                    //activeDataSeriesUpdated = true
                }

                

                for (const dataTypeGroupIdentifier in dataTypeToggles) {
                    const dataTypeGroup = dataTypeToggles[dataTypeGroupIdentifier]


                    for (const dataType in dataTypeGroup.dataTypes) {
                        const dataTypeInfo = dataTypeGroup.dataTypes[dataType]
                        let identifier = dataTypeInfo.identifier

                        if (dataTypeGroup.selected && dataTypeInfo.selected)   {
                            if (chartRef.current.activeYAxes[dataTypeInfo.yAxis] !== undefined) {
                                
                                const lineColor = dataTypeInfo.color.replace("rgb(", '').replace(")", '').split(',')
                                // Validate that this series is added
                                if (chartRef.current.dataSeries[grow.id][identifier] === undefined)    {
                                    
                                    if (dataTypeGroupIdentifier === "dosing" && dataType === "nutrients")   {
                                        chartRef.current.dataSeries[grow.id][identifier] = { 
                                            main: chartRef.current.mainChart.addRectangleSeries({
                                                dataPattern: {pattern: 'ProgressiveX', regularProgressiveStep: false},
                                                yAxis: chartRef.current.activeYAxes[dataTypeInfo.yAxis].main
                                                }).setMouseInteractions(false),
                                            overview: chartRef.current.overviewChart.addRectangleSeries({
                                                dataPattern: {pattern: 'ProgressiveX', regularProgressiveStep: false},
                                                yAxis: chartRef.current.activeYAxes[dataTypeInfo.yAxis].overview
                                                }).setMouseInteractions(false),
                                            mainVersion: -1,
                                            overviewVersion: -1,
                                            changedVersion: -1,
                                            lastLiveUpdateOn: new Date()
                                        }
                                        chartRef.current.dataSeries[grow.id][identifier].loadedIds = []
                                        chartRef.current.dataSeries[grow.id][identifier].groupedInstances = []
                                        chartRef.current.dataSeries[grow.id][identifier].loadedGroupedInstances = false
                                        chartRef.current.dataSeries[grow.id][identifier].incompleteInjectEvent = null
                                        chartRef.current.dataSeries[grow.id][identifier].dosingInstanceBars = []
                                    }else {
                                        chartRef.current.dataSeries[grow.id][identifier] = { 
                                            main: chartRef.current.mainChart.addLineSeries({
                                                dataPattern: {pattern: 'ProgressiveX', regularProgressiveStep: false},
                                                yAxis: chartRef.current.activeYAxes[dataTypeInfo.yAxis].main
                                                }).setStrokeStyle(new SolidLine({ thickness: 0.5, fillStyle: new SolidFill({color: ColorRGBA(...lineColor)})}))
                                                //}).setStrokeStyle(new SolidLine({ thickness: 0.5, color: ColorRGBA(lineColor)}))
                                                .setMouseInteractions(false),
                                            overview: chartRef.current.overviewChart.addLineSeries({
                                                dataPattern: {pattern: 'ProgressiveX', regularProgressiveStep: false},
                                                yAxis: chartRef.current.activeYAxes[dataTypeInfo.yAxis].overview
                                                }).setStrokeStyle(new SolidLine({ thickness: 0.5, fillStyle: new SolidFill({color: ColorRGBA(...lineColor)})}))
                                                .setMouseInteractions(false),
                                            mainVersion: -1,
                                            overviewVersion: -1,
                                            changedVersion: -1,
                                            lastLiveUpdateOn: new Date()
                                        }                           
                                    }
                                }

                                

                                // Make sure all data is reporting
                                if (dataTypeGroupIdentifier === "dosing" && dataType === "nutrients")   {
                                    if (chartRef.current.lastMainChartDeltaType != mainChartDeltaType || activeGrowsChanged || chartRef.current.lastTotalGrowDuration != chartRef.current.totalGrowDuration) {
                                        chartRef.current.nutrientDosingBarsRequiresReset = false
                                        delete chartRef.current.tempYAxisInterval[dataTypeInfo.yAxis]
                                        const yAxisInfo = chartAxisTypes.find(type => type.identifier === dataTypeInfo.yAxis)
                                        if (yAxisInfo !== undefined)    {
                                            chartRef.current.activeYAxes[dataTypeInfo.yAxis].main.setInterval(yAxisInfo.min , yAxisInfo.max, false, false)
                                        }
                                        chartRef.current.dataSeries[grow.id][identifier].main.clear()
                                        chartRef.current.dataSeries[grow.id][identifier].loadedIds = []
                                        chartRef.current.dataSeries[grow.id][identifier].groupedInstances = []
                                        chartRef.current.dataSeries[grow.id][identifier].loadedGroupedInstances = false
                                        chartRef.current.dataSeries[grow.id][identifier].incompleteInjectEvent = null
                                        chartRef.current.dataSeries[grow.id][identifier].dosingInstanceBars = []
                                    }

                                    if ((mainChartVisibleRange.end - mainChartVisibleRange.start) <= 1000 * 60 * 60 * 24 ) {
                                        for (let dosingInstance of grow.dosingInstances.filter((d) => {
                                            if (chartRef.current.dataSeries[grow.id][identifier].loadedIds.indexOf(d.startedEvent.id) === -1)
                                                return d
                                        }))    {
                                            let toTime = (dosingInstance.completedEvent !== null) ? (dosingInstance.completedEvent.occurred_on - growStartedOn) : new Date().getTime() - growStartedOn
                                            //let loadedDoseItemIds = []
                                            let currentVolume = 0
                                            let barWidth = 1000 * 60 * 5//toTime - (dosingInstance.startedEvent.occurred_on - growStartedOn)
                                            if (dosingInstance.completedEvent !== null)    {
                                                for (let dosingItem of Object.values(dosingInstance.dosingItems)) {
                                                    ///console.log(dosingItem)
                                                    chartRef.current.dataSeries[grow.id][identifier].main.add({
                                                        x: (dosingInstance.startedEvent.occurred_on - growStartedOn),
                                                        y: currentVolume,
                                                        width: barWidth,
                                                        height: dosingItem.volume
                                                    }).setFillStyle(new SolidFill({color: ColorRGBA(...dosingItem.color)}))
                                                    .setStrokeStyle(emptyLine)

                                                    currentVolume += dosingItem.volume
                                                    //loadedDoseItemIds.push(dosingItem.id)
                                                }

                                                let barDimensions = {
                                                    x: (dosingInstance.startedEvent.occurred_on - growStartedOn),
                                                    y: 0,
                                                    width: barWidth,
                                                    height: currentVolume
                                                }
                                                let barBorder = chartRef.current.dataSeries[grow.id][identifier].main.add(barDimensions).setFillStyle(emptyFill)
                                                    .setStrokeStyle(emptyLine)    
                                                chartRef.current.dataSeries[grow.id][identifier].dosingInstanceBars.push({
                                                    bar: barBorder,
                                                    dimensions: barDimensions,
                                                    dosingInstance: dosingInstance,
                                                    growId: grow.id
                                                })
                                            }
                                            ///console.log(chartRef.current.dataSeries[grow.id][identifier].main)
                                            chartRef.current.dataSeries[grow.id][identifier].loadedIds.push(dosingInstance.startedEvent.id)
                                        }
                                    }else {

                                        
                                        
                                        if (!chartRef.current.dataSeries[grow.id][identifier].loadedGroupedInstances)  {
                                            let instanceTime = 0
                                            while (instanceTime < chartRef.current.totalGrowDuration)    {
                                                chartRef.current.dataSeries[grow.id][identifier].groupedInstances.push({
                                                    fromTime: instanceTime,
                                                    toTime: instanceTime + mainChartMinorDelta,
                                                    dosingItems: [],
                                                    loadedIds: []
                                                })
                                                instanceTime += mainChartMinorDelta
                                            }
                                            chartRef.current.dataSeries[grow.id][identifier].loadedGroupedInstances = true

                                        }


                                        let dosingInstanceChanged = false

                                        for (let dosingInstance of grow.dosingInstances)    {
                                            //Find the time associated with this dosingInstance
                                            for (let instanceTimeSpan of chartRef.current.dataSeries[grow.id][identifier].groupedInstances) {
                                                if ((dosingInstance.startedEvent.occurred_on - growStartedOn) >= instanceTimeSpan.fromTime && (dosingInstance.startedEvent.occurred_on - growStartedOn) <= instanceTimeSpan.toTime)   {
                                                    if (instanceTimeSpan.loadedIds.indexOf(dosingInstance.startedEvent.id) === -1) {
                                                        dosingInstanceChanged = true

                                                        for (let dosingItem of Object.values(dosingInstance.dosingItems))  {
                                                            let foundDosingItem = null;
                                                            for (let currentDosingItem of instanceTimeSpan.dosingItems) {
                                                                if (currentDosingItem.type == dosingItem.type && currentDosingItem.ref_id == dosingItem.ref_id) {
                                                                    foundDosingItem = currentDosingItem
                                                                    break
                                                                }
                                                            }

                                                            if (foundDosingItem === null)   {
                                                                instanceTimeSpan.dosingItems.push({
                                                                    type: dosingItem.type,
                                                                    ref_id: dosingItem.ref_id,
                                                                    volume: dosingItem.volume,
                                                                    totalVolume: dosingItem.totalVolume,
                                                                    color: dosingItem.color
                                                                })
                                                            }else {
                                                                foundDosingItem.volume += dosingItem.volume
                                                                if (foundDosingItem.totalVolume < dosingItem.totalVolume)   {
                                                                    foundDosingItem.totalVolume = dosingItem.totalVolume
                                                                }
                                                            }
                                                        }

                                                        
                                                        instanceTimeSpan.loadedIds.push(dosingInstance.startedEvent.id)
                                                    }
                                                }

                                                
                                            }
                                        }

                                        if (dosingInstanceChanged)  {
                                            const dosingItemWidth = mainChartMinorDelta / 10
                                            const dosingItemSpace = mainChartMinorDelta / 20
                                            const totalDosingItemSpace = (dosingItemWidth * activeGrows.length) + (dosingItemSpace * (activeGrows.length - 1))

                                            chartRef.current.dataSeries[grow.id][identifier].main.clear()
                                            for (let instanceTimeSpan of chartRef.current.dataSeries[grow.id][identifier].groupedInstances) {
                                                let currentVolume = 0
                                                
                                                let dosingItemX = instanceTimeSpan.fromTime + (mainChartMinorDelta / 2) - (totalDosingItemSpace / 2) + (growIndex * (dosingItemWidth + dosingItemSpace))
                                                for (let dosingItem of instanceTimeSpan.dosingItems) {

                                                    ///console.log(dosingItem)
                                                    chartRef.current.dataSeries[grow.id][identifier].main.add({
                                                        x: dosingItemX,
                                                        y: currentVolume,
                                                        width: dosingItemWidth,
                                                        height: dosingItem.volume
                                                    }).setFillStyle(new SolidFill({color: ColorRGBA(...dosingItem.color)}))
                                                    .setStrokeStyle(emptyLine)
                                                    currentVolume += dosingItem.volume


                                                }

                                                

                                                let barDimensions = {
                                                    x: dosingItemX,
                                                    y: 0,
                                                    width: dosingItemWidth,
                                                    height: currentVolume
                                                }
                                                let barBorder = chartRef.current.dataSeries[grow.id][identifier].main.add(barDimensions).setFillStyle(emptyFill)
                                                    .setStrokeStyle(emptyLine)    
                                                chartRef.current.dataSeries[grow.id][identifier].dosingInstanceBars.push({
                                                    bar: barBorder,
                                                    dimensions: barDimensions,
                                                    groupedInstance: instanceTimeSpan,
                                                    growId: grow.id
                                                })

                                                if (currentVolume > chartRef.current.nutrientDosingLargestVolume)  {
                                                    chartRef.current.nutrientDosingLargestVolume = currentVolume
                                                }
                                            }

                                            chartRef.current.tempYAxisInterval[dataTypeInfo.yAxis] = {min: 0, max: chartRef.current.nutrientDosingLargestVolume * 2}
                                            chartRef.current.activeYAxes[dataTypeInfo.yAxis].main.setInterval(0, chartRef.current.nutrientDosingLargestVolume * 2)

                                        }



                                    }

                                }else if (dataTypeGroupIdentifier === "dosing" && dataType === "nutrients_total") {
                                    if (grow.analyticsData.nutrientsTotalVersion > chartRef.current.dataSeries[grow.id][identifier].mainVersion || (!grow.completed && chartRef.current.dataSeries[grow.id][identifier].lastLiveUpdateOn.getTime() + 1000 < new Date().getTime()))  {
                                        // /nutrients_total
                                        chartRef.current.dataSeries[grow.id][identifier].mainVersion = grow.analyticsData.nutrientsTotalVersion

                                        let endDate = (grow.completed ? new Date(grow.finished_on) : new Date())
                                        let newData = []
                                        if (grow.analyticsData.nutrientsTotalData.length > 0)  {
                                            newData = [...grow.analyticsData.nutrientsTotalData, {
                                                x: (endDate.getTime() - growStartedOn),
                                                y: grow.analyticsData.nutrientsTotalData[grow.analyticsData.nutrientsTotalData.length - 1].y
                                            }]
                                        }

                                        chartRef.current.dataSeries[grow.id][identifier].main.clear().add(newData)
                                        chartRef.current.dataSeries[grow.id][identifier].lastLiveUpdateOn = new Date()

                                    }
                                }else {
                                    if (grow.analyticsData.timePeriods !== undefined)   {
                                        if (dataTypeInfo.isEnergyData !== true) {
                                            if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index] !== undefined)    {
                                                if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[identifier] !== undefined)    {
                                                    
                                                    if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].changedVersion > chartRef.current.dataSeries[grow.id][identifier].mainVersion || chartRef.current.lastMainDataRecordingTimePeriodType != mainDataRecordingTimePeriodType || (!grow.completed && chartRef.current.dataSeries[grow.id][identifier].lastLiveUpdateOn.getTime() + 1000 < new Date().getTime()))  {
                                                        chartRef.current.dataSeries[grow.id][identifier].mainVersion = grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].changedVersion
                                                        
                                                        let endDate = (grow.completed ? new Date(grow.finished_on) : new Date())
                                                        let newData = []
                                                        if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[identifier].length > 0)  {
                                                            newData = [...grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[identifier], {
                                                                x: (endDate.getTime() - growStartedOn),
                                                                y: grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[identifier][grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[identifier].length - 1].y
                                                            }]
                                                        }
                                                        chartRef.current.dataSeries[grow.id][identifier].main.clear().add(newData)

                                                        chartRef.current.dataSeries[grow.id][identifier].lastLiveUpdateOn = new Date()
                                                    }

                                                    
                                                }
                                            }
                                        
                                            if (grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index] !== undefined)    {
                                                if (grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index].data[identifier] !== undefined)    {
                                                    if (grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index].changedVersion > chartRef.current.dataSeries[grow.id][identifier].overviewVersion)  {
                                                        chartRef.current.dataSeries[grow.id][identifier].overviewVersion = grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index].changedVersion                                                            
                                                        chartRef.current.dataSeries[grow.id][identifier].overview.clear().add(grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index].data[identifier]);                             
                                                    }                             
                                                }
                                            }


                                        }else {
                                    
                                            if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index] !== undefined)    {
                                                if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData[identifier] !== undefined)    {
                                                    if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].changedEnergyVersion > chartRef.current.dataSeries[grow.id][identifier].mainVersion || chartRef.current.lastMainDataRecordingTimePeriodType != mainDataRecordingTimePeriodType)  {
                                                        chartRef.current.dataSeries[grow.id][identifier].mainVersion = grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].changedEnergyVersion
                                                        chartRef.current.dataSeries[grow.id][identifier].main.clear().add(grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData[identifier]);   
                                                    }
                                                }
                                            }
                                            if (grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index] !== undefined)    {
                                                if (grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index].energyData[identifier] !== undefined)    {
                                                    if (grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index].changedEnergyVersion > chartRef.current.dataSeries[grow.id][identifier].overviewVersion)  {
                                                        chartRef.current.dataSeries[grow.id][identifier].overviewVersion = grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index].changedEnergyVersion
                                                        chartRef.current.dataSeries[grow.id][identifier].overview.clear().add(grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index].energyData[identifier]);                             
                                                    }                             
                                                }
                                            }
                                        }
                                    }
                                }
                            }




                        }else   {
                            // Validate that this series is removed
                            if (chartRef.current.dataSeries[grow.id][identifier] !== undefined)    {
                                chartRef.current.dataSeries[grow.id][identifier].main.dispose()
                                chartRef.current.dataSeries[grow.id][identifier].overview.dispose()
                                delete chartRef.current.dataSeries[grow.id][identifier]
                            }
                        }
                    }
                }


                //lastEnergySubSelection
                //energySubSelection: defaultMainChartInterval,
                //energyTotals
                let energyNeedsUpdating = true
                let energyRange = {start: 0, end : 0}
                if (chartRef.current.energySubselectionActive)   {
                    energyRange.start = chartRef.current.energySubSelection.start
                    energyRange.end = chartRef.current.energySubSelection.end
                }else {
                    energyRange.start = mainChartVisibleRange.start
                    energyRange.end = mainChartVisibleRange.end
                }

                if (chartRef.current.energySubSelection.start != chartRef.current.lastEnergySubSelection.start || chartRef.current.energySubSelection.end != chartRef.current.lastEnergySubSelection.end)   {


                }
                if (energyNeedsUpdating)    {
                    chartRef.current.energyTotals[grow.id] = {power: 0, cost: 0}
                    
                    if (usingSingleDataset) {
                        if (grow.analyticsData.energyDataTypes !== undefined) {
                            if (grow.analyticsData.energyDataTypes["power"] !== undefined) {
                                //.data has the current data
                                for (let dataItem of grow.analyticsData.energyDataTypes["power"].data)  {
                                    //console.log(grow.analyticsData.energyDataTypes["power"].data)
                                }
                            }
                        }
                    }else {
                        if (grow.analyticsData.timePeriods !== undefined)   {
                            if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index] !== undefined)    {


                                if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData["power"] !== undefined)    {
                                    let lastDataItem = null;
                                    for (let dataItem of grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData["power"])  {
                                        if (lastDataItem != null)   {
                                            if (dataItem.x >= energyRange.start && dataItem.x <= energyRange.end)   {
                                                const timeAmount = (dataItem.x - lastDataItem.x) / 1000
                                                if (timeAmount != 0 && dataItem.y != 0)    {
                                                    chartRef.current.energyTotals[grow.id].power += dataItem.y / (3600 / timeAmount)
                                                }
                                            }
                                        }
                                        lastDataItem = dataItem
                                    }
                                }
                                if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData["cost"] !== undefined)    {
                                    for (let dataItem of grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData["cost"])  {
                                        
                                        if (dataItem.x >= energyRange.start && dataItem.x <= energyRange.end)   {
                                            chartRef.current.energyTotals[grow.id].cost += dataItem.y / 100
                                        }
                                    }
                                }
                            }
                        }                               
                    }
                }

            }
            for (let grow of inactiveGrows) {
                delete chartRef.current.energyTotals[grow.id]
                //Make sure this grow doesn't exist in active data series
                if (chartRef.current.dataSeries[grow.id] !== undefined)    {
                    for (let identifier in chartRef.current.dataSeries[grow.id])    {
                        if (identifier === "nutrients") {
                            chartRef.current.dataSeries[grow.id][identifier].main.clear()
                        }
                        chartRef.current.dataSeries[grow.id][identifier].main.dispose()
                        chartRef.current.dataSeries[grow.id][identifier].overview.dispose()
                        delete chartRef.current.dataSeries[grow.id][identifier]
                    }
                    delete chartRef.current.dataSeries[grow.id]
                }
            }
            chartRef.current.lastMainDataRecordingTimePeriodType = mainDataRecordingTimePeriodType
            chartRef.current.lastMainChartDeltaType = mainChartDeltaType

                
            if (overviewChartVisibleRange.end != chartRef.current.totalGrowDuration) {
                chartRef.current.overviewChartDateAxis.setInterval(0, chartRef.current.totalGrowDuration, false, false)
                if (mainChartVisibleRange.end >= chartRef.current.totalGrowDuration)    {
                    chartRef.current.mainChartDateAxis.setInterval(0, chartRef.current.totalGrowDuration, false, false)
                }
            }

            chartRef.current.lastTotalGrowDuration = chartRef.current.totalGrowDuration
        }



        

        //Handle current position of energy subselection 


        chartRef.current.lastEnergySubSelection.start = chartRef.current.energySubSelection.start
        chartRef.current.lastEnergySubSelection.end = chartRef.current.energySubSelection.end
        
        if (energyDataTypeToggleDataUpdate.current !== undefined)   {
            energyDataTypeToggleDataUpdate.current(chartRef.current.energyTotals)
        }


        chartRef.current.lastRenderLoopCompletedOn = new Date().getTime()
    }


    const requestLoop = React.useCallback(() =>  {
        if (!chartRef.current) 
            return


        if (haveAppInfo)   {
            let grows = chartRef.current.grows
            for (let grow of grows) {
                if (grow.selected)  {
                    let growDataRequest = {}
                    if (grow.initializedDataRecordingTimePeriodTypes === false) {
                        //Validate that the data type has been initialzed for this grow
                        dispatch(initializeDataRecordingTimePeriodTypes({growId: grow.id, dataRecordingTimePeriodTypes: dataRecordingTimePeriodTypes}))

                    }else {
                        let overviewDataRecordingTimePeriodType = dataRecordingTimePeriodTypes[dataRecordingTimePeriodTypes.length - 1]

                        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
                        let mainDataRecordingTimePeriodType = dataRecordingTimePeriodTypes[dataRecordingTimePeriodTypes.length - 1]
                        for (let dataRecordingTimePeriodType of dataRecordingTimePeriodTypes)   {
                            if (mainChartVisibleRange.end - mainChartVisibleRange.start < dataRecordingTimePeriodType.duration * 1000) {
                                mainDataRecordingTimePeriodType = dataRecordingTimePeriodType
                                break
                            }
                        }

                        let growStartedOn = new Date(grow.started_on).getTime()

                        //Check that we have the overview data for this grow
                        let overviewDataPeriod = {from: growStartedOn, to: growStartedOn + (grow.duration * 1000)}
                        let overviewData = grow.analyticsData.timePeriods[overviewDataRecordingTimePeriodType.index]
                        
                        //Check that we are not currently loading data
                        if (overviewData.loadingStatus == "idle" || overviewData.loadingStatus == "fulfilled")  {

                            let overviewFromEntryIndex = Math.floor((growStartedOn / 1000) / (overviewDataRecordingTimePeriodType.duration))
                            let overviewToEntryIndex = Math.floor(((growStartedOn / 1000) + grow.duration) / (overviewDataRecordingTimePeriodType.duration))
                            
                            //Check out of these entries which ones we need
                            let requestEntries = {}
                            for (let entryIndex = overviewFromEntryIndex; entryIndex <= overviewToEntryIndex; entryIndex++)    {
                                if (overviewData.dataChunks[entryIndex] !== undefined)  {
                                    if (!overviewData.dataChunks[entryIndex].completed) {
                                        //Lets build a request for the portion of data we don't have
                                        let requestRange = {"from": growStartedOn + overviewData.haveDataUpUntil + 1, "to": (entryIndex + 1) * overviewDataRecordingTimePeriodType.duration * 1000}
                                        if (requestRange["to"] - requestRange["from"] > 1000)   { //minimum request range
                                            requestEntries[entryIndex] = requestRange
                                        }
                                    }
                                }else {
                                    requestEntries[entryIndex] = {}
                                }
                            }


                            //For now just get data
                            if (Object.keys(requestEntries).length)  {
                                dispatch(getGrowAnalyticsData({growId: grow.id, dataTimePeriods: {
                                    [overviewDataRecordingTimePeriodType.index]: [overviewDataPeriod]
                                }, entries: {
                                    [overviewDataRecordingTimePeriodType.index]: requestEntries
                                }, timePeriodTypes: dataRecordingTimePeriodTypes}))
                            }

                        }


                        //mainChartDataRecordingTimePeriodType
                        //Check that we have the main data for this grow
                        if (overviewDataRecordingTimePeriodType != mainDataRecordingTimePeriodType) {

                            let mainDataPeriod = {from: growStartedOn + mainChartVisibleRange.start, to: growStartedOn + mainChartVisibleRange.end}
                            let mainData = grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index]
                            
                            //Check that we are not currently loading data
                            if (mainData.loadingStatus == "idle" || mainData.loadingStatus == "fulfilled")  {
                                let startWindow = Math.floor(growStartedOn + mainChartVisibleRange.start)
                                const now = new Date()
                                const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000)  
                                const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000)    
                                if (startWindow < now.getTime())  {
                                    let endWindow = Math.floor(growStartedOn + mainChartVisibleRange.end)
                                    if (endWindow > now.getTime())   {
                                        endWindow = now.getTime()
                                    }

                                    let mainFromEntryIndex = Math.floor(startWindow / (mainDataRecordingTimePeriodType.duration * 1000))
                                    let mainToEntryIndex = Math.floor(endWindow / (mainDataRecordingTimePeriodType.duration * 1000))
                                    
                                    //Check out of these entries which ones we need
                                    let requestEntries = {}
                                    for (let entryIndex = mainFromEntryIndex; entryIndex <= mainToEntryIndex; entryIndex++)    {
                                        if (mainData.dataChunks[entryIndex] !== undefined)  {
                                            if (!mainData.dataChunks[entryIndex].completed) {
                                                let chunkStartedOn = entryIndex * mainDataRecordingTimePeriodType.duration * 1000
                                                //Lets build a request for the portion of data we don't have
                                                let requestRange = {"from": growStartedOn + mainData.haveDataUpUntil + 1 , "to": endWindow}
                                                if (requestRange["to"] - requestRange["from"] > 1000)   { //minimum request range
                                                    requestEntries[entryIndex] = requestRange
                                                }
                                            }
                                        }else {
                                            requestEntries[entryIndex] = {}
                                        }
                                    }


                                    //For now just get data
                                    if (Object.keys(requestEntries).length)  {
                                        //console.log("Dispatching request for: ", requestEntries, mainData)
                                        dispatch(getGrowAnalyticsData({growId: grow.id, dataTimePeriods: {
                                            [mainDataRecordingTimePeriodType.index]: [mainDataPeriod]
                                        }, entries: {
                                            [mainDataRecordingTimePeriodType.index]: requestEntries
                                        }, timePeriodTypes: dataRecordingTimePeriodTypes}))
                                    }
                                }
                            }
                        }

                        //Determine the time range to request dosing events for
                        dispatch(getGrowDosingHistory({growId: grow.id, fromTime: grow.haveDosingEventsUpUntil, toTime: new Date(grow.started_on).getTime() + 1000 * 60 * 60 * 24 * 30}))

                        let nutrientSolutionsRequests = []
                        for (let dosingItem of Object.values(grow.dosingItems))    {
                            if (dosingItem.type === "nutrient_solution")    {
                                if (dosingItem.nutrientSolution === undefined)  {
                                    const nutrientSolution = nutrientSolutions.find((n) => n.id === parseInt(dosingItem.ref_id))
                                    if (nutrientSolution === undefined) {
                                        if (nutrientSolutionsRequests.indexOf(dosingItem.ref_id) === -1) {
                                            nutrientSolutionsRequests.push(parseInt(dosingItem.ref_id))
                                        }
                                    }else {
                                        //dosingItem.nutrientSolution = nutrientSolution
                                    }
                                    //const nutrientSolution = useSelector(state => selectNutrientSolutionById(state, dosingItem.ref_id))
                                }
                            }
                        }
                        
                        if (nutrientSolutionsRequests.length === 1) {
                            dispatch(getNutrientSolutionById({nutrientSolutionId: nutrientSolutionsRequests[0]}))
                        }else if (nutrientSolutionsRequests.length > 1) {
                            dispatch(getNutrientSolutionById({nutrientSolutions: nutrientSolutionsRequests}))
                        }
                    }
                }
            }
        }

        chartRef.current.lastRequestLoopCompletedOn = new Date().getTime()
    })

    React.useEffect(() => {
        const chartInterval = setInterval(() => {
            if (chartRef.current.lastRenderLoopCompletedOn + 50 < new Date().getTime()) {
                chartLoop()
            }
        }, 1);
        //chartLoop()

        const requestInterval = setInterval(() => {
            if (chartRef.current.lastRequestLoopCompletedOn + 100 < new Date().getTime()) {
                requestLoop()
            }
        }, 1);


        //requestLoop()
        

        return () => {
            clearInterval(chartInterval)
            clearInterval(requestInterval)
        };
    }, [haveAppInfo, activeDataSeries, chartRef, nutrientSolutions]);

    


  



    

    

 
    let processingMainChartInterval = false
    let processingOverviewChartInterval = false

    const getTimeDeltaType = (timeDelta) => {
        if (timeDelta <= 1000 * 60 * 1)
            return ["minute", 1000 * 5, 1000 * 60]
        if (timeDelta <= 1000 * 60 * 5)
            return ["minute5", 1000 * 10, 1000 * 60]
        if (timeDelta <= 1000 * 60 * 10)
            return ["minute10", 1000 * 30, 1000 * 60 * 5]
        if (timeDelta <= 1000 * 60 * 30)
            return ["minute30", 1000 * 60, 1000 * 60 * 60]
        if (timeDelta <= 1000 * 60 * 60)
            return ["hour", 1000 * 60 * 5, 1000 * 60 * 60]
        if (timeDelta <= 1000 * 60 * 60 * 3)
            return ["hour3", 1000 * 60 * 15, 1000 * 60 * 60]
        if (timeDelta <= 1000 * 60 * 60 * 6)
            return ["hour6", 1000 * 60 * 60, 1000 * 60 * 60 * 24]
        if (timeDelta <= 1000 * 60 * 60 * 12)
            return ["hour12", 1000 * 60 * 60, 1000 * 60 * 60 * 24]
        if (timeDelta <= 1000 * 60 * 60 * 24)
            return ["day", 1000 * 60 * 60 * 3, 1000 * 60 * 60 * 24]
        if (timeDelta <= 1000 * 60 * 60 * 24 * 7)
            return ["week", 1000 * 60 * 60 * 6, 1000 * 60 * 60 * 24]

        return ["month", 1000 * 60 * 60 * 24, 1000 * 60 * 60 * 24 * 7]
    }

    const createTicksInRangeX = (axis, tickList, timeDelta, start, end) => {

        //let minorTickInterval = 1000 * 60 * 60 * 24 //every day
        //let majorTickInterval = 1000 * 60 * 60 * 24 * 7 //every week

        let minorTickFormat = '~D'
        let majorTickFormat = '~D'

        const [deltaType, minorTickInterval, majorTickInterval] = getTimeDeltaType(timeDelta)
        
        if (deltaType === "minute")  { //1 minute span -- we want ticks every 30 seconds
            minorTickFormat = '~HH:~MM:~SS'
            majorTickFormat = '~HH:~MM'

        }else if (deltaType === "minute5")  { //5 minute span -- we want ticks every 30 seconds
            minorTickFormat = '~HH:~MM:~SS'
            majorTickFormat = '~HH:~MM'

        }else if (deltaType === "minute10")  { //10 minute span -- we want ticks every minute
            minorTickFormat = '~HH:~MM:~SS'
            majorTickFormat = '~HH:~MM'

        }else if (deltaType === "minute30")  { //30 minute span -- we want ticks every 5 minutes
            minorTickFormat = '~HH:~MM:~SS'
            majorTickFormat = '~HH:~MM'

        }else if (deltaType === "hour")  { //60 minute span -- we want ticks every 15 minutes
            minorTickFormat = '~HH:~MM'
            majorTickFormat = '~HH:~MM'

        }else if (deltaType === "hour3")  { //3 hour span -- we want ticks every 30 minutes
            minorTickFormat = '~HH:~MM'
            majorTickFormat = '~HH:~MM'

        }else if (deltaType === "hour6")  { //6 hour span -- we want ticks every hour
            minorTickFormat = '~HH:~MM'
            majorTickFormat = 'Day ~D'
        }else if (deltaType === "hour12")  { //12 hour span -- we want ticks every hour
            minorTickFormat = '~HH:~MM'
            majorTickFormat = 'Day ~D'
        }else if (deltaType === "day")  { //1 day span -- we want ticks every 6 hours
            minorTickFormat = '~HH:~MM'
            majorTickFormat = 'Day ~D'

        }else if (deltaType === "week")  { //7 day span -- we want ticks every day
            minorTickFormat = '~HH:~MM'
            majorTickFormat = 'Day ~D'
        }

        // Major ticks every 1000 units.
        for (let majorTickPos = start - (start % majorTickInterval); majorTickPos <= end; majorTickPos += majorTickInterval) {
            if (majorTickPos >= start) {
                const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
                    .setTextFormatter(() => FormatTime(majorTickPos, majorTickFormat))
                    .setValue(majorTickPos)
                    .setMarker(marker => marker
                        .setTextFont(new FontSettings({ size: 14, style: '' }))
                    )
                    .setTickLength(8)
                    .setTickLabelPadding(-4)
                    .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA( 100 )))
                tickList.push(tick)
            }
        }
        // Major ticks every 100 units, but not at same interval as major ticks.
        for (let minorTickPos = start - (start % minorTickInterval); minorTickPos <= end; minorTickPos += minorTickInterval) {
            if (minorTickPos >= start && minorTickPos % majorTickInterval !== 0) {
                const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
                    .setTextFormatter(() => FormatTime(minorTickPos, minorTickFormat))
                    .setValue(minorTickPos)
                    .setMarker(marker => marker
                        .setTextFont(new FontSettings({ size: 12, style: '' }))
                    )
                    .setTickLabelPadding(-4)
                    .setTickLength(4)
                    .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA(50)))
                tickList.push(tick)
            }
        }
    }




    const updateChartAxisTicks = (axis, ticks, lastRange, start, end) =>    {
        
        if (start == lastRange.start && end == lastRange.end)
            return ticks

        const timeDelta = end - start
        if (Math.floor(timeDelta) != Math.floor(lastRange.end - lastRange.start)) {
            //Zoom
           
            ticks = ticks.filter(tick => {
                tick.dispose()
                return false
            })
            createTicksInRangeX(axis, ticks, timeDelta, start, end)
            

        }else {
            //Pan

            //compare last vs now to see if we need to add ticks
            if (end > lastRange.end) {
                createTicksInRangeX(axis, ticks, timeDelta, lastRange.end, end)
            }
            if (start < lastRange.start)    {
                createTicksInRangeX(axis, ticks, timeDelta, start, lastRange.start)
            }

            //compare last vs now to see if we need to remove ticks
            ticks = ticks.filter(tick => {
                if (tick.getValue() < start || tick.getValue() > end) {
                    // Tick is out of view.
                    tick.dispose()
                    return false
                } else {
                    return true
                }
            })
        }

        lastRange.start = start
        lastRange.end = end


        return ticks
    }

    const checkMainChartInterval = (start, end) =>    {
        if (chartRef.current === undefined)
            return
        if ((start == chartRef.current.lastMainChartInterval.start && end == chartRef.current.lastMainChartInterval.end) || processingMainChartInterval)
            return
        processingMainChartInterval = true
        let changed = false;
        const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()
        const timeDelta = end - start
        let newEnergySubselection = {start: chartRef.current.energySubSelection.start, end: chartRef.current.energySubSelection.end}
        
        if (Math.floor(timeDelta) != Math.floor(chartRef.current.lastMainChartInterval.end - chartRef.current.lastMainChartInterval.start)) {
            //Zoom
            if (start < overviewChartVisibleRange.start || start > overviewChartVisibleRange.end) {
                start = overviewChartVisibleRange.start
                changed = true
            }
            if (end > overviewChartVisibleRange.end || end < overviewChartVisibleRange.start) {
                end = overviewChartVisibleRange.end
                changed = true
            }


            //Handle current position of energy subselection 
            if (newEnergySubselection.start < start)    {
                newEnergySubselection.start = start
                if (newEnergySubselection.end < start)    {
                    newEnergySubselection.end = start + 1000
                }
                //newEnergySubselection.end = start + (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
            }
            if (newEnergySubselection.end > end)    {
                newEnergySubselection.end = end
                if (newEnergySubselection.start > end)    {
                    newEnergySubselection.start = end - 1000
                }
                //newEnergySubselection.start = end - (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
            }

            

        }else {
            //Pan
            if (end > overviewChartVisibleRange.end)    {
                start = overviewChartVisibleRange.end - timeDelta
                end = overviewChartVisibleRange.end
                changed = true
            }
            if (start < overviewChartVisibleRange.start)    {
                start = overviewChartVisibleRange.start
                end = overviewChartVisibleRange.start + timeDelta
                changed = true
            }

            //Handle current position of energy subselection 
            if (newEnergySubselection.start < start)    {
                newEnergySubselection.start = start
                newEnergySubselection.end = start + (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
            }
            if (newEnergySubselection.end > end)    {
                newEnergySubselection.end = end
                newEnergySubselection.start = end - (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
            }
        }


        
        chartRef.current.energySubSelection = newEnergySubselection

        chartRef.current.lastMainChartInterval.start = start
        chartRef.current.lastMainChartInterval.end = end
        if (changed)    {
            chartRef.current.mainChartDateAxis.setInterval(start, end, false, false)
        }
        chartRef.current.mainChartTimeTicks = updateChartAxisTicks(chartRef.current.mainChartDateAxis, chartRef.current.mainChartTimeTicks, chartRef.current.lastMainChartTickRange, start, end)
        
        processingMainChartInterval = false
        drawEnergySubselection()
        drawOverviewSubselection()
    }

    const checkOverviewChartInterval = React.useCallback((start, end) =>    {
        if (chartRef.current === undefined)
            return
        if ((start == chartRef.current.lastOverviewChartInterval.start && end == chartRef.current.lastOverviewChartInterval.end) || processingOverviewChartInterval)
            return
            processingOverviewChartInterval = true
        let changed = false;



        if (start !== 0)    {
            start = 0
            changed = true
        }
        if (end !== chartRef.current.totalGrowDuration)  {
            end = chartRef.current.totalGrowDuration
            changed = true
        }



        chartRef.current.lastOverviewChartInterval.start = start
        chartRef.current.lastOverviewChartInterval.end = end
        if (changed)    {
            chartRef.current.overviewChartDateAxis.setInterval(start, end, false, false)
        }

        chartRef.current.overviewChartTimeTicks = updateChartAxisTicks(chartRef.current.overviewChartDateAxis, chartRef.current.overviewChartTimeTicks, chartRef.current.lastOverviewChartTickRange, start, end)
        
        processingOverviewChartInterval = false

        drawEnergySubselection()
        drawOverviewSubselection()
    })


    const {devicePixelRatio: pixelRatio = 1} = window
    const grabberWidth = 4;
    const chartingAreaRef = React.useRef()
    const [chartingAreaPointerId, SetChartingAreaPointerId] = React.useState(null)
    const [chartsContainerBind, { height: chartsContainerHeight, documentTop: chartsContainerAreaTop, documentLeft: chartsContainerAreaLeft}] = useMeasure()
    const [mainChartAreaBind, { height: mainChartAreaHeight, documentTop: mainChartAreaTop, documentLeft: mainChartAreaLeft}] = useMeasure()
    const [overviewChartAreaBind, { height: overviewChartAreaHeight, documentTop: overviewChartAreaTop, documentLeft: overviewChartAreaLeft }] = useMeasure()
    
    const [lastMousePosition, SetLastMousePosition] = React.useState({x: 0, y: 0});
    const [numberOfPointersDownOnMainCanvas, SetNumberOfPointersDownOnMainCanvas] = React.useState(0)
    const [draggingMainEnergyGrabber, SetDraggingMainEnergyGrabber] = React.useState(undefined)
    const [pointerOverMainEnergyGrabber, SetPointerOverMainEnergyGrabber] = React.useState(undefined)
    const [pointerOverMainChartDate, SetPointerOverMainChartDate] = React.useState(undefined)
    const [pointerOverDosingInstance, SetPointerOverDosingInstance] = React.useState(undefined)
    const [dosingInstanceSelected, SetDosingInstanceSelected] = React.useState(undefined)
    const [pointerOverMainChartY, SetPointerOverMainChartY] = React.useState(0)
    const [isTouchOverMainChart, SetIsTouchOverMainChart] = React.useState(false)
    const [mainCursor, SetMainCursor] = React.useState("default")
    const [isPointerDownOnOverviewCanvas, SetIsPointerDownOnOverviewCanvas] = React.useState(false)
    const [draggingOverviewGrabber, SetDraggingOverviewGrabber] = React.useState(undefined)
    const [pointerOverOverviewGrabber, SetPointerOverOverviewGrabber] = React.useState(undefined)
    const [overviewCursor, SetOverviewCursor] = React.useState("default")


    const chartingAreaPointerMove = React.useCallback((e) =>  {
        
        let pointerPosition = {top: 0, left: 0}
        pointerPosition.top = e.clientY
        pointerPosition.left = e.clientX

        if (numberOfPointersDownOnMainCanvas == 1 || (!isPointerDownOnOverviewCanvas && pointerPosition.top < mainChartAreaTop + mainChartAreaHeight))   {
            mainCanvasPointerMove(e, {y: pointerPosition.top - mainChartAreaTop, x: pointerPosition.left - mainChartAreaLeft})
        }else if (isPointerDownOnOverviewCanvas || (numberOfPointersDownOnMainCanvas == 0 && pointerPosition.top > overviewChartAreaTop && pointerPosition.top < overviewChartAreaTop + overviewChartAreaHeight))   {
            overviewCanvasPointerMove(e, {y: pointerPosition.top - overviewChartAreaTop, x: pointerPosition.left - overviewChartAreaLeft})
            SetPointerOverMainChartDate(undefined)
        }else {
            SetPointerOverMainChartDate(undefined)
        }
        SetLastMousePosition({x: e.clientX, y: e.clientY})
    })
    const chartingAreaPointerDown = React.useCallback((e) =>  {
        let pointerPosition = {top: 0, left: 0}
        pointerPosition.top = e.clientY
        pointerPosition.left = e.clientX

        

        if (pointerPosition.top < mainChartAreaTop + mainChartAreaHeight)   {
            SetNumberOfPointersDownOnMainCanvas(numberOfPointersDownOnMainCanvas + 1)
            if (numberOfPointersDownOnMainCanvas > 1)   { 
                chartingAreaReset()
            }else {
                mainCanvasPointerDown(e, {y: pointerPosition.top - mainChartAreaTop, x: pointerPosition.left - mainChartAreaLeft})
            }
        }else if (pointerPosition.top > overviewChartAreaTop && pointerPosition.top < overviewChartAreaTop + overviewChartAreaHeight)   {
            SetIsPointerDownOnOverviewCanvas(true)
            overviewCanvasPointerDown(e, {y: pointerPosition.top - overviewChartAreaTop, x: pointerPosition.left - overviewChartAreaLeft})
        }

        if (e.pointerType == "touch")   {
            SetIsTouchOverMainChart(true)
        }else {
            SetIsTouchOverMainChart(false)
        }

        SetLastMousePosition({x: e.clientX, y: e.clientY})

        
    })
    const chartingAreaPointerUp = (e) =>  {
        chartingAreaReset()
        if (numberOfPointersDownOnMainCanvas > 0)   {
            SetNumberOfPointersDownOnMainCanvas(numberOfPointersDownOnMainCanvas - 1)
        }
        if (chartingAreaRef.current !== undefined && chartingAreaRef.current.releasePointerCapture)    {
            chartingAreaRef.current.releasePointerCapture(chartingAreaPointerId);
        }
    }
    const chartingAreaPointerLeave = (e) => {
        SetPointerOverMainChartDate(undefined)
    }

    const chartingAreaReset = () => {
        SetDraggingMainEnergyGrabber(undefined)
        SetPointerOverMainEnergyGrabber(undefined)
        SetDraggingOverviewGrabber(undefined)
        SetIsPointerDownOnOverviewCanvas(false)
        SetPointerOverMainChartDate(undefined)
    }
     

    const mainCanvasPointerMove = (e, pointerOffset) =>    {
        if (!energySubSelectionCanvasRef.current || !chartRef.current || dosingInstanceSelected) 
            return

        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()
        const mainChartPadding = chartRef.current.mainChart.getPadding()
        const chartArea = getMainChartArea()

        pointerOffset.x -= mainChartPadding.left + 4 //hack
        pointerOffset.y -= mainChartPadding.top + 10 //hack
        SetPointerOverMainChartY(pointerOffset.y)

        const currentlyOverTime = mainChartConvertPositionToDate(pointerOffset.x)



        //Detect if we are currently over a grabber (only applies to non touch here, touch needs to be initiated on pointer down)
        let pointerOverGrabber = undefined;
        if (energySubselectionActive && e.pointerType != "touch")   {
            let eX1 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.start)
            let eX2 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.end)
            
            let hitTestWidth = {outside: grabberWidth + 1, inside: 1}
            let hitTestHeight = 30 //for now just give the whole vertical space -- fix later
            if (eX2 - eX1 < hitTestWidth.inside * 2)    {
                hitTestWidth.inside = (eX2 - eX1) / 2
            }
            if (eX1 - hitTestWidth.outside <= pointerOffset.x && eX1 + hitTestWidth.inside > pointerOffset.x)   {
                pointerOverGrabber = "left"
            }else if (eX2 - hitTestWidth.inside < pointerOffset.x && eX2 + hitTestWidth.outside >= pointerOffset.x) {
                pointerOverGrabber = "right"
            }else if (eX1 - hitTestWidth.outside <= pointerOffset.x && eX2 + hitTestWidth.outside >= pointerOffset.x) {
                //pointerOverGrabber = "middle"
            }
        }
        let isForTooltip = false
        //Check if we are dragging a grabber
        if (draggingMainEnergyGrabber)    {
            let offset = {x: e.clientX - lastMousePosition.x, y: e.clientY - lastMousePosition.y};
            let addingTime = ((mainChartVisibleRange.end - mainChartVisibleRange.start) * (offset.x / chartArea.width))
            if (draggingMainEnergyGrabber == "right") {
                let newEnd = currentlyOverTime
                if (newEnd < chartRef.current.energySubSelection.start) {
                    newEnd = chartRef.current.energySubSelection.start + 1
                }
                chartRef.current.energySubSelection = {start: chartRef.current.energySubSelection.start, end: newEnd}
            }else if (draggingMainEnergyGrabber == "left") {
                let newStart = currentlyOverTime
                if (newStart > chartRef.current.energySubSelection.end) {
                    newStart = chartRef.current.energySubSelection.end - 1
                }
                chartRef.current.energySubSelection = {start: newStart, end: chartRef.current.energySubSelection.end}
            }

        //Check if we are dragging on the canvas currently (only applies to mouse/pointer)
        //}else if (isPointerDownOnMainCanvas)  {


        //Check if we are currently pointing over a grabber
        }else if (pointerOverGrabber !== undefined)   {
            e.preventDefault()
            e.stopPropagation()
            if (pointerOverMainEnergyGrabber != pointerOverGrabber)    {
                SetPointerOverMainEnergyGrabber(pointerOverGrabber)
            }

        //Otherwise -- We aren't dragging, we aren't over a grabber: Tooltip calculation here
        }else {
            let overDosingInstance = null;
            if (chartRef.current.activeYAxes["millilitres"] !== undefined)  {
                for (const [growId, growData] of Object.entries(chartRef.current.dataSeries)) {
                    if (growData["nutrients"] !== undefined)    {
                        if (growData["nutrients"].dosingInstanceBars !== undefined)    {
                            for (const dosingInstanceBar of growData["nutrients"].dosingInstanceBars)  {
                                if (dosingInstanceBar.dimensions.x <= currentlyOverTime && dosingInstanceBar.dimensions.x + dosingInstanceBar.dimensions.width >= currentlyOverTime)    {//&&
                                    overDosingInstance = dosingInstanceBar
                                    break
                                }
                            }
                        }
                    }
                    if (overDosingInstance) {
                        break
                    }
                }
            }
            if (overDosingInstance) {
                if (pointerOverDosingInstance !== undefined && pointerOverDosingInstance !== overDosingInstance)    {
                    pointerOverDosingInstance.bar.setStrokeStyle(emptyLine)
                }
                if (pointerOverDosingInstance !== overDosingInstance)   {
                    overDosingInstance.bar.setStrokeStyle(new SolidLine({fillStyle: new SolidFill({color: ColorRGBA(46, 114, 210)}), thickness:3}))
                    SetPointerOverDosingInstance(overDosingInstance)
                }
            }else {
                if (pointerOverDosingInstance !== undefined)    {
                    pointerOverDosingInstance.bar.setStrokeStyle(emptyLine)
                    SetPointerOverDosingInstance(undefined)
                }
            }
            isForTooltip = true
            SetPointerOverMainChartDate(currentlyOverTime)
           

        }
        

        //Check if we were over a grabber but aren't anymore
        if (pointerOverGrabber === undefined && pointerOverMainEnergyGrabber !== undefined)   {
            SetPointerOverMainEnergyGrabber(undefined)
        }

        if (!isForTooltip)  {
            SetPointerOverMainChartDate(undefined)
        }
    }

    const mainCanvasPointerDown = (e, pointerOffset) => {
        if (!energySubSelectionCanvasRef.current || !chartRef.current) 
            return

        let requiresPointerCapture = false
        

        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()
        const mainChartPadding = chartRef.current.mainChart.getPadding()

        pointerOffset.x -= mainChartPadding.left + 4 //hack
        pointerOffset.y -= mainChartPadding.top + 10 //hack
        const currentlyOverTime = mainChartConvertPositionToDate(pointerOffset.x)

        //Detect if we are currently touching down on a grabber (only applies to touch here, touch needs to be initiated on pointer down)
        let touchDownOnGrabber = undefined;
        if (energySubselectionActive && e.pointerType == "touch")   {
            let eX1 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.start)
            let eX2 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.end)
            
            let hitTestWidth = {outside: grabberWidth * 5, inside: grabberWidth * 4}
            let hitTestHeight = 10 //for now just give the whole vertical space -- fix later

            if (eX2 - eX1 < hitTestWidth.inside * 2)    {
                hitTestWidth.inside = (eX2 - eX1) / 2
            }
            if (eX1 - hitTestWidth.outside <= pointerOffset.x && eX1 + hitTestWidth.inside > pointerOffset.x)   {
                touchDownOnGrabber = "left"
            }else if (eX2 - hitTestWidth.inside < pointerOffset.x && eX2 + hitTestWidth.outside >= pointerOffset.x) {
                touchDownOnGrabber = "right"
            }


            if (touchDownOnGrabber) {
                if (draggingMainEnergyGrabber != touchDownOnGrabber)  {
                    SetDraggingMainEnergyGrabber(touchDownOnGrabber)
                    requiresPointerCapture = true
                }
            }

            if (draggingMainEnergyGrabber !== undefined && touchDownOnGrabber === undefined)  {
                SetDraggingMainEnergyGrabber(undefined)
            }

        }else {

            if (pointerOverMainEnergyGrabber !== undefined && draggingMainEnergyGrabber != pointerOverMainEnergyGrabber)   {
                SetDraggingMainEnergyGrabber(pointerOverMainEnergyGrabber)
                requiresPointerCapture = true
            }



            /*if (draggingOverviewGrabber !== undefined && pointerOverOverviewGrabber === undefined)  {
                SetDraggingOverviewGrabber(undefined)
            }*/
        }

        if (touchDownOnGrabber === undefined && pointerOverMainEnergyGrabber === undefined && draggingMainEnergyGrabber === undefined) {
            let overDosingInstance = null
            if (chartRef.current.activeYAxes["millilitres"] !== undefined)  {
                const chartArea = getMainChartArea()
                const axisLimits = chartRef.current.activeYAxes["millilitres"].main.getInterval()
                let yValue = (1 - (pointerOffset.y / chartArea.height)) * axisLimits.end
                for (const [growId, growData] of Object.entries(chartRef.current.dataSeries)) {
                    if (growData["nutrients"] !== undefined)    {
                        if (growData["nutrients"].dosingInstanceBars !== undefined)    {
                            for (const dosingInstanceBar of growData["nutrients"].dosingInstanceBars)  {
                                let hitTestMargin = e.pointerType == "touch" ? 10 : 0
                                if (dosingInstanceBar.dimensions.x - hitTestMargin <= currentlyOverTime && dosingInstanceBar.dimensions.x + dosingInstanceBar.dimensions.width + hitTestMargin >= currentlyOverTime &&
                                    dosingInstanceBar.dimensions.y - hitTestMargin <= yValue && dosingInstanceBar.dimensions.y + dosingInstanceBar.dimensions.height + hitTestMargin >= yValue)    {
                                    
                                    if (e.pointerType == "touch")   {
                                        if (overDosingInstance === null || 
                                                Math.abs(dosingInstanceBar.dimensions.x + (dosingInstanceBar.dimensions.width / 2) - currentlyOverTime) < 
                                                Math.abs(overDosingInstance.dimensions.x + (overDosingInstance.dimensions.width / 2) - currentlyOverTime)) {
                                            overDosingInstance = dosingInstanceBar                                            
                                        }
                                    }else {
                                        overDosingInstance = dosingInstanceBar
                                        break
                                    }
                                
                                }
                                
                            }
                        }
                    }
                    
                    if (e.pointerType !== "touch" && overDosingInstance) {
                        break
                    }
                }
            }

            if (overDosingInstance) {
                if (dosingInstanceSelected && overDosingInstance != dosingInstanceSelected) {
                    dosingInstanceSelected.bar.setStrokeStyle(emptyLine)
                }
                overDosingInstance.bar.setStrokeStyle(new SolidLine({fillStyle: new SolidFill({color: ColorRGBA(46, 114, 210)}), thickness:3}))
                
                SetDosingInstanceSelected(overDosingInstance)
                e.preventDefault()
                e.stopPropagation()
                const undoDosingInstanceSelection = (e) =>   {
                    overDosingInstance.bar.setStrokeStyle(emptyLine)
                    SetDosingInstanceSelected(undefined)
                    document.removeEventListener("pointerdown", undoDosingInstanceSelection)
                }
                document.addEventListener("pointerdown", undoDosingInstanceSelection)
            }
        }

        if (requiresPointerCapture) {
            if (chartingAreaRef.current !== undefined && chartingAreaRef.current.setPointerCapture)    {
                SetChartingAreaPointerId(e.pointerId)
                chartingAreaRef.current.setPointerCapture(e.pointerId);
            }
        }
    }


    
    const overviewCanvasPointerMove = (e, pointerOffset) =>    {
        if (!chartRef.current) 
            return

        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()
        const overviewChartPadding = chartRef.current.overviewChart.getPadding()
        const chartArea = getOverviewChartArea()

        pointerOffset.x -= overviewChartPadding.left + 4 //hack
        pointerOffset.y -= overviewChartPadding.top + 10 //hack
        //Detect if we are currently over a grabber (only applies to non touch here, touch needs to be initiated on pointer down)
        let pointerOverGrabber = undefined;
        if (e.pointerType != "touch")   {
            let sX1 = overviewChartConvertDateToPosition(mainChartVisibleRange.start - overviewChartVisibleRange.start)
            let sX2 = overviewChartConvertDateToPosition(mainChartVisibleRange.end - overviewChartVisibleRange.start)
            
            let hitTestWidth = {outside: grabberWidth + 1, inside: 1}
            let hitTestHeight = 10 //for now just give the whole vertical space -- fix later
            if (sX2 - sX1 < hitTestWidth.inside * 2)    {
                hitTestWidth.inside = (sX2 - sX1) / 2
            }
            if (sX1 - hitTestWidth.outside <= pointerOffset.x && sX1 + hitTestWidth.inside > pointerOffset.x)   {
                pointerOverGrabber = "left"
            }else if (sX2 - hitTestWidth.inside < pointerOffset.x && sX2 + hitTestWidth.outside >= pointerOffset.x) {
                pointerOverGrabber = "right"
            }else if (sX1 - hitTestWidth.outside <= pointerOffset.x && sX2 + hitTestWidth.outside >= pointerOffset.x) {
                pointerOverGrabber = "middle"
            }
        }

        //Check if we are dragging a grabber
        if (draggingOverviewGrabber)    {
            let offset = {x: e.clientX - lastMousePosition.x, y: e.clientY - lastMousePosition.y};
            let addingTime = ((overviewChartVisibleRange.end - overviewChartVisibleRange.start) * (offset.x / chartArea.width))
           
            if (draggingOverviewGrabber == "right") {
                let newEnd = overviewChartConvertPositionToDate(pointerOffset.x)
                if (newEnd < mainChartVisibleRange.start) {
                    newEnd = mainChartVisibleRange.start + 1000 * 30
                }
                chartRef.current.mainChartDateAxis.setInterval(mainChartVisibleRange.start, newEnd, false, false)
            }else if (draggingOverviewGrabber == "left") {
                let newStart = overviewChartConvertPositionToDate(pointerOffset.x)
                if (newStart > mainChartVisibleRange.end) {
                    newStart = mainChartVisibleRange.end - 1000 * 30
                }
                chartRef.current.mainChartDateAxis.setInterval(newStart, mainChartVisibleRange.end, false, false)
            }else if (draggingOverviewGrabber == "middle") {
                chartRef.current.mainChartDateAxis.setInterval(mainChartVisibleRange.start + addingTime, mainChartVisibleRange.end + addingTime, false, false)
            }

        //Check if we are dragging on the canvas currently (only applies to mouse/pointer)
        }else if (isPointerDownOnOverviewCanvas)  {


        //Check if we are currently pointing over a grabber
        }else if (pointerOverGrabber !== undefined)   {
            if (pointerOverOverviewGrabber != pointerOverGrabber)    {
                SetPointerOverOverviewGrabber(pointerOverGrabber)
            }

        //Otherwise -- We aren't dragging, we aren't over a grabber: Tooltip calculation here
        }else {
            let date = overviewChartConvertPositionToDate(pointerOffset.x)
            //console.log(date)
        }
        

        //Check if we were over a grabber but aren't anymore
        if (pointerOverGrabber === undefined && pointerOverOverviewGrabber !== undefined)   {
            SetPointerOverOverviewGrabber(undefined)
        }
    }

    const overviewCanvasPointerDown = (e, pointerOffset) => {
        if (!chartRef.current) 
            return

        let requiresPointerCapture = false

        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()
        const overviewChartPadding = chartRef.current.overviewChart.getPadding()

        pointerOffset.x -= overviewChartPadding.left + 4 //hack
        pointerOffset.y -= overviewChartPadding.top + 10 //hack
        //Detect if we are currently touching down on a grabber (only applies to touch here, touch needs to be initiated on pointer down)
        let touchDownOnGrabber = undefined;
        if (e.pointerType == "touch")   {
            let sX1 = overviewChartConvertDateToPosition(mainChartVisibleRange.start - overviewChartVisibleRange.start)
            let sX2 = overviewChartConvertDateToPosition(mainChartVisibleRange.end - overviewChartVisibleRange.start)
            
            let hitTestWidth = {outside: grabberWidth * 3, inside: grabberWidth * 2}
            let hitTestHeight = 10 //for now just give the whole vertical space -- fix later

            if (sX2 - sX1 < hitTestWidth.inside * 2)    {
                hitTestWidth.inside = (sX2 - sX1) / 2
            }
            if (sX1 - hitTestWidth.outside <= pointerOffset.x && sX1 + hitTestWidth.inside > pointerOffset.x)   {
                touchDownOnGrabber = "left"
            }else if (sX2 - hitTestWidth.inside < pointerOffset.x && sX2 + hitTestWidth.outside >= pointerOffset.x) {
                touchDownOnGrabber = "right"
            }else if (sX1 - hitTestWidth.outside <= pointerOffset.x && sX2 + hitTestWidth.outside >= pointerOffset.x) {
                touchDownOnGrabber = "middle"
            }


            if (touchDownOnGrabber) {
                if (draggingOverviewGrabber != touchDownOnGrabber)  {
                    SetDraggingOverviewGrabber(touchDownOnGrabber)
                    requiresPointerCapture = true
                }
            }

            if (draggingOverviewGrabber !== undefined && touchDownOnGrabber === undefined)  {
                SetDraggingOverviewGrabber(undefined)
            }

        }else {

            if (pointerOverOverviewGrabber !== undefined && draggingOverviewGrabber != pointerOverOverviewGrabber)   {
                SetDraggingOverviewGrabber(pointerOverOverviewGrabber)
                requiresPointerCapture = true
            }



            /*if (draggingOverviewGrabber !== undefined && pointerOverOverviewGrabber === undefined)  {
                SetDraggingOverviewGrabber(undefined)
            }*/
        }


        if (requiresPointerCapture) {
            if (chartingAreaRef.current !== undefined && chartingAreaRef.current.setPointerCapture)    {
                SetChartingAreaPointerId(e.pointerId)
                chartingAreaRef.current.setPointerCapture(e.pointerId);
            }
        }
    }

    const drawGrowEvents = React.useCallback(() => {
        if (!chartRef.current) 
            return
        
        
        const mainDataRecordingTimePeriodType = getMainDataRecordingTimePeriodType()
        const mainChartPadding = chartRef.current.mainChart.getPadding()
        const chartArea = getMainChartArea()

        const currentPositionIndicatorWidth = 2

        return (
            <>
                {chartRef.current.grows.map((grow) => {
                    if (grow.selected)  {
                        let currentPositionIndicatorStyleProps = {}
                        let endDate = (grow.completed ? new Date(grow.finished_on) : new Date())
                        let xPoint = mainChartConvertDateToPosition((endDate.getTime() - new Date(grow.started_on).getTime()))
                        currentPositionIndicatorStyleProps.left = xPoint + (mainChartAreaLeft - chartsContainerAreaLeft) + mainChartPadding.left + 4 - (currentPositionIndicatorWidth / 2);
                        currentPositionIndicatorStyleProps.top = 10 + mainChartPadding.top
                        currentPositionIndicatorStyleProps.height = chartArea.height
                        currentPositionIndicatorStyleProps.width = currentPositionIndicatorWidth

                        return ( 
                            <div key={grow.id} 
                                className="GrowManagerChartsPage_CurrentPositionIndicator" 
                                style={currentPositionIndicatorStyleProps}/>
                        )
                    }
                })}
            </>
        )
    })
    
    const drawTooltip = React.useCallback(() =>   {
        if (!chartRef.current) 
            return

        const mainDataRecordingTimePeriodType = getMainDataRecordingTimePeriodType()
        const mainChartPadding = chartRef.current.mainChart.getPadding()
        const chartArea = getMainChartArea()
        
        let tooltipSpacingFromCenter = {x: 5, y: 5}
        if (isTouchOverMainChart)   {
            tooltipSpacingFromCenter = {x: 5, y: 15}
        }
        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()


        let tooltipStyleProps = {}
        let xPoint = mainChartConvertDateToPosition(pointerOverMainChartDate)
        if (pointerOverMainChartDate - mainChartVisibleRange.start < (mainChartVisibleRange.end - mainChartVisibleRange.start) / 2)   {
            tooltipStyleProps.left = xPoint + (mainChartAreaLeft - chartsContainerAreaLeft) + mainChartPadding.left + 4 + tooltipSpacingFromCenter.x;
        }else   {
            tooltipStyleProps.right = chartArea.width - xPoint - (mainChartAreaLeft - chartsContainerAreaLeft) + mainChartPadding.right + 4 + tooltipSpacingFromCenter.x;
        }
        tooltipStyleProps.bottom = (chartsContainerHeight - pointerOverMainChartY) + tooltipSpacingFromCenter.y;
        if (tooltipStyleProps.bottom > chartsContainerHeight)   {
            tooltipStyleProps.bottom = chartsContainerHeight
            //tooltipStyleProps.top = 
        }

        let timeTooltipStyleProps = {
            left: xPoint + mainChartPadding.left + 4,
            top: mainChartAreaHeight
        }


        //pointerOverMainChartDate
        let selectedGrows = []
        for (let grow of chartRef.current.grows)    {
            if (grow.selected)  {
                selectedGrows.push(grow)
            }
        }


        let selectedDataTypes = []
        for (const dataTypeGroupIdentifier in dataTypeToggles) {
            const dataTypeGroup = dataTypeToggles[dataTypeGroupIdentifier]
            for (const dataType in dataTypeGroup.dataTypes) {
                const dataTypeInfo = dataTypeGroup.dataTypes[dataType]
                let identifier = dataTypeInfo.identifier

                if (dataTypeGroup.selected && dataTypeInfo.selected)   {
                    selectedDataTypes.push(dataTypeInfo)
                }
            }
        }

        let growsInTooltips = []
        for (let grow of selectedGrows) {
            if (new Date().getTime() - new Date(grow.started_on).getTime() > pointerOverMainChartDate)  {
                growsInTooltips.push(grow)
            }
        }

        if (growsInTooltips.length > 0) {

            return (
                <>
                    <div id="GrowManagerChartsPage_Tooltip" style={tooltipStyleProps}>
                        <table className="GrowManagerChartsPage_Tooltip-Table">
                            <thead><tr>
                                <th> </th>
                                <th> </th>
                            {growsInTooltips.map((grow) => {
                                        
                                    //do some math if necessary

                                    return (
                                        <th key={grow.id}>
                                            {grow.id}
                                        </th>
                                    )}
                                )}
                            </tr></thead>
                            <tbody>
                                {selectedDataTypes.map((dataType) => (
                                    <tr key={dataType.identifier}>
                                        <td><div className="GrowManagerDataToggle-DataTypeColorIndicator" style={{backgroundColor:dataType.color}}/></td>
                                        <td>{dataType.label}</td>

                                        {growsInTooltips.map((grow) => {
                                            
                                

                                            //do some math if necessary
                                            let value = 0
                                            if (grow.analyticsData.timePeriods !== undefined && grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index] !== undefined) {
                                                if (!dataType.isEnergyData) {
                                                    if (dataType.identifier === "nutrients_total")    {
                                                        let closestDataPoint = binaryClosestIdx(grow.analyticsData.nutrientsTotalData, pointerOverMainChartDate, 'x')
                                                        if (grow.analyticsData.nutrientsTotalData[closestDataPoint].x > pointerOverMainChartDate) {
                                                            closestDataPoint -= 1
                                                        }
                                                        
                                                        if (closestDataPoint !== -1)    {
                                                            value = RoundToNearest(grow.analyticsData.nutrientsTotalData[closestDataPoint].y, dataType.resolution)
                                                        }
                                                    }else if (dataType.identifier === "nutrients")    {
                                                        value = "n/a"
                                                        if (pointerOverDosingInstance !== undefined && pointerOverDosingInstance.growId === grow.id)    {
                                                            value = 0
                                                            if (pointerOverDosingInstance.dosingInstance !== undefined) {
                                                                for (let dosingItem of Object.values(pointerOverDosingInstance.dosingInstance.dosingItems))   {
                                                                    if (dosingItem.type === "nutrient_solution" || dosingItem.type === "solution")   {
                                                                        value += parseFloat(dosingItem.volume)
                                                                    }
                                                                }
                                                            }else if (pointerOverDosingInstance.groupedInstance !== undefined) {
                                                                for (let dosingItem of pointerOverDosingInstance.groupedInstance.dosingItems)   {
                                                                    if (dosingItem.type === "nutrient_solution" || dosingItem.type === "solution")   {
                                                                        value += parseFloat(dosingItem.volume)
                                                                    }
                                                                }
                                                            }
                                                            value = Math.round(Math.round(value * 100) / 100)
                                                        }
                                                        
                                                    }else {
                                                        if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[dataType.identifier] !== undefined)  {
                                                            if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[dataType.identifier].length > 0) {
                                                                
                                                                let closestDataPoint = binaryClosestIdx(grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[dataType.identifier], pointerOverMainChartDate, 'x')
                                                                if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[dataType.identifier][closestDataPoint].x > pointerOverMainChartDate) {
                                                                    closestDataPoint -= 1
                                                                }
                                                                
                                                                if (closestDataPoint !== -1)    {
                                                                    value = RoundToNearest(grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[dataType.identifier][closestDataPoint].y, dataType.resolution)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }else {
                                                    if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData[dataType.identifier] !== undefined)  {
                                                        if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData[dataType.identifier].length > 0) {
                                                            
                                                            let closestDataPoint = binaryClosestIdx(grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData[dataType.identifier], pointerOverMainChartDate, 'x')
                                                            if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData[dataType.identifier][closestDataPoint].x > pointerOverMainChartDate) {
                                                                closestDataPoint -= 1
                                                            }
                                                            if (closestDataPoint !== -1)    {
                                                                value = RoundToNearest(grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData[dataType.identifier][closestDataPoint].y, 0.1)
                                                            }
                                                            //console.log(grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].data[dataType.identifier][closestDataPoint])
                                                        }
                                                    }
                                                }
                                            }

                                            return (
                                                <td key={grow.id} className="GrowManagerChartsPage_Tooltip-ValueDisplay">
                                                    {value}<span className="GrowManagerChartsPage_Tooltip-Unit">{dataType.unit}</span>
                                                </td>
                                            )}
                                        )}
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    {growsInTooltips.map((grow) => {
                                        let currentDate = new Date(new Date(grow.started_on).getTime() + pointerOverMainChartDate)
                                        return (
                                            <td key={"d-" + grow.id} className="GrowManagerChartsPage_Tooltip-DateDisplay">
                                                <div>{FormatDate(currentDate, 'MM/dd/yyyy')}</div>
                                                <div>{FormatDate(currentDate, 'HH:mm:ss')}</div>
                                                
                                            </td>
                                        )
                                    })}
                                </tr>    
                            </tbody>
                        </table>
                    </div>


                    <div className="GrowManagerChartsPage_TimeTooltip" style={timeTooltipStyleProps}>
                        {FormatTime(pointerOverMainChartDate, 'Day ~D, ~HH:~MM:~SS')}
                    </div>
                </>
            )
        }else {
            return (<></>)
        }
    })



const drawDosingInstanceTooltip = React.useCallback(() => {

        if (!chartRef.current) 
        return

    const mainDataRecordingTimePeriodType = getMainDataRecordingTimePeriodType()
    const mainChartPadding = chartRef.current.mainChart.getPadding()
    const chartArea = getMainChartArea()
    
    const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()


    let tooltipStyleProps = {}
    let xPoint = mainChartConvertDateToPosition(dosingInstanceSelected.dimensions.x)
    let xEndPoint = mainChartConvertDateToPosition(dosingInstanceSelected.dimensions.x + dosingInstanceSelected.dimensions.width)
    const axisLimits = chartRef.current.activeYAxes["millilitres"].main.getInterval()
    let yPoint = (1 - (dosingInstanceSelected.dimensions.height / axisLimits.end)) * chartArea.height
    if (yPoint > chartArea.height)  {
        yPoint = chartArea.height
    }

    if (dosingInstanceSelected.dimensions.x - mainChartVisibleRange.start < (mainChartVisibleRange.end - mainChartVisibleRange.start) / 2)   {
        tooltipStyleProps.left = xEndPoint + (mainChartAreaLeft - chartsContainerAreaLeft) + mainChartPadding.left + 4;
    }else   {
        tooltipStyleProps.right = chartArea.width - xPoint - (mainChartAreaLeft - chartsContainerAreaLeft) + mainChartPadding.right + 4;
    }
    tooltipStyleProps.bottom = (chartsContainerHeight - yPoint);
    if (tooltipStyleProps.bottom > chartsContainerHeight)   {
        tooltipStyleProps.bottom = chartsContainerHeight
        //tooltipStyleProps.top = 
    }

    let timeTooltipStyleProps = {
        left: xPoint + mainChartPadding.left + 4,
        top: mainChartAreaHeight
    }

    //console.log(dosingInstanceSelected)

    let nutrientDosingItems = []
    let pHUpDosingItem = null
    let pHDownDosingItem = null
    if (dosingInstanceSelected.dosingInstance !== undefined)    {

        for (let dosingItem of Object.values(dosingInstanceSelected.dosingInstance.dosingItems))  {
            let currentDosingItem = {...dosingItem}
            if (currentDosingItem.type === "nutrient_solution")    {
                const nutrientSolution = nutrientSolutions.find((nutrientSolution) => nutrientSolution.id === currentDosingItem.ref_id)
                if (nutrientSolution !== undefined) {
                    currentDosingItem.name = nutrientSolution.display_name
                }else {
                    currentDosingItem.name = "Subpart"
                }
                nutrientDosingItems.push(currentDosingItem)

            }else if (currentDosingItem.type === "solution")   {
                nutrientDosingItems.push(currentDosingItem)
            }else if (currentDosingItem.type === "ph_up")  {
                pHUpDosingItem = currentDosingItem
            }else if (currentDosingItem.type === "ph_down")  {
                pHDownDosingItem = currentDosingItem
            }
        }
    }else if (dosingInstanceSelected.groupedInstance !== undefined)    {
        for (let dosingItem of Object.values(dosingInstanceSelected.groupedInstance.dosingItems))  {
            if (dosingItem.type === "nutrient_solution")    {
                const nutrientSolution = nutrientSolutions.find((nutrientSolution) => nutrientSolution.id === dosingItem.ref_id)
                if (nutrientSolution !== undefined) {
                    dosingItem.name = nutrientSolution.display_name
                }else {
                    dosingItem.name = "Subpart"
                }
                nutrientDosingItems.push(dosingItem)

            }else if (dosingItem.type === "solution")   {
                nutrientDosingItems.push(dosingItem)
            }else if (dosingItem.type === "ph_up")  {
                pHUpDosingItem = dosingItem
            }else if (dosingItem.type === "ph_down")  {
                pHDownDosingItem = dosingItem
            }
        }
    }


    return (
        <>
            <div id="GrowManagerChartsPage_Tooltip" style={tooltipStyleProps}>
                <table>
                    <thead><tr>
                        <th>Name</th>
                        <th>Volume</th>
                        {/*<th>Total Volume</th>*/}
                    </tr></thead>
                    <tbody>
                        {nutrientDosingItems.map((dosingItem) => {
                            return (
                                <tr key={dosingItem.type + "-" + dosingItem.ref_id}>
                                    <td>{dosingItem.name}</td>
                                    <td className="GrowManagerChartsPage_Tooltip-ValueDisplay">{Math.round((Math.round(dosingItem.volume * 100) / 100))}mL</td>
                                    {/*<td className="GrowManagerChartsPage_Tooltip-ValueDisplay">{(Math.round(dosingItem.totalVolume * 100) / 100).toFixed(2)}mL</td>*/}
                                </tr>
                            )
                        })}

                        {pHUpDosingItem && 
                            <tr key={pHUpDosingItem.type + "-" + pHUpDosingItem.ref_id}>
                                <td>pH+</td>
                                <td className="GrowManagerChartsPage_Tooltip-ValueDisplay">{Math.round((Math.round(pHUpDosingItem.volume * 100) / 100))}mL</td>
                                {/*<td className="GrowManagerChartsPage_Tooltip-ValueDisplay">{(Math.round(pHUpDosingItem.totalVolume * 100) / 100).toFixed(2)}mL</td>*/}
                            </tr>
                        }
                        {pHDownDosingItem && 
                            <tr key={pHDownDosingItem.type + "-" + pHDownDosingItem.ref_id}>
                                <td>pH-</td>
                                <td className="GrowManagerChartsPage_Tooltip-ValueDisplay">{Math.round((Math.round(pHDownDosingItem.volume * 100) / 100))}mL</td>
                                {/*<td className="GrowManagerChartsPage_Tooltip-ValueDisplay">{(Math.round(pHDownDosingItem.totalVolume * 100) / 100).toFixed(2)}mL</td>*/}
                            </tr>
                        }
                    </tbody>
                </table>
            </div>


            <div className="GrowManagerChartsPage_TimeTooltip" style={timeTooltipStyleProps}>
                {FormatTime(dosingInstanceSelected.dimensions.x, 'Day ~D, ~HH:~MM:~SS')}
            </div>
        </>
    )


    })


    const resizeView = view => {
        const { width, height } = view.getBoundingClientRect()
        //alert(width.toString() + "-" + height)
        
        
        if (view.width !== width*pixelRatio || view.height !== height*pixelRatio) {
          const context = view.getContext('2d')
          view.width = width*pixelRatio
          view.height = height*pixelRatio
          context.scale(pixelRatio, pixelRatio)
          return true
        }
    
        return false
    }
    
    const drawRoundedRect = (ctx, x, y, width, height, radius) => { //top left, top right, bottom left, bottom right
        ctx.beginPath();
        ctx.moveTo(x + radius[0], y);
        ctx.lineTo(x + width - radius[1], y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius[1]);
        ctx.lineTo(x + width, y + height - radius[3]);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius[3], y + height);
        ctx.lineTo(x + radius[2], y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius[2]);
        ctx.lineTo(x, y + radius[0]);
        ctx.quadraticCurveTo(x, y, x + radius[0], y);
        ctx.closePath();
        ctx.fill()
    }

    const [energyCanvasBounds, setEnergyCanvasROBounds] = React.useState({ left: 0, top: 0, width: 0, height: 0 })
    const [energyCanvasRO] = React.useState(() => new ResizeObserver(([entry]) => setEnergyCanvasROBounds(entry.contentRect)))
    const mainChartConvertDateToPosition = (date) => {
        if (!energySubSelectionCanvasRef.current || !chartRef.current) 
            return undefined
        const view = energySubSelectionCanvasRef.current

        let width = view.width/pixelRatio
        let height = view.height/pixelRatio

        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        const mainChartPadding = chartRef.current.mainChart.getPadding()

        let chartArea = {
            x1: mainChartPadding.left, 
            x2: width - mainChartPadding.right,
            y1: mainChartPadding.top,
            y2: height - mainChartPadding.bottom - chartRef.current.mainChartDateAxis.getHeight()
        }
        chartArea.width = chartArea.x2 - chartArea.x1
        chartArea.height = chartArea.y2 - chartArea.y1
        
        const mainChartRangeDelta = mainChartVisibleRange.end - mainChartVisibleRange.start

        // Figure out the x position of both gray areas
        return ((date - mainChartVisibleRange.start) / mainChartRangeDelta) * chartArea.width
    }
    const mainChartConvertPositionToDate = (x) => {
        if (!energySubSelectionCanvasRef.current || !chartRef.current) 
            return undefined
        const view = energySubSelectionCanvasRef.current

        let width = view.width/pixelRatio
        let height = view.height/pixelRatio

        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        const mainChartPadding = chartRef.current.mainChart.getPadding()

        let chartArea = {
            x1: mainChartPadding.left, 
            x2: width - mainChartPadding.right,
            y1: mainChartPadding.top,
            y2: height - mainChartPadding.bottom - chartRef.current.mainChartDateAxis.getHeight()
        }
        chartArea.width = chartArea.x2 - chartArea.x1
        chartArea.height = chartArea.y2 - chartArea.y1
        
        const mainChartRangeDelta = mainChartVisibleRange.end - mainChartVisibleRange.start

        // Figure out the x position of both gray areas
        if (x < 0)
            x = 0
        if (x > chartArea.width)
            x = chartArea.width


        return mainChartVisibleRange.start + (x / chartArea.width) * mainChartRangeDelta


    }
    const getMainChartArea = () => {
        if (!energySubSelectionCanvasRef.current || !chartRef.current) 
            return undefined
        const view = energySubSelectionCanvasRef.current

        let width = view.width/pixelRatio
        let height = view.height/pixelRatio

        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        const mainChartPadding = chartRef.current.mainChart.getPadding()

        let chartArea = {
            x1: mainChartPadding.left, 
            x2: width - mainChartPadding.right,
            y1: mainChartPadding.top,
            y2: height - mainChartPadding.bottom - chartRef.current.mainChartDateAxis.getHeight()
        }
        chartArea.width = chartArea.x2 - chartArea.x1
        chartArea.height = chartArea.y2 - chartArea.y1

        return chartArea
    }


    const drawEnergySubselection = () =>  {
        if (!energySubSelectionCanvasRef.current || !chartRef.current) 
            return
        const view = energySubSelectionCanvasRef.current

        let width = view.width/pixelRatio
        let height = view.height/pixelRatio
        const ctx = view.getContext('2d')
        ctx.clearRect(0, 0, width, height)
        
        
        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        
        const mainChartPadding = chartRef.current.mainChart.getPadding()

        let chartArea = {
            x1: mainChartPadding.left, 
            x2: width - mainChartPadding.right,
            y1: mainChartPadding.top,
            y2: height - mainChartPadding.bottom - chartRef.current.mainChartDateAxis.getHeight()
        }
        chartArea.width = chartArea.x2 - chartArea.x1
        chartArea.height = chartArea.y2 - chartArea.y1


        if (chartRef.current.energySubselectionActive)   {
            

            // Figure out the x position of both gray areas
            let eX1 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.start)
            let eX2 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.end)


            ctx.fillStyle = "rgba(255, 180, 62, 0.2)"
            ctx.fillRect(chartArea.x1 + eX1, chartArea.y1, eX2 - eX1, chartArea.height)
            //ctx.fillRect(chartArea.x1 + eX2, chartArea.y1, chartArea.width - eX2, chartArea.height)

            ctx.strokeStyle = "rgba(255, 180, 62, 1.0)"
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(chartArea.x1 + eX2 - 1, chartArea.y1 + 1);
            ctx.lineTo(chartArea.x1 + eX2 - 1, chartArea.y1 + chartArea.height - 1);
            ctx.stroke();
            ctx.moveTo(chartArea.x1 + eX1 + 1, chartArea.y1 + chartArea.height - 1);
            ctx.lineTo(chartArea.x1 + eX1 + 1, chartArea.y1 + 1);
            ctx.stroke();
            ctx.lineWidth = 1;

            ctx.fillStyle = "rgba(255, 96, 35, 1)"
            drawRoundedRect(ctx, chartArea.x1 + eX1 + 1 - grabberWidth, chartArea.y1 + (chartArea.height / 4), grabberWidth, chartArea.height / 2, [grabberWidth, 0, grabberWidth, 0])
            drawRoundedRect(ctx, chartArea.x1 + eX2 - 1, chartArea.y1 + (chartArea.height / 4), grabberWidth, chartArea.height / 2, [0, grabberWidth, 0, grabberWidth])
        }

        if (pointerOverMainChartDate !== undefined)   {
            let pX = mainChartConvertDateToPosition(pointerOverMainChartDate)
            ctx.strokeStyle = "rgba(0, 0, 0, 1.0)"
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(chartArea.x1 + pX, chartArea.y1 + 1);
            ctx.lineTo(chartArea.x1 + pX, chartArea.y1 + chartArea.height - 1);
            ctx.stroke();
        }
    }
    const updateEnergySubselection = () =>  {
        if (!energySubSelectionCanvasRef.current) 
            return
            
        energyCanvasRO.observe(energySubSelectionCanvasRef.current)
        const view = energySubSelectionCanvasRef.current

        const ctx = view.getContext('2d')
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.clearRect(0, 0, view.width, view.height)
        resizeView(view)
        ctx.translate(0.5, 0.5);


        drawEnergySubselection()
    }

    const [overviewCanvasBounds, setOverviewCanvasROBounds] = React.useState({ left: 0, top: 0, width: 0, height: 0 })
    const [overviewCanvasRO] = React.useState(() => new ResizeObserver(([entry]) => setOverviewCanvasROBounds(entry.contentRect)))
    const overviewChartConvertDateToPosition = (date) => {
        if (!overviewSubSelectionCanvasRef.current || !chartRef.current) 
            return undefined
        const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()

        let chartArea = getOverviewChartArea()
        
        const overviewChartRangeDelta = overviewChartVisibleRange.end - overviewChartVisibleRange.start

        // Figure out the x position of both gray areas
        return (date / overviewChartRangeDelta) * chartArea.width
    }
    const overviewChartConvertPositionToDate = (x) => {
        if (!overviewSubSelectionCanvasRef.current || !chartRef.current) 
            return undefined

        const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()

        let chartArea = getOverviewChartArea()
        const overviewChartRangeDelta = overviewChartVisibleRange.end - overviewChartVisibleRange.start

        // Figure out the x position of both gray areas
        if (x < 0)
            x = 0
        if (x > chartArea.width)
            x = chartArea.width


        return overviewChartVisibleRange.start + (x / chartArea.width) * overviewChartRangeDelta


    }
    const getOverviewChartArea = () => {
        if (!overviewSubSelectionCanvasRef.current || !chartRef.current) 
            return undefined
        const view = overviewSubSelectionCanvasRef.current

        let width = view.width/pixelRatio
        let height = view.height/pixelRatio

        const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()
        const overviewChartPadding = chartRef.current.overviewChart.getPadding()

        let chartArea = {
            x1: overviewChartPadding.left, 
            x2: width - overviewChartPadding.right,
            y1: overviewChartPadding.top,
            y2: height - overviewChartPadding.bottom - chartRef.current.overviewChartDateAxis.getHeight()
        }
        chartArea.width = chartArea.x2 - chartArea.x1
        chartArea.height = chartArea.y2 - chartArea.y1

        return chartArea
    }


    const drawOverviewSubselection = () =>  {
        if (!overviewSubSelectionCanvasRef.current || !chartRef.current) 
            return
        const view = overviewSubSelectionCanvasRef.current

        let width = view.width/pixelRatio
        let height = view.height/pixelRatio
        const ctx = view.getContext('2d')
        
        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()
        const overviewChartPadding = chartRef.current.overviewChart.getPadding()

        let chartArea = {
            x1: overviewChartPadding.left, 
            x2: width - overviewChartPadding.right,
            y1: overviewChartPadding.top,
            y2: height - overviewChartPadding.bottom - chartRef.current.overviewChartDateAxis.getHeight()
        }
        chartArea.width = chartArea.x2 - chartArea.x1
        chartArea.height = chartArea.y2 - chartArea.y1
        

        // Figure out the x position of both gray areas
        let sX1 = overviewChartConvertDateToPosition(mainChartVisibleRange.start - overviewChartVisibleRange.start)
        let sX2 = overviewChartConvertDateToPosition(mainChartVisibleRange.end - overviewChartVisibleRange.start)

        ctx.clearRect(0, 0, width, height)



        if (chartRef.current.energySubselectionActive)   {
            const mainChartRangeDelta = mainChartVisibleRange.end - mainChartVisibleRange.start
            let eX1 = sX1 + (((chartRef.current.energySubSelection.start - mainChartVisibleRange.start) / mainChartRangeDelta) * (sX2 - sX1))
            let eX2 = sX1 + (((chartRef.current.energySubSelection.end - mainChartVisibleRange.start) / mainChartRangeDelta) * (sX2 - sX1))

            ctx.fillStyle = "rgba(255, 180, 62, 0.2)"
            ctx.fillRect(chartArea.x1 + eX1, chartArea.y1, eX2 - eX1, chartArea.height)

            ctx.strokeStyle = "rgba(255, 180, 62, 1.0)"
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(chartArea.x1 + eX2 - 1, chartArea.y1 + 1);
            ctx.lineTo(chartArea.x1 + eX2 - 1, chartArea.y1 + chartArea.height - 1);
            ctx.lineTo(chartArea.x1 + eX1 + 1, chartArea.y1 + chartArea.height - 1);
            ctx.lineTo(chartArea.x1 + eX1 + 1, chartArea.y1 + 1);
            ctx.stroke();
            ctx.lineWidth = 1;
        }


        ctx.fillStyle = "rgba(0,0,0,0.2)"
        ctx.fillRect(chartArea.x1, chartArea.y1, sX1, chartArea.height)
        ctx.fillRect(chartArea.x1 + sX2, chartArea.y1, chartArea.width - sX2, chartArea.height)

        ctx.strokeStyle = "rgba(0,0,0,0.5)"
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(chartArea.x1 + sX1 + 1, chartArea.y1 + 1)
        ctx.lineTo(chartArea.x1 + sX2 - 1, chartArea.y1 + 1);
        ctx.lineTo(chartArea.x1 + sX2 - 1, chartArea.y1 + chartArea.height - 1);
        ctx.lineTo(chartArea.x1 + sX1 + 1, chartArea.y1 + chartArea.height - 1);
        ctx.lineTo(chartArea.x1 + sX1 + 1, chartArea.y1 + 1);
        ctx.stroke();
        ctx.lineWidth = 1;


        if (pointerOverOverviewGrabber == "left")   {
            ctx.fillStyle = "rgba(20, 20, 20, 1)"
        }else {
            ctx.fillStyle = "rgba(50, 50, 50, 1)"
        }
        drawRoundedRect(ctx, chartArea.x1 + sX1 + 1 - grabberWidth, chartArea.y1 + (chartArea.height / 4), grabberWidth, chartArea.height / 2, [grabberWidth, 0, grabberWidth, 0])

        if (pointerOverOverviewGrabber == "right")  {
            ctx.fillStyle = "rgba(50, 20, 20, 1)"
        }else {
            ctx.fillStyle = "rgba(50, 50, 50, 1)"
        }
        drawRoundedRect(ctx, chartArea.x1 + sX2 - 1, chartArea.y1 + (chartArea.height / 4), grabberWidth, chartArea.height / 2, [0, grabberWidth, 0, grabberWidth])


    }
    const updateOverviewSubselection = () =>  {
        if (!overviewSubSelectionCanvasRef.current) 
            return
            
        overviewCanvasRO.observe(overviewSubSelectionCanvasRef.current)
        const view = overviewSubSelectionCanvasRef.current

        const ctx = view.getContext('2d')
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.clearRect(0, 0, view.width, view.height)
        resizeView(view)
        ctx.translate(0.5, 0.5);


        drawOverviewSubselection()
    }

    React.useEffect(() => {
        updateEnergySubselection()
        updateOverviewSubselection()
        return () => {
            energyCanvasRO.disconnect()
            overviewCanvasRO.disconnect()
        }
    }, [])
    updateEnergySubselection()
    updateOverviewSubselection()

    React.useEffect(() =>   {
        updateOverviewSubselection()
    }, [pointerOverOverviewGrabber])


    React.useEffect(() => {
        const mainChart = lightningChart({
            overrideInteractionMouseButtons: {
                chartXYPanMouseButton: 0,
            },
        }).ChartXY({ 
            container: "GrowManagerChartsPage_MainChart",
            theme: mainChartTheme,
        }).setMouseInteractionRectangleZoom(false)
        .setMouseInteractionRectangleFit(false)
        .setMouseInteractionWheelZoom(true)
        .setTitle("")
        .setPadding({top:0, left: 14, right:14, bottom: 0})
        .setAutoCursorMode(AutoCursorModes.disabled)

        /*mainChart.onBackgroundMouseDown((e) =>   {
            console.log(e)
        })
        mainChart.onSeriesBackgroundMouseDown((e) =>    {
            console.log(e)
        })*/
        

        mainChart.getDefaultAxisY()
            .setMouseInteractions(false)
            .setTickStrategy(AxisTickStrategies.Empty)
        
        const defaultMainChartInterval = {start: 0, end: 1000 * 60 * 60 * 24}
        const mainChartDateAxis = mainChart.getDefaultAxisX()
        mainChartDateAxis.setTickStrategy(
                AxisTickStrategies.Time,
            )
            .setAnimationsEnabled(false)
            .setChartInteractionPanByDrag(true)
            .setChartInteractionZoomByWheel(true)
            .setNibInteractionScaleByWheeling(true)
            .setInterval(defaultMainChartInterval.start , defaultMainChartInterval.end, false, false)
            .setScrollStrategy(undefined)
            .setTickStrategy(AxisTickStrategies.Empty)
            //.setInterval(new Date(new Date().setDate(new Date().getHours()-2)).getTime() , new Date(new Date().setDate(new Date().getHours()-1)).getTime(), false)
            
        mainChartDateAxis.onScaleChange((start, end) => {
            checkMainChartInterval(start, end)
        })


        let mainChartTimeTicks = []
        let lastMainChartInterval = {start: 0, end: 0}
        let lastMainChartTickRange = {start: 0, end: 0}
        mainChartTimeTicks = updateChartAxisTicks(mainChartDateAxis, mainChartTimeTicks, lastMainChartTickRange, defaultMainChartInterval.start , defaultMainChartInterval.end)

        const overviewChart = lightningChart({
            overrideInteractionMouseButtons: {
                chartXYPanMouseButton: 0,
            },
        }).ChartXY({ 
            container: "GrowManagerChartsPage_OverviewChart",
            theme: mainChartTheme,
        }).setMouseInteractionRectangleZoom(false)
        .setMouseInteractionRectangleFit(false)
        .setMouseInteractionWheelZoom(false)
        .setMouseInteractions(false)
        .setTitle("")
        .setPadding({top:0, left: 14, right: 14, bottom: 0})
        .setAutoCursorMode(AutoCursorModes.disabled)
        overviewChart.getDefaultAxisY()
            .setMouseInteractions(false)
            .setTickStrategy(AxisTickStrategies.Empty)


        const totalGrowDuration = 1000 * 60 * 60 * 24
        const overviewChartDateAxis = overviewChart.getDefaultAxisX()
            .setTickStrategy(
                AxisTickStrategies.Time,
            )
            .setMouseInteractions(false)
            .setChartInteractionPanByDrag(false)
            .setChartInteractionZoomByWheel(false)
            .setNibInteractionScaleByWheeling(false)
            .setInterval(0 , totalGrowDuration, false)
            .setScrollStrategy(undefined)
            .setTickStrategy(AxisTickStrategies.Empty)
        
        let overviewChartTimeTicks = []
        let lastOverviewChartInterval = {start: 0, end: 0}
        let lastOverviewChartTickRange = {start: 0, end: 0}
        overviewChartTimeTicks = updateChartAxisTicks(overviewChartDateAxis, overviewChartTimeTicks, lastOverviewChartTickRange, 0 , totalGrowDuration)
        
        overviewChartDateAxis.onScaleChange((start, end) => {
            checkOverviewChartInterval(start , end)
        })


        chartRef.current = { mainChart, 
            mainChartDateAxis, 
            overviewChart, 
            overviewChartDateAxis, 
            dataSeries: {},
            mainChartTimeTicks, 
            overviewChartTimeTicks,
            lastSelectedGrows: [],
            lastMainChartInterval,
            lastOverviewChartInterval,
            lastMainChartTickRange,
            lastOverviewChartTickRange,
            lastMainDataRecordingTimePeriodType: null,
            lastMainChartDeltaType: null,
            totalGrowDuration,
            lastTotalGrowDuration: 0,
            grows,
            growPositionIndicator: {},
            activeYAxes: {},
            lastEnergySubSelection: {start: 0, end: 0},
            energySubSelection: defaultMainChartInterval,
            energyTotals: {},
            energySubselectionActive,

            nutrientDosingBarsRequiresReset: true,
            nutrientDosingLargestVolume: 0,
            nutrientDosingBars: {},

            tempYAxisInterval: {},

            lastRenderLoopCompletedOn: 0,
            lastRequestLoopCompletedOn: 0
        }

        return () => {
            mainChart.dispose()
            overviewChart.dispose()
            mainChartTimeTicks.filter(tick => {
                tick.dispose()
                    return false
            })
            mainChartTimeTicks.filter(tick => {
                tick.dispose()
                    return false
            })
            lastMainChartTickRange = {start: 0, end: 0}
            lastOverviewChartTickRange = {start: 0, end: 0}
          chartRef.current = undefined
        }
      }, [mainChartTheme])


      React.useEffect(() => {
        if (!chartRef.current) 
            return

        const mainChartVisibleRange = chartRef.current.mainChartDateAxis.getInterval()
        const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()
        chartRef.current.mainChartTimeTicks = updateChartAxisTicks(chartRef.current.mainChartDateAxis, chartRef.current.mainChartTimeTicks, chartRef.current.lastMainChartTickRange, mainChartVisibleRange.start , mainChartVisibleRange.end)
        chartRef.current.overviewChartTimeTicks = updateChartAxisTicks(chartRef.current.overviewChartDateAxis, chartRef.current.overviewChartTimeTicks, chartRef.current.lastOverviewChartTickRange, overviewChartVisibleRange.start , overviewChartVisibleRange.end)


      }, [chartRef])

      React.useEffect(() => {
        if (!chartRef.current) 
            return
        chartRef.current.grows = grows

      }, [grows])

      React.useEffect(() => {
        if (!chartRef.current) 
            return
        chartRef.current.energySubselectionActive = energySubselectionActive
        updateEnergySubselection()
        updateOverviewSubselection()

    }, [energySubselectionActive])
  




    let energySubSelectionStyleProps = {}
    if ((pointerOverMainEnergyGrabber === undefined && !draggingMainEnergyGrabber && pointerOverDosingInstance === undefined) || numberOfPointersDownOnMainCanvas > 1)    {
        energySubSelectionStyleProps["pointerEvents"] = "none"
    }

    return (
        <div id="GrowManagerChartsPage" {...chartsContainerBind}>
            <div id="GrowManagerChartsPage_ChartingArea"
                className="noselect"
                ref={chartingAreaRef}
                onPointerMove={chartingAreaPointerMove}
                onPointerDown={chartingAreaPointerDown}
                onPointerUp={chartingAreaPointerUp}
                onPointerLeave={chartingAreaPointerLeave}>
                <div id="GrowManagerChartsPage_MainChartingArea">
                    <div id="GrowManagerChartsPage_MainChartingWrapper" {...mainChartAreaBind}>
                        <div id="GrowManagerChartsPage_MainChartingContent">
                            <div id="GrowManagerChartsPage_MainChart"></div>
                            <canvas id="GrowManagerChartsPage_MainChart-SubselectedEnergyArea" 
                            ref={energySubSelectionCanvasRef}
                            style={energySubSelectionStyleProps}/>
                        </div>
                    </div>
                    <div id="GrowManagerDataToggles">
                        {Object.entries(dataTypeToggles).map(([key, info]) => {
                            return (
                                <GrowManagerDataTypeToggle name={key} key={key} info={info} onGroupToggle={dataTypeGroupToggled} onDataTypeToggle={dataTypeToggled}/> 
                            ) 
                        })}
                        <GrowManagerEnergyDataTypeToggle 
                            updateEnergyDataCallback={(energyDataUpdateFunc) => energyDataTypeToggleDataUpdate.current = energyDataUpdateFunc}
                            onEnergySubselectionToggle={energySubselectionToggled}
                            energySubselectionActive={energySubselectionActive}/>
                    </div>
                </div>
                <div id="GrowManagerChartsPage_OverviewChartingArea" {...overviewChartAreaBind}>
                    <div id="GrowManagerChartsPage_OverviewChartingWrapper">
                        <div id="GrowManagerChartsPage_OverviewChartingContent">
                            <div id="GrowManagerChartsPage_OverviewChart"
                                style={{cursor: overviewCursor}}></div>
                            <canvas id="GrowManagerChartsPage_OverviewChart-SubselectedArea" ref={overviewSubSelectionCanvasRef}/>
                        </div>
                    </div>
                </div>
            </div>
            <div id="GrowManagerChartsPage_DrawingBoard">
                {drawGrowEvents()}
                {dosingInstanceSelected !== undefined && drawDosingInstanceTooltip()}
                {pointerOverMainChartDate !== undefined && drawTooltip()}
            </div>
        </div>
    ); 
  }

  export default GrowManagerChartsPage 