import './CreateRecipePopup.scss';
import React from 'react';

import PopupModal from '../../../model_components/PopupModal'
import { useSelector, useDispatch} from 'react-redux'

import NumberInput from '../../../components/input/NumberInput';
import TextInput from '../../../components/input/TextInput';
import DropDownInput from '../../../components/input/DropDownInput';
import ControlBar from '../../../components/ControlBar';
import Button from '../../../components/Button';
  

const CreateRecipePopup = ({completeCallback, closeCallback}) => {
    const closePopup = (force) =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }

    const dispatch = useDispatch()
    /*React.useEffect(() => {
        //dispatch to get all models we need for this popup
    }, [])*/


    const timelineSteps = {
        
    }
    const [currentTimelineStep, SetCurrentTimelineStep] = React.useState("name")
    const timelineStepChanged = (key) =>    {
        if (currentTimelineStep !== key)    {
            SetCurrentTimelineStep(key)
        }
    }
    
    const previousStepClicked = () =>   {
        switch (currentTimelineStep)    {
            default:
                break
        }
    }

    const [recipeName, SetRecipeName] = React.useState("")
    const onRecipeNameChanged = (value) =>    {
        SetRecipeName(value)
    }
    const [starterName, SetStarterName] = React.useState("")
    const onStarterNameChanged = (value) =>    {
        SetStarterName(value)
    }

    const [completedRequiredDetails, SetCompletedRequiredDetails] = React.useState(false)
    React.useEffect(() => {
        if (recipeName.length === 0)  {
            SetCompletedRequiredDetails(false)
            return
        }
        if (starterName.length === 0)  {
            SetCompletedRequiredDetails(false)
            return
        }
        SetCompletedRequiredDetails(true)
    }, [recipeName, starterName])
    
    const finalizeRecipeClicked = (e) =>  {
        let newRecipe = {
            name: recipeName,
            starter_product_id: starterName
        }
        if (completeCallback !== undefined) {
            completeCallback(newRecipe)
        }
    }


    let headerDescription = ""
    let footerContent = (<></>)
    switch (currentTimelineStep)    {
        case "name": 
            headerDescription = "What do you want to name it?"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
                </>)} secondaryControls={(<>
                    {completedRequiredDetails && <Button content={"Create Recipe"} onClick={finalizeRecipeClicked}/>}
                </>)}/>
            )
            break
        default: 
            break
    }


    return (
        <PopupModal 
            title="Create Recipe"
            size="full"
            closeCallback={closePopup}
            /*timelineSteps={suggestionsActive ? alternativeTimelineSteps : standardTimelineSteps} 
            timelineStep={currentTimelineStep} 
            onStepChange={timelineStepChanged}*/
            footer={footerContent}>
            <div className="CreateRecipePopup-Content">
                {currentTimelineStep === "name" && 
                
                    <div className="CreateRecipePopup-DetailsForm">
                        <div className="PopupModal-FormItem">
                            <div className="PopupModal-FormItem-Question">
                                What do you want to name your recipe?
                            </div>
                            <div className="PopupModal-FormItem-Answer">
                                <TextInput value={recipeName} placeHolder="" onChange={onRecipeNameChanged}/>
                            </div>
                        </div>
                        <div className="PopupModal-FormItem">
                            <div className="PopupModal-FormItem-Question">
                                What do you want to start the recipe with?
                            </div>
                            <div className="PopupModal-FormItem-Answer">
                                <TextInput value={starterName} placeHolder="" onChange={onStarterNameChanged}/>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </PopupModal>
    )
} 

export default CreateRecipePopup