import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'


const processTaskFromAPI = (task) =>  {
  if (task.completed) {
    task.status = "Completed"
  }
  task.startDate = new Date(task.scheduled_for).getTime()
  /*task.endDate = new Date(task.end_date).getTime()
  task.dueDate = task.endDate
  task.duration = task.endDate - task.startDate*/
  if (task.duration < 3600) {
    task.duration = 3600
  }
  //task.key = task.id
  task.category = "test"
  return task
}

export const getAllTasks = createAsyncThunk('tasks/getAllTasks', async () => {
  return await FetchPost(APIRepository.Tasks.GetAllTasksForAccount, {
    AccountId: 29,
    HaveTasks: {}
  })  
})

export const markTaskAsStarted = createAsyncThunk('tasks/markTaskAsStarted', async ({taskId}, thunkAPI) => {
  return await FetchPost(APIRepository.Tasks.MarkTaskAsStarted, {
    account_id: 29,
    task_id: taskId
  })
})


export const completeTaskStep = createAsyncThunk('tasks/completeTaskStep', async ({taskId, step, params}, thunkAPI) => {
  return await FetchPost(APIRepository.Tasks.CompleteTaskStep, {
    account_id: 29,
    task_id: taskId,
    step: step,
    params: params
  })
})

export const setTaskStep = createAsyncThunk('tasks/setTaskStep', async ({taskId, step, params}, thunkAPI) => {
  return await FetchPost(APIRepository.Tasks.SetTaskStep, {
    account_id: 29,
    task_id: taskId,
    step: step,
    params: params
  })
})

export const markTaskAsCompleted = createAsyncThunk('tasks/markTaskAsCompleted', async ({taskId, params}, thunkAPI) => {
  return await FetchPost(APIRepository.Tasks.MarkTaskAsCompleted, {
    account_id: 29,
    task_id: taskId,
    params: params
  })
})

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasks:  [

    ],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false
  },
  reducers: {
    
    setTaskLayoutBounds: (state, action) =>  {
      
      let hasChanged = false
      let newTasks = { ...state, tasks: state.tasks.map((task) => {
        if (task.id !== action.payload.taskId) {
          return task
        }
    
        if (task.bounds === undefined || task.bounds.y !== action.payload.bounds.y || task.bounds.height !== action.payload.bounds.height)  {
          hasChanged = true
        }
        return {
          ...task,
          bounds: action.payload.bounds
        }
      })}

      if (hasChanged) {
        return newTasks
      }
    },

    taskUpdate: (state, action) => {
      let hasChanged = false
      let newTasks = { ...state, tasks: state.tasks.map((task, index) => {
        if (task.id !== action.payload.taskId) {
          return task
        }
    
        if (task[action.payload.prop] === undefined || task[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...task,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newTasks
      }
    }
  },
  extraReducers: {
    [getAllTasks.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllTasks.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      action.payload.tasks.map(function(task){ processTaskFromAPI(task); return task });
      state.tasks = action.payload.tasks;
    },

    [getAllTasks.rejected]: (state) => {
      state.status = 'rejected';
    },

    [markTaskAsStarted.pending]: (state) => {
      state.status = 'pending';
    },

    [markTaskAsStarted.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      let foundTask = state.tasks.find((t) => t.id === action.meta.arg.taskId)
      if (foundTask !== undefined)  {
        foundTask.started = true
      }
    },

    [markTaskAsStarted.rejected]: (state) => {
      state.status = 'rejected';
    },


    [setTaskStep.pending]: (state) => {
      state.status = 'pending';
    },

    [setTaskStep.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      let foundTask = state.tasks.find((t) => t.id === action.meta.arg.taskId)
      if (action.payload.success) {
        foundTask.current_step = action.meta.arg.step
      }
    },

    [setTaskStep.rejected]: (state) => {
      state.status = 'rejected';
    },

    
    [completeTaskStep.pending]: (state) => {
      state.status = 'pending';
    },

    [completeTaskStep.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      let foundTask = state.tasks.find((t) => t.id === action.meta.arg.taskId)
      if (action.payload.success && action.payload.step) {
        foundTask.current_step = action.payload.step
      }
    },

    [completeTaskStep.rejected]: (state) => {
      state.status = 'rejected';
    },


    [markTaskAsCompleted.pending]: (state) => {
      state.status = 'pending';
    },

    [markTaskAsCompleted.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      let foundTask = state.tasks.find((t) => t.id === action.meta.arg.taskId)
      if (foundTask !== undefined)  {
        foundTask.completed = true
      }
    },

    [markTaskAsCompleted.rejected]: (state) => {
      state.status = 'rejected';
    }
    
  }
})



// Action creators are generated for each case reducer function
export const { taskUpdate, setTaskLayoutBounds} = tasksSlice.actions

export default tasksSlice.reducer

export const selectAllTasks = state => state.tasks.tasks

export const selectTaskById = (state, taskId) =>
  state.tasks.tasks.find(task => task.id === taskId)