import './TasksPage.scss';
import React from 'react';


import Badge from '../../components/Badge.js'
import { FormatDate } from '../../helpers'
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import Button from '../../components/Button';
import { markTaskAsCompleted } from '../../redux/entities/Tasks';
import TaskPopup_PlantSeeds from './TaskPopups/TaskPopup_PlantSeeds';
import TaskPopup_MoveToGermination from './TaskPopups/TaskPopup_MoveToGermination'; 
import TaskPopup_TransplantToGrowZone from './TaskPopups/TaskPopup_TransplantToGrowZone';
import TaskPopup_NDSSensorCalibration from './TaskPopups/TaskPopup_NDSSensorCalibration';
import TaskPopup_Harvest from './TaskPopups/TaskPopup_Harvest';
import TaskPopup_GrowZoneCleanOut from './TaskPopups/TaskPopup_GrowZoneCleanOut';
import TaskPopup_NDSNutrientTopUp from './TaskPopups/TaskPopup_NDSNutrientTopUp';
  

const TaskPopup = () => {
  let data = useSelector((state) => state.tasks)
  let tasks = data.tasks


  const { taskId } = useParams();
  let task = tasks.find(e => e.id.toString() === taskId.toString())


  const navigate = useNavigate();
  const dispatch = useDispatch()
  React.useLayoutEffect(() => {
    if (task === undefined || (!/\d+/.test(taskId))) {
      navigate(-1);
    }
  }, [taskId, navigate, task]);

 
  const closePopup = () =>  {
    navigate(-1);
  }

  const completeTaskClicked = React.useCallback(() => {
    dispatch(markTaskAsCompleted({taskId: task.id}))
    navigate(-1);
  })

  if (task === undefined) {
    return (<></>) //Change to loading in popup view
  }else {
    switch (task.key) {
      case "grow_plant_seeds":
        return <TaskPopup_PlantSeeds task={task} onClosePopup={closePopup}/>
      case "grow_germinate_for_duration": 
        return <TaskPopup_MoveToGermination task={task} onClosePopup={closePopup}/>
      case "grow_transplant_to_grow_zone":
        return <TaskPopup_TransplantToGrowZone task={task} onClosePopup={closePopup}/>
      case "grow_harvest":
        return <TaskPopup_Harvest task={task} onClosePopup={closePopup}/>
      case "grow_grow_zone_clean_out":
        return <TaskPopup_GrowZoneCleanOut task={task} onClosePopup={closePopup}/>
      case "nds_sensor_calibration":
        return <TaskPopup_NDSSensorCalibration task={task} onClosePopup={closePopup}/>
      case "nds_nutrient_top_up":
        return <TaskPopup_NDSNutrientTopUp task={task} onClosePopup={closePopup}/>
        default:
        return (
          <div className="Popup_Standard" id="Task_Popup" onClick={closePopup}>
            <div className="Popup_Standard-Modal" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
              <div className="Popup_Standard-Modal_Header">
                <div className="Popup_Standard-Modal_HeaderContent">
                  <div className="t_DisplayMedium">
                    {task.name}
                  </div>
                </div>
                <div className="Popup_Standard-Modal_Close" onClick={closePopup}>
                  <MdClose/>
                </div>
              </div>
              Task type unknown
              <Button status="Primary" content="Complete task" onClick={completeTaskClicked}/>
            </div>
          </div>
        )
    }
  }
} 

export default TaskPopup