import './FarmsPage.scss';

import React from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';
import DropDownInput from '../../components/input/DropDownInput.js'
import Button from '../../components/Button.js'

import ExpensesPage from '../Expenses/ExpensesPage';
import RevenuePage from '../Revenue/RevenuePage';
import InventoryPage from '../Inventory/InventoryPage';
import RecordsPage from '../Records/RecordsPage';
import ServicesPage from '../Services/ServicesPage';
import { getAllFacilitiesForAccount, selectAllFacilities } from '../../redux/entities/Facilities.js';
import { useDispatch, useSelector } from 'react-redux';


const FarmsPage = ({pageHeaderHelper}) => {

  
  const { farmID } = useParams();

  const location = useLocation()
  const navigate = useNavigate(); 
  const dispatch = useDispatch()
  const validateLocation = () =>  {
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    if (splitLocation.length <= 2 || Number.isNaN(splitLocation[2]))  {
      navigate("/farms/all/expenses")
    }else if (splitLocation[2] !== "all" && Number.isNaN(parseInt(splitLocation[2]))) {
      if (splitLocation.length <= 3) {
        navigate("/farms/all/expenses")
      }else {
        navigate("/farms/all/" + splitLocation[3])
      }
    }else {
      SetSelectedFacility(splitLocation[2] === "all" ? splitLocation[2] : parseInt(splitLocation[2]))
    }
  }

  React.useEffect(() => {
    validateLocation()
  }, [location])
  React.useLayoutEffect(() => {
    validateLocation()
  }, [])



  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const selectedTab = splitLocation.length >= 3 ? splitLocation[3] : null
  let facilities = useSelector(selectAllFacilities)
  let haveInitialData = useSelector((state) => state.facilities.haveInitialData)

  React.useEffect( () =>  { 
    //fetchTasks
    if (!haveInitialData) {
      dispatch(getAllFacilitiesForAccount({accountId: 29}))
    }
  }, [facilities, haveInitialData])



  const [selectedFacility, SetSelectedFacility] = React.useState(farmID === "all" ? farmID : parseInt(farmID));
  const [facilitySelectionOptions, SetFacilitySelectionOptions] = React.useState([]);
  const selectedFacilityChanged = (value) => {
    const { pathname } = location;
    let splitLocation = pathname.split("/");
    splitLocation[2] = value
    navigate(splitLocation.join("/"))
    //SetIsAllSelected(value === "all")
    //SetSelectedFacility(value)
  }
  
  React.useEffect(() => {
    //Check location to see if we are in services, if so we need to limit the options to not include all farms
    let currentFacilitySelectionOptions = selectedTab === "services" ? [] : [{value: "all", label: "All Farms"}]
    currentFacilitySelectionOptions.push({value: 2, label: "Test"})
    for (let facility of facilities)  {
      currentFacilitySelectionOptions.push({
        value: facility.id,
        label: facility.name
      })
    }

    if (selectedTab === "services" && selectedFacility === "all") {
      if (currentFacilitySelectionOptions.length > 0) {
        selectedFacilityChanged(currentFacilitySelectionOptions[0].value)
      }else {
        //Uh oh
      }
    }

    SetFacilitySelectionOptions(currentFacilitySelectionOptions)
  }, [facilities, location])




  
  const tabs = [new TabControlTab("Expenses", "expenses", true),
    new TabControlTab("Revenue", "revenue"),
    new TabControlTab("Inventory", "inventory"),
    new TabControlTab("Records", "records"),
    new TabControlTab("Services", "services")]
  
  const tabControlTabClickedRef = React.useRef(null);

  const setPageHeader = () => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Farms")
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              <DropDownInput options={facilitySelectionOptions} defaultValue={selectedFacility} onSelectionChange={selectedFacilityChanged}/>
              {/*<Button content={"Create Farm"}/>*/}
            </div>
          </div>
        </>
      )
    })
    pageHeaderHelper.current.SetTabControlTabs(tabs);
    pageHeaderHelper.current.SetTabControlTabClickCallback((tab) => {
      if (tabControlTabClickedRef.current) {
        tabControlTabClickedRef.current(tab);
      }
    });
  }
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [])
  React.useEffect(() => {
    setPageHeader()
  }, [facilitySelectionOptions])


  return (
    <div className="PageWrapper_Standard">
      <TabControl activePathIndex={3} tabs={tabs}
        hideTabs={true}
        tabClickedRef={(ref) => {tabControlTabClickedRef.current=ref;}}
        content={
          <div id="FarmsPage">
            
            <div id="FarmsPage_TabContent">
              <Routes>
                <Route path="/services/*" element={
                  <ServicesPage setPageHeader={setPageHeader} selectedFacility={selectedFacility}/>
                }/>
                <Route exact path="/expenses" element={
                 <ExpensesPage setPageHeader={setPageHeader} selectedFacility={selectedFacility}/>
                }/>
                <Route path="/revenue" element={
                  <RevenuePage setPageHeader={setPageHeader} selectedFacility={selectedFacility}/>
                }/>
                <Route path="/inventory/*" element={
                  <InventoryPage setPageHeader={setPageHeader} selectedFacility={selectedFacility}/>
                }/>
                
                <Route path="/records" element={
                  <RecordsPage setPageHeader={setPageHeader} selectedFacility={selectedFacility}/>
                }/>
                <Route path="*" element={<Navigate to="expenses" replace />} />
              </Routes>
            </div>
          </div>
        }/>
    </div>
  )
} 

export default FarmsPage