import './GrowZones.scss';
import React from 'react'


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getAllServicesForFacility, selectAllFacilities } from '../../../redux/entities/Facilities';
import { getVerticalRackZoneConfigurationMap, selectAllVerticalRackGroups, selectAllVerticalRackZoneConfigurationMaps } from '../../../redux/entities/service/VerticalRackGroup';
import ControlBar from '../../../components/ControlBar';
import GrowZoneDataAnalytics from './DataAnalytics';

















const GrowZoneStatus = ({selectedFacility, setSecondaryControls}) => {
  
  React.useLayoutEffect(() => {
    setSecondaryControls((() => {
      return (
        <></>
      )
    }))
  }, [])

  const dispatch = useDispatch()

  const facilities = useSelector(selectAllFacilities)

  const facilityInfo = useSelector(state => state.facilities.facilities.find((f) => f.id === selectedFacility))
  const allVerticalRackGroups = useSelector(selectAllVerticalRackGroups)

  const [verticalRackGroups, SetVerticalRackGroups] = React.useState([])
  React.useEffect(() => {
    SetVerticalRackGroups(allVerticalRackGroups.filter((vRG) => facilityInfo !== undefined && facilityInfo.services.find((s) => s.id === vRG.service_id) !== undefined))
  }, [selectedFacility])


  const [growOutZones, SetGrowOutZones] = React.useState([])
  React.useEffect(() => {
    let currentGrowOutZones = []
    for (let verticalRackGroup of verticalRackGroups) {
      if (verticalRackGroup.zone_map) {
        currentGrowOutZones.push(...verticalRackGroup.zone_map.standard_grow_out_zones)
      }
    }
    SetGrowOutZones([...currentGrowOutZones])
  }, [verticalRackGroups])
 

  const verticalRackZoneConfigurationMaps = useSelector(selectAllVerticalRackZoneConfigurationMaps)
  React.useEffect(() => {
    //We need to make sure we have all the racks configuration maps for components/io
    let mapsToLoad = {}
    for (let verticalRackGroup of verticalRackGroups) {
      if (verticalRackGroup.zone_map) {
        for (let growOutZone of verticalRackGroup.zone_map.standard_grow_out_zones)  {
          let foundVerticalRackZoneConfigurationMap = verticalRackZoneConfigurationMaps.find((cM) => cM.id === growOutZone.configuration_id)
          if (foundVerticalRackZoneConfigurationMap === undefined)  {
            mapsToLoad[growOutZone.configuration_id] = {"component_map": null, "analytics_categories": null}
          }
        }
      }
    }

    if (Object.entries(mapsToLoad).length > 0)  {
      dispatch(getVerticalRackZoneConfigurationMap({maps: mapsToLoad}))
    }

  }, [verticalRackGroups, verticalRackZoneConfigurationMaps])



  return (<>
    <div id="GrowZones-Status">
      <ControlBar controls={<>
        
        </>}
        secondaryControls={<>
        
        </>}
      />
      <div className="GrowZones-Status-ZoneTypes">
        <div className="GrowZones-Status-ZoneType">
          <div className="GrowZones-Status-ZoneType-Header">
            Standard Grow Out
          </div>
          <div className="GrowZones-Status-ZoneType-Zones">
            {growOutZones.map((growOutZone) => {

              return (
                <div key={growOutZone.id}
                  className="GrowZones-Status-GrowOutZone">
                    <div className="GrowZones-Status-GrowOutZone-Header">
                      <div className="GrowZones-Status-GrowOutZone-Name">
                        {growOutZone.display_name}
                      </div>
                      <div className="GrowZones-Status-GrowOutZone-StatusIndicator" style={{backgroundColor:"#f00"}}/>
                  
                    </div>
                    <div className="GrowZones-Status-GrowOutZone-Content">
                      <GrowZoneDataAnalytics selectedFacility={selectedFacility} growOutZone={growOutZone}/>
                    </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  </>)
} 

export default GrowZoneStatus