import './../ManageRackWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackPowerOnTutorial, RackStandardGroup, ScanRack } from '../../../../assets/icons/RackIcons';
import { useSelector } from 'react-redux';
import { selectAllVerticalRackGroups } from '../../../../redux/entities/service/VerticalRackGroup';


  

const ConfirmNewRackWalkthroughStep = ({rackName, rackGroupId, rackIndex, rackSides, zoneChanges, transitionToPreviousStep, transitionToNextStep}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const verticalRackGroups = useSelector(selectAllVerticalRackGroups)
    let selectedVerticalRackGroup = verticalRackGroups.find((vRG) => vRG.id === rackGroupId)


    const backClicked = React.useCallback(() => {
        transitionToPreviousStep(["manage_zone_changes", zoneChanges.length])
    })
    const confirmChangesClicked = React.useCallback(() => {
        //transitionToNextStep(["", null])
    })





    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header">
                <div className="Walkthrough-Card-HeaderContent">
                    Review Rack Info
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    <div className="FlexContent-Center" style={{flexGrow:0.5, flexShrink: 1}}>
                        <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                            <div className="Button-Large-Icon"><RackStandardGroup maxWidth={(isTablet || isDesktop) ? 300 : 140} width={"100%"}/></div>
                        </>}/>
                    </div>
                    <div className="FlexContent-5 FlexContent-HFill" style={{flexGrow:0.5, flexShrink:0}}>
                        <div className="FlexContent-40 FlexContent-Center FlexContent-OverflowY">
                            <div className="FlexContent-10 FlexContent-Left">
                                <div className="FlexContent-H-40">
                                    <span style={{fontWeight: 500}}>Name</span><span>{rackName}</span>
                                </div>
                                <div className="FlexContent-H-40">
                                    <span style={{fontWeight: 500}}>Group</span><span>{selectedVerticalRackGroup.display_name}</span>
                                </div>
                                <div className="FlexContent-H-40">
                                    <span style={{fontWeight: 500}}>Location</span><span>Position {rackIndex}</span>
                                </div>
                                {Object.entries(rackSides).map(([side, sideKey]) => {
                                    if (side === "left")    {
                                        switch (sideKey)    {
                                            case "nursery":
                                                return (
                                                    <div className="FlexContent-H-40">
                                                        <span style={{fontWeight: 500}}>Front</span><span>Nursery</span>
                                                    </div>
                                                )
                                            default:
                                                return <></>
                                        }
                                    }else if (side === "right") {
                                        switch (sideKey)    {
                                            case "empty":
                                                return (
                                                    <div className="FlexContent-H-40">
                                                        <span style={{fontWeight: 500}}>Back</span><span>Nothing</span>
                                                    </div>
                                                )
                                            default:
                                                return <></>
                                        }
                                    }else if (side === "dual")  {
                                        
                                    }
                                })}
                            </div>
                            <div className="FlexContent-10 FlexContent-Left">
                                {zoneChanges.map((zoneChange) => {
                                    switch (zoneChange.type) {
                                        case "new_zone":
                                            return (<>
                                                <div className="FlexContent-H-40">
                                                    <span style={{fontWeight: 500}}>{zoneChange["zone_name"]}</span><span>Created</span>
                                                </div>
                                            </>)
                                        case "decommission_zone":
                                            return (<>
                                                <div className="FlexContent-H-40">
                                                    <span style={{fontWeight: 500}}>Placeholder Name</span><span>Decommission</span>
                                                </div>
                                            </>)
                                        default:
                                            return <></>
                                    }
                                    return (<>

                                    </>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content="Back" onClick={backClicked} status={"Neutral"} size={isMobile ? "Medium" : "Large"} width="Flex50"/>
                <Button content="Finalize" onClick={confirmChangesClicked} status={"Primary"} size={isMobile ? "Medium" : "Large"} width="Flex50"/>
            </div>
        </div>
    )
}

  


export default ConfirmNewRackWalkthroughStep