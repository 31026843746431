import './CreateGrowPlanPopup.scss';
import React from 'react';

import Button from '../../../components/Button';
import TextInput from '../../../components/input/TextInput';
import NumberInput from '../../../components/input/NumberInput';
import DropDownInput from '../../../components/input/DropDownInput';
import ControlBar from '../../../components/ControlBar';
import PopupModal from '../../../model_components/PopupModal'
import SelectPlantTypes from '../../../model_components/SelectPlantTypes.js'
import SelectPlantVarieties from '../../../model_components/SelectPlantVarieties';
import SelectRecipes from '../../../model_components/SelectRecipes';
import {SelectRaftType} from '../../../model_components/RaftType';
import { FormatDate } from '../../../helpers'
import { useSelector, useDispatch} from 'react-redux'

  

const CreateGrowPlanPopup = ({completeCallback, closeCallback}) => {
    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }

    const [currentTimelineStep, SetCurrentTimelineStep] = React.useState("type")
    const timelineSteps = {
        type: { render: (<span>Type</span>) },
        variety: { render: (<span>Variety</span>) },
        recipe: { render: (<span>Recipe</span>) },
        details: { render: (<span>Details</span>) },
    }
    const timelineStepChanged = (key) =>    {
        if (currentTimelineStep !== key)    {
            SetCurrentTimelineStep(key)
        }
    }
    const previousStepClicked = () =>   {
        switch (currentTimelineStep)    {
            case "variety":
                SetCurrentTimelineStep("type")
                break
            case "recipe":
                SetCurrentTimelineStep("variety")
                break
            case "details":
                SetCurrentTimelineStep("recipe")
                break
            default:
                break
        }
    }

    const [selectedPlantType, SetSelectedPlantType] = React.useState(undefined)
    const plantTypeSelected = (plantType) =>    {
        SetSelectedPlantType(plantType)
    }
    const switchToVarietySelectionClicked = () =>    {
        if (selectedPlantType !== undefined) {
            SetCurrentTimelineStep("variety")
        }
    }

    const [selectedPlantVariety, SetSelectedPlantVariety] = React.useState(undefined)
    const plantVarietySelected = (plantVariety) =>    {
        SetSelectedPlantVariety(plantVariety)
    }
    const switchToRecipeSelectionClicked = () =>    {
        if (selectedPlantVariety !== undefined) {
            SetCurrentTimelineStep("recipe")
        }
    }

    const [selectedRecipe, SetSelectedRecipe] = React.useState(undefined)
    const recipeSelected = (recipe) =>    {
        SetSelectedRecipe(recipe)
    }
    const switchToDetailsSelectionClicked = () =>    {
        if (selectedRecipe !== undefined) {
            SetCurrentTimelineStep("details")
        }
    }


    const [planName, SetPlanName] = React.useState("")
    const onPlanNameChanged = (value) =>    {
        SetPlanName(value)
    }
    const [raftType, SetRaftType] = React.useState(undefined)
    const onRaftTypeSelected = (raftType) =>    {
        SetRaftType(raftType)
    }
    const [estimatedYield, SetEstimatedYield] = React.useState(0)
    const onEstimatedYieldChanged = (value) =>    {
        SetEstimatedYield(value)
    }
    const [selectedUnit, SetSelectedUnit] = React.useState("weight")
    const onUnitSelectionChanged = (value) =>    {
        SetSelectedUnit(value)
    }
    const [planNotes, SetPlanNotes] = React.useState("")
    const onPlanNotesChanged = (value) =>    {
        SetPlanNotes(value)
    }
    const [completedRequiredDetails, SetCompletedRequiredDetails] = React.useState(false)
    
    React.useEffect(() => {
        if (planName === "")    {
            SetCompletedRequiredDetails(false)
            return
        }
        if (raftType === undefined) {
            SetCompletedRequiredDetails(false)
            return
        }
        

        SetCompletedRequiredDetails(true)
    }, [planName, raftType])

    const finalizePlanClicked = (e) =>  {
        let newGrowPlan = {
            name: planName, 
            plant_type_id: selectedPlantType.id,
            plant_variety_id: selectedPlantVariety.id,
            recipe_id: selectedRecipe.id,
            recipe_version: selectedRecipe.version,
            estimated_yield: parseFloat(estimatedYield),
            yield_unit_type: selectedUnit,
            raft_type_id: raftType.id,
            notes: planNotes
        }

        if (completeCallback !== undefined) {
            completeCallback(newGrowPlan)
        }
    }

    let headerDescription = undefined
    let footerContent = (<></>)
    switch (currentTimelineStep)    {
        case "type": 
            headerDescription = "Select a plant type"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
                </>)} secondaryControls={(<>
                    {selectedPlantType && <Button content={"Next Step"} onClick={switchToVarietySelectionClicked}/>}
                </>)}/>
            )
            break
        case "variety": 
            headerDescription = "Select a plant variety"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Previous Step"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {selectedPlantVariety && <Button content={"Next Step"} onClick={switchToRecipeSelectionClicked}/>}
                </>)}/>
            )
            break
        case "recipe":
            headerDescription = "Select a recipe"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Previous Step"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {selectedRecipe && <Button content={"Next Step"} onClick={switchToDetailsSelectionClicked}/>}
                </>)}/>
            )
            break
        case "details":
            headerDescription = "Plan details"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Previous Step"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {completedRequiredDetails && <Button content={"Finalize Plan"} onClick={finalizePlanClicked}/>}
                </>)}/>
            )
            break
        default: 
            break
    }



    return (
        <PopupModal 
            title="Create Grow Plan"
            description={headerDescription}
            size="full"
            closeCallback={closePopup}
            timelineSteps={timelineSteps} 
            timelineStep={currentTimelineStep} 
            onStepChange={timelineStepChanged}
            footer={footerContent}>
            {currentTimelineStep === "type" && 
                <SelectPlantTypes selectedPlantType={selectedPlantType} onPlantTypeSelection={plantTypeSelected}/>
            }
            {currentTimelineStep === "variety" && 
                <SelectPlantVarieties selectedPlantVariety={selectedPlantVariety} onPlantVarietySelection={plantVarietySelected}/>
            }
            {currentTimelineStep === "recipe" && 
                <SelectRecipes selectedRecipe={selectedRecipe} onRecipeSelection={recipeSelected}/>
            }
            {currentTimelineStep === "details" &&  
                <div className="CreateGrowPlanPopup-DetailsForm">
                    <div className="PopupModal-FormItem">
                        <div className="PopupModal-FormItem-Question">
                            What do you want to name your grow plan?
                        </div>
                        <div className="PopupModal-FormItem-Answer">
                            <TextInput value={planName} placeHolder="" onChange={onPlanNameChanged}/>
                        </div>
                    </div>
                    
                    <div className="CreateGrowPlanPopup-RaftSelection-Container">
                        <div className="CreateGrowPlanPopup-RaftSelection-Label">Raft Type</div>
                        <SelectRaftType selectedRaftType={raftType} onRaftTypeSelect={onRaftTypeSelected}/>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", gap: 8}}>
                        <NumberInput label={"Estimated Yield"} value={estimatedYield} suffix={(selectedUnit === "weight" ? "kg" : "units")} onChange={onEstimatedYieldChanged}/>
                        <DropDownInput label={"Unit"} options={[{ value: 'weight', label: 'Weight' }, { value: 'plant', label: 'Plant' }]} value={selectedUnit} onSelectionChange={onUnitSelectionChanged}/>
                    </div>
                    <div className="PopupModal-FormItem">
                        <div className="PopupModal-FormItem-Question">
                            Notes
                        </div>
                        <div className="PopupModal-FormItem-Answer">
                            <TextInput multiline={true} label={"Notes"} value={planNotes} placeHolder="" onChange={onPlanNotesChanged}/>
                        </div>
                    </div>

                    
                </div>
            }
        </PopupModal>
    )
} 

export default CreateGrowPlanPopup