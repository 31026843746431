import './DiscardRecipeChangesPopup.scss';
import React from 'react';
import PopupModal from '../../../model_components/PopupModal';
import Button from '../../../components/Button';
import ControlBar from '../../../components/ControlBar';


  

const DiscardRecipeChangesPopup = ({completeCallback, closeCallback}) => {
    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }
    const confirmedCancelClicked = (e) =>  {
        

        if (completeCallback !== undefined) {
            completeCallback()
        }
    }

    return (
        <PopupModal
            title="Confirm Discard Changes"
            closeCallback={closePopup}
            footer={
                <ControlBar controls={(<>
                    <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
                </>)} secondaryControls={(<>
                    <Button content={"Confirm Discard"} onClick={confirmedCancelClicked}/>
                </>)}/>
            }>
            Are you sure you want to discard all changes made?
        </PopupModal>
    )
} 

export default DiscardRecipeChangesPopup