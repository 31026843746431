import './Walkthrough.scss';
import React from 'react';

import BadgeTimeline from '../components/BadgeTimeline.js'
import {MdClose} from 'react-icons/md'
import {Close} from '../assets/icons/Icons';
import { useMediaQuery } from 'react-responsive';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from '../helpers';


const Walkthrough = ({
  id, title, subHeaderContent, description, closeCallback,
  size, hideMainSeparators, contentAlignment,
  hasTimeline, timelineSteps, timelineStep, timelineStepChange,
  children, footer,

  content, transitionToContent, transitionBackToContent, transitionNextCompletedCallback, transitionPreviousCompletedCallback
}) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  const closePopup = () =>  {
    if (closeCallback !== undefined)  {
      closeCallback(false)
    }
  }
  const forceClosePopup = () =>  {
    if (closeCallback !== undefined)  {
      closeCallback(true)
    }
  }

  const [currentTimelineStep, SetTimelineStep] = React.useState(undefined)
  React.useEffect(() => {
    if (timelineStep !== currentTimelineStep) {
      SetTimelineStep(timelineStep)
    }
  }, [timelineStep])
  const timelineStepChanged = (key) =>    {
      if (currentTimelineStep !== key)    {
        SetTimelineStep(key)
          if (timelineStepChange !== undefined) {
            timelineStepChange(key)
          }
      }
  }
  



  const [walkthroughTransitionStep, SetWalkthroughTransitionStep] = React.useState("idle")
  const [walkthroughTransitionDirection, SetWalkthroughTransitionDirection] = React.useState("")
  React.useEffect(() => {
    if (transitionToContent !== null && walkthroughTransitionStep === "idle") {
      SetWalkthroughTransitionStep("transitioning")
      SetWalkthroughTransitionDirection("next")

      currentCardTransitionToNextCardAnimationAPI.start({
        from: { left: 0, scale: 1.0},
        to: { left : -walkthroughContentWidth, scale: 0.7}
      })
      
      nextCardTransitionToCurrentCardAnimationAPI.start({
        from: { left: walkthroughContentWidth, scale: 0.7},
        to: { left : 0, scale: 1.0}
      })
    }else if (walkthroughTransitionStep === "done") {
      currentCardTransitionToNextCardAnimationAPI.start({
        from: { left: 0, scale: 1},
        to: { left : 0, scale: 1}
      })
      nextCardTransitionToCurrentCardAnimationAPI.start({
        from: { left: walkthroughContentWidth, scale: 0.7},
        to: { left : walkthroughContentWidth, scale: 0.7}
      })
      SetWalkthroughTransitionStep("idle")
    }
  }, [transitionToContent])


  React.useEffect(() => {
    if (transitionBackToContent !== null && walkthroughTransitionStep === "idle") {
      SetWalkthroughTransitionStep("transitioning")
      SetWalkthroughTransitionDirection("previous")

      currentCardTransitionToNextCardAnimationAPI.start({
        from: { left: 0, scale: 1.0},
        to: { left : walkthroughContentWidth, scale: 0.7}
      })
      
      previousCardTransitionToCurrentCardAnimationAPI.start({
        from: { left: -walkthroughContentWidth, scale: 0.7},
        to: { left : 0, scale: 1.0}
      })
    }else if (walkthroughTransitionStep === "done") {
      currentCardTransitionToNextCardAnimationAPI.start({
        from: { left: 0, scale: 1},
        to: { left : 0, scale: 1}
      })
      previousCardTransitionToCurrentCardAnimationAPI.start({
        from: { left: -walkthroughContentWidth, scale: 0.7},
        to: { left : -walkthroughContentWidth, scale: 0.7}
      })
      SetWalkthroughTransitionStep("idle")
    }
  }, [transitionBackToContent])



  const [currentCardTransitionToNextCardAnimation, currentCardTransitionToNextCardAnimationAPI] = useSpring(() => ({
    from: { left: 0 },
    onRest: () => {
      SetWalkthroughTransitionStep("completed")
    }
  }))


  React.useEffect(() => {
    if (walkthroughTransitionStep === "completed") {
      SetWalkthroughTransitionStep("done")

      if (walkthroughTransitionDirection === "previous")  {
        if (transitionPreviousCompletedCallback !== undefined)  {
          transitionPreviousCompletedCallback()
        }
      }else if (walkthroughTransitionDirection === "next")  {
        if (transitionNextCompletedCallback !== undefined)  {
          transitionNextCompletedCallback()
        }
      }

    }
  }, [walkthroughTransitionStep, walkthroughTransitionDirection])

  const [walkthroughContentBind, { width: walkthroughContentWidth }] = useMeasure()

  const [nextCardTransitionToCurrentCardAnimation, nextCardTransitionToCurrentCardAnimationAPI] = useSpring(() => ({
    from: { left: walkthroughContentWidth },
  }))

  const [previousCardTransitionToCurrentCardAnimation, previousCardTransitionToCurrentCardAnimationAPI] = useSpring(() => ({
    from: { left: walkthroughContentWidth },
  }))



  const walkthroughProps = {style:{}}
  if (id) {
    walkthroughProps.id = id;
  }


  if (size === "full_screen") {
    const walkthroughPointerProps = {style:{}}
    if (walkthroughTransitionStep !== "idle") {
      walkthroughPointerProps.style.pointerEvents = "none"
    }
  
    return (
      <div className={"Walkthrough_FullScreen" + (contentAlignment === "center" ? " Walkthrough_CenterContent" : "") + (isMobile ? " Walkthrough_Mobile" : (isTablet ? " Walkthrough_Tablet" : (isDesktop ? " Walkthrough_Desktop" : "") + (isWideDesktop ? " Walkthrough_WideDesktop" : "" ) ) )} {...walkthroughProps}  {...walkthroughContentBind}>
        <div className="Walkthrough" {...walkthroughPointerProps}>
            <div className={"Walkthrough-Header" + (hideMainSeparators ? " Walkthrough-NoSeperator" : "")}>
                <div className="Walkthrough-HeaderContent">
                    <div className="Walkthrough-HeaderTitle t_DisplayLarge">
                        {title}
                    </div>
                    {description && 
                      <div className="Walkthrough-HeaderDescription">
                          {description}
                      </div>
                    }
                    {timelineSteps !== false &&
                      <BadgeTimeline steps={timelineSteps} currentStep={currentTimelineStep} onStepChanged={timelineStepChanged}/>
                    }
                  <div className="Walkthrough-CloseButton" onClick={forceClosePopup}>
                    <Close/>
                  </div>
                </div>
                {subHeaderContent && 
                  <div className="Walkthrough-HeaderSubContent">
                    {subHeaderContent}
                  </div>
                }
            </div>

            <div className="Walkthrough-Content">
              <div className="Walkthrough-Cards">
                {transitionBackToContent &&
                  <animated.div className="Walkthrough-Card-Wrapper" style={previousCardTransitionToCurrentCardAnimation}>
                    {transitionBackToContent}
                  </animated.div>
                  }

                <animated.div className="Walkthrough-Card-Wrapper" style={currentCardTransitionToNextCardAnimation}>
                  {content}
                </animated.div>
                
                {transitionToContent &&
                  <animated.div className="Walkthrough-Card-Wrapper" style={nextCardTransitionToCurrentCardAnimation}>
                    {transitionToContent}
                  </animated.div>
                  }
              </div>
            </div>

            {footer && 
              <div className={"Walkthrough-Footer" + (hideMainSeparators ? " Walkthrough-NoSeperator" : "")}>
                {footer}
              </div>
            }
        </div>
      </div>
    )

  }else {

    const containerProps = {style: {}}
    if (size === "auto")  {
      containerProps.style.alignItems = "center"
    }else if (size === "full")  {
      containerProps.style.alignItems = "stretch"
    }
    if (walkthroughTransitionStep !== "idle") {
      containerProps.style.pointerEvents = "none"
    }


    return (
      <div className={"Walkthrough-ScreenWrapper"} {...walkthroughProps}>
        <div onClick={closePopup} className="Walkthrough-Container" {...containerProps}>
          <div className="Walkthrough" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
              <div className="Walkthrough-Header">
                  <div className="Walkthrough-HeaderContent">
                      <div className="Walkthrough-HeaderTitle t_DisplayLarge">
                          {title}
                      </div>
                      {description && 
                        <div className="Walkthrough-HeaderDescription">
                            {description}
                        </div>
                      }
                      {timelineSteps !== false &&
                        <BadgeTimeline steps={timelineSteps} currentStep={currentTimelineStep} onStepChanged={timelineStepChanged}/>
                      }
                  </div>
                  <div className="Walkthrough-CloseButton" onClick={forceClosePopup}>
                    <Close/>
                  </div>
              </div>

              <div className="Walkthrough-Content">
                <div className="Walkthrough-Cards">
                  <animated.div className="Walkthrough-Card-Wrapper">
                    {content}
                  </animated.div>
                  
                  {transitionToContent &&
                    <animated.div className="Walkthrough-Card-Wrapper" style={{left:walkthroughContentWidth}}>
                      {transitionToContent}
                    </animated.div>
                  }
                </div>
              </div>

              {footer && 
                <div className="Walkthrough-Footer">
                  {footer}
                </div>
              }
          </div>
        </div>
      </div>
    )
  }
} 

Walkthrough.defaultProps = {
  id: false,
  size: "auto",
  contentAlignment: false,
  footer: false,
  timelineSteps: false,
  hideMainSeparators: false,
  subHeaderContent: false,
  transitionToContent: null,
  transitionBackToContent: null
}


export default Walkthrough