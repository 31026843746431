import './ScheduleGrowPopup.scss';
import React from 'react';

import PopupModal from '../../model_components/PopupModal'
import { FormatDate } from '../../helpers'
import { useSelector, useDispatch} from 'react-redux'
import { selectAllRaftTypes, haveRaftTypes, getAllRaftTypes } from '../../redux/entities/RaftTypes'
import { selectAllRecipes, getRecipeById } from '../../redux/entities/Recipes'
import { RaftTypes } from '../../model_components/RaftType';
import SelectGrowPlans from '../../model_components/SelectGrowPlans';

import NumberInput from '../../components/input/NumberInput';
import TextInput from '../../components/input/TextInput';
import DropDownInput from '../../components/input/DropDownInput';
import ControlBar from '../../components/ControlBar';
import Button from '../../components/Button';
import { SelectGrowZoneType, GrowZoneTypes } from '../../model_components/GrowZoneType';
import SliderInput from '../../components/input/SliderInput';
import { MdClose } from 'react-icons/md';
import Calendar from '../../components/input/Calendar';
import {HiArrowRight} from 'react-icons/hi'
  

const ScheduleGrowPopup = ({growCompleteCallback, growGroupCompleteCallback, closeCallback}) => {
    const closePopup = (force) =>  {
        if (closeCallback !== undefined && (force || currentTimelineStep === "grow_plan"))  {
            closeCallback()
        }
    }

    const dispatch = useDispatch()
    let recipesEntitiesState = useSelector((state) => state.recipes.status)
    let loadedRaftTypes = useSelector((state) => state.raftTypes.haveInitialData)
    React.useEffect(() => {
        if (!loadedRaftTypes)   {
            dispatch(getAllRaftTypes())
        }
    }, [loadedRaftTypes])
    let raftTypes = useSelector(selectAllRaftTypes)
    let recipes = useSelector(selectAllRecipes)

    const [currentTimelineStep, SetCurrentTimelineStep] = React.useState("grow_plan")
    const standardTimelineSteps = {
        grow_plan: { render: (<span>Grow Plan</span>) },
        grow_zone_types: { render: (<span>Rack Types</span>) },
        units: { render: (<span>Units</span>) },
        timing_options: { render: (<span>Timing Options</span>) },
        timing: { render: (<span>Timing</span>) },
        review: { render: (<span>Review</span>) },
    }
    const alternativeTimelineSteps = {
        grow_plan: { render: (<span>Grow Plan</span>) },
        grow_zone_types: { render: (<span>Rack Types</span>) },
        units: { render: (<span>Units</span>) },
        timing_options: { render: (<span>Timing Options</span>) },
        timing: { render: (<span>Timing</span>) },
        suggestions: { render: (<span>Suggestions</span>) },
        review: { render: (<span>Review</span>) },
    }
    
    const [suggestionsActive, SetSuggestionsActive] = React.useState(false)
    const timelineStepChanged = (key) =>    {
        if (currentTimelineStep !== key)    {
            SetCurrentTimelineStep(key)
        }
    }
    const previousStepClicked = () =>   {
        switch (currentTimelineStep)    {
            case "grow_zone_types":
                SetCurrentTimelineStep("grow_plan")
                break
            case "units":
                SetCurrentTimelineStep("grow_zone_types")
                break
            case "timing_options":
                SetCurrentTimelineStep("units")
                break
            case "timing":
                SetCurrentTimelineStep("timing_options")
                break
            case "suggestions":
                SetCurrentTimelineStep("timing")
                break
            case "review":
                SetCurrentTimelineStep(suggestionsActive ? "suggestions" : "timing")
                break
            default:
                break
        }
    }

    
    const [selectedGrowPlans, SetSelectedGrowPlans] = React.useState([])
    const [selectedRecipeId, SetSelectedRecipeId] = React.useState(undefined)
    const [selectedRecipeVersion, SetSelectedRecipeVersion] = React.useState(undefined)
    const [selectedRecipe, SetSelectedRecipe] = React.useState(undefined)
    const growPlanSelected = (growPlan) =>  {
        SetSelectedGrowPlans([...selectedGrowPlans, growPlan])
    }
    React.useEffect(() => {
        if (selectedGrowPlans.length === 0) {
            SetSelectedRecipeId(undefined)
        }else if (selectedGrowPlans.length === 1) {
            SetSelectedRecipeId(selectedGrowPlans[0].recipe_id)
            SetSelectedRecipeVersion(selectedGrowPlans[0].recipe_version)
        }

        let isFirst = true
        for (let growPlan of selectedGrowPlans) {
            if (selectedGrowPlanUnits[growPlan.id] === undefined)   {
                selectedGrowPlanUnits[growPlan.id] = 0
            }

            if (isFirst)    {
                selectedGrowPlanRatios[growPlan.id] = 100
                isFirst = false
            }else {
                selectedGrowPlanRatios[growPlan.id] = 0
            }

            selectedGrowPlanRatiosLocked[growPlan.id] = false
        }
        for (let growPlanId in selectedGrowPlanUnits) {
            if (selectedGrowPlans.find((gP) => gP.id === parseInt(growPlanId)) === undefined)   {
                delete selectedGrowPlanUnits[growPlanId]
                delete selectedGrowPlanRatios[growPlanId]
                delete selectedGrowPlanRatiosLocked[growPlanId]
            }
        }

        SetSelectedGrowPlanUnits(selectedGrowPlanUnits)
        SetSelectedGrowPlanRatios(selectedGrowPlanRatios)
        SetSelectedGrowPlanRatiosLocked(selectedGrowPlanRatiosLocked)

    }, [selectedGrowPlans])

    React.useEffect(() => {
        if (selectedRecipeId !== undefined && selectedRecipe === undefined && recipesEntitiesState !== "pending") {
            let foundRecipe = recipes.find((r) => r.id === selectedRecipeId && r.version === selectedRecipeVersion)
            if (foundRecipe === undefined)  {
                dispatch(getRecipeById(selectedRecipeId))
            }else {
                SetSelectedRecipe(foundRecipe)
            }
        }else if (selectedRecipeId === undefined && selectedRecipe !== undefined) {
            SetSelectedRecipe(undefined)
        }
    }, [selectedRecipeId, recipes, recipesEntitiesState])

    const switchToGrowZoneTypesSelectionClicked = () =>    {
        if (selectedGrowPlans.length > 0) {
            SetCurrentTimelineStep("grow_zone_types")
        }
    }


    const [selectedGrowZoneType, SetSelectedGrowZoneType] = React.useState(undefined)
    const onGrowZoneTypeSelected = (selectedGrowZoneType) =>    {
        SetSelectedGrowZoneType(selectedGrowZoneType)
        
        if (selectedGrowZoneType !== "any") {
            for (let growPlan of selectedGrowPlans) {
                if (selectedGrowPlanUnits[growPlan.id] !== 0)  {
                    let raftType = RaftTypes.find((rT) => rT.id === growPlan.raft_type_id)
                    
                    let unitsPerZone = 0
                    if (raftType !== undefined) {
                        unitsPerZone = raftType.plant_sites * selectedGrowZoneType.number_of_rafts
                    }

                    SetSelectedGrowPlanUnits({...selectedGrowPlanUnits, [growPlan.id]: Math.round(selectedGrowPlanUnits[growPlan.id] / unitsPerZone) * unitsPerZone})
                }
            }
        }
        SetCurrentTimelineStep("units")
    }
    const switchToUnitsSelectionClicked = () =>    {
        SetCurrentTimelineStep("units")
    }


    const [selectedGrowPlanUnits, SetSelectedGrowPlanUnits] = React.useState({})
    const [selectedGrowPlanRatios, SetSelectedGrowPlanRatios] = React.useState({})
    const [selectedGrowPlanRatiosLocked, SetSelectedGrowPlanRatiosLocked] = React.useState({})
    const [selectedNumberOfGrowZones, SetSelectedNumberOfGrowZones] = React.useState(0)    
    let currentTotalGrowPlanUnits = 0
    Object.values(selectedGrowPlanUnits).map((gPU) => currentTotalGrowPlanUnits += gPU)

    const onGrowUnitsChanged = (growPlanId, value) =>    {
        SetSelectedGrowPlanUnits({...selectedGrowPlanUnits, [growPlanId]: value})
    }
    const onSelectedNumberOfGrowZonesChanged = (value) =>    {
        SetSelectedNumberOfGrowZones(value)
    }

    React.useEffect(() => {
        if (selectedGrowZoneType !== "any") {
            for (let growPlan of selectedGrowPlans) {
                let raftType = RaftTypes.find((rT) => rT.id === growPlan.raft_type_id)

                selectedGrowPlanUnits[growPlan.id] = Math.round((selectedGrowZoneType.number_of_rafts * (selectedGrowPlanRatios[growPlan.id] / 100)) * raftType.plant_sites * selectedNumberOfGrowZones)
            }
        }
    }, [selectedGrowZoneType, selectedGrowPlanRatios, selectedNumberOfGrowZones])

    React.useEffect(() =>   {
        for (let [growPlanId, units] of Object.entries(selectedGrowPlanUnits))  {
            let growPlan = selectedGrowPlans[growPlanId]

        }
    }, [selectedGrowPlanUnits])

    const switchToTimingOptionsSelectionClicked = () =>    {
        if (currentTotalGrowPlanUnits !== 0) {
            SetCurrentTimelineStep("timing_options")
        }
    }


    const [selectedTimingOption, SetSelectedTimingOption] = React.useState(undefined)
    const onTimingOptionSelectionChanged = (value) =>    {
        if (value !== selectedTimingOption) {
            SetSelectedTiming(undefined)
            SetCurrentCalendarDateRange(undefined)
        }
        SetSelectedTimingOption(value)
        SetCurrentTimelineStep("timing")
    }
    const switchToTimingSelectionClicked = () =>    {
        SetCurrentTimelineStep("timing")
    }


    const [selectedTiming, SetSelectedTiming] = React.useState(undefined)
    const [currentCalendarDateRange, SetCurrentCalendarDateRange] = React.useState(undefined)
    const onTimingSelectionChanged = (timing) =>    {
        SetSelectedTiming(timing)
    }
    const onCalendarDayClicked = (day) =>  {
        if (day === undefined)  {
            SetCurrentCalendarDateRange(undefined)
        }else {
            if (selectedTimingOption === "start")   {
                SetCurrentCalendarDateRange([day, new Date(day.getTime() + (selectedRecipe.expected_duration * 1000))])
            }else if (selectedTimingOption === "harvest")   {
                SetCurrentCalendarDateRange([new Date(day.getTime() - (selectedRecipe.expected_duration * 1000)), day])
            }
        }
        SetSelectedTiming(day)

    }
    
    const switchToReviewClicked = () =>    {
        if (selectedTiming !== undefined) {
            SetCurrentTimelineStep("review")
        }
    }

    



    const [growNotes, SetGrowNotes] = React.useState("")
    const onGrowNotesChanged = (value) =>    {
        SetGrowNotes(value)
    }

    const [completedRequiredDetails, SetCompletedRequiredDetails] = React.useState(false)
    React.useEffect(() => {
      

        SetCompletedRequiredDetails(true)
    }, [growNotes])


    const growTempId = useSelector((state) => state.grows.currentGrowTempId)
    const growGroupTempId = useSelector((state) => state.grows.currentGroupTempId)
    const finalizeGrowClicked = (e) =>  {
        let currentGrowTempId = growTempId
        let currentGrowGroupTempId = growGroupTempId
        
        let growPlans = []
        for (let growPlan of selectedGrowPlans)   {
            let numberOfUnits = selectedGrowPlanUnits[growPlan.id]
            growPlans.push({id: growPlan.id, units: selectedGrowPlanUnits[growPlan.id] / selectedNumberOfGrowZones, ratio: selectedGrowPlanRatios[growPlan.id]})
        }
        if (selectedNumberOfGrowZones === 1)    {
            let newGrow = {
                temp_id: currentGrowTempId,
                id: "t" + currentGrowTempId,
                facility_id: 1, //hacked
                grow_plans_info: growPlans,
                recipe_id: selectedRecipe.id,
                recipe_version: selectedRecipe.version,
                scheduled_by_account_id: 29, //hacked
                scheduled_for: selectedTiming.toISOString(),
                scheduled_type: selectedTimingOption,
                pending_start: true, //may not need
                stage: "scheduled",
                started_on: null,
                finished_on: null,
                completed: false,
                terminated_early: false,
                initialSave: false
            }
            currentGrowTempId += 1
    
            if (growCompleteCallback !== undefined) {
                growCompleteCallback(newGrow)
            }
        }else {
            let newGroup = {
                temp_id: currentGrowGroupTempId,
                id: "t" + currentGrowGroupTempId,
                facility_id: 1, //hacked
                grow_plans_info: growPlans,
                recipe_id: selectedRecipe.id,
                recipe_version: selectedRecipe.version,
                scheduled_by_account_id: 29, //hacked
                scheduled_for: selectedTiming.toISOString(),
                scheduled_type: selectedTimingOption,
                pending_start: true, //may not need
                stage: "scheduled",
                started_on: null,
                finished_on: null,
                completed: false,
                terminated_early: false,
                initialSave: false
            }
            currentGrowGroupTempId += 1

            let newGrows = []
            for (let i = 0; i < selectedNumberOfGrowZones; i++) {
                newGrows.push({
                    temp_id: currentGrowTempId,
                    id: "t" + currentGrowTempId,
                    group_temp_id: newGroup.temp_id,
                    group_id: newGroup.id,
                    facility_id: 1, //
                    grow_plans_info: growPlans,
                    recipe_id: selectedRecipe.id,
                    recipe_version: selectedRecipe.version,
                    scheduled_by_account_id: 29, //hacked
                    scheduled_for: selectedTiming,
                    scheduled_type: selectedTimingOption,
                    pending_start: true, //may not need
                    stage: "scheduled",
                    started_on: null,
                    finished_on: null,
                    completed: false,
                    terminated_early: false,
                    initialSave: false
                })
                currentGrowTempId += 1
            }
    
            if (growGroupCompleteCallback !== undefined) {
                growGroupCompleteCallback(newGroup, newGrows)
            }
        }
    }


    let headerDescription = ""
    let footerContent = (<></>)
    switch (currentTimelineStep)    {
        case "grow_plan": 
            headerDescription = "Select a grow plan"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
                </>)} secondaryControls={(<>
                    {selectedGrowPlans.length > 0 && <Button content={"Next: Rack Types"} onClick={switchToGrowZoneTypesSelectionClicked}/>}
                </>)}/>
            )
            break
        case "grow_zone_types": 
            headerDescription = "How do you want to provide your units"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Previous: Grow Plans"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {selectedGrowZoneType !== undefined && <Button content={"Next: Units"} onClick={switchToUnitsSelectionClicked}/>}
                </>)}/>
            )
            break
        case "units": 
            headerDescription = "Select the number of units"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Previous: Units"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {currentTotalGrowPlanUnits !== 0 && 
                        <Button content={"Next: Timing Options"} onClick={switchToTimingOptionsSelectionClicked}/>
                    }
                </>)}/>
            )
            break
        case "timing_options": 
            headerDescription = "Timing options"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Previous Step"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {selectedTimingOption !== undefined && <Button content={"Next Step"} onClick={switchToTimingSelectionClicked}/>}
                </>)}/>
            )
            break
        case "timing": 
            headerDescription = "Timing"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Previous Step"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {selectedTiming && <Button content={"Next Step"} onClick={switchToReviewClicked}/>}
                </>)}/>
            )
            break
        case "review":
            headerDescription = "Grow review"
            footerContent = (
                <ControlBar controls={(<>
                    <Button content={"Previous Step"} status={"Neutral"} onClick={previousStepClicked}/>
                </>)} secondaryControls={(<>
                    {completedRequiredDetails && <Button content={"Schedule Grow"} onClick={finalizeGrowClicked}/>}
                </>)}/>
            )
            break
        default: 
            break
    }


    let reviewInProgressDisplayProps = {style:{}}
    if (currentTimelineStep !== "grow_plan")    {
        reviewInProgressDisplayProps.style.borderBottomWidth = 1
        reviewInProgressDisplayProps.style.paddingBottom = 8
    }
    return (
        <PopupModal 
            title="Schedule Grow"
            size="full"
            closeCallback={closePopup}
            timelineSteps={suggestionsActive ? alternativeTimelineSteps : standardTimelineSteps} 
            timelineStep={currentTimelineStep} 
            onStepChange={timelineStepChanged}
            footer={footerContent}>
            <div className="ScheduleGrowPopup-Content">
                {currentTimelineStep === "grow_plan" && 
                    <div className="PopupModal-FormItem">
                        <div className="PopupModal-FormItem-Question">
                            What grow plans do you want to use?
                        </div>
                        <div className="PopupModal-FormItem-Answer">
                            <div className="ScheduleGrowPopup-ReviewInProgress" {...reviewInProgressDisplayProps}>
                                <div className="ScheduleGrowPopup-SelectedGrowPlanChips">
                                    {/*<div className="ScheduleGrowPopup-SelectedGrowPlanChips-Title">Grow Plans:</div>*/}
                                    <div className="ScheduleGrowPopup-SelectedGrowPlanChips-Items">
                                        {selectedGrowPlans.map((growPlan) => {
                                            const removeGrowPlan = () =>    {
                                                selectedGrowPlans.pop(selectedGrowPlans.indexOf(growPlan))
                                                SetSelectedGrowPlans([...selectedGrowPlans])
                                            }
                                            return (
                                                <div className="ScheduleGrowPopup-SelectedGrowPlanChip" key={growPlan.id}>
                                                    <div className="ScheduleGrowPopup-SelectedGrowPlanChip-Title">{growPlan.name}</div>
                                                    {currentTimelineStep === "grow_plan" && <div className="ScheduleGrowPopup-SelectedGrowPlanChip-Remove" onClick={removeGrowPlan}>
                                                        <MdClose/>
                                                    </div>}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <SelectGrowPlans 
                                pullOutSelectedItems={true} 
                                selectedGrowPlans={selectedGrowPlans} 
                                onGrowPlanSelection={growPlanSelected}
                                matchesRecipeId={selectedGrowPlans.length > 0 ? selectedGrowPlans[0].recipe_id : false}/>
                        </div>
                    </div>
                }
                {currentTimelineStep === "grow_zone_types" && 
                    <>
                        <div className="PopupModal-FormItem">
                            <div className="PopupModal-FormItem-Question">
                                Which rack type would you like to use?
                            </div>
                            <div className="PopupModal-FormItem-Answer">
                                <SelectGrowZoneType allowAnyOption={true} selectedGrowZoneType={selectedGrowZoneType} onGrowZoneTypeSelect={onGrowZoneTypeSelected}/>
                            </div>
                        </div>
                                
                    </>
                }
                {currentTimelineStep === "units" && 
                    <>
                        
                            
                        {selectedGrowZoneType === "any" &&
                            <div className="PopupModal-FormItem">
                                <div className="PopupModal-FormItem-Question">
                                    How many units would you like to grow?
                                </div>
                                <div className="PopupModal-FormItem-Answer">
                                    {selectedGrowPlans.length == 1 && 
                                        <>
                                            <NumberInput 
                                                label={"Units"} 
                                                value={selectedGrowPlanUnits[selectedGrowPlans[0].id]} 
                                                suffix="units" 
                                                min={0}
                                                onChange={(value) => {onGrowUnitsChanged(selectedGrowPlans[0].id, value)}}/> 
                                        </>
                                    }
                                    {selectedGrowPlans.length > 1 && 
                                        <>
                                            
                                            <div className="ScheduleGrowPopup-UnitsSelectionTable">
                                                <div className="ScheduleGrowPopup-UnitsSelectionTable-GrowPlanIdentifiers">
                                                    {selectedGrowPlans.map((growPlan) => {
                                                        return (
                                                            <div key={growPlan.id}>
                                                                {growPlan.name}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="ScheduleGrowPopup-UnitsSelectionTable-GrowPlanUnitInputs">
                                                    {selectedGrowPlans.map((growPlan) => {
                                                        return (
                                                            <NumberInput
                                                                key={growPlan.id}
                                                                label={"Units"} 
                                                                value={selectedGrowPlanUnits[growPlan.id]} 
                                                                suffix="units" 
                                                                min={0}
                                                                onChange={(value) => {onGrowUnitsChanged(growPlan.id, value)}}/>
                                                        ) 
                                                    })}
                                                </div>
                                            </div>
                                                
                                        </>
                                    }
                                </div>
                            </div>
                        }
                        {selectedGrowZoneType !== "any" &&
                            <>
                                <div className="PopupModal-FormItem">
                                    <div className="PopupModal-FormItem-Question">
                                        <span>How many <span style={{fontWeight:"bold"}}>{selectedGrowZoneType.name}</span> zones would you like to grow in?</span>
                                    </div>
                                    <div className="PopupModal-FormItem-Answer">
                                        <div style={{display:"flex"}}>
                                            
                                            <NumberInput 
                                                label={"Zones"} 
                                                value={selectedNumberOfGrowZones} 
                                                suffix="zones" 
                                                min={0}
                                                onChange={onSelectedNumberOfGrowZonesChanged}/> 

                                        </div>
                                    </div>
                                </div>

                                <div className="PopupModal-FormItem">
                                    <div className="PopupModal-FormItem-Question">
                                        <span>How do you want to split up your zones?</span>
                                    </div>
                                    <div className="PopupModal-FormItem-Answer">
                                        <div className="ScheduleGrowPopup-UnitsSelectionTable">
                                            <div className="ScheduleGrowPopup-UnitsSelectionTable-GrowPlanIdentifiers">
                                                {selectedGrowPlans.map((growPlan) => {
                                                    return (
                                                        <div key={growPlan.id}>
                                                            {growPlan.name}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {selectedGrowPlans.length > 1 && 
                                                <div className="ScheduleGrowPopup-UnitsSelectionTable-GrowPlanRatios">
                                                    {selectedGrowPlans.map((growPlan) => {
                                                        let raftType = RaftTypes.find((rT) => rT.id === growPlan.raft_type_id)
                                                        
                                                        let ratio = selectedGrowPlanRatios[growPlan.id]
                                                        let unlockedGrowPlans = Object.entries(selectedGrowPlanRatiosLocked).filter(([growPlanId, state]) => parseInt(growPlanId) !== growPlan.id && !state)
                                                        let resolution = 100 / (selectedGrowZoneType.number_of_rafts / unlockedGrowPlans.length)


                                                        let unitsPerZone = 0
                                                        if (raftType !== undefined) {
                                                            unitsPerZone = raftType.plant_sites * selectedGrowZoneType.number_of_rafts
                                                        }
                                                        const onGrowUnitsChanged = (value) =>    {
                                                            SetSelectedGrowPlanUnits({...selectedGrowPlanUnits, [growPlan.id]: value * unitsPerZone})
                                                        }

                                                        const ratioChanged = (newRatio) => {
                                                            
                                                            let newRatios = {...selectedGrowPlanRatios}

                                                            //Calculate all other spectrums
                                                            let desiredAmountOfChange = newRatio - ratio
                                                            
                                                            let availableGrowPlans = []
                                                            let availableRatioToTake = 0
                                                            let availableRatioToGive = 0
                                                            for (let growPlanId in selectedGrowPlanRatiosLocked) {
                                                                if (!selectedGrowPlanRatiosLocked[growPlanId] && growPlan.id !== parseInt(growPlanId))   {
                                                                    availableGrowPlans.push(growPlanId)
                                                                    availableRatioToTake += selectedGrowPlanRatios[growPlanId]
                                                                    availableRatioToGive += 100 - selectedGrowPlanRatios[growPlanId]
                                                                }
                                                            }
                                
                                                            if (ratio + desiredAmountOfChange < 0)  {
                                                                desiredAmountOfChange = -ratio
                                                            }
                                                            if (desiredAmountOfChange === 0 || (desiredAmountOfChange > 0 && availableRatioToTake <= 0) || (desiredAmountOfChange < 0 && availableRatioToGive <= 0))  {
                                                                return ratio
                                                            }


                                                            for (const growPlanId of availableGrowPlans)  {
                                                                if (desiredAmountOfChange >= 0)  {
                                                                    const currentRatio = (100 / availableRatioToTake) * selectedGrowPlanRatios[growPlanId]
                                                                    const amountOfChange = (currentRatio / 100) * -desiredAmountOfChange
                                                                    newRatios[growPlanId] += amountOfChange
                                                                }else {
                                                                    let currentRatio = 100 / availableGrowPlans.length
                                                                    if (availableRatioToTake !== 0) {
                                                                        currentRatio = (100 / availableRatioToTake) * selectedGrowPlanRatios[growPlanId]
                                                                    }
                                                                    

                                                                    const amountOfChange = (currentRatio / 100) * -desiredAmountOfChange
                                                                    newRatios[growPlanId] += amountOfChange
                                                                }
                                                            }
                                                            
                                                            newRatios[growPlan.id] += desiredAmountOfChange
                                                            
                                                            SetSelectedGrowPlanRatios(newRatios)

                                                            return ratio + desiredAmountOfChange

                                                        }
                                                        const ratioChangedComplete = (e) => {
                                                            
                                                        }
                                                        const lockStateChanged = (state) => {
                                                            selectedGrowPlanRatiosLocked[growPlan.id] = state
                                                            SetSelectedGrowPlanRatiosLocked({...selectedGrowPlanRatiosLocked})
                                                        }
                                                        return (
                                                            <div key={growPlan.id}>
                                                                <SliderInput
                                                                    orientation="horizontal"
                                                                    value={ratio}
                                                                    lockable={selectedGrowPlans.length > 2}
                                                                    locked={selectedGrowPlanRatiosLocked[growPlan.id]}
                                                                    step={resolution}
                                                                    onValueChanged={ratioChanged}
                                                                    onValueChangedComplete={ratioChangedComplete} 
                                                                    onLockChanged={lockStateChanged}/>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                            <div className="ScheduleGrowPopup-UnitsSelectionTable-GrowPlanUnitsOutput">
                                                {selectedGrowPlans.map((growPlan) => {
                                                    let currentNumberOfRafts = Math.round((selectedGrowPlanRatios[growPlan.id] / 100) * selectedGrowZoneType.number_of_rafts * selectedNumberOfGrowZones)
                                                    return (
                                                        <div key={growPlan.id}>
                                                            {currentNumberOfRafts} rafts ({selectedGrowPlanUnits[growPlan.id]} units)
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                                 
                                
                    </>
                }
                {currentTimelineStep === "timing_options" && 
                    <> 
                        <div className="PopupModal-FormItem">
                            <div className="PopupModal-FormItem-Question">
                                What is your timing?
                            </div>
                            <div className="PopupModal-FormItem-Answer">
                                <div style={{display:"flex", gap: 10}}>
                                    <Button 
                                        content={"Harvest On"} 
                                        status={selectedTimingOption === "harvest" ? "Primary" : "Neutral"}
                                        onClick={() => {onTimingOptionSelectionChanged("harvest")}}/>
                                    <Button 
                                        content={"Start On"} 
                                        status={selectedTimingOption === "start" ? "Primary" : "Neutral"}
                                        onClick={() => {onTimingOptionSelectionChanged("start")}}/>
                                    <Button 
                                        content={"Next Available Date"} 
                                        status={selectedTimingOption === "nad" ? "Primary" : "Neutral"}
                                        onClick={() => {onTimingOptionSelectionChanged("nad")}}/>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {currentTimelineStep === "timing" && 
                    <>
                        {selectedTimingOption === "nad" && 
                            <>Date Display</>
                        }
                         {selectedTimingOption !== "nad" && 
                            <div className="PopupModal-FormItem">
                                <div className="PopupModal-FormItem-Question">
                                    {selectedTimingOption === "start" && 
                                        <>When do you want to start planting?</>
                                    }
                                    {selectedTimingOption === "harvest" && 
                                        <>When do you want to harvest?</>
                                    }
                                </div>
                                <div className="PopupModal-FormItem-Answer">
                                    <div className="ScheduleGrowPopup-SelectedDateDisplay">
                                        <div className="ScheduleGrowPopup-SelectedDateDisplay-StartDate">
                                            {currentCalendarDateRange !== undefined &&
                                                <>
                                                    {FormatDate(currentCalendarDateRange[0], "MMMM dd, yyyy")}
                                                </>
                                            }
                                        </div>
                                        <HiArrowRight/>
                                        <div className="ScheduleGrowPopup-SelectedDateDisplay-HarvestDate">
                                            {currentCalendarDateRange !== undefined &&
                                                <>
                                                    {FormatDate(currentCalendarDateRange[1], "MMMM dd, yyyy")}
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <Calendar
                                        value={selectedTiming} 
                                        dateClicked={onCalendarDayClicked}
                                        tileContent={(date) => {
                                            let tileClassName = "ScheduleGrowPopup-Calendar-DayTile"
                                            if (currentCalendarDateRange !== undefined)  {
                                                if (date.getTime() === currentCalendarDateRange[0].getTime()) {
                                                    tileClassName += " ScheduleGrowPopup-Calendar-SelectedDayStart"
                                                }else if (date.getTime() === currentCalendarDateRange[1].getTime()) {
                                                    tileClassName += " ScheduleGrowPopup-Calendar-SelectedDayHarvest"
                                                }else if (date.getTime() >= currentCalendarDateRange[0].getTime() && date.getTime() <= currentCalendarDateRange[1].getTime()) {
                                                    tileClassName += " ScheduleGrowPopup-Calendar-SelectedDayRange"
                                                }
                                                if (date.getTime() === selectedTiming.getTime())  {
                                                    tileClassName += " ScheduleGrowPopup-Calendar-SelectedDay"
                                                }
                                            }

                                            return (
                                                <div className={tileClassName}>
                                                    <div className="ScheduleGrowPopup-Calendar-DayTileContent">
                                                        {date.getDate()}
                                                    </div>
                                                </div>
                                            )
                                        }}/>
                                </div>
                            </div>
                        }
                    </>
                }
                {currentTimelineStep === "review" && 
                    <div className="ScheduleGrowPopup-DetailsForm">
                        <TextInput multiline={true} label={"Notes"} value={growNotes} placeHolder="" onChange={onGrowNotesChanged}/>
                    </div>
                }
            </div>
        </PopupModal>
    )
} 

export default ScheduleGrowPopup