import './../TasksPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate } from '../../../helpers'
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import Button from '../../../components/Button';
import { markTaskAsCompleted, markTaskAsStarted } from '../../../redux/entities/Tasks';
import { getGrowById } from '../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../redux/entities/GrowPlans';
import { getRecipeById } from '../../../redux/entities/Recipes'
import ControlBar from '../../../components/ControlBar';
import PopupModal from '../../../model_components/PopupModal';
import NumberInput from '../../../components/input/NumberInput';
  

const TaskPopup_MoveToGermination = ({task, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  let growsList = useSelector((state) => state.grows.grows)
  let recipesList = useSelector((state) => state.recipes.recipes)
 
  const closePopup = () =>  {
    onClosePopup()
  }

  const [grow, SetGrow] = React.useState(null)
  const [recipe, SetRecipe] = React.useState(null)
  
  
  React.useEffect(() => {
    let foundGrow = growsList.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }else {
      SetGrow({...foundGrow})
    }
  }, [task, growsList])

  React.useEffect(() => {
    if (grow) {
      let foundRecipe = recipesList.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
      console.log(grow, recipesList, foundRecipe)
      if (foundRecipe === undefined)  {
        dispatch(getRecipeById({recipeId: grow.recipe_id, recipeVersion: grow.recipe_version}))
      }else {
        SetRecipe({...foundRecipe})
      }
    }
  }, [grow, recipesList])



  const completeTaskClicked = React.useCallback(() => {
    dispatch(markTaskAsCompleted({taskId: task.id}))
    onClosePopup()
  })





  return (
    <PopupModal 
      title={task.name + (grow ? " #G" + grow.id : "")}
      closeCallback={closePopup}
      footer={<ControlBar controls={(<>
        <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
      </>)} secondaryControls={(<>
          {<Button status="Primary" content="Complete" onClick={completeTaskClicked}/>}
      </>)}/>}>

     
      <div className="PopupModal-FormItem">
        <div className="PopupModal-FormItem-Question">
          Move into Germination Chamber A
        </div>
      </div>

    </PopupModal>

  )
} 

export default TaskPopup_MoveToGermination