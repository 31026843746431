import './../ManageNDSWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, RackStandardGroup, RackStandardGrowOut, ScanRack } from '../../../../assets/icons/RackIcons';
import { Plus, ReorderItem } from '../../../../assets/icons/Icons';
import { createNewVerticalRackGroup, selectAllVerticalRackGroups } from '../../../../redux/entities/service/VerticalRackGroup';
import { useDispatch, useSelector } from 'react-redux';


import { nanoid, customAlphabet  } from 'nanoid'
import LoadingBar from '../../../../components/LoadingBar';
import { selectAllFacilities } from '../../../../redux/entities/Facilities';
import { selectAllNDS } from '../../../../redux/entities/service/NDS';
import { NDSConnectionPortSide, NDSConnectionPortSideNarrow, NDSConnectionReservoirSide, NDSPortSelection, ScanNDS } from '../../../../assets/icons/NDSIcons';
import { useMeasure } from '../../../../helpers';
import Switch from '../../../../components/Switch';
import Checkbox from '../../../../components/input/Checkbox';
import NumberInput from '../../../../components/input/NumberInput';
import DropDownInput from '../../../../components/input/DropDownInput';

  

const ManageReservoirsWalkthroughStep = ({reservoirs, numberOfReservoirs, transitionToPreviousStep, transitionToNextStep, NDSReservoirsUpdatedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const NDSs = useSelector(selectAllNDS)




    const [selectedReservoir, SetSelectedReservoir] = React.useState(0)
    const [selectedReservoirInformation, SetSelectedReservoirInformation] = React.useState(null)
    React.useEffect(() => {
        let reservoirInformation = reservoirs[selectedReservoir.toString()] ?? null
        if (reservoirInformation)    {
            
        }
        SetSelectedReservoirInformation(reservoirInformation)
    }, [selectedReservoir, reservoirs])

    const reservoirActiveStateChanged = (state) =>  {
        NDSReservoirsUpdatedCallback({reservoirs: {...reservoirs, [selectedReservoir]: {...selectedReservoirInformation, active: state, has_reservoir_volume: false, reservoir_volume: null}}})
    }
    const reservoirHasVolumeStateChanged = () =>    {
        NDSReservoirsUpdatedCallback({reservoirs: {...reservoirs, [selectedReservoir]: {...selectedReservoirInformation, has_reservoir_volume: true, reservoir_volume: 0}}})
    }
    const reservoirVolumeChanged = (volume) =>  {
        NDSReservoirsUpdatedCallback({reservoirs: {...reservoirs, [selectedReservoir]: {...selectedReservoirInformation, reservoir_volume: volume}}})
    }
    const selectedReservoirTypeChanged = (type) =>  {
        NDSReservoirsUpdatedCallback({reservoirs: {...reservoirs, [selectedReservoir]: {...selectedReservoirInformation, reservoir_volume_type: type}}})
    }
    const reservoirIsUndeterminedStateChanged = () =>   {
        NDSReservoirsUpdatedCallback({reservoirs: {...reservoirs, [selectedReservoir]: {...selectedReservoirInformation, has_reservoir_volume: false, reservoir_volume: null}}})
    }

    const backClicked = () =>   {
        transitionToPreviousStep(["manage_ports", null])
    }

    const nextClicked = () =>   {
        transitionToNextStep(["final_confirm", null])
    }



    const [reservoirKeyColumnBind, { width: reservoirKeyColumnWidth }] = useMeasure()
    const [reservoirColumnBind, { width: reservoirColumnWidth }] = useMeasure()


    const reservoirOptionProps = {style:{}}
    if (selectedReservoirInformation === null || !selectedReservoirInformation.active)  {
        reservoirOptionProps.style.opacity = 0.4
    }


    const resuableReservoirOptionProps = {style:{}}
    if (selectedReservoirInformation && !selectedReservoirInformation.has_reservoir_volume) {
        resuableReservoirOptionProps.style.opacity = 0.4
    }

    const undeterminedReservoirOptionProps = {style:{}}
    if (selectedReservoirInformation && selectedReservoirInformation.has_reservoir_volume) {
        undeterminedReservoirOptionProps.style.opacity = 0.4
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Manage Reservoirs</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                 {!isMobile && 
                    <div className={"Walkthrough-Card-Content"}>
                        <div className="FlexContent-H" style={{gap:70}}>
                            <div className="FlexContent-HFill">
                                <div className="FlexContent-20">
                                    <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" style={{paddingBottom:10}} content={<>
                                        <div className="Button-Large-Icon">
                                            <NDSConnectionReservoirSide width={isShortDisplay ? 170 : ((isTablet || isDesktop) ? 192 : 85)}/>
                                        </div>
                                        <div className="Button-Large-Icon-Subtitle">NDS Bottom View</div>    
                                    </>}/>

                                    <div className="FlexContent-5 FlexContent-Center">
                                        <div className="FlexContent-H-5">
                                            <div style={{width: reservoirKeyColumnWidth}}>&nbsp;</div>
                                            <div className="ManageNDSWalkthrough_Popup-ManageReservoirs-ReservoirColumns">
                                                {Object.entries(reservoirs).map(([rI, r]) => {
                                                    if (parseInt(rI) < numberOfReservoirs / 2) {
                                                        return (
                                                            <div 
                                                                key={rI} 
                                                                className="ManageNDSWalkthrough_Popup-ManageReservoirs-ReservoirColumn"
                                                                style={{width:reservoirColumnWidth}}>
                                                                
                                                                {parseInt(rI)}
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        <div className="FlexContent-H-5">
                                            <div className="FlexContent-5 FlexContent-Center" {...reservoirKeyColumnBind}>A</div>
                                            <div className="ManageNDSWalkthrough_Popup-ManageReservoirs-Reservoirs">
                                                {Object.entries(reservoirs).map(([rI, r]) => {
                                                    let reservoirIndex = parseInt(rI)
                                                    let reservoirPortClicked = () => {
                                                        SetSelectedReservoir(reservoirIndex)
                                                    }
                                                    if (reservoirIndex < numberOfReservoirs / 2) {
                                                        return (
                                                            <div 
                                                                key={rI} 
                                                                className="ManageNDSWalkthrough_Popup-ManageReservoirs-Reservoir" 
                                                                onClick={reservoirPortClicked} {...reservoirColumnBind}>
                                                                
                                                                <NDSPortSelection selected={selectedReservoir === reservoirIndex} completed={r.active}/>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        <div className="FlexContent-H-5">
                                            <div className="FlexContent-5 FlexContent-Center" {...reservoirKeyColumnBind}>B</div>
                                            <div className="ManageNDSWalkthrough_Popup-ManageReservoirs-Reservoirs">
                                                {Object.entries(reservoirs).map(([rI, r]) => {
                                                    let reservoirIndex = parseInt(rI)
                                                    let reservoirPortClicked = () => {
                                                        SetSelectedReservoir(reservoirIndex)
                                                    }
                                                    if (reservoirIndex >= numberOfReservoirs / 2) {
                                                        return (
                                                            <div 
                                                                key={rI} 
                                                                className="ManageNDSWalkthrough_Popup-ManageReservoirs-Reservoir" 
                                                                onClick={reservoirPortClicked} {...reservoirColumnBind}>
                                                                
                                                                <NDSPortSelection selected={selectedReservoir === reservoirIndex} completed={r.active}/>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="FlexContent-HFill FlexContent-Center"> 
                                <div className="FlexContent-H-40 FlexContent-HFill FlexContent-Center">
                                    
                                    <div className="FlexContent-30 FlexContent-HFill">
                                        <div className="FlexContent-H-30 FlexContent-HFlex">
                                            <div className="FlexContent FlexContent-HFlex" style={{alignSelf:"center"}}>Reservoir {selectedReservoir + 1}</div>
                                            <Switch offLabel={"Deactivate"} onLabel={"Activate"} state={selectedReservoirInformation ? selectedReservoirInformation.active : false} onSwitch={reservoirActiveStateChanged}/>
                                        </div>
                                        <div className="FlexContent-10 FlexContent-HFlex" {...reservoirOptionProps}>
                                            <div className="FlexContent-H-5">
                                                <Checkbox 
                                                    label={"Reusable Container"}
                                                    state={selectedReservoirInformation ? selectedReservoirInformation.has_reservoir_volume : false} 
                                                    disabled={selectedReservoirInformation ? !selectedReservoirInformation.active : true} 
                                                    onChange={reservoirHasVolumeStateChanged}/>
                                            </div>
                                            <div className="Walkthrough-FormItem" {...resuableReservoirOptionProps}>
                                                <div className="Walkthrough-FormItem-Question">Reservoir Volume</div>
                                                <div className="Walkthrough-FormItem-Answer">
                                                    <div className="FlexContent-H-10 FlexContent-HFlex">
                                                        <NumberInput
                                                            min={0}
                                                            max={1000}
                                                            value={parseFloat(selectedReservoirInformation ? (selectedReservoirInformation.reservoir_volume ?? 0) : 0)} 
                                                            disabled={selectedReservoirInformation ? (!selectedReservoirInformation.active || !selectedReservoirInformation.has_reservoir_volume) : true}
                                                            onBlur={reservoirVolumeChanged}/>
                                                        <DropDownInput options={[
                                                            {"label": "Litres", value: "litres"},
                                                        ]} value={selectedReservoirInformation ? selectedReservoirInformation.reservoir_volume_type : null} onSelectionChange={selectedReservoirTypeChanged}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="FlexContent-10 FlexContent-HFlex" {...reservoirOptionProps}>
                                            <div className="FlexContent-H-5">
                                                <Checkbox 
                                                    label={"Undetermined Container"}
                                                    state={selectedReservoirInformation ? (!selectedReservoirInformation.active ? false : !selectedReservoirInformation.has_reservoir_volume ) : true} 
                                                    disabled={selectedReservoirInformation ? !selectedReservoirInformation.active : true} 
                                                    onChange={reservoirIsUndeterminedStateChanged}/>
                                            </div>
                                            <div {...undeterminedReservoirOptionProps}>Volume is set by container later</div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>        
                }
                
                {isMobile && 
                    <div className={"Walkthrough-Card-Content"}>
                            <div className="FlexContent-20 FlexContent-Center">
                                <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                    <div className="Button-Large-Icon"><NDSConnectionReservoirSide width={(isTablet || isDesktop) ? 93 : "100%"}/></div>
                                </>}/>

                                <div className="FlexContent-5 FlexContent-Center">
                                    <div className="FlexContent-H-5">
                                        <div style={{width: reservoirKeyColumnWidth}}>&nbsp;</div>
                                        <div className="ManageNDSWalkthrough_Popup-ManageReservoirs-ReservoirColumns">
                                            {Object.entries(reservoirs).map(([rI, r]) => {
                                                if (parseInt(rI) < numberOfReservoirs / 2) {
                                                    return (
                                                        <div 
                                                            key={rI} 
                                                            className="ManageNDSWalkthrough_Popup-ManageReservoirs-ReservoirColumn"
                                                            style={{width:reservoirColumnWidth}}>
                                                            
                                                            {parseInt(rI) + 1}
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="FlexContent-H-5">
                                        <div className="FlexContent-5 FlexContent-Center" {...reservoirKeyColumnBind}>A</div>
                                        <div className="ManageNDSWalkthrough_Popup-ManageReservoirs-Reservoirs">
                                            {Object.entries(reservoirs).map(([rI, r]) => {
                                                let reservoirIndex = parseInt(rI)
                                                let reservoirPortClicked = () => {
                                                    SetSelectedReservoir(reservoirIndex)
                                                }
                                                if (reservoirIndex < numberOfReservoirs / 2) {
                                                    return (
                                                        <div 
                                                            key={rI} 
                                                            className="ManageNDSWalkthrough_Popup-ManageReservoirs-Reservoir" 
                                                            onClick={reservoirPortClicked} {...reservoirColumnBind}>
                                                            
                                                            <NDSPortSelection selected={selectedReservoir === reservoirIndex} completed={r.active}/>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="FlexContent-H-5">
                                        <div className="FlexContent-5 FlexContent-Center" {...reservoirKeyColumnBind}>B</div>
                                        <div className="ManageNDSWalkthrough_Popup-ManageReservoirs-Reservoirs">
                                            {Object.entries(reservoirs).map(([rI, r]) => {
                                                let reservoirIndex = parseInt(rI)
                                                let reservoirPortClicked = () => {
                                                    SetSelectedReservoir(reservoirIndex)
                                                }
                                                if (reservoirIndex >= numberOfReservoirs / 2) {
                                                    return (
                                                        <div 
                                                            key={rI} 
                                                            className="ManageNDSWalkthrough_Popup-ManageReservoirs-Reservoir" 
                                                            onClick={reservoirPortClicked} {...reservoirColumnBind}>
                                                            
                                                            <NDSPortSelection selected={selectedReservoir === reservoirIndex} completed={r.active}/>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        <div className="FlexContent-HFill">
                               
                            <div className="FlexContent-30 FlexContent-Center FlexContent-HFill">
                               
                                <div className="FlexContent-H-30 FlexContent-HFill">
                                    <div className="FlexContent FlexContent-HFlex" style={{alignSelf:"center"}}>Reservoir {selectedReservoir + 1}</div>
                                    <Switch offLabel={"Deactivate"} onLabel={"Activate"} state={selectedReservoirInformation ? selectedReservoirInformation.active : false} onSwitch={reservoirActiveStateChanged}/>
                                </div>
                                <div className="FlexContent-10 FlexContent-HFlex" {...reservoirOptionProps}>
                                    <div className="FlexContent-H-5">
                                        <Checkbox 
                                            label={"Reusable Container"}
                                            state={selectedReservoirInformation ? selectedReservoirInformation.has_reservoir_volume : false} 
                                            disabled={selectedReservoirInformation ? !selectedReservoirInformation.active : true} 
                                            onChange={reservoirHasVolumeStateChanged}/>
                                    </div>
                                    <div className="Walkthrough-FormItem">
                                        <div className="Walkthrough-FormItem-Question">Reservoir Volume</div>
                                        <div className="Walkthrough-FormItem-Answer">
                                            <div className="FlexContent-H-10 FlexContent-HFlex">
                                                <NumberInput
                                                    min={0}
                                                    max={1000}
                                                    value={parseFloat(selectedReservoirInformation ? (selectedReservoirInformation.reservoir_volume ?? 0) : 0)} 
                                                    disabled={selectedReservoirInformation ? (!selectedReservoirInformation.active || !selectedReservoirInformation.has_reservoir_volume) : true}
                                                    onBlur={reservoirVolumeChanged}/>
                                                <DropDownInput options={[
                                                    {"label": "Litres", value: "litres"},
                                                ]} value={selectedReservoirInformation ? selectedReservoirInformation.reservoir_volume_type : null} onSelectionChange={selectedReservoirTypeChanged}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="FlexContent-10 FlexContent-HFlex" {...reservoirOptionProps}>
                                    <div className="FlexContent-H-5">
                                        <Checkbox 
                                            label={"Undetermined Container"}
                                            state={selectedReservoirInformation ? (!selectedReservoirInformation.active ? false : !selectedReservoirInformation.has_reservoir_volume ) : true} 
                                            disabled={selectedReservoirInformation ? !selectedReservoirInformation.active : true} 
                                            onChange={reservoirIsUndeterminedStateChanged}/>
                                    </div>
                                    <div>Volume is set by container later</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>  
                }

            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status={"Neutral"} content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default ManageReservoirsWalkthroughStep