import './ServicesPage.scss';
import React from 'react'

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import StandardList from '../../components/StandardList/StandardList';

import Badge from '../../components/Badge.js'


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormatDate } from '../../helpers';
import ControlBar from '../../components/ControlBar';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import GrowZoneStatus from './GrowZones/Status';
import GrowZoneConfig from './GrowZones/Config';
import GrowZoneService from './GrowZones/Service';
import { getAllServicesForFacility, selectAllFacilities } from '../../redux/entities/Facilities';
import { getVerticalRackGroupByServiceId, selectAllVerticalRackGroups } from '../../redux/entities/service/VerticalRackGroup';
import GrowRackService from './GrowRacks/Status';
import NDSService from './NDSs/Status';
import { getNDSByServiceId, selectAllNDS } from '../../redux/entities/service/NDS';






const ServicesPage = ({setPageHeader, selectedFacility}) => {
  const { facilityID } = useParams();
  
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [setPageHeader])

 
  const location = useLocation()
  const navigate = useNavigate(); 
  const dispatch = useDispatch()
  const validateLocation = () =>  {
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    if (splitLocation.length <= 4)  {
      navigate("/farms/" + selectedFacility + "/services/" + Object.keys(serviceTypeButtons)[0])
    }else {
      SetSelectedServiceType(splitLocation[4])
    }
  }

  React.useEffect(() => {
    validateLocation()
  }, [location])
  React.useLayoutEffect(() => {
    validateLocation()
  }, [])



  const [serviceTypeButtons, SetServiceTypeButtons] = React.useState({
    "grow_racks": {label: "Racks", icon: null},
    "grow_zones": {label: "Grow Zones", icon: null},
    "nds": {label: "NDS", icon: null},
    /*"hvac": {label: "HVAC", icon: null},*/
  })
  const [selectedServiceType, SetSelectedServiceType] = React.useState(null)
  const serviceTypeSelected = React.useCallback((key) => {
    const { pathname } = location;
    let splitLocation = pathname.split("/");
    splitLocation[4] = key
    navigate(splitLocation.join("/"))
  })



  const facilities = useSelector(selectAllFacilities)
  const verticalRackGroups = useSelector(selectAllVerticalRackGroups)
  const NDSs = useSelector(selectAllNDS)
  React.useEffect(() => {
    const { pathname } = location;
    let splitLocation = pathname.split("/");
    let serviceType = splitLocation[4]

    let foundFacility = facilities.find((f) => f.id === selectedFacility)
    if (foundFacility !== undefined)  {
      if (!foundFacility.initialServicesLoaded)  {
        dispatch(getAllServicesForFacility({facilityId: foundFacility.id}))
      }else {
      
        let verticalRackGroupServicesToLoad = []
        let verticalRackGroupServiceUIDs = {}
        for (let service of foundFacility.services.filter((s) => s.service_type === "vertical_rack_group")) {
          let foundVerticalRackGroupService = verticalRackGroups.find((vRG) => vRG.serviceId === service.id)
          if (foundVerticalRackGroupService === undefined)  {
            verticalRackGroupServicesToLoad.push(service.id)
            verticalRackGroupServiceUIDs[service.id] = service.uid
          }
        }
        if (verticalRackGroupServicesToLoad.length > 0) {
          dispatch(getVerticalRackGroupByServiceId({verticalRackGroupServiceIds: verticalRackGroupServicesToLoad, verticalRackGroupServiceUIDs: verticalRackGroupServiceUIDs}))
        }

        let NDSServicesToLoad = []
        for (let service of foundFacility.services.filter((s) => s.service_type === "nds")) {
          let foundNDSService = NDSs.find((vRG) => vRG.serviceId === service.id)
          if (foundNDSService === undefined)  {
            NDSServicesToLoad.push(service.id)
          }
        }
        if (NDSServicesToLoad.length > 0) {
          dispatch(getNDSByServiceId({NDSServiceIds: NDSServicesToLoad}))
        }

      }
      //console.log(foundFacility.services)
    }
  }, [facilities, selectedFacility, location])


  const [secondaryControls, SetSecondaryControls] = React.useState(<></>)

  return (<>
    <div id="ServicesPage">
      <ControlBar controls={<>
        <ButtonGroup buttons={serviceTypeButtons} activeButton={selectedServiceType} onButtonClicked={serviceTypeSelected}/>
      </>} secondaryControls={<>
        {secondaryControls}
      </>}/>
 

      <Routes>
        <Route path="/grow_racks" element={
          <GrowRackService selectedFacility={selectedFacility} setSecondaryControls={(content) => SetSecondaryControls(content)}/>
        }/>
        <Route path="/grow_zones" element={
          <GrowZoneStatus selectedFacility={selectedFacility} setSecondaryControls={(content) => SetSecondaryControls(content)}/>
        }/>
        <Route path="/hvac" element={
          <>HVAC</>
        }/>
        <Route path="/nds" element={
          <NDSService selectedFacility={selectedFacility} setSecondaryControls={(content) => SetSecondaryControls(content)}/>
        } />
      </Routes>


    </div>
  </>)
} 

export default ServicesPage