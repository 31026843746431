import './GrowZoneType.scss';
import React from 'react';
import Button from '../components/Button';


export const GrowZoneTile = ({growZoneType, isSelected, onGrowZoneTypeSelect}) => {


  const onGrowZoneTypeSelected = (e) => {
    if (onGrowZoneTypeSelect !== undefined) {
      onGrowZoneTypeSelect()
    }
  }
  
  
  return (
   
    <Button 
      content={(growZoneType === "any") ? "Any" : growZoneType.name}
      status={isSelected ? "Primary" : "Neutral"}
      onClick={onGrowZoneTypeSelected}/>
  )
} 


export const GrowZoneTypes = [
  {id: 1, name: "Gen 3S", number_of_rafts: 36, columns: 6},
  {id: 2, name: "Gen 3L", number_of_rafts: 144, columns: 9}
]

export const SelectGrowZoneType = ({
    allowAnyOption, selectedGrowZoneType, onGrowZoneTypeSelect,
    allowMultiselect, selectedGrowZoneTypes, onGrowZoneTypesSelect
  }) => {


  const [currentSelectedGrowZoneType, SetSelectedGrowZoneType] = React.useState(undefined)
  React.useState(() => {
    if (selectedGrowZoneType !== currentSelectedGrowZoneType) {
      SetSelectedGrowZoneType(selectedGrowZoneType)
    }
  }, selectedGrowZoneTypes)

  const [currentSelectedGrowZoneTypes, SetSelectedGrowZoneTypes] = React.useState(selectedGrowZoneTypes)
  React.useState(() => {
    if (selectedGrowZoneTypes !== currentSelectedGrowZoneTypes) {
      SetSelectedGrowZoneTypes([...selectedGrowZoneTypes])
    }
  }, selectedGrowZoneTypes)


  
  const onAnyGrowZoneSelected = (e) =>  {
    SetSelectedGrowZoneType("any")
    if (onGrowZoneTypeSelect !== undefined) {
      onGrowZoneTypeSelect("any")
    }
  }
  
  return (
    <div className="SelectGrowZoneType-Container">
      {allowAnyOption && 
          <GrowZoneTile
            growZoneType={"any"}
            isSelected={currentSelectedGrowZoneType === "any"}
            onGrowZoneTypeSelect={onAnyGrowZoneSelected}/>
        }
      {GrowZoneTypes.map((growZoneType) => {

        const onGrowZoneSelected = (e) =>  {
          if (!allowMultiselect)  {
            SetSelectedGrowZoneType(growZoneType)
            if (onGrowZoneTypeSelect !== undefined) {
              onGrowZoneTypeSelect(growZoneType)
            }
          }else {
            let foundGrowZoneType = currentSelectedGrowZoneTypes.find((gZT) => gZT.id === growZoneType.id)
            if (foundGrowZoneType === undefined)  {
              currentSelectedGrowZoneTypes.push(growZoneType)
            }else {
              currentSelectedGrowZoneTypes.pop(currentSelectedGrowZoneTypes.indexOf(foundGrowZoneType))
            }
            SetSelectedGrowZoneTypes([...currentSelectedGrowZoneTypes])
  
            if (onGrowZoneTypesSelect !== undefined) {
              onGrowZoneTypesSelect(currentSelectedGrowZoneTypes)
            }
          }
        }
        
        let isSelected
        if (!allowMultiselect)  {
          isSelected = currentSelectedGrowZoneType !== undefined && currentSelectedGrowZoneType.id === growZoneType.id
        }else {
          isSelected = currentSelectedGrowZoneTypes.find((gZT) => gZT.id === growZoneType.id) !== undefined
        }

        return (
          <GrowZoneTile
            key={growZoneType.id}
            growZoneType={growZoneType}
            isSelected={isSelected}
            onGrowZoneTypeSelect={onGrowZoneSelected}/>
        )
      })}
    </div>
  )
} 



SelectGrowZoneType.defaultProps = {
  allowAnyOption: false
}