import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { components } from 'react-select'
import { binaryClosestIdx } from '../../../helpers'
import  {Repository as APIRepository, FetchPost} from '../../api'


const processVerticalRackGroupFromAPI = (state, verticalRackGroup) =>  {
  //verticalRackGroup.lastStatusUpdatedOn = new Date().getTime();
  
  verticalRackGroup.loadedFromDatabase = true;

  //Find the UID
  /*const facilityInfo = state => state.facilities.facilities.find((f) => f.id === verticalRackGroup.facility_id)
  console.log(facilityInfo)
  const foundService = facilityInfo.services.find((s) => s.id === verticalRackGroup.service_id)
  verticalRackGroup.uid = foundService.uid*/

  if (verticalRackGroup.rack_map) {
    for (let verticalRack of verticalRackGroup.rack_map.racks)  {
      verticalRack.vertical_rack_group_id = verticalRackGroup.id
      verticalRack = processVerticalRackFromAPI(verticalRack)
    }
  }else {
    verticalRackGroup.rack_map = {racks: []}
  }

  if (verticalRackGroup.zone_map) {
    for (let standardGrowOutZone of verticalRackGroup.zone_map.standard_grow_out_zones)  {
      standardGrowOutZone.vertical_rack_group_id = verticalRackGroup.id
      standardGrowOutZone = processStandardGrowOutZoneFromAPI(standardGrowOutZone)
    }
  }

  return verticalRackGroup
}

const processVerticalRackFromAPI = (verticalRack) =>  {
  verticalRack.loadedFromDatabase = true;

  verticalRack.lastStatusUpdatedOn = new Date().getTime();
  verticalRack.lastLiveDataUpdatedOn = 0;

  if (verticalRack.liveData === undefined)  {
    verticalRack.liveData = {}
  }

  if (verticalRack.analyticsData === undefined)  {
    verticalRack.analyticsData = {}
  }

  if (verticalRack.initializedDataRecordingTimePeriodTypes === undefined)  {
    verticalRack.initializedDataRecordingTimePeriodTypes = false
  }

  if (verticalRack.runtime_information === undefined)  {
    verticalRack.runtime_information = {}
  }
  


  return verticalRack
}



const processStandardGrowOutZoneFromAPI = (growOutZone) =>  {
  growOutZone.lastStatusUpdatedOn = new Date().getTime();
  growOutZone.components = []

  return growOutZone
}



const uniqueConfigurationMapKeys = {
  "AuxALiquidSystemInstalled": "auxalsi",
  "AuxBLiquidSystemInstalled": "auxblsi",
}
export const getUniqueConfigurationByName = (verticalRack, name) =>  {
  if (verticalRack.unique_configuration === undefined)  {
    return undefined;
  }
  if (uniqueConfigurationMapKeys[name] === undefined) {
    return undefined;
  }
  return verticalRack.unique_configuration.properties[uniqueConfigurationMapKeys[name]];
}


export const getLiveDataItem = (verticalRack, componentMap, componentName, identifiers) =>  {
  let components = componentMap.components.filter((c) => c.name === componentName)
  if (components.length <= 1)  {
    let identifierInfos = []
    let dataItems = []
    for (let identifier of identifiers) {
      let identifierInfo = components[0] !== undefined ? (components[0].data_types ? components[0].data_types.find((dT) => dT.identifier === identifier) : undefined) : undefined
      identifierInfos.push(identifierInfo)
      dataItems.push(identifierInfo !== undefined && verticalRack.liveData[components[0].id] !== undefined && verticalRack.liveData[components[0].id][identifierInfo.identifier] !== undefined ? verticalRack.liveData[components[0].id][identifierInfo.identifier] : null)
    }
    return [components[0], identifierInfos, dataItems]
  }else {
    let componentPackages = []
    for (let component of components) {
      let identifierInfos = []
      let dataItems = []
      for (let identifier of identifiers) {
        let identifierInfo = components[0] !== undefined ? (components[0].data_types ? components[0].data_types.find((dT) => dT.identifier === identifier) : undefined) : undefined
        identifierInfos.push(identifierInfo)
        dataItems.push(identifierInfo !== undefined && verticalRack.liveData[components[0].id] !== undefined && verticalRack.liveData[components[0].id][identifierInfo.identifier] !== undefined ? verticalRack.liveData[components[0].id][identifierInfo.identifier] : null)
      }
      componentPackages.push([component, identifierInfos, dataItems])
    }
    return componentPackages
  }
}




export const getVerticalRackGroupById = createAsyncThunk('verticalRackGroup/getVerticalRackGroupById', async ({verticalRackGroupId, verticalRackGroupIds}) => {
  let payload = {
    account_id: 29,
  }
  if (verticalRackGroupId !== undefined) {
    payload.vertical_rack_group_id = verticalRackGroupId
  }
  if (verticalRackGroupIds !== undefined) {
    payload.vertical_rack_group_ids = verticalRackGroupIds
  }
  return await FetchPost(APIRepository.VerticalRackGroup.GetVerticalRackGroupById, payload)  
},
{
  condition: (args, { getState }) => {
    const { verticalRackGroups } = getState()
    if (verticalRackGroups.status === 'pending') {
      return false
    }
  },
})


export const getVerticalRackGroupByServiceId = createAsyncThunk('verticalRackGroup/getVerticalRackGroupByServiceId', async ({verticalRackGroupServiceId, verticalRackGroupServiceIds, verticalRackGroupServiceUIDs}) => {
  let payload = {
    account_id: 29,
  }
  if (verticalRackGroupServiceId !== undefined) {
    payload.service_id = verticalRackGroupServiceId
  }
  if (verticalRackGroupServiceIds !== undefined) {
    payload.service_ids = verticalRackGroupServiceIds
  }
  return await FetchPost(APIRepository.VerticalRackGroup.GetVerticalRackGroupByServiceId, payload)  
},
{
  condition: (args, { getState }) => {
    const { verticalRackGroups } = getState()
    if (verticalRackGroups.status === 'pending') {
      return false
    }
  },
})



export const getVerticalRackStatusById = createAsyncThunk('verticalRackGroup/getVerticalRackStatusById', async ({verticalRackId, verticalRackIds}) => {
  let payload = {
    account_id: 29,
  }
  if (verticalRackId !== undefined) {
    payload.rack_id = verticalRackId
  }
  if (verticalRackIds !== undefined) {
    payload.rack_ids = verticalRackIds
  }
  return await FetchPost(APIRepository.VerticalRackGroup.GetVerticalRackStatusById, payload)  
},
{
  condition: (args, { getState }) => {
    const { verticalRackGroups } = getState()
    if (verticalRackGroups.settingRackRuntimeProperty === 'pending') {
      return false
    }
  },
})



export const setVerticalRackRuntimeProperty = createAsyncThunk('verticalRackGroup/setVerticalRackRuntimeProperty', async ({verticalRackId, properties}) => {
  let payload = {
    account_id: 29,
    rack_id: verticalRackId,
    properties: properties
  }
  return await FetchPost(APIRepository.VerticalRackGroup.SetVerticalRackRuntimeProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { verticalRackGroups } = getState()
    if (verticalRackGroups.settingRackRuntimeProperty === 'pending') {
      return false
    }
  },
})


export const getVerticalRackLiveDataById = createAsyncThunk('verticalRackGroup/getVerticalRackLiveDataById', async ({verticalRackId, verticalRackIds}) => {
  let payload = {
    account_id: 29,
  }
  if (verticalRackId !== undefined) {
    payload.rack_id = verticalRackId
  }
  if (verticalRackIds !== undefined) {
    payload.rack_ids = verticalRackIds
  }


  return await FetchPost(APIRepository.VerticalRackGroup.GetVerticalRackLiveDataById, payload)  
},
{
  condition: (args, { getState }) => {
    const { verticalRackGroups } = getState()
    if (verticalRackGroups.loadingRackLiveData === 'pending') {
      return false
    }
  },
})


export const getVerticalRackAnalyticsData = createAsyncThunk('verticalRackGroup/getVerticalRackAnalyticsData', async ({entries}) => {
  let payload = {
    account_id: 29,
    entries: entries //map[int]map[string]map[int][]int -- time period type id : map of start/end date string to map of rack ids to list of component ids
  }
  return await FetchPost(APIRepository.VerticalRackGroup.GetVerticalRackAnalyticsData, payload)  
}/*,
{
  condition: (args, { getState }) => {
    const { verticalRackGroups } = getState()
    if (verticalRackGroups.loadingRackAnalyticsData === 'pending') {
      return false
    }
  },
}*/)



export const getVerticalRackConfigurationMap = createAsyncThunk('verticalRackGroup/getVerticalRackConfigurationMap', async ({maps}) => {
  let payload = {
    account_id: 29,
    maps: maps //Must be []configurationId:{mapKey:*version
  }
  return await FetchPost(APIRepository.VerticalRackGroup.GetVerticalRackConfigurationMap, payload)  
},
{
  condition: (args, { getState }) => {
    const { verticalRackGroups } = getState()
    if (verticalRackGroups.loadingConfigurationMaps === 'pending') {
      return false
    }
  },
})



export const getVerticalRackZoneConfigurationMap = createAsyncThunk('verticalRackGroup/getVerticalRackZoneConfigurationMap', async ({maps}) => {
  let payload = {
    account_id: 29,
    maps: maps //Must be []configurationId:{mapKey:*version
  }
  return await FetchPost(APIRepository.VerticalRackGroup.GetVerticalRackZoneConfigurationMap, payload)  
},
{
  condition: (args, { getState }) => {
    const { verticalRackGroups } = getState()
    if (verticalRackGroups.loadingConfigurationMaps === 'pending') {
      return false
    }
  },
})




export const validateVerticalRackSystemConnection = createAsyncThunk('verticalRackGroup/validateVerticalRackSystemConnection', async ({rackCode, callback}) => {
  let payload = {
    account_id: 29,
    rack_code: rackCode,
  }

  return await FetchPost(APIRepository.VerticalRackGroup.ValidateVerticalRackSystemConnection, payload)  
},
{
  condition: (args, { getState }) => {
    const { verticalRackGroups } = getState()
    if (verticalRackGroups.validatingVerticalRackConnectedToSystem === 'pending') {
      return false
    }
  },
})


export const createNewVerticalRackGroup = createAsyncThunk('verticalRackGroup/createNewVerticalRackGroup', async ({facilityId, groupName, uid}) => {
  let payload = {
    account_id: 29,
    facility_id: facilityId,
    group_name: groupName,
    uid: uid
  }

  console.log(payload)

  return await FetchPost(APIRepository.VerticalRackGroup.CreateNewVerticalRackGroup, payload)  
},
{
  condition: (args, { getState }) => {
    const { verticalRackGroups } = getState()
    if (verticalRackGroups.creatingNewVerticalRackGroup === 'pending') {
      return false
    }
  },
})



export const verticalRackGroupsSlice = createSlice({
  name: 'verticalRackGroups',
  initialState: {
    verticalRackGroups:  [

    ],
    tempVerticalRackGroupId: 1,
    tempVerticalRackId: 1,
    configurationMaps: [],
    zoneConfigurationMaps: [],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false,
    loadingRackStatus: 'idle',
    settingRackRuntimeProperty: 'idle',
    loadingRackLiveData: 'idle',
    loadingRackAnalyticsData: 'idle',
    loadingConfigurationMaps: 'idle',

    creatingNewVerticalRackGroup: 'idle',
    validatingVerticalRackConnectedToSystem: 'idle',
  },
  reducers: {
    initializeComponentForAnalyticsData: (state, action) =>  { //Action includes rack group id, zone id, rack id, component id
      let newVerticalRackGroups = { ...state, verticalRackGroups: state.verticalRackGroups.map((verticalRackGroup, index) => {
        if (verticalRackGroup.id !== action.payload.verticalRackGroupId) {
          return verticalRackGroup
        }

        let updatedRacks = verticalRackGroup.rack_map.racks.map((verticalRack, index) => {
          if (verticalRack.id !== action.payload.verticalRackId)  {
            return verticalRack
          }

          if (verticalRack.analyticsData[action.payload.rackComponentId] !== undefined) {
            return verticalRack
          }


          let dataTimePeriods = {...verticalRack.analyticsData, [action.payload.rackComponentId]: {}}
          for (let dataRecordingTimePeriodType of action.payload.dataRecordingTimePeriodTypes)  {
            dataTimePeriods[action.payload.rackComponentId][dataRecordingTimePeriodType.index] = {
              loadingStatus: "idle",
              data: {},
              dataChunks: {},
              haveNewData: {},
              haveDataUpUntil: 0,
              changedVersion: 0,
  
              energyData: {},
              energyDataChunks: {},
              haveNewEnergyData: {},
              haveEnergyDataUpUntil: 0,
              changedEnergyVersion: 0
  
  
            }
          }

          return {
            ...verticalRack,
            analyticsData: dataTimePeriods,
            initializedDataRecordingTimePeriodTypes: true
          }
          
        })


        let updatedZones = verticalRackGroup.zone_map.standard_grow_out_zones.map((growOutZone, index) => {
          if (growOutZone.id !== action.payload.growOutZoneId)  {
            return growOutZone
          }
          //console.log(growOutZone.components)
          let exists = false
          let newZoneComponentPayload = {id: action.payload.zoneComponentId, componentInfo: action.payload.componentInfo, rackId: action.payload.verticalRackId}
          let zoneComponents = growOutZone.components.map((zoneComponent) => {
            if (zoneComponent.id !== action.payload.zoneComponentId) {
              return zoneComponent
            }
            exists = true
            return newZoneComponentPayload
          })

          if (!exists)  {
            zoneComponents.push(newZoneComponentPayload)
          }

          return {
            ...growOutZone,
            components: [...zoneComponents]
          }
          
        })


        return {
          ...verticalRackGroup,
          rack_map: {
            ...verticalRackGroup.rack_map, 
            racks: updatedRacks
          },
          zone_map: {
            ...verticalRackGroup.zone_map,
            standard_grow_out_zones: [...updatedZones]
          }
        }
      })}


      return newVerticalRackGroups

    }, createNewGroup: (state, action) =>  { //Action includes rack group id, zone id, rack id, component id
      return { ...state, verticalRackGroups: [...state.verticalRackGroups, 
        {
          loadedFromDatabase: false,
          rack_map: {
            racks: []
          },
          id: -1,
          temp_id: state.tempVerticalRackGroupId,
          saved: false,
          isNew: true,
          canRedo: false,
          canUndo: false,
          changeStack: [],
          display_name: "New Vertical Rack Group"
        }
      ], tempVerticalRackGroupId: state.tempVerticalRackGroupId + 1}
    }, removeGroup: (state, action) =>  { //Action includes rack group id, zone id, rack id, component id
      return {...state, verticalRackGroups: [...state.verticalRackGroups.filter((vRG) => {
        if (action.payload.tempId != undefined && vRG.temp_id !== undefined && vRG.temp_id === action.payload.tempId) {
          return false
        }
        return true
      })]}
    }, 
    
    
    addRackToGroup: (state, action) =>  { //Action includes rack group id, zone id, rack id, component id
      return {...state, verticalRackGroups: [...state.verticalRackGroups.map((vRG) => {
        let foundVerticalRackGroup = null;
        if (action.payload.tempId != undefined && vRG.temp_id !== undefined && vRG.temp_id === action.payload.tempId) {
          foundVerticalRackGroup = vRG;
        }else if (action.payload.id != undefined && vRG.id !== undefined && vRG.id === action.payload.id) {
          foundVerticalRackGroup = vRG;
        }
        if (foundVerticalRackGroup !== null)  {

          return {...vRG, rack_map: {
            racks: [...vRG.rack_map.racks, {
              loadedFromDatabase: false,
              id: -1,
              temp_id: state.tempVerticalRackId,
              saved: false,
              isNew: true,
              display_name: "New Rack",
              serial_number_validated: false,
            }]
          }}
        }
        return vRG
      })], tempVerticalRackId: state.tempVerticalRackId + 1}
    },



    activateTemporaryEditMode: (state, action) =>  { //Action includes rack group id, zone id, rack id, component id
      return {...state, verticalRackGroups: [...state.verticalRackGroups.map((vRG) => {
        let foundVerticalRackGroup = null;
        if (action.payload.tempId != undefined && vRG.temp_id !== undefined && vRG.temp_id === action.payload.tempId) {
          foundVerticalRackGroup = vRG;
        }else if (action.payload.id != undefined && vRG.id !== undefined && vRG.id === action.payload.id) {
          foundVerticalRackGroup = vRG;
        }
        if (foundVerticalRackGroup !== null)  {

          return {...vRG, temporaryEditMode: true}
        }
        return vRG
      })]}
    },


    showRackStatusContent: (state, action) =>  { //Action includes rack group id, zone id, rack id, component id
      return {...state, verticalRackGroups: [...state.verticalRackGroups.map((vRG) => {
        if (vRG.id === action.payload.group_id) {
          return {...vRG, rack_map: {...vRG.rack_map, racks: [...vRG.rack_map.racks.map((vR) => {
            if (vR.id === action.payload.rack_id) {
              return {...vR, showStatusContent: true} 
            }
            return vR
          })]}}
        }
        return vRG
      })]}
    },

    hideRackStatusContent: (state, action) =>  { //Action includes rack group id, zone id, rack id, component id
      return {...state, verticalRackGroups: [...state.verticalRackGroups.map((vRG) => {
        if (vRG.id === action.payload.group_id) {
          return {...vRG, rack_map: {...vRG.rack_map, racks: [...vRG.rack_map.racks.map((vR) => {
            if (vR.id === action.payload.rack_id) {
              return {...vR, showStatusContent: false} 
            }
            return vR
          })]}}
        }
        return vRG
      })]}
    },


  },
  extraReducers: {

    [getVerticalRackGroupById.pending]: (state) => {
      state.status = 'pending';
    },

    [getVerticalRackGroupById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      /*if (action.payload.nds !== null) {
        state.NDS.push(processNDSFromAPI(action.payload.nds))
      }*/
      if (action.payload.verticalRackGroups !== null) {
        for (let verticalRackGroup of action.payload.vertical_rack_groups)  {
          if (verticalRackGroup)  {
            state.verticalRackGroups.push(processVerticalRackGroupFromAPI(state, verticalRackGroup))
          }
        }
      }
    },

    [getVerticalRackGroupById.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getVerticalRackGroupByServiceId.pending]: (state) => {
      state.status = 'pending';
    },

    [getVerticalRackGroupByServiceId.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload.vertical_rack_groups)
      if (action.payload.vertical_rack_groups !== null) {
        for (let verticalRackGroup of action.payload.vertical_rack_groups)  {
          if (verticalRackGroup)  {
            let exists = false
            for (let existingVerticalRackGroup of state.verticalRackGroups) {
              if (existingVerticalRackGroup.id === verticalRackGroup.id)  {
                //Get the UID
                
                existingVerticalRackGroup = processVerticalRackGroupFromAPI(state, {...existingVerticalRackGroup, ...verticalRackGroup})
                exists = true
                break
              }
            }
            if (!exists)  {
              state.verticalRackGroups.push(processVerticalRackGroupFromAPI(state, verticalRackGroup))              
            }
          }
        }
      }
    },

    [getVerticalRackGroupByServiceId.rejected]: (state) => {
      state.status = 'rejected';
    },



    [getVerticalRackStatusById.pending]: (state) => {
      state.loadingRackStatus = 'pending';
    },

    [getVerticalRackStatusById.fulfilled]: (state, action) => {
      state.loadingRackStatus = 'fulfilled';
      //console.log(action.payload.vertical_racks)
      if (action.payload.vertical_racks !== null) {
        for (let verticalRack of action.payload.vertical_racks)  {
          if (verticalRack)  {
            for (let existingVerticalRackGroup of state.verticalRackGroups) {
              let foundRackGroup = false
              for (let i in existingVerticalRackGroup.rack_map.racks) {
                if (existingVerticalRackGroup.rack_map.racks[i].id === verticalRack.id)  {
                  existingVerticalRackGroup.rack_map.racks[i] = processVerticalRackFromAPI({...existingVerticalRackGroup.rack_map.racks[i], ...verticalRack})
                  foundRackGroup = true
                  break
                }
              }
              if (foundRackGroup) {
                break
              }
            }
          }
        }
      }
    },

    [getVerticalRackStatusById.rejected]: (state) => {
      state.loadingRackStatus = 'rejected';
    },
    


    [setVerticalRackRuntimeProperty.pending]: (state) => {
      state.settingRackRuntimeProperty = 'pending';
    },

    [setVerticalRackRuntimeProperty.fulfilled]: (state, action) => {
      state.settingRackRuntimeProperty = 'fulfilled';
    },

    [setVerticalRackRuntimeProperty.rejected]: (state) => {
      state.settingRackRuntimeProperty = 'rejected';
    },
    
    


    [getVerticalRackLiveDataById.pending]: (state) => {
      state.loadingRackLiveData = 'pending';
    },

    [getVerticalRackLiveDataById.fulfilled]: (state, action) => {
      state.loadingRackLiveData = 'fulfilled';
      if (action.payload.live_data !== null) {
        for (let [verticalRackId, liveData] of Object.entries(action.payload.live_data))  {
          for (let existingVerticalRackGroup of state.verticalRackGroups) {
            let foundRackGroup = false
            for (let i in existingVerticalRackGroup.rack_map.racks) {
              if (existingVerticalRackGroup.rack_map.racks[i].id === parseInt(verticalRackId))  {
                existingVerticalRackGroup.rack_map.racks[i].liveData = liveData
                foundRackGroup = true
                break
              }
            }
            if (foundRackGroup) {
              break
            }
          }
        }
      }
    },

    [getVerticalRackLiveDataById.rejected]: (state) => {
      state.loadingRackLiveData = 'rejected';
    },
    
    

    [getVerticalRackAnalyticsData.pending]: (state) => {
      state.loadingRackAnalyticsData = 'pending';
    },

    [getVerticalRackAnalyticsData.fulfilled]: (state, action) => {
      state.loadingRackAnalyticsData = 'fulfilled';
      if (action.payload.analytics_data !== null) {
        //console.log(action.payload.analytics_data)
        for (let componentDataChunk of action.payload.analytics_data) {
          state.verticalRackGroups = state.verticalRackGroups.map((vRG) => {
            let foundRackGroup = false
            let updatedRacks = vRG.rack_map.racks.map((verticalRack) => {
              if (verticalRack.id !== componentDataChunk.rack_id) {
                return verticalRack
              }
            
              if (componentDataChunk["component_id"] in verticalRack.analyticsData)  {
                let componentDataTimePeriodsTypes = verticalRack.analyticsData[componentDataChunk["component_id"]]
                const currentDataRecordingTimePeriodType = action.meta.arg.timePeriodTypes.find((dataRecordingTimePeriodType) => { return dataRecordingTimePeriodType.id === componentDataChunk["time_period_type_id"]; })
                if (currentDataRecordingTimePeriodType !== undefined && componentDataChunk["time_period_type_id"] in componentDataTimePeriodsTypes) {
                  let componentData = componentDataTimePeriodsTypes[componentDataChunk["time_period_type_id"]]
                  for (let dataChunk of componentDataChunk["data"])  {
                    const chunkStartedOn = parseInt(dataChunk["entry_index"]) * currentDataRecordingTimePeriodType.duration * 1000
                    var newData = false;

                    let currentDataChunk = componentData.dataChunks[parseInt(dataChunk["entry_index"])]
                    if (currentDataChunk === undefined) {
                      currentDataChunk = componentData.dataChunks[parseInt(dataChunk["entry_index"])] = dataChunk
                    }
                    
                    //console.log(currentDataChunk)



                    if (dataChunk.completed)  {


                      const dataItemList = dataChunk.values.split('\n')
                      
                      const processedData = {}
            
                      //console.log(dataChunk.values)
                      for (let dataItemString of dataItemList)  {
                        const dataItemInfo = dataItemString.split(',')
                        const identifier = dataItemInfo[0]
                        let forTime
                        if (currentDataRecordingTimePeriodType["max_number_of_points"] == 0)  {
                          forTime = chunkStartedOn + parseInt(dataItemInfo[1])
                        }else {
                          forTime = chunkStartedOn + parseInt(dataItemInfo[1] * 1000)
                        }
                          
                        const value = parseFloat(dataItemInfo[2])
                        const flag = dataItemInfo[3]
          
                        if (processedData[identifier] === undefined) {
                          processedData[identifier] = []            
                        }
                        processedData[identifier].push({x: forTime - action.meta.arg.dateOffset, y: value})
          
                        componentData.haveNewData[identifier] = true
                        newData = true;
                        
                      }
            
                      for (let identifier in processedData)  {
                        if (componentData.data[identifier] === undefined) {
                          componentData.data[identifier] = []            
                        }
                        if (identifier === "lightr" || identifier === "lightg" || identifier === "lightb" || identifier === "lightfr")  {
                          processedData[identifier].sort( function(a , b)  {
                            if(a.x > b.x) 
                              return 1;
                            if(a.x < b.x) 
                              return -1;
                            return 0
                            
                          });
            
                          let lastValue = null
                          for (let dataItem of processedData[identifier]) {
                            if (!lastValue && componentData.data[identifier].length > 0)  {
                              let lastDataItem = binaryClosestIdx(componentData.data[identifier], dataItem.x - 1, 'x')
                              if (lastDataItem && componentData.data[identifier][lastDataItem] !== undefined) {
                                if (componentData.data[identifier][lastDataItem].x < dataItem.x)  {
                                  lastValue = componentData.data[identifier][lastDataItem].y
                                }
                              }
                            }
                            if (lastValue)  {
                              componentData.data[identifier].push({x: dataItem.x - 1 - action.meta.arg.dateOffset, y: lastValue})
                            }
                            componentData.data[identifier].push({x: dataItem.x - action.meta.arg.dateOffset, y: dataItem.y})
                            lastValue = dataItem.y
                          }
                        }else {
                          componentData.data[identifier].push(...processedData[identifier])
                        }
                      }
            
            
            
                    }else {
                      //Live data chunk
                      for (let identifier in dataChunk["live_values"]) {
                        let isNew = false
            
                        if (componentData.data[identifier] === undefined) {
                          componentData.data[identifier] = []               
                          isNew = true
                        }
            
                        let lastValue = null
                        for (let forTime in dataChunk["live_values"][identifier]) {
                          const pointTime = forTime
                          if (identifier === "lightr" || identifier === "lightg" || identifier === "lightb" || identifier === "lightfr")  {
                            if (!lastValue && componentData.data[identifier].length > 0)  {
                              let lastDataItem = binaryClosestIdx(componentData.data[identifier], pointTime - 1, 'x')
                              if (lastDataItem && componentData.data[identifier][lastDataItem] !== undefined) {
                                if (componentData.data[identifier][lastDataItem].x < pointTime)  {
                                  lastValue = componentData.data[identifier][lastDataItem].y
                                }
                              }
                            }
                            if (lastValue)  {
                              componentData.data[identifier].push({x: pointTime - 1 - action.meta.arg.dateOffset, y: lastValue})
                            }
                            componentData.data[identifier].push({x: pointTime - action.meta.arg.dateOffset, y: dataChunk["live_values"][identifier][forTime]})
                          }else {
                            componentData.data[identifier].push({x: pointTime - action.meta.arg.dateOffset, y: dataChunk["live_values"][identifier][forTime]})
                          }
                          newData = true;
                          componentData.haveNewData[identifier] = true
                          
                          if (pointTime > componentData.haveDataUpUntil)  {
                            componentData.haveDataUpUntil = parseInt(pointTime)
                          }
          
                          lastValue = dataChunk["live_values"][identifier][forTime]
                        }
                      }
            
                      currentDataChunk.to = componentData.haveDataUpUntil
                    }
            
            
                    if (newData)  { 
                      for (let identifier in componentData.data)  {
                        componentData.data[identifier].sort( function(a , b)  {
                          if(a.x > b.x) 
                            return 1;
                          if(a.x < b.x) 
                            return -1;
                          return 0
                          
                        });
                      }
            
                      componentData.changedVersion += 1
                    }
                    
                  }
                }
                
              }

              return {
                ...verticalRack,
                //analyticsData: updatedAnalyticsData
              }
            })

            return vRG
          })

        }
      }
    },

    [getVerticalRackAnalyticsData.rejected]: (state) => {
      state.loadingRackAnalyticsData = 'rejected';
    },
    
    
    

    
    [getVerticalRackConfigurationMap.pending]: (state) => {
      state.loadingConfigurationMaps = 'pending';
    },

    [getVerticalRackConfigurationMap.fulfilled]: (state, action) => {
      state.loadingConfigurationMaps = 'fulfilled';
      if (action.payload.maps !== null) {
        for (let [configurationId, maps] of Object.entries(action.payload.maps))  {
          let foundConfigurationMap = state.configurationMaps.find((cM) => cM.id === parseInt(configurationId))
          if (foundConfigurationMap === undefined)  {
            foundConfigurationMap = {id: parseInt(configurationId), component_map: {}, io_map: {}}
            state.configurationMaps.push(foundConfigurationMap)
          }
          for (let [mapKey, map] of Object.entries(maps))  {
            foundConfigurationMap[mapKey] = map
          }
        }
      }
    },

    [getVerticalRackConfigurationMap.rejected]: (state) => {
      state.loadingConfigurationMaps = 'rejected';
    },



    [getVerticalRackZoneConfigurationMap.pending]: (state) => {
      state.loadingConfigurationMaps = 'pending';
    },

    [getVerticalRackZoneConfigurationMap.fulfilled]: (state, action) => {
      state.loadingConfigurationMaps = 'fulfilled';
      if (action.payload.maps !== null) {
        //console.log(action.payload.maps)
        for (let [configurationId, maps] of Object.entries(action.payload.maps))  {
          let foundConfigurationMap = state.zoneConfigurationMaps.find((cM) => cM.id === parseInt(configurationId))
          if (foundConfigurationMap === undefined)  {
            foundConfigurationMap = {id: parseInt(configurationId), component_map: {}, io_map: {}}
            state.zoneConfigurationMaps.push(foundConfigurationMap)
          }
          for (let [mapKey, map] of Object.entries(maps))  {
            foundConfigurationMap[mapKey] = map
          }
        }
      }
    },

    [getVerticalRackZoneConfigurationMap.rejected]: (state) => {
      state.loadingConfigurationMaps = 'rejected';
    },


    [createNewVerticalRackGroup.pending]: (state) => {
      state.creatingNewVerticalRackGroup = 'pending';
    },

    [createNewVerticalRackGroup.fulfilled]: (state, action) => {
      state.creatingNewVerticalRackGroup = 'fulfilled';
      if (action.payload.vertical_rack_group) {
        state.verticalRackGroups.push(processVerticalRackGroupFromAPI(state, action.payload.vertical_rack_group)) 
      } 
    },

    [createNewVerticalRackGroup.rejected]: (state) => {
      state.creatingNewVerticalRackGroup = 'rejected';
    },
    

    [validateVerticalRackSystemConnection.pending]: (state) => {
      state.validatingVerticalRackConnectedToSystem = 'pending';
    },

    [validateVerticalRackSystemConnection.fulfilled]: (state, action) => {
      state.validatingVerticalRackConnectedToSystem = 'fulfilled';
      if (action.meta.arg.callback !== undefined) {
        if (action.payload.connected) {
          action.meta.arg.callback(action.payload.connected)
        }else {
          action.meta.arg.callback(false)
        }
      }
    },

    [validateVerticalRackSystemConnection.rejected]: (state, action) => {
      state.validatingVerticalRackConnectedToSystem = 'rejected';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },
    
  }
})



// Action creators are generated for each case reducer function
export const { initializeComponentForAnalyticsData, createNewGroup, removeGroup, addRackToGroup, 
  activateTemporaryEditMode, showRackStatusContent, hideRackStatusContent} = verticalRackGroupsSlice.actions

export default verticalRackGroupsSlice.reducer

export const selectAllVerticalRackGroups = state => state.verticalRackGroups.verticalRackGroups
export const selectAllVerticalRackConfigurationMaps = state => state.verticalRackGroups.configurationMaps
export const selectAllVerticalRackZoneConfigurationMaps = state => state.verticalRackGroups.zoneConfigurationMaps
