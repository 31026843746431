import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'


const processGrowPlanFromAPI = (growPlan) =>  {
  
  return growPlan
}

export const getAllGrowPlans = createAsyncThunk('growPlans/getAllGrowPlans', async () => {
  return await FetchPost(APIRepository.GrowPlans.GetAllGrowPlansForAccount, {
    account_id: 29,
    have_grow_plans: {}
  })  
})

export const getGrowPlanById = createAsyncThunk('growPlans/getGrowPlanById', async ({growPlanId, growPlans}) => {
  let payload = {
    account_id: 29,
  }
  if (growPlanId !== undefined) {
    payload.grow_plan_id = growPlanId
  }
  if (growPlans !== undefined) {
    payload.grow_plans = growPlans
  }
  return await FetchPost(APIRepository.GrowPlans.GetGrowPlanById, payload)  
},
{
  condition: (args, { getState }) => {
    const { growPlans } = getState()
    if (growPlans.status === 'pending') {
      return false
    }
  },
})

export const createGrowPlan = createAsyncThunk('growPlans/create', async (growPlanData) =>  {
  return await FetchPost(APIRepository.GrowPlans.Create, {
    account_id: 29,
    grow_plan: growPlanData
  })  
})


export const growPlansSlice = createSlice({
  name: 'growPlans',
  initialState: {
    growPlans:  [

    ],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false
  },
  reducers: {
    

    growPlanUpdate: (state, action) => {
      let hasChanged = false
      let newGrowPlans = { ...state, growPlans: state.growPlans.map((growPlan, index) => {
        if (growPlan.id !== action.payload.growPlanId) {
          return growPlan
        }
    
        if (growPlan[action.payload.prop] === undefined || growPlan[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...growPlan,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newGrowPlans
      }
    }
  },
  extraReducers: {
    [getAllGrowPlans.pending]: (state) => {
      state.status = 'pending';
    },
    [getAllGrowPlans.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      action.payload.grow_plans.map(function(growPlan){ processGrowPlanFromAPI(growPlan); return growPlan });
      state.growPlans = action.payload.grow_plans;
    },
    [getAllGrowPlans.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getGrowPlanById.pending]: (state) => {
      state.status = 'pending';
    },

    [getGrowPlanById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.payload.grow_plan !== null) {
        state.growPlans.push(processGrowPlanFromAPI(action.payload.grow_plan))
      }
      if (action.payload.grow_plans !== null) {
        for (let growPlan of action.payload.grow_plans)  {
          state.growPlans.push(processGrowPlanFromAPI(growPlan))
        }
      }
    },

    [getGrowPlanById.rejected]: (state) => {
      state.status = 'rejected';
    },

    
    [createGrowPlan.pending]: (state) => {
      state.status = 'pending';
    },

    [createGrowPlan.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.payload.success) {
        state.growPlans = [...state.growPlans, {...action.meta.arg, id: action.payload.grow_plan_id}]
      }
    },

    [createGrowPlan.rejected]: (state) => {
      state.status = 'rejected';
    }
  }
})



// Action creators are generated for each case reducer function
export const { growPlanUpdate} = growPlansSlice.actions

export default growPlansSlice.reducer

export const selectAllGrowPlans = state => state.growPlans.growPlans

export const selectGrowPlanById = (state, growPlanId) =>
  state.growPlans.growPlans.find(growPlan => growPlan.id === growPlanId)