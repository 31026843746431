import './RecipesPage.scss';
import React from 'react';

import { useMeasure, useMeasureWithRef } from '../../../helpers'
import {FiEdit} from 'react-icons/fi'


const cycleColors = [
    {background: "#E8DECF", foreground: "#916A00"},
    {background: "#E8D0CF", foreground: "#D82C0D"},
    {background: "#CEE8DE", foreground: "#008060"},
]


export const RecipeTimeline = ({
        recipe, 
        timelineItemSelected, selectedTimelineItem, timelineSelectable,
        linkToManage, manageTimelineSelected,
        showProgress, currentProgress
    }) => {

    const [visualizationBind, { width: visualizationWidth}] = useMeasure()
    const [lastVisualizationWidth, SetLastVisualizationWidth] = React.useState(0);
    const [visualizationTicks, SetVisualizationTicks] = React.useState([]);
    const [recipeDuration, SetRecipeDuration] = React.useState(0);
    const [totalDurationDisplay, SetTotalDurationDisplay] = React.useState("");


    const [timeline, SetTimeline] = React.useState([]);
    const [germinationCycles, SetGerminationCycles] = React.useState([]);
    const [nurseryCycles, SetNurseryCycles] = React.useState([]);
    const [growZoneCycles, SetGrowZoneCycles] = React.useState([]);


    const SelectManageTimeline = React.useCallback(() => {
       if (manageTimelineSelected !== undefined)  {
        manageTimelineSelected()
       }
    });

    const timelineItemClicked = React.useCallback((timelineItem) => {
        if (selectedTimelineItem.id != timelineItem.id)  {
            if (timelineItemSelected !== undefined)    {
                timelineItemSelected(timelineItem)
            }
        }
    })

    
  React.useEffect(() => {
    if (recipe === undefined)   {
        SetTimeline([])
        SetRecipeDuration(0)
        SetVisualizationTicks([])
        SetTotalDurationDisplay("")
        return
    }



    
    //Produce the timeline
    let timeline = []
    let currentGerminationCycles = []
    let currentNurseryCycles = []
    let currentGrowZoneCycles = []

    let totalDuration = 0
    if (recipe.timeline_items) {
        let recipeTimelineItems = [...recipe.timeline_items].sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
        for (let timelineItem of recipeTimelineItems)    {
            if (timelineItem.item)  {
                timeline.push({...timelineItem})
                switch (timelineItem.type)  {
                    case "germination_cycle":
                        currentGerminationCycles.push({...timelineItem})
                        totalDuration += timelineItem.item.iterations * timelineItem.item.duration
                        break
                    case "nursery_cycle":
                        currentNurseryCycles.push({...timelineItem})
                        totalDuration += timelineItem.item.iterations * timelineItem.item.duration
                        break
                    
                    case "grow_zone_cycle":
                        currentGrowZoneCycles.push({...timelineItem})
                        totalDuration += timelineItem.item.iterations * timelineItem.item.duration
                        break
                    
                    default:
                        break
                }
            }
        }
    }
    
    SetRecipeDuration(totalDuration)

    //Cycle selection options and visualization sizing
    let currentCycleSelectionOptions = []
    let currentCycleContribution = 0;
    for (let timelineItem of timeline) {
      if (timelineItem.type === "germination_cycle" || timelineItem.type === "nursery_cycle" || timelineItem.type === "grow_zone_cycle") {
        let cycle = timelineItem.item

        let cycleContribution = (cycle.duration * cycle.iterations) / totalDuration
        timelineItem.width = visualizationWidth * cycleContribution
        timelineItem.x = visualizationWidth * currentCycleContribution
        timelineItem.startsOn = currentCycleContribution * totalDuration * 1000
        currentCycleContribution += cycleContribution
        timelineItem.endsOn = currentCycleContribution * totalDuration * 1000
        
        if (timelineSelectable) {
          currentCycleSelectionOptions.push({ value: (cycle.temp_id ? "t" + cycle.temp_id.toString() : cycle.id), label: cycle.name })
        }
      }
    }
    SetTimeline(timeline)
    SetGerminationCycles(currentGerminationCycles)
    SetNurseryCycles(currentNurseryCycles)
    SetGrowZoneCycles(currentGrowZoneCycles)
    



    //Calculate visualization
    const desiredNumberOfTicks = 12
    const hourDuration = 60 * 60
    const dayDuration = hourDuration * 24
    const totalDurationInHours = Math.floor(totalDuration / hourDuration)
    const totalDurationInDays = Math.floor(totalDuration / dayDuration)
    const remainingHours = (totalDuration - (totalDurationInDays * dayDuration)) / hourDuration
    let tickDayDelta = Math.ceil(totalDurationInDays / desiredNumberOfTicks)
    let numberOfTicks, startingDayTick, ticksCoveringDuration, currentDay;
    if (tickDayDelta == 0)  {
      tickDayDelta = 1
      numberOfTicks = totalDurationInDays
      ticksCoveringDuration = currentDay = startingDayTick = 1
    }else {
      numberOfTicks = Math.floor(totalDurationInDays / tickDayDelta)
      ticksCoveringDuration = (numberOfTicks - 1) * tickDayDelta
      startingDayTick = Math.floor((totalDurationInDays - ticksCoveringDuration) / 2)

      currentDay = startingDayTick
    }

    let ticks = []
    while (startingDayTick < totalDurationInDays) {
        if (startingDayTick !== 0)  {
            ticks.push({
                day: startingDayTick, relativePosition: startingDayTick / totalDurationInDays
            })
        }
        startingDayTick += tickDayDelta
    }
    SetVisualizationTicks(ticks)




    let currentTotalDuration = ""
    if (totalDurationInDays !== 0) {
      currentTotalDuration += totalDurationInDays.toString() + "d"
      if (remainingHours !== 0)  {
        currentTotalDuration += " " + remainingHours.toString() + "h"
      }
    }else {
      currentTotalDuration += remainingHours.toString() + "h"
    }
    
    SetTotalDurationDisplay(currentTotalDuration)


  }, [recipe, visualizationWidth])

  
    return (
        <div className="RecipePage_CycleTimeline">
            <div className="RecipePage_CycleTimeline-Visualization">
                <div className="RecipePage_CycleTimeline-VisualizationContainer" {...visualizationBind}>
                <div className="RecipePage_CycleTimeline-VisualizationBars">
                    {timeline.map((timelineItem) => {
                    
                    if (timelineItem.type === "germination_cycle" || timelineItem.type === "nursery_cycle" || timelineItem.type === "grow_zone_cycle") {
                        let cycle = timelineItem.item

                        let currentColorIndex = (cycle.index - 1) % cycleColors.length
                        if (cycleColors[currentColorIndex] === undefined)   {
                            currentColorIndex = 0
                        }
                        
                        let margin = visualizationWidth * 0.005

                        let barStyles = {
                            backgroundColor: cycleColors[currentColorIndex].background, 
                            color: cycleColors[currentColorIndex].foreground,
                            marginLeft: margin,
                            marginRight: margin
                        } 
                        if (selectedTimelineItem !== undefined && selectedTimelineItem.id == timelineItem.id && timelineSelectable)  {
                            barStyles.borderColor = cycleColors[currentColorIndex].foreground
                            barStyles.fontWeight = "bold"
                        }

                        let barProps = {
                            style:barStyles
                        }
                        if (timelineSelectable)  {
                            barProps.onClick = () => {timelineItemClicked(timelineItem)}
                        }

                        let barProgress = 0
                        if (showProgress)   {
                            if (currentProgress !== undefined)  {
                                if (currentProgress >= timelineItem.startsOn && currentProgress <= timelineItem.endsOn) {
                                    barProgress = 1 - ((timelineItem.endsOn - currentProgress) / (timelineItem.endsOn - timelineItem.startsOn))
                                }else if (currentProgress > timelineItem.endsOn)   {
                                    barProgress = 1
                                }
                            }
                        }

                        return (
                            <div className="RecipePage_CycleTimeline-VisualizationBar" 
                            style={{
                                width: timelineItem.width, 
                                left: timelineItem.x,
                            }}
                            key={timelineItem.id}>
                                <div className="RecipePage_CycleTimeline-VisualizationBarContent"
                                {...barProps}>
                                {showProgress && 
                                    <div 
                                        className="RecipePage_CycleTimeline-VisualizationBarProgress"
                                        style={{
                                            width: (timelineItem.width - (margin * 2)) * barProgress,
                                            backgroundColor: "rgba(0, 0, 0, 0.2)"
                                        }}></div>
                                }
                                {timelineItem.width > 40 &&
                                    <span>{"C" + (timelineItem.type === "nursery_cycle" ? "N" : "") + cycle.index}</span> 
                                }
                            </div>
                            </div>
                        )
                    }else {

                        return (
                        <div key={timelineItem.id}>
                        
                        </div>
                        )
                    }}
                    )}
                </div>


                <div className="RecipePage_CycleTimeline-VisualizationTicks">
                    {visualizationTicks.map((tick) => {
                    let tickPosition = visualizationWidth * tick.relativePosition
                    return (
                        <div className="RecipePage_CycleTimeline-VisualizationTickContent"
                        style={{left: tickPosition}}
                        key={tick.day}>
                        <div className="RecipePage_CycleTimeline-VisualizationTick"></div>
                        <div className="RecipePage_CycleTimeline-VisualizationTickLabel">
                            {tick.day}
                        </div>
                        </div>
                    )
                    })}
                </div>
                </div>
                <div className="RecipePage_CycleTimeline-VisualizationDurationDisplay">
                {totalDurationDisplay}
                </div>
                {linkToManage && timelineSelectable && <div className="RecipePage_CycleTimeline-CycleManagementLink" onClick={SelectManageTimeline}>
                    <FiEdit />
                </div>}
            </div>
        </div>
    )
} 

RecipeTimeline.defaultProps = {
  
}