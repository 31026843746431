import './DropDownButton.scss';

import * as React from 'react';
import Button from './Button';

import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {MdKeyboardArrowDown} from 'react-icons/md'



const DropDownButton = ({label, status, actions, onActionClicked}) => {
  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const actionsRef = React.useRef(actions)

  const prevOpen = React.useRef(open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = () => {
    setOpen(false)
  }

  const actionClicked = React.useCallback((action) =>  {
    handleClickAway();
    if (onActionClicked !== undefined)  {
      onActionClicked(action.key)
    }
  })

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  
  return (
      <>
        <div className={"GroupedOptions GroupedOptions-" + status} onClick={handleToggle}>
          <div className="GroupedOptions-Content">
            <div
              className="GroupedOptions-Main"
              ref={anchorRef}>
              <span className="GroupedOptions-Display noselect">
                {label}
              </span>
            </div>
            <div className="GroupedOptions-DropDown noselect">
              <MdKeyboardArrowDown/>
            </div>
          </div>
        </div>
        <Popper 
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Paper>
                  <div className="GroupedOptions-DropDownContent">
                    {actionsRef.current.map((action) => {

                      return (
                        <div
                          key={action.key}
                          className="GroupedOptions-DropDownContent-Item noselect" 
                          onClick={() => {actionClicked(action)}}>
                          <div className="GroupedOptions-DropDownContent-ItemDisplay noselect">{action.label}</div>
                        </div>
                      )}
                    )}
                  </div>
                </Paper>
              </ClickAwayListener>
            </Box>
            </Fade>
          )}
        </Popper>
      </>
  )
} 

DropDownButton.defaultProps = {
  actions: {},
  status: "Neutral",
  label: "",
}

export default DropDownButton