import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../../api'


const processNutrientSolutionFromAPI = (nutrientSolution) =>  {
  
  return nutrientSolution
}

export const getNutrientSolutionById = createAsyncThunk('nutrientSolutions/getNutrientSolutionById', async ({nutrientSolutionId, nutrientSolutions, loadAllInfo}) => {
  let payload = {load_all_info: loadAllInfo !== undefined ? loadAllInfo : false}
  if (nutrientSolutionId !== undefined) {
    payload.nutrient_solution_id = nutrientSolutionId
  }
  if (nutrientSolutions !== undefined) {
    payload.nutrient_solutions = nutrientSolutions
  }
  return await FetchPost(APIRepository.Common.GetNutrientSolutionById, payload)  
},
{
  condition: (args, { getState }) => {
    const { nutrientSolutions } = getState()
    if (nutrientSolutions.status === 'pending') {
      return false
    }
  },
})




export const nutrientSolutionsSlice = createSlice({
  name: 'nutrientSolutions',
  initialState: {
    nutrientSolutions:  [

    ],
    status: 'idle',
    error: null
  },
  reducers: {
  },
  extraReducers: {
    [getNutrientSolutionById.pending]: (state) => {
      state.status = 'pending';
    },

    [getNutrientSolutionById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload)
      if (action.payload.nutrient_solution !== null) {
        state.nutrientSolutions.push(processNutrientSolutionFromAPI(action.payload.nutrient_solution))
      }
      if (action.payload.nutrient_solutions !== null) {
        for (let nutrientSolution of action.payload.nutrient_solutions)  {
          state.nutrientSolutions.push(processNutrientSolutionFromAPI(nutrientSolution))
        }
      }
    },

    [getNutrientSolutionById.rejected]: (state) => {
      state.status = 'rejected';
    },
  }
})



// Action creators are generated for each case reducer function
export const {} = nutrientSolutionsSlice.actions

export default nutrientSolutionsSlice.reducer

export const selectAllNutrientSolutions = state => state.nutrientSolutions.nutrientSolutions

export const selectNutrientSolutionById = (state, nutrientSolutionId) =>
  state.nutrientSolutions.nutrientSolutions.find(nutrientSolution => nutrientSolution.id === nutrientSolutionId)