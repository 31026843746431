import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
//import { useState } from 'react';

import { getAppInfo} from './redux/AppInfo'
import { useSelector, useDispatch } from 'react-redux'

import PageHeader from "./components/PageHeader"
import {SideNavigator} from "./components/SideNavigator";
import Pages from './pages/PageList';

function App() {
  //const [activePage, setActivePage] = useState (DefaultActivePage)
  let haveAppInfo = useSelector((state) => state.appInfo.haveAppInfo)
  const dispatch = useDispatch()

  React.useEffect( () =>  { 
    //fetchTasks
    if (!haveAppInfo) {
      dispatch(getAppInfo())
    }
  }, [haveAppInfo])

  const navigationHelpersRef = React.useRef(null);
  const pageHelpersRef = React.useRef(null);
  const onPageHeaderNavigationToggled = () => {

  }
  return (
    <>
      <Router>
        <SideNavigator helperFunctionCallback={helperFunctions => { navigationHelpersRef.current = helperFunctions; }}/> 
        <div id="PageWrapper">
          <div id="PageContainer">
            <PageHeader helperFunctionCallback={helperFunctions => { pageHelpersRef.current = helperFunctions; }} navigationFunctions={navigationHelpersRef}/>
            <div id="PageContent">
                <Routes>
                  <Route exact path="/" element={<Pages.HomePage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/tasks/*" element={<Pages.TasksPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/growmanager/*" element={<Pages.GrowManagerPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/farms/*" element={<Pages.FarmsPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/farms/:farmID/*" element={<Pages.FarmsPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/repo/*" element={<Pages.RecipesAndPlansPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/growanalytics/*" element={<Pages.GrowAnalyticsPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/inventory/*" element={<Pages.InventoryPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/expenses/*" element={<Pages.ExpensesPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/records/*" element={<Pages.RecordsPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/reports/*" element={<Pages.ReportsPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/onlinestore/*" element={<Pages.OnlineStorePage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/crm/*" element={<Pages.CRMPage pageHeaderHelper={pageHelpersRef} />}/>

                  
                  <Route path="/chat/*" element={<Pages.ChatPage pageHeaderHelper={pageHelpersRef} />}/>
                  <Route path="/settings/*" element={<Pages.SettingsPage pageHeaderHelper={pageHelpersRef} />}/>
                </Routes>
            </div>
          </div>
        </div>
      </Router>
    </>
  );
} 

export default App;
 