import './TasksPage.scss';
import React from "react";

import { useSelector } from 'react-redux'
//import { updateTasks } from './counterSlice'


import Button from '../../components/Button.js'
import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'


import { Routes, Route, Navigate} from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';

import TasksTimelinePage from './TasksTimelinePage';
import TasksListPage from './TasksListPage';


import TaskPopup from './TaskPopup'


const TasksPage = ({pageHeaderHelper}) => {




  const tabs = [
    new TabControlTab("Timeline", "timeline", true),
    new TabControlTab("Groups", "groups"),
    new TabControlTab("List", "list")
  ]

  const tabControlTabClickedRef = React.useRef(null);


  const setPageHeader = () => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Tasks")
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              <Button content={"Create Task"}/>
            </div>
          </div> 
        </>
      )
    })
    pageHeaderHelper.current.SetTabControlTabs(tabs);
    pageHeaderHelper.current.SetTabControlTabClickCallback((tab) => {
      if (tabControlTabClickedRef.current) {
        tabControlTabClickedRef.current(tab);
      }
    });
  }

  React.useLayoutEffect(() => {
    setPageHeader();
  }, [pageHeaderHelper])


  let data = useSelector((state) => state.tasks)

  return (
    <div className="PageWrapper_Standard">
      <TabControl 
        tabs={tabs}
        tabClickedRef={(ref) => {tabControlTabClickedRef.current=ref;}}
        hideTabs={true}
        content={
          <>
            <div className="ControlBar_Horizontal">
              <div className="ControlBar_Horizontal-Left ControlBar_Horizontal-Overflow">
                <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
                <DropDownInput options={[{ value: 'all', label: 'All Status' }]} defaultValue="all"/>
                <DropDownInput options={[{ value: 'all', label: 'All Assignee' }]} defaultValue="all"/>
                <DropDownInput options={[{ value: 'all', label: 'All Years' }]} defaultValue="all"/>
                <DropDownInput options={[{ value: 'date', label: 'Date' }]} defaultValue="date" prefix="Sort By"/>
              </div>
              <div className="ControlBar_Horizontal-Right">

              </div>
            </div>
            <Routes>
              <Route exact path="/timeline" element={
                <TasksTimelinePage setPageHeader={setPageHeader}/>
              }/>
              <Route path="/groups" element={
                <div>
                  Groups
                </div>
              }/>
              <Route path="/list" element={
                <TasksListPage data={data} pageHeaderHelper={pageHeaderHelper} setPageHeader={setPageHeader}/>
              }>
                <Route path=":taskId" element={
                  <TaskPopup/>
                } />
              </Route>


              <Route path="*" element={<Navigate to="timeline" replace />} />
            </Routes>
          </>
        }/>
      </div>
  )
} 

export default TasksPage