import './NDSs.scss';
import variables from '../../../globals.scss';
import React from 'react'


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getAllServicesForFacility, selectAllFacilities } from '../../../redux/entities/Facilities';
import { activateTemporaryEditMode, addRackToGroup, createNewGroup, getLiveDataItem, getUniqueConfigurationByName, getVerticalRackConfigurationMap, getVerticalRackGroupByServiceId, getVerticalRackLiveDataById, getVerticalRackStatusById, hideRackStatusContent, removeGroup, selectAllVerticalRackConfigurationMaps, selectAllVerticalRackGroups, setVerticalRackRuntimeProperty, showRackStatusContent } from '../../../redux/entities/service/VerticalRackGroup';
import ControlBar from '../../../components/ControlBar';
import CircularGauge from '../../../components/CircularGauge';
import { GridTile } from '../../../components/GridTile';
import NumberInput from '../../../components/input/NumberInput';
import Switch from '../../../components/Switch';
import { parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import TextInput from '../../../components/input/TextInput';
import ToggleCellButton from '../../../components/ToggleCellButton';
import { AddItem, BadX, GoodCheckmark, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import BasicAlert from '../../../components/BasicAlert';
import { selectAllNDS } from '../../../redux/entities/service/NDS';
import AddNDSWalkthrough from './AddNDSWalkthrough';






const NDSService = ({selectedFacility, setSecondaryControls}) => {
  


  const dispatch = useDispatch()

  const facilityInfo = useSelector(state => state.facilities.facilities.find((f) => f.id === selectedFacility))
  
  const allNDSs = useSelector(selectAllNDS)

  const [NDSs, SetNDSs] = React.useState([])
  React.useEffect(() => {
    SetNDSs(allNDSs.filter((vRG) => facilityInfo !== undefined && facilityInfo.services.find((s) => s.id === vRG.service_id) !== undefined))
  }, [selectedFacility, allNDSs])
  
  //const verticalRackConfigurationMaps = useSelector(selectAllVerticalRackConfigurationMaps)

  React.useLayoutEffect(() => {
    setSecondaryControls((() => {
      
      return (<>
        {NDSs.length > 0 && 
          <Button content="Add NDS" status="Primary" onClick={addNewNDS}/>  
        }
      </>)
    }))
  }, [NDSs])

  
  
  
  const [addingNewNDS, SetAddingNewNDS] = React.useState(false)
  const addNewNDS = React.useCallback(() => {
    SetAddingNewNDS(true)
  })

  

  const addNDSWalkthroughCloseCallback = React.useCallback(() => {
    SetAddingNewNDS(false)  
  })



  




  ///const verticalRackGroupEditing = React.useState(null);

  return (<>
    <div id="NDSStatus">
      
        {NDSs.length === 0 && 
          <div className="NDSStatus-NoGroupsContent">
            <span>No active NDS</span>
            <Button content={"Add your first NDS"} onClick={addNewNDS}/>
          </div>
        }
        {NDSs.length > 0 &&
          <div className="NDSStatus-NDSs">
            {NDSs.map((NDS) => {
              return (
                <div key={NDS.temp_id || NDS.id}
                  className="NDSStatus-NDS">
                    {NDS.display_name}
                </div>
              )
            })}
          </div>
        }
    </div>
    {addingNewNDS && <AddNDSWalkthrough facilityId={selectedFacility} closeCallback={addNDSWalkthroughCloseCallback}/>}
  </>)
} 

export default NDSService