import './../TasksPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate } from '../../../helpers'
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import Button from '../../../components/Button';
import { markTaskAsCompleted, markTaskAsStarted } from '../../../redux/entities/Tasks';
import { getGrowById } from '../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../redux/entities/GrowPlans';
import { getRecipeById } from '../../../redux/entities/Recipes'
import ControlBar from '../../../components/ControlBar';
import PopupModal from '../../../model_components/PopupModal';
import NumberInput from '../../../components/input/NumberInput';
import TextInput from '../../../components/input/TextInput'
import DropDownInput from '../../../components/input/DropDownInput';
import { Raft } from '../../../model_components/RaftType';

  

const TaskPopup_Harvest = ({task, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  let growsList = useSelector((state) => state.grows.grows)
  let growPlansList = useSelector((state) => state.growPlans.growPlans)
  let recipesList = useSelector((state) => state.recipes.recipes)
 
  const closePopup = () =>  {
    onClosePopup()
  }

  const [grow, SetGrow] = React.useState(null)
  const [growPlans, SetGrowPlans] = React.useState({})
  const [recipe, SetRecipe] = React.useState(null)

  const [currentStep, SetStep] = React.useState("pending")
  
  React.useEffect(() => {
    let foundGrow = growsList.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }else {
      SetGrow({...foundGrow})
    }
  }, [task, growsList])

  React.useEffect(() => {
    
    let currentGrowPlans = {}
    let growPlansToLoad = []
    if (grow) {
      for (let [growPlanIdString, growPlanInfo] of Object.entries(grow.grow_plans_info)) {
        let growPlanId = parseInt(growPlanIdString)
        let foundGrowPlan = growPlansList.find((gP) => gP.id === growPlanId)
        if (foundGrowPlan === undefined)  {
          if (growPlansToLoad.indexOf(growPlanId) === -1) {
            growPlansToLoad.push(growPlanId)
          }
        }else {
          currentGrowPlans[growPlanId] = {...growPlanInfo, plan: foundGrowPlan}
        }
      }
      //console.log(grow)
    }

    if (growPlansToLoad.length > 0) {
      dispatch(getGrowPlanById({growPlans: growPlansToLoad}))
    }
    
    SetGrowPlans(currentGrowPlans)

    let currentHarvestInputs = {...harvestInputs}
    for (let growPlanIdString of Object.keys(currentGrowPlans))  {
      let growPlanId = parseInt(growPlanIdString)
      if (currentHarvestInputs[growPlanId] === undefined) {
        currentHarvestInputs[growPlanId] = baseInputs
      }
    }
    
    //set defaults
    SetHarvestInputs(currentHarvestInputs)
  }, [grow, growPlansList])

  React.useEffect(() => {
    if (grow) {
      let foundRecipe = recipesList.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
      if (foundRecipe === undefined)  {
        dispatch(getRecipeById({recipeId: grow.recipe_id, recipeVersion: grow.recipe_version}))
      }else {
        SetRecipe({...foundRecipe})
      }
    }
  }, [grow, recipesList])



  const [baseInputs, SetBaseInputs] = React.useState({
    "total_yield": {
      name: "Total yield",
      value: 0,
      suffix: "kg"
    },
    "number_of_plant_sites": {
      name: "Harvested plant sites",
      value: 0,
    }
  })
  const [harvestInputs, SetHarvestInputs] = React.useState({}) //Grow plan id : list of inputs which are key value pairs
  

  

  const startTaskClicked = React.useCallback(() => {
    dispatch(markTaskAsStarted({taskId: task.id}))
    //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
  })

  const completeTaskClicked = React.useCallback(() => {
    let harvestInputsToSend = {}
    for (let [growPlanIdString, harvestInput] of Object.entries(harvestInputs))  {
      let growPlanId = parseInt(growPlanIdString)
      harvestInputsToSend[growPlanId] = {}
      for (let [harvestInputType, harvestInputInfo] of Object.entries(harvestInput))  {
        harvestInputsToSend[growPlanId][harvestInputType] = harvestInputInfo.value
      }
    }
    dispatch(markTaskAsCompleted({taskId: task.id, params: {"inputs": harvestInputsToSend}}))
    onClosePopup()
  })



  let footerContent = <></>
  if (!task.started) {
    footerContent = <>
       <ControlBar controls={(<>
          <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
        </>)} secondaryControls={(<>
            {<Button status="Primary" content="Start" onClick={startTaskClicked}/>}
        </>)}/>
    </>
  }else {
    footerContent = <>
      <ControlBar secondaryControls={(<>
         {<Button status="Primary" content="Complete task" onClick={completeTaskClicked}/>}
      </>)}/>
    </>
  }


  return (
    <PopupModal 
      title={task.name + (grow ? " #G" + grow.id : "")}
      closeCallback={closePopup}
      footer={footerContent}>

      {(grow && recipe) && <>
        <div className="PopupModal-FormItem">
          <div className="PopupModal-FormItem-Question">
            Harvest the following plants
          </div>
          <div className="PopupModal-FormItem-Answer">
            <div className="Grow_Harvest-PlantTypes">
              {Object.entries(growPlans).map(([growPlanId, growPlan]) => {
                console.log(harvestInputs)
                return (
                  <div className="Grow_Harvest-PlantType" key={growPlanId}>
                    <div className="Grow_Harvest-PlantType-Header">
                      {growPlan.plan.name}
                    </div>
                    <div className="Grow_Harvest-PlantType-Inputs">
                      {harvestInputs[growPlanId] !== undefined && 
                        <>
                          {Object.entries(harvestInputs[growPlanId]).map(([harvestInputType, harvestInput]) => {
                            let harvestInputOptions = [{value: harvestInputType, label: harvestInput.name}]

                            const harvestInputChanged = (value) =>  {

                              return value
                            }
                            
                            const harvestInputChangeFinalize = (value) =>   {
                              let updatedHarvestInputs = {...harvestInputs}
                              updatedHarvestInputs[growPlanId][harvestInputType].value = value
                              console.log(updatedHarvestInputs)
                              SetHarvestInputs({...updatedHarvestInputs})
                            }

                            return (
                              <div key={harvestInputType}
                                className="Grow_Harvest-PlantType-Input">
                                <div className="Grow_Harvest-PlantType-Input-Type">
                                  <DropDownInput value={harvestInputType} options={harvestInputOptions}/>
                                </div>
                                <div className="Grow_Harvest-PlantType-Input-Value">
                                  <NumberInput 
                                    value={harvestInput.value} 
                                    suffix={harvestInput.suffix ?? undefined} 
                                    onChange={harvestInputChanged}
                                    onBlur={harvestInputChangeFinalize}/>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </>}


    </PopupModal>

  )
} 

export default TaskPopup_Harvest