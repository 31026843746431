import './../ManageRackWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, RackStandardGroup, RackStandardGrowOut, ScanRack } from '../../../../assets/icons/RackIcons';
import RadioButton from '../../../../components/RadioButton';
import { Plus, ReorderItem } from '../../../../assets/icons/Icons';
import { createNewVerticalRackGroup, selectAllVerticalRackGroups } from '../../../../redux/entities/service/VerticalRackGroup';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from '../../../../helpers';


import { nanoid, customAlphabet  } from 'nanoid'
import LoadingBar from '../../../../components/LoadingBar';
import { selectAllFacilities } from '../../../../redux/entities/Facilities';

  

const SelectRackIndexWalkthroughStep = ({movingRackIndex, initialRackIndex, facilityId, rackGroupId, transitionToPreviousStep, transitionToNextStep, rackIndexSelectedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const facilities = useSelector(selectAllFacilities)
    const verticalRackGroups = useSelector(selectAllVerticalRackGroups)




    let selectedVerticalRackGroup = verticalRackGroups.find((vRG) => vRG.id === rackGroupId)
    let existingRack = movingRackIndex !== null ? selectedVerticalRackGroup.rack_map.racks.find((r) => r.index === movingRackIndex) : null
    //let initialRackIndex = movingRackIndex ?? selectedVerticalRackGroup.rack_map.racks.length + 1
    
    const [selectedRackIndex, SetSelectedRackIndex] = React.useState(initialRackIndex)
    const [potentialRackIndex, SetPotentialRackIndex] = React.useState(initialRackIndex)
    const [rackList, SetRackList] = React.useState([])

    React.useEffect(() => {
        let movingRackAdded = false
        let currentRackList = []
        for (let rack of selectedVerticalRackGroup.rack_map.racks)  {
            if (rack.index === selectedRackIndex)   {
                if (movingRackIndex === null)  {
                    currentRackList.push({
                        display_name: "New Rack " + initialRackIndex,
                        index: rack.index,
                        isNewRack: true
                    })
                    movingRackAdded = true
                }else {
                    currentRackList.push(existingRack)
                }
            }
            currentRackList.push(rack)
        }
        if (!movingRackAdded)   {
            if (movingRackIndex === null)  {
                currentRackList.push({
                    display_name: "New Rack " + initialRackIndex,
                    index: initialRackIndex,
                    isNewRack: true
                })
            }else {
                currentRackList.push(existingRack)
            }
        }

        SetRackList(currentRackList)
        console.log(currentRackList)
    }, [movingRackIndex, selectedRackIndex])






    const existingRackContainerRefs = React.useRef({})
    const moveRackContainerRef = React.useRef(undefined)
    const [moveRackPointerId, SetMoveRackPointerId] = React.useState(null)
    const [isPointerDownOverMovingRack, SetIsPointerDownOverMovingRack] = React.useState(false)
    const [isRackBeingDragged, SetIsRackBeingDragged] = React.useState(false)
    const [currentPointerPosition, SetCurrentPointerPosition] = React.useState({x: 0, y: 0})
    const [rackDraggingOffset, SetRackDraggingOffset] = React.useState({x: 0, y: 0})

    const beginMoveRackPointerDown = React.useCallback((e) =>  {
        if (moveRackContainerRef.current !== undefined && moveRackContainerRef.current.setPointerCapture)    {
            SetMoveRackPointerId(e.pointerId)
            moveRackContainerRef.current.setPointerCapture(e.pointerId);
            SetIsPointerDownOverMovingRack(true)
        }
    })
    const beginMoveRackPointerMove = React.useCallback((e) =>  {
        if (isPointerDownOverMovingRack)    {
            SetIsPointerDownOverMovingRack(false)
            SetCurrentPointerPosition({x: e.clientX, y: e.clientY})
            const { offsetLeft, offsetTop } = moveRackContainerRef.current
            SetRackDraggingOffset({x: e.clientX - offsetLeft - walkthroughCardLeft, y: e.clientY - offsetTop - walkthroughCardTop})

            SetIsRackBeingDragged(true)
            e.preventDefault()
            e.stopPropagation()
        }

        let mouseOffsetY = e.clientY - walkthroughCardTop

        if (isRackBeingDragged) {
            e.preventDefault()
            e.stopPropagation()
            SetCurrentPointerPosition({x: e.clientX , y: e.clientY})

            //Check current position against all existing racks
            let closestBefore = null;
            let closestAfter = null;
            for (let [rackIndex, rackRef] of Object.entries(existingRackContainerRefs.current))  {
                if (rackRef.offsetTop + (rackRef.clientHeight / 2) <= mouseOffsetY)  {
                    if (closestBefore === null || closestBefore.ref.offsetTop < rackRef.offsetTop)  {
                        closestBefore = {index: parseInt(rackIndex), ref: rackRef}
                    }
                }else {
                    if (closestAfter === null || closestAfter.ref.offsetTop > rackRef.offsetTop)  {
                        closestAfter = {index: parseInt(rackIndex), ref: rackRef}
                    }
                }
            }
            if (closestBefore !== null && closestAfter !== null)    {
                SetPotentialRackIndex(closestBefore.index + 1)       
            }else if (closestBefore === null)  {
                SetPotentialRackIndex(1)
            }else if (closestAfter === null) {
                SetPotentialRackIndex(movingRackIndex === null ? selectedVerticalRackGroup.rack_map.racks.length + 1 : selectedVerticalRackGroup.rack_map.racks.length)
            }
        }
    })
    const beginMoveRackPointerUp = React.useCallback((e) =>  {
        SetIsPointerDownOverMovingRack(false)
        SetIsRackBeingDragged(false)
        SetSelectedRackIndex(potentialRackIndex)
        if (moveRackContainerRef.current !== undefined && moveRackContainerRef.current.releasePointerCapture && moveRackPointerId)    {
            moveRackContainerRef.current.releasePointerCapture(moveRackPointerId);
        }

        //SetIsRackDoneBeingDragged(true)
    })
    

    const [walkthroughCardBind, { documentTop: walkthroughCardTop, documentLeft: walkthroughCardLeft }] = useMeasure()



    const backClicked = () =>   {
        transitionToPreviousStep(["select_rack_group", "rack_group_listing"])
    }
    const nextClicked = () =>   {
        rackIndexSelectedCallback({rackIndex: selectedRackIndex})
        transitionToNextStep(["name_rack", null])
    }

    let offsetPosition = false
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill" {...walkthroughCardBind}>
            <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">{selectedVerticalRackGroup.display_name}</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    {!isMobile && 
                        <div>
                            <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                <div className="Button-Large-Icon"><RackStandardGroup width={(isTablet || isDesktop) ? 168 : 140}/></div>
                            </>}/>
                        </div>
                    }
                    <div className="FlexContent-HFill FlexContent-Center">
                        <div className="FlexContent-20 FlexContent-HFill">
                            <div className="FlexContent-5 FlexContent-HLeft FlexContent-Center">
                                <div className="PopupModal-StandardSubHeader">Move Rack {initialRackIndex} into installed position</div>
                            </div>
                            <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks FlexContent-HFill">
                                {rackList.map((rack) => {
                                    return (<>
                                        {(rack.isNewRack !== undefined && rack.isNewRack) && (() => {
                                            const rackProps = {style:{}}
                                            if (isRackBeingDragged) {
                                                rackProps.style.left = currentPointerPosition.x - rackDraggingOffset.x
                                                rackProps.style.top = currentPointerPosition.y - rackDraggingOffset.y
                                            }else {
                                                offsetPosition = true
                                            }
                                            return (<>
                                                {/*isRackBeingDragged &&
                                                    <div className={"ManageRackWalkthrough_Popup-RackPlacement-Racks-DropIndicator"}></div>
                                                */}
                                                <div className={"ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack" + (!isRackBeingDragged ? " ManageRackWalkthrough_Popup-RackPlacement-MovingRack-Still" : " ManageRackWalkthrough_Popup-RackPlacement-MovingRack-Moving")}
                                                    key={rack.index}
                                                    onPointerDown={beginMoveRackPointerDown}
                                                    onPointerMove={beginMoveRackPointerMove}
                                                    onPointerUp={beginMoveRackPointerUp}
                                                    onContextMenu={(e) => {e.preventDefault(); return false;}}
                                                    ref={moveRackContainerRef}
                                                    {...rackProps}>
                                                    <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Name noselect">
                                                        <ReorderItem/>
                                                        {rack["display_name"]}
                                                    </div>
                                                    {/*<div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Position noselect">Position {rack["index"]}</div>*/}
                                                </div> 
                                            </>)
                                        
                                        })()}
                                        {(isRackBeingDragged && ((rack.index === potentialRackIndex && (rack.isNewRack === undefined || !rack.isNewRack)) || (rack.index === initialRackIndex && potentialRackIndex == initialRackIndex && rack.isNewRack !== undefined && rack.isNewRack))) && (() => {
                                            offsetPosition = true
                                            return (
                                                <div key={rack.index + "-dragging"} className={"ManageRackWalkthrough_Popup-RackPlacement-Racks-DropIndicator"}></div>
                                                
                                            )
                                        })()}                          

                                        {(rack.isNewRack === undefined || !rack.isNewRack) && <>
                                            <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack ManageRackWalkthrough_Popup-RackPlacement-ExistingRack noselect"
                                                ref={el => existingRackContainerRefs.current[rack.index] = el}
                                                key={rack.index}>
                                                <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Name">{rack["display_name"]}</div>
                                                <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Position">Position {rack["index"] + (offsetPosition ? 1 : 0)}</div>
                                            </div>
                                        </>}
                                    </>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next Step"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>                       
        </div>
    )
    
}


SelectRackIndexWalkthroughStep.defaultProps = {
    theme: "normal",
  }
  


export default SelectRackIndexWalkthroughStep