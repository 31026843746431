import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import navigationReducer from './Navigation.js'
import appInfoReducer from './AppInfo'
import nutrientSolutionsReducer from './entities/common/NutrientSolutions'
import inventoryReducer from './entities/Inventory'
import taskEntitiesReducer from './entities/Tasks.js'
import growEntitiesReducer from './entities/Grow.js'
import growPlansEntitiesReducer from './entities/GrowPlans.js'
import productEntitiesReducer from './entities/Products.js'
import recipesEntitiesReducer from './entities/Recipes.js'
import raftTypesEntitiesReducer from './entities/RaftTypes'
import facilitiesReducer from './entities/Facilities'
import VerticalRackGroupsEntitiesReducer from './entities/service/VerticalRackGroup'
import NDSsEntitiesReducer from './entities/service/NDS.js'
//import logger from 'redux-logger'

export const store = configureStore({
    reducer: {
        navigation: navigationReducer,
        appInfo: appInfoReducer,

        nutrientSolutions: nutrientSolutionsReducer,

        inventory: inventoryReducer,
        tasks: taskEntitiesReducer,
        recipes: recipesEntitiesReducer,
        grows: growEntitiesReducer, 
        growPlans: growPlansEntitiesReducer, 
        products: productEntitiesReducer,
        raftTypes: raftTypesEntitiesReducer, 

        facilities: facilitiesReducer,
        verticalRackGroups: VerticalRackGroupsEntitiesReducer,
        NDS: NDSsEntitiesReducer,
    },
    middleware: getDefaultMiddleware({
      serializableCheck: {
          ignoredPaths: ['grows']
      }
    })/*.concat(logger)*/,
})