import './NumberInput.scss';
import React from 'react'

import { useMeasure } from '../../helpers'
import {FiPlus, FiMinus} from 'react-icons/fi'

const NumberInput = ({value, stepper, stepAmount, prefix, suffix, onChange, onBlur, flex, size, maxLength, precision, min, max, disabled}) => {
  const [focusState, SetFocusState] = React.useState(false);
  const [pointerId, SetPointerId] = React.useState(null)
  const containerRef = React.useRef(null);

  const [numberValue, setNumberValue] = React.useState(value);

  const [numberValueDisplay, setNumberValueDisplay] = React.useState(value);
  const numberValueRef = React.useRef(null);
  const numberInput = React.useRef(null);

  const actualIcon = false;
  const [inputBind, { height: inputHeight }] = useMeasure()

  const [inTempEmptyMode, SetInTempEmptyMode] = React.useState(false)


  const buttonUpClicked = React.useCallback(() => {
    if (disabled)
      return
    SetInTempEmptyMode(false)
    if (onChange !== undefined) {
      let result = onChange(numberValue + stepAmount)
      if (result !== undefined) {
        setNumberValue(result)
        return
      }
    }
    //numberInput.current.value = numberValue + stepAmount
    setNumberValue(numberValue + stepAmount)
  })
  const buttonDownClicked = React.useCallback(() => {
    if (disabled)
      return
    SetInTempEmptyMode(false)
    if (onChange !== undefined) {
      let result = onChange(numberValue - stepAmount)
      if (result !== undefined) {
        //numberInput.current.value = result
        setNumberValue(result)
        return
      }
    }
    //numberInput.current.value = numberValue - stepAmount
    setNumberValue(numberValue - stepAmount)
  })

  const valueChanged = React.useCallback((e) =>  {
    if (disabled)
      return
    e.preventDefault()
    if (e.target.value === "")  {
      SetInTempEmptyMode(true)
      if (onChange !== undefined) {
        let result = onChange(min != false ? min : 0)
        if (result !== undefined) {
          setNumberValue(result)
          return false
        }
      }else {
        setNumberValue(min != false ? min : 0)
      }
    }else {
      SetInTempEmptyMode(false)
      let requestedValue = parseFloat(e.target.value)
      if (requestedValue == numberValue)
        return
      let valid = true
      if (min && requestedValue < min)  {
        requestedValue = min
        valid = false
      }
      if (max && requestedValue > max)  {
        requestedValue = max
        valid = false
      }
        
      if (onChange !== undefined) {
        let result = onChange(requestedValue)
        if (result !== undefined) {
          //numberInput.current.value = result
          setNumberValue(result)
          return false
        }else {
          numberInput.current.value = requestedValue
          setNumberValue(requestedValue)
          return false
        }
      }else {
        if (!valid) {
          //numberInput.current.value = requestedValue
          setNumberValue(requestedValue)
          return false
        }
      }

      setNumberValue(e.target.value)

    }
  })


  React.useEffect(() => {
    //numberInput.current.value = value
    setNumberValue(value)
  }, [value])

  React.useEffect(() => {
    numberValueRef.current = numberValue
  }, [numberValue])


  const widthRatio = 0.75

  let inputProps = {}
  if (maxLength !== false)  {
    inputProps["maxLength"] = maxLength
  }
  if (inTempEmptyMode)  {
    inputProps["placeholder"] = min !== false ? min : 0
  }

  const handleFocus = React.useCallback((e) => {
    if (disabled) {
      SetFocusState(false)
      return
    }
    if (!focusState)  {
      SetFocusState(true)
      document.addEventListener('pointerdown', handleBlur);
    }
  })

  const handleBlur = React.useCallback((ev) => {
    const xCoord = ev.clientX;
    const yCoord = ev.clientY;
    if (containerRef.current) {
      const { right, x, bottom, y } = containerRef.current.getBoundingClientRect();
      if (xCoord < right && xCoord > x && yCoord < bottom && yCoord > y) {
        return;
      }
      blur()
      document.removeEventListener('pointerdown', handleBlur);
    }
  })

  const blur = React.useCallback(() => {
    SetFocusState(false)
    SetInTempEmptyMode(false)
    if (onBlur !== undefined) {
      onBlur(numberValueRef.current)
    }
  })

  const focusEnd = (e) => {
    
  }

  const calculateValueDisplay = (value) =>  {
    if (inTempEmptyMode)  {
      return ""
    }
    let valueDisplay = value
    if (precision !== false)  {
      valueDisplay = Math.round(value * (1 / precision)) / (1 / precision)
    }
    return valueDisplay
  }

  
  React.useEffect(() => {
    let valueDisplay = calculateValueDisplay(numberValue)
    //if (valueDisplay !== numberInput.current.value)  {
      numberInput.current.value = valueDisplay
    //}
  }, [numberValue, inTempEmptyMode])

  //let defaultValue = calculateValueDisplay(numberValue)

  const inputHitboxClicked = React.useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
    handleFocus(e)
    numberInput.current.focus()
  })

  let valueToDisplay = calculateValueDisplay(numberValue)

  const containerProps = {style:{}}
  if (flex) {
    containerProps.style.flex = "1"
    size = false
    inputProps.style = {
      flex: "1"
    }
  }

  return (
    <div className={"NumberInput" + (focusState ? " NumberInput-Focused" : "") + (disabled ? " NumberInput-Disabled" : "")} onPointerDown={handleFocus} ref={containerRef} {...containerProps}>
      {stepper && 
        <div 
          className="NumberInput_ButtonUp noselect" 
          /*style={{width: inputHeight * widthRatio, height: inputHeight / 2}}   style={{width: inputHeight * widthRatio}}*/
          onClick={buttonDownClicked}>
          <div className="NumberInput_Button noselect">
            <FiMinus/>
          </div>
        </div>
      }
      <div {...inputBind} onPointerDown={inputHitboxClicked}>
        <div className="NumberInput_Content"
          style={{ width: size ? (size + (suffix ? suffix.length : 0)) + 'ch' : 'auto' }}>
          {(prefix !== false) &&
            <div className="NumberInput_Prefix noselect">
              {prefix}
            </div>
          }
          <div className="NumberInput_DisplayArea">
            <input
              defaultValue={valueToDisplay}
              style={{ width: valueToDisplay.toString().length + 'ch' }}
              onChange={valueChanged} 
              type="text" pattern="[0-9]*"
              ref={numberInput}
              onPointerDown={(e) => {
                e.stopPropagation()
                handleFocus(e)
              }}
              {...inputProps}/>
          </div>
          {(suffix !== false) &&
            <div className="NumberInput_Suffix noselect">
              {suffix}
            </div>
          }
        </div>
      </div>

    {stepper && 
        <div 
          className="NumberInput_ButtonDown noselect" 
          /*style={{width: inputHeight * widthRatio, height: inputHeight / 2}}*/
          onClick={buttonUpClicked}>
          <div className="NumberInput_Button noselect">
            <FiPlus/>
          </div>
        </div>
      }
    </div>
  )
} 

NumberInput.defaultProps = {
  value: "",
  stepper: true,
  stepAmount: 1,
  prefix: false,
  suffix: false,
  flex: false,
  size: 4,
  maxLength: false,
  precision: false,
  min: false,
  max: false,
  disabled: false
}


export default NumberInput