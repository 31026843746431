import React, { useState, useRef, useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
//import { install } from "resize-observer";
//install();

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => void (ref.current = value), [value])
  return ref.current
}

export function useMeasure(cb) {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0, documentTop: 0, documentLeft: 0, documentBottom: 0, documentRight: 0 })
  const [callback, setCallback] = useState(cb)


  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    
    let updatedBounds = {width: entry.contentRect.width, height: entry.contentRect.height, left: entry.contentRect.left, top: entry.contentRect.top, right: entry.contentRect.right, bottom: entry.contentRect.bottom}
    const bounds = entry.target.getBoundingClientRect()
    updatedBounds.documentTop = bounds.top
    updatedBounds.documentLeft = bounds.left
    updatedBounds.documentBottom = parseFloat(document.body.offsetHeight) - bounds.bottom
    updatedBounds.documentRight = parseFloat(document.body.offsetWidth) - bounds.right
    set(updatedBounds)
    if (cb !== undefined) {
      cb(updatedBounds)
    }

  }))

  useEffect(() => {
    console.log(ref, "Changed")
  }, [ref])

  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current)
    }
    return () => {
      ro.disconnect()
    }
  }, [])
  return [{ ref }, bounds]
}



export function useMeasureWithTrigger(cb) {
  const ref = useRef()
  const trigger = () => {
    handleBoundsChange()
  }
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0, documentTop: 0, documentLeft: 0, documentBottom: 0, documentRight: 0 })
  const [callback, setCallback] = useState(cb)


  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    
    let updatedBounds = {width: entry.contentRect.width, height: entry.contentRect.height, left: entry.contentRect.left, top: entry.contentRect.top, right: entry.contentRect.right, bottom: entry.contentRect.bottom}
    const newBounds = entry.target.getBoundingClientRect()
    updatedBounds.documentTop = newBounds.top
    updatedBounds.documentLeft = newBounds.left
    updatedBounds.documentBottom = parseFloat(document.body.offsetHeight) - newBounds.bottom
    updatedBounds.documentRight = parseFloat(document.body.offsetWidth) - newBounds.right
    set(updatedBounds)
    if (cb !== undefined) {
      cb(updatedBounds)
    }

  }))


  const handleBoundsChange = () =>  {
    if (!ref.current)  {
      return
    }
    let updatedBounds = {...bounds}

    const newBounds = ref.current.getBoundingClientRect()
    updatedBounds.documentTop = newBounds.top
    updatedBounds.documentLeft = newBounds.left
    updatedBounds.documentBottom = parseFloat(document.body.offsetHeight) - newBounds.bottom
    updatedBounds.documentRight = parseFloat(document.body.offsetWidth) - newBounds.right
    set(updatedBounds)
    if (cb !== undefined) {
      cb(updatedBounds)
    }
  }

  useEffect(() => {
    console.log(ref, "Changed")
  }, [ref])

  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current)
    }
    return () => {
      ro.disconnect()
    }
  }, [])
  return [{ ref }, trigger, bounds]
}



export function useMeasureWithPosition({scrollContainerRef}, cb) {
  const ref = useRef()
  const [bounds, SetBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0, width: 0, height: 0})
  const [position, SetPosition] = useState({ documentTop: 0, documentLeft: 0 })
  const [callback, setCallback] = useState(cb)


  useEffect(() => {
    if (cb !== undefined) {
      cb({...bounds, ...position})
    }
  }, [bounds, position])

  
  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    if (ref.current === null)  {
      return
    }
    let updatedBounds = {width: entry.contentRect.width, height: entry.contentRect.height, left: entry.contentRect.left, top: entry.contentRect.top, right: entry.contentRect.right, bottom: entry.contentRect.bottom}
    SetBounds({...updatedBounds})
    
    const currentPosition = entry.target.getBoundingClientRect()
    if (position.documentLeft !== currentPosition.left || position.documentTop !== currentPosition.top) {
      SetPosition({documentLeft: currentPosition.left, documentTop: currentPosition.top})
    }
  }))


  const containerScrolled = React.useCallback((e) =>  {
    forceBoundsUpdate()
  })
  useEffect(() => {
    if (scrollContainerRef !== undefined && scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", containerScrolled)
    }
    return () =>  {
      if (scrollContainerRef !== undefined && scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", containerScrolled)
      }
    }
  }, [scrollContainerRef])

  
  const forceBoundsUpdate = React.useCallback((forceCallback) => {
    if (ref.current)  {
      const currentPositionBounds = ref.current.getBoundingClientRect()
      //if (bounds.width !== currentPositionBounds.width || bounds.height !== currentPositionBounds.height) {
      //  SetBounds({...bounds, width: currentPositionBounds.width, height: currentPositionBounds.height})
      //}
      if (position.documentLeft !== currentPositionBounds.left || position.documentTop !== currentPositionBounds.top) {
        SetPosition({documentLeft: currentPositionBounds.left, documentTop: currentPositionBounds.top})
      }

      if (forceCallback !== undefined && forceCallback && cb !== undefined) {
        cb({...bounds, documentLeft: currentPositionBounds.left, documentTop: currentPositionBounds.top})
      }
    }
  })



  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current)
    }
    return () => ro.disconnect()
  }, [])
  return [{ref}, forceBoundsUpdate, {...bounds, ...position}]
}


export function useMeasureWithRef(ref, cb) {
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0, documentTop: 0, documentLeft: 0 })
  const [callback, setCallback] = useState(cb)
  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    const bounds = entry.target.getBoundingClientRect()
    entry.contentRect.documentTop = bounds.top
    entry.contentRect.documentLeft = bounds.left
    set(entry.contentRect)

    if (cb !== undefined) {
      cb(entry.contentRect)
    }
  }))

  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current)
    }
    return () => ro.disconnect()
  }, [ref])
  return [bounds]
}




export const distToSegment = (p, v, w) => {

  let sqr = (x) => { 
    return x * x 
  }
  
  let dist2 = (v, w) => { 
    return sqr(v.x - w.x) + sqr(v.y - w.y) 
  }
  
  let distToSegmentSquared = (p, v, w) => {
    var l2 = dist2(v, w);
      
    if (l2 == 0) return dist2(p, v);
      
    var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
      
    if (t < 0) return dist2(p, v);
    if (t > 1) return dist2(p, w);
      
    return dist2(p, { x: v.x + t * (w.x - v.x), y: v.y + t * (w.y - v.y) });
  }


  return Math.sqrt(distToSegmentSquared(p, v, w));
}


export const binaryClosestIdx = (arr, target, propKey) => {
  let start = 0;
  let end = arr.length - 1;
  let mid = Math.floor((start + end) / 2);

  while (1) {
    if (arr[mid] === undefined) {
      break
    }
      if (arr[mid][propKey] === target) {
          return mid;
      }
      else if (start >= end) {
          break;
      }
      else if (arr[mid][propKey] > target) {
          end = mid - 1;
      } else {
          start = mid + 1;
      }
      
      mid = Math.floor((start + end) / 2);
  }

  // Return the closest between the last value checked and it's surrounding neighbors
  const first = Math.max(mid - 1, 0);
  const neighbors = arr.slice(first, mid + 2);
  const best = neighbors.reduce((b, el) => Math.abs(el[propKey] - target) < Math.abs(b[propKey] - target) ? el : b);

  return first + neighbors.indexOf(best);
}


export function FormatTime(time, patternStr)  {
  
  const milliseconds = Math.floor(time % 1000),
        seconds = Math.floor((time / 1000) % 60),
        minutes = Math.floor((time / (1000 * 60)) % 60),
        hours = Math.floor((time / (1000 * 60 * 60)) % 24),
        days = Math.floor(time / (1000 * 60 * 60 * 24))


  const twoDigitPad = num => {
    return num < 10 ? "0" + num : num;
  }

  if (!patternStr) {
    patternStr = '~HH:~SS';
  }

  let result = patternStr
      .replace('~HH', twoDigitPad(hours)).replace('~H', hours)
      .replace('~MM', twoDigitPad(minutes)).replace('~M', minutes) 
      .replace('~SS', twoDigitPad(seconds)).replace('~S', seconds)
      .replace('~MS', milliseconds)
      .replace('~DD', twoDigitPad(days)).replace('~D', days)

  return result
}



export function FormatDate(date, patternStr)  {
  
    const monthNames = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
    const dayOfWeekNames = [
      "Sunday", "Monday", "Tuesday",
      "Wednesday", "Thursday", "Friday", "Saturday"
    ];
    const twoDigitPad = num => {
        return num < 10 ? "0" + num : num;
    }
    if (!patternStr) {
        patternStr = 'MM/d/yyyy';
    }
    let day = date.getDate(),
        month = date.getMonth(),
        year = date.getFullYear(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        miliseconds = date.getMilliseconds(),
        h = hour % 12,
        hh = twoDigitPad(h),
        HH = twoDigitPad(hour),
        mm = twoDigitPad(minute),
        ss = twoDigitPad(second),
        aaa = hour < 12 ? 'AM' : 'PM',
        yearStartedOn = new Date(date.getFullYear(), 0, 1),
        daysSinceYearStarted = Math.floor((date - yearStartedOn) / (24 * 60 * 60 * 1000)),
        weeksSinceYearStarted = Math.ceil(daysSinceYearStarted / 7),
        EEEE = dayOfWeekNames[date.getDay()],
        EEE = EEEE.substr(0, 3),
        E = EEEE.substr(0, 1),
        dd = twoDigitPad(day),
        M = month + 1,
        MM = twoDigitPad(M),
        MMMM = monthNames[month],
        MMM = MMMM.substr(0, 3),
        yyyy = year + "",
        yy = yyyy.substr(2, 2)
    ;

    // checks to see if month name will be used
    patternStr = patternStr
      .replace('hh', hh).replace('h', h)
      .replace('HH', HH).replace('H', hour)
      .replace('mm', mm).replace('m', minute) 
      .replace('ss', ss).replace('s', second)
      .replace('S', miliseconds)
      .replace('dd', dd).replace('d', day)
      
      .replace('EEEE', EEEE).replace('EEE', EEE).replace('E', E)
      .replace('yyyy', yyyy)
      .replace('yy', yy)
      .replace('aaa', aaa)
      .replace('wn', weeksSinceYearStarted);
    if (patternStr.indexOf('MMM') > -1) {
        patternStr = patternStr
          .replace('MMMM', MMMM)
          .replace('MMM', MMM);
    }
    else {
        patternStr = patternStr
          .replace('MM', MM)
          //.replace('M', M);
    }
    return patternStr;
}


export const RoundedDateToNearestMinute = (minutes, d=new Date()) => {

  let ms = 1000 * 60 * minutes; // convert minutes to ms
  let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

  return roundedDate
}


export function RoundToNearest(value, resolution) {
  return Math.round(value * (1 / resolution)) / (1 / resolution);
}



export const daysInMonth = (y, m) => new Date(y, m + 1, 0).getDate()


export const addArrayItemAtIndex = ( array, index, newItem ) => {
  return [...array.slice(0, index), newItem, ...array.slice(index)];
}


export const remapRange = (value, rangeFrom, rangeTo) =>  {
  const leftSpan = rangeFrom[1] - rangeFrom[0]
  const rightSpan = rangeTo[1] - rangeTo[0]
  const valueScaled = (value - rangeFrom[0]) / leftSpan
  return rangeTo[0] + (valueScaled * rightSpan)
}

export const parseBool = (value: any) => {
  if (['true', true, 1, '1', 'yes'].includes(value)) {
    return true;
  }
  return false;
}


/*


export function useMeasureWithPosition({boundingRef, scrollContainerRef}, cb) {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0, documentTop: 0, documentLeft: 0 })
  const [position, setPosition] = useState({ documentTop: 0, documentLeft: 0 })
  const [callback, setCallback] = useState(cb)

  const margin = 1

  const positionObserver = document.createElement('div');
  Object.assign(positionObserver.style, {
      position: 'absolute',
      pointerEvents: 'none',
      width: '2px',
      height: '2px',
      background: "blue"
  });

  React.useEffect(() => {
    if (ref.current && boundingRef !== undefined && boundingRef.current)  {
      const boundingRect = boundingRef.current.getBoundingClientRect();
      const currentBounds = ref.current.getBoundingClientRect()
      const offsetLeft = -(currentBounds.left - boundingRect.left - margin) + "px"
      const offsetTop = -(currentBounds.top - boundingRect.top - margin) + "px"
      positionObserver.style.transform = "translate(" + (offsetLeft) + "," + (offsetTop) + ")"
    }
  }, [bounds])

  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    if (ref.current === null)  {
      return
    }
    let updatedBounds = {width: entry.contentRect.width, height: entry.contentRect.height, left: entry.contentRect.left, top: entry.contentRect.top, right: entry.contentRect.right, bottom: entry.contentRect.bottom}
    
    const currentBounds = entry.target.getBoundingClientRect()
    updatedBounds.documentTop = currentBounds.top
    updatedBounds.documentLeft = currentBounds.left
    set(updatedBounds)
    if (boundingRef !== undefined && boundingRef.current)  {
      const boundingRect = boundingRef.current.getBoundingClientRect();
      const offsetLeft = -(currentBounds.left - boundingRect.left - margin) + "px"
      const offsetTop = -(currentBounds.top - boundingRect.top - margin) + "px"
      positionObserver.style.transform = "translate(" + (offsetLeft) + "," + (offsetTop) + ")"
      //positionObserver.style.marginLeft = -(currentBounds.left - boundingRect.left - margin) + "px"
      //positionObserver.style.marginTop = -(currentBounds.top - boundingRect.top - margin) + "px"
    }
    if (cb !== undefined) {
      cb(updatedBounds)
    }
  }))

  const [io, setIO] = useState(null)

  useEffect(() => {
    if (bounds.documentLeft !== position.documentLeft || bounds.documentTop !== position.documentTop) {
      let updatedBounds = {...bounds, documentLeft: position.documentLeft, documentTop: position.documentTop}
      set(updatedBounds)
      if (cb !== undefined) {
        cb(updatedBounds)
      }
    }
  }, [position])
  
  useEffect(() => {
    let io
    if (ref.current && boundingRef !== undefined && boundingRef.current)  {
      //console.log(ref.current.getBoundingClientRect())
      io = new IntersectionObserver(([entry]) => {
        if (ref.current === null)  {
          return
        }
        const visiblePixels = Math.round(entry.intersectionRatio * 4);
        if (visiblePixels !== 1) {
          const boundingRect = boundingRef.current.getBoundingClientRect();
          const currentBounds = ref.current.getBoundingClientRect()
          if (currentBounds.documentLeft !== bounds.left || currentBounds.documentTop !== bounds.top) {
            let updatedBounds = {...bounds}
            updatedBounds.documentTop = currentBounds.top
            updatedBounds.documentLeft = currentBounds.left
            const offsetLeft = -(currentBounds.left - boundingRect.left - margin) + "px"
            const offsetTop = -(currentBounds.top - boundingRect.top - margin) + "px"
            positionObserver.style.transform = "translate(" + (offsetLeft) + "," + (offsetTop) + ")"
            //setPosition(updatedBounds)
          }
          /*if (cb !== undefined) {
            cb(updatedBounds)
          }* /
          //callback();
        }
      }, {threshold: [0.125, 0.375, 0.625, 0.875], root: boundingRef.current })
      io.observe(positionObserver)
    }
    return () => {
      if (io) {
        io.disconnect()
      }
    }
  }, [ref, bounds, position])


  const containerScrolled = (e) =>  {
    forceBoundsUpdate()
  }
  useEffect(() => {
    if (scrollContainerRef !== undefined && scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", containerScrolled)
    }
    return () =>  {
      if (scrollContainerRef !== undefined && scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", containerScrolled)
      }
    }
  }, [scrollContainerRef])

  useEffect(() => {
    if (ref.current) {
      if (boundingRef !== undefined && boundingRef.current) {
        ref.current.style.position = "relative"
        ref.current.appendChild(positionObserver);
      }
      ro.observe(ref.current)
    }
    return () => {
      ro.disconnect()
      positionObserver.remove();
    }
  }, [])

  const forceBoundsUpdate = () => {
    if (ref.current)  {
      const currentBounds = ref.current.getBoundingClientRect()
      if (bounds.documentLeft != currentBounds.left || bounds.documentTop != currentBounds.top) {
        let updatedBounds = {...bounds}
        updatedBounds.documentTop = currentBounds.top
        updatedBounds.documentLeft = currentBounds.left
        set(updatedBounds)
        if (cb !== undefined) {
          cb(updatedBounds)
        }
      }
    }
  }
  return [{ref}, forceBoundsUpdate, bounds]
}
*/