import React from 'react'
import './RackIcons.scss';

export function ScanRack({width}) {
  
  return (
   <svg className="ScanRackIcon" width={width} viewBox="0 0 221 123" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 2400" clip-path="url(#clip0_164_29613)">
    <g id="scan_rack_graphic">
    <g id="Group 1468">
    <path id="Vector 228" d="M32.9031 119.267V10.0947L193.296 0L200.774 3.73877V112.163L40.0067 122.632L32.9031 119.267Z" fill="white"/>
    <g id="Group 1438">
    <g id="Group 1437">
    <circle id="Ellipse 575" cx="41.9955" cy="113.022" r="0.519627" fill="black"/>
    <circle id="Ellipse 576" cx="41.9955" cy="83.922" r="0.519627" fill="black"/>
    <g id="Group 1395">
    <path id="Vector 218" d="M197.105 14.291L64.0803 22.8649V115.358" stroke="#2E72D2"/>
    <path id="Vector 216" d="M32.9036 10.1347L40.4382 13.5123M32.9036 10.1347L193.468 0.00195312L201.003 3.37953M32.9036 10.1347V20.5272M40.4382 13.5123L201.003 3.37953M40.4382 13.5123L40.4122 24.4244M40.1783 122.634L32.9036 119.256V113.54M40.1783 122.634L40.192 116.918M40.1783 122.634L201.003 112.241V106.785M32.9036 113.54L40.192 116.918M32.9036 113.54V20.5272M40.192 116.918L64.0812 115.413M40.192 116.918L40.2786 80.5441M201.003 3.37953V14.0319M201.003 106.785V14.0319M201.003 106.785L197.106 107.031M64.0812 115.413L197.106 107.031M64.0812 115.413V114.06M32.9036 20.5272L40.4122 24.4244M40.4122 24.4244L64.0812 22.8927M40.4122 24.4244L40.2786 80.5441M201.003 14.0319L197.106 14.2841M64.0812 22.8927L85.1261 21.5308M64.0812 22.8927V38.1945M40.2786 80.5441L64.0812 78.9853M64.0812 78.9853V68.3329M64.0812 78.9853V83.6619M197.106 14.2841V107.031M197.106 14.2841L195.547 14.385M197.106 107.031L195.287 106.006L195.331 90.4171M195.547 14.385L173.593 15.8057M195.547 14.385L195.503 29.8805M194.508 105.746L173.073 107.112M64.0812 114.06L85.1261 112.719M64.0812 114.06V98.7311M85.1261 112.719V21.5308M85.1261 112.719L107.21 111.311M85.1261 21.5308L107.21 20.1016M107.21 20.1016V111.311M107.21 20.1016L129.294 18.6725M107.21 111.311L129.294 109.903M129.294 109.903V18.6725M129.294 109.903L151.638 108.479M129.294 18.6725L151.638 17.2265M151.638 17.2265V108.479M151.638 17.2265L173.593 15.8057M151.638 108.479L173.073 107.112M173.073 107.112L173.593 15.8057M195.503 29.8805L64.0812 38.1945M195.503 29.8805L195.46 44.9497M64.0812 38.1945V53.2637M64.0812 53.2637L195.46 44.9497M64.0812 53.2637V68.3329M195.46 44.9497L195.417 60.0189M195.417 60.0189L64.0812 68.3329M195.417 60.0189L195.374 75.3479M195.374 75.3479L64.0812 83.6619M195.374 75.3479L195.331 90.4171M64.0812 83.6619V98.7311M195.331 90.4171L64.0812 98.7311" stroke="#2E72D2" stroke-width="0.5"/>
    <path id="highlight_indicator" d="M32.9036 10.1347V119.256L40.1783 122.634L201.003 112.241V3.37953L193.468 0.00195312L32.9036 10.1347Z" stroke="#2E72D2" stroke-width="1.5"/>
    </g>
    </g>
    </g>
    </g>
    <path id="Rectangle 1994" d="M34.8235 43.8945L38.6643 45.3478V51.5762L34.8235 50.1229V43.8945Z" fill="#2E72D2"/>
    <path id="Rectangle 2019" d="M34.8235 51.0273L38.6643 52.4056V52.9478L34.8235 51.5695V51.0273Z" fill="#DDE3EB"/>
    <path id="Vector 228_2" opacity="0.4" d="M33.7128 44.5156L22.0068 54.4327L35.7553 52.1165C37.1942 51.8741 38.4323 50.9625 39.091 49.6604L39.4165 49.017C40.209 47.4503 39.7936 45.5424 38.4215 44.4469C37.0371 43.3418 35.0644 43.3706 33.7128 44.5156Z" fill="#2E72D2"/>
    <path id="phone_scanning" d="M22.6756 44.7263L27.6756 47.2488C28.1812 47.5039 28.5 48.0218 28.5 48.588V63.5673C28.5 64.6847 27.322 65.4098 26.3244 64.9065L21.3244 62.384C20.8188 62.1289 20.5 61.611 20.5 61.0448V46.0655C20.5 44.9481 21.678 44.223 22.6756 44.7263Z" fill="white" stroke="#2E72D2"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_164_29613">
    <rect width="221" height="123" fill="white"/>
    </clipPath>
    </defs>
   </svg>
   
  )
}
ScanRack.defaultProps = {
  width: "auto"
}


export function RackLoading({width})  {
  return(
    <svg className="RackLoadingIcon" width={width} viewBox="0 0 227 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1534">
      <g id="Group 1532">
      <path id="Vector 228" d="M1.09727 160.5V14.5L215.597 1L225.597 6V151L10.5973 165L1.09727 160.5Z" fill="white"/>
      <g id="Group 1438">
      <g id="Group 1437">
      <circle id="Ellipse 575" cx="13.2572" cy="152.144" r="0.694916" fill="#2E72D2"/>
      <circle id="Ellipse 576" cx="13.2572" cy="113.228" r="0.694916" fill="#2E72D2"/>
      <g id="Group 1395">
      <path id="Vector 218" d="M220.691 20.1094L42.7924 31.5755V155.271" stroke="#2E72D2"/>
      <path id="Vector 216" d="M1.09727 14.5509L11.1736 19.0678M1.09727 14.5509L215.826 1L225.903 5.51696M1.09727 14.5509V28.4492M11.1736 19.0678L225.903 5.51696M11.1736 19.0678L11.1388 33.6611M10.8261 165L1.09727 160.483V152.839M10.8261 165L10.8443 157.356M10.8261 165L225.903 151.102V143.805M1.09727 152.839L10.8443 157.356M1.09727 152.839V28.4492M10.8443 157.356L42.7923 155.343M10.8443 157.356L10.9601 108.712M225.903 5.51696V19.7627M225.903 143.805V19.7627M225.903 143.805L220.691 144.134M42.7923 155.343L220.691 144.134M42.7923 155.343V153.534M1.09727 28.4492L11.1388 33.6611M11.1388 33.6611L42.7923 31.6126M11.1388 33.6611L10.9601 108.712M225.903 19.7627L220.691 20.1M42.7923 31.6126L70.9364 29.7913M42.7923 31.6126V52.0764M10.9601 108.712L42.7923 106.627M42.7923 106.627V92.3815M42.7923 106.627V112.882M220.691 20.1V144.134M220.691 20.1L218.606 20.2349M220.691 144.134L218.259 142.763L218.318 121.915M218.606 20.2349L189.246 22.135M218.606 20.2349L218.547 40.9577M217.216 142.415L188.551 144.243M42.7923 153.534L70.9364 151.74M42.7923 153.534V133.034M70.9364 151.74V29.7913M70.9364 151.74L100.47 149.857M70.9364 29.7913L100.47 27.88M100.47 27.88V149.857M100.47 27.88L130.004 25.9688M100.47 149.857L130.004 147.975M130.004 147.975V25.9688M130.004 147.975L159.886 146.07M130.004 25.9688L159.886 24.035M159.886 24.035V146.07M159.886 24.035L189.246 22.135M159.886 146.07L188.551 144.243M188.551 144.243L189.246 22.135M218.547 40.9577L42.7923 52.0764M218.547 40.9577L218.49 61.1103M42.7923 52.0764V72.2289M42.7923 72.2289L218.49 61.1103M42.7923 72.2289V92.3815M218.49 61.1103L218.433 81.2628M218.433 81.2628L42.7923 92.3815M218.433 81.2628L218.375 101.763M218.375 101.763L42.7923 112.882M218.375 101.763L218.318 121.915M42.7923 112.882V133.034M218.318 121.915L42.7923 133.034" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 217" d="M1.09727 14.5509V160.483L10.8261 165L225.903 151.102V5.51696L215.826 1L1.09727 14.5509Z" stroke="#2E72D2" stroke-width="1.5"/>
      </g>
      </g>
      </g>
      </g>
      <g id="loading_dots">
      <circle id="dot_1" cx="84.0973" cy="88" r="10" fill="#2E72D2"/>
      <circle id="dot_2" cx="124.097" cy="88" r="10" fill="#2E72D2"/>
      <circle id="dot_3" cx="164.097" cy="88" r="10" fill="#2E72D2"/>
      </g>
      </g>
    </svg>
  )
}
RackLoading.defaultProps = {
  width: 227
}


export function RackConnectionFailed({width})  {
  return(
    <svg className="RackConnectionFailed" width={width} viewBox="0 0 227 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1536">
      <g id="Group 1535">
      <path id="Vector 228" d="M1.09729 160.5V14.5L215.597 1L225.597 6V151L10.5973 165L1.09729 160.5Z" fill="white"/>
      <g id="Group 1438">
      <g id="Group 1437">
      <circle id="Ellipse 575" cx="13.2572" cy="152.144" r="0.694916" fill="#2E72D2"/>
      <circle id="Ellipse 576" cx="13.2572" cy="113.228" r="0.694916" fill="#2E72D2"/>
      <g id="Group 1395">
      <path id="Vector 218" d="M220.691 20.1094L42.7924 31.5755V155.271" stroke="#2E72D2"/>
      <path id="Vector 216" d="M1.09729 14.5509L11.1736 19.0678M1.09729 14.5509L215.826 1L225.903 5.51696M1.09729 14.5509V28.4492M11.1736 19.0678L225.903 5.51696M11.1736 19.0678L11.1388 33.6611M10.8261 165L1.09729 160.483V152.839M10.8261 165L10.8443 157.356M10.8261 165L225.903 151.102V143.805M1.09729 152.839L10.8443 157.356M1.09729 152.839V28.4492M10.8443 157.356L42.7923 155.343M10.8443 157.356L10.9601 108.712M225.903 5.51696V19.7627M225.903 143.805V19.7627M225.903 143.805L220.691 144.134M42.7923 155.343L220.691 144.134M42.7923 155.343V153.534M1.09729 28.4492L11.1388 33.6611M11.1388 33.6611L42.7923 31.6126M11.1388 33.6611L10.9601 108.712M225.903 19.7627L220.691 20.1M42.7923 31.6126L70.9364 29.7913M42.7923 31.6126V52.0764M10.9601 108.712L42.7923 106.627M42.7923 106.627V92.3815M42.7923 106.627V112.882M220.691 20.1V144.134M220.691 20.1L218.606 20.2349M220.691 144.134L218.259 142.763L218.318 121.915M218.606 20.2349L189.246 22.135M218.606 20.2349L218.547 40.9577M217.216 142.415L188.551 144.243M42.7923 153.534L70.9364 151.74M42.7923 153.534V133.034M70.9364 151.74V29.7913M70.9364 151.74L100.47 149.857M70.9364 29.7913L100.47 27.88M100.47 27.88V149.857M100.47 27.88L130.004 25.9688M100.47 149.857L130.004 147.975M130.004 147.975V25.9688M130.004 147.975L159.886 146.07M130.004 25.9688L159.886 24.035M159.886 24.035V146.07M159.886 24.035L189.246 22.135M159.886 146.07L188.551 144.243M188.551 144.243L189.246 22.135M218.547 40.9577L42.7923 52.0764M218.547 40.9577L218.49 61.1103M42.7923 52.0764V72.2289M42.7923 72.2289L218.49 61.1103M42.7923 72.2289V92.3815M218.49 61.1103L218.433 81.2628M218.433 81.2628L42.7923 92.3815M218.433 81.2628L218.375 101.763M218.375 101.763L42.7923 112.882M218.375 101.763L218.318 121.915M42.7923 112.882V133.034M218.318 121.915L42.7923 133.034" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 217" d="M1.09729 14.5509V160.483L10.8261 165L225.903 151.102V5.51696L215.826 1L1.09729 14.5509Z" stroke="#2E72D2" stroke-width="1.5"/>
      </g>
      </g>
      </g>
      </g>
      <g id="Red X">
      <circle id="Ellipse 577" cx="126.597" cy="86.5" r="39.5" fill="#EB3223"/>
      <path id="Vector 251" d="M108.097 68L145.097 105M108.097 105L145.097 68" stroke="white" stroke-width="8"/>
      </g>
      </g>
    </svg>
  )
}
RackConnectionFailed.defaultProps = {
  width: 227
}

 

export function RackConnectionSuccess({width, maxWidth})  {
  return(
    <svg className="RackConnectionSuccess" width={width} maxWidth={maxWidth} viewBox="0 0 227 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 160.5V14.5L215.5 1L225.5 6V151L10.5 165L1 160.5Z" fill="white"/>
      <circle cx="13.1598" cy="152.144" r="0.694916" fill="#2E72D2"/>
      <circle cx="13.1598" cy="113.229" r="0.694916" fill="#2E72D2"/>
      <path d="M220.594 20.1104L42.6953 31.5765V155.272" stroke="#2E72D2"/>
      <path d="M1 14.5509L11.0763 19.0678M1 14.5509L215.729 1L225.805 5.51696M1 14.5509V28.4492M11.0763 19.0678L225.805 5.51696M11.0763 19.0678L11.0415 33.6611M10.7288 165L1 160.483V152.839M10.7288 165L10.747 157.356M10.7288 165L225.805 151.102V143.805M1 152.839L10.747 157.356M1 152.839V28.4492M10.747 157.356L42.695 155.343M10.747 157.356L10.8628 108.712M225.805 5.51696V19.7627M225.805 143.805V19.7627M225.805 143.805L220.594 144.134M42.695 155.343L220.594 144.134M42.695 155.343V153.534M1 28.4492L11.0415 33.6611M11.0415 33.6611L42.695 31.6126M11.0415 33.6611L10.8628 108.712M225.805 19.7627L220.594 20.1M42.695 31.6126L70.8391 29.7913M42.695 31.6126V52.0764M10.8628 108.712L42.695 106.627M42.695 106.627V92.3815M42.695 106.627V112.882M220.594 20.1V144.134M220.594 20.1L218.509 20.2349M220.594 144.134L218.161 142.763L218.22 121.915M218.509 20.2349L189.149 22.135M218.509 20.2349L218.45 40.9577M217.119 142.415L188.454 144.243M42.695 153.534L70.8391 151.74M42.695 153.534V133.034M70.8391 151.74V29.7913M70.8391 151.74L100.373 149.857M70.8391 29.7913L100.373 27.88M100.373 27.88V149.857M100.373 27.88L129.907 25.9688M100.373 149.857L129.907 147.975M129.907 147.975V25.9688M129.907 147.975L159.788 146.07M129.907 25.9688L159.788 24.035M159.788 24.035V146.07M159.788 24.035L189.149 22.135M159.788 146.07L188.454 144.243M188.454 144.243L189.149 22.135M218.45 40.9577L42.695 52.0764M218.45 40.9577L218.393 61.1103M42.695 52.0764V72.2289M42.695 72.2289L218.393 61.1103M42.695 72.2289V92.3815M218.393 61.1103L218.336 81.2628M218.336 81.2628L42.695 92.3815M218.336 81.2628L218.278 101.763M218.278 101.763L42.695 112.882M218.278 101.763L218.22 121.915M42.695 112.882V133.034M218.22 121.915L42.695 133.034" stroke="#2E72D2" stroke-width="0.5"/>
      <path d="M1 14.5509V160.483L10.7288 165L225.805 151.102V5.51696L215.729 1L1 14.5509Z" stroke="#2E72D2" stroke-width="1.5"/>
      <circle cx="125.5" cy="82.5" r="39.5" fill="#2ED25C"/>
      <path d="M107.742 84.2726L117.662 96.6331L143.256 64.7432" stroke="white" stroke-width="8"/>
    </svg> 
  )
}
RackConnectionSuccess.defaultProps = {
  width: 227,
  maxWidth: 227,
}


export function RackStandardGrowOut({width, maxWidth})  {
  return (
    <svg id="RackStandardGrowOut" width={width} style={{maxWidth:maxWidth}} viewBox="0 0 227 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.09766 160.5V14.5L215.598 1L225.598 6V151L10.5977 165L1.09766 160.5Z" fill="white" stroke="#2E72D2"/>
      <circle cx="13.2574" cy="152.144" r="0.5" fill="black" stroke="#2E72D2" stroke-width="0.389833"/>
      <circle cx="13.2574" cy="113.229" r="0.5" fill="black" stroke="#2E72D2" stroke-width="0.389833"/>
      <path d="M220.692 20.1104L42.793 31.5765V155.272" stroke="#2E72D2"/>
      <path d="M1.09766 14.5509L11.1739 19.0678M1.09766 14.5509L215.827 1L225.903 5.51696M1.09766 14.5509V28.4492M11.1739 19.0678L225.903 5.51696M11.1739 19.0678L11.1392 33.6611M10.8265 165L1.09766 160.483V152.839M10.8265 165L10.8447 157.356M10.8265 165L225.903 151.102V143.805M1.09766 152.839L10.8447 157.356M1.09766 152.839V28.4492M10.8447 157.356L42.7926 155.343M10.8447 157.356L10.9605 108.712M225.903 5.51696V19.7627M225.903 143.805V19.7627M225.903 143.805L220.691 144.134M42.7926 155.343L220.691 144.134M42.7926 155.343V153.534M1.09766 28.4492L11.1392 33.6611M11.1392 33.6611L42.7926 31.6126M11.1392 33.6611L10.9605 108.712M225.903 19.7627L220.691 20.1M42.7926 31.6126L70.9368 29.7913M42.7926 31.6126V52.0764M10.9605 108.712L42.7926 106.627M42.7926 106.627V92.3815M42.7926 106.627V112.882M220.691 20.1V144.134M220.691 20.1L218.606 20.2349M220.691 144.134L218.259 142.763L218.318 121.915M218.606 20.2349L189.246 22.135M218.606 20.2349L218.548 40.9577M217.217 142.415L188.551 144.243M42.7926 153.534L70.9368 151.74M42.7926 153.534V133.034M70.9368 151.74V29.7913M70.9368 151.74L100.471 149.857M70.9368 29.7913L100.471 27.88M100.471 27.88V149.857M100.471 27.88L130.005 25.9688M100.471 149.857L130.005 147.975M130.005 147.975V25.9688M130.005 147.975L159.886 146.07M130.005 25.9688L159.886 24.035M159.886 24.035V146.07M159.886 24.035L189.246 22.135M159.886 146.07L188.551 144.243M188.551 144.243L189.246 22.135M218.548 40.9577L42.7926 52.0764M218.548 40.9577L218.491 61.1103M42.7926 52.0764V72.2289M42.7926 72.2289L218.491 61.1103M42.7926 72.2289V92.3815M218.491 61.1103L218.433 81.2628M218.433 81.2628L42.7926 92.3815M218.433 81.2628L218.375 101.763M218.375 101.763L42.7926 112.882M218.375 101.763L218.318 121.915M42.7926 112.882V133.034M218.318 121.915L42.7926 133.034" stroke="#2E72D2" stroke-width="0.5"/>
      <path d="M1.09766 14.5509V160.483L10.8265 165L225.903 151.102V5.51696L215.827 1L1.09766 14.5509Z" stroke="#2E72D2" stroke-width="1.5"/>
    </svg> 
  )
}
RackStandardGrowOut.defaultProps = {
  width: 227,
  maxWidth: 227,
}


export function RackStandardEnvironment({width, maxWidth})  {
  return (
    <svg id="RackStandardEnvironment" maxWidth={maxWidth} width={width} viewBox="0 0 199 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1509">
      <path id="Vector 228" d="M196.539 139.924V12.7585L9.70966 1L0.999664 5.355V131.65L188.265 143.844L196.539 139.924Z" fill="white"/>
      <g id="Group 1468">
      <circle id="Ellipse 575" cx="0.607419" cy="0.607419" r="0.607419" transform="matrix(-1 0 0 1 187.479 132.506)" fill="#2E72D2"/>
      <circle id="Ellipse 576" cx="0.607419" cy="0.607419" r="0.607419" transform="matrix(-1 0 0 1 187.479 98.4902)" fill="#2E72D2"/>
      <g id="Group 1395">
      <path id="Vector 218" d="M5.55547 17.7031L161.055 27.7255V135.846" stroke="#2E72D2"/>
      <path id="Vector 216" d="M197.5 12.8447L188.692 16.7929M197.5 12.8447L9.80759 1L1 4.94822M197.5 12.8447V24.993M188.692 16.7929L1 4.94822M188.692 16.7929L188.723 29.5487M188.996 144.351L197.5 140.403V133.721M188.996 144.351L188.98 137.669M188.996 144.351L1 132.202V125.825M197.5 133.721L188.98 137.669M197.5 133.721V24.993M188.98 137.669L161.055 135.91M188.98 137.669L188.879 95.1499M1 4.94822V17.4003M1 125.825V17.4003M1 125.825L5.55565 126.112M161.055 135.91L5.55565 126.112M161.055 135.91V134.328M197.5 24.993L188.723 29.5487M188.723 29.5487L161.055 27.7582M188.723 29.5487L188.879 95.1499M1 17.4003L5.55565 17.6951M161.055 27.7582L136.454 26.1662L110.639 24.4955L84.8239 22.8249L58.7048 21.1346L33.0414 19.4738L7.3779 17.8131M161.055 27.7582V45.6453V63.2604V80.8756V93.3277M188.879 95.1499L161.055 93.3277M161.055 93.3277V98.7944V116.41V134.328M5.55565 17.6951V126.112M5.55565 17.6951L7.3779 17.8131M5.55565 126.112L7.68161 124.913L7.62996 106.691L7.58 89.0757L7.52919 71.1569L7.47923 53.5417L7.42928 35.9266L7.3779 17.8131M8.59274 124.61L33.6488 126.207L58.7048 127.804L84.8239 129.469L110.639 131.115L136.454 132.76L161.055 134.328" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 217" d="M197.5 12.8447V140.403L188.996 144.351L1 132.202V4.94822L9.80759 1L197.5 12.8447Z" stroke="#2E72D2" stroke-width="1.5"/>
      </g>
      </g>
      <path id="Rectangle 2033" d="M58 28L57 28.3784V30L58 29.6216V28Z" fill="#2E72D2"/>
      <path id="Rectangle 2034" d="M109 32L108 32.3784V34L109 33.6216V32Z" fill="#2E72D2"/>
      <path id="Rectangle 2035" d="M160 36L159 36.3784V38L160 37.6216V36Z" fill="#2E72D2"/>
      <path id="Rectangle 2036" d="M58 42L57 42.3784V44L58 43.6216V42Z" fill="#2E72D2"/>
      <path id="Rectangle 2037" d="M109 46L108 46.3784V48L109 47.6216V46Z" fill="#2E72D2"/>
      <path id="Rectangle 2038" d="M160 50L159 50.3784V52L160 51.6216V50Z" fill="#2E72D2"/>
      <path id="Rectangle 2039" d="M58 58L57 58.3784V60L58 59.6216V58Z" fill="#2E72D2"/>
      <path id="Rectangle 2040" d="M109 62L108 62.3784V64L109 63.6216V62Z" fill="#2E72D2"/>
      <path id="Rectangle 2041" d="M160 66L159 66.3784V68L160 67.6216V66Z" fill="#2E72D2"/>
      <path id="Rectangle 2042" d="M58 72L57 72.3784V74L58 73.6216V72Z" fill="#2E72D2"/>
      <path id="Rectangle 2043" d="M109 76L108 76.3784V78L109 77.6216V76Z" fill="#2E72D2"/>
      <path id="Rectangle 2044" d="M160 80L159 80.3784V82L160 81.6216V80Z" fill="#2E72D2"/>
      <path id="Rectangle 2045" d="M58 86L57 86.3784V88L58 87.6216V86Z" fill="#2E72D2"/>
      <path id="Rectangle 2046" d="M109 90L108 90.3784V92L109 91.6216V90Z" fill="#2E72D2"/>
      <path id="Rectangle 2047" d="M160 94L159 94.3784V96L160 95.6216V94Z" fill="#2E72D2"/>
      <path id="Rectangle 2048" d="M58 102L57 102.378V104L58 103.622V102Z" fill="#2E72D2"/>
      <path id="Rectangle 2049" d="M109 106L108 106.378V108L109 107.622V106Z" fill="#2E72D2"/>
      <path id="Rectangle 2050" d="M160 110L159 110.378V112L160 111.622V110Z" fill="#2E72D2"/>
      <g id="Group 1469">
      <path id="Vector 222" d="M10 24.5L56 27.5M10 24.5V29L56 32M10 24.5L14.5 24L59 27M56 27.5V32M56 27.5L59 27M56 32L59 30V27" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223" d="M54.5 28.5L11 25.5V28L54.5 31V28.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1470">
      <path id="Vector 222_2" d="M10 68.5L56 71.5M10 68.5V73L56 76M10 68.5L14.5 68L59 71M56 71.5V76M56 71.5L59 71M56 76L59 74V71" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_2" d="M54.5 72.5L11 69.5V72L54.5 75V72.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1471">
      <path id="Vector 222_3" d="M10 38.5L56 41.5M10 38.5V43L56 46M10 38.5L14.5 38L59 41M56 41.5V46M56 41.5L59 41M56 46L59 44V41" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_3" d="M54.5 42.5L11 39.5V42L54.5 45V42.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1472">
      <path id="Vector 222_4" d="M10 82.5L56 85.5M10 82.5V87L56 90M10 82.5L14.5 82L59 85M56 85.5V90M56 85.5L59 85M56 90L59 88V85" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_4" d="M54.5 86.5L11 83.5V86L54.5 89V86.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1473">
      <path id="Vector 222_5" d="M10 54.5L56 57.5M10 54.5V59L56 62M10 54.5L14.5 54L59 57M56 57.5V62M56 57.5L59 57M56 62L59 60V57" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_5" d="M54.5 58.5L11 55.5V58L54.5 61V58.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1474">
      <path id="Vector 222_6" d="M10 98.5L56 101.5M10 98.5V103L56 106M10 98.5L14.5 98L59 101M56 101.5V106M56 101.5L59 101M56 106L59 104V101" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_6" d="M54.5 102.5L11 99.5V102L54.5 105V102.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1475">
      <path id="Vector 222_7" d="M61 28.5L107 31.5M61 28.5V33L107 36M61 28.5L65.5 28L110 31M107 31.5V36M107 31.5L110 31M107 36L110 34V31" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_7" d="M105.5 32.5L62 29.5V32L105.5 35V32.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1476">
      <path id="Vector 222_8" d="M61 72.5L107 75.5M61 72.5V77L107 80M61 72.5L65.5 72L110 75M107 75.5V80M107 75.5L110 75M107 80L110 78V75" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_8" d="M105.5 76.5L62 73.5V76L105.5 79V76.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1477">
      <path id="Vector 222_9" d="M61 42.5L107 45.5M61 42.5V47L107 50M61 42.5L65.5 42L110 45M107 45.5V50M107 45.5L110 45M107 50L110 48V45" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_9" d="M105.5 46.5L62 43.5V46L105.5 49V46.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1478">
      <path id="Vector 222_10" d="M61 86.5L107 89.5M61 86.5V91L107 94M61 86.5L65.5 86L110 89M107 89.5V94M107 89.5L110 89M107 94L110 92V89" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_10" d="M105.5 90.5L62 87.5V90L105.5 93V90.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1479">
      <path id="Vector 222_11" d="M61 58.5L107 61.5M61 58.5V63L107 66M61 58.5L65.5 58L110 61M107 61.5V66M107 61.5L110 61M107 66L110 64V61" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_11" d="M105.5 62.5L62 59.5V62L105.5 65V62.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1480">
      <path id="Vector 222_12" d="M61 102.5L107 105.5M61 102.5V107L107 110M61 102.5L65.5 102L110 105M107 105.5V110M107 105.5L110 105M107 110L110 108V105" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_12" d="M105.5 106.5L62 103.5V106L105.5 109V106.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1481">
      <path id="Vector 222_13" d="M112 32.5L158 35.5M112 32.5V37L158 40M112 32.5L116.5 32L161 35M158 35.5V40M158 35.5L161 35M158 40L161 38V35" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_13" d="M156.5 36.5L113 33.5V36L156.5 39V36.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1482">
      <path id="Vector 222_14" d="M112 76.5L158 79.5M112 76.5V81L158 84M112 76.5L116.5 76L161 79M158 79.5V84M158 79.5L161 79M158 84L161 82V79" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_14" d="M156.5 80.5L113 77.5V80L156.5 83V80.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1483">
      <path id="Vector 222_15" d="M112 46.5L158 49.5M112 46.5V51L158 54M112 46.5L116.5 46L161 49M158 49.5V54M158 49.5L161 49M158 54L161 52V49" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_15" d="M156.5 50.5L113 47.5V50L156.5 53V50.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1484">
      <path id="Vector 222_16" d="M112 90.5L158 93.5M112 90.5V95L158 98M112 90.5L116.5 90L161 93M158 93.5V98M158 93.5L161 93M158 98L161 96V93" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_16" d="M156.5 94.5L113 91.5V94L156.5 97V94.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1485">
      <path id="Vector 222_17" d="M112 62.5L158 65.5M112 62.5V67L158 70M112 62.5L116.5 62L161 65M158 65.5V70M158 65.5L161 65M158 70L161 68V65" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_17" d="M156.5 66.5L113 63.5V66L156.5 69V66.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1486">
      <path id="Vector 222_18" d="M112 106.5L158 109.5M112 106.5V111L158 114M112 106.5L116.5 106L161 109M158 109.5V114M158 109.5L161 109M158 114L161 112V109" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_18" d="M156.5 110.5L113 107.5V110L156.5 113V110.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      </g>
    </svg>
  )
}
RackStandardEnvironment.defaultProps = {
  width: 199
}



export function RackStandardGroup({width, maxWidth})  {
  return (
    <svg id="RackStandardGroup" width={width} maxWidth={maxWidth} viewBox="0 0 244 152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1543">
      <g id="Group 1542">
      <path id="Vector 287" d="M33 1H6.5L1 12.6383V151H28L31 145V151H58L61 145V151H88L91 145V151H118L121.5 145V151H148L151.5 145V151H178.5L181.5 145V151H208.5L211 146V151H238L243 140.5V1H217L213.5 8V1H187L183.291 8.7927V1H157L153.183 9V1H127L123.228 8.7315V1H97L93 9V1H67L63 9V1H37L33 8.5V1Z" fill="white"/>
      </g>
      <g id="Group 1541">
      <path id="Vector 237" d="M213.474 8.01559V1H187.082L181.402 12.6927V151H208.462L211.135 145.655" stroke="#2E72D2"/>
      <path id="Vector 238" d="M183.403 8.01559V1H157.011L151.332 12.6927V151H178.392L181.065 145.655" stroke="#2E72D2"/>
      <path id="Vector 240" d="M153.337 8.01559V1H126.945L121.266 12.6927V151H148.326L150.998 145.655" stroke="#2E72D2"/>
      <path id="Vector 242" d="M123.27 8.01559V1H96.8785L91.1992 12.6927V151H118.259L120.932 145.655" stroke="#2E72D2"/>
      <path id="Vector 244" d="M93.2041 8.01559V1H66.8121L61.1328 12.6927V151H88.1929L90.8656 145.655" stroke="#2E72D2"/>
      <path id="Vector 246" d="M63.1377 8.01559V1H36.7457L31.0664 12.6927V151H58.1265L60.7991 145.655" stroke="#2E72D2"/>
      <path id="Vector 248" d="M33.0713 8.01559V1H6.67929L1 12.6927V151H28.0601L30.7327 145.655" stroke="#2E72D2"/>
      <path id="Vector 235" d="M211.133 12.6926V151H238.193L243.204 140.31V1H216.812L211.133 12.6926Z" stroke="#2E72D2"/>
      <path id="Vector 234" d="M238.194 24.7197H211.134M208.461 24.7197H181.401M178.394 24.7197H151.334M148.327 24.7197H121.267M118.26 24.7197H91.2003M88.1937 24.7197H61.1335M57.7928 24.7197H30.7326M28.06 24.7197H0.999885M178.394 143.317H151.334M148.327 143.317H121.267M118.26 143.317H91.2003M88.1937 143.317H61.1335M57.7928 143.317H30.7326M208.461 143.317H181.401M28.0015 143.317H0.941406" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 233" d="M243.206 15.3652L238.195 24.7194" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 231" d="M238.193 151H211.133V12.6927M238.193 151V12.6927M238.193 151L243.204 140.31V1M238.193 12.6927H211.133M238.193 12.6927L243.204 1M211.133 12.6927L216.812 1H243.204M238.861 142.314L242.536 134.964V16.6079L238.861 23.7171V142.314Z" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 236" d="M208.462 151H181.402V12.6927M208.462 151V12.6927M208.462 151L211.135 145.655M208.462 12.6927H181.402M208.462 12.6927L213.474 1H187.082L181.402 12.6927M211.135 138.973L209.131 142.314V23.7171L211.268 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 239" d="M178.392 151H151.332V12.6927M178.392 151V12.6927M178.392 151L181.065 145.655M178.392 12.6927H151.332M178.392 12.6927L183.403 1H157.011L151.332 12.6927M181.065 138.973L179.06 142.314V23.7171L181.198 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 241" d="M148.326 151H121.266V12.6927M148.326 151V12.6927M148.326 151L150.998 145.655M148.326 12.6927H121.266M148.326 12.6927L153.337 1H126.945L121.266 12.6927M150.998 138.973L148.994 142.314V23.7171L151.131 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 243" d="M118.259 151H91.1992V12.6927M118.259 151V12.6927M118.259 151L120.932 145.655M118.259 12.6927H91.1992M118.259 12.6927L123.27 1H96.8785L91.1992 12.6927M120.932 138.973L118.928 142.314V23.7171L121.065 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 245" d="M88.1929 151H61.1328V12.6927M88.1929 151V12.6927M88.1929 151L90.8656 145.655M88.1929 12.6927H61.1328M88.1929 12.6927L93.2041 1H66.8121L61.1328 12.6927M90.8656 138.973L88.8611 142.314V23.7171L90.9984 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 247" d="M58.1265 151H31.0664V12.6927M58.1265 151V12.6927M58.1265 151L60.7991 145.655M58.1265 12.6927H31.0664M58.1265 12.6927L63.1377 1H36.7457L31.0664 12.6927M60.7991 138.973L58.7947 142.314V23.7171L60.9319 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 249" d="M28.0601 151H1V12.6927M28.0601 151V12.6927M28.0601 151L30.7327 145.655M28.0601 12.6927H1M28.0601 12.6927L33.0713 1H6.67929L1 12.6927M30.7327 138.973L28.7283 142.314V23.7171L30.8655 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 232" d="M242.536 134.965H238.861M211.133 143.651H238.193" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      </g>
    </svg>

  )
}
RackStandardGroup.defaultProps = {
  width: "100%",
  maxWidth: 244,
}



export function RackPowerOnTutorial({width})  {
  return (
    <svg id={RackPowerOnTutorial} width={width} viewBox="0 0 254 196"fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 2801" clip-path="url(#clip0_388_43141)">
      <g id="Group 1541">
      <path id="Power button" fill-rule="evenodd" clip-rule="evenodd" d="M101.351 99.6355C96.0248 114.605 81.7304 125.323 64.9328 125.323C48.1353 125.323 33.8409 114.605 28.5147 99.6355H101.351ZM101.351 73.7223H28.5147C33.8409 58.7526 48.1353 48.0352 64.9328 48.0352C81.7304 48.0352 96.0248 58.7526 101.351 73.7223ZM111.577 86.6789C111.577 112.44 90.6935 133.323 64.9328 133.323C39.1722 133.323 18.2891 112.44 18.2891 86.6789C18.2891 60.9183 39.1722 40.0352 64.9328 40.0352C90.6935 40.0352 111.577 60.9183 111.577 86.6789Z" fill="#2E72D2"/>
      <path id="Power button_2" fill-rule="evenodd" clip-rule="evenodd" d="M204.864 50.2584C219.834 55.5846 230.551 69.879 230.551 86.6765C230.551 103.474 219.834 117.769 204.864 123.095V50.2584ZM178.951 50.2584V123.095C163.981 117.769 153.264 103.474 153.264 86.6765C153.264 69.879 163.981 55.5846 178.951 50.2584ZM191.907 40.0328C217.668 40.0328 238.551 60.9159 238.551 86.6765C238.551 112.437 217.668 133.32 191.907 133.32C166.147 133.32 145.264 112.437 145.264 86.6765C145.264 60.9159 166.147 40.0328 191.907 40.0328Z" fill="#2E72D2"/>
      <circle id="Ellipse 605" cx="59.5508" cy="172.059" r="18" fill="#EC3C3C"/>
      <circle id="Ellipse 606" cx="191.551" cy="172.059" r="18" fill="#5AE044"/>
      <path id="Arrow 4" d="M112.238 29.3303L110.375 30.9975L112.238 29.3303ZM120.507 41.2222C121.886 41.2987 123.065 40.2432 123.142 38.8646L124.388 16.3991C124.465 15.0205 123.409 13.8409 122.03 13.7645C120.652 13.688 119.472 14.7436 119.396 16.1221L118.288 36.0914L98.3187 34.9836C96.9401 34.9071 95.7605 35.9626 95.684 37.3412C95.6075 38.7198 96.6631 39.8994 98.0417 39.9759L120.507 41.2222ZM110.375 30.9975L118.783 40.3932L122.509 37.059L114.101 27.6632L110.375 30.9975ZM11.6856 40.0277C33.2313 4.68633 82.7729 0.15314 110.375 30.9975L114.101 27.6632C84.2627 -5.67993 30.7075 -0.779486 7.41642 37.4251L11.6856 40.0277Z" fill="#191C22"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_388_43141">
      <rect width="254" height="196" fill="white"/>
      </clipPath>
      </defs>
    </svg>

  )
}
RackPowerOnTutorial.defaultProps = {
  width: 254
}