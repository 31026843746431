import './TasksPage.scss';
import React from 'react';

import StandardTable from '../../components/StandardTable.js'

import Badge from '../../components/Badge.js'
import { FormatDate } from '../../helpers'
 

import { Routes, Route, Navigate, useNavigate, useLocation, Outlet  } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { selectAllTasks, getAllTasks, taskUpdate} from '../../redux/entities/Tasks'
import StandardList from '../../components/StandardList/StandardList';


const Tasks_ListPage = ({pageHeaderHelper, setPageHeader}) => {
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [setPageHeader])


  const location = useLocation()
  let navigate = useNavigate(); 
  const dispatch = useDispatch()
  let tasks = useSelector(selectAllTasks)
  let haveInitialData = useSelector((state) => state.tasks.haveInitialData)

  React.useEffect( () =>  { 
    //fetchTasks
    if (!haveInitialData) {
      dispatch(getAllTasks())
    }
  }, [tasks, haveInitialData])




  const columns = {
    name: {render: (<div className="StandardList-Header-Column">Name</div>), width: 25, resizable: true, sort_by: false},
    category: {render: (<div className="StandardList-Header-Column">Category</div>), width: 12, resizable: true, sort_by: false},
    status: {render: (<div className="StandardList-Header-Column">Status</div>), width: 10, resizable: true, sort_by: false},
    assignedTo: {render: (<div className="StandardList-Header-Column">Assignees</div>), width: 10, resizable: true, sort_by: false},
    scheduledFor: {render: (<div className="StandardList-Header-Column">Date</div>), width: 10, resizable: true, sort_by: false},
    /*deleteFunction: {render: (<div className="StandardList-Header-Column"> </div>), widthType: "px", width: 20, resizable: false},*/
  }


  
  const [tasksList, SetTasksList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")

  React.useEffect(() => {
    let currentTasksList = {}

    let currentTasks = []
    

    if (currentSearchInput === "")  {
      currentTasks = [...tasks]
    }else {
      currentTasks.push(...tasks.filter((task) => {
        if (task.name.indexOf(currentSearchInput) !== -1 && currentTasks.indexOf(task) === -1)  {
          return true
        }
        return false
      }))
    }
    
    
    for (let task of currentTasks) {
      //Check if the recipe is in working mode
      currentTasksList[task.id] = {
        data: task,
        columns: {
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{task.name}</div>
            </div>),
          },
          category: {
            render: (<div className="StandardList-Row-Column">
              <Badge status="Neutral" content={task.category} size="Medium"/>
            </div>),
          },
          status: {
            render: (<div className="StandardList-Row-Column">
              <Badge status="Neutral" content={task.status} size="Medium"/>
            </div>),
          },
          assignedTo: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">Nobody</div>
            </div>),
          },
          scheduledFor: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{FormatDate(new Date(task.scheduled_for), "MM/dd/yyyy")}</div>
            </div>),
          },
        }
      }
    }
    SetTasksList(currentTasksList)
  }, [tasks, currentSearchInput])



  const taskClicked = (task) => {
    navigate(location.pathname + "/" + task.data.id);
  }

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }

  
  return (
    <>
    
      <div id="Tasks_ListPage">
        <StandardList 
          id="TasksTable" 
          columns={columns} 
          showHeader={true}
          rows={tasksList}
          isSearchable={false}
          isMultiSelectable={true}
          resourcesAttached={false}
          onRowClicked={taskClicked}
          loadingData={haveInitialData}/>

          <Outlet/>
      </div>
    </>
  )
} 

export default Tasks_ListPage