import './BadgeTimeline.scss';


import Badge from './Badge.js'

const BadgeTimeline = ({steps, currentStep, onStepChanged}) => {


  const BadgeTimelineItem = ({stepKey, step}) => {
    
      
    const stepClicked = (e) =>  {
      
    }

    return (
      <>
        <div className="BadgeTimeline-Badge">
          <Badge content={step.render} status={currentStep === stepKey ? "Active" : "Neutral"} onClick={stepClicked}/>
        </div>
        {stepKey !== Object.keys(steps).pop() &&
          <div className="BadgeTimeline-Seperator"></div>
        }
      </>
    )
  }

  return (
    <div className="BadgeTimeline">
      {Object.entries(steps).map(([key, step]) => {
        return (
          <BadgeTimelineItem key={key} stepKey={key} step={step}/>
        )
      })}
    </div>
  )
} 

BadgeTimeline.defaultProps = {
  steps: {},
  currentStep: undefined
}


export default BadgeTimeline