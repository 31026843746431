
import './SelectNutrientSubParts.scss';
import React from 'react'

import {StandardList, StandardListRow} from '../components/StandardList/StandardList.js'
import { getInventoryItemsByItemTypeGroupId, getInventoryItemTypeById, getInventoryItemTypeGroupByPath, selectAllInventoryItems, selectAllInventoryItemTypeGroups, selectAllInventoryItemTypes, selectInventoryItemTypeGroupByPath } from '../redux/entities/Inventory';
import { useDispatch, useSelector } from 'react-redux';


const SelectNutrientSubParts = ({selectedNutrientSubPart, onNutrientSubPartSelection}) => {


  const [nutrientTypes, SetNutrientTypes] = React.useState([
    {id: 1, loadedGroup: false, name: "Nutrient Products", inventoryItems: []},
    {id: 2, loadedGroup: false, name: "Solution", inventoryItems: []}
  ])
  
  let inventoryItems = useSelector(selectAllInventoryItems)
  let inventoryItemTypes = useSelector(selectAllInventoryItemTypes)
  let inventoryItemTypeGroups = useSelector(selectAllInventoryItemTypeGroups)
  const [inventorySubparts, SetInventorySubparts] = React.useState([])

  React.useEffect(() => {
    for (let nutrientType of nutrientTypes) {
      if (nutrientType.loadedGroup) {
        for (let inventoryItem of inventoryItems) {
          if (inventoryItem.item_type_group_id === nutrientType.id) {
            let foundInventoryItem = false
            for (let existingInventoryItem of nutrientType.inventoryItems)  {
              if (existingInventoryItem.id === inventoryItem.id)  {
                foundInventoryItem = true
                break
              }
            }
            if (!foundInventoryItem)  {
              nutrientType.inventoryItems.push(inventoryItem)
            }
          }
        }
      }
    }
    SetNutrientTypes([...nutrientTypes])
  }, [inventoryItems])

  //let nutrientSolutionsGroup = useSelector()

  React.useEffect(() => {
    //Validate that all the item types are loaded
    let itemTypesToLoad = []
    for (let nutrientType of nutrientTypes) {
      if (nutrientType.loadedGroup) {
        for (let inventoryItem of inventoryItems) {
          if (inventoryItemTypes.find((iIT) => iIT.id === inventoryItem.item_type_id) === undefined)  {
            itemTypesToLoad.push(inventoryItem.item_type_id)
          }
        }
      }
    }

    if (itemTypesToLoad.length > 0)  {
      dispatch(getInventoryItemTypeById({itemTypes: itemTypesToLoad}))
    }
    //inventoryItemTypes
    
  }, [nutrientTypes, inventoryItemTypes])

  const dispatch = useDispatch()
  React.useEffect(() => {
    let nutrientSolutionsGroup = selectInventoryItemTypeGroupByPath(inventoryItemTypeGroups, ["Consumables", "Nutrient Products"])
    if (nutrientSolutionsGroup === null)  {
      dispatch(getInventoryItemTypeGroupByPath({itemTypeGroupPath: ["Consumables", "Nutrient Products"]}))
    }

    let solutionsGroup = selectInventoryItemTypeGroupByPath(inventoryItemTypeGroups, ["Consumables", "Solutions"])
    if (solutionsGroup === null)  {
      dispatch(getInventoryItemTypeGroupByPath({itemTypeGroupPath: ["Consumables", "Solutions"]}))
    }

    if (nutrientSolutionsGroup !== null && solutionsGroup !== null) {
      nutrientTypes[0].id = nutrientSolutionsGroup.id
      nutrientTypes[0].loadedGroup = true
      nutrientTypes[1].id = solutionsGroup.id
      nutrientTypes[1].loadedGroup = true
      //We have the two groups filtered, lets get all inventory items for these 2 group ids
      dispatch(getInventoryItemsByItemTypeGroupId({itemTypeGroupIds: [nutrientSolutionsGroup.id, solutionsGroup.id]}))
    }
  }, [inventoryItemTypeGroups])


  /*const [nutrientSubParts, SetNutrientSubParts] = React.useState([
    {id: 1, name: "Grow A", group_id: 1, description: "Makes the best lettuce"},
    {id: 2, name: "Grow B", group_id: 1, description: "Makes the bester lettuce"},
    {id: 3, name: "Grow C", group_id: 1, description: "Makes the bestest lettuce"},
    {id: 4, name: "pH+", group_id: 2, description: "Ups the PH"},
    {id: 5, name: "pH-", group_id: 2, description: "Downs the PH"},
  ])*/

  const [nutrientSubPartsList, SetNutrientSubPartsList] = React.useState({})
  const [nutrientTypesList, SetNutrientTypesList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedNutrientSubPart, SetSelectedNutrientSubPart] = React.useState(undefined)
  React.useEffect(() => {
    if (currentSelectedNutrientSubPart !== selectedNutrientSubPart) {
      SetSelectedNutrientSubPart(selectedNutrientSubPart)
    }
  }, [selectedNutrientSubPart])
  
  const [currentGroupByTypeFilter, SetGroupByTypeFilter] = React.useState('*')
  


  React.useEffect(() => {
    let currentNutrientSubPartsList = {}
    let currentNutrientTypesList = {}

    let currentNutrientSubParts = []
    let currentNutrientTypes = []
    
    let availableNutrientTypes = []

    if (currentGroupByTypeFilter === "*") {
      availableNutrientTypes = [...nutrientTypes]
    }else {
      availableNutrientTypes = [nutrientTypes.find((nutrientType) => nutrientType.id === currentGroupByTypeFilter)]
    }

    currentNutrientTypes = [...availableNutrientTypes]

    for (let nutrientType of currentNutrientTypes)  {
      currentNutrientSubParts.push(...nutrientType.inventoryItems.filter((nutrientSubPart) => {
        let foundInventoryItemType = inventoryItemTypes.find((iIT) => iIT.id === nutrientSubPart.item_type_id)
        if (foundInventoryItemType !== undefined) {
          if ((currentSearchInput === "" || foundInventoryItemType.name.indexOf(currentSearchInput) !== -1) && currentNutrientSubParts.indexOf(nutrientSubPart) === -1)  {
            return true
          }
        }
        return false

      }))
    }

    
    for (let nutrientSubPart of currentNutrientSubParts) {
      if (currentNutrientTypesList[nutrientSubPart.item_type_group_id] === undefined) {
        let foundNutrientType = currentNutrientTypes.find((nutrientType) => nutrientType.id === nutrientSubPart.item_type_group_id)
        if (foundNutrientType !== undefined)  {
          currentNutrientTypesList[nutrientSubPart.item_type_group_id] = {
            data: foundNutrientType,
            render: (<div className="StandardList-Group-Header">{foundNutrientType.name}</div>)
          }
        }
      }
      let foundInventoryItemType = inventoryItemTypes.find((iIT) => iIT.id === nutrientSubPart.item_type_id)

      currentNutrientSubPartsList[nutrientSubPart.id] = {
        data: nutrientSubPart,
        columns: {
          icon: {
            render: (<div className="StandardList-Row-Column">
              <div style={{height:20}}></div>
            </div>)
          },
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{foundInventoryItemType ? foundInventoryItemType.name : "N/A"}</div>
              <div className="StandardList-Row-Column-Secondary">{""}</div>
            </div>),
          },
        },
        groupKey: nutrientSubPart.item_type_group_id
      }
      if (currentSelectedNutrientSubPart !== undefined && currentSelectedNutrientSubPart.id === nutrientSubPart.id) {
        currentNutrientSubPartsList[nutrientSubPart.id].selected = true
      }
    }
    SetNutrientSubPartsList(currentNutrientSubPartsList)
    SetNutrientTypesList(currentNutrientTypesList)
  }, [nutrientTypes, inventoryItemTypes, currentSearchInput, currentGroupByTypeFilter, currentSelectedNutrientSubPart])




  
  const columns = {
    icon: {widthType: "px", width: 20, resizable: false, sort_by: false},
    name: {render: (<div className="StandardList-Header-Column">Name</div>), width: "grow", resizable: true, sort_by: false},
  }

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }

  const onGroupFilter = (value) =>  {
    SetGroupByTypeFilter(value)
  }
  

  const rowSelected = (nutrientSubPart) => {
    
    SetSelectedNutrientSubPart(nutrientSubPart.data)
    if (onNutrientSubPartSelection !== undefined) {
      onNutrientSubPartSelection(nutrientSubPart.data)
    }
  }

  return (
    <>
      <div id="SelectNutrientSubParts">
        <StandardList 
          columns={columns} 
          showHeader={false}
          columnSpacing={10}
          groups={nutrientTypesList} 
          rows={nutrientSubPartsList}
          isSearchable={true} 
          onSearchInput={onSearchInput}
          isGroupFiltered={true}
          groupFilterOptions={[{value: "*", label: "All Types"}, ...nutrientTypes.map((nutrientType) => {
            return {value: nutrientType.id, label: nutrientType.name}
          })]}
          onGroupFilterChange={onGroupFilter}
          filteredByGroup={currentGroupByTypeFilter}
          onRowClicked={rowSelected}/>
      </div>
    </>
  )
} 

export default SelectNutrientSubParts