import './GanttChart.scss';

import React from 'react'
import TimeAxis from './TimeAxis.js'
import { useMeasure, FormatDate } from '../helpers'
import WebFont from 'webfontloader';
import ResizeObserver from 'resize-observer-polyfill'

import variables from '../globals.scss';
  

export const GanttChart = ({
        id, axisHeight, tableHeight, 
        viewPeriod, fromTime, 
        data, dataBounds, listScrollPosition, dataStartDateKey, dataEndDateKey,
        updateRow, barMargin,
        onScroll
    }) => {

    const chartRef = React.useRef(null)
    const mainRef = React.useRef(null)
    let tilesRef = React.useRef(null)
    const [axisTiles, SetAxisTiles] = React.useState([])
    const [currentTableHeight, SetTableHeight] = React.useState(tableHeight);
    const [bounds, set] = React.useState({ left: 0, top: 0, width: 0, height: 0 })
    const [fontLoaded, setFontLoaded] = React.useState(false)
    const [ro] = React.useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)))
    const [currentViewPeriod, SetViewPeriod] = React.useState(viewPeriod);
    const [minViewPeriod, setMinViewPeriod] = React.useState(1000 * 60 * 60 * 24)
    const [maxViewPeriod, setMaxViewPeriod] = React.useState(1000 * 60 * 60 * 24 * (365 / 2))
    const [currentFromTime, SetFromTime] = React.useState(fromTime);
    const [isMouseDownOnCanvas, SetIsMouseDownOnCanvas] = React.useState(false);
    const [mousePointerId, SetMousePointerId] = React.useState(0);
    const [isMouseDownOnItem, SetIsMouseDownOnItem] = React.useState(false);
    const [mouseDownOnItemKey, SetMouseDownOnItemKey] = React.useState(null);
    const [mouseOverOnItemKey, SetMouseOverOnItemKey] = React.useState(null);
    const [numberOfPointersDown, SetNumberOfPointersDown] = React.useState(0);
    const [numberOfTouchesDown, SetNumberOfTouchesDown] = React.useState(0);
    const [isItemSelected, SetIsItemSelected] = React.useState(false);
    const [selectedItemKey, SetSelectedItemKey] = React.useState(null);
    const [selectedItemPart, SetSelectedItemPart] = React.useState(null);
    const [currentCursor, SetCursor] = React.useState("default");
    
    const [isTwoFingerTouch, SetIsTwoFingerTouch] = React.useState(false);
    const [lastMousePosition, SetLastMousePosition] = React.useState({x: 0, y: 0});
    const [lastTwoFingerZoomDistance, SetLastTwoFingerZoomDistance] = React.useState(0);
    const [lastTwoFingerZoomPosition, SetLastTwoFingerZoomPosition] = React.useState({x: 0, y: 0});

    
    const [currentTempStartDate, SetCurrentTempStartDate] = React.useState(null);
    const [currentTempEndDate, SetCurrentTempEndDate] = React.useState(null);
    const [currentPendingStartDate, SetCurrentPendingStartDate] = React.useState(null);
    const [currentPendingEndDate, SetCurrentPendingEndDate] = React.useState(null);
    
    
    const dragGrabberWidth = 10
    
    const { devicePixelRatio:ratio=1 } = window
    const zoomRate = 0.005
    React.useEffect(() => {
        WebFont.load({
            google: {
            families: ['Roboto']
            }
        })
        setFontLoaded(true)

        
    }, [])

    
    const hitTestDataItem = (eX, eY) =>   {
        let closestItemKey = null;
        let hitPart = "";

        const chart = chartRef.current
        let width = chart.width/ratio
        const chartBounds = chartRef.current.getBoundingClientRect()
        let x = eX - chartBounds.left, y = eY - chartBounds.top;
        

        for (let [dataItemKey, dataItem] of Object.entries(data))  {
            if (dataBounds[dataItemKey] !== undefined)  {     
                const bounds = dataBounds[dataItemKey]
                let x1 = Math.floor(((dataItem[dataStartDateKey] - currentFromTime.getTime()) / currentViewPeriod) * width)
                let x2 = Math.floor((((dataItem[dataStartDateKey] + (dataItem.duration * 1000)) - currentFromTime.getTime()) / currentViewPeriod) * width)
                let y1 = (bounds.offsetY ?? (bounds.y ?? 0)) - axisHeight
                let y2 = (bounds.offsetY ?? (bounds.y ?? 0)) - axisHeight + bounds.height
                
                const initiateCompleteDragBounds = {x1: x1, x2: x2, y1: y1, y2: y2}
                
                const initiateStartDragBounds = {x1: x1 - dragGrabberWidth, x2: x1, y1: y1, y2: y2}
                const initiateEndDragBounds = {x1: x2, x2: x2 + dragGrabberWidth, y1: y1, y2: y2}

                if (initiateCompleteDragBounds !== undefined)   {
                    if (x > initiateCompleteDragBounds.x1 && x < initiateCompleteDragBounds.x2 && y > initiateCompleteDragBounds.y1 && y < initiateCompleteDragBounds.y2)   {
                        //TODO figure out if this is closer than any other hit item, if needed
                        closestItemKey = dataItemKey;
                        hitPart = "middle"
                        break
                    }else if (selectedItemKey != null && dataItemKey == selectedItemKey && x > initiateStartDragBounds.x1 && x < initiateStartDragBounds.x2 && y > initiateStartDragBounds.y1 && y < initiateStartDragBounds.y2)   {
                        //TODO figure out if this is closer than any other hit item, if needed
                        closestItemKey = dataItemKey;
                        hitPart = "start"
                        break
                    }else if (selectedItemKey != null && dataItemKey == selectedItemKey && x > initiateEndDragBounds.x1 && x < initiateEndDragBounds.x2 && y > initiateEndDragBounds.y1 && y < initiateEndDragBounds.y2)   {
                        //TODO figure out if this is closer than any other hit item, if needed
                        closestItemKey = dataItemKey;
                        hitPart = "end"
                        break
                    }
                }
            }
        }


        return [closestItemKey, hitPart];
    }   
    
    


    const handlePointerUp = (e) => {
        let pointerCount = numberOfPointersDown - 1
        pointerCount = pointerCount < 0 ? 0 : pointerCount
        SetNumberOfPointersDown(pointerCount)
        if (isMouseDownOnItem && (!isItemSelected || mouseDownOnItemKey != selectedItemKey))    {
            SetIsMouseDownOnItem(false);
            SetIsItemSelected(true);
            SetSelectedItemKey(mouseDownOnItemKey)
            SetMouseDownOnItemKey(null);
        }else if (isMouseDownOnItem && isItemSelected) {
            //moveItemToPosition({x: e.clientX - lastMousePosition.x, y: e.clientY - lastMousePosition.y});
            completeItemMove()
            SetIsMouseDownOnItem(false);
            SetMouseDownOnItemKey(null);
        }else if (isMouseDownOnCanvas)  {
            SetIsMouseDownOnCanvas(false);
        }
        if (mainRef.current !== undefined && mainRef.current.releasePointerCapture)    {
            mainRef.current.releasePointerCapture(mousePointerId);
        }

        handleCursor({x: e.clientX, y: e.clientY})
    };


    const handlePointerDown = (e) =>  {
        let pointerCount = numberOfPointersDown + 1
        SetNumberOfPointersDown(pointerCount)
        if (pointerCount == 1)  {
            e.preventDefault()
            let [downOnItemKey, hitPart] = hitTestDataItem(e.clientX, e.clientY)
            if (downOnItemKey !== null)   {
                SetIsMouseDownOnItem(true);
                if (isItemSelected && selectedItemKey != downOnItemKey)   {
                    SetIsItemSelected(false)
                    SetSelectedItemKey(null)
                }
                SetSelectedItemPart(hitPart)
                SetMouseDownOnItemKey(downOnItemKey);
            }else {
                SetIsMouseDownOnCanvas(true);
                SetIsItemSelected(false);
                SetSelectedItemKey(null);
            }
            SetLastMousePosition({x: e.clientX, y: e.clientY});
            if (mainRef.current !== undefined && mainRef.current.setPointerCapture)    {
                SetMousePointerId(e.pointerId)
                mainRef.current.setPointerCapture(e.pointerId);
            }
        }
       

        handleCursor({x: e.clientX, y: e.clientY})
    }





    const handlePointerMove = (e) =>  {
        const chart = chartRef.current
        const width = chart.width/ratio
        
        handleCursor({x: e.clientX, y: e.clientY})
        if (numberOfPointersDown === 1)   {
            if ((isMouseDownOnCanvas || (isMouseDownOnItem && !isItemSelected)) && chartRef.current !== undefined)  {
                
                const chart = chartRef.current
                let offset = {x: e.clientX - lastMousePosition.x, y: e.clientY - lastMousePosition.y};
                let width = chart.width/ratio

                let addingTime = (currentViewPeriod * (offset.x / width))

                SetFromTime(new Date(currentFromTime.getTime() - addingTime));
                SetLastMousePosition({x: e.clientX, y: e.clientY});
                if (onScroll !== undefined && offset.y !== 0) {
                    onScroll(-offset.y)
                }

                if (isMouseDownOnItem)  {
                    SetIsMouseDownOnItem(false)
                    SetMouseDownOnItemKey(false)
                    SetSelectedItemKey(null)
                    SetIsMouseDownOnCanvas(true)
                }
            }else if (isMouseDownOnItem && isItemSelected && chartRef.current !== undefined) {

                moveItemToPendingPosition({x: e.clientX , y: e.clientY});

            }
        }
    }


    const handleTouchStart = (e) =>  {
        if (e.targetTouches.length == 1)    {
            if ((isMouseDownOnCanvas && !isMouseDownOnItem && !isItemSelected) && chartRef.current !== undefined)  {
                
            }
        }else if (e.targetTouches.length == 2)  {
            e.preventDefault()
            const chart = chartRef.current
            SetLastTwoFingerZoomDistance(Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY));
            const midX = (e.touches[0].pageX + (e.touches[1].pageX - e.touches[0].pageX) / 2) - chart.getBoundingClientRect().left;
            const midY = (e.touches[0].pageY + (e.touches[1].pageY - e.touches[0].pageY) / 2) - chart.getBoundingClientRect().top;
            SetLastTwoFingerZoomPosition({x: midX, y: midY})
            SetIsTwoFingerTouch(true)

            SetIsMouseDownOnItem(false);
            SetIsItemSelected(false);
            SetSelectedItemKey(null);
        }
    }

    const handleTouchMove = (e) =>  {
        

        const chart = chartRef.current
        const width = chart.width/ratio
        if (isTwoFingerTouch && e.targetTouches.length == 2) {
            e.preventDefault()
            const currentTwoFingerZoomDistance = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);

            const delta = lastTwoFingerZoomDistance - currentTwoFingerZoomDistance
            const zoomAmount = (1 + (zoomRate * 1) * delta)
            
            
            if(delta > 0) {
                processZoom(zoomAmount, lastTwoFingerZoomPosition.x / width)
            }
            if(delta < 0) {
                processZoom(zoomAmount, lastTwoFingerZoomPosition.x / width)
            }

            SetLastTwoFingerZoomDistance(currentTwoFingerZoomDistance)
        }
    }

    const handleTouchEnd = (e) =>    {

    }


    const handleMouseWheel = (e) => {
        e.preventDefault()
        
        const chart = chartRef.current
        const width = chart.width/ratio
        const zoomAmount = (1 + zoomRate * e.deltaY)
        processZoom(zoomAmount, e.offsetX / width)
    }

    const processZoom = (zoomAmount, atPosition) =>   {
        let newViewPeriod = Math.floor(currentViewPeriod * zoomAmount)
        if (newViewPeriod < minViewPeriod)
            newViewPeriod = minViewPeriod
        if (newViewPeriod > maxViewPeriod)
            newViewPeriod = maxViewPeriod


        const mouseAtTime = currentFromTime.getTime() + (atPosition * currentViewPeriod)
        SetFromTime(new Date(mouseAtTime - (atPosition * newViewPeriod)));
        SetViewPeriod(newViewPeriod);
    }

    const moveItemToPendingPosition = (mousePosition) => {
        const chart = chartRef.current
        let width = chart.width/ratio

        if (data[selectedItemKey] === undefined)    {
            return
        }
        let selectedItem = data[selectedItemKey]

        let addingTime = (currentViewPeriod * ((mousePosition.x - lastMousePosition.x) / width))


        let tempStartDate = currentTempStartDate;
        let tempEndDate = currentTempEndDate;
        let potentialStartDate = null;
        let potentialEndDate = null;

        if (currentTempStartDate === null || currentTempEndDate === null)   {
            tempStartDate = new Date(selectedItem[dataStartDateKey])
            tempEndDate = new Date(selectedItem[dataStartDateKey] + (selectedItem.duration * 1000))
        }

        
        if (selectedItemPart == "middle")  {
            tempStartDate = new Date(tempStartDate.getTime() + addingTime)
            tempEndDate = new Date(tempEndDate.getTime() + addingTime)

            let closestStartDate = new Date(0);
            for (let tile of tilesRef)  {
                if (tile.subTiles != null)  {
                    for (let subTile of tile.subTiles)  {
                        const existingStartDelta = Math.abs(tempStartDate.getTime() - closestStartDate.getTime())
                        const startDelta = Math.abs(tempStartDate.getTime() - subTile.startsAt.getTime())
                        if (startDelta < existingStartDelta)    {
                            closestStartDate = subTile.startsAt;
                        }
                    }
                }
            }

            potentialStartDate = closestStartDate
            potentialEndDate = new Date(potentialStartDate.getTime() + selectedItem.duration * 1000)


        }else if (selectedItemPart == "start")  {
            tempStartDate = new Date(tempStartDate.getTime() + addingTime)

            let closestStartDate = new Date(0);
            for (let tile of tilesRef)  {
                if (tile.subTiles != null)  {
                    for (let subTile of tile.subTiles)  {
                        const existingStartDelta = Math.abs(tempStartDate.getTime() - closestStartDate.getTime())
                        const startDelta = Math.abs(tempStartDate.getTime() - subTile.startsAt.getTime())
                        if (startDelta < existingStartDelta)    {
                            closestStartDate = subTile.startsAt;
                        }
                    }
                }
            }

            potentialStartDate = closestStartDate
            SetCurrentPendingEndDate(new Date(selectedItem[dataStartDateKey] + selectedItem.duration * 1000))



        }else if (selectedItemPart == "end") {
            tempEndDate = new Date(tempEndDate.getTime() + addingTime)

            let closestEndDate = new Date(0);
            for (let tile of tilesRef)  {
                if (tile.subTiles != null)  {
                    for (let subTile of tile.subTiles)  {
                        const existingEndDelta = Math.abs(tempEndDate.getTime() - closestEndDate.getTime())
                        const endDelta = Math.abs(subTile.endsAt.getTime() - tempEndDate.getTime())
                        if (endDelta < existingEndDelta)    {
                            closestEndDate = subTile.endsAt;
                        }
                    }
                }
            }

            SetCurrentPendingStartDate(new Date(selectedItem[dataStartDateKey]))
            potentialEndDate = new Date(closestEndDate.getTime())

        }

        SetCurrentTempStartDate(tempStartDate)
        SetCurrentTempEndDate(tempEndDate)

        if (potentialStartDate != null) {
            SetCurrentPendingStartDate(potentialStartDate)
        }
        if (potentialEndDate != null) {
            SetCurrentPendingEndDate(potentialEndDate)
        }
        SetLastMousePosition({x: mousePosition.x, y: mousePosition.y});
    }


    
    const completeItemMove = () => {
        if (currentPendingStartDate !== null && currentPendingEndDate !== null)  {
            if (selectedItemPart == "middle")  {
                if (updateRow !== undefined)    {
                    updateRow(selectedItemKey, dataStartDateKey, currentPendingStartDate.getTime())
                }
            }else if (selectedItemPart == "start")  {
                if (updateRow !== undefined)    {
                    updateRow(selectedItemKey, dataStartDateKey, currentPendingStartDate.getTime())
                    updateRow(selectedItemKey, "duration", (currentPendingEndDate.getTime() - currentPendingStartDate.getTime()) / 1000)
                }
                
                //selectedItem.duration = (selectedItem.pendingEndDate.getTime() - selectedItem.pendingStartDate.getTime()) / 1000
                //selectedItem.startDate = selectedItem.pendingStartDate.getTime()
            }else if (selectedItemPart == "end")  {
                if (updateRow !== undefined)    {
                    updateRow(selectedItemKey, "duration", (currentPendingEndDate.getTime() - currentPendingStartDate.getTime()) / 1000)
                }

            }
        }
        
        SetCurrentTempStartDate(null)
        SetCurrentTempEndDate(null)
        SetCurrentPendingStartDate(null)
        SetCurrentPendingEndDate(null)
    }


    const handleCursor = (mousePosition) =>    {
        const [mouseOverItemKey, hitPart] = hitTestDataItem(mousePosition.x, mousePosition.y)
        if (isMouseDownOnCanvas)    {
            
        }else if (isItemSelected)    {
            if (isMouseDownOnItem)  {
                SetCursor("grabbing")
            }else {
                if (mouseOverItemKey != null) {
                    if (mouseOverItemKey == selectedItemKey)  {
                        SetCursor("grab")
                    }else {
                        SetCursor("pointer")
                    }
                }else {
                    SetCursor("default")
                }
            }
        }else if (mouseOverItemKey != null) {
            if (mouseOverOnItemKey !== null && mouseOverOnItemKey != mouseOverItemKey)    {
                //mouseOverOnItem.mouseIsOver = false
            }
            SetMouseOverOnItemKey(mouseOverItemKey)
            //mouseOverItem.mouseIsOver = true
            SetCursor("pointer")
        }else {
            SetCursor("default")
        }
    }

    const resizeChart = chart => {
        const { width, height } = chart.getBoundingClientRect()
        //alert(width.toString() + "-" + height)
        
        
        if (chart.width !== width*ratio || chart.height !== height*ratio) {
          const context = chart.getContext('2d')
          chart.width = width*ratio
          chart.height = height*ratio
          context.scale(ratio, ratio)
          return true
        }
    
        return false
      }

    const generateRoundedRect = (x, y, width, height, radius) => {
        return new Path2D(`M ${x + radius} ${y} H ${x + width - radius} a ${radius} ${radius} 0 0 1 ${radius} ${radius} V ${y + height - radius} a ${radius} ${radius} 0 0 1 ${-radius} ${radius} H ${x + radius} a ${radius} ${radius} 0 0 1 ${-radius} ${-radius} V ${y + radius} a ${radius} ${radius} 0 0 1 ${radius} ${-radius}`);
    }

    const drawRoundedRect = (ctx, x, y, width, height, radius, onlyBorder) => { //top left, top right, bottom left, bottom right
        ctx.beginPath();
        ctx.moveTo(x + radius[0], y);
        ctx.lineTo(x + width - radius[1], y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius[1]);
        ctx.lineTo(x + width, y + height - radius[3]);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius[3], y + height);
        ctx.lineTo(x + radius[2], y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius[2]);
        ctx.lineTo(x, y + radius[0]);
        ctx.quadraticCurveTo(x, y, x + radius[0], y);
        ctx.closePath();
        if (onlyBorder) {
            ctx.stroke()
        }else {
            ctx.fill()
        }
    }


    const drawDataItem = (canvas, ctx, dataItemKey, dataItem, isSubItem) =>    {
        const dayDuration = 1000 * 60 * 60 * 24
        const width = canvas.width/ratio
        const height = canvas.height/ratio


        if (dataBounds[dataItemKey] !== undefined)  {     
            const bounds = dataBounds[dataItemKey]        
            let x1 = Math.floor(((dataItem[dataStartDateKey] - currentFromTime.getTime()) / currentViewPeriod) * width)
            let x2 = Math.floor((((dataItem[dataStartDateKey] + (dataItem.duration * 1000)) - currentFromTime.getTime()) / currentViewPeriod) * width)
            let y1 = (bounds.offsetY ?? (bounds.y ?? 0)) - axisHeight
            let y2 = (bounds.offsetY ?? (bounds.y ?? 0)) - axisHeight + bounds.height

            //dataItem.initiateCompleteDragBounds = {x1: x1, x2: x2, y1: y1, y2: y2}
            
            //dataItem.initiateStartDragBounds = {x1: x1 - dragGrabberWidth, x2: x1, y1: y1, y2: y2}
            //dataItem.initiateEndDragBounds = {x1: x2, x2: x2 + dragGrabberWidth, y1: y1, y2: y2}

            let barHeight = y2 - y1
            let barWidth = x2 - x1
            let currentBarMargin = barHeight * (barMargin / 200)
            barHeight -= currentBarMargin * 2
            y1 += currentBarMargin
            y2 -= currentBarMargin


            if ((x1 > 0 && x1 < width) || (x2 > 0 && x2 < width) || (x1 < 0 && x2 > width))   {
                let isOverlappingBehind = false
                let isOverlappingAhead = false
                if (x1 < 0) {
                    x1 = 0
                    isOverlappingBehind = true
                }
                if (x2 > width) {
                    x2 = width
                    isOverlappingAhead = true
                }
                if (dataItem.statusColor !== undefined)  {
                    if (dataItem.isGroup)   {
                        ctx.strokeStyle = dataItem.statusColor
                        ctx.fillStyle = "#000"
                    }else {
                        ctx.strokeStyle = "#000"
                        ctx.fillStyle = dataItem.statusColor
                    }
                }

                if (isItemSelected && selectedItemKey == dataItemKey) {
                    ctx.fillRect(x1, y1, x2 - x1, barHeight)
                }else {
                    let leftRadius = (isOverlappingBehind ? 0 : 4)
                    let rightRadius = (isOverlappingAhead ? 0 : 4)
                    drawRoundedRect(ctx, x1, y1, x2 - x1, barHeight, [leftRadius, rightRadius, leftRadius, rightRadius], dataItem.isGroup)
                }



                //ctx.fill(generateRoundedRect(x1, y1, x2 - x1, barHeight, 4));

                if (dataItem.isGroup && dataItem.isExpanded)   {
                    const yG2 = y1 + bounds.outerHeight - currentBarMargin
                    ctx.strokeStyle = variables.neutral100
                    ctx.lineWidth = 1;
                    ctx.beginPath();
                    ctx.moveTo(x1, y1);
                    ctx.lineTo(x1, yG2);
                    ctx.lineTo(x2, yG2);
                    ctx.lineTo(x2, y1);
                    ctx.stroke();
                }
            }

            if (isItemSelected && selectedItemKey == dataItemKey && isMouseDownOnItem && currentPendingStartDate !== null && currentPendingEndDate !== null)   {
                //console.log(dataItem.pendingStartDate)
                let pX1 = Math.floor(((currentPendingStartDate.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
                let pX2 = Math.floor(((currentPendingEndDate.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)

                let barWidth = pX2 - pX1

                if ((pX1 > 0 && pX1 < width) || (pX2 > 0 && pX2 < width) || (pX1 < 0 && pX2 > width))   {
                    let isOverlappingBehind = false
                    let isOverlappingAhead = false
                    if (pX1 < 0) {
                        pX1 = 0
                        isOverlappingBehind = true
                    }
                    if (pX2 > width) {
                        pX2 = width
                        isOverlappingAhead = true
                    }

                    //Draw backdrop
                    ctx.fillStyle = "rgba(0,0,0,0.1)"
                    ctx.fillRect(pX1, 0, barWidth, height)
                    
                    let color
                    switch (dataItem.status_status)    {
                        case "Success":
                            color = variables.accentSuccess
                            break
                        case "Info":
                            color = variables.accentHighlight
                            break
                        case "Warning":
                            color = variables.accentWarning
                            break

                        default:
                            color = "#555555"
                    }
                    
                    if (dataItem.isGroup)   {
                        ctx.strokeStyle = color
                        ctx.fillStyle = "transparent"
                    }else {
                        ctx.fillStyle = color
                    }
                    let leftRadius = (isOverlappingBehind ? 0 : 4)
                    let rightRadius = (isOverlappingAhead ? 0 : 4)
                    drawRoundedRect(ctx, pX1, y1, pX2 - pX1, barHeight, [leftRadius, rightRadius, leftRadius, rightRadius])

                }

            }else {

                

                //Check if its selected
                if (isItemSelected && selectedItemKey == dataItemKey) {
                    ctx.fillStyle = variables.neutral800
                    drawRoundedRect(ctx, x1 - dragGrabberWidth, y1, dragGrabberWidth, barHeight, [4, 0, 4, 0])
                    drawRoundedRect(ctx, x2, y1, dragGrabberWidth, barHeight, [0, 4, 0, 4])

                    ctx.font = '11px Roboto';
                    ctx.fillStyle = '#191C22'
                    ctx.textBaseline = 'middle';
                    ctx.textAlign ="left";
                    if (dataItem.isGroup)   {
                        ctx.fillText(dataItem.name, x1 + 5, y1 + ((y2 - y1) / 2));
                    }else {
                        ctx.fillText(dataItem.name, x2 + dragGrabberWidth + 5, y1 + ((y2 - y1) / 2));
                    }
                }else {
                    ctx.font = '11px Roboto';
                    ctx.fillStyle = '#191C22'
                    ctx.textBaseline = 'middle';
                    ctx.textAlign ="left";
                    if (dataItem.isGroup)   {
                        ctx.fillText(dataItem.name, x1 + 5, y1 + ((y2 - y1) / 2));
                    }else {
                        ctx.fillText(dataItem.name, x2 + 5, y1 + ((y2 - y1) / 2));
                    }
                }
            }

            if (dataItem.isGroup)   {
                if (dataItem.isExpanded)    {
                    for (let [rowDataItemKey, rowDataItem] of Object.entries(dataItem.rowData))   {
                        drawDataItem(canvas, ctx, rowDataItemKey, rowDataItem, true)
                    }
                }
            }
        }
    }

    const drawData = (canvas, ctx) => {

        for (let [dataItemKey, dataItem] of Object.entries(data))  {
            drawDataItem(canvas, ctx, dataItemKey, dataItem, false)
        }
      }

      const draw = (canvas, ctx) => {
        let width = canvas.width/ratio
        let height = canvas.height/ratio

        //First draw the weekend backdrops
        const dayDuration = 1000 * 60 * 60 * 24
        let currentDate = new Date(currentFromTime.getTime())
        currentDate.setHours(0, 0, 0, 0)
        while (currentDate.getTime() < currentFromTime.getTime() + currentViewPeriod)   {
            let nextDate = new Date(currentDate)
            nextDate.setDate(currentDate.getDate() + 1)
            if (currentDate.getDay() == 0 || currentDate.getDay() == 6) {
                //It's a weekend, lets fill-er in
                let x1 = Math.floor(((currentDate.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
                
                let x2 = Math.floor((((nextDate.getTime()) - currentFromTime.getTime()) / currentViewPeriod) * width)

                ctx.fillStyle = "rgba(0,0,0,0.05)";
                ctx.fillRect(x1, 0, x2 - x1, height);
            }
           currentDate = nextDate
        }

        drawData(canvas, ctx)

        const now = new Date();
        const nowX = Math.floor(((now.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
        ctx.strokeStyle = '#2E72D2'
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(nowX, 0);
        ctx.lineTo(nowX, height);
        ctx.stroke();
        ctx.lineWidth = 1;
    }

    const handleUpdate = () =>  {
        if (chartRef.current) {
            ro.observe(chartRef.current)
            const chart = chartRef.current

            const ctx = chart.getContext('2d')
            ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
            ctx.clearRect(0, 0, chart.width, chart.height)
            resizeChart(chart)
            ctx.translate(0.5, 0.5);
            draw(chart, ctx)
        }
    }
    React.useEffect(() => {
        if (selectedItemKey)   {
            if (data[selectedItemKey] !== undefined)  {
                SetSelectedItemKey(data[selectedItemKey])
            }
        }

        handleUpdate()
        mainRef.current.addEventListener('wheel',  handleMouseWheel, { passive: false });

        return () => {
            ro.disconnect()
            if (mainRef.current !== undefined && mainRef.current != null) {
                mainRef.current.removeEventListener('wheel', handleMouseWheel, { passive: false });
            }
        }
    }, [data, dataBounds, axisHeight, currentViewPeriod, currentFromTime, fontLoaded])
    handleUpdate()

    React.useEffect(() => {
        if (currentTableHeight != tableHeight)    {
            SetTableHeight(tableHeight)
            
        }
        
    }, [tableHeight])

    
    return (
    <div className="GanttChart"
        style={{cursor: currentCursor}}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onPointerCancel={handlePointerUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        ref={mainRef}>
        <TimeAxis 
            id="GanttChartTimeAxis" 
            axisHeight={axisHeight} 
            hasExternalControl={true} 
            viewPeriod={currentViewPeriod} 
            fromTime={currentFromTime}
            tilesRef={ref => {tilesRef = ref}}/>
        <canvas
            className="GanttChartCanvas noselect"
            style={{height: currentTableHeight}}
            ref={chartRef}/>
    </div>
  )
}


GanttChart.defaultProps = {
    axisHeight: 50,
    tableHeight: 200,
    viewPeriod: 1000 * 60 * 60 * 24 * 5,
    fromTime: new Date(),
    data: [],
    dataStartDateKey: "startDate",
    barMargin: 50,
    listScrollPosition: 0
}

export default GanttChart 