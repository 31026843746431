//import './PageHeader.scss';
import React from 'react'

const HomePage = ({pageHeaderHelper}) => {

  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Home")
  }, [])

  return (
    <>
      Home
    </>
  )
} 

export default HomePage