import './TextInput.scss';
import React from 'react'
import {BsSearch} from 'react-icons/bs'


const TextInput = ({label, value, icon, stepper, placeHolder, prefix, suffix, multiline, clearButton, size, onChange, onBlur, disabled}) => {
  const [focusState, SetFocusState] = React.useState(false);
  const [pointerId, SetPointerId] = React.useState(null)
  const containerRef = React.useRef(null);

  const actualIcon = false;
  
  const inputRef = React.useRef(null);
  const [currentValue, setValue] = React.useState(value);
  const currentValueRef = React.useRef(null);

  React.useEffect(() => {
    setValue(value)
  }, [value])

  const textChanged = (e) =>  {
    if (onChange !== undefined) {
      let result = onChange(e.target.value)
      if (result !== undefined) {
        inputRef.current.value = result
        setValue(result)
      }else {
        setValue(e.target.value)
      }
    }else {
      setValue(e.target.value)
    }
  }

  React.useEffect(() => {
    currentValueRef.current = currentValue
  }, [currentValue])

  const handleFocus = React.useCallback((e) => {
    if (disabled) {
      SetFocusState(false)
      e.preventDefault()
      e.stopPropagation()
      return;
    }
    if (!focusState)  {
      SetFocusState(true)
      document.addEventListener('pointerdown', handleBlur);
    }
  })

  const handleBlur = React.useCallback((ev) => {
    const xCoord = ev.clientX;
    const yCoord = ev.clientY;
    if (containerRef.current) {
      const { right, x, bottom, y } = containerRef.current.getBoundingClientRect();
      if (xCoord < right && xCoord > x && yCoord < bottom && yCoord > y) {
        return;
      }
      blur()
      document.removeEventListener('pointerdown', handleBlur);
    }
  })

  const blur = React.useCallback(() => {
    SetFocusState(false)
    if (onBlur !== undefined) {
      onBlur(currentValueRef.current)
    }
  })

  const focusEnd = (e) => {
    
  }

  return (
    <div className={"TextInput" + (disabled ? " TextInput-Disabled" : (focusState ? " TextInput-Focused" : ""))} onPointerDown={handleFocus} ref={containerRef}>
      <div>
        {label && <div className="TextInput-Label">{label}</div>}
        <div className="TextInput-Content noselect">
          {(icon !== false || prefix !== false) &&
            <div className="TextInput-Prefix">
              {icon !== false &&
                icon
              }
            </div>
          }
          <div className="TextInput-DisplayArea noselect">
            {!multiline && 
              <input ref={inputRef} type="text" value={currentValue} placeholder={placeHolder !== false ? placeHolder: ""} onChange={textChanged}/>
            }
            {multiline && 
              <textarea ref={inputRef} value={currentValue} placeholder={placeHolder !== false ? placeHolder: ""} onChange={textChanged} rows={3}></textarea>
            }
          </div>
        </div>
      </div>
    </div>
  )
} 

TextInput.defaultProps = {
  value: "",
  icon: false,
  stepper: false,
  placeHolder: false,
  prefix: false,
  suffix: false,
  multiline: false,
  clearButton:false,
  size: 20,
  disabled: false
}

TextInput.SearchIcon = BsSearch

export default TextInput