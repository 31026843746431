import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'


const processRaftTypeFromAPI = (raftType) =>  {
  
  return raftType
}

export const getAllRaftTypes = createAsyncThunk('raftTypes/getAllRaftTypes', async () => {
  return await FetchPost(APIRepository.RaftTypes.GetAllRaftTypesForAccount, {
    AccountId: 29,
    HaveRaftTypes: {}
  })  
})


export const raftTypesSlice = createSlice({
  name: 'raftTypes',
  initialState: {
    raftTypes:  [

    ],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false
  },
  reducers: {
    

    raftTypeUpdate: (state, action) => {
      let hasChanged = false
      let newRaftTypes = { ...state, raftTypes: state.raftTypes.map((raftType, index) => {
        if (raftType.id !== action.payload.raftTypeId) {
          return raftType
        }
    
        if (raftType[action.payload.prop] === undefined || raftType[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...raftType,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newRaftTypes
      }
    }
  },
  extraReducers: {
    [getAllRaftTypes.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllRaftTypes.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      action.payload.raftTypes.map(function(raftType){ processRaftTypeFromAPI(raftType); return raftType });
      console.log(action.payload.raftTypes)
      state.raftTypes = action.payload.raftTypes;
    },

    [getAllRaftTypes.rejected]: (state) => {
      state.status = 'rejected';
    }
  }
})



// Action creators are generated for each case reducer function
export const { raftTypeUpdate} = raftTypesSlice.actions

export default raftTypesSlice.reducer

export const selectAllRaftTypes = state => state.raftTypes.raftTypes

export const selectRaftTypeById = (state, raftTypeId) =>
  state.raftTypes.raftTypes.find(raftType => raftType.id === raftTypeId)