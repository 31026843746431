import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../../api'


const processNDSFromAPI = (NDS) =>  {
  
  NDS.numberOfSensorBanks = null
  NDS.sensorBanks = null
  NDS.sensors = null


  return NDS
}

const processNDSPropertyFromAPI = (NDS, key, value) =>  {
  console.log(key, value)
  switch (key)  {
    case "number_of_sensor_banks":
      NDS.numberOfSensorBanks = parseInt(value)
    case "sensor_banks":
      NDS.sensorBanks = value
    case "sensors":
      NDS.sensors = value
    case "nutrient_reservoirs":
      NDS.nutrientReservoirs = value
    default:
      break
  }
}

export const getAllNDS = createAsyncThunk('NDS/getAllNDS', async ({facilityId}) => {
  return await FetchPost(APIRepository.NDS.GetAllNDSForFacility, {
    account_id: 29,
    facility_id: facilityId,
    have_grow_plans: {}
  })  
})

export const getNDSById = createAsyncThunk('NDS/getNDSById', async ({NDSId, NDSIds}) => {
  console.log(NDSId)
  let payload = {
    account_id: 29,
  }
  if (NDSId !== undefined) {
    payload.nds_id = NDSId
  }
  if (NDSIds !== undefined) {
    payload.nds_ids = NDSIds
  }
  return await FetchPost(APIRepository.NDS.GetNDSById, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    if (NDS.status === 'pending') {
      return false
    }
  },
})


export const getNDSByServiceId = createAsyncThunk('NDS/getNDSByServiceId', async ({NDSServiceId, NDSServiceIds}) => {
  let payload = {
    account_id: 29,
  }
  if (NDSServiceId !== undefined) {
    payload.service_id = NDSServiceId
  }
  if (NDSServiceIds !== undefined) {
    payload.service_ids = NDSServiceIds
  }
  return await FetchPost(APIRepository.NDS.GetNDSByServiceId, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    if (NDS.status === 'pending') {
      return false
    }
  },
})


export const getNDSProperty = createAsyncThunk('NDS/getNDSProperty', async ({NDSId, key, keys}) => {
  let payload = {
    account_id: 29,
    nds_id: NDSId
  }  
  if (key !== undefined) {
    payload.key = key
  }
  if (keys !== undefined) {
    payload.keys = keys
  }
  return await FetchPost(APIRepository.NDS.GetNDSProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    if (NDS.status === 'pending') {
      return false
    }
  },
})

export const setNDSProperty = createAsyncThunk('NDS/setNDSProperty', async ({NDSId, key, value, values}) => {
  let payload = {
    account_id: 29,
    nds_id: NDSId
  }  
  if (key !== undefined && value !== undefined) {
    payload.key = key
    payload.value = value
  }
  if (values !== undefined) {
    payload.values = values
  }
  return await FetchPost(APIRepository.NDS.SetNDSProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    if (NDS.status === 'pending') {
      return false
    }
  },
})

export const NDSsSlice = createSlice({
  name: 'NDS',
  initialState: {
    NDS:  [

    ],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false
  },
  reducers: {
    
  },
  extraReducers: {
    [getAllNDS.pending]: (state) => {
      state.status = 'pending';
    },
    [getAllNDS.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      action.payload.NDS.map(function(NDS){ processNDSFromAPI(NDS); return NDS });
      state.NDS = action.payload.ndss;
    },
    [getAllNDS.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getNDSById.pending]: (state) => {
      state.status = 'pending';
    },

    [getNDSById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      /*if (action.payload.nds !== null) {
        state.NDS.push(processNDSFromAPI(action.payload.nds))
      }*/
      if (action.payload.ndss !== null) {
        for (let NDS of action.payload.ndss)  {
          if (NDS)  {
            state.NDS.push(processNDSFromAPI(NDS))
          }
        }
      }
    },

    [getNDSById.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getNDSByServiceId.pending]: (state) => {
      state.status = 'pending';
    },

    [getNDSByServiceId.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload.vertical_rack_groups)
      if (action.payload.ndss !== undefined && action.payload.ndss !== null) {
        for (let NDS of action.payload.ndss)  {
          if (NDS)  {
            let exists = false
            for (let existingNDS of state.NDS) {
              if (existingNDS.id === NDS.id)  {
                existingNDS = processNDSFromAPI({...existingNDS, ...NDS})
                exists = true
                break
              }
            }
            if (!exists)  {
              state.NDS.push(processNDSFromAPI(NDS))              
            }
          }
        }
      }
    },

    [getNDSByServiceId.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getNDSProperty.pending]: (state) => {
      state.status = 'pending';
    },

    [getNDSProperty.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload)
      
      const NDS = state.NDS.find((n) => { return n.id === action.meta.arg.NDSId; })
      if (NDS !== undefined)  {
        //Do something with the resulting property
        if (action.payload.value !== null) {
          processNDSPropertyFromAPI(NDS, action.meta.arg.key, action.payload.value)
        }
        if (action.payload.values !== null) {
          for (let [key, value] of Object.entries(action.payload.values))  {
            processNDSPropertyFromAPI(NDS, key, value)
          }
        }
      }
    },

    [getNDSProperty.rejected]: (state) => {
      state.status = 'rejected';
    },

    [setNDSProperty.pending]: (state) => {
      state.status = 'pending';
    },

    [setNDSProperty.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //Do something with the resulting property
    },

    [setNDSProperty.rejected]: (state) => {
      state.status = 'rejected';
    },
    
  }
})



// Action creators are generated for each case reducer function
export const { } = NDSsSlice.actions

export default NDSsSlice.reducer

export const selectAllNDS = state => state.NDS.NDS
