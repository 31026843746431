
import './GrowPlansPage.scss';

import React from 'react'
import {StandardList, StandardListRow} from '../../../components/StandardList/StandardList.js'

import { Routes, Route, Navigate, useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { selectAllRecipes, getAllRecipes } from '../../../redux/entities/Recipes'
import { selectAllGrowPlans, getAllGrowPlans, createGrowPlan} from '../../../redux/entities/GrowPlans'
import { RaftTypes } from '../../../model_components/RaftType';
import { FormatDate } from '../../../helpers'

import Badge from '../../../components/Badge.js'
import Button from '../../../components/Button.js'

import {Raft} from '../../../model_components/RaftType';
import CreateGrowPlanPopup from './CreateGrowPlanPopup'

import {FaTrashAlt} from 'react-icons/fa'


const GrowPlansPage = ({pageHeaderHelper, pageHeaderHelperSetup}) => {
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset() 
    pageHeaderHelperSetup()
    pageHeaderHelper.current.SetTitle("My Repository")
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              <Button content={"Create Grow Plan"} onClick={() => {SetCreatingGrowPlan(true)}}/>
            </div>
          </div>
        </>
      )
    })
  }, [])

  
  const dispatch = useDispatch()
  React.useEffect( () =>  {
    dispatch(getAllGrowPlans())
    dispatch(getAllRecipes())
  }, [])
  let growPlans = useSelector(selectAllGrowPlans)
  let recipes = useSelector(selectAllRecipes)


  const [creatingGrowPlan, SetCreatingGrowPlan] = React.useState(false)
  const closeCreateGrowPlanPopup = () =>  {
    SetCreatingGrowPlan(false)
  }
  const growPlanFinalized = (finalizedGrowPlan) =>  {
    dispatch(createGrowPlan(finalizedGrowPlan))
    //SetGrowPlans([...growPlans, {...finalizedGrowPlan, id: 10, uid: "#GP28192"}])
    SetCreatingGrowPlan(false)
  }

  const [growPlansList, SetGropPlansList] = React.useState({})

  const [plantTypes, SetPlantTypes] = React.useState([
    {id: 1, name: "Blackberries", group_id: 1, description: "Disgusting horribly delicious fruit"},
    {id: 2, name: "Strawberries", group_id: 1, description: "Disgusting horribly delicious fruit"},
    {id: 3, name: "Butterhead", group_id: 2, description: "The only plant we can grow"}
  ])
  const [plantVarieties, SetPlantVarieties] = React.useState([
    {id: 1, plant_type_id: 1, name: "Crimson Queen", description: "Disgusting horribly delicious fruit"},
    {id: 2, plant_type_id: 2, name: "Bitter Blue", description: "Disgusting horribly delicious fruit"},
    {id: 3, plant_type_id: 2, name: "Blah blah yuck", description: "The only plant we can grow"},
    {id: 4, plant_type_id: 3, name: "Ya blah yuck", description: "Testing"}
  ])
  



  React.useEffect(() => {
    let currentGrowPlanList = {}
    
    for (let growPlan of growPlans) {

      let foundPlantType = plantTypes.find((plantType) => plantType.id === growPlan.plant_type_id)
      let foundPlantVariety = plantVarieties.find((plantVariety) => plantVariety.id === growPlan.plant_variety_id)
      let foundRecipe = recipes.find((recipe) => recipe.id === growPlan.recipe_id && recipe.version == growPlan.recipe_version)
      let foundRaftType = RaftTypes.find((raftType) => raftType.id === growPlan.raft_type_id)
      console.log(foundRaftType)
      const hourDuration = 60 * 60
      const dayDuration = hourDuration * 24
  
      let totalDurationDisplay = ""
      if (foundRecipe !== undefined)  {
        
        const totalDays = Math.floor(foundRecipe.expected_duration  / dayDuration)
        const remainingHours = (foundRecipe.expected_duration  - (totalDays * dayDuration)) / hourDuration
  
        if (totalDays !== 0) {
          totalDurationDisplay += totalDays.toString() + "d"
          if (remainingHours !== 0)  {
            totalDurationDisplay += " " + remainingHours.toString() + "h"
          }
        }else {
          totalDurationDisplay += remainingHours.toString() + "h"
        }
      }

      currentGrowPlanList[growPlan.id] = {
        data: growPlan,
        columns: {
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{growPlan.name}</div>
              <div className="StandardList-Row-Column-Secondary">#GP{growPlan.uid}</div>
            </div>),
          },
          plantType: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{foundPlantType ? foundPlantType.name : "n/a"}</div>
              <div className="StandardList-Row-Column-Secondary">{foundPlantVariety ? foundPlantVariety.name : ""}</div>
            </div>),
          },
          recipe: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{foundRecipe ? foundRecipe.name : "n/a"}</div>
              <div className="StandardList-Row-Column-Secondary">{foundRecipe ? "v" + foundRecipe.version + ", " + FormatDate(new Date(foundRecipe.modified_on), "MM/d/yyyy"): ""}</div>
            </div>),
          },
          durationAndYield: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{totalDurationDisplay}</div>
              <div className="StandardList-Row-Column-Secondary">{growPlan.estimated_yield}{growPlan.yield_unit_type === "weight" ? "kg" : (growPlan.yield_unit_type === "plant" ? " plants" : "")}</div>
            </div>),
          },
          raftType: {
            render: (<div className="StandardList-Row-Column"><Raft raftType={foundRaftType}/></div>),
          },
          deleteFunction: {
            render: (<div className="StandardList-Row-Column">
              <FaTrashAlt className="StandardList-Row-Column-Trash"/>
            </div>)
          },
        },
        groupKey: "all"
      }
    }
    SetGropPlansList(currentGrowPlanList)
  }, [growPlans, recipes])


  /*const [groups, SetGroups] = React.useState({
    all: { render: (<div className="StandardList-Group-Header">ALL</div>) }
  })*/

  
  const columns = {
    name: {render: (<div className="StandardList-Header-Column">Name</div>), minWidth: 10, width: 15, maxWidth: 40, resizable: true, sort_by: false},
    plantType: {render: (<div className="StandardList-Header-Column">Plant Type</div>), minWidth: 10, width: 15, maxWidth: 40, resizable: true, sort_by: false},
    recipe: {render: (<div className="StandardList-Header-Column">Recipe</div>), minWidth: 10, width: 20, maxWidth: 50, resizable: true, sort_by: false},
    durationAndYield: {render: (<div className="StandardList-Header-Column">D&Y</div>), minWidth: 6, width: 8, maxWidth: 20, resizable: true, sort_by: false},
    raftType: {render: (<div className="StandardList-Header-Column">Raft</div>), minWidth: 20, width: "grow", resizable: false},
    /*deleteFunction: {render: (<div className="StandardList-Header-Column"> </div>), widthType: "px", width: 20, resizable: false},*/
  }
  


  React.useEffect( () =>  { 
    //fetchTasks
    //dispatch(getAllGrowPlans())
  }, [])
  
  //let plans = useSelector(selectAllPlans)
  //let haveInitialData = useSelector((state) => state.plans.haveInitialData)


  let navigate = useNavigate(); 
  const planClicked = (plan) => {
    navigate("/repo/plan/" + plan.id);
  }

 
  const onSearchInput = () => {

  }
  


  return (
    <>
      <div id="GrowPlansPage">
        <StandardList 
          isSearchable={true}
          isMultiSelectable={true}
          resourcesAttached={false}
          columns={columns} 
          showHeader={true} 
          rows={growPlansList}/>
        <div>
          Test
        </div>
      </div>
      {creatingGrowPlan && 
        <CreateGrowPlanPopup closeCallback={closeCreateGrowPlanPopup} completeCallback={growPlanFinalized}/>
      }
    </>
  )
} 

export default GrowPlansPage