import './PopupModal.scss';
import React from 'react';

import BadgeTimeline from '../components/BadgeTimeline.js'
import {MdClose} from 'react-icons/md'
import {Close} from '../assets/icons/Icons';
import { useMediaQuery } from 'react-responsive';


const PopupModal = ({
  id, title, description, closeCallback,
  size, hideMainSeparators, contentAlignment,
  hasTimeline, timelineSteps, timelineStep, timelineStepChange,
  children, footer
}) => {

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  const closePopup = () =>  {
    if (closeCallback !== undefined)  {
      closeCallback(false)
    }
  }
  const forceClosePopup = () =>  {
    if (closeCallback !== undefined)  {
      closeCallback(true)
    }
  }

  const [currentTimelineStep, SetTimelineStep] = React.useState(undefined)
  React.useEffect(() => {
    if (timelineStep !== currentTimelineStep) {
      SetTimelineStep(timelineStep)
    }
  }, [timelineStep])
  const timelineStepChanged = (key) =>    {
      if (currentTimelineStep !== key)    {
        SetTimelineStep(key)
          if (timelineStepChange !== undefined) {
            timelineStepChange(key)
          }
      }
  }
  

  const idProps = {}
  if (id) {
    idProps.id = id;
  }

  if (size === "full_screen") {


    return (
      <div className={"PopupModal_FullScreen" + (contentAlignment === "center" ? " PopupModal_CenterContent" : "") + (isMobile ? " PopupModal_Mobile" : (isTablet ? " PopupModal_Tablet" : (isDesktop ? " PopupModal_Desktop" : "" ) ) )} {...idProps}>
        <div className="PopupModal">
            <div className={"PopupModal-Header" + (hideMainSeparators ? " PopupModal-NoSeperator" : "")}>
                <div className="PopupModal-HeaderContent">
                    <div className="PopupModal-HeaderTitle t_DisplayLarge">
                        {title}
                    </div>
                    {description && 
                      <div className="PopupModal-HeaderDescription">
                          {description}
                      </div>
                    }
                    {timelineSteps !== false &&
                      <BadgeTimeline steps={timelineSteps} currentStep={currentTimelineStep} onStepChanged={timelineStepChanged}/>
                    }
                </div>
                <div className="PopupModal-CloseButton" onClick={forceClosePopup}>
                  <Close/>
                </div>
            </div>

            <div className="PopupModal-Content">
              {children}
            </div>

            {footer && 
              <div className={"PopupModal-Footer" + (hideMainSeparators ? " PopupModal-NoSeperator" : "")}>
                {footer}
              </div>
            }
        </div>
      </div>
    )

  }else {

    const containerProps = {style: {}}
    if (size === "auto")  {
      containerProps.style.alignItems = "center"
    }else if (size === "full")  {
      containerProps.style.alignItems = "stretch"
    }


    return (
      <div className={"PopupModal-ScreenWrapper"} {...idProps}>
        <div onClick={closePopup} className="PopupModal-Container" {...containerProps}>
          <div className="PopupModal" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
              <div className="PopupModal-Header">
                  <div className="PopupModal-HeaderContent">
                      <div className="PopupModal-HeaderTitle t_DisplayLarge">
                          {title}
                      </div>
                      {description && 
                        <div className="PopupModal-HeaderDescription">
                            {description}
                        </div>
                      }
                      {timelineSteps !== false &&
                        <BadgeTimeline steps={timelineSteps} currentStep={currentTimelineStep} onStepChanged={timelineStepChanged}/>
                      }
                  </div>
                  <div className="PopupModal-CloseButton" onClick={forceClosePopup}>
                    <Close/>
                  </div>
              </div>

              <div className="PopupModal-Content">
                {children}
              </div>

              {footer && 
                <div className="PopupModal-Footer">
                  {footer}
                </div>
              }
          </div>
        </div>
      </div>
    )
  }
} 

PopupModal.defaultProps = {
  id: false,
  size: "auto",
  contentAlignment: false,
  footer: false,
  timelineSteps: false,
  hideMainSeparators: false
}


export default PopupModal