import {BsFillHouseDoorFill, BsFillCalendarCheckFill, BsBarChartSteps, 
    BsBookFill, BsFillInboxesFill, BsBuilding, BsFillChatFill, 
    BsGearFill, BsReverseLayoutTextWindowReverse, BsShop, BsWalletFill,
    BsPeopleFill, BsGraphUp, BsFillFilePostFill} from 'react-icons/bs'
import { MenuCRM, MenuFarms, MenuGrowManager, MenuHome, MenuRepo, MenuSettings, MenuStore, MenuTasks } from '../assets/icons/Icons';

class PageLink {
    constructor(title, path, icon = null, iconSize = 100) {
        this.title = title;
        this.path = path;
        this.icon = icon;
        this.iconSize = iconSize
    } 
  }
  

const defaultSize = 80;

/* new PageLink("Grow Analytics", "/growanalytics", 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-graph-up" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"/>
        </svg>, 
    defaultSize), */
    
const MainLinks = [
    new PageLink("Home", "", MenuHome, defaultSize),
    new PageLink("Tasks", "tasks", MenuTasks, defaultSize),
    new PageLink("Grow Manager", "growmanager", MenuGrowManager, defaultSize),
    new PageLink("Farms", "farms", MenuFarms, defaultSize),
    new PageLink("Recipes & Plans", "repo", MenuRepo, defaultSize),
    
    /*new PageLink("Inventory", "/inventory", <BsFillInboxesFill/>, defaultSize),*/
    /*new PageLink("Expenses", "/expenses", <BsWalletFill/>, defaultSize),*/
    /*new PageLink("Records", "/records", <BsReverseLayoutTextWindowReverse/>, defaultSize),*/
    /*new PageLink("Reports", "/reports", <BsFillFilePostFill/>, defaultSize),*/
    new PageLink("Online Store", "onlinestore", MenuStore, defaultSize),
    new PageLink("CRM", "crm", MenuCRM, defaultSize),
    new PageLink("Settings", "settings", MenuSettings, defaultSize),
]

const BottomLinks = [
    new PageLink("Chat", "chat", <BsFillChatFill/>, defaultSize),
] 



export const SideNavigatorMainLinks = MainLinks
export const SideNavigatorBottomLinks = BottomLinks