import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from './api'


const processDataRecordingTimePeriodTypeFromAPI = (type) =>  {
  return type
}
const processChartAxisTypeFromAPI = (type) =>  {
  return type
}
const processRecipeSetpointTypeFromAPI = (type) =>  {
  return type
}


export const getAppInfo = createAsyncThunk('appInfo/getAppInfo', async () => {
  return await FetchPost(APIRepository.AppInfo.GetAppInfo, {
    account_id: 29
  })   
}) 

export const appInfoSlice = createSlice({
  name: 'appInfo',
  initialState: {
    dataRecordingTimePeriodTypes:  [],
    recipeSetpointTypes:  [],
    chartAxisTypes: [],
    haveAppInfo: false,
    status: "idle"
  },
  reducers: {
    
  },
  
  extraReducers: {
    [getAppInfo.pending]: (state) => {
      state.status = 'pending';
    },

    [getAppInfo.fulfilled]: (state, action) => {
      if (action.payload.info !== undefined)  {
        state.status = 'fulfilled';
        state.haveAppInfo = true
        action.payload.info["data_recording_time_period_types"].map(function(type){ processDataRecordingTimePeriodTypeFromAPI(type); return type });
        action.payload.info["chart_axis_types"].map(function(type){ processChartAxisTypeFromAPI(type); return type });
        action.payload.info["recipe_setpoint_types"].map(function(type){ processRecipeSetpointTypeFromAPI(type); return type });
        state.dataRecordingTimePeriodTypes = action.payload.info["data_recording_time_period_types"];
        state.chartAxisTypes = action.payload.info["chart_axis_types"];
        state.recipeSetpointTypes = action.payload.info["recipe_setpoint_types"];
      }else {
        state.status = 'failed';
      }

      
    },

    [getAppInfo.rejected]: (state) => {
      state.status = 'rejected';
    },
  }
})


export default appInfoSlice.reducer


export const selectAllDataRecordingTimePeriodTypes = state => state.appInfo.dataRecordingTimePeriodTypes

export const selectDataRecordingTimePeriodTypeById = (state, dataRecordingTimePeriodTypeId) =>
  state.appInfo.dataRecordingTimePeriodTypes.find(type => type.id === dataRecordingTimePeriodTypeId)


export const selectAllChartAxisTypes = state => state.appInfo.chartAxisTypes

export const selectChartAxisTypeByIdentifier = (state, identifier) =>
  state.appInfo.chartAxisTypes.find(type => type.identifier === identifier)


export const selectAllRecipeSetpointTypes = state => state.appInfo.recipeSetpointTypes

export const selectRecipeSetpointTypeByIdentifier = (state, identifier) =>
  state.appInfo.recipeSetpointTypes.find(type => type.identifier === identifier)