import './GrowRacks.scss';
import variables from '../../../globals.scss';
import React from 'react'


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getAllServicesForFacility, selectAllFacilities } from '../../../redux/entities/Facilities';
import { activateTemporaryEditMode, addRackToGroup, createNewGroup, getLiveDataItem, getUniqueConfigurationByName, getVerticalRackConfigurationMap, getVerticalRackGroupByServiceId, getVerticalRackLiveDataById, getVerticalRackStatusById, hideRackStatusContent, removeGroup, selectAllVerticalRackConfigurationMaps, selectAllVerticalRackGroups, setVerticalRackRuntimeProperty, showRackStatusContent } from '../../../redux/entities/service/VerticalRackGroup';
import ControlBar from '../../../components/ControlBar';
import CircularGauge from '../../../components/CircularGauge';
import { GridTile } from '../../../components/GridTile';
import NumberInput from '../../../components/input/NumberInput';
import Switch from '../../../components/Switch';
import { parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import TextInput from '../../../components/input/TextInput';
import ToggleCellButton from '../../../components/ToggleCellButton';
import { AddItem, BadX, GoodCheckmark, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import BasicAlert from '../../../components/BasicAlert';
import AddRackWalkthrough from './AddRackWalkthrough';






const GrowRackService = ({selectedFacility, setSecondaryControls}) => {
  


  const dispatch = useDispatch()

  const facilityInfo = useSelector(state => state.facilities.facilities.find((f) => f.id === selectedFacility))
  
  const allVerticalRackGroups = useSelector(selectAllVerticalRackGroups)

  const [verticalRackGroups, SetVerticalRackGroups] = React.useState([])
  React.useEffect(() => {
    SetVerticalRackGroups(allVerticalRackGroups.filter((vRG) => facilityInfo !== undefined && facilityInfo.services.find((s) => s.id === vRG.service_id) !== undefined))
  }, [selectedFacility, allVerticalRackGroups])
  
  const verticalRackConfigurationMaps = useSelector(selectAllVerticalRackConfigurationMaps)


  React.useLayoutEffect(() => {
    setSecondaryControls((() => {
      
      return (<>
        {verticalRackGroups.length > 0 && 
          <Button content="Add Rack" status="Primary" onClick={addNewRack}/>  
        }
      </>)
    }))
  }, [verticalRackGroups])

  
  
  React.useEffect(() => {
    //We need to make sure we have all the racks configuration maps for components/io
    let mapsToLoad = {}
    for (let verticalRackGroup of verticalRackGroups) {
      if (verticalRackGroup.rack_map) {
        for (let verticalRack of verticalRackGroup.rack_map.racks)  {
          if (verticalRack.loadedFromDatabase)  {
            let foundVerticalRackConfigurationMap = verticalRackConfigurationMaps.find((cM) => cM.id === verticalRack.configuration_id)
            if (foundVerticalRackConfigurationMap === undefined)  {
              mapsToLoad[verticalRack.configuration_id] = {"component_map": null}
            }
          }
        }
      }
    }

    if (Object.entries(mapsToLoad).length > 0)  {
      dispatch(getVerticalRackConfigurationMap({maps: mapsToLoad}))
    }
    //console.log(verticalRackGroups)
  }, [verticalRackGroups, verticalRackConfigurationMaps])



  const lastRackStatusUpdateOn = React.useRef(new Date().getTime())
  const lastRackLiveDataUpdateOn = React.useRef(new Date().getTime())
  React.useEffect(() => {
    const getVerticalRackStatus = (rackIds) =>  {
      dispatch(getVerticalRackStatusById({verticalRackIds: rackIds}))
    }

    const getVerticalRackLiveData = (rackIds) =>  {
      dispatch(getVerticalRackLiveDataById({verticalRackIds: rackIds}))
    }
    
    const requestInterval = setInterval(() => {
      if (lastRackStatusUpdateOn.current + 2000 < new Date().getTime()) {
        //if (loadingRackStatus !== "pending")  {
          getVerticalRackStatus([1,2,3])
          lastRackStatusUpdateOn.current = new Date().getTime()
        //}
      }
      if (lastRackLiveDataUpdateOn.current + 200 < new Date().getTime()) {
        //if (loadingRackStatus !== "pending")  {
          getVerticalRackLiveData([1,2,3])
          lastRackLiveDataUpdateOn.current = new Date().getTime()
        //}
      }
    }, 10);
    

    return () => {
        clearInterval(requestInterval)
    };
  }, [verticalRackGroups])



  
  
  const [addingNewRack, SetAddingNewRack] = React.useState(false)
  const addNewRack = React.useCallback(() => {
    SetAddingNewRack(true)
  })

  

  const addRackWalkthroughCloseCallback = React.useCallback(() => {
    SetAddingNewRack(false)  
  })


  const addRackGroup = React.useCallback(() => {
    dispatch(createNewGroup())
  })
  const cancelRackGroupChanges = React.useCallback((verticalRackGroup) =>  {
    if (verticalRackGroup.isNew)  {
      dispatch(removeGroup({tempId: verticalRackGroup.temp_id}))
    }else {
      //dispatch(deleteGroup({id: verticalRackGroup.id}))
    }
  })

  const addRackToVerticalRackGroup = React.useCallback((verticalRackGroup) =>   {
    if (verticalRackGroup.isNew)  {
      dispatch(addRackToGroup({tempId: verticalRackGroup.temp_id}))
    }else {
      dispatch(addRackToGroup({id: verticalRackGroup.id}))
    }
  })


  




  ///const verticalRackGroupEditing = React.useState(null);

  return (<>
    <div id="GrowRacksStatus">
      
        {verticalRackGroups.length === 0 && 
          <div className="GrowRacksStatus-NoGroupsContent">
            <span>No active racks</span>
            <Button content={"Add your first rack"} onClick={addNewRack}/>
          </div>
        }
        {verticalRackGroups.length > 0 &&
          <div className="GrowRacksStatus-VerticalRackGroups">
            {verticalRackGroups.map((verticalRackGroup) => {
              let isVerticalRackGroupInEditMode = verticalRackGroup.pending_changes || verticalRackGroup.temporaryEditMode

              const toggleGroupInfo = () =>  {

              }
              const beginGroupEdit = () => {
                if (verticalRackGroup.isNew)  {
                  dispatch(activateTemporaryEditMode({tempId: verticalRackGroup.temp_id}))
                }else {
                  dispatch(activateTemporaryEditMode({id: verticalRackGroup.id}))
                }
              }
              
              
              const cancelChanges = ()  => {
                cancelRackGroupChanges(verticalRackGroup)
              }
              return (
                <div key={verticalRackGroup.temp_id || verticalRackGroup.id}
                  className="GrowRacksStatus-VerticalRackGroup">
                    <div className="GrowRacksStatus-VerticalRackGroup-Header">
                      <div className="GrowRacksStatus-VerticalRackGroup-Header-Title">
                        {isVerticalRackGroupInEditMode &&
                          <TextInput value={verticalRackGroup.display_name}/>
                        }
                        {!isVerticalRackGroupInEditMode && <>
                          {verticalRackGroup.display_name}
                        </>}
                      </div>
                      <div className="GrowRacksStatus-VerticalRackGroup-Header-Controls">
                          <ToggleCellButton onClick={toggleGroupInfo} content={"Hide Info"} state={true}/>
                      </div>
                    </div>
                    <div className="GrowRacksStatus-VerticalRackGroup-Racks">
                      {verticalRackGroup.rack_map.racks.map((verticalRack) => {
                        let isSerialNumberValidated = verticalRack.linked
                        let isRackOnline = (verticalRack.control_device !== null && verticalRack.control_device.connected)
                        let isRackStatusInfoVisible = (verticalRack.showStatusContent !== undefined && verticalRack.showStatusContent)

                        const toggleRackInfo = () =>  {

                        }

                        const toggleStatusContent = () => {
                          if (isRackStatusInfoVisible)  {
                            dispatch(hideRackStatusContent({group_id: verticalRackGroup.id, rack_id: verticalRack.id}))
                          }else {
                            dispatch(showRackStatusContent({group_id: verticalRackGroup.id, rack_id: verticalRack.id}))
                          }
                        }

                        return <div className="GrowRacksStatus-VerticalRackGroup-Rack" key={verticalRack.temp_id || verticalRack.id}>
                          <div className="GrowRackStatus-VerticalRackGroup-RackInfoContent">
                            <div className="GrowRackStatus-VerticalRackGroup-RackIdentityInfo">
                              {isVerticalRackGroupInEditMode &&
                                <ReorderItem/>
                              }
                              <div className="GrowRackStatus-VerticalRackGroup-RackIdentityInfo-Name">
                                {isVerticalRackGroupInEditMode &&
                                  <TextInput value={verticalRack.display_name}/>
                                }
                                {!isVerticalRackGroupInEditMode && <>
                                  <span>{verticalRack.display_name}</span>
                                </>}
                              </div>
                              <div className="GrowRackStatus-VerticalRackGroup-RackIdentityInfo-Status">
                                {!isSerialNumberValidated && 
                                  <BasicAlert status="Notify" content="Commission Rack" icon={<SoftWarning/>}/>
                                }
                                {(!isVerticalRackGroupInEditMode && isSerialNumberValidated) && <>
                                  {isRackOnline &&
                                    <div className="GrowRackStatus-VerticalRackGroup-RackStatus">
                                      <GoodCheckmark/>
                                      <span className="Online">Online</span>
                                    </div>
                                  }
                                  {!isRackOnline &&
                                    <div className="GrowRackStatus-VerticalRackGroup-RackStatus">
                                      <BadX/>
                                      <span className="Offline">Offline</span>
                                    </div>
                                  }
                                </>}
                                

                              </div>
                            </div>
                            <div className="GrowRackStatus-VerticalRackGroup-ManageControls">
                              {!isSerialNumberValidated && 
                                <Button status="Neutral" content="Commission Rack"/>
                              }
                              {isVerticalRackGroupInEditMode &&
                                <Button status="Neutral" content={<Trash/>}/>
                              }
                              {!isVerticalRackGroupInEditMode && <>
                                <ToggleCellButton onClick={toggleStatusContent} content={!isRackStatusInfoVisible ? "Show Status" : "Hide Status"} state={isRackStatusInfoVisible}/>
                              </>}
                            </div>
                          </div>
                          {isRackStatusInfoVisible && 
                            <div className="GrowRackStatus-VerticalRackGroup-RackStatusContent">









                              {(() => {
                                let rackStatusColor = (verticalRack.control_device !== null && verticalRack.control_device.connected) ? variables.online : variables.offline
                                var inServiceMode = parseBool(verticalRack.runtime_information_requested["service_mode"] ?? verticalRack.runtime_information["service_mode"] ?? false)

                                var compressorSpeedInOverride = parseBool(verticalRack.runtime_information["compressor_speed_override"] ?? false)
                                var compressorSpeedOverrideValue = verticalRack.runtime_information_requested["compressor_speed"] ?? verticalRack.runtime_information["compressor_speed"] ?? 0

                                var supplyFanSpeedInOverride = parseBool(verticalRack.runtime_information_requested["supply_fan_speed_override"] ?? verticalRack.runtime_information["supply_fan_speed_override"] ?? false)
                                var supplyFanSpeedOverrideValue = verticalRack.runtime_information_requested["supply_fan_speed"] ?? verticalRack.runtime_information["supply_fan_speed"] ?? 0

                                var condenserFanSpeedInOverride = parseBool(verticalRack.runtime_information_requested["condenser_fan_speed_override"] ?? verticalRack.runtime_information["condenser_fan_speed_override"] ?? false)
                                var condenserFanSpeedOverrideValue = verticalRack.runtime_information_requested["condenser_fan_speed"] ?? verticalRack.runtime_information["condenser_fan_speed"] ?? 0

                                var bypassDamperPositionInOverride = parseBool(verticalRack.runtime_information_requested["bypass_damper_position_override"] ?? verticalRack.runtime_information["bypass_damper_position_override"] ?? false)
                                var bypassDamperPositionOverrideValue = verticalRack.runtime_information_requested["bypass_damper_position"] ?? verticalRack.runtime_information["bypass_damper_position"] ?? 0

                                var subcoolValvePositionInOverride = parseBool(verticalRack.runtime_information_requested["subcool_valve_position_override"] ?? verticalRack.runtime_information["subcool_valve_position_override"] ?? false)
                                var subcoolValvePositionOverrideValue = verticalRack.runtime_information_requested["subcool_valve_position"] ?? verticalRack.runtime_information["subcool_valve_position"] ?? 0

                                var reheatSubcoolValvePositionInOverride = parseBool(verticalRack.runtime_information_requested["reheat_subcool_valve_position_override"] ?? verticalRack.runtime_information["reheat_subcool_valve_position_override"] ?? false)
                                var reheatSubcoolValvePositionOverrideValue = verticalRack.runtime_information_requested["reheat_subcool_valve_position"] ?? verticalRack.runtime_information["reheat_subcool_valve_position"] ?? 0

                                var primaryLocalRecirculationInOverride = parseBool(verticalRack.runtime_information_requested["primary_local_recirculation_override"] ?? verticalRack.runtime_information["primary_local_recirculation_override"] ?? false)
                                
                                var auxALocalRecirculationInOverride = parseBool(verticalRack.runtime_information_requested["aux_a_local_recirculation_override"] ?? verticalRack.runtime_information["aux_a_local_recirculation_override"] ?? false)
                                var auxBLocalRecirculationInOverride = parseBool(verticalRack.runtime_information_requested["aux_b_local_recirculation_override"] ?? verticalRack.runtime_information["aux_b_local_recirculation_override"] ?? false)
                                


                                var isAuxAPumpingSystemActive = parseBool(getUniqueConfigurationByName(verticalRack, "AuxALiquidSystemInstalled"));
                                var isAuxBPumpingSystemActive = parseBool(getUniqueConfigurationByName(verticalRack, "AuxBLiquidSystemInstalled"));


                                let foundRackConfigurationMap = verticalRackConfigurationMaps.find((cM) => cM.id === verticalRack.configuration_id)
                                if (foundRackConfigurationMap !== undefined)  {
                                  
                                  

                                  let [leftDoorSafetySwitchComponent, [leftDoorSafetySwitchIdentifierInfo], [leftDoorSafetySwitchDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "LeftDoorSafetySwitch", ["state"])
                                  let [rightDoorSafetySwitchComponent, [rightDoorSafetySwitchIdentifierInfo], [rightDoorSafetySwitchDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "RightDoorSafetySwitch", ["state"])

                                  let [rackCurrentTranducerComponent, [rackCurrentIdentifierInfo, rackWattageIdentifierInfo, rackVoltageIdentifierInfo],  [rackCurrentDataItem, rackWattageDataItem, rackVoltageDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "RackCurrentTransducer", ["current", "w", "v"])
                                  
                                  let lightingComponent = foundRackConfigurationMap.component_map.components.find((c) => c.name === "LightingController")
                                  let lightingBlueIdentifierInfo = lightingComponent !== undefined ? lightingComponent.data_types.find((dT) => dT.identifier === "blue") : undefined
                                  let lightingBlueInfo = lightingBlueIdentifierInfo !== undefined && verticalRack.liveData[lightingComponent.id] !== undefined && verticalRack.liveData[lightingComponent.id][lightingBlueIdentifierInfo.identifier] !== undefined ? verticalRack.liveData[lightingComponent.id][lightingBlueIdentifierInfo.identifier] : null
                                  
                                  let [canopyAirComponent, 
                                    [airTempIdentifierInfo, airRHIdentifierInfo, airCO2IdentifierInfo, airVPDIdentifierInfo], 
                                    [airTempDataItem, airRHDataItem, airCO2DataItem, airVPDDataItem]] =
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "CanopyAirSpace", ["at", "arh", "ac", "vpd"])
                                  let [leafTempComponent, [leafTempIdentifierInfo],  [leafTempDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "LeafThermistor", ["input"])

                                    
                                  let [compressorComponent, 
                                    [compressorSpeedIdentifierInfo, compressorRPMIdentifierInfo, compressorStateIdentifierInfo], 
                                    [compressorSpeedDataItem, compressorRPMDataItem, compressorStateDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "Compressor", ["speed", "rpm", "state"])
                                  let compressorStateColor = compressorStateDataItem !== null ? (compressorStateDataItem.value ? variables.online : variables.offline) : variables.offline
                                  
                                  let [compressorSuctionPressureComponent, 
                                    [compressorSuctionPressureIdentifierInfo, evaporatorSuperheatIdentifierInfo], 
                                    [compressorSuctionPressureDataItem, evaporatorSuperheatDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "CompressorInletPressureTransducer", ["pressure", "sh"])
                                  let [condenserSubcoolPressureComponent, 
                                    [condenserSubcoolPressureIdentifierInfo, condenserSubcoolIdentifierInfo], 
                                    [condenserSubcoolPressureDataItem, condenserSubcoolDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "CondenserSubcoolPressureTransducer", ["pressure", "sc"])
                                  let [accumulatorSubcoolPressureComponent, 
                                    [accumulatorSubcoolPressureIdentifierInfo, accumulatorSubcoolIdentifierInfo], 
                                    [accumulatorSubcoolPressureDataItem, accumulatorSubcoolDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "AccumulatorSubcoolPressureTransducer", ["pressure", "sc"])

                                  let [compressorDischargeTempComponent, [compressorDischargeTempIdentifierInfo],  [compressorDischargeTempDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["comp-d"])
                                  let [enteringExpValveTempComponent, [enteringExpValveTempIdentifierInfo],  [enteringExpValveTempDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["ent-exp-v"])                    
                                  let [enteringEvapTempComponent, [enteringEvapTempIdentifierInfo],  [enteringEvapTempDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["ent-evap"])                    
                                  let [leavingEvapTempComponent, [leavingEvapTempIdentifierInfo],  [leavingEvapTempDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["leave-evap"])                     
                                  let [reheatTempComponent, [reheatTempIdentifierInfo],  [reheatTempDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["rh"])                   

                                  let [condenserFanComponent, [condenserFanSpeedIdentifierInfo],  [condenserFanSpeedDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "CondenserFan", ["control"])
                                  let [supplyFanComponent, [supplyFanSpeedIdentifierInfo, supplyFanRPMIdentifierInfo],  [supplyFanSpeedDataItem, supplyFanRPMDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "SupplyFan", ["control", "rpm"])
                                  let [subcoolValveComponent, [subcoolValvePositionIdentifierInfo],  [subcoolValvePositionDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "SubcoolValve", ["output_signal"])
                                  let [reheatValveComponent, [reheatValvePositionIdentifierInfo],  [reheatValvePositionDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "ReheatBypassValve", ["output_signal"])
                                  
                                  let [condensateSubsystemComponent, 
                                    [condensateSubsystemPumpStateIdentifierInfo, condensateSubsystemFloatSwitchStateIdentifierInfo, condensateSubsystemTotalVolumeIdentifierInfo], 
                                    [condensateSubsystemPumpStateDataItem, condensateSubsystemFloatSwitchStateDataItem, condensateSubsystemTotalVolumeDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "CondensateSubsystem", ["p_state", "fs_state", "current_total_condensate_volume"])

                                  let  [recirculationPump1Component, [recirculationPump1StateIdentifierInfo], [recirculationPump1StateDataItem]]
                                    = getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "PrimaryALiquidRecirculationPump", ["state"])
                                  let [recirculationPump2Component, [recirculationPump2StateIdentifierInfo], [recirculationPump2StateDataItem]]
                                      = getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "PrimaryBLiquidRecirculationPump", ["state"])
                                  
                                

                                    
              
                                  let [primaryWaterReservoirComponent, 
                                    [primaryWaterLevelIdentifierInfo, primaryWaterVolumeIdentifierInfo], 
                                    [primaryWaterLevelDataItem, primaryWaterVolumeDataItem]] =
                                      getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "PrimaryWaterReservoir", ["water_level", "water_volume"])
                                  
                                  let [primaryWaterReservoirTempComponent, [primaryWaterTempIdentifierInfo],  [primaryWaterTempDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["p-wt"])
                                  let [primaryReservoirSystemPressureTransducer, [primaryReservoirSystemPressureIdentifierInfo],  [primaryReservoirSystemPressureDataItem]] =
                                      getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "PrimaryLiquidSystemPressureTransducer", ["pressure"])
                  
                                  let reservoirStateColor = variables.online
              



                                  let  [auxARecirculationPumpComponent, [auxARecirculationPumpStateIdentifierInfo], [auxARecirculationPumpStateDataItem]]
                                    = getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "AuxALiquidRecirculationPump", ["state"])
                                  let [auxAWaterReservoirComponent, 
                                    [auxAWaterLevelIdentifierInfo, auxAWaterVolumeIdentifierInfo], 
                                    [auxAWaterLevelDataItem, auxAWaterVolumeDataItem]] =
                                      getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "AuxAWaterReservoir", ["water_level", "water_volume"])
                                  
                                  let [auxAWaterReservoirTempComponent, [auxAWaterTempIdentifierInfo],  [auxAWaterTempDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["p-aawt"])
                                  let [auxAReservoirSystemPressureTransducer, [auxAReservoirSystemPressureIdentifierInfo],  [auxAReservoirSystemPressureDataItem]] =
                                      getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "AuxALiquidSystemPressureTransducer", ["pressure"])
                                  
                                  let  [auxBRecirculationPumpComponent, [auxBRecirculationPumpStateIdentifierInfo], [auxBRecirculationPumpStateDataItem]]
                                    = getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "AuxBLiquidRecirculationPump", ["state"])
                                  let [auxBWaterReservoirComponent, 
                                    [auxBWaterLevelIdentifierInfo, auxBWaterVolumeIdentifierInfo], 
                                    [auxBWaterLevelDataItem, auxBWaterVolumeDataItem]] =
                                      getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "AuxBWaterReservoir", ["water_level", "water_volume"])
                                  
                                  let [auxBWaterReservoirTempComponent, [auxBWaterTempIdentifierInfo],  [auxBWaterTempDataItem]] = 
                                    getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["p-abwt"])
                                  let [auxBReservoirSystemPressureTransducer, [auxBReservoirSystemPressureIdentifierInfo],  [auxBReservoirSystemPressureDataItem]] =
                                      getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "AuxBLiquidSystemPressureTransducer", ["pressure"])
                                    

                                  let [rackMotionComponent, 
                                    [rackMotionReverseStateIdentifierInfo, rackMotionForwardStateIdentifierInfo], 
                                    [rackMotionReverseStateDataItem, rackMotionForwardStateDataItem]] =
                                      getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "RackMotionController", ["reverse_switch_state", "forward_switch_state"])
              
                                      let [rackMotionDistanceSensorComponent, [rackMotionDistanceSensorDistanceIdentifierInfo], [rackMotionDistanceSensorDistanceDataItem]] =
                                          getLiveDataItem(verticalRack, foundRackConfigurationMap.component_map, "MotionDistanceSensor", ["cd"])
                  
                                      

                                  return (
                                    <div key={verticalRack.id}
                                      className="GrowZones-Service-VerticalRackGroup-Rack">
                                        <div className="GrowZones-Service-VerticalRackGroup-Rack-Header">
                                          <div className="GrowZones-Service-VerticalRackGroup-Rack-Header-Title">Rack {verticalRack.index}</div>
                                          <div className="GrowZones-Service-VerticalRackGroup-Rack-Header-StatusIndicator" style={{backgroundColor:rackStatusColor}}/>
                                          <div className="GrowZones-Service-VerticalRackGroup-Rack-Header-ServiceMode">
                                            Service Mode:
                                            <Switch state={inServiceMode} onSwitch={(state) => {
                                                dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"service_mode": state}}))
                                              }}/>
                                          </div>
                                        </div>
                                        <div className="GrowZones-Service-VerticalRackGroup-Rack-Content">
                                          <div className="GridTile-WrappingTiles">
                                            <GridTile>
                                              <div className="GridTile-Header">
                                                <div className="GridTile-Title">System Status</div>
                                              </div>
                                              <table className="GridTile-DataItemTable">
                                                <tbody>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">IP:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{verticalRack.control_device.ip_address.split(":")[0]}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Local IP:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{verticalRack.control_device.local_ip_address}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Local Port:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{verticalRack.control_device.port}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Left Service Door:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{leftDoorSafetySwitchDataItem !== null ? (leftDoorSafetySwitchDataItem.value === 1 ? "Closed": "Open") : "N/A"}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Right Service Door:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{rightDoorSafetySwitchDataItem !== null ? (rightDoorSafetySwitchDataItem.value === 1 ? "Closed": "Open") : "N/A"}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Current:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{rackCurrentDataItem !== null ? rackCurrentDataItem.value + "A" : "N/A"}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Voltage:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{rackVoltageDataItem !== null ? rackVoltageDataItem.value + "V" : "N/A"}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Wattage:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{rackWattageDataItem !== null ? rackWattageDataItem.value + "W" : "N/A"}</td>
                                                  </tr>
                                                </tbody>
                                              </table>

                                              
                                            </GridTile>
                                            <GridTile className="GridTile-GaugeTile">
                                                <div className="GridTile-Header" onClick={() => {
                                                    
                                                  }}>
                                                <div className="GridTile-Title">Air Condition Load</div>
                                                <div className="GridTile-StatusIndicator" style={{backgroundColor:compressorStateColor}}/>
                                              </div>
                                              <CircularGauge value={compressorSpeedDataItem && compressorSpeedDataItem.value} auxText={compressorRPMDataItem && compressorRPMDataItem.value + "rpm"}/>
                                              {!!inServiceMode && 
                                                <div className="GridTile-OverrideItem">
                                                  <div className="GridTile-OverrideItem-Toggle">Speed Override
                                                    <Switch state={compressorSpeedInOverride} onSwitch={(state) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"compressor_speed_override": state}}))
                                                    }}/>
                                                  </div>
                                                  <div className="GridTile-OverrideItem-Value">
                                                    <NumberInput 
                                                      min={0}
                                                      max={100}
                                                      value={parseFloat(compressorSpeedOverrideValue)} 
                                                      onBlur={(value) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"compressor_speed": value}}))
                                                    }}/>
                                                  </div>
                                                </div>
                                              }
                                            </GridTile>
                                            <GridTile className="GridTile-GaugeTile">
                                              <div className="GridTile-Header">
                                                <div className="GridTile-Title">Air Condition Status</div>
                                              </div>
                                              <table className="GridTile-DataItemTable">
                                                <tbody>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Discharge Temp:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{compressorDischargeTempDataItem && compressorDischargeTempDataItem.value}°C</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Ent Subcool V Temp:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{enteringExpValveTempDataItem && enteringExpValveTempDataItem.value}°C</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Suction Temp:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{enteringEvapTempDataItem && enteringEvapTempDataItem.value}°C</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Leaving Evap Temp:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{leavingEvapTempDataItem && leavingEvapTempDataItem.value}°C</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Reheat Temp:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{reheatTempDataItem && reheatTempDataItem.value}°C</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Suction Pres.:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{compressorSuctionPressureDataItem && compressorSuctionPressureDataItem.value}psi</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Cond SC Pres.:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{condenserSubcoolPressureDataItem && condenserSubcoolPressureDataItem.value}psi</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Acc SC Pres.:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{accumulatorSubcoolPressureDataItem && accumulatorSubcoolPressureDataItem.value}psi</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Cond Subcool:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{condenserSubcoolDataItem && condenserSubcoolDataItem.value}°C</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Acc Subcool:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{accumulatorSubcoolDataItem && accumulatorSubcoolDataItem.value}°C</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Superheat:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{evaporatorSuperheatDataItem && evaporatorSuperheatDataItem.value}°C</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Condenser Fan:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{condenserFanSpeedDataItem && condenserFanSpeedDataItem.value}%</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Supply Fan:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{supplyFanSpeedDataItem && supplyFanSpeedDataItem.value}%</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Supply RPM:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{supplyFanRPMDataItem && supplyFanRPMDataItem.value}%</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Subcool Valve:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{subcoolValvePositionDataItem && subcoolValvePositionDataItem.value}%</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Reheat Valve:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{reheatValvePositionDataItem && reheatValvePositionDataItem.value}%</td>
                                                  </tr>

                                                  
                                                </tbody>
                                              </table>
                                            </GridTile>
                                            {!!inServiceMode && 
                                              <GridTile className="GridTile-GaugeTile">
                                                <div className="GridTile-Header">
                                                  <div className="GridTile-Title">Air Condition Overrides</div>
                                                </div>
                                                <div className="GridTile-OverrideItem">
                                                  <div className="GridTile-OverrideItem-Toggle">Supply Fan 
                                                    <Switch state={supplyFanSpeedInOverride} onSwitch={(state) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"supply_fan_speed_override": state}}))
                                                    }}/>
                                                  </div>
                                                  <div className="GridTile-OverrideItem-Value">
                                                    <NumberInput 
                                                      min={0}
                                                      max={100}
                                                      value={parseFloat(supplyFanSpeedOverrideValue)} 
                                                      onBlur={(value) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"supply_fan_speed": value}}))
                                                  }}/></div>
                                                </div>
                                                <div className="GridTile-OverrideItem">
                                                  <div className="GridTile-OverrideItem-Toggle">Bypass Damper
                                                    <Switch state={bypassDamperPositionInOverride} onSwitch={(state) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"bypass_damper_position_override": state}}))
                                                    }}/>
                                                  </div>
                                                  <div className="GridTile-OverrideItem-Value">
                                                    <NumberInput 
                                                      min={0}
                                                      max={100}
                                                      value={parseFloat(bypassDamperPositionOverrideValue)} 
                                                      onBlur={(value) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"bypass_damper_position": value}}))
                                                  }}/></div>
                                                </div>
                                                <div className="GridTile-OverrideItem">
                                                  <div className="GridTile-OverrideItem-Toggle">Condenser Fan 
                                                    <Switch state={condenserFanSpeedInOverride} onSwitch={(state) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"condenser_fan_speed_override": state}}))
                                                    }}/>
                                                  </div>
                                                  <div className="GridTile-OverrideItem-Value">
                                                    <NumberInput 
                                                      min={0}
                                                      max={100}
                                                      value={parseFloat(condenserFanSpeedOverrideValue)} 
                                                      onBlur={(value) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"condenser_fan_speed": value}}))
                                                  }}/></div>
                                                </div>
                                                <div className="GridTile-OverrideItem">
                                                  <div className="GridTile-OverrideItem-Toggle">Subcool Valve
                                                    <Switch state={subcoolValvePositionInOverride} onSwitch={(state) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"subcool_valve_position_override": state}}))
                                                    }}/>
                                                  </div>
                                                  <div className="GridTile-OverrideItem-Value">
                                                    <NumberInput 
                                                      min={0}
                                                      max={100}
                                                      value={parseFloat(subcoolValvePositionOverrideValue)} 
                                                      onBlur={(value) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"subcool_valve_position": value}}))
                                                  }}/></div>
                                                </div>
                                                <div className="GridTile-OverrideItem">
                                                  <div className="GridTile-OverrideItem-Toggle">Reheat Valve
                                                    <Switch state={reheatSubcoolValvePositionInOverride} onSwitch={(state) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"reheat_subcool_valve_position_override": state}}))
                                                    }}/>
                                                  </div>
                                                  <div className="GridTile-OverrideItem-Value">
                                                    <NumberInput 
                                                      min={0}
                                                      max={100}
                                                      value={parseFloat(reheatSubcoolValvePositionOverrideValue)} 
                                                      onBlur={(value) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"reheat_subcool_valve_position": value}}))
                                                  }}/></div>
                                                </div>
                                              </GridTile>
                                            }
                                            <GridTile>
                                              <div className="GridTile-Header">
                                                <div className="GridTile-Title">Canopy Air</div>
                                              </div>
                                              <table className="GridTile-DataItemTable">
                                                <tbody>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Temperature:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{airTempDataItem && airTempDataItem.value}°C</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">RH:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{airRHDataItem && airRHDataItem.value}%</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">VPD:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{airVPDDataItem && airVPDDataItem.value}kPa</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">CO2:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{airCO2DataItem && airCO2DataItem.value}ppm</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Leaf Temp:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{leafTempDataItem && leafTempDataItem.value}°C</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </GridTile>
                                            <GridTile>
                                              <div className="GridTile-Header">
                                                <div className="GridTile-Title">Primary Pump System Status</div>
                                                <div className="GridTile-StatusIndicator" style={{backgroundColor:reservoirStateColor}}/>
                                              </div>
                                              <CircularGauge value={primaryWaterLevelDataItem && primaryWaterLevelDataItem.value} auxText={primaryWaterVolumeDataItem && primaryWaterVolumeDataItem.value + "L"}/>
                                              <table className="GridTile-DataItemTable">
                                                <tbody>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Temperature:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{primaryWaterTempDataItem && primaryWaterTempDataItem.value}°C</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Pressure:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{primaryReservoirSystemPressureDataItem && primaryReservoirSystemPressureDataItem.value}psi</td>
                                                  </tr>

                                                  
                                                </tbody>
                                              </table>
                                              <div className="GridTile-OverrideItem">
                                                  <div className="GridTile-OverrideItem-Toggle">Local Recirculation
                                                    <Switch state={primaryLocalRecirculationInOverride} onSwitch={(state) => {
                                                      dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"primary_local_recirculation_override": state}}))
                                                    }}/>
                                                  </div>
                                                </div>
                                            </GridTile>
                                            {isAuxAPumpingSystemActive &&
                                              <GridTile>
                                                <div className="GridTile-Header">
                                                  <div className="GridTile-Title">Aux A Pump System Status</div>
                                                  <div className="GridTile-StatusIndicator" style={{backgroundColor:reservoirStateColor}}/>
                                                </div>
                                                <CircularGauge value={auxAWaterLevelDataItem && auxAWaterLevelDataItem.value} auxText={auxAWaterVolumeDataItem && auxAWaterVolumeDataItem.value + "L"}/>
                                                <table className="GridTile-DataItemTable">
                                                  <tbody>
                                                    <tr className="GridTile-DataItem-Row">
                                                      <td className="GridTile-DataItem-Row-Label">Temperature:</td>
                                                      <td className="GridTile-DataItem-Row-Value">{auxAWaterTempDataItem && auxAWaterTempDataItem.value}°C</td>
                                                    </tr>
                                                    <tr className="GridTile-DataItem-Row">
                                                      <td className="GridTile-DataItem-Row-Label">Pressure:</td>
                                                      <td className="GridTile-DataItem-Row-Value">{auxAReservoirSystemPressureDataItem && auxAReservoirSystemPressureDataItem.value}psi</td>
                                                    </tr>

                                                    
                                                  </tbody>
                                                </table>
                                                <div className="GridTile-OverrideItem">
                                                    <div className="GridTile-OverrideItem-Toggle">Local Recirculation
                                                      <Switch state={auxALocalRecirculationInOverride} onSwitch={(state) => {
                                                        dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"aux_a_local_recirculation_override": state}}))
                                                      }}/>
                                                    </div>
                                                  </div>
                                              </GridTile>
                                            }
                                            {isAuxBPumpingSystemActive &&
                                              <GridTile>
                                                <div className="GridTile-Header">
                                                  <div className="GridTile-Title">Aux B Pump System Status</div>
                                                  <div className="GridTile-StatusIndicator" style={{backgroundColor:reservoirStateColor}}/>
                                                </div>
                                                <CircularGauge value={auxBWaterLevelDataItem && auxBWaterLevelDataItem.value} auxText={auxBWaterVolumeDataItem && auxBWaterVolumeDataItem.value + "L"}/>
                                                <table className="GridTile-DataItemTable">
                                                  <tbody>
                                                    <tr className="GridTile-DataItem-Row">
                                                      <td className="GridTile-DataItem-Row-Label">Temperature:</td>
                                                      <td className="GridTile-DataItem-Row-Value">{auxBWaterTempDataItem && auxBWaterTempDataItem.value}°C</td>
                                                    </tr>
                                                    <tr className="GridTile-DataItem-Row">
                                                      <td className="GridTile-DataItem-Row-Label">Pressure:</td>
                                                      <td className="GridTile-DataItem-Row-Value">{auxBReservoirSystemPressureDataItem && auxBReservoirSystemPressureDataItem.value}psi</td>
                                                    </tr>

                                                    
                                                  </tbody>
                                                </table>
                                                <div className="GridTile-OverrideItem">
                                                    <div className="GridTile-OverrideItem-Toggle">Local Recirculation
                                                      <Switch state={auxBLocalRecirculationInOverride} onSwitch={(state) => {
                                                        dispatch(setVerticalRackRuntimeProperty({verticalRackId: verticalRack.id, properties: {"aux_b_local_recirculation_override": state}}))
                                                      }}/>
                                                    </div>
                                                  </div>
                                              </GridTile>
                                            }
                                            <GridTile>
                                              <div className="GridTile-Header">
                                                <div className="GridTile-Title">Condensate Subsystem</div>
                                              </div>
                                              <table className="GridTile-DataItemTable">
                                                <tbody>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Pump State:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{condensateSubsystemPumpStateDataItem && (condensateSubsystemPumpStateDataItem.value === 1) ? "On" : "Off"}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Float Switch State:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{condensateSubsystemFloatSwitchStateDataItem && (condensateSubsystemFloatSwitchStateDataItem.value === 1) ? "On" : "Off"}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Current Volume:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{condensateSubsystemTotalVolumeDataItem && condensateSubsystemTotalVolumeDataItem.value}mL</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </GridTile>
                                            <GridTile>
                                              <div className="GridTile-Header">
                                                <div className="GridTile-Title">Rack Motion</div>
                                              </div>
                                              <table className="GridTile-DataItemTable">
                                                <tbody>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Reverse Switch:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{rackMotionReverseStateDataItem && rackMotionReverseStateDataItem.value ? "On" : "Off"}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Forward Switch:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{rackMotionForwardStateDataItem && rackMotionForwardStateDataItem.value ? "On" : "Off"}</td>
                                                  </tr>
                                                  <tr className="GridTile-DataItem-Row">
                                                    <td className="GridTile-DataItem-Row-Label">Distance:</td>
                                                    <td className="GridTile-DataItem-Row-Value">{rackMotionDistanceSensorDistanceDataItem && rackMotionDistanceSensorDistanceDataItem.value}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </GridTile>
                                          </div>
                                        </div>
                                    </div>
                                  )

                                  

                                }else {
                                  return (
                                    <div key={verticalRack.id}
                                      className="GrowZones-Service-VerticalRackGroup-Rack">
                                        <div className="GrowZones-Service-VerticalRackGroup-Rack-Content">
                                          <div className="GrowZones-Service-VerticalRackGroup-Rack-Name">
                                            Rack {verticalRack.index}
                                          </div>
                                          No configuration map
                                        </div>
                                    </div>
                                  )
                                }  
                              })()}



































                            </div>
                          }
                        </div>                  
                      })}
                    </div>
                    {/*<div className="GrowRacksStatus-VerticalRackGroup-FooterControls">
                      <div></div>
                      <div>
                        <Button 
                          onClick={addNewRack}
                          content={<>
                            <AddItem/>
                            <span>Add New Rack</span>
                          </>} status="Primary-Inverted"/>
                      </div>
                      <div></div>
                          </div>*/}
                </div>
              )
            })}
          </div>
        }
    </div>
    {addingNewRack && <AddRackWalkthrough facilityId={selectedFacility} closeCallback={addRackWalkthroughCloseCallback}/>}
  </>)
} 

export default GrowRackService