
import './SelectGrowPlans.scss';
import React from 'react'

import {StandardList, StandardListRow} from '../components/StandardList/StandardList.js'
import { selectAllGrowPlans, getAllGrowPlans } from '../redux/entities/GrowPlans'
import { selectAllRecipes, getAllRecipes } from '../redux/entities/Recipes'
import { useSelector, useDispatch } from 'react-redux'
import { FormatDate } from '../helpers'
import { Raft, RaftTypes } from './RaftType';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import Button from '../components/Button';


const SelectGrowPlans = ({
    pullOutSelectedItems,
    selectedGrowPlan, onGrowPlanSelection, 
    allowMultiselect, selectedGrowPlans, onGrowPlansSelection,
    matchesRecipeId 
  }) => {

  const dispatch = useDispatch()
  React.useEffect( () =>  { 
    //fetchTasks
    dispatch(getAllGrowPlans())
  }, [])
  
  let growPlans = useSelector(selectAllGrowPlans)
  let recipes = useSelector(selectAllRecipes)
  let haveInitialData = useSelector((state) => state.growPlans.haveInitialData)

  const [plantTypes, SetPlantTypes] = React.useState([
    {id: 1, name: "Blackberries", group_id: 1, description: "Disgusting horribly delicious fruit"},
    {id: 2, name: "Strawberries", group_id: 1, description: "Disgusting horribly delicious fruit"},
    {id: 3, name: "Butterhead", group_id: 2, description: "The only plant we can grow"}
  ])
  const [plantVarieties, SetPlantVarieties] = React.useState([
    {id: 1, plant_type_id: 1, name: "Crimson Queen", description: "Disgusting horribly delicious fruit"},
    {id: 2, plant_type_id: 2, name: "Bitter Blue", description: "Disgusting horribly delicious fruit"},
    {id: 3, plant_type_id: 2, name: "Blah blah yuck", description: "The only plant we can grow"},
    {id: 4, plant_type_id: 3, name: "Ya blah yuck", description: "Testing"}
  ])

  const [loadingGrowPlans, SetLoadingGrowPlans] = React.useState(haveInitialData)
  const [growPlansList, SetGrowPlansList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedGrowPlan, SetSelectedGrowPlan] = React.useState(undefined)
  const [currentSelectedGrowPlans, SetSelectedGrowPlans] = React.useState([])
  React.useEffect(() => {
    if (currentSelectedGrowPlan !== selectedGrowPlan) {
      SetSelectedGrowPlan(selectedGrowPlan)
    }
  }, [selectedGrowPlan])

  React.useEffect(() => {
    SetSelectedGrowPlans([...selectedGrowPlans])
  }, [selectedGrowPlans])
  

  React.useEffect(() => {
    let currentGrowPlansList = {}

    let currentGrowPlans = []
    
    let availableGrowPlans = []
    if (!pullOutSelectedItems) {
      availableGrowPlans = [...growPlans]
    }else {
      availableGrowPlans = growPlans.filter((gP) => currentSelectedGrowPlans.find((gPS) => gPS.id === gP.id) === undefined)
    }
    if (matchesRecipeId)  {
      availableGrowPlans = availableGrowPlans.filter((gP) => gP.recipe_id === matchesRecipeId)
    }

    if (currentSearchInput === "")  {
      currentGrowPlans = availableGrowPlans
    }else {
      currentGrowPlans.push(...availableGrowPlans.filter((growPlan) => {
        if (growPlan.name.indexOf(currentSearchInput) !== -1 && currentGrowPlans.indexOf(growPlan) === -1)  {
          return growPlan
        }
      }))
    }
    
    
    for (let growPlan of currentGrowPlans) {

      let foundPlantType = plantTypes.find((plantType) => plantType.id === growPlan.plant_type_id)
      let foundPlantVariety = plantVarieties.find((plantVariety) => plantVariety.id === growPlan.plant_variety_id)
      let foundRecipe = recipes.find((recipe) => recipe.id === growPlan.recipe_id && recipe.version === growPlan.recipe_version)
      let foundRaftType = RaftTypes.find((raftType) => raftType.id === growPlan.raft_type_id)
      const hourDuration = 60 * 60
      const dayDuration = hourDuration * 24
  
      let totalDurationDisplay = ""
      if (foundRecipe !== undefined)  {
        
        const totalDays = Math.floor(foundRecipe.expected_duration  / dayDuration)
        const remainingHours = (foundRecipe.expected_duration  - (totalDays * dayDuration)) / hourDuration
  
        if (totalDays !== 0) {
          totalDurationDisplay += totalDays.toString() + "d"
          if (remainingHours !== 0)  {
            totalDurationDisplay += " " + remainingHours.toString() + "h"
          }
        }else {
          totalDurationDisplay += remainingHours.toString() + "h"
        }
      }


      const onRowPullOutClicked = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (onGrowPlanSelection !== undefined)  {
          onGrowPlanSelection(growPlan)
        }
      }

      currentGrowPlansList[growPlan.id] = {
        data: growPlan,
        columns: {
          select: {
            render: (<div className="StandardList-Row-Column SelectGrowPlans-RowPullOut">
              <div className="StandardList-Row-Column-Button SelectGrowPlans-RowPullOut-Button" onClick={onRowPullOutClicked}>
                <FaPlus/>
              </div>
            </div>),
          },
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{growPlan.name}</div>
              <div className="StandardList-Row-Column-Secondary">#GP{growPlan.uid}</div>
            </div>),
          },
          plantType: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{foundPlantType ? foundPlantType.name : "n/a"}</div>
              <div className="StandardList-Row-Column-Secondary">{foundPlantVariety ? foundPlantVariety.name : ""}</div>
            </div>),
          },
          recipe: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{foundRecipe ? foundRecipe.name : "n/a"}</div>
              <div className="StandardList-Row-Column-Secondary">{foundRecipe ? "v" + foundRecipe.version + ", " + FormatDate(new Date(foundRecipe.modified_on), "MM/d/yyyy"): ""}</div>
            </div>),
          },
          durationAndYield: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{totalDurationDisplay}</div>
              <div className="StandardList-Row-Column-Secondary">{growPlan.estimated_yield}{growPlan.yield_unit_type === "weight" ? "kg" : (growPlan.yield_unit_type === "plant" ? " plants" : "")}</div>
            </div>),
          },
          raftType: {
            render: (<div className="StandardList-Row-Column"><Raft raftType={foundRaftType}/></div>),
          },
          deleteFunction: {
            render: (<div className="StandardList-Row-Column">
              <FaTrashAlt className="StandardList-Row-Column-Trash"/>
            </div>)
          },
        },
        groupKey: "all"
      }

      
      if (!allowMultiselect)  {
        if (currentSelectedGrowPlan !== undefined && currentSelectedGrowPlan.id === growPlan.id) {
          currentGrowPlansList[growPlan.id].selected = true
        }
      }else {
        if (currentSelectedGrowPlans.find((gP) => gP.id === growPlan.id) !== undefined) {
          currentGrowPlansList[growPlan.id].selected = true
        }
      }
    }
    SetGrowPlansList(currentGrowPlansList)
  }, [growPlans, recipes, currentSearchInput, currentSelectedGrowPlan, currentSelectedGrowPlans, matchesRecipeId])




  let columns = {}
  if (pullOutSelectedItems) {
    columns = {...columns, 
      select: {render: (<div className="StandardList-Header-Column"></div>), width: 5, sort_by: false},
    }
  }
  columns = {...columns, 
    name: {render: (<div className="StandardList-Header-Column">Name</div>), minWidth: 10, width: 15, maxWidth: 40, resizable: true, sort_by: false},
    plantType: {render: (<div className="StandardList-Header-Column">Plant Type</div>), minWidth: 10, width: 15, maxWidth: 40, resizable: true, sort_by: false},
    recipe: {render: (<div className="StandardList-Header-Column">Recipe</div>), minWidth: 10, width: 20, maxWidth: 50, resizable: true, sort_by: false},
    durationAndYield: {render: (<div className="StandardList-Header-Column">D&Y</div>), minWidth: 6, width: 8, maxWidth: 20, resizable: true, sort_by: false},
    raftType: {render: (<div className="StandardList-Header-Column">Raft</div>), minWidth: 20, width: "grow", resizable: false},
  }

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }


  const rowSelected = (growPlan) => {
    if (!allowMultiselect)  {
      SetSelectedGrowPlan(growPlan.data)
      if (onGrowPlanSelection !== undefined) {
        onGrowPlanSelection(growPlan.data)
      }
    }else {
      let foundGrowPlan = currentSelectedGrowPlans.find((gP) => gP.id === growPlan.id)
      if (foundGrowPlan === undefined)  {
        currentSelectedGrowPlans.push(growPlan)
      }else {
        currentSelectedGrowPlans.pop(currentSelectedGrowPlans.indexOf(foundGrowPlan))
      }
      SetSelectedGrowPlans([...currentSelectedGrowPlans])

      if (onGrowPlansSelection !== undefined) {
        onGrowPlansSelection(currentSelectedGrowPlans)
      }
    }
  }


  let standardListProps = {}
  if (!pullOutSelectedItems) {
    standardListProps.onRowClicked = rowSelected
  }
  return (
    <>
      <div id="SelectGrowPlans">
        <StandardList 
          columns={columns} 
          showHeader={true}
          columnSpacing={10}
          rows={growPlansList}
          isMultiSelectable={allowMultiselect}
          isSearchable={true} 
          onSearchInput={onSearchInput}
          loadingData={loadingGrowPlans}
          {...standardListProps}/>
      </div>
    </>
  )
} 



SelectGrowPlans.defaultProps = {
  allowMultiselect: false,
  matchesRecipeId: false
}

export default SelectGrowPlans