import './ProgressBar.scss';
import React from 'react'

const ProgressBar = React.forwardRef(({content, subContent, fill, border, 
    completedBarFill, completedBarBorder, 
    currentBarFill, currentBarBorder, 
    incompletedBarFill, incompletedBarBorder, 
    numberOfSteps, space, currentStep, size, barHeight}, ref) => {
  
  const barContainerProps = {style:{borderColor:border, backgroundColor: fill, minHeight: barHeight, gap: space}}
  
  return (
    <div 
      ref={ref} 
      className={"ProgressBar ProgressBar-" + size}>
      <div className="ProgressBar-BarContainer" {...barContainerProps}>
        {new Array(numberOfSteps).fill(0).map((_, index) => {

          const barProps = {style:{flex: 1 / numberOfSteps}}
          if (index + 1 < currentStep)  {
            barProps.style.borderColor = completedBarBorder
            barProps.style.backgroundColor = completedBarFill
          }else if (index + 1 === currentStep)  {
            barProps.style.borderColor = currentBarBorder
            barProps.style.backgroundColor = currentBarFill
            
          }else if (index + 1 > currentStep)  {
            barProps.style.borderColor = incompletedBarBorder
            barProps.style.backgroundColor = incompletedBarFill
          }

          return (
            <div className={"ProgressBar-Bar" + ((index + 1) < currentStep ? " ProgressBar-Bar-Completed" : "") + ((index + 1) === currentStep ? " ProgressBar-Bar-CurrentStep" : "") + ((index + 1) > currentStep ? " ProgressBar-Bar-Incomplete" : "")} {...barProps}></div>
          )
        })}
      </div>
      {subContent && <div className="ProgressBar-SubContent noselect">
        {subContent}
      </div>}
    </div>
  )
})

ProgressBar.defaultProps = {
  content: "",
  subContent: false,
  fill: "transparent",
  border: "transparent",
  completedBarFill: "#C4CAD5",
  completedBarBorder: "transparent",
  currentBarFill: "#2E72D2",
  currentBarBorder: "transparent",
  incompletedBarFill: "#FFF",
  incompletedBarBorder: "transparent",
  numberOfSteps: 4,
  currentStep: 1,
  space: 10,
  barHeight: 15,
  size: "Medium",
}


export default ProgressBar