import './SettingsPage.scss';
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';
import Pages from './../../pages/PageList';




const SettingsPage = ({pageHeaderHelper}) => {
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Settings")
  }, [])

  return (
    <div className="PageWrapper_Standard">
      <TabControl tabs={
        [new TabControlTab("Account", "account", true),
        new TabControlTab("Company", "company"),
        new TabControlTab("Users", "users"),
        new TabControlTab("Plans and Billing", "billing"),
        new TabControlTab("Farms", "farms"),
        new TabControlTab("Store", "store")]}
        content={
          <Routes>
            <Route exact path="/account" element={
              <Pages.Settings_AccountPage/>
            }/>
            <Route path="/company" element={
              <Pages.Settings_CompanyPage/>
            }/>
            <Route path="/users" element={
              <Pages.Settings_UsersPage/>
            }/>
            <Route path="/billing" element={
              <Pages.Settings_BillingPage/>
            }/>
            <Route path="/farms" element={
              <Pages.Settings_FarmsPage/>
            }/>
            <Route path="/store" element={
              <Pages.Settings_StorePage/>
            }/>

            <Route path="*" element={<Navigate to="account" replace />} />
          </Routes>
        }/>
    </div>
  )
} 

export default SettingsPage