import './GrowZones.scss';
import React from 'react'
import { GridTile } from '../../../components/GridTile';
import { useDispatch, useSelector } from 'react-redux';
import { getVerticalRackAnalyticsData, getVerticalRackConfigurationMap, initializeComponentForAnalyticsData, selectAllVerticalRackConfigurationMaps, selectAllVerticalRackGroups, selectAllVerticalRackZoneConfigurationMaps } from '../../../redux/entities/service/VerticalRackGroup';
import { selectAllChartAxisTypes, selectAllDataRecordingTimePeriodTypes } from '../../../redux/AppInfo';
import { AutoCursorModes, AxisTickStrategies, ColorRGBA, DataPatterns, EmptyFill, emptyLine, emptyTick, FontSettings, lightningChart, SolidFill, SolidLine, Themes, UIElementBuilders } from '@arction/lcjs';
import { FormatDate, FormatTime, RoundedDateToNearestMinute, useMeasure } from '../../../helpers';
import GroupedOptions from '../../../components/input/GroupedOptions';



const mainChartTheme = {...Themes.lightNew,
  seriesBackgroundFillStyle: new SolidFill({
      color: ColorRGBA( 255, 255, 255, 255 )
  }),


  seriesBackgroundStrokeStyle: emptyLine, 

  backgroundFillStyle: new SolidFill({
      color: ColorRGBA( 255, 255, 255, 255 )
  }),

  backgroundStrokeStyle: emptyLine,
  panelBackgroundFillStyle: new SolidFill({
      color: ColorRGBA( 255, 255, 255, 255 )
  }),

  dateTimeTickStrategy: Themes.lightNew.dateTimeTickStrategy
  
  .setMajorTickStyle((majorTicks) =>
    majorTicks
      .setLabelFont(new FontSettings({ size: 13, style: 'bold' }))
        .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
  )
  .setMinorTickStyle((minorTicks) =>
      minorTicks
          .setLabelFont(new FontSettings({ size: 12, style: '' }))
          .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
  )
  .setGreatTickStyle((greatTicks) =>
      emptyTick 
  ),

  timeTickStrategy: Themes.lightNew.timeTickStrategy
  
  .setMajorTickStyle((majorTicks) =>
    majorTicks
      .setLabelFont(new FontSettings({ size: 13, style: 'bold' }))
        .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
  )
  .setMinorTickStyle((minorTicks) =>
      minorTicks
          .setLabelFont(new FontSettings({ size: 12, style: '' }))
          .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
  ),

  numericTickStrategy: Themes.lightNew.numericTickStrategy
  .setMajorTickStyle((majorTicks) =>
    majorTicks
      .setLabelFont(new FontSettings({ size: 12, style: '' }))
        .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
  )
  .setMinorTickStyle((minorTicks) =>
      minorTicks
          .setLabelFont(new FontSettings({ size: 12, style: '' }))
          .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
  ),
}


const GrowZoneDataAnalytics = ({selectedFacility, growOutZone}) => {

  const dispatch = useDispatch()
  
  //const facilities = useSelector(selectAllFacilities)
  const verticalRackGroups = useSelector(selectAllVerticalRackGroups)
  const [verticalRackGroup, SetVerticalRackGroup] = React.useState({})
  const [verticalRacks, SetVerticalRacks] = React.useState([])
  const verticalRackConfigurationMaps = useSelector(selectAllVerticalRackConfigurationMaps)
  const verticalRackZoneConfigurationMaps = useSelector(selectAllVerticalRackZoneConfigurationMaps)
  const [zoneConfigurationMap, SetZoneConfigurationMap] = React.useState(undefined)
  React.useEffect(() => {
    let foundVerticalRackGroup = verticalRackGroups.find((vRG) => vRG.id === growOutZone.vertical_rack_group_id)
    if (foundVerticalRackGroup !== undefined) {
      SetVerticalRackGroup(foundVerticalRackGroup)
    }
    SetZoneConfigurationMap(verticalRackZoneConfigurationMaps.find((cM) => cM.id === growOutZone.configuration_id))
  }, [selectedFacility, growOutZone, verticalRackGroups, verticalRackZoneConfigurationMaps])

  React.useEffect(() => {
    let foundVerticalRackGroup = verticalRackGroups.find((vRG) => vRG.id === growOutZone.vertical_rack_group_id)
    let foundVerticalRacks = []
    if (foundVerticalRackGroup !== undefined) {
      for (let verticalRack of foundVerticalRackGroup.rack_map.racks) {
        if (verticalRack.index === growOutZone.grow_rack_index) {
          foundVerticalRacks.push(verticalRack)
        }else if (verticalRack.index === growOutZone.environment_rack_index) {
          foundVerticalRacks.push(verticalRack)
        }
      }
    }
    SetVerticalRacks(foundVerticalRacks)
    /*if (chartRef.current !== undefined) {
      chartRef.current.verticalRackGroup = verticalRackGroup
      chartRef.current.verticalRacks = foundVerticalRacks
    }*/
  }, [verticalRackGroup])



  //Chart stuff
  const chartRef = React.useRef(undefined)
  const [activeDataSeries, SetActiveDataSeries] = React.useState({});
  const [chartOriginDate, SetChartOriginDate] = React.useState(new Date().getTime());
  const [isForceLive, SetIsForceLive] = React.useState(true);

  const energySubSelectionCanvasRef = React.useRef(undefined)
  const [energySubselectionActive, SetEnergySubselectionActive] = React.useState(false);
  const overviewSubSelectionCanvasRef = React.useRef(undefined)



  const [componentToggles, SetComponentToggles] = React.useState({
    air: { label: "Air", selected: true, dataTypes: {
        temp: { componentName: "LightingController", identifier: "cli-sp", label: "Temp", color: "rgb(51,160,44)", yAxis: "temp", selected: true, defaultSelected: true, unit: "°C"},
        rh: { label: "RH", identifier: "airrh", color: "rgb(135,125,185)", yAxis: "rh", selected: true, defaultSelected: true, resolution: 1, unit: "%"},
        vpd: { label: "VPD", identifier: "airvpd", color: "rgb(21,120,90)", yAxis: "vpd", selected: true, defaultSelected: true, resolution: 0.1, unit: "kPa"},
        cO2: { label: "cO2", identifier: "airc", color: "rgb(150,155,0)", yAxis: "co2", selected: false, defaultSelected: false, resolution: 1, unit: "ppm"},
        leafTemp: { label: "Leaf Temp", identifier: "leaft", color: "rgb(31,120,84)", yAxis: "temp", selected: false, defaultSelected: false, resolution: 0.5, unit: "°C"}
    } },
    water: { label: "Water", selected: false, dataTypes: {
        temp: { label: "Temp", identifier: "watert", color: "rgb(101,120,64)", yAxis: "temp", selected: true, defaultSelected: true, resolution: 0.5, unit: "°C"},
        pH: { label: "pH", identifier: "waterph", color: "rgb(255,0,86)", yAxis: "ph", selected: true, defaultSelected: true, resolution: 0.1, unit: ""},
        EC: { label: "EC", identifier: "waterec", color: "rgb(0,0,139)", yAxis: "ec", selected: true, defaultSelected: true, resolution: 1, unit: "S/m"},
        DO: { label: "DO", identifier: "waterdo", color: "rgb(158,0,142)", yAxis: "do", selected: false, defaultSelected: false, resolution: 1, unit: ""},
        ORP: { label: "ORP", identifier: "waterorp", color: "rgb(1,0,103)", yAxis: "orp", selected: false, defaultSelected: false, resolution: 1, unit: "mV"}
    } },
    lights: { label: "Lights", selected: false, dataTypes: {
        red: { label: "Red", identifier: "lightr", color: "rgb(255,100,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s"},
        green: { label: "Green", identifier: "lightg", color: "rgb(100,255,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s"},
        blue: { label: "Blue", identifier: "lightb", color: "rgb(100,100,255)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s"},
        farRed: { label: "Far Red", identifier: "lightfr", color: "rgb(255,150,150)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s"},
    } },
    condensate: { label: "Condensate", selected: false, dataTypes: {
        condensateVolume: { label: "Total", identifier: "condensate-t", color: "rgb(255,150,150)", yAxis: "litres", selected: true, defaultSelected: true, resolution: 0.1, unit: "L"},
        evapMetric: { label: "Evap Metric", identifier: "evap-avg-m", color: "rgb(213,255,0)", yAxis: "g/m2/s", selected: true, defaultSelected: true, resolution: 0.0001, unit: "g/m2/s"},
        evapImperial: { label: "Evap Imperial", identifier: "evap-avg-i", color: "rgb(70,130,180)", yAxis: "lbs/sqft/h", selected: true, defaultSelected: true, resolution: 0.0001, unit: "lbs/sqft/h"},
    }},
    refrigeration: { label: "Refrigeration", selected: false, dataTypes: {
        water: {label: "Water (Total)", identifier: "water", color: "rgb(0,19,56)", yAxis: "millilitres", selected: true, defaultSelected: true, resolution: 0.1, unit: "mL"},
        nutrients_total: {label: "Nutrients (Total)", identifier: "nutrients_total", color: "rgb(0, 0, 0)", yAxis: "litres", selected: true, defaultSelected: true, resolution: 0.1, unit: "L"},
        nutrients: {label: "Nutrient Doses", identifier: "nutrients", color: "rgb(0, 0, 0)", yAxis: "millilitres", selected: true, defaultSelected: true, resolution: 0.1, unit: "mL"},
    }},
    pumping_system: { label: "Pumping System", selected: false, dataTypes: {
        water: {label: "Water (Total)", identifier: "water", color: "rgb(0,19,56)", yAxis: "millilitres", selected: true, defaultSelected: true, resolution: 0.1, unit: "mL"},
        nutrients_total: {label: "Nutrients (Total)", identifier: "nutrients_total", color: "rgb(0, 0, 0)", yAxis: "litres", selected: true, defaultSelected: true, resolution: 0.1, unit: "L"},
        nutrients: {label: "Nutrient Doses", identifier: "nutrients", color: "rgb(0, 0, 0)", yAxis: "millilitres", selected: true, defaultSelected: true, resolution: 0.1, unit: "mL"},
    }},
    rack_motion: { label: "Rack Motion", selected: false, dataTypes: {
      temp: { componentName: "RackMotionController", identifier: "current_distance", label: "Distance", color: "rgb(0, 0, 0)", yAxis: "mm", selected: true, defaultSelected: true, unit: "mm"},
      nutrients_total: {label: "Nutrients (Total)", identifier: "nutrients_total", color: "rgb(0, 0, 0)", yAxis: "litres", selected: true, defaultSelected: true, resolution: 0.1, unit: "L"},
        nutrients: {label: "Nutrient Doses", identifier: "nutrients", color: "rgb(0, 0, 0)", yAxis: "millilitres", selected: true, defaultSelected: true, resolution: 0.1, unit: "mL"},
    }},
    energy: {label: "Energy", selected: false,dataTypes: {
        power: {label: "Power", identifier: "power", isEnergyData: true, color: "rgb(21,120,90)", yAxis: "kilowatt", selected: true, defaultSelected: true, resolution: 0.5, unit: "kW"},
        //cost: {label: "Cost", identifier: "cost", color: "rgb(21,120,90)", yAxis: "cost", selected: false,defaultSelected: true},
        current: {label: "Current",identifier: "cur", color: "rgb(21,120,90)", yAxis: "current", selected: false,defaultSelected: true},
    }}
});


const componentToggled = React.useCallback((componentName, selected) => {
  componentToggles[componentName].selected = selected
  SetComponentToggles({...componentToggles})
})
const componentDataTypeToggled = React.useCallback((dataType, selected) => {
  dataType.selected = selected
  SetComponentToggles({...componentToggles})
})


  const haveAppInfo = useSelector((state) => state.appInfo.haveAppInfo)
  const dataRecordingTimePeriodTypes = useSelector(selectAllDataRecordingTimePeriodTypes)
  const chartAxisTypes = useSelector(selectAllChartAxisTypes)

  const getMainChartInterval = () => {
    let interval = chartRef.current.mainChartDateAxis.getInterval()
    interval.start += chartOriginDate
    interval.end += chartOriginDate
    return interval
  }

  const getMainDataRecordingTimePeriodType = () =>    {
    const mainChartVisibleRange = getMainChartInterval()
    const timeDelta = mainChartVisibleRange.end - mainChartVisibleRange.start
    
    let lastDataRecordingTimePeriodType = null
    for (let dataRecordingTimePeriodType of dataRecordingTimePeriodTypes)   {
        if (lastDataRecordingTimePeriodType === null)   {
            if (timeDelta < dataRecordingTimePeriodType.duration * 1000)   {
                return dataRecordingTimePeriodType
            }
        }else {
            if (timeDelta >= lastDataRecordingTimePeriodType.duration * 1000 && timeDelta <= dataRecordingTimePeriodType.duration * 1000)   {
                return dataRecordingTimePeriodType
            }
        }
        lastDataRecordingTimePeriodType = dataRecordingTimePeriodType
    }
    return lastDataRecordingTimePeriodType
  }

  const getOverviewChartInterval = () => {
    let interval = chartRef.current.overviewChartDateAxis.getInterval()
    interval.start += chartOriginDate
    interval.end += chartOriginDate
    return interval
  }

  const getOverviewDataRecordingTimePeriodType = () =>  {
    const overviewChartVisibleRange = getOverviewChartInterval()
    const timeDelta = overviewChartVisibleRange.end - overviewChartVisibleRange.start
    
    let lastDataRecordingTimePeriodType = null
    for (let dataRecordingTimePeriodType of dataRecordingTimePeriodTypes)   {
        if (lastDataRecordingTimePeriodType === null)   {
            if (timeDelta < dataRecordingTimePeriodType.duration * 1000)   {
                return dataRecordingTimePeriodType
            }
        }else {
            if (timeDelta >= lastDataRecordingTimePeriodType.duration * 1000 && timeDelta <= dataRecordingTimePeriodType.duration * 1000)   {
                return dataRecordingTimePeriodType
            }
        }
        lastDataRecordingTimePeriodType = dataRecordingTimePeriodType
    }
    return lastDataRecordingTimePeriodType
  }

  const chartLoop = () =>  {
    if (!chartRef.current) 
        return

    const usingSingleDataset = false
    let activeDataSeriesUpdated = false

    if (haveAppInfo)   {
        
        const mainChartVisibleRange = getMainChartInterval()
        const overviewChartVisibleRange = getOverviewChartInterval()
        const mainDataRecordingTimePeriodType = getMainDataRecordingTimePeriodType()
        const overviewDataRecordingTimePeriodType = getOverviewDataRecordingTimePeriodType()

        const [mainChartDeltaType, mainChartMinorDelta, mainChartMajorDelta] = getTimeDeltaType(mainChartVisibleRange.end - mainChartVisibleRange.start)

        //Make sure all y axes are properly loaded in
        //selectChartAxisTypeByIdentifier
        let currentRequiredYAxes = []
        for (const componentGroupName in componentToggles) {
            const componentGroup = componentToggles[componentGroupName]
            if (componentGroup.selected) {
                for (const dataType in componentGroup.dataTypes) {
                    const dataTypeInfo = componentGroup.dataTypes[dataType]
                    if (dataTypeInfo.yAxis !== undefined && dataTypeInfo.selected && currentRequiredYAxes.indexOf(dataTypeInfo.yAxis) === -1)    {
                        currentRequiredYAxes.push(dataTypeInfo.yAxis)
                    }
                }
            }
        }
        for (const yAxisIdentifier of currentRequiredYAxes) {
            if (chartRef.current.activeYAxes[yAxisIdentifier] === undefined)    {
                //YAxis is missing, lets create it
                const yAxisInfo = chartAxisTypes.find(type => type.identifier === yAxisIdentifier)
                if (yAxisInfo !== undefined)    {
                    chartRef.current.activeYAxes[yAxisIdentifier] = {}
                    chartRef.current.activeYAxes[yAxisIdentifier].main = chartRef.current.mainChart.addAxisY()
                    chartRef.current.activeYAxes[yAxisIdentifier].main.setInterval(yAxisInfo.min , yAxisInfo.max, false, false)
                        .setMouseInteractions(false)
                        .setTickStrategy(AxisTickStrategies.Empty)
                        .setScrollStrategy(undefined)
                        .setStrokeStyle(emptyLine)
                        .onScaleChange((start, end) => {
                            let min = (chartRef.current.tempYAxisInterval[yAxisIdentifier] !== undefined) ? chartRef.current.tempYAxisInterval[yAxisIdentifier].min : yAxisInfo.min
                            let max = (chartRef.current.tempYAxisInterval[yAxisIdentifier] !== undefined) ? chartRef.current.tempYAxisInterval[yAxisIdentifier].max : yAxisInfo.max

                            if (start !== min || end !== max)  {
                                chartRef.current.activeYAxes[yAxisIdentifier].main.setInterval(min, max, false, false)
                            }
                        })
                    chartRef.current.activeYAxes[yAxisIdentifier].overview = chartRef.current.overviewChart.addAxisY()
                    chartRef.current.activeYAxes[yAxisIdentifier].overview.setInterval(yAxisInfo.min , yAxisInfo.max, false, false)
                        .setMouseInteractions(false)
                        .setTickStrategy(AxisTickStrategies.Empty)
                        .setScrollStrategy(undefined)
                        .setStrokeStyle(emptyLine)
                        .onScaleChange((start, end) => {
                            if (start !== yAxisInfo.min || end !== yAxisInfo.max)  {
                                chartRef.current.activeYAxes[yAxisIdentifier].overview.setInterval(yAxisInfo.min, yAxisInfo.max, false, false)
                            }
                        })
                }
            }
        }
        for (const yAxisIdentifier in chartRef.current.activeYAxes) {
            //Check that we require all of the ones here
            if (currentRequiredYAxes.indexOf(yAxisIdentifier) === -1)   {
                //We need to remove this one
                chartRef.current.activeYAxes[yAxisIdentifier].main.dispose()
                chartRef.current.activeYAxes[yAxisIdentifier].overview.dispose()
                delete  chartRef.current.activeYAxes[yAxisIdentifier]
            }
        }



    
        if (chartRef.current.dataSeries === undefined)    {
            chartRef.current.dataSeries= {}
            //activeDataSeriesUpdated = true
        }

        

        for (const componentGroupName in componentToggles) {
          const componentGroup = componentToggles[componentGroupName]
          for (const dataType in componentGroup.dataTypes) {
            const dataTypeInfo = componentGroup.dataTypes[dataType]
            let identifier = dataTypeInfo.identifier

            let foundZoneComponents = growOutZone.components.filter((c) => c.componentInfo.name === dataTypeInfo.componentName)
            for (let zoneComponent of foundZoneComponents)  {
              let verticalRack = verticalRacks.find((vR) => vR.id === zoneComponent.rackId)
              if (verticalRack !== undefined) {
                
                if (componentGroup.selected && dataTypeInfo.selected)   {
                  if (chartRef.current.activeYAxes[dataTypeInfo.yAxis] !== undefined) {
                    
                    const lineColor = dataTypeInfo.color.replace("rgb(", '').replace(")", '').split(',')
                    
                    // Validate that this series is added
                    if (chartRef.current.dataSeries[zoneComponent.componentInfo.id] === undefined)  {
                      chartRef.current.dataSeries[zoneComponent.componentInfo.id] = {}
                    }
                    if (chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier] === undefined)    {
                      chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier] = { 
                          main: chartRef.current.mainChart.addLineSeries({
                              dataPattern: DataPatterns.HorizontalProgressivePattern,
                              yAxis: chartRef.current.activeYAxes[dataTypeInfo.yAxis].main
                              }).setStrokeStyle(new SolidLine({ thickness: 0.5, fillStyle: new SolidFill({color: ColorRGBA(...lineColor)})}))
                              //}).setStrokeStyle(new SolidLine({ thickness: 0.5, color: ColorRGBA(lineColor)}))
                              .setMouseInteractions(false),
                          overview: chartRef.current.overviewChart.addLineSeries({
                              dataPattern: DataPatterns.HorizontalProgressivePattern,
                              yAxis: chartRef.current.activeYAxes[dataTypeInfo.yAxis].overview
                              }).setStrokeStyle(new SolidLine({ thickness: 0.5, fillStyle: new SolidFill({color: ColorRGBA(...lineColor)})}))
                              .setMouseInteractions(false),
                          mainVersion: -1,
                          overviewVersion: -1,
                          changedVersion: -1,
                          lastLiveUpdateOn: new Date()
                      }                           
                    }

                    let analyticsData = verticalRack.analyticsData[zoneComponent.componentInfo.id]
                    if (analyticsData !== undefined)   {
                      //console.log(verticalRack, analyticsData)
                      if (dataTypeInfo.isEnergyData !== true) {
                        if (analyticsData[mainDataRecordingTimePeriodType.id] !== undefined)    {
                          if (analyticsData[mainDataRecordingTimePeriodType.id].data[identifier] !== undefined)    {
                            //console.log(analyticsData[mainDataRecordingTimePeriodType.id].data[identifier])
                            if (analyticsData[mainDataRecordingTimePeriodType.id].changedVersion > chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].mainVersion || chartRef.current.lastMainDataRecordingTimePeriodType != mainDataRecordingTimePeriodType)  {
                              chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].mainVersion = analyticsData[mainDataRecordingTimePeriodType.id].changedVersion
                              
                              let newData = []
                              if (analyticsData[mainDataRecordingTimePeriodType.id].data[identifier].length > 0)  {
                                  newData = [...analyticsData[mainDataRecordingTimePeriodType.id].data[identifier], {
                                      x: (mainChartVisibleRange.end),
                                      y: analyticsData[mainDataRecordingTimePeriodType.id].data[identifier][analyticsData[mainDataRecordingTimePeriodType.id].data[identifier].length - 1].y
                                  }]
                              }
                              chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].main.clear().add(newData)

                              chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].lastLiveUpdateOn = new Date()
                            }

                              
                          }
                        }
                    
                        if (analyticsData[overviewDataRecordingTimePeriodType.id] !== undefined)    {
                          if (analyticsData[overviewDataRecordingTimePeriodType.id].data[identifier] !== undefined)    {
                            if (analyticsData[overviewDataRecordingTimePeriodType.id].changedVersion > chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overviewVersion)  {
                                chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overviewVersion = analyticsData[overviewDataRecordingTimePeriodType.id].changedVersion                                                            
                                chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overview.clear().add(analyticsData[overviewDataRecordingTimePeriodType.id].data[identifier]);                             
                            }                             
                          }
                        }
                      }


                    }else {
                
                      if (analyticsData[mainDataRecordingTimePeriodType.id] !== undefined)    {
                        if (analyticsData[mainDataRecordingTimePeriodType.id].energyData[identifier] !== undefined)    {
                          if (analyticsData[mainDataRecordingTimePeriodType.id].changedEnergyVersion > chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].mainVersion || chartRef.current.lastMainDataRecordingTimePeriodType != mainDataRecordingTimePeriodType)  {
                              chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].mainVersion = analyticsData[mainDataRecordingTimePeriodType.id].changedEnergyVersion
                              chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].main.clear().add(analyticsData[mainDataRecordingTimePeriodType.id].energyData[identifier]);   
                          }
                        }
                      }
                      if (analyticsData[overviewDataRecordingTimePeriodType.id] !== undefined)    {
                        if (analyticsData[overviewDataRecordingTimePeriodType.id].energyData[identifier] !== undefined)    {
                          if (analyticsData[overviewDataRecordingTimePeriodType.id].changedEnergyVersion > chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overviewVersion)  {
                              chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overviewVersion = analyticsData[overviewDataRecordingTimePeriodType.id].changedEnergyVersion
                              chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overview.clear().add(analyticsData[overviewDataRecordingTimePeriodType.id].energyData[identifier]);                             
                          }                             
                        }
                      }
                    }
                  }

                }else   {
                  // Validate that this series is removed
                  /*if (chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier] !== undefined)    {
                    chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].main.dispose()
                    chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overview.dispose()
                    delete chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier]
                  }*/
                }
              }
            }
          }
        }


        
        
        /*let energyNeedsUpdating = true
        let energyRange = {start: 0, end : 0}
        if (chartRef.current.energySubselectionActive)   {
            energyRange.start = chartRef.current.energySubSelection.start
            energyRange.end = chartRef.current.energySubSelection.end
        }else {
            energyRange.start = mainChartVisibleRange.start
            energyRange.end = mainChartVisibleRange.end
        }

        if (chartRef.current.energySubSelection.start != chartRef.current.lastEnergySubSelection.start || chartRef.current.energySubSelection.end != chartRef.current.lastEnergySubSelection.end)   {


        }
        if (energyNeedsUpdating)    {
            chartRef.current.energyTotals[grow.id] = {power: 0, cost: 0}
            
            if (usingSingleDataset) {
                if (grow.analyticsData.energyDataTypes !== undefined) {
                    if (grow.analyticsData.energyDataTypes["power"] !== undefined) {
                        //.data has the current data
                        for (let dataItem of grow.analyticsData.energyDataTypes["power"].data)  {
                            //console.log(grow.analyticsData.energyDataTypes["power"].data)
                        }
                    }
                }
            }else {
                if (grow.analyticsData.timePeriods !== undefined)   {
                    if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index] !== undefined)    {


                        if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData["power"] !== undefined)    {
                            let lastDataItem = null;
                            for (let dataItem of grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData["power"])  {
                                if (lastDataItem != null)   {
                                    if (dataItem.x >= energyRange.start && dataItem.x <= energyRange.end)   {
                                        const timeAmount = (dataItem.x - lastDataItem.x) / 1000
                                        if (timeAmount != 0 && dataItem.y != 0)    {
                                            chartRef.current.energyTotals[grow.id].power += dataItem.y / (3600 / timeAmount)
                                        }
                                    }
                                }
                                lastDataItem = dataItem
                            }
                        }
                        if (grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData["cost"] !== undefined)    {
                            for (let dataItem of grow.analyticsData.timePeriods[mainDataRecordingTimePeriodType.index].energyData["cost"])  {
                                
                                if (dataItem.x >= energyRange.start && dataItem.x <= energyRange.end)   {
                                    chartRef.current.energyTotals[grow.id].cost += dataItem.y / 100
                                }
                            }
                        }
                    }
                }                               
            }
        }*/

        
        /*delete chartRef.current.energyTotals[grow.id]
        //Make sure this grow doesn't exist in active data series
        if (chartRef.current.dataSeries[grow.id] !== undefined)    {
            for (let identifier in chartRef.current.dataSeries[grow.id])    {
                if (identifier === "nutrients") {
                    chartRef.current.dataSeries[grow.id][identifier].main.clear()
                }
                chartRef.current.dataSeries[grow.id][identifier].main.dispose()
                chartRef.current.dataSeries[grow.id][identifier].overview.dispose()
                delete chartRef.current.dataSeries[grow.id][identifier]
            }
            delete chartRef.current.dataSeries[grow.id]
        }*/
        chartRef.current.lastMainDataRecordingTimePeriodType = mainDataRecordingTimePeriodType
        chartRef.current.lastMainChartDeltaType = mainChartDeltaType
    }



    

    //Handle current position of energy subselection 
    /*chartRef.current.lastEnergySubSelection.start = chartRef.current.energySubSelection.start
    chartRef.current.lastEnergySubSelection.end = chartRef.current.energySubSelection.end
    
    if (energyDataTypeToggleDataUpdate.current !== undefined)   {
        energyDataTypeToggleDataUpdate.current(chartRef.current.energyTotals)
    }*/


    chartRef.current.lastRenderLoopCompletedOn = new Date().getTime()
  }


  const requestLoop = React.useCallback(() =>  {
    if (!chartRef.current) 
        return


    if (haveAppInfo && zoneConfigurationMap !== undefined)   {
      let analyticsDataRequestEntries = {}


      let rackConfigurationMapsToLoad = {}
      //Make sure we have loaded all the matching rack component info
      for (let zoneComponent of zoneConfigurationMap.component_map.components)  {
        let foundZoneComponent = growOutZone.components.find((growOutZoneComponent) => growOutZoneComponent.id === zoneComponent.id)
        if (foundZoneComponent === undefined) {
          for (let verticalRack of verticalRacks) {
            if ((zoneComponent.rack_type === "grow" && verticalRack.index === growOutZone.grow_rack_index) || (zoneComponent.rack_type === "environment" && verticalRack.index === growOutZone.environment_rack_index)) {
              let foundVerticalRackConfigurationMap = verticalRackConfigurationMaps.find((cM) => cM.id === verticalRack.configuration_id)
              if (foundVerticalRackConfigurationMap === undefined)  {
                //Configuration for rack is missing, lets add to request list
                if (rackConfigurationMapsToLoad[verticalRack.configuration_id] === undefined)  {
                  rackConfigurationMapsToLoad[verticalRack.configuration_id] = {"component_map": null}
                }
              }else {
                //Time to find the matching component
                for (let rackComponent of foundVerticalRackConfigurationMap.component_map.components) {
                  //console.log(rackComponent, zoneComponent)
                  if (rackComponent.name === zoneComponent.name && rackComponent.index === zoneComponent.index) {
                    dispatch(initializeComponentForAnalyticsData({
                      verticalRackGroupId: verticalRackGroup.id, 
                      verticalRackId: verticalRack.id, 
                      dataRecordingTimePeriodTypes: dataRecordingTimePeriodTypes,
                      growOutZoneId: growOutZone.id,
                      componentInfo: rackComponent,
                      rackComponentId: rackComponent.id,
                      zoneComponentId: zoneComponent.id
                    }))
                    break //found matching component
                  }
                } 
              }
              break //found matching rack
            }
          }
        }
      }
      

      const overviewDataRecordingTimePeriodType = getOverviewDataRecordingTimePeriodType()
      const overviewChartVisibleRange = getOverviewChartInterval()

      const mainDataRecordingTimePeriodType = getMainDataRecordingTimePeriodType()
      const mainChartVisibleRange = getMainChartInterval()

      const addComponentRequest = (timePeriodTypeId, requestFrom, requestTo, rackId, componentId) =>   {
        let requestKey = requestFrom + "-" + requestTo
        if (analyticsDataRequestEntries[timePeriodTypeId] === undefined)  {
          analyticsDataRequestEntries[timePeriodTypeId] = {}
        }
        if (analyticsDataRequestEntries[timePeriodTypeId][requestKey] === undefined)  {
          analyticsDataRequestEntries[timePeriodTypeId][requestKey] = {}
        }
        if (analyticsDataRequestEntries[timePeriodTypeId][requestKey][rackId] === undefined)  {
          analyticsDataRequestEntries[timePeriodTypeId][requestKey][rackId] = []
        }
        if (analyticsDataRequestEntries[timePeriodTypeId][requestKey][rackId].indexOf(componentId) === -1)  {
          analyticsDataRequestEntries[timePeriodTypeId][requestKey][rackId].push(componentId)
        } 
      }

      for (const componentGroupName in componentToggles) {
        const componentGroup = componentToggles[componentGroupName]
        for (const dataType in componentGroup.dataTypes) {
          const dataTypeInfo = componentGroup.dataTypes[dataType]
          let identifier = dataTypeInfo.identifier

          if (componentGroup.selected && dataTypeInfo.selected)   {
            let foundZoneComponents = growOutZone.components.filter((c) => c.componentInfo.name === dataTypeInfo.componentName)
            for (let zoneComponent of foundZoneComponents)  {

              //Get the relating analytics component from the rack
              let foundVerticalRack = verticalRacks.find((vR) => vR.id === zoneComponent.rackId)
              if (foundVerticalRack !== undefined && foundVerticalRack.analyticsData[zoneComponent.componentInfo.id] !== undefined)  {
                let analyticsData = foundVerticalRack.analyticsData[zoneComponent.componentInfo.id]
                let mainAnalyticsData = analyticsData[mainDataRecordingTimePeriodType.id]
                let overviewAnalyticsData = analyticsData[overviewDataRecordingTimePeriodType.id]
                //Check if we can load overview data
                if (overviewAnalyticsData.loadingStatus == "idle" || overviewAnalyticsData.loadingStatus == "fulfilled")  {
                  let overviewFromEntryIndex = Math.floor((overviewChartVisibleRange.start / 1000) / (overviewDataRecordingTimePeriodType.duration))
                  let overviewToEntryIndex = Math.floor((overviewChartVisibleRange.end / 1000) / (overviewDataRecordingTimePeriodType.duration))
                  //console.log(overviewAnalyticsData.dataChunks)
                  for (let entryIndex = overviewFromEntryIndex; entryIndex <= overviewToEntryIndex; entryIndex++)    {
                    if (overviewAnalyticsData.dataChunks[entryIndex] !== undefined)  {
                      //console.log(overviewAnalyticsData.dataChunks[entryIndex])
                        if (!overviewAnalyticsData.dataChunks[entryIndex].completed) {
                            //Lets build a request for the portion of data we don't have
                            let requestRange = {"from": overviewAnalyticsData.haveDataUpUntil + 1, "to": (entryIndex + 1) * overviewDataRecordingTimePeriodType.duration * 1000}
                            if (requestRange["to"] - requestRange["from"] > 1000)   { //minimum request range
                              //addComponentRequest(overviewDataRecordingTimePeriodType.id, requestRange["from"], requestRange["to"], zoneComponent.rackId, zoneComponent.componentInfo.id)
                            }
                        }
                    }else {
                      addComponentRequest(overviewDataRecordingTimePeriodType.id, overviewChartVisibleRange.start, overviewChartVisibleRange.end, zoneComponent.rackId, zoneComponent.componentInfo.id)
                    }
                  }
                }
                if (overviewDataRecordingTimePeriodType != mainDataRecordingTimePeriodType) {
                  //Check if we can load main data
                  if (mainAnalyticsData.loadingStatus == "idle" || mainAnalyticsData.loadingStatus == "fulfilled")  {
                    let mainFromEntryIndex = Math.floor((mainChartVisibleRange.start / 1000) / (mainDataRecordingTimePeriodType.duration))
                    let mainToEntryIndex = Math.floor((mainChartVisibleRange.end / 1000) / (mainDataRecordingTimePeriodType.duration))
                    

                    //console.log(mainAnalyticsData.dataChunks)
                    for (let entryIndex = mainFromEntryIndex; entryIndex <= mainToEntryIndex; entryIndex++)    {
                      if (mainAnalyticsData.dataChunks[entryIndex] !== undefined)  {
                        //console.log(mainAnalyticsData.dataChunks[entryIndex])
                           if (!mainAnalyticsData.dataChunks[entryIndex].completed) {
                              //Lets build a request for the portion of data we don't have
                              let requestRange = {"from": mainAnalyticsData.haveDataUpUntil + 1, "to": (entryIndex + 1) * mainDataRecordingTimePeriodType.duration * 1000}
                              if (requestRange["to"] - requestRange["from"] > 1000)   { //minimum request range
                                addComponentRequest(mainDataRecordingTimePeriodType.id, requestRange["from"], requestRange["to"], zoneComponent.rackId, zoneComponent.componentInfo.id)
                              }  
                          }
                      }else {
                        addComponentRequest(mainDataRecordingTimePeriodType.id, mainChartVisibleRange.start, mainChartVisibleRange.end, zoneComponent.rackId, zoneComponent.componentInfo.id)
                      }
                    }
                  }
                }
              }

            }
          }
        }
      }

            

              

      if (Object.entries(rackConfigurationMapsToLoad).length > 0)  {
        dispatch(getVerticalRackConfigurationMap({maps: rackConfigurationMapsToLoad}))
      }
      if (Object.entries(analyticsDataRequestEntries).length > 0) {
        console.log(analyticsDataRequestEntries)
        dispatch(getVerticalRackAnalyticsData({entries: analyticsDataRequestEntries, timePeriodTypes: dataRecordingTimePeriodTypes, dateOffset: chartOriginDate}))
      }
    }

    chartRef.current.lastRequestLoopCompletedOn = new Date().getTime()
  })

  React.useEffect(() => {
      const chartInterval = setInterval(() => {
        if (chartRef.current.lastRenderLoopCompletedOn + 50 < new Date().getTime()) {
            chartLoop()
        }
      }, 1);
      //chartLoop()

      const liveInterval = setInterval(() => {
        if (isForceLive)  {
          let newEndDate = new Date().getTime() - chartOriginDate
          let mainChartInterval = chartRef.current.mainChartDateAxis.getInterval()
          let overviewChartInterval = chartRef.current.overviewChartDateAxis.getInterval()
          chartRef.current.mainChartDateAxis.setInterval(mainChartInterval.start + (newEndDate - mainChartInterval.end), newEndDate, false, false)
          chartRef.current.overviewChartDateAxis.setInterval(overviewChartInterval.start + (newEndDate - overviewChartInterval.end), newEndDate, false, false)
        }
      }, 50)

      const requestInterval = setInterval(() => {
          if (chartRef.current.lastRequestLoopCompletedOn + 1000 < new Date().getTime()) {
              requestLoop()
          }
      }, 1);


      //requestLoop()
      

      return () => {
          clearInterval(chartInterval)
          clearInterval(liveInterval)
          clearInterval(requestInterval)
      };
  }, [haveAppInfo, activeDataSeries, chartRef, verticalRackGroups, verticalRackGroup, verticalRacks, growOutZone, zoneConfigurationMap, verticalRackConfigurationMaps]);


  
  let processingMainChartInterval = false
  let processingOverviewChartInterval = false

  const getTimeDeltaType = (timeDelta) => {
      if (timeDelta <= 1000 * 60 * 1)
          return ["minute", 1000 * 5, 1000 * 60]
      if (timeDelta <= 1000 * 60 * 5)
          return ["minute5", 1000 * 10, 1000 * 60]
      if (timeDelta <= 1000 * 60 * 10)
          return ["minute10", 1000 * 30, 1000 * 60 * 5]
      if (timeDelta <= 1000 * 60 * 30)
          return ["minute30", 1000 * 60, 1000 * 60 * 60]
      if (timeDelta <= 1000 * 60 * 60)
          return ["hour", 1000 * 60 * 5, 1000 * 60 * 60]
      if (timeDelta <= 1000 * 60 * 60 * 3)
          return ["hour3", 1000 * 60 * 15, 1000 * 60 * 60]
      if (timeDelta <= 1000 * 60 * 60 * 6)
          return ["hour6", 1000 * 60 * 60, 1000 * 60 * 60 * 24]
      if (timeDelta <= 1000 * 60 * 60 * 12)
          return ["hour12", 1000 * 60 * 60, 1000 * 60 * 60 * 24]
      if (timeDelta <= 1000 * 60 * 60 * 24)
          return ["day", 1000 * 60 * 60 * 3, 1000 * 60 * 60 * 24]
      if (timeDelta <= 1000 * 60 * 60 * 24 * 7)
          return ["week", 1000 * 60 * 60 * 6, 1000 * 60 * 60 * 24]

      return ["month", 1000 * 60 * 60 * 24, 1000 * 60 * 60 * 24 * 7]
  }

  const createTicksInRangeX = (axis, tickList, timeDelta, start, end) => {

    //let minorTickInterval = 1000 * 60 * 60 * 24 //every day
    //let majorTickInterval = 1000 * 60 * 60 * 24 * 7 //every week

    let minorTickFormat = 'MMM dd'
    let majorTickFormat = 'MMM dd'
    let startDate = new Date(start)
    let endDate = new Date(end)
    const [deltaType, minorTickInterval, majorTickInterval] = getTimeDeltaType(timeDelta)
    if (deltaType === "minute")  { //1 minute span -- we want ticks every 30 seconds
        minorTickFormat = 'HH:mm:ss'
        majorTickFormat = 'HH:mm'
        start = RoundedDateToNearestMinute(1, startDate).getTime()

    }else if (deltaType === "minute5")  { //5 minute span -- we want ticks every 30 seconds
        minorTickFormat = 'HH:mm:ss'
        majorTickFormat = 'HH:mm'
        start = RoundedDateToNearestMinute(5, startDate).getTime()

    }else if (deltaType === "minute10")  { //10 minute span -- we want ticks every minute
        minorTickFormat = 'HH:mm:ss'
        majorTickFormat = 'HH:mm'
        start = RoundedDateToNearestMinute(5, startDate).getTime()

    }else if (deltaType === "minute30")  { //30 minute span -- we want ticks every 5 minutes
      minorTickFormat = 'HH:mm:ss'
      majorTickFormat = 'HH:mm'
      start = RoundedDateToNearestMinute(5, startDate).getTime()
    }else if (deltaType === "hour")  { //60 minute span -- we want ticks every 15 minutes
      minorTickFormat = 'HH:mm'
      majorTickFormat = 'HH:mm'
      start = RoundedDateToNearestMinute(5, startDate).getTime()
    }else if (deltaType === "hour3")  { //3 hour span -- we want ticks every 30 minutes
      minorTickFormat = 'HH:mm'
      majorTickFormat = 'HH:mm'

    }else if (deltaType === "hour6")  { //6 hour span -- we want ticks every hour
      minorTickFormat = 'HH:MM'
      majorTickFormat = 'MMM dd'
    }else if (deltaType === "hour12")  { //12 hour span -- we want ticks every hour
        minorTickFormat = 'HH:mm'
        majorTickFormat = 'MMM dd'
    }else if (deltaType === "day")  { //1 day span -- we want ticks every 6 hours
        minorTickFormat = 'HH:mm'
        majorTickFormat = 'MMM dd'

    }else if (deltaType === "week")  { //7 day span -- we want ticks every day
        minorTickFormat = 'HH:mm'
        majorTickFormat = 'MMM dd'
    }
    // Major ticks every 1000 units.
    let majorTickPositions = []
    start -= chartOriginDate
    end -= chartOriginDate
    for (let majorTickPos = start; majorTickPos <= end; majorTickPos += majorTickInterval) {
        if (majorTickPos >= start) {
            majorTickPositions.push(majorTickPos)
            const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
                .setTextFormatter(() => FormatDate(new Date(majorTickPos + chartOriginDate), majorTickFormat))
                .setValue(majorTickPos)
                .setMarker(marker => marker
                    .setTextFont(new FontSettings({ size: 14, style: '' }))
                )
                .setTickLength(8)
                .setTickLabelPadding(-4)
                .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA( 100 )))
            tickList.push(tick)
        }
    }
    // Major ticks every 100 units, but not at same interval as major ticks.
    for (let minorTickPos = start; minorTickPos <= end; minorTickPos += minorTickInterval) {
        if (minorTickPos >= start && majorTickPositions.indexOf(minorTickPos) === -1) {
            const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
                .setTextFormatter(() => FormatDate(new Date(minorTickPos + chartOriginDate), minorTickFormat))
                .setValue(minorTickPos)
                .setMarker(marker => marker
                    .setTextFont(new FontSettings({ size: 12, style: '' }))
                )
                .setTickLabelPadding(-4)
                .setTickLength(4)
                .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA(50)))
            tickList.push(tick)
        }
    }

  }




  const updateChartAxisTicks = (axis, ticks, lastRange, start, end) =>    {
      
      if (start == lastRange.start && end == lastRange.end)
          return ticks

      const timeDelta = end - start
      if (Math.floor(timeDelta) != Math.floor(lastRange.end - lastRange.start)) {
          //Zoom
         
          ticks = ticks.filter(tick => {
              tick.dispose()
              return false
          })
          createTicksInRangeX(axis, ticks, timeDelta, start, end)
          

      }else {
          //Pan

          ticks = ticks.filter(tick => {
            tick.dispose()
            return false
          }
          )
          createTicksInRangeX(axis, ticks, timeDelta, start, end)

          
          
          /*//compare last vs now to see if we need to add ticks
          if (end > lastRange.end) {
              createTicksInRangeX(axis, ticks, timeDelta, lastRange.end, end)
          }
          if (start < lastRange.start)    {
              createTicksInRangeX(axis, ticks, timeDelta, start, lastRange.start)
          }

          //compare last vs now to see if we need to remove ticks
          ticks = ticks.filter(tick => {
              if (tick.getValue() < (start - chartOriginDate) || tick.getValue() > (end - chartOriginDate)) {
                  // Tick is out of view.
                  tick.dispose()
                  return false
              } else {
                  return true
              }
          })*/
      }

      lastRange.start = start
      lastRange.end = end


      return ticks
  }



  
  const checkMainChartInterval = (start, end) =>    {
    if (chartRef.current === undefined)
        return
        
    if ((start == chartRef.current.lastMainChartInterval.start && end == chartRef.current.lastMainChartInterval.end) || processingMainChartInterval)
        return


    processingMainChartInterval = true
    let changed = false;
    const overviewChartVisibleRange = getOverviewChartInterval()
    const timeDelta = end - start
    //let newEnergySubselection = {start: chartRef.current.energySubSelection.start, end: chartRef.current.energySubSelection.end}
    
    if (Math.floor(timeDelta) != Math.floor(chartRef.current.lastMainChartInterval.end - chartRef.current.lastMainChartInterval.start)) {
        //Zoom
        if (start < overviewChartVisibleRange.start || start > overviewChartVisibleRange.end) {
            start = overviewChartVisibleRange.start
            changed = true
        }
        if (end > overviewChartVisibleRange.end || end < overviewChartVisibleRange.start) {
            end = overviewChartVisibleRange.end
            changed = true
        }


        //Handle current position of energy subselection 
        /*if (newEnergySubselection.start < start)    {
            newEnergySubselection.start = start
            if (newEnergySubselection.end < start)    {
                newEnergySubselection.end = start + 1000
            }
            //newEnergySubselection.end = start + (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
        }
        if (newEnergySubselection.end > end)    {
            newEnergySubselection.end = end
            if (newEnergySubselection.start > end)    {
                newEnergySubselection.start = end - 1000
            }
            //newEnergySubselection.start = end - (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
        }*/

        

    }else {
        //Pan
        if (end > overviewChartVisibleRange.end)    {
            start = overviewChartVisibleRange.end - timeDelta
            end = overviewChartVisibleRange.end
            changed = true
        }
        if (start < overviewChartVisibleRange.start)    {
            start = overviewChartVisibleRange.start
            end = overviewChartVisibleRange.start + timeDelta
            changed = true
        }

        //Handle current position of energy subselection 
        /*if (newEnergySubselection.start < start)    {
            newEnergySubselection.start = start
            newEnergySubselection.end = start + (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
        }
        if (newEnergySubselection.end > end)    {
            newEnergySubselection.end = end
            newEnergySubselection.start = end - (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
        }*/
    }


    
    //chartRef.current.energySubSelection = newEnergySubselection

    if (isForceLive)  {
      let newEnd = new Date().getTime()
      start += newEnd - end
      end = newEnd
    }
    chartRef.current.lastMainChartInterval.start = start
    chartRef.current.lastMainChartInterval.end = end
    if (changed)    {
        chartRef.current.mainChartDateAxis.setInterval(start - chartOriginDate, end - chartOriginDate, false, false)
    }
    chartRef.current.mainChartTimeTicks = updateChartAxisTicks(chartRef.current.mainChartDateAxis, chartRef.current.mainChartTimeTicks, chartRef.current.lastMainChartTickRange, start, end)
    
    processingMainChartInterval = false
    //drawEnergySubselection()
    drawOverviewSubselection()
  }

  const checkOverviewChartInterval = React.useCallback((start, end) =>    {
      if (chartRef.current === undefined)
          return
        
      if ((start == chartRef.current.lastOverviewChartInterval.start && end == chartRef.current.lastOverviewChartInterval.end) || processingOverviewChartInterval)
          return
          processingOverviewChartInterval = true
      let changed = false;



      /*if (start !== 0)    {
          start = 0
          changed = true
      }
      if (end !== chartRef.current.totalGrowDuration)  {
          end = chartRef.current.totalGrowDuration
          changed = true
      }*/



      if (isForceLive)  {
        let newEnd = new Date().getTime()
        start += newEnd - end
        end = newEnd
      }
      chartRef.current.lastOverviewChartInterval.start = start
      chartRef.current.lastOverviewChartInterval.end = end
      if (changed)    {
          chartRef.current.overviewChartDateAxis.setInterval(start - chartOriginDate, end - chartOriginDate, false, false)
      }

      chartRef.current.overviewChartTimeTicks = updateChartAxisTicks(chartRef.current.overviewChartDateAxis, chartRef.current.overviewChartTimeTicks, chartRef.current.lastOverviewChartTickRange, start, end)
      
      processingOverviewChartInterval = false

      //drawEnergySubselection()
      drawOverviewSubselection()
  })

  const {devicePixelRatio: pixelRatio = 1} = window
  const grabberWidth = 4;
  const chartingAreaRef = React.useRef()
  const [chartingAreaPointerId, SetChartingAreaPointerId] = React.useState(null)
  const [chartsContainerBind, { height: chartsContainerHeight, documentTop: chartsContainerAreaTop, documentLeft: chartsContainerAreaLeft}] = useMeasure()
  const [mainChartAreaBind, { height: mainChartAreaHeight, documentTop: mainChartAreaTop, documentLeft: mainChartAreaLeft}] = useMeasure()
  const [overviewChartAreaBind, { height: overviewChartAreaHeight, documentTop: overviewChartAreaTop, documentLeft: overviewChartAreaLeft }] = useMeasure()
  
  const [lastMousePosition, SetLastMousePosition] = React.useState({x: 0, y: 0});
  const [numberOfPointersDownOnMainCanvas, SetNumberOfPointersDownOnMainCanvas] = React.useState(0)
  const [draggingMainEnergyGrabber, SetDraggingMainEnergyGrabber] = React.useState(undefined)
  const [pointerOverMainEnergyGrabber, SetPointerOverMainEnergyGrabber] = React.useState(undefined)
  const [pointerOverMainChartDate, SetPointerOverMainChartDate] = React.useState(undefined)
  const [pointerOverDosingInstance, SetPointerOverDosingInstance] = React.useState(undefined)
  const [pointerOverMainChartY, SetPointerOverMainChartY] = React.useState(0)
  const [isTouchOverMainChart, SetIsTouchOverMainChart] = React.useState(false)
  const [mainCursor, SetMainCursor] = React.useState("default")
  const [isPointerDownOnOverviewCanvas, SetIsPointerDownOnOverviewCanvas] = React.useState(false)
  const [draggingOverviewGrabber, SetDraggingOverviewGrabber] = React.useState(undefined)
  const [pointerOverOverviewGrabber, SetPointerOverOverviewGrabber] = React.useState(undefined)
  const [overviewCursor, SetOverviewCursor] = React.useState("default")




  
  const chartingAreaPointerMove = React.useCallback((e) =>  {
        
    let pointerPosition = {top: 0, left: 0}
    pointerPosition.top = e.clientY
    pointerPosition.left = e.clientX

    if (numberOfPointersDownOnMainCanvas == 1 || (!isPointerDownOnOverviewCanvas && pointerPosition.top < mainChartAreaTop + mainChartAreaHeight))   {
        mainCanvasPointerMove(e, {y: pointerPosition.top - mainChartAreaTop, x: pointerPosition.left - mainChartAreaLeft})
    }else if (isPointerDownOnOverviewCanvas || (numberOfPointersDownOnMainCanvas == 0 && pointerPosition.top > overviewChartAreaTop && pointerPosition.top < overviewChartAreaTop + overviewChartAreaHeight))   {
        overviewCanvasPointerMove(e, {y: pointerPosition.top - overviewChartAreaTop, x: pointerPosition.left - overviewChartAreaLeft})
        SetPointerOverMainChartDate(undefined)
    }else {
        SetPointerOverMainChartDate(undefined)
    }
    SetLastMousePosition({x: e.clientX, y: e.clientY})
  })
  const chartingAreaPointerDown = React.useCallback((e) =>  {
    let pointerPosition = {top: 0, left: 0}
    pointerPosition.top = e.clientY
    pointerPosition.left = e.clientX

    

    if (pointerPosition.top < mainChartAreaTop + mainChartAreaHeight)   {
        SetNumberOfPointersDownOnMainCanvas(numberOfPointersDownOnMainCanvas + 1)
        if (numberOfPointersDownOnMainCanvas > 1)   { 
            chartingAreaReset()
        }else {
            mainCanvasPointerDown(e, {y: pointerPosition.top - mainChartAreaTop, x: pointerPosition.left - mainChartAreaLeft})
        }
    }else if (pointerPosition.top > overviewChartAreaTop && pointerPosition.top < overviewChartAreaTop + overviewChartAreaHeight)   {
        SetIsPointerDownOnOverviewCanvas(true)
        overviewCanvasPointerDown(e, {y: pointerPosition.top - overviewChartAreaTop, x: pointerPosition.left - overviewChartAreaLeft})
    }

    if (e.pointerType == "touch")   {
        SetIsTouchOverMainChart(true)
    }else {
        SetIsTouchOverMainChart(false)
    }

    SetLastMousePosition({x: e.clientX, y: e.clientY})

    
  })
  const chartingAreaPointerUp = (e) =>  {
    chartingAreaReset()
    if (numberOfPointersDownOnMainCanvas > 0)   {
        SetNumberOfPointersDownOnMainCanvas(numberOfPointersDownOnMainCanvas - 1)
    }
    if (chartingAreaRef.current !== undefined && chartingAreaRef.current.releasePointerCapture)    {
        chartingAreaRef.current.releasePointerCapture(chartingAreaPointerId);
    }
  }
  const chartingAreaPointerLeave = (e) => {
    SetPointerOverMainChartDate(undefined)
  }

  const chartingAreaReset = () => {
    SetDraggingMainEnergyGrabber(undefined)
    SetPointerOverMainEnergyGrabber(undefined)
    SetDraggingOverviewGrabber(undefined)
    SetIsPointerDownOnOverviewCanvas(false)
    SetPointerOverMainChartDate(undefined)
  }
 

  const mainCanvasPointerMove = (e, pointerOffset) =>    {
    if (!energySubSelectionCanvasRef.current || !chartRef.current) 
        return

    const mainChartVisibleRange = getMainChartInterval()
    const overviewChartVisibleRange = getOverviewChartInterval()
    const mainChartPadding = chartRef.current.mainChart.getPadding()
    const chartArea = getMainChartArea()

    pointerOffset.x -= mainChartPadding.left + 4 //hack
    pointerOffset.y -= mainChartPadding.top + 10 //hack
    SetPointerOverMainChartY(pointerOffset.y)

    const currentlyOverTime = mainChartConvertPositionToDate(pointerOffset.x)


    //Detect if we are currently over a grabber (only applies to non touch here, touch needs to be initiated on pointer down)
    let pointerOverGrabber = undefined;
    if (energySubselectionActive && e.pointerType != "touch")   {
        let eX1 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.start)
        let eX2 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.end)
        
        let hitTestWidth = {outside: grabberWidth + 1, inside: 1}
        let hitTestHeight = 30 //for now just give the whole vertical space -- fix later
        if (eX2 - eX1 < hitTestWidth.inside * 2)    {
            hitTestWidth.inside = (eX2 - eX1) / 2
        }
        if (eX1 - hitTestWidth.outside <= pointerOffset.x && eX1 + hitTestWidth.inside > pointerOffset.x)   {
            pointerOverGrabber = "left"
        }else if (eX2 - hitTestWidth.inside < pointerOffset.x && eX2 + hitTestWidth.outside >= pointerOffset.x) {
            pointerOverGrabber = "right"
        }else if (eX1 - hitTestWidth.outside <= pointerOffset.x && eX2 + hitTestWidth.outside >= pointerOffset.x) {
            //pointerOverGrabber = "middle"
        }
    }
    let isForTooltip = false
    //Check if we are dragging a grabber
    if (draggingMainEnergyGrabber)    {
        let offset = {x: e.clientX - lastMousePosition.x, y: e.clientY - lastMousePosition.y};
        let addingTime = ((mainChartVisibleRange.end - mainChartVisibleRange.start) * (offset.x / chartArea.width))
        if (draggingMainEnergyGrabber == "right") {
            let newEnd = currentlyOverTime
            if (newEnd < chartRef.current.energySubSelection.start) {
                newEnd = chartRef.current.energySubSelection.start + 1
            }
            chartRef.current.energySubSelection = {start: chartRef.current.energySubSelection.start, end: newEnd}
        }else if (draggingMainEnergyGrabber == "left") {
            let newStart = currentlyOverTime
            if (newStart > chartRef.current.energySubSelection.end) {
                newStart = chartRef.current.energySubSelection.end - 1
            }
            chartRef.current.energySubSelection = {start: newStart, end: chartRef.current.energySubSelection.end}
        }

    //Check if we are dragging on the canvas currently (only applies to mouse/pointer)
    //}else if (isPointerDownOnMainCanvas)  {


    //Check if we are currently pointing over a grabber
    }else if (pointerOverGrabber !== undefined)   {
        e.preventDefault()
        e.stopPropagation()
        if (pointerOverMainEnergyGrabber != pointerOverGrabber)    {
            SetPointerOverMainEnergyGrabber(pointerOverGrabber)
        }

    //Otherwise -- We aren't dragging, we aren't over a grabber: Tooltip calculation here
    }else {
        let overDosingInstance = null;
        if (chartRef.current.activeYAxes["millilitres"] !== undefined)  {
            for (const [growId, growData] of Object.entries(chartRef.current.dataSeries)) {
                if (growData["nutrients"] !== undefined)    {
                    if (growData["nutrients"].dosingInstanceBars !== undefined)    {
                        for (const dosingInstanceBar of growData["nutrients"].dosingInstanceBars)  {
                            if (dosingInstanceBar.dimensions.x <= currentlyOverTime && dosingInstanceBar.dimensions.x + dosingInstanceBar.dimensions.width >= currentlyOverTime)    {//&&
                                overDosingInstance = dosingInstanceBar
                                break
                            }
                        }
                    }
                }
                if (overDosingInstance) {
                    break
                }
            }
        }
        if (overDosingInstance) {
            if (pointerOverDosingInstance !== undefined && pointerOverDosingInstance !== overDosingInstance)    {
                pointerOverDosingInstance.bar.setStrokeStyle(emptyLine)
            }
            if (pointerOverDosingInstance !== overDosingInstance)   {
                overDosingInstance.bar.setStrokeStyle(new SolidLine({fillStyle: new SolidFill({color: ColorRGBA(46, 114, 210)}), thickness:3}))
                SetPointerOverDosingInstance(overDosingInstance)
            }
        }else {
            if (pointerOverDosingInstance !== undefined)    {
                pointerOverDosingInstance.bar.setStrokeStyle(emptyLine)
                SetPointerOverDosingInstance(undefined)
            }
        }
        isForTooltip = true
        SetPointerOverMainChartDate(currentlyOverTime)
       

    }
    

    //Check if we were over a grabber but aren't anymore
    if (pointerOverGrabber === undefined && pointerOverMainEnergyGrabber !== undefined)   {
        SetPointerOverMainEnergyGrabber(undefined)
    }

    if (!isForTooltip)  {
        SetPointerOverMainChartDate(undefined)
    }
  }

  const mainCanvasPointerDown = (e, pointerOffset) => {
    if (!energySubSelectionCanvasRef.current || !chartRef.current) 
        return

    let requiresPointerCapture = false
    

    const mainChartVisibleRange = getMainChartInterval()
    const overviewChartVisibleRange = getOverviewChartInterval()
    const mainChartPadding = chartRef.current.mainChart.getPadding()

    pointerOffset.x -= mainChartPadding.left + 4 //hack
    pointerOffset.y -= mainChartPadding.top + 10 //hack
    const currentlyOverTime = mainChartConvertPositionToDate(pointerOffset.x)

    //Detect if we are currently touching down on a grabber (only applies to touch here, touch needs to be initiated on pointer down)
    let touchDownOnGrabber = undefined;
    if (energySubselectionActive && e.pointerType == "touch")   {
        let eX1 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.start)
        let eX2 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.end)
        
        let hitTestWidth = {outside: grabberWidth * 5, inside: grabberWidth * 4}
        let hitTestHeight = 10 //for now just give the whole vertical space -- fix later

        if (eX2 - eX1 < hitTestWidth.inside * 2)    {
            hitTestWidth.inside = (eX2 - eX1) / 2
        }
        if (eX1 - hitTestWidth.outside <= pointerOffset.x && eX1 + hitTestWidth.inside > pointerOffset.x)   {
            touchDownOnGrabber = "left"
        }else if (eX2 - hitTestWidth.inside < pointerOffset.x && eX2 + hitTestWidth.outside >= pointerOffset.x) {
            touchDownOnGrabber = "right"
        }


        if (touchDownOnGrabber) {
            if (draggingMainEnergyGrabber != touchDownOnGrabber)  {
                SetDraggingMainEnergyGrabber(touchDownOnGrabber)
                requiresPointerCapture = true
            }
        }

        if (draggingMainEnergyGrabber !== undefined && touchDownOnGrabber === undefined)  {
            SetDraggingMainEnergyGrabber(undefined)
        }

    }else {

        if (pointerOverMainEnergyGrabber !== undefined && draggingMainEnergyGrabber != pointerOverMainEnergyGrabber)   {
            SetDraggingMainEnergyGrabber(pointerOverMainEnergyGrabber)
            requiresPointerCapture = true
        }



        /*if (draggingOverviewGrabber !== undefined && pointerOverOverviewGrabber === undefined)  {
            SetDraggingOverviewGrabber(undefined)
        }*/
    }

    if (touchDownOnGrabber === undefined && pointerOverMainEnergyGrabber === undefined && draggingMainEnergyGrabber === undefined) {
        let overDosingInstance = null
        if (chartRef.current.activeYAxes["millilitres"] !== undefined)  {
            const chartArea = getMainChartArea()
            const axisLimits = chartRef.current.activeYAxes["millilitres"].main.getInterval()
            let yValue = (1 - (pointerOffset.y / chartArea.height)) * axisLimits.end
            for (const [growId, growData] of Object.entries(chartRef.current.dataSeries)) {
                if (growData["nutrients"] !== undefined)    {
                    if (growData["nutrients"].dosingInstanceBars !== undefined)    {
                        for (const dosingInstanceBar of growData["nutrients"].dosingInstanceBars)  {
                            let hitTestMargin = e.pointerType == "touch" ? 10 : 0
                            if (dosingInstanceBar.dimensions.x - hitTestMargin <= currentlyOverTime && dosingInstanceBar.dimensions.x + dosingInstanceBar.dimensions.width + hitTestMargin >= currentlyOverTime &&
                                dosingInstanceBar.dimensions.y - hitTestMargin <= yValue && dosingInstanceBar.dimensions.y + dosingInstanceBar.dimensions.height + hitTestMargin >= yValue)    {
                                
                                if (e.pointerType == "touch")   {
                                    if (overDosingInstance === null || 
                                            Math.abs(dosingInstanceBar.dimensions.x + (dosingInstanceBar.dimensions.width / 2) - currentlyOverTime) < 
                                            Math.abs(overDosingInstance.dimensions.x + (overDosingInstance.dimensions.width / 2) - currentlyOverTime)) {
                                        overDosingInstance = dosingInstanceBar                                            
                                    }
                                }else {
                                    overDosingInstance = dosingInstanceBar
                                    break
                                }
                            
                            }
                            
                        }
                    }
                }
                
                if (e.pointerType !== "touch" && overDosingInstance) {
                    break
                }
            }
        }

    }

    if (requiresPointerCapture) {
        if (chartingAreaRef.current !== undefined && chartingAreaRef.current.setPointerCapture)    {
            SetChartingAreaPointerId(e.pointerId)
            chartingAreaRef.current.setPointerCapture(e.pointerId);
        }
    }
  }



  const overviewCanvasPointerMove = (e, pointerOffset) =>    {
    if (!chartRef.current) 
        return

    const mainChartVisibleRange = getMainChartInterval()
    const overviewChartVisibleRange = getOverviewChartInterval()
    const overviewChartPadding = chartRef.current.overviewChart.getPadding()
    const chartArea = getOverviewChartArea()

    pointerOffset.x -= overviewChartPadding.left + 4 //hack
    pointerOffset.y -= overviewChartPadding.top + 10 //hack
    //Detect if we are currently over a grabber (only applies to non touch here, touch needs to be initiated on pointer down)
    let pointerOverGrabber = undefined;
    if (e.pointerType != "touch")   {
        let sX1 = overviewChartConvertDateToPosition(mainChartVisibleRange.start - overviewChartVisibleRange.start)
        let sX2 = overviewChartConvertDateToPosition(mainChartVisibleRange.end - overviewChartVisibleRange.start)
        
        let hitTestWidth = {outside: grabberWidth + 1, inside: 1}
        let hitTestHeight = 10 //for now just give the whole vertical space -- fix later
        if (sX2 - sX1 < hitTestWidth.inside * 2)    {
            hitTestWidth.inside = (sX2 - sX1) / 2
        }
        if (sX1 - hitTestWidth.outside <= pointerOffset.x && sX1 + hitTestWidth.inside > pointerOffset.x)   {
            pointerOverGrabber = "left"
        }else if (sX2 - hitTestWidth.inside < pointerOffset.x && sX2 + hitTestWidth.outside >= pointerOffset.x) {
            pointerOverGrabber = "right"
        }else if (sX1 - hitTestWidth.outside <= pointerOffset.x && sX2 + hitTestWidth.outside >= pointerOffset.x) {
            pointerOverGrabber = "middle"
        }
    }

    //Check if we are dragging a grabber
    if (draggingOverviewGrabber)    {
        let offset = {x: e.clientX - lastMousePosition.x, y: e.clientY - lastMousePosition.y};
        let addingTime = ((overviewChartVisibleRange.end - overviewChartVisibleRange.start) * (offset.x / chartArea.width))
       
        if (draggingOverviewGrabber == "right") {
            let newEnd = overviewChartConvertPositionToDate(pointerOffset.x)
            if (newEnd < mainChartVisibleRange.start) {
                newEnd = mainChartVisibleRange.start + 1000 * 30
            }
            chartRef.current.mainChartDateAxis.setInterval(mainChartVisibleRange.start, newEnd, false, false)
        }else if (draggingOverviewGrabber == "left") {
            let newStart = overviewChartConvertPositionToDate(pointerOffset.x)
            if (newStart > mainChartVisibleRange.end) {
                newStart = mainChartVisibleRange.end - 1000 * 30
            }
            chartRef.current.mainChartDateAxis.setInterval(newStart, mainChartVisibleRange.end, false, false)
        }else if (draggingOverviewGrabber == "middle") {
            chartRef.current.mainChartDateAxis.setInterval(mainChartVisibleRange.start + addingTime, mainChartVisibleRange.end + addingTime, false, false)
        }

    //Check if we are dragging on the canvas currently (only applies to mouse/pointer)
    }else if (isPointerDownOnOverviewCanvas)  {


    //Check if we are currently pointing over a grabber
    }else if (pointerOverGrabber !== undefined)   {
        if (pointerOverOverviewGrabber != pointerOverGrabber)    {
            SetPointerOverOverviewGrabber(pointerOverGrabber)
        }

    //Otherwise -- We aren't dragging, we aren't over a grabber: Tooltip calculation here
    }else {
        let date = overviewChartConvertPositionToDate(pointerOffset.x)
        //console.log(date)
    }
    

    //Check if we were over a grabber but aren't anymore
    if (pointerOverGrabber === undefined && pointerOverOverviewGrabber !== undefined)   {
        SetPointerOverOverviewGrabber(undefined)
    }
  }

  const overviewCanvasPointerDown = (e, pointerOffset) => {
    if (!chartRef.current) 
        return

    let requiresPointerCapture = false

    const mainChartVisibleRange = getMainChartInterval()
    const overviewChartVisibleRange = getOverviewChartInterval()
    const overviewChartPadding = chartRef.current.overviewChart.getPadding()

    pointerOffset.x -= overviewChartPadding.left + 4 //hack
    pointerOffset.y -= overviewChartPadding.top + 10 //hack
    //Detect if we are currently touching down on a grabber (only applies to touch here, touch needs to be initiated on pointer down)
    let touchDownOnGrabber = undefined;
    if (e.pointerType == "touch")   {
        let sX1 = overviewChartConvertDateToPosition(mainChartVisibleRange.start - overviewChartVisibleRange.start)
        let sX2 = overviewChartConvertDateToPosition(mainChartVisibleRange.end - overviewChartVisibleRange.start)
        
        let hitTestWidth = {outside: grabberWidth * 3, inside: grabberWidth * 2}
        let hitTestHeight = 10 //for now just give the whole vertical space -- fix later

        if (sX2 - sX1 < hitTestWidth.inside * 2)    {
            hitTestWidth.inside = (sX2 - sX1) / 2
        }
        if (sX1 - hitTestWidth.outside <= pointerOffset.x && sX1 + hitTestWidth.inside > pointerOffset.x)   {
            touchDownOnGrabber = "left"
        }else if (sX2 - hitTestWidth.inside < pointerOffset.x && sX2 + hitTestWidth.outside >= pointerOffset.x) {
            touchDownOnGrabber = "right"
        }else if (sX1 - hitTestWidth.outside <= pointerOffset.x && sX2 + hitTestWidth.outside >= pointerOffset.x) {
            touchDownOnGrabber = "middle"
        }


        if (touchDownOnGrabber) {
            if (draggingOverviewGrabber != touchDownOnGrabber)  {
                SetDraggingOverviewGrabber(touchDownOnGrabber)
                requiresPointerCapture = true
            }
        }

        if (draggingOverviewGrabber !== undefined && touchDownOnGrabber === undefined)  {
            SetDraggingOverviewGrabber(undefined)
        }

    }else {

        if (pointerOverOverviewGrabber !== undefined && draggingOverviewGrabber != pointerOverOverviewGrabber)   {
            SetDraggingOverviewGrabber(pointerOverOverviewGrabber)
            requiresPointerCapture = true
        }



        /*if (draggingOverviewGrabber !== undefined && pointerOverOverviewGrabber === undefined)  {
            SetDraggingOverviewGrabber(undefined)
        }*/
    }


    if (requiresPointerCapture) {
        if (chartingAreaRef.current !== undefined && chartingAreaRef.current.setPointerCapture)    {
            SetChartingAreaPointerId(e.pointerId)
            chartingAreaRef.current.setPointerCapture(e.pointerId);
        }
    }
  }





  const drawTooltip = React.useCallback(() =>   {
    if (!chartRef.current) 
        return

    const mainDataRecordingTimePeriodType = getMainDataRecordingTimePeriodType()
    const mainChartPadding = chartRef.current.mainChart.getPadding()
    const chartArea = getMainChartArea()
    
    let tooltipSpacingFromCenter = {x: 5, y: 5}
    if (isTouchOverMainChart)   {
        tooltipSpacingFromCenter = {x: 5, y: 15}
    }
    const mainChartVisibleRange = getMainChartInterval()


    let tooltipStyleProps = {}
    let xPoint = mainChartConvertDateToPosition(pointerOverMainChartDate)
    if (pointerOverMainChartDate - mainChartVisibleRange.start < (mainChartVisibleRange.end - mainChartVisibleRange.start) / 2)   {
        tooltipStyleProps.left = xPoint + (mainChartAreaLeft - chartsContainerAreaLeft) + mainChartPadding.left + 4 + tooltipSpacingFromCenter.x;
    }else   {
        tooltipStyleProps.right = chartArea.width - xPoint - (mainChartAreaLeft - chartsContainerAreaLeft) + mainChartPadding.right + 4 + tooltipSpacingFromCenter.x;
    }
    tooltipStyleProps.bottom = (chartsContainerHeight - pointerOverMainChartY) + tooltipSpacingFromCenter.y;
    if (tooltipStyleProps.bottom > chartsContainerHeight)   {
        tooltipStyleProps.bottom = chartsContainerHeight
        //tooltipStyleProps.top = 
    }

    let timeTooltipStyleProps = {
        left: xPoint + mainChartPadding.left + 4,
        top: mainChartAreaHeight
    }


  })


  const resizeView = view => {
    const { width, height } = view.getBoundingClientRect()
    //alert(width.toString() + "-" + height)
    
    
    if (view.width !== width*pixelRatio || view.height !== height*pixelRatio) {
      const context = view.getContext('2d')
      view.width = width*pixelRatio
      view.height = height*pixelRatio
      context.scale(pixelRatio, pixelRatio)
      return true
    }

    return false
  }

  const drawRoundedRect = (ctx, x, y, width, height, radius) => { //top left, top right, bottom left, bottom right
    ctx.beginPath();
    ctx.moveTo(x + radius[0], y);
    ctx.lineTo(x + width - radius[1], y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius[1]);
    ctx.lineTo(x + width, y + height - radius[3]);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius[3], y + height);
    ctx.lineTo(x + radius[2], y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius[2]);
    ctx.lineTo(x, y + radius[0]);
    ctx.quadraticCurveTo(x, y, x + radius[0], y);
    ctx.closePath();
    ctx.fill()
  }

  const [energyCanvasBounds, setEnergyCanvasROBounds] = React.useState({ left: 0, top: 0, width: 0, height: 0 })
  const [energyCanvasRO] = React.useState(() => new ResizeObserver(([entry]) => setEnergyCanvasROBounds(entry.contentRect)))
  const mainChartConvertDateToPosition = (date) => {
    if (!energySubSelectionCanvasRef.current || !chartRef.current) 
        return undefined
    const view = energySubSelectionCanvasRef.current

    let width = view.width/pixelRatio
    let height = view.height/pixelRatio

    const mainChartVisibleRange = getMainChartInterval()
    const mainChartPadding = chartRef.current.mainChart.getPadding()

    let chartArea = {
        x1: mainChartPadding.left, 
        x2: width - mainChartPadding.right,
        y1: mainChartPadding.top,
        y2: height - mainChartPadding.bottom - chartRef.current.mainChartDateAxis.getHeight()
    }
    chartArea.width = chartArea.x2 - chartArea.x1
    chartArea.height = chartArea.y2 - chartArea.y1
    
    const mainChartRangeDelta = mainChartVisibleRange.end - mainChartVisibleRange.start

    // Figure out the x position of both gray areas
    return ((date - mainChartVisibleRange.start) / mainChartRangeDelta) * chartArea.width
  }
  const mainChartConvertPositionToDate = (x) => {
    if (!energySubSelectionCanvasRef.current || !chartRef.current) 
        return undefined
    const view = energySubSelectionCanvasRef.current

    let width = view.width/pixelRatio
    let height = view.height/pixelRatio

    const mainChartVisibleRange = getMainChartInterval()
    const mainChartPadding = chartRef.current.mainChart.getPadding()

    let chartArea = {
        x1: mainChartPadding.left, 
        x2: width - mainChartPadding.right,
        y1: mainChartPadding.top,
        y2: height - mainChartPadding.bottom - chartRef.current.mainChartDateAxis.getHeight()
    }
    chartArea.width = chartArea.x2 - chartArea.x1
    chartArea.height = chartArea.y2 - chartArea.y1
    
    const mainChartRangeDelta = mainChartVisibleRange.end - mainChartVisibleRange.start

    // Figure out the x position of both gray areas
    if (x < 0)
        x = 0
    if (x > chartArea.width)
        x = chartArea.width


    return mainChartVisibleRange.start + (x / chartArea.width) * mainChartRangeDelta


  }
  const getMainChartArea = () => {
    if (!energySubSelectionCanvasRef.current || !chartRef.current) 
        return undefined
    const view = energySubSelectionCanvasRef.current

    let width = view.width/pixelRatio
    let height = view.height/pixelRatio

    const mainChartVisibleRange = getMainChartInterval()
    const mainChartPadding = chartRef.current.mainChart.getPadding()

    let chartArea = {
        x1: mainChartPadding.left, 
        x2: width - mainChartPadding.right,
        y1: mainChartPadding.top,
        y2: height - mainChartPadding.bottom - chartRef.current.mainChartDateAxis.getHeight()
    }
    chartArea.width = chartArea.x2 - chartArea.x1
    chartArea.height = chartArea.y2 - chartArea.y1

    return chartArea
  }


  const drawEnergySubselection = () =>  {
    if (!energySubSelectionCanvasRef.current || !chartRef.current) 
        return
    const view = energySubSelectionCanvasRef.current

    let width = view.width/pixelRatio
    let height = view.height/pixelRatio
    const ctx = view.getContext('2d')
    ctx.clearRect(0, 0, width, height)
    
    
    const mainChartVisibleRange = getMainChartInterval()
    
    const mainChartPadding = chartRef.current.mainChart.getPadding()

    let chartArea = {
        x1: mainChartPadding.left, 
        x2: width - mainChartPadding.right,
        y1: mainChartPadding.top,
        y2: height - mainChartPadding.bottom - chartRef.current.mainChartDateAxis.getHeight()
    }
    chartArea.width = chartArea.x2 - chartArea.x1
    chartArea.height = chartArea.y2 - chartArea.y1


    if (chartRef.current.energySubselectionActive)   {
        

        // Figure out the x position of both gray areas
        let eX1 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.start)
        let eX2 = mainChartConvertDateToPosition(chartRef.current.energySubSelection.end)


        ctx.fillStyle = "rgba(255, 180, 62, 0.2)"
        ctx.fillRect(chartArea.x1 + eX1, chartArea.y1, eX2 - eX1, chartArea.height)
        //ctx.fillRect(chartArea.x1 + eX2, chartArea.y1, chartArea.width - eX2, chartArea.height)

        ctx.strokeStyle = "rgba(255, 180, 62, 1.0)"
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(chartArea.x1 + eX2 - 1, chartArea.y1 + 1);
        ctx.lineTo(chartArea.x1 + eX2 - 1, chartArea.y1 + chartArea.height - 1);
        ctx.stroke();
        ctx.moveTo(chartArea.x1 + eX1 + 1, chartArea.y1 + chartArea.height - 1);
        ctx.lineTo(chartArea.x1 + eX1 + 1, chartArea.y1 + 1);
        ctx.stroke();
        ctx.lineWidth = 1;

        ctx.fillStyle = "rgba(255, 96, 35, 1)"
        drawRoundedRect(ctx, chartArea.x1 + eX1 + 1 - grabberWidth, chartArea.y1 + (chartArea.height / 4), grabberWidth, chartArea.height / 2, [grabberWidth, 0, grabberWidth, 0])
        drawRoundedRect(ctx, chartArea.x1 + eX2 - 1, chartArea.y1 + (chartArea.height / 4), grabberWidth, chartArea.height / 2, [0, grabberWidth, 0, grabberWidth])
    }

    if (pointerOverMainChartDate !== undefined)   {
        let pX = mainChartConvertDateToPosition(pointerOverMainChartDate)
        ctx.strokeStyle = "rgba(0, 0, 0, 1.0)"
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(chartArea.x1 + pX, chartArea.y1 + 1);
        ctx.lineTo(chartArea.x1 + pX, chartArea.y1 + chartArea.height - 1);
        ctx.stroke();
    }
  }
  const updateEnergySubselection = () =>  {
    if (!energySubSelectionCanvasRef.current) 
        return
        
    energyCanvasRO.observe(energySubSelectionCanvasRef.current)
    const view = energySubSelectionCanvasRef.current

    const ctx = view.getContext('2d')
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.clearRect(0, 0, view.width, view.height)
    resizeView(view)
    ctx.translate(0.5, 0.5);


    drawEnergySubselection()
  }

  const [overviewCanvasBounds, setOverviewCanvasROBounds] = React.useState({ left: 0, top: 0, width: 0, height: 0 })
  const [overviewCanvasRO] = React.useState(() => new ResizeObserver(([entry]) => setOverviewCanvasROBounds(entry.contentRect)))
  const overviewChartConvertDateToPosition = (date) => {
    if (!overviewSubSelectionCanvasRef.current || !chartRef.current) 
        return undefined
    const overviewChartVisibleRange = getOverviewChartInterval()

    let chartArea = getOverviewChartArea()
    
    const overviewChartRangeDelta = overviewChartVisibleRange.end - overviewChartVisibleRange.start

    // Figure out the x position of both gray areas
    return (date / overviewChartRangeDelta) * chartArea.width
  }
  const overviewChartConvertPositionToDate = (x) => {
    if (!overviewSubSelectionCanvasRef.current || !chartRef.current) 
        return undefined

    const overviewChartVisibleRange = getOverviewChartInterval()

    let chartArea = getOverviewChartArea()
    const overviewChartRangeDelta = overviewChartVisibleRange.end - overviewChartVisibleRange.start

    // Figure out the x position of both gray areas
    if (x < 0)
        x = 0
    if (x > chartArea.width)
        x = chartArea.width


    return overviewChartVisibleRange.start + (x / chartArea.width) * overviewChartRangeDelta


  }
  const getOverviewChartArea = () => {
    if (!overviewSubSelectionCanvasRef.current || !chartRef.current) 
        return undefined
    const view = overviewSubSelectionCanvasRef.current

    let width = view.width/pixelRatio
    let height = view.height/pixelRatio

    const overviewChartVisibleRange = getOverviewChartInterval()
    const overviewChartPadding = chartRef.current.overviewChart.getPadding()

    let chartArea = {
        x1: overviewChartPadding.left, 
        x2: width - overviewChartPadding.right,
        y1: overviewChartPadding.top,
        y2: height - overviewChartPadding.bottom - chartRef.current.overviewChartDateAxis.getHeight()
    }
    chartArea.width = chartArea.x2 - chartArea.x1
    chartArea.height = chartArea.y2 - chartArea.y1

    return chartArea
  }


  const drawOverviewSubselection = () =>  {
    if (!overviewSubSelectionCanvasRef.current || !chartRef.current) 
        return
    const view = overviewSubSelectionCanvasRef.current

    let width = view.width/pixelRatio
    let height = view.height/pixelRatio
    const ctx = view.getContext('2d')
    
    const mainChartVisibleRange = getMainChartInterval()
    const overviewChartVisibleRange = getOverviewChartInterval()
    const overviewChartPadding = chartRef.current.overviewChart.getPadding()

    let chartArea = {
        x1: overviewChartPadding.left, 
        x2: width - overviewChartPadding.right,
        y1: overviewChartPadding.top,
        y2: height - overviewChartPadding.bottom - chartRef.current.overviewChartDateAxis.getHeight()
    }
    chartArea.width = chartArea.x2 - chartArea.x1
    chartArea.height = chartArea.y2 - chartArea.y1
    

    // Figure out the x position of both gray areas
    let sX1 = overviewChartConvertDateToPosition(mainChartVisibleRange.start - overviewChartVisibleRange.start)
    let sX2 = overviewChartConvertDateToPosition(mainChartVisibleRange.end - overviewChartVisibleRange.start)

    ctx.clearRect(0, 0, width, height)



    if (chartRef.current.energySubselectionActive)   {
        const mainChartRangeDelta = mainChartVisibleRange.end - mainChartVisibleRange.start
        let eX1 = sX1 + (((chartRef.current.energySubSelection.start - mainChartVisibleRange.start) / mainChartRangeDelta) * (sX2 - sX1))
        let eX2 = sX1 + (((chartRef.current.energySubSelection.end - mainChartVisibleRange.start) / mainChartRangeDelta) * (sX2 - sX1))

        ctx.fillStyle = "rgba(255, 180, 62, 0.2)"
        ctx.fillRect(chartArea.x1 + eX1, chartArea.y1, eX2 - eX1, chartArea.height)

        ctx.strokeStyle = "rgba(255, 180, 62, 1.0)"
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(chartArea.x1 + eX2 - 1, chartArea.y1 + 1);
        ctx.lineTo(chartArea.x1 + eX2 - 1, chartArea.y1 + chartArea.height - 1);
        ctx.lineTo(chartArea.x1 + eX1 + 1, chartArea.y1 + chartArea.height - 1);
        ctx.lineTo(chartArea.x1 + eX1 + 1, chartArea.y1 + 1);
        ctx.stroke();
        ctx.lineWidth = 1;
    }


    ctx.fillStyle = "rgba(0,0,0,0.2)"
    ctx.fillRect(chartArea.x1, chartArea.y1, sX1, chartArea.height)
    ctx.fillRect(chartArea.x1 + sX2, chartArea.y1, chartArea.width - sX2, chartArea.height)

    ctx.strokeStyle = "rgba(0,0,0,0.5)"
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(chartArea.x1 + sX1 + 1, chartArea.y1 + 1)
    ctx.lineTo(chartArea.x1 + sX2 - 1, chartArea.y1 + 1);
    ctx.lineTo(chartArea.x1 + sX2 - 1, chartArea.y1 + chartArea.height - 1);
    ctx.lineTo(chartArea.x1 + sX1 + 1, chartArea.y1 + chartArea.height - 1);
    ctx.lineTo(chartArea.x1 + sX1 + 1, chartArea.y1 + 1);
    ctx.stroke();
    ctx.lineWidth = 1;


    if (pointerOverOverviewGrabber == "left")   {
        ctx.fillStyle = "rgba(20, 20, 20, 1)"
    }else {
        ctx.fillStyle = "rgba(50, 50, 50, 1)"
    }
    drawRoundedRect(ctx, chartArea.x1 + sX1 + 1 - grabberWidth, chartArea.y1 + (chartArea.height / 4), grabberWidth, chartArea.height / 2, [grabberWidth, 0, grabberWidth, 0])

    if (pointerOverOverviewGrabber == "right")  {
        ctx.fillStyle = "rgba(50, 20, 20, 1)"
    }else {
        ctx.fillStyle = "rgba(50, 50, 50, 1)"
    }
    drawRoundedRect(ctx, chartArea.x1 + sX2 - 1, chartArea.y1 + (chartArea.height / 4), grabberWidth, chartArea.height / 2, [0, grabberWidth, 0, grabberWidth])


  }
  const updateOverviewSubselection = () =>  {
    if (!overviewSubSelectionCanvasRef.current) 
        return
        
    overviewCanvasRO.observe(overviewSubSelectionCanvasRef.current)
    const view = overviewSubSelectionCanvasRef.current

    const ctx = view.getContext('2d')
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.clearRect(0, 0, view.width, view.height)
    resizeView(view)
    ctx.translate(0.5, 0.5);


    drawOverviewSubselection()
  }

  React.useEffect(() => {
    updateEnergySubselection()
    updateOverviewSubselection()
    return () => {
        energyCanvasRO.disconnect()
        overviewCanvasRO.disconnect()
    }
  }, [])
  updateEnergySubselection()
  updateOverviewSubselection()

  React.useEffect(() =>   {
    updateOverviewSubselection()
  }, [pointerOverOverviewGrabber])


  React.useEffect(() => {
    const mainChart = lightningChart({
        overrideInteractionMouseButtons: {
            chartXYPanMouseButton: 0,
        },
    }).ChartXY({ 
        container: "GrowZones-Charts-MainChart-" + + growOutZone.id,
        theme: mainChartTheme,
    }).setMouseInteractionRectangleZoom(false)
    .setMouseInteractionRectangleFit(false)
    .setMouseInteractionWheelZoom(true)
    .setTitle("")
    .setPadding({top:0, left: 14, right:14, bottom: 0})
    .setAutoCursorMode(AutoCursorModes.disabled)

   
    let currentDate = new Date().getTime()

    mainChart.getDefaultAxisY()
        .setMouseInteractions(false)
        .setTickStrategy(AxisTickStrategies.Empty)
    
    const defaultMainChartInterval = {start: (currentDate - (1000 * 60 * 60 * 3)) - chartOriginDate, end: currentDate - chartOriginDate}
    const mainChartDateAxis = mainChart.getDefaultAxisX()
    mainChartDateAxis.setTickStrategy(
            AxisTickStrategies.DateTime,
            (tickStrategy) => tickStrategy.setDateOrigin(new Date(chartOriginDate)),
        )
        .setAnimationsEnabled(false)
        .setChartInteractionPanByDrag(true)
        .setChartInteractionZoomByWheel(true)
        .setNibInteractionScaleByWheeling(true)
        .setInterval(defaultMainChartInterval.start , defaultMainChartInterval.end, false, false)
        .setScrollStrategy(undefined)
        .setTickStrategy(AxisTickStrategies.Empty)
        //.setInterval(new Date(new Date().setDate(new Date().getHours()-2)).getTime() , new Date(new Date().setDate(new Date().getHours()-1)).getTime(), false)
        
    mainChartDateAxis.onScaleChange((start, end) => {
        checkMainChartInterval(start + chartOriginDate, end + chartOriginDate)
    })


    let mainChartTimeTicks = []
    let lastMainChartInterval = {start: 0, end: 0}
    let lastMainChartTickRange = {start: 0, end: 0}
    mainChartTimeTicks = updateChartAxisTicks(mainChartDateAxis, mainChartTimeTicks, lastMainChartTickRange, defaultMainChartInterval.start + chartOriginDate, defaultMainChartInterval.end + chartOriginDate)

    const overviewChart = lightningChart({
        overrideInteractionMouseButtons: {
            chartXYPanMouseButton: 0,
        },
    }).ChartXY({ 
        container: "GrowZones-Charts-OverviewChart-" + + growOutZone.id,
        theme: mainChartTheme,
    }).setMouseInteractionRectangleZoom(false)
    .setMouseInteractionRectangleFit(false)
    .setMouseInteractionWheelZoom(false)
    .setMouseInteractions(false)
    .setTitle("")
    .setPadding({top:0, left: 14, right: 14, bottom: 0})
    .setAutoCursorMode(AutoCursorModes.disabled)
    overviewChart.getDefaultAxisY()
        .setMouseInteractions(false)
        .setTickStrategy(AxisTickStrategies.Empty)


    const defaultOverviewChartInterval = {start: (currentDate - (1000 * 60 * 60 * 24)) - chartOriginDate, end: currentDate - chartOriginDate}
    const overviewChartDateAxis = overviewChart.getDefaultAxisX()
        .setTickStrategy(
            AxisTickStrategies.DateTime,
            (tickStrategy) => tickStrategy.setDateOrigin(new Date(chartOriginDate)),
        )
        .setMouseInteractions(false)
        .setChartInteractionPanByDrag(false)
        .setChartInteractionZoomByWheel(false)
        .setNibInteractionScaleByWheeling(false)
        .setInterval(defaultOverviewChartInterval.start , defaultOverviewChartInterval.end, false, false)
        .setScrollStrategy(undefined)
        .setTickStrategy(AxisTickStrategies.Empty)
    
    let overviewChartTimeTicks = []
    let lastOverviewChartInterval = {start: 0, end: 0}
    let lastOverviewChartTickRange = {start: 0, end: 0}
    overviewChartTimeTicks = updateChartAxisTicks(overviewChartDateAxis, overviewChartTimeTicks, lastOverviewChartTickRange, defaultOverviewChartInterval.start + chartOriginDate, defaultOverviewChartInterval.end + chartOriginDate)
    
    overviewChartDateAxis.onScaleChange((start, end) => {
        checkOverviewChartInterval(start + chartOriginDate, end + chartOriginDate)
    })


    chartRef.current = { mainChart, 
        mainChartDateAxis, 
        overviewChart, 
        overviewChartDateAxis, 
        dataSeries: {},
        mainChartTimeTicks, 
        overviewChartTimeTicks,
        lastSelectedGrows: [],
        lastMainChartInterval,
        lastOverviewChartInterval,
        lastMainChartTickRange,
        lastOverviewChartTickRange,
        lastMainDataRecordingTimePeriodType: null,
        lastMainChartDeltaType: null,
        growOutZone,
        growPositionIndicator: {},
        /*verticalRackGroup : {},
        verticalRacks: [],*/
        activeYAxes: {},
        lastEnergySubSelection: {start: 0, end: 0},
        energySubSelection: defaultMainChartInterval,
        energyTotals: {},
        energySubselectionActive,

        nutrientDosingBarsRequiresReset: true,
        nutrientDosingLargestVolume: 0,
        nutrientDosingBars: {},

        tempYAxisInterval: {},

        lastRenderLoopCompletedOn: 0,
        lastRequestLoopCompletedOn: 0
    }

    return () => {
        mainChart.dispose()
        overviewChart.dispose()
        mainChartTimeTicks.filter(tick => {
            tick.dispose()
                return false
        })
        mainChartTimeTicks.filter(tick => {
            tick.dispose()
                return false
        })
        lastMainChartTickRange = {start: 0, end: 0}
        lastOverviewChartTickRange = {start: 0, end: 0}
      chartRef.current = undefined
    }
  }, [mainChartTheme])


  React.useEffect(() => {
    if (!chartRef.current) 
        return

    const mainChartVisibleRange = getMainChartInterval()
    const overviewChartVisibleRange = getOverviewChartInterval()
    chartRef.current.mainChartTimeTicks = updateChartAxisTicks(chartRef.current.mainChartDateAxis, chartRef.current.mainChartTimeTicks, chartRef.current.lastMainChartTickRange, mainChartVisibleRange.start , mainChartVisibleRange.end)
    chartRef.current.overviewChartTimeTicks = updateChartAxisTicks(chartRef.current.overviewChartDateAxis, chartRef.current.overviewChartTimeTicks, chartRef.current.lastOverviewChartTickRange, overviewChartVisibleRange.start , overviewChartVisibleRange.end)


  }, [chartRef])

  React.useEffect(() => {
    if (!chartRef.current) 
        return
    chartRef.current.growOutZone = growOutZone

  }, [growOutZone])

  React.useEffect(() => {
    if (!chartRef.current) 
        return
    chartRef.current.energySubselectionActive = energySubselectionActive
    updateEnergySubselection()
    updateOverviewSubselection()

  }, [energySubselectionActive])





  let energySubSelectionStyleProps = {}
  if ((pointerOverMainEnergyGrabber === undefined && !draggingMainEnergyGrabber && pointerOverDosingInstance === undefined) || numberOfPointersDownOnMainCanvas > 1)    {
      energySubSelectionStyleProps["pointerEvents"] = "none"
  }




  


  return (<>
    <div className="GrowZones-DataAnalytics">
      <GridTile size="full">
        
        <div className="GrowZones-Charts" {...chartsContainerBind}>
          <div className="GrowZones-Charts-ChartingArea noselect"
            ref={chartingAreaRef}
            onPointerMove={chartingAreaPointerMove}
            onPointerDown={chartingAreaPointerDown}
            onPointerUp={chartingAreaPointerUp}
            onPointerLeave={chartingAreaPointerLeave}>
            <div className="GrowZones-Charts-MainChartingArea">
              <div className="GrowZones-Charts-MainChartingWrapper" {...mainChartAreaBind}>
                <div className="GrowZones-Charts-MainChartingContent">
                  <div id={"GrowZones-Charts-MainChart-" + growOutZone.id} className="GrowZones-Charts-MainChart"></div>
                  <canvas className="GrowZones-Charts-MainChart-SubselectedEnergyArea" 
                  ref={energySubSelectionCanvasRef}
                  style={energySubSelectionStyleProps}/>
                </div>
              </div>
              <div className="GrowZones-Charts-ComponentToggles">
                {Object.entries(componentToggles).map(([key, info]) => {
                  return (
                    <ComponentToggle name={key} key={key} info={info} onToggle={componentToggled} onDataTypeToggle={componentDataTypeToggled}/> 
                  ) 
                })}
                {/*<GrowManagerEnergyDataTypeToggle 
                  updateEnergyDataCallback={(energyDataUpdateFunc) => energyDataTypeToggleDataUpdate.current = energyDataUpdateFunc}
                  onEnergySubselectionToggle={energySubselectionToggled}
                  energySubselectionActive={energySubselectionActive}/>*/}
              </div>
            </div>
            <div className="GrowZones-Charts-OverviewChartingArea" {...overviewChartAreaBind}>
              <div className="GrowZones-Charts-OverviewChartingWrapper">
                <div className="GrowZones-Charts-OverviewChartingContent">
                  <div id={"GrowZones-Charts-OverviewChart-" + growOutZone.id} 
                    className="GrowZones-Charts-OverviewChart"
                    style={{cursor: overviewCursor}}></div>
                  <canvas className="GrowZones-Charts-OverviewChart-SubselectedArea" ref={overviewSubSelectionCanvasRef}/>
                </div>
              </div>
            </div>
          </div>
          <div className="GrowZones-Charts-DrawingBoard">
            {pointerOverMainChartDate !== undefined && drawTooltip()}
          </div>
        </div>

      </GridTile>
    </div>
  </>)
} 



const ComponentToggle = ({name, info, onToggle, onDataTypeToggle}) =>  {
    
  const groupedToggled = () =>    {
      if (onToggle !== undefined)    {
        onToggle(name, !info.selected)
      }
  }
  
  const onOptionChange = (option, selected) =>  {
      if (info.dataTypes[option.id] !== undefined)    {
          if (onDataTypeToggle !== undefined) {
              //onGroupToggle(name, true)
              onDataTypeToggle(info.dataTypes[option.id], selected)
          }
          //info.dataTypes[option.id].selected = selected
          //console.log(info.dataTypes[option.id])
      }
  }


  let options = []
  for (let dataType in info.dataTypes)    {
      options.push({
          id: dataType,
          value: dataType,
          label: info.dataTypes[dataType].label,
          selected: info.dataTypes[dataType].selected,
          prefixContent:(
              <div className="DataToggle-DataTypeColorIndicator" style={{backgroundColor:info.dataTypes[dataType].color}}/>
          ),            
      })
  }

  return (
      <GroupedOptions 
          uid={name} 
          label={info.label} 
          options={options}
          groupActive={info.selected}
          onGroupToggle={groupedToggled}
          onOptionChange={onOptionChange}/>
  )
}


export default GrowZoneDataAnalytics