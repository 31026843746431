import './RecipesPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate, useMeasure, useMeasureWithPosition, useMeasureWithRef } from '../../../helpers'
import {TabControl, TabControlTab} from '../../../components/TabControl.js';
import Button from '../../../components/Button.js';
import TextInput from '../../../components/input/TextInput';
import NumberInput from '../../../components/input/NumberInput';
import DropDownButton from '../../../components/DropDownButton.js';
import Checkbox from '@mui/material/Checkbox';
 
import { useParams, useNavigate, Route, Navigate, Routes} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { selectRecipeById, selectAllRecipes, recipeChanged, timelineItemChanged, pushRecipeChange } from '../../../redux/entities/Recipes'

import {BiGridVertical} from 'react-icons/bi'
import {FaTrashAlt} from 'react-icons/fa'
import {HiOutlineDuplicate} from 'react-icons/hi'

const RecipeTimelinePage = ({
    recipe, isEditingRecipe
  }) => {
  const dispatch = useDispatch()
  const [, forceRerender] = React.useReducer(x => x + 1, 0);

  const scrollContainerRef = React.useRef(null);

  const [allActions, SetAllActions] = React.useState([
    {type: "action", key: 'plant_seeds', label: 'Plant Seeds', single: true},
    {type: "action", key: 'place_into_germination_chamber', label: 'Place Into Germination (Cycles)', single: true},
    {type: "duration_action", key: 'place_into_germination_chamber_duration', label: 'Place Into Germination (Duration)', single: true},
    {type: "action", key: 'transplant_to_nursery', label: 'Transplant To Nursery', single: true},
    {type: "action", key: 'transplant_to_grow_zone', label: 'Transplant To Grow Out', single: true},
    {type: "action", key: 'harvest', label: 'Harvest', single: true},
  ]);

  const [availableActions, SetAvailableActions] = React.useState([])

  const [timelineItems, SetTimelineItems] = React.useState([]);

  const [germinationCycles, SetGerminationCycles] = React.useState([]);
  const [nurseryCycles, SetNurseryCycles] = React.useState([]);
  const [growZoneCycles, SetGrowZoneCycles] = React.useState([]);

  const [haveInitialTimelineAction, SetHaveInitialTimelineAction] = React.useState(false);
  const [isShowingNextInFlowButtons, SetIsShowingNextInFlowButtons] = React.useState(false);
  

  React.useEffect(() => {
    if (recipe === undefined) 
      return
    let currentTimelineItems = []
    let currentGerminationCycles = []
    let currentNurseryCycles = []
    let currentGrowZoneCycles = []
    let currentIsShowingNextInFlowButtons = false
    let currentAvailableActions = [...allActions]


    if (recipe.starter_product_id)  {
      currentTimelineItems.push({
        fixed: true,
        type: "starter_product",
        name: recipe.starter_product_id,
      })
    }
    if (recipe.timeline_items != null && recipe.timeline_items.length > 0) {
      let recipeTimelineItems = [...recipe.timeline_items].sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
      SetHaveInitialTimelineAction(true)
      
      for (let timelineItem of recipeTimelineItems) {
        const isLastTimelineItem = (timelineItem.index == recipeTimelineItems[recipeTimelineItems.length - 1].index)
        if (timelineItem.type === "action") {

          let foundActionType = allActions.find((a) => a.key === timelineItem.item.type)
          if (foundActionType !== undefined)  {
            currentAvailableActions.splice(currentAvailableActions.indexOf(foundActionType), 1)

            switch (timelineItem.item.type)  {
              case "place_into_germination_chamber_duration":
                if (isLastTimelineItem) {
                  currentIsShowingNextInFlowButtons = true
                  currentTimelineItems.push({
                    ...timelineItem,
                    nextButtons: [{
                      label: "Add Transplant",
                      actions: [
                        {key: 'transplant_to_nursery', label: 'Transplant To Nursery'},
                        {key: 'transplant_to_grow_zone', label: 'Transplant To Grow Out'},
                      ] 
                    }, {
                      label: "Add Action",
                      actions: [...availableActions]
                    }]
                  })
                }else {
                  currentTimelineItems.push({...timelineItem})                  
                }
                break
              case "place_into_germination_chamber":
                currentTimelineItems.push({
                  ...timelineItem,
                  isGerminationCycleGroup: true
                })
                break
              
              case "transplant_to_nursery":
                currentTimelineItems.push({
                  ...timelineItem,
                  isNurseryCycleGroup: true
                })
                break
              case "transplant_to_grow_zone":
                currentTimelineItems.push({
                  ...timelineItem,
                  isGrowZoneCycleGroup: true
                })
                break
              

              default: //Probably a random action
              currentTimelineItems.push(timelineItem)
                break
            }
          }

        }else if (timelineItem.type === "duration_action") {
          let foundActionType = allActions.find((a) => a.key === timelineItem.item.type)
          if (foundActionType !== undefined)  {
            currentAvailableActions.splice(currentAvailableActions.indexOf(foundActionType), 1)
            currentTimelineItems.push({...timelineItem})        
          }  
        }else if (timelineItem.type === "germination_cycle") {
          currentGerminationCycles.push({...timelineItem})
        }else if (timelineItem.type === "nursery_cycle") {
          currentNurseryCycles.push({...timelineItem})
        }else if (timelineItem.type === "grow_zone_cycle") {
          currentGrowZoneCycles.push({...timelineItem})
        }
      }
    }else {
      SetHaveInitialTimelineAction(false)
    }
    //console.log(recipe, timeline_items)
    SetTimelineItems(currentTimelineItems)
    SetGerminationCycles(currentGerminationCycles)
    SetNurseryCycles(currentNurseryCycles)
    SetGrowZoneCycles(currentGrowZoneCycles)
    SetIsShowingNextInFlowButtons(currentIsShowingNextInFlowButtons)
    SetAvailableActions(currentAvailableActions)
  }, [recipe])



  const addNewAction = ({actionKey, index}) => {
    const foundAction = allActions.find((a) => a.key === actionKey)
    if (foundAction === undefined)
      return

    if (index === undefined)  {
      index = recipe.timeline_items.length + 1
    }

    let multiAdd = (foundAction.key === "transplant_to_nursery" || foundAction.key === "transplant_to_grow_zone")


    let item = {
      type: actionKey,
      name: foundAction.label,
    }

    switch (actionKey) {
      case "place_into_germination_chamber_duration":
        item.duration = 60 * 60 * 24
        break
      default:
        break
    }


    //Shift index of all items that belong after + add the new item
    let updatedTimelineItems = [...recipe.timeline_items.map((timelineItem, itemIndex) => {
      if (timelineItem.index < index) {
        return timelineItem
      }
      return {...timelineItem, index: timelineItem.index + (multiAdd ? 2 : 1)}
    }), {
      id: recipe.currentTimelineItemTempId,
      index: index,
      type: foundAction.type,
      item: item
    }]

    //Add first cycle if necessary
    if (foundAction.key === "transplant_to_nursery") {
      updatedTimelineItems.push({
        id: recipe.currentTimelineItemTempId + 1,
        index: index + 1,
        type: "nursery_cycle",
        item: {
          created_on: new Date().toISOString(),
          /*currentSetpointTempId: 2,*/
          duration: 60 * 60 * 24,
          index: 1,
          iterations: 1,
          lighting_intensity_setpoints: [
            {id: 1,
            index: 1,
            time: 0,
            value: 0,
            function: "instant",
            function_params: {}}
          ],
          lighting_spectrum_ratios: {
            red: 70,
            green: 8,
            blue: 5,
            farred: 17,
          },
          name: "New Cycle",
          nutrient_recipe: {parts: []},
          relationships: [],
          setpoints: [],
        }
      })
    }else if (foundAction.key === "transplant_to_grow_zone") {
      updatedTimelineItems.push({
        id: recipe.currentTimelineItemTempId + 1,
        index: index + 1,
        type: "grow_zone_cycle",
        item: {
          created_on: new Date().toISOString(),
          /*currentSetpointTempId: 2,*/
          duration: 60 * 60 * 24,
          index: 1,
          iterations: 1,
          lighting_intensity_setpoints: [
            {id: 1,
            index: 1,
            time: 0,
            value: 0,
            function: "instant",
            function_params: {}}
          ],
          lighting_spectrum_ratios: {
            red: 70,
            green: 8,
            blue: 5,
            farred: 17,
          },
          name: "New Cycle",
          nutrient_recipe: {parts: []},
          relationships: [],
          setpoints: [],
        }
      })
    }

    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: updatedTimelineItems,
      currentTimelineItemTempId: recipe.currentTimelineItemTempId + (multiAdd ? 2 : 1)
    }}))
  }


  const updateTimelineItem = React.useCallback((timelineItem) => {
    let updatedTimelineItems = [...recipe.timeline_items.map((currentTimelineItem) => {
      if (currentTimelineItem.index !== timelineItem.index) {
        return currentTimelineItem
      }
      return {...timelineItem}
    })]


    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: updatedTimelineItems
    }}))
  })

  const deleteTimelineItem = React.useCallback((timelineItem) => {
    
    let updatedTimelineItems = [...recipe.timeline_items.filter((currentTimelineItem) => {
      if (currentTimelineItem.index !== timelineItem.index) {
        return true
      }
      return false
    })]


    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: updatedTimelineItems
    }}))
  })



  const createCycle = React.useCallback((type) => {
    let existingCycles = []
    switch (type) {
      case "germination_cycle":
        existingCycles = germinationCycles
        break
      case "nursery_cycle":
        existingCycles = nurseryCycles
        break
      case "grow_zone_cycle":
        existingCycles = growZoneCycles
        break
      default:
        break
    }

    let conflict = true
    let newCycleName = "New Cycle"
    let newCycleIndex = existingCycles[existingCycles.length - 1].index + 1
    while (conflict)  {
      let match = newCycleName.match(/^(.+ )(\d+)$/);
      newCycleName = (match ? match[1] + (+match[2] + 1) : newCycleName + ' 2')
      if (existingCycles.find((c) => c.name === newCycleName) === undefined)  {
        conflict = false
      }
    }

    let updatedTimelineItems = [...recipe.timeline_items.map((timelineItem, itemIndex) => {
      if (timelineItem.index < newCycleIndex) {
        return timelineItem
      }
      return {...timelineItem, index: timelineItem.index + 1}
    }), {
      id: recipe.currentTimelineItemTempId,
      type: type,
      index: existingCycles[existingCycles.length - 1].index + 1,
      item: {
        created_on: new Date().toISOString(),
        /*currentSetpointTempId: 2,*/
        duration: 60 * 60 * 24,
        index: existingCycles[existingCycles.length - 1].item.index + 1,
        iterations: 1,
        lighting_intensity_setpoints: [
          {id: 1,
          index: 1,
          time: 0,
          value: 0,
          function: "instant",
          function_params: {}}
        ],
        lighting_spectrum_ratios: {
          red: 70,
          green: 8,
          blue: 5,
          farred: 17,
        },
        name: newCycleName,
        nutrient_recipe: {parts: []},
        relationships: [],
        setpoints: [],
      }
    }]


    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: updatedTimelineItems,
      currentTimelineItemTempId: recipe.currentTimelineItemTempId + 1
    }}))
  })
  
  const duplicateCycle = React.useCallback((timelineItem, type) => {
    let cycle = timelineItem.item
    
    let existingCycles = []
    switch (type) {
      case "germination_cycle":
        existingCycles = germinationCycles
        break
      case "nursery_cycle":
        existingCycles = nurseryCycles
        break
      case "grow_zone_cycle":
        existingCycles = growZoneCycles
        break
      default:
        break
    }


    let conflict = true
    let newCycleName = cycle.name
    while (conflict)  {
      let match = newCycleName.match(/^(.+\()(\d+)(\))$/);
      newCycleName = (match ? match[1] + (+match[2] + 1) + match[3] : newCycleName + '(1)')
      if (existingCycles.find((c) => c.name === newCycleName) === undefined)  {
        conflict = false
      }
    }

    let updatedTimelineItems = [...recipe.timeline_items.map((currentTimelineItem, itemIndex) => {
      if (currentTimelineItem.index <= timelineItem.index) {
        return currentTimelineItem
      }
      return {...currentTimelineItem, index: currentTimelineItem.index + 1}
    }), {
      id: recipe.currentTimelineItemTempId,
      type: type,
      index: timelineItem.index + 1,
      item: {
        created_on: new Date().toISOString(),
        currentSetpointTempId: 1,
        duration: cycle.duration,
        index: cycle.index + 1,
        iterations: cycle.iterations,
        lighting_intensity_setpoints: [...cycle.lighting_intensity_setpoints],
        lighting_spectrum_ratios: {...cycle.lighting_spectrum_ratios},
        name: newCycleName,
        nutrient_recipe: {...cycle.nutrient_recipe},
        relationships: cycle.relationships ? [...cycle.relationships] : [],
        setpoints: [...cycle.setpoints],
      }
    }]


    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: updatedTimelineItems,
      currentTimelineItemTempId: recipe.currentTimelineItemTempId + 1
    }}))
  })

  const deleteCycle = React.useCallback((timelineItem, type) => {
    let removeAssociatedAction = null
    switch (type) {
      case "germination_cycle":
        if (germinationCycles.length === 1) {
          removeAssociatedAction = true
        }
        break
      case "nursery_cycle":
        if (nurseryCycles.length === 1) {
          removeAssociatedAction = true
        }
        break
      case "grow_zone_cycle":
        if (growZoneCycles.length === 1) {
          removeAssociatedAction = true
        }
        break
      default:
        break
    }

    let updatedTimelineItems = [...recipe.timeline_items.filter((currentTimelineItem) => {
      if (currentTimelineItem.index !== timelineItem.index && (!removeAssociatedAction || currentTimelineItem.index !== timelineItem.index - 1)) {
        return true
      }
      return false
    })]


    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: updatedTimelineItems
    }}))
  })



  const [timelineItemReordering, SetTimelineItemReordering] = React.useState(undefined)
  const [timelineItemReorderingOffset, SetTimelineItemReorderingOffset] = React.useState({x: 0, y: 0})
  const timelineItemReorderingOffsetRef = React.useRef({x: 0, y: 0})
  React.useEffect(() => {timelineItemReorderingOffsetRef.current = timelineItemReorderingOffset}, [timelineItemReorderingOffset])
  const [pointerPosition, SetPointerPosition] = React.useState({x: 0, y: 0})
  const [timelineItemReorderingCompleted, SetTimelineItemReorderingCompleted] = React.useState(false)
  const [timelineItemReorderingHasChanged, SetTimelineItemReorderingHasChanged] = React.useState(false)
  const reorderingTimelineItemRef = React.useRef(undefined)


  

  const onReorderBegin = (e, timelineItem, offset) =>  {
    SetTimelineItemReordering(timelineItem)
    SetTimelineItemReorderingOffset(offset)
    SetPointerPosition({x: e.clientX, y: e.clientY})

    if (reorderingTimelineItemRef.current)  {
      reorderingTimelineItemRef.current.style.top = (e.clientY - offset.y) + "px"
      reorderingTimelineItemRef.current.style.left = (e.clientX - offset.x) + "px"
    }
  }
  const onReorderMove = (e) =>  {
    
    SetPointerPosition({x: e.clientX, y: e.clientY})

    if (reorderingTimelineItemRef.current)  {
      reorderingTimelineItemRef.current.style.top = (e.clientY - timelineItemReorderingOffsetRef.current.y) + "px"
      reorderingTimelineItemRef.current.style.left = (e.clientX - timelineItemReorderingOffsetRef.current.x) + "px"
    }
  }

  React.useEffect(() => {
    if (timelineItemReordering) {
      //First lets check if we are a cycle type
      let timelineItemsToManage = []
      switch (timelineItemReordering.type) {
        case "germination_cycle":
          timelineItemsToManage = germinationCycles
          break
        case "nursery_cycle":
          timelineItemsToManage = nurseryCycles
          break
        case "grow_zone_cycle":
          timelineItemsToManage = growZoneCycles
          break
        default:
          timelineItemsToManage = timelineItems
          break
      }
      if (!timelineItemReorderingCompleted) {

        

        //Now that we know the list of cycles that are potential movers, lets work out any position changes
        let overTimelineItem = undefined
        for (let timelineItem of timelineItemsToManage) {
          if (pointerPosition.y >= timelineItem.containerBounds.top && pointerPosition.y <= timelineItem.containerBounds.top + timelineItem.containerBounds.height)  {
            overTimelineItem = timelineItem
          }
        }
        //Check if we determined we are over an item
        let timelineChanged = false
        if (overTimelineItem !== undefined)  {
          let foundOriginalTimelineItem = timelineItemsToManage.find((t) => t.id === timelineItemReordering.id)

          //Either -- we aren't over our original location and we haven't shifted yet
          if (timelineItemReordering.index !== overTimelineItem.index && foundOriginalTimelineItem.temp_index !== overTimelineItem.index)  {
            foundOriginalTimelineItem.temp_index = overTimelineItem.index

            for (let timelineItem of timelineItemsToManage) {
              //First, check if we found the dragging item, if so move it to the over items position
              if (timelineItemReordering.id !== timelineItem.id) {
                if (timelineItemReordering.index < overTimelineItem.index && timelineItem.index > timelineItemReordering.index && timelineItem.index <= overTimelineItem.index)  {
                  timelineItem.temp_index =  timelineItem.index - 1

                }else if (timelineItemReordering.index > overTimelineItem.index && timelineItem.index < timelineItemReordering.index && timelineItem.index >= overTimelineItem.index)  {
                  timelineItem.temp_index =  timelineItem.index + 1
                }else {
                  timelineItem.temp_index = timelineItem.index
                }
              }
            }

            timelineItemsToManage.sort((a, b) => a.temp_index - b.temp_index);
            timelineChanged = true
            SetTimelineItemReorderingHasChanged(true)

          //Or we moved back to our original location
          }else if (timelineItemReordering.temp_index !== undefined && timelineItemReordering.index === overTimelineItem.index) {
            for (let timelineItem of timelineItemsToManage) {
              delete timelineItem.temp_index
            }
            timelineItemsToManage.sort((a, b) => a.index - b.index);
            timelineChanged = true
            SetTimelineItemReorderingHasChanged(false)
          }

          if (timelineChanged)  {
            
            switch (timelineItemReordering.type) {
              case "germination_cycle":
                SetGerminationCycles(timelineItemsToManage)
                break
              case "nursery_cycle":
                SetNurseryCycles(timelineItemsToManage)
                break
              case "grow_zone_cycle":
                SetGrowZoneCycles(timelineItemsToManage)
                break
              default:
                break
            }

          }


        }

      }else  {
        if (timelineItemReorderingHasChanged) {
          

          let updatedTimelineItems = [...recipe.timeline_items.map((timelineItem) => {
            if (timelineItem.type !== timelineItemReordering.type)
              return timelineItem

        
            let foundTimelineItem = timelineItemsToManage.find((g) => g.id === timelineItem.id)
            if (foundTimelineItem === undefined || foundTimelineItem.temp_index === undefined)  {
              return timelineItem
            }
            return {...timelineItem, index: foundTimelineItem.temp_index}
             
          })]
          updatedTimelineItems.sort((a, b) => a.index - b.index);
      
          dispatch(pushRecipeChange({recipe: {...recipe, 
            timeline_items: updatedTimelineItems
          }}))

        }

        SetTimelineItemReorderingCompleted(false)
        SetTimelineItemReordering(undefined)
        SetTimelineItemReorderingOffset({x: 0, y: 0})
        SetPointerPosition({x: 0, y: 0})
      }
    }
  }, [
    timelineItemReordering, timelineItemReorderingCompleted, timelineItemReorderingHasChanged,
    timelineItemReorderingOffset, pointerPosition, 
    germinationCycles, nurseryCycles, growZoneCycles, timelineItems])


  const onReorderEnd = (e) =>  {
    SetTimelineItemReorderingCompleted(true)
  }



  

  return (
    
    <div id="Recipe-TimelineManager" ref={scrollContainerRef}>
      {/*
      <div className="ControlBar_Horizontal">
        <div className="ControlBar_Horizontal-Left ControlBar_Horizontal-Overflow">
          <Button content={"Create Cycle"} onClick={createTimelineItem}/>
          <DropDownButton actions={[{ key: 'add_transplant', label: 'Add Transplant' }]} label="Add Action"/>
        </div>
  </div>*/}
      <div id="Recipe-TimelineItems">
        {timelineItems.map((timelineItem, index) => {
          let timelineActionContent
          switch (timelineItem.type)  {
            case "starter_product":
              const actionsToAdd = [
                {key: 'plant_seeds', label: 'Plant Seeds'}
              ]
              return (
                <div key="started_product" className="Recipe-TimelineItem-FixedItem">
                  <div className="Recipe-TimelineItem-FixedItem-Content">
                    Starts With: {timelineItem.name}
                  </div>
                  {(isEditingRecipe && !haveInitialTimelineAction) &&
                    <>
                      <DropDownButton 
                        status="Primary"
                        actions={actionsToAdd} 
                        label="Add First Action"
                        onActionClicked={(actionKey) => addNewAction({actionKey: actionKey, index: 1})}/>
                    </>
                  }
                </div>
              )
            case "duration_action":
              switch (timelineItem.item.type) {

                case "place_into_germination_chamber_duration":
                  const durationChanged = (newDuration) =>  {
                    let result = newDuration
                    if (newDuration < 1)  {
                      return 1
                    }
                
                    return result
                  }
                
                  const durationChangeFinalize = (newDuration) =>  {
                    updateTimelineItem({...timelineItem, item: {...timelineItem.item,
                      duration: newDuration * 3600
                    }})
                  }
                  let duration = timelineItem.item.duration
                  if (duration < 0)
                    duration = 60 * 60
                  timelineActionContent = <>
                    <div className="Recipe-TimelineItem-Action-Name">
                      Place into Germination Chamber for
                    </div>
                    <div className="Recipe-TimelineItem-Cycle-Iterations">
                      <NumberInput value={timelineItem.item.duration / (60 * 60)} suffix="hours" maxLength={4} onChange={durationChanged} onBlur={durationChangeFinalize}/>
                    </div>
                  </>
                  
                  break
                default: 
                  timelineActionContent = timelineItem.item.name
              }

              return (
                <div key={timelineItem.index} className="Recipe-TimelineItem-Action">
                  <div className="Recipe-TimelineItem-Action-ContentWrapper">
                    <div className="Recipe-TimelineItem-Action-Content">
                      {timelineActionContent}
                    </div>
                    {isEditingRecipe &&
                      <div className="Recipe-TimelineItem-Action-FunctionsContainer">
                        <div className="Recipe-TimelineItem-Action-Functions">
                          <div className="Button Button-Critical Button-Small Button-WithBorder" onClick={() => {deleteTimelineItem(timelineItem)}}>
                            <div>
                              <FaTrashAlt className="Recipe-TimelineItem-Trash"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  {(isEditingRecipe && timelineItem.nextButtons !== undefined) &&
                    <div className="Recipe-TimelineItem-NextInFlowButtons">
                      {timelineItem.nextButtons.map((button, index) => {

                        return (
                          <DropDownButton
                            key={index}
                            status="Primary"
                            actions={button.actions} 
                            label={button.label}
                            onActionClicked={(actionKey) => addNewAction({actionKey: actionKey})}/>
                        )
                      })}
                    </div>
                  }
                </div>
              )
              break
              
            case "action":
              switch (timelineItem.item.type) {

                case "transplant_to_nursery":
                  return (
                    <div key={timelineItem.index} className="Recipe-TimelineItem-CycleGroup">
                      <div className="Recipe-TimelineItem-CycleGroup-Header">
                        <div className="Recipe-TimelineItem-CycleGroup-Header-Content">
                          {timelineItem.item.name}
                        </div>
                      </div>
                      <div className="Recipe-TimelineItem-CycleGroup-List">
                        {nurseryCycles.map((nurseryCycle) => {
                          
                          
                          const customProps = {}
                          if (timelineItemReordering !== undefined && timelineItemReordering.id === nurseryCycle.id)  {
                            customProps.reorderingTimelineItem = true
                          }
                          return (
                            <GrowZoneCycleTimelineItem
                              key={nurseryCycle.id}
                              isEditingRecipe={isEditingRecipe}
                              timelineItem={nurseryCycle}
                              canReorder={nurseryCycles.length > 1}
                              onReorderBegin={onReorderBegin} 
                              onReorderMove={onReorderMove} 
                              onReorderEnd={onReorderEnd}
                              scrollContainerRef={scrollContainerRef}
                              onDuplicate={(cycle) => duplicateCycle(cycle, "nursery_cycle")}
                              onDelete={(cycle) => deleteCycle(cycle, "nursery_cycle")}
                              canDelete={nurseryCycles.length > 1}
                              onUpdate={updateTimelineItem}
                              {...customProps}/>
                          )
                        })}
                        <Button content={"Add Cycle"} onClick={() => createCycle("nursery_cycle")}/>
                      </div>
                    </div>
                  )
                  break
                
                case "transplant_to_grow_zone":
                  return (
                    <div key={timelineItem.index} className="Recipe-TimelineItem-CycleGroup">
                      <div className="Recipe-TimelineItem-CycleGroup-Header">
                        <div className="Recipe-TimelineItem-CycleGroup-Header-Content">
                          {timelineItem.item.name}
                        </div>
                      </div>
                      <div className="Recipe-TimelineItem-CycleGroup-List">
                        {growZoneCycles.map((growZoneCycle) => {
                          
                          
                          const customProps = {}
                          if (timelineItemReordering !== undefined && timelineItemReordering.id === growZoneCycle.id)  {
                            customProps.reorderingTimelineItem = true
                          }
                          return (
                            <GrowZoneCycleTimelineItem
                              key={growZoneCycle.id}
                              isEditingRecipe={isEditingRecipe}
                              timelineItem={growZoneCycle}
                              canReorder={growZoneCycles.length > 1}
                              onReorderBegin={onReorderBegin} 
                              onReorderMove={onReorderMove} 
                              onReorderEnd={onReorderEnd}
                              scrollContainerRef={scrollContainerRef}
                              onDuplicate={(cycle) => duplicateCycle(cycle, "grow_zone_cycle")}
                              onDelete={(cycle) => deleteCycle(cycle, "grow_zone_cycle")}
                              canDelete={growZoneCycles.length > 1}
                              onUpdate={updateTimelineItem}
                              {...customProps}/>
                          )
                        })}
                        <Button content={"Add Cycle"} onClick={() => createCycle("grow_zone_cycle")}/>
                      </div>
                    </div>
                  )
                  break

                default: 
                  return (
                    <div key={timelineItem.index} className="Recipe-TimelineItem-Action">
                      <div className="Recipe-TimelineItem-Action-ContentWrapper">
                        <div className="Recipe-TimelineItem-Action-Content">
                          {timelineItem.item.name}
                        </div>
                        {isEditingRecipe &&
                          <div className="Recipe-TimelineItem-Action-FunctionsContainer">
                            <div className="Recipe-TimelineItem-Action-Functions">
                              <div className="Button Button-Critical Button-Small Button-WithBorder" onClick={() => {deleteTimelineItem(timelineItem)}}>
                                <div>
                                  <FaTrashAlt className="Recipe-TimelineItem-Trash"/>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      {(isEditingRecipe && timelineItem.nextButtons !== undefined) &&
                        <div className="Recipe-TimelineItem-NextInFlowButtons">
                          {timelineItem.nextButtons.map((button) => {
    
                            return (
                              <DropDownButton 
                                status="Primary"
                                actions={button.actions} 
                                label={button.label}
                                onActionClicked={(actionKey) => addNewAction({actionKey: actionKey})}/>
                            )
                          })}
                        </div>
                      }
                    </div>
                  )
              }

            default:
              return (<div key={timelineItem.index}></div>)
          }
        })}

        {(!isShowingNextInFlowButtons && haveInitialTimelineAction) &&
          <DropDownButton 
            status="Primary"
            actions={availableActions} 
            label="Add Action"
            onActionClicked={(actionKey) => addNewAction({actionKey: actionKey})}/>
        }

        {timelineItemReordering && 
          <>
            {/*<div id="Recipe-TimelineItem-ReorderingTimelineItem-BlockingInteractivity"/>*/}
            <div id="Recipe-TimelineItem-ReorderingTimelineItem" ref={reorderingTimelineItemRef}>
              {timelineItemReordering.type === "germination_cycle" && 
                <GrowZoneCycleTimelineItem
                  timelineItem={timelineItemReordering}
                  isForReordering={true}/>
              }
              {timelineItemReordering.type === "nursery_cycle" && 
                <GrowZoneCycleTimelineItem
                  timelineItem={timelineItemReordering}
                  isForReordering={true}/>
              }
              {timelineItemReordering.type === "grow_zone_cycle" && 
                <GrowZoneCycleTimelineItem
                  timelineItem={timelineItemReordering}
                  isForReordering={true}/>
              }
            </div>
          </>
        }
      </div>
    </div>
                 
  )
} 











const GrowZoneCycleTimelineItem = ({
    recipe, timelineItem, isEditingRecipe,
    canReorder, reorderingTimelineItem, isForReordering, 
    onReorderBegin, onReorderMove, onReorderEnd, scrollContainerRef,
    onUpdate, onDuplicate, onDelete, canDelete,
    getContainerRef, updateBounds
  }) => {
  const dispatch = useDispatch()
  const [, forceRerender] = React.useReducer(x => x + 1, 0);
  const [containerBounds, SetContainerBounds] = React.useState(false)
  const [pointerId, SetPointerId] = React.useState(null)

 


  React.useEffect(() => {
    if (getContainerRef !== undefined)  {
      getContainerRef(containerBind.ref)
    }
  }, [])

  

  const [containerBind, forceBoundsUpdate, { height: containerHeight, width: containerWidth, documentLeft: containerLeft, documentTop: containerTop}] = useMeasureWithPosition({scrollContainerRef: scrollContainerRef}, (bounds) => {
    if (bounds === undefined || isForReordering || timelineItem.temp_index !== undefined)
      return
    
    timelineItem.containerBounds = {}
    timelineItem.containerBounds.width = bounds.width
    timelineItem.containerBounds.height = bounds.height
    timelineItem.containerBounds.left = bounds.documentLeft
    timelineItem.containerBounds.top = bounds.documentTop


  })

  React.useEffect(() => {
    forceBoundsUpdate(true)    
  }, [])
  React.useEffect(() => {
    forceBoundsUpdate(true)
  }, [containerBind])

  const hourDuration = 60 * 60
  const dayDuration = hourDuration * 24

  const totalCycleDuration = timelineItem.item.duration * timelineItem.item.iterations
  const durationInHours = timelineItem.item.duration  / hourDuration
  const totalDays = Math.floor(totalCycleDuration / dayDuration)
  const remainingHours = (totalCycleDuration - (totalDays * dayDuration)) / hourDuration

  let totalDurationInHoursDisplay = ""
  if (totalDays !== 0) {
    totalDurationInHoursDisplay += totalDays.toString() + "d"
    if (remainingHours !== 0)  {
      totalDurationInHoursDisplay += " " + remainingHours.toString() + "h"
    }
  }else {
    totalDurationInHoursDisplay += remainingHours.toString() + "h"
  }


  const [currentName, SetCurrentName] = React.useState("")

  React.useEffect(() => {
    if (currentName !== timelineItem.item.name) {
      SetCurrentName(timelineItem.item.name)
    }
    forceBoundsUpdate(true)
  }, [timelineItem])

  const nameChanged = (newName) =>  {
    let result = newName

    return result
  }
  const nameChangeFinalized = (newName) =>  {
    if (onUpdate !== undefined && timelineItem.item.name !== newName) {
      onUpdate({...timelineItem, item: {...timelineItem.item,
        name: newName
      }})
    }  
  }

  const iterationsChanged = (newIterations) =>  {
    let result = newIterations
    if (newIterations < 1)  {
      return 1
    }

    return result
  }

  const iterationsChangeFinalize = (newIterations) =>  {
    if (onUpdate !== undefined && timelineItem.item.iterations !== newIterations) {
      onUpdate({...timelineItem, item: {...timelineItem.item,
        iterations: newIterations
      }})
    }  
  }

  const durationChanged = (newDuration) =>  {
    let result = newDuration
    if (newDuration < 1)  {
      return 1
    }

    return result
  }

  const durationChangeFinalize = (newDuration) =>  {
    if (onUpdate !== undefined && timelineItem.item.duration !== newDuration * 3600) {
      onUpdate({...timelineItem, item: {...timelineItem.item,
        duration: newDuration * 3600
      }})
    }  
  }


  const reorderButtonRef = React.useRef(null)
  const [isReordering, SetIsReordering] = React.useState(false)
  const [isStartingReorder, SetIsStartingReorder] = React.useState(false)
  const [isCurrentlyReordering, SetIsCurrentlyReordering] = React.useState(false)
  React.useEffect(() => {
    if (reorderingTimelineItem !== isReordering) {
      SetIsReordering(reorderingTimelineItem)
    }
  }, [reorderingTimelineItem])

  const reorderTimelineItemPointerDown = React.useCallback((e) =>  {
    e.preventDefault()
    e.stopPropagation()
    SetIsStartingReorder(true)
    SetIsCurrentlyReordering(false)
    forceBoundsUpdate()
  })
  
  const reorderTimelinePreventScroll = React.useCallback((e) => {
    scrollContainerRef.current.addEventListener("touchmove", handlePreventScroll, { passive: false })
  })

  const reorderTimelineItemPointerMove = React.useCallback((e) =>  {
    if (isStartingReorder && !isCurrentlyReordering) {
      
      SetIsStartingReorder(false)
      SetIsCurrentlyReordering(true)

      if (onReorderBegin !== undefined) {
        let pointerPosition = {top: 0, left: 0}
        pointerPosition.top = e.clientY
        pointerPosition.left = e.clientX
        const pointerOffset = {y: pointerPosition.top - containerTop, x: pointerPosition.left - containerLeft}

        SetIsReordering(true)
        onReorderBegin(e, timelineItem, pointerOffset)

        document.addEventListener("pointermove", handleReorderMove)
        document.addEventListener("pointerup", handleReorderUp)
        /*if (document.setPointerCapture)    {
          SetPointerId(e.pointerId)
          document.setPointerCapture(e.pointerId);
        }*/


      }
    }
  })

  const handleReorderMove = React.useCallback((e) => {
    if (onReorderMove !== undefined) {
      onReorderMove(e)
    }
  })

  const handleReorderUp = React.useCallback((e) => {
    if (onReorderEnd !== undefined) {
      onReorderEnd(e)
    }
    document.removeEventListener("pointermove", handleReorderMove)
    document.removeEventListener("pointerup", handleReorderUp)
    scrollContainerRef.current.removeEventListener("touchmove", handlePreventScroll, { passive: false })
    SetIsStartingReorder(false)
    SetIsCurrentlyReordering(false)
    SetIsReordering(false)
  })

  const handlePreventScroll = React.useCallback((e) => {
    e.preventDefault()
    return false
  })


  /*const reorderTimelineItemPointerUp = React.useCallback((e) =>  {
    if (isCurrentlyReordering && onReorderEnd !== undefined) {
      onReorderEnd(e)
      /*if (reorderButtonRef.current !== undefined && reorderButtonRef.current.releasePointerCapture)    {
        reorderButtonRef.current.releasePointerCapture(e.pointerId);
        SetPointerId(null)
      }*
    }
    SetIsStartingReorder(false)
    SetIsCurrentlyReordering(false)
    SetIsReordering(false)
  })*/
  


  const containerProps = {style: {}, ...containerBind}
  if (reorderingTimelineItem) {
    containerProps.style.visibility = "hidden"
  }
  /*if (isForReordering) {
    containerProps.style = {
      position: "fixed",
    }
  }*/


  return (
    <div
      key={timelineItem.id}
      className="Recipe-TimelineItem-Cycle"
      {...containerProps}>
      <div className="Recipe-TimelineItem-Cycle-Content">
        <div className="Recipe-TimelineItem-Mover">
          {(isEditingRecipe && canReorder) &&
            <div className="Button Button-Neutral Button-Small"
              onPointerDown={reorderTimelineItemPointerDown}
              onPointerMove={reorderTimelineItemPointerMove}
              onTouchStart={reorderTimelinePreventScroll}
              ref={reorderButtonRef}>
              <div> 
                <BiGridVertical/>
              </div>
            </div>
          }
        </div>
        <div className="Recipe-TimelineItem-Cycle-Name">
          <TextInput value={currentName} onChange={nameChanged} onBlur={nameChangeFinalized}/>
        </div>
        <div className="Recipe-TimelineItem-Cycle-Iterations">
          <NumberInput value={timelineItem.item.iterations} suffix="iterations" maxLength={4} onChange={iterationsChanged} onBlur={iterationsChangeFinalize}/>
        </div>
        <div className="Recipe-TimelineItem-Cycle-Duration">
          <NumberInput value={durationInHours} suffix="hours" maxLength={2} onChange={durationChanged} onBlur={durationChangeFinalize} min={1} max={48}/>
        </div>
        <div className="Recipe-TimelineItem-Cycle-TotalDurationDisplay noselect">
          {totalDurationInHoursDisplay}
        </div>
      </div>
      {isEditingRecipe &&
        <div className="Recipe-TimelineItem-Cycle-FunctionsContainer">
          <div className="Recipe-TimelineItem-Cycle-Functions">
            <div className="Button Button-Neutral Button-Small Button-WithBorder" onClick={() => {if (onDuplicate !== undefined) {onDuplicate(timelineItem)}}}>
              <div>
                <HiOutlineDuplicate className="Recipe-TimelineItem-Duplicate"/>
              </div>
            </div>
            <div className="Button Button-Critical Button-Small Button-WithBorder" onClick={() => {if (onDelete !== undefined) {onDelete(timelineItem)}}}>
              <div>
                <FaTrashAlt className="Recipe-TimelineItem-Trash"/>
              </div>
            </div>
          </div>
        </div>
      }
    </div>

  )
}




export default RecipeTimelinePage