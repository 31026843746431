import './../ManageNDSWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackPowerOnTutorial, RackStandardGroup, ScanRack } from '../../../../assets/icons/RackIcons';
import { useSelector } from 'react-redux';
import { selectAllVerticalRackGroups } from '../../../../redux/entities/service/VerticalRackGroup';
import { ScanNDS } from '../../../../assets/icons/NDSIcons';


  

const ConfirmNewNDSWalkthroughStep = ({NDSName, ports, reservoirs, transitionToPreviousStep, transitionToNextStep}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const verticalRackGroups = useSelector(selectAllVerticalRackGroups)


    const backClicked = React.useCallback(() => {
        transitionToPreviousStep(["manage_reservoirs", null])
    })
    const confirmChangesClicked = React.useCallback(() => {
        //transitionToNextStep(["", null])
    })





    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header">
                <div className="Walkthrough-Card-HeaderContent">
                    Review Rack Info
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-H-30">
                        <div className="FlexContent-Center">
                            <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                <div className="Button-Large-Icon"><ScanNDS width={isShortDisplay ? 90 : ((isTablet || isDesktop) ? 120 : 105)}/></div>
                            </>}/>
                        </div>
                        <div className="FlexContent-5">
                            <div className="FlexContent-40 FlexContent-Center FlexContent-OverflowY">
                                <div className="FlexContent-40 FlexContent-Left">
                                    <div className="FlexContent-10">
                                        <span style={{fontWeight: 500}}>NDS Name</span><span>{NDSName}</span>
                                    </div>
                                    <div className="FlexContent-10">
                                        <span style={{fontWeight: 500}}>Active Ports</span><span>{Object.values(ports).filter((p) => p.linked).length}/{Object.keys(ports).length}</span>
                                    </div>
                                    <div className="FlexContent-10">
                                        <span style={{fontWeight: 500}}>Active Reservoirs</span><span>{Object.values(reservoirs).filter((p) => p.active).length}/{Object.keys(reservoirs).length}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content="Back" onClick={backClicked} status={"Neutral"} size={isMobile ? "Medium" : "Large"} width="Flex50"/>
                <Button content="Finalize" onClick={confirmChangesClicked} status={"Primary"} size={isMobile ? "Medium" : "Large"} width="Flex50"/>
            </div>
        </div>
    )
}

  


export default ConfirmNewNDSWalkthroughStep