import './../ManageRackWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, ScanRack } from '../../../../assets/icons/RackIcons';
import RadioButton from '../../../../components/RadioButton';
import { DeviceTransmitting, GoodCheckmark, WiFiFull } from '../../../../assets/icons/Icons';
import Switch from '../../../../components/Switch';
import DropDownInput from '../../../../components/input/DropDownInput';
import { validateVerticalRackSystemConnection } from '../../../../redux/entities/service/VerticalRackGroup';
import { useDispatch } from 'react-redux';
import Checkbox from '../../../../components/input/Checkbox';


  

const ConnectingRackWalkthroughStep = ({rackCode, selectedConnectionOption, currentStep, transitionToPreviousStep, transitionToNextStep, rackConnectionTypeSelectionCallback, rackConnectedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const dispatch = useDispatch()



    const [usingExistingWiFiCredentials, SetUsingExistingWiFiCredentials] = React.useState(false)
    const [seenFailedBluetoothOnce, SetSeenFailedBluetoothOnce] = React.useState(false)
    //const [seenFailedRackConnection, SetSeenFailedRackConnection] = React.useState(false)

    const [isWiFiCredentialsNew, SetIsWiFiCredentialsNew] = React.useState(false)
    const [wiFiSSID, SetWiFiSSID] = React.useState("")
    const [wiFiPassword, SetWiFiPassword] = React.useState("")


    
    const [saveWiFiCredentialsState, SetSaveWiFiCredentialsState] = React.useState(false)
    const saveWiFiCredentialsStateChanged = (state) =>  {
        SetSaveWiFiCredentialsState(state)
        if (!state) {
            SetWiFiCredentialsName("")
        }
    }

    const [wiFiCredentialsName, SetWiFiCredentialsName] = React.useState("")
    const wiFiCredentialsNameChanged = (name) =>   {
        SetWiFiCredentialsName(name)
    }

    /*const isValidatingRackConnectionLoading = useSelector((state) => state.verticalRackGroups.validatingVerticalRackConnectedToSystem)
    const [validatingRackConnectionLoadingStep, SetValidatingRackConnectionLoadingStep] = React.useState("idle")
    React.useEffect(() => {
        if (isValidatingRackConnectionLoading === "pending")  {
            SetValidatingRackConnectionLoadingStep("validating")
        }else if (isValidatingRackConnectionLoading === "fulfilled")  {
            SetValidatingRackConnectionLoadingStep("validation_response")
        }else if (isValidatingRackConnectionLoading === "rejected")  {
            SetValidatingRackConnectionLoadingStep("failed")
        }
    }, [isValidatingRackConnectionLoading])*/


    
    /*React.useEffect(() => {
        if (validatingRackConnectionLoadingStep === "finished") {
            let foundFacility = facilities.find((f) => f.id === facilityId)
            if (foundFacility !== undefined)    {
                console.log(foundFacility, verticalRackGroups, newGroupUID)
                let foundService = foundFacility.services.find((s => s.uid === newGroupUID))
                if (foundService !== undefined) {
                    let foundNewRackGroup = verticalRackGroups.find((vRG) => vRG.service_id === foundService.id)
                    if (foundNewRackGroup !== undefined)  {
                        SetSelectedVerticalRackGroupId(foundNewRackGroup.id)
                    }
                }
            }
        }
    }, [validatingRackConnectionLoadingStep])

    React.useEffect(() => {
        if (validatingRackConnectionLoadingStep === "validating")  {
            const timeout = setTimeout(() => {
                SetCreatingNewGroupLoadingStep("group_created-stage2")
            }, 1000)
            return () => clearTimeout(timeout)            
        }else if (creatingNewGroupLoadingStep === "group_created-stage2")  {
            const timeout = setTimeout(() => {
                SetCreatingNewGroupLoadingStep("group_created-stage3")
            }, 1000)
            return () => clearTimeout(timeout)            
        }else if (creatingNewGroupLoadingStep === "group_created-stage3")  {
            const timeout = setTimeout(() => {
                SetCreatingNewGroupLoadingStep("finished")
            }, 1000)
            return () => clearTimeout(timeout)            
        }else if (creatingNewGroupLoadingStep === "finished")  {
            const timeout = setTimeout(() => {
                SetCreatingNewGroupLoadingStep("idle")
                transitionToNextStep(["select_rack_group", "rack_group_listing"])
            }, 2000)
            return () => clearTimeout(timeout)            
        }
    }, [validatingRackConnectionLoadingStep])*/



    const validateRackConnection = () =>    {
        console.log(rackCode)
        dispatch(validateVerticalRackSystemConnection({rackCode: rackCode.toString(), callback: (success) => {
            if (success)    {
                transitionToNextStep(["connecting_to_rack", "rack_connected"])
            }else {
                transitionToNextStep(["connecting_to_rack", "connection_failed"])
            }
        }}))
    }


    React.useEffect(() => {
        if (currentStep === "attempting_connection")    {
            /*const timeout = setTimeout(() => {
                if (!seenFailedRackConnection)   {
                    transitionToNextStep(["connecting_to_rack", "connection_failed"])
                    SetSeenFailedRackConnection(true)
                }else if (seenFailedRackConnection)   {
                    transitionToNextStep(["connecting_to_rack", "rack_connected"])
                }
            }, 1000)
            return () => clearTimeout(timeout)*/
        }else  if (currentStep === "sending_credentials_over_bluetooth")    {
            const timeout = setTimeout(() => {
                if (!seenFailedBluetoothOnce)   {
                    transitionToNextStep(["connecting_to_rack", "bluetooth_connection_failed"])
                    SetSeenFailedBluetoothOnce(true)
                }else {
                    transitionToNextStep(["connecting_to_rack", "bluetooth_connection_success"])
                }
            }, 1000)
            return () => clearTimeout(timeout)
        }else  if (currentStep === "bluetooth_connection_success")    {
            const timeout = setTimeout(() => {
                transitionToNextStep(["connecting_to_rack", "sending_wifi_credentials"])
            }, 2000)
            return () => clearTimeout(timeout)
        }else  if (currentStep === "sending_wifi_credentials")    {
            const timeout = setTimeout(() => {
                transitionToNextStep(["connecting_to_rack", "wifi_credentials_sent"])
            }, 2000)
            return () => clearTimeout(timeout)
        }else  if (currentStep === "wifi_credentials_sent")    {
            const timeout = setTimeout(() => {
                transitionToNextStep(["connecting_to_rack", "attempting_connection"])
                validateRackConnection()
            }, 2000)
            return () => clearTimeout(timeout)
        }


        

      }, [currentStep])

    switch (currentStep) {

        case "connection_options":
            const nextClicked = () =>   {
                transitionToNextStep(["connecting_to_rack", "sending_credentials_over_bluetooth"])
            }

            const WiFiNameChanged = (name) =>   {
                SetWiFiSSID(name)
            }
            const WiFiPasswordChanged = (password) =>   {
                SetWiFiPassword(password)
            }

            const connectToExistingWiFiClicked = () => {
                transitionToNextStep(["connecting_to_rack", "confirm_device_nearby"])
            }
            const connectToWiFiWithCredentialsClicked = () =>   {
                transitionToNextStep(["connecting_to_rack", "confirm_device_nearby"])
            }

            const connectToEthernetClicked = () =>   {
                transitionToNextStep(["connecting_to_rack", "attempting_connection"])
                validateRackConnection()
            }


            let canAttemptConnection = wiFiSSID.length > 0 && wiFiPassword.length > 0
            return (
                <div className="Walkthrough-Card Walkthrough-CardFill">
                    <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Connection Setup</div></div>


                    <div className={"Walkthrough-Card-Content-Wrapper"}>
                        <div className="Walkthrough-Card-Content">
                            <div className="FlexContent-20">
                                <div className="FlexContent-5" style={{fontWeight:500}}>Connection</div>
                                <div className="FlexContent-H-10">
                                    <Button 
                                        status="Primary-Toggle" 
                                        state={selectedConnectionOption == "use_existing_credentials"} 
                                        content="Existing WiFi"
                                        onClick={() => rackConnectionTypeSelectionCallback("use_existing_credentials")}/>
                                    <Button 
                                        status="Primary-Toggle" 
                                        state={selectedConnectionOption == "provide_credentials"} 
                                        content="New WiFi"
                                        onClick={() => rackConnectionTypeSelectionCallback("provide_credentials")}/>
                                    <Button 
                                        status="Primary-Toggle" 
                                        state={selectedConnectionOption == "ethernet"} 
                                        content="Ethernet"
                                        onClick={() => rackConnectionTypeSelectionCallback("ethernet")}/>
                                </div>
                                {selectedConnectionOption == "use_existing_credentials" && 
                                    <div className="FlexContent-10">
                                        <DropDownInput placeholder={"Network Name"}/>
                                        <Button content={"Connect"} status="Primary" size={isMobile ? "Medium" : "Large"} width={"Flex100"} onClick={connectToExistingWiFiClicked} />
                                    </div>
                                }
                                {selectedConnectionOption == "provide_credentials" && 
                                    <div className="FlexContent-10 FlexContent-HFill">
                                        <div className="FlexContent-10 FlexContent-HFill">
                                            <div className="Walkthrough-FormItem">
                                                <div className="Walkthrough-FormItem-Question">WiFi Name</div>
                                                <div className="Walkthrough-FormItem-Answer">
                                                    <TextInput value={wiFiSSID} placeHolder="Enter WiFi Name" onChange={WiFiNameChanged} disabled={usingExistingWiFiCredentials}/>
                                                </div>
                                            </div>
                                            <div className="Walkthrough-FormItem">
                                                <div className="Walkthrough-FormItem-Question">WiFi Password</div>
                                                <div className="Walkthrough-FormItem-Answer">
                                                    <TextInput value={wiFiPassword} placeHolder="Enter WiFi Password" onChange={WiFiPasswordChanged} disabled={usingExistingWiFiCredentials}/>
                                                </div>
                                            </div>
                                        </div>
                                        <Button content={"Connect"} status="Primary" size={isMobile ? "Medium" : "Large"} onClick={connectToWiFiWithCredentialsClicked} width={"Flex100"} disabled={!canAttemptConnection}/>
                                    </div>
                                }
                                {selectedConnectionOption == "ethernet" && 
                                    <div className="FlexContent-10">
                                        <span style={{fontWeight:500}}>Connect Ethernet</span>
                                        <Button content={"Connect"} status="Primary" size={isMobile ? "Medium" : "Large"} width={"Flex100"} onClick={connectToEthernetClicked}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    
                </div>
            )



        case "confirm_device_nearby":
            const beginConnectNowClicked = () =>    {
                transitionToNextStep(["connecting_to_rack", "sending_credentials_over_bluetooth"])
            }
            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Header">
                        <div className="Walkthrough-Card-HeaderContent">
                            
                        </div>
                    </div>
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <DeviceTransmitting width={(isMobile ? 80 : 100) }/>
                        <div style={{wordWrap:"break-word", textAlign:"center", maxWidth:(isMobile ? "90%" : "60%")}}>Make sure your bluetooth is turned on and bring your device close to the rack before attempting to connect</div>
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                        <Button content={"Connect Now"} status="Primary" onClick={beginConnectNowClicked} width={"Flex100"}/>

                    </div>
                </div>
            )


        case "attempting_connection":

            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackLoading/>
                        <div className="PopupModal-StandardHeader">Rack Connecting</div>
                    </div>
                </div>
            )
        
        case "connection_failed":
            const tryConnectingAgainClicked = () => {
                if (selectedConnectionOption === "use_existing_credentials" || selectedConnectionOption === "provide_credentials")   {
                    transitionToPreviousStep(["connecting_to_rack", "confirm_device_nearby"])
                }else {
                    transitionToPreviousStep(["connecting_to_rack", "attempting_connection"])
                    validateRackConnection()
                }
            }

            const selectWiFiCredentialsClicked = () =>  {
                transitionToPreviousStep(["connecting_to_rack", "connection_options"])
            }
            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <div className="FlexContent-50">
                            <div className="FlexContent-30 FlexContent-Center">
                                <RackConnectionFailed width={isMobile ? 180 : 224}/>
                                <div className="PopupModal-StandardHeader">Rack Not Connected</div>
                            </div>

                            <div className="FlexContent-30">
                                <div className="FlexContent-HFill">
                                    <Button content={"Retry"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={tryConnectingAgainClicked} width={"Flex100"}/>
                                </div>
                                <div className="FlexContent-Center">
                                    {(selectedConnectionOption === "use_existing_credentials" || selectedConnectionOption === "provide_credentials") &&
                                        <Button content={"Change WiFi Credentials"} status="Primary-Inverted" onClick={selectWiFiCredentialsClicked}/>
                                    }
                                    {selectedConnectionOption === "ethernet" &&
                                        <Button content={"Try connecting using WiFi"} status="Primary-Inverted" onClick={selectWiFiCredentialsClicked}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )

            
        case "sending_credentials_over_bluetooth":

            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Header">
                        <div className="Walkthrough-Card-HeaderContent">
                            
                        </div>
                    </div>
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackLoading/>
                        <div className="PopupModal-StandardHeader" style={{textAlign:"center"}}>Connecting to rack over bluetooth</div>

                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                    </div>
                </div>
            )
        case "bluetooth_connection_failed":
            const tryBluetoothConnectingAgainClicked = () => {
                transitionToNextStep(["connecting_to_rack", "sending_credentials_over_bluetooth"])
            }

            const selectNewWiFiCredentialsClicked = () =>  {
                transitionToNextStep(["connecting_to_rack", "connection_options"])
            }
            return (

                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Header">
                        <div className="Walkthrough-Card-HeaderContent">
                            
                        </div>
                    </div>
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackConnectionFailed/>
                        <div className="PopupModal-StandardHeader">Rack Not Connected</div>
                        <Button content={"Retry"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={tryBluetoothConnectingAgainClicked} width={"Flex100"}/>
                        <Button content={"Try different method"} status="Primary-Inverted" onClick={selectNewWiFiCredentialsClicked}/>
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                    </div>
                </div>
                
            )

        case "bluetooth_connection_success":
            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Header">
                        <div className="Walkthrough-Card-HeaderContent">
                            
                        </div>
                    </div>
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackConnectionSuccess/>
                        <div className="PopupModal-StandardHeader" style={{textAlign:"center"}}>Bluetooth Connection Established</div>

                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                    </div>
                </div>
            )


            
        case "sending_wifi_credentials":
            return (
                <div className="Walkthrough-Card Walkthrough-Card-VerticalCenter">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackLoading/>
                        <div className="PopupModal-StandardHeader">Pushing WiFi Credentials</div>

                    </div>
                </div>
            )

            case "wifi_credentials_sent":
                return (
                    
    
                    <div className="Walkthrough-Card">
                        <div className="Walkthrough-Card-Header">
                            <div className="Walkthrough-Card-HeaderContent">
                                
                            </div>
                        </div>
                        <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                            <RackConnectionSuccess/>
                            <div className="PopupModal-StandardHeader">WiFi Credentials Sent</div>
    
                        </div>
                        <div className="Walkthrough-Card-FooterButtons">
                        </div>
                    </div>
                )
    

                case "rack_connected":
                    const setupRackClicked = () =>  {
                        let rackUID = 1003
                        rackConnectedCallback({rackUID: rackUID})
                        transitionToNextStep(["select_rack_group", "rack_group_listing"])
                    }
                    return (
                        <div className="Walkthrough-Card">
                            <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                                <RackConnectionSuccess/>
                                <div className="FlexContent-40">
                                    <div className="Walkthrough-StandardHeader Walkthrough-StandardHeader-Center">Rack Connected Successfully</div>
                                    {selectedConnectionOption === "provide_credentials" && 
                                        <div className="FlexContent-20">
                                            <div className="FlexContent FlexContent-Center">
                                                <Checkbox
                                                    style={"check"}
                                                    label="Save WiFi Credentials for future use"
                                                    state={saveWiFiCredentialsState}
                                                    onChange={saveWiFiCredentialsStateChanged}/>
                                            </div>
                                            <TextInput value={wiFiCredentialsName} onBlur={wiFiCredentialsNameChanged} placeHolder={"Enter WiFi Name"} disabled={!saveWiFiCredentialsState}/>
                                        </div>
                                    }
                                </div>                    
                            </div>
                            <div className="Walkthrough-Card-FooterButtons">
                                <Button content="Setup Rack Information" size={isMobile ? "Medium" : "Large"}  status="Primary" onClick={setupRackClicked} width={"Flex100"}/>
                            </div>
                        </div>
                    
                    )
                    

        default:
            return (<></>)
    }
}


ConnectingRackWalkthroughStep.defaultProps = {
    theme: "normal",
  }
  


export default ConnectingRackWalkthroughStep