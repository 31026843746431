
import './SelectInventoryItemType.scss';
import React from 'react'

import {StandardList, StandardListRow} from '../components/StandardList/StandardList.js'
import { getAllInventoryItemTypes, selectAllInventoryItemTypeGroups, selectAllInventoryItemTypes } from '../redux/entities/Inventory';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '../components/Badge';


const SelectInventoryItemType = ({selectedInventoryItemType, onInventoryItemTypeSelection}) => {

  
  let inventoryItemTypes = useSelector(selectAllInventoryItemTypes)
  let inventoryItemTypeGroups = useSelector(selectAllInventoryItemTypeGroups)
  let loadedAllItemTypes = useSelector((state) => state.inventory.haveAllItemTypes)


  const [inventoryItemTypesList, SetInventoryItemTypesList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedInventoryItemType, SetSelectedInventoryItemType] = React.useState(null)
  React.useEffect(() => {
    if (currentSelectedInventoryItemType !== selectedInventoryItemType) {
      SetSelectedInventoryItemType(selectedInventoryItemType)
    }
  }, [selectedInventoryItemType])
  
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!loadedAllItemTypes)  {
      dispatch(getAllInventoryItemTypes())
    }

    let currentInventoryItemTypesList = {}

    let currentInventoryItemTypes = []
    

    if (currentSearchInput === "")  {
      currentInventoryItemTypes = [...inventoryItemTypes]
    }else {
      currentInventoryItemTypes.push(...inventoryItemTypes.filter((inventoryItemType) => {
        if (inventoryItemType.name.indexOf(currentSearchInput) !== -1 && currentInventoryItemTypes.indexOf(inventoryItemType) === -1)  {
          return inventoryItemType
        }
      }))
    }
    
    
    for (let inventoryItemType of currentInventoryItemTypes) {
      let foundItemTypeGroup = inventoryItemTypeGroups.find((iTG) => iTG.id === inventoryItemType.type_group_id)
      currentInventoryItemTypesList[inventoryItemType.id] = {
        data: inventoryItemType,
        columns: {
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{inventoryItemType.name}</div>
            </div>),
          },
          category: {
            render: (<div className="StandardList-Row-Column">
              <Badge status="Neutral" content={foundItemTypeGroup !== undefined ? foundItemTypeGroup.name : "N/A"} size="Medium"/>
            </div>),
          },
        }
      }
      if (currentSelectedInventoryItemType && currentSelectedInventoryItemType.id === inventoryItemType.id) {
        currentInventoryItemTypesList[inventoryItemType.id].selected = true
      }
    }


    SetInventoryItemTypesList(currentInventoryItemTypesList)
  }, [inventoryItemTypes, currentSearchInput, currentSelectedInventoryItemType])




  
  const columns = {
    name: {render: (<div className="StandardList-Header-Column">Name</div>), width: "grow", resizable: true, sort_by: false},
    category: {render: (<div className="StandardList-Header-Column">Category</div>), width: 15, resizable: true, sort_by: false},
  }

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }


  const rowSelected = (inventoryItemType) => {
    SetSelectedInventoryItemType(inventoryItemType.data)
    if (onInventoryItemTypeSelection !== undefined) {
      onInventoryItemTypeSelection(inventoryItemType.data)
    }
  }

  return (
    <>
      <div id="SelectInventoryItemType">
        <StandardList 
          columns={columns} 
          showHeader={false}
          columnSpacing={10}
          rows={inventoryItemTypesList}
          isSearchable={true} 
          onSearchInput={onSearchInput}
          onRowClicked={rowSelected}/>
      </div>
    </>
  )
} 

export default SelectInventoryItemType