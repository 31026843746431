import { useMediaQuery } from 'react-responsive';
import './Button.scss';
import React from 'react'

const Button = React.forwardRef(({style, content, status, state, alignContent, size, width, onClick, hasBorder, disabled}, ref) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  let buttonProps = {style: style}
  const buttonClicked = (e) => {
    if (!disabled && onClick !== undefined && onClick !== null)  {
      onClick(e)
    }
  }
  /*if (buttonKey !== null)  {
    buttonProps.key = buttonKey
  }*/
  return (
    <div 
      ref={ref}
      className={"Button Button-" + status + " Button-" + alignContent + "Content" + (state ? " Button-" + status + "-Active" : "") + " Button-" + size + 
      (hasBorder ? " Button-WithBorder" : "") + (width ? " Button-" + width : "") + (disabled ? " Button-Disabled noselect" : "" ) + 
      (isMobile ? " Button-Mobile" : "") + (isTablet ? " Button-Tablet" : "") + (isDesktop ? " Button-Desktop" : "")} {...buttonProps}>
      <div onClick={buttonClicked}><div className="noselect">{content}</div></div>
    </div>
  )
})

Button.defaultProps = {
  content: "",
  status: "Primary",
  size: "Medium",
  alignContent: "Center",
  state: false,
  key: null,
  hasBorder: false,
  disabled: false,
  width: false
}


export default Button