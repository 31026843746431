import './RecipesPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate, useMeasure, useMeasureWithRef } from '../../../helpers'
import {TabControl, TabControlTab} from '../../../components/TabControl.js';
import Button from '../../../components/Button.js';
import TextInput from '../../../components/input/TextInput';
import NumberInput from '../../../components/input/NumberInput';
import DropDownButton from '../../../components/DropDownButton.js';
import Checkbox from '@mui/material/Checkbox';
 
import { useParams, useNavigate, Route, Navigate, Routes} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { selectRecipeById, selectAllRecipes, recipeChanged, timelineItemChanged, pushRecipeChange } from '../../../redux/entities/Recipes'

import {BiGridVertical} from 'react-icons/bi'
import {FaLock, FaUnlock, FaTrashAlt} from 'react-icons/fa'
import {HiOutlineDuplicate} from 'react-icons/hi'

import SelectSubpartPopup from './SelectSubpartPopup'
import { getInventoryItemTypeById, selectAllInventoryItemTypes } from '../../../redux/entities/Inventory';
import DropDownInput from '../../../components/input/DropDownInput';

const RecipeNutrientsPage = ({recipe, selectedTimelineItem, selectTimelineItem, timelineItemSelectionOptions}) => {
  const dispatch = useDispatch()
  const [, forceRerender] = React.useReducer(x => x + 1, 0);
  const [nutrientParts, SetNutrientParts] = React.useState([]);

  const [pointerPosition, SetPointerPosition] = React.useState({x: 0, y: 0});

  const [partReordering, SetPartReordering] = React.useState(undefined);
  const [partReorderingOffset, SetPartReorderingOffset] = React.useState({x: 0, y: 0});
  const partReorderingOffsetRef = React.useRef({x: 0, y: 0});
  React.useEffect(() => {partReorderingOffsetRef.current = partReorderingOffset}, [partReorderingOffset])
  const [partReorderingCompleted, SetPartReorderingCompleted] = React.useState(false);
  const reorderingPartRef = React.useRef(undefined);
  

  const [selectingSubpartForPart, SetSelectingSubpartForPart] = React.useState(undefined)
  const [selectingSubpart, SetSelectingSubpart] = React.useState(false)

  

  const [timelineItems, SetTimelineItems] = React.useState([]);
  React.useEffect(() => {
    if (recipe === undefined) 
      return

    if (recipe.timeline_items != null) {
      SetTimelineItems([...recipe.timeline_items])
    }
  }, [recipe])
  const timelineItemSelected = React.useCallback((timelineItem) => {
    if (selectedTimelineItem != timelineItem && selectTimelineItem !== undefined)  {
      selectTimelineItem(timelineItem)
    }
  })
  const onTimelineItemSelectionChanged = React.useCallback((value) => {
    let foundTimelineItem = timelineItems.find(c => value === c.id)
    if (foundTimelineItem !== undefined) {
      timelineItemSelected(foundTimelineItem)
    }
  })


  React.useEffect(() => {
    if (selectedTimelineItem === undefined || selectedTimelineItem.item.nutrient_recipe === undefined)  {
      if (nutrientParts.length > 0) {
        SetNutrientParts([])
      }
      return
    }

    SetNutrientParts(selectedTimelineItem.item.nutrient_recipe.parts ?? [])

  }, [selectedTimelineItem])

  const createPart = React.useCallback(() => {

    /*let unlockedParts = []
    let totalLockedRatio = 0
    for (let part of nutrientParts) {
      if (part.locked === undefined || !part.locked)  {
        unlockedParts.push(part.index)
      }else {
        totalLockedRatio += part.percent_of
      }
    }

    let newNutrientPartRatio = (100 - totalLockedRatio) / (unlockedParts.length + 1)

    let currentNutrientParts = nutrientParts.map((part) =>  {
      if (part.locked === undefined || !part.locked)  {
        return {...part, percent_of: ((100 - (totalLockedRatio + newNutrientPartRatio)) / 100) * part.percent_of }
      }
      return part
    })*/

    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            nutrient_recipe: {
              parts: [...nutrientParts, {
               index: nutrientParts.length + 1,
               percent_of: nutrientParts.length === 0 ? 100 : 0,
               sub_parts: []
              }] 
             }
          }
        }
      })]
    }}))

  })


  const PartItem = ({part}) => {
    const dispatch = useDispatch()
    const [, forceRerender] = React.useReducer(x => x + 1, 0);




    const ratioChanged = (ratio) =>  {
      let newRatio = ratio
      
      let totalLockedRatio = 0
      let totalAvailableRatios = 0
      for (let currentPart of nutrientParts) {
        if ((currentPart.locked === undefined || !currentPart.locked) && currentPart.index != part.index)  {
          totalAvailableRatios += 1
        }else if ((currentPart.locked !== undefined && currentPart.locked) && currentPart.index != part.index)  {
          totalLockedRatio += currentPart.percent_of
        }
      }

      if (totalAvailableRatios === 0) {
        return part.percent_of
      }

      if (newRatio < 0)  {
        newRatio = 0
      }else if (newRatio > (100 - totalLockedRatio))  {
        newRatio = (100 - totalLockedRatio)
      }
  
      return newRatio
    }
  
    const ratioChangeFinalize = (newRatio) =>  {
      let ratioChange = newRatio - part.percent_of

      if (ratioChange === 0)  {
        return
      }

      let unlockedParts = []
      let totalLockedRatio = 0
      let totalUsedRatio = 0
      for (let currentPart of nutrientParts) {
        if ((currentPart.locked === undefined || !currentPart.locked) && currentPart.index != part.index)  {
          unlockedParts.push(currentPart.index)
          totalUsedRatio += parseInt(currentPart.percent_of)
        }else if (currentPart.index != part.index) {
          totalLockedRatio += parseInt(currentPart.percent_of)
        }
      }



      dispatch(pushRecipeChange({recipe: {...recipe, 
        timeline_items: [...recipe.timeline_items.map((timelineItem) => {
          if (timelineItem.id != selectedTimelineItem.id) {
            return timelineItem
          }
          return {
            ...timelineItem,
            item: {
              ...timelineItem.item,
              nutrient_recipe: {
                parts: nutrientParts.map((currentPart) =>  {
                  if ((currentPart.locked === undefined || !currentPart.locked) && currentPart.index != part.index)  {
                    if (totalUsedRatio > 0) {
                      return {...currentPart, percent_of: currentPart.percent_of - ((currentPart.percent_of / (100 - (totalLockedRatio + part.percent_of))) * ratioChange)  }
                    }else {
                      return {...currentPart, percent_of: -ratioChange / unlockedParts.length  }
                    }
                  }else if (currentPart.index === part.index) {
                    return {...currentPart, percent_of: newRatio, locked: false}
                  }
                  return currentPart
                })
              }
            }
          }
        })]
      }}))
    }

    const onLockToggle = () => {
      
      
      dispatch(timelineItemChanged({recipeId: recipe.id, timelineItem: selectedTimelineItem, changes: {
        nutrient_recipe: {
          parts: nutrientParts.map((currentPart) =>  {
            if (currentPart.index === part.index) {
              return {...currentPart, locked: (part.locked === undefined || !part.locked) ? true : false}
            }
            return currentPart
          })
        }
      }}))
    }

    const deletePart = () =>  {
      let remainingParts = nutrientParts.filter((currentPart) =>  {
        if (currentPart.index !== part.index) {
          return currentPart
        }
      }) 

      

      dispatch(pushRecipeChange({recipe: {...recipe, 
        timeline_items: [...recipe.timeline_items.map((timelineItem) => {
          if (timelineItem.id != selectedTimelineItem.id) {
            return timelineItem
          }
          return {
            ...timelineItem,
            item: {
              ...timelineItem.item,
              nutrient_recipe: {
                parts: remainingParts.map((currentPart) =>  {
                  return {
                    ...currentPart, 
                    percent_of: currentPart.percent_of + ((currentPart.percent_of / (100 - part.percent_of)) * part.percent_of), 
                    index: (currentPart.index > part.index) ? currentPart.index - 1 : currentPart.index
                  }
                })
              }
            }
          }
        })]
      }}))
    }

    const containerProps = {}

    const addSubpartClicked = () => {
      SetSelectingSubpartForPart(part)
      openSubpartSelectionPopup()
    }

    return (
      <>
        <div
          key={part.index}
          /*ref={containerRef}*/
          className="Recipe-Nutrients-Part"
          {...containerProps}>
          <div className="Recipe-Nutrients-Part-Content">
            <div className="Recipe-Nutrients-Part-Name">
              Part {part.index}
            </div>
            <div className="Recipe-Nutrients-Part-Ratio">
              <NumberInput value={part.percent_of} suffix="%" stepAmount={0.1} maxLength={4} onChange={ratioChanged} onBlur={ratioChangeFinalize} precision={0.1}/>
            </div>
          </div>
          <div className="Recipe-Nutrients-Part-FunctionsContainer">
            <div className="Recipe-Nutrients-Part-Functions">
              <div className="Button Button-Neutral Button-Small Button-WithBorder" onClick={onLockToggle}>
                <div>
                  {(part.locked !== undefined && part.locked) && 
                    <FaLock className="Recipe-Nutrients-Part-Lock"/>
                  }
                  {(part.locked === undefined || !part.locked) && 
                    <FaUnlock className="Recipe-Nutrients-Part-Unlock"/>
                  }
                </div>
              </div>
              <div className="Button Button-Critical Button-Small Button-WithBorder">
                <div>
                  <FaTrashAlt className="Recipe-Nutrients-Part-Trash" onClick={deletePart}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Recipe-Nutrients-Subparts">
          {part.sub_parts.map((subpart) => {
            return (
            <SubPartItem
              key={subpart.index}
              part={part}
              subpart={subpart}/>
            )
          })}
          <div className="ControlBar_Horizontal-Left ControlBar_Horizontal-Overflow">
            <Button content={"Add Sub Part"} onClick={addSubpartClicked}/>
          </div>
        </div>
      </>
    )
  }


  const SubPartItem = ({part, subpart}) => {
    const dispatch = useDispatch()
    const [, forceRerender] = React.useReducer(x => x + 1, 0);

    let inventoryItemTypes = useSelector(selectAllInventoryItemTypes)
    const [inventoryItemType, SetInventoryItemType] = React.useState(inventoryItemTypes.find((iIT) => iIT.id === subpart.item_type_id))
    React.useEffect(() => {
      let foundInventoryItemType = inventoryItemTypes.find((iIT) => iIT.id === subpart.item_type_id)
      if (foundInventoryItemType === undefined) {
        dispatch(getInventoryItemTypeById({itemTypes: [subpart.item_type_id]}))
      }else {
        SetInventoryItemType(foundInventoryItemType)
      }
    }, [inventoryItemTypes])


    const ratioChanged = (ratio) =>  {
      let newRatio = ratio
      
      let totalLockedRatio = 0
      let totalAvailableRatios = 0
      for (let currentSubpart of part.sub_parts) {
        if ((currentSubpart.locked === undefined || !currentSubpart.locked) && currentSubpart.index != subpart.index)  {
          totalAvailableRatios += 1
        }else if ((currentSubpart.locked !== undefined && currentSubpart.locked) && currentSubpart.index != subpart.index)  {
          totalLockedRatio += currentSubpart.percent_of
        }
      }

      if (totalAvailableRatios === 0) {
        return subpart.percent_of
      }

      if (newRatio < 0)  {
        newRatio = 0
      }else if (newRatio > (100 - totalLockedRatio))  {
        newRatio = (100 - totalLockedRatio)
      }
  
      return newRatio
    }
  



    const ratioChangeFinalize = (newRatio) =>  {
      let ratioChange = newRatio - subpart.percent_of

      if (ratioChange === 0)  {
        return
      }

      let unlockedParts = []
      let totalLockedRatio = 0
      let totalUsedRatio = 0
      for (let currentSubpart of part.sub_parts) {
        if ((currentSubpart.locked === undefined || !currentSubpart.locked) && currentSubpart.index != subpart.index)  {
          unlockedParts.push(currentSubpart.index)
          totalUsedRatio += parseInt(currentSubpart.percent_of)
        }else if (currentSubpart.index != subpart.index) {
          totalLockedRatio += parseInt(currentSubpart.percent_of)
        }
      }


      dispatch(pushRecipeChange({recipe: {...recipe, 
        timeline_items: [...recipe.timeline_items.map((timelineItem) => {
          if (timelineItem.id != selectedTimelineItem.id) {
            return timelineItem
          }
          return {
            ...timelineItem,
            item: {
              ...timelineItem.item,
              nutrient_recipe: {
                parts: nutrientParts.map((currentPart) =>  {
                  if (currentPart.index === part.index) {
                    return {...currentPart, sub_parts: part.sub_parts.map((currentSubpart) => {
                      if ((currentSubpart.locked === undefined || !currentSubpart.locked) && currentSubpart.index != subpart.index)  {
                        if (totalUsedRatio > 0) {
                          return {...currentSubpart, percent_of: currentSubpart.percent_of - ((currentSubpart.percent_of / (100 - (totalLockedRatio + subpart.percent_of))) * ratioChange)  }
                        }else {
                          return {...currentSubpart, percent_of: -ratioChange / unlockedParts.length  }
                        }
                      }else if (currentSubpart.index === subpart.index) {
                        return {...currentSubpart, percent_of: newRatio, locked: false}
                      }
                      return currentSubpart
                    })}
                  }
                  return currentPart
                })
              }
            }
          }
        })]
      }}))

    }

    const onLockToggle = () => {
      /*dispatch(pushRecipeChange({recipe: {...recipe, 
        timeline_items: [...recipe.timeline_items.map((timelineItem) => {
          if (timelineItem.id != selectedTimelineItem.id) {
            return timelineItem
          }
          return {
            ...timelineItem,
            item: {
              ...timelineItem.item,
              nutrient_recipe: {
                parts: [...nutrientParts, {
                 index: nutrientParts.length + 1,
                 percent_of: nutrientParts.length === 0 ? 100 : 0,
                 sub_parts: []
                }] 
               }
            }
          }
        })]
      }}))*/

      
      dispatch(timelineItemChanged({recipeId: recipe.id, timelineItem: selectedTimelineItem, changes: {
        nutrient_recipe: {
          parts: nutrientParts.map((currentPart) =>  {
            if (currentPart.index === part.index) {
              return {...currentPart, sub_parts: part.sub_parts.map((currentSubpart) => {
                if (currentSubpart.index === subpart.index) {
                  return {...currentSubpart, locked: (subpart.locked === undefined || !subpart.locked) ? true : false}
                }
                return currentSubpart
              })}
            }
            return currentPart
          })
        }
      }}))
    }

    const deleteSubpart = () =>  {
      let remainingSubparts = part.sub_parts.filter((currentSubpart) =>  {
        if (currentSubpart.index !== subpart.index) {
          return currentSubpart
        }
      })

      dispatch(pushRecipeChange({recipe: {...recipe, 
        timeline_items: [...recipe.timeline_items.map((timelineItem) => {
          if (timelineItem.id != selectedTimelineItem.id) {
            return timelineItem
          }
          return {
            ...timelineItem,
            item: {
              ...timelineItem.item,
              nutrient_recipe: {
                parts: nutrientParts.map((currentPart) =>  {
                  if (currentPart.index === part.index) {
                    return {...currentPart, sub_parts: remainingSubparts.map((currentSubpart) => {
                      return {
                        ...currentSubpart, 
                        percent_of: currentSubpart.percent_of + ((currentSubpart.percent_of / (100 - subpart.percent_of)) * subpart.percent_of), 
                        index: (currentSubpart.index > subpart.index) ? currentSubpart.index - 1 : currentSubpart.index
                      }
                    })}
                  }
                  return currentPart
                })
               }
            }
          }
        })]
      }}))
    }

    const containerProps = {}

    let display_name = "Subpart " + subpart.index.toString()
    if (subpart.nutrient_solution)  {
      display_name = subpart.nutrient_solution.DisplayName
    }else if (subpart.solution)  {
      display_name = subpart.solution.DisplayName
    }

    return (
      <div
        key={subpart.index}
        /*ref={containerRef}*/
        className="Recipe-Nutrients-Part"
        {...containerProps}>
        <div className="Recipe-Nutrients-Part-Content">
          <div className="Recipe-Nutrients-Part-Name">
            {inventoryItemType ? inventoryItemType.name : "Subpart"}
          </div>
          <div className="Recipe-Nutrients-Part-Ratio">
            <NumberInput value={subpart.percent_of} stepAmount={0.1} suffix="%" maxLength={4} onChange={ratioChanged} onBlur={ratioChangeFinalize} precision={0.1}/>
          </div>
        </div>
        <div className="Recipe-Nutrients-Part-FunctionsContainer">
          <div className="Recipe-Nutrients-Part-Functions">
            <div className="Button Button-Neutral Button-Small Button-WithBorder" onClick={onLockToggle}>
              <div>
                {(subpart.locked !== undefined && subpart.locked) && 
                  <FaLock className="Recipe-Nutrients-Part-Lock"/>
                }
                {(subpart.locked === undefined || !subpart.locked) && 
                  <FaUnlock className="Recipe-Nutrients-Part-Unlock"/>
                }
              </div>
            </div>
            <div className="Button Button-Critical Button-Small Button-WithBorder">
              <div>
                <FaTrashAlt className="Recipe-Nutrients-Part-Trash" onClick={deleteSubpart}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const openSubpartSelectionPopup =() => {
    SetSelectingSubpart(true)
  }
  const closeSubpartSelectionPopup = () =>  {
    SetSelectingSubpart(false)
  }

  const subpartSelectionCompleted = (selectedSubpart) =>  {
    console.log(selectedSubpart)

    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            nutrient_recipe: {
              parts: [...nutrientParts.map((part) => {
                if (part.index !== selectingSubpartForPart.index) {
                  return part
                }
                return {...part, sub_parts: [...part.sub_parts, {
                  index: part.sub_parts.length + 1,
                  percent_of: part.sub_parts.length === 0 ? 100 : 0,
                  item_type_id: selectedSubpart.item_type_id,
                }]}
               })]
              }
          }
        }
      })]
    }}))

    
    
    SetSelectingSubpartForPart(undefined)
    SetSelectingSubpart(false)
  }

  return (
    <>
      <div className="ControlBar_Horizontal">
        <div className="ControlBar_Horizontal-Left ControlBar_Horizontal-Overflow">
        </div>
       
        <div className="ControlBar_Horizontal-Right">
          <DropDownInput 
            uid="timeline_item_select" 
            prefix="Timeline Item: "
            options={timelineItemSelectionOptions} 
            value={(selectedTimelineItem !== undefined ? (selectedTimelineItem.id) : "")}
            onSelectionChange={(value) => {
              onTimelineItemSelectionChanged(value)
            }}/>
        </div>
      </div>
      <div id="Recipe-NutrientsManager">
        <div className="ControlBar_Horizontal">
          <div className="ControlBar_Horizontal-Left ControlBar_Horizontal-Overflow">
            <Button content={"Add Part"} onClick={createPart}/>
          </div>
        </div>
        <div id="Recipe-NutrientsManager-Content">
          <div id="Recipe-Nutrients-Parts">
            {nutrientParts.map((part) => {
              return (
              <PartItem
                key={part.index}
                part={part}/>
              )
            })}
          </div>
          <div id="Recipe-Nutrients-CompositionDisplay">
            
          </div>
        </div>
      </div>
    
      {selectingSubpart && 
        <SelectSubpartPopup closeCallback={closeSubpartSelectionPopup} selectionCallback={subpartSelectionCompleted}/>
      }
    </>
  )
} 

export default RecipeNutrientsPage