import './RecipesPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate, useMeasure, useMeasureWithRef } from '../../../helpers'
import {TabControl, TabControlTab} from '../../../components/TabControl.js';
import Button from '../../../components/Button.js';
import TextInput from '../../../components/input/TextInput';
import NumberInput from '../../../components/input/NumberInput';
import DropDownButton from '../../../components/DropDownButton.js';
import Checkbox from '@mui/material/Checkbox';
 
import { useParams, useNavigate, Route, Navigate, Routes} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { selectRecipeById, selectAllRecipes, recipeChanged, timelineItemChanged, pushRecipeChange } from '../../../redux/entities/Recipes'

import {BiGridVertical} from 'react-icons/bi'
import DropDownInput from '../../../components/input/DropDownInput';
import { selectAllRecipeSetpointTypes } from '../../../redux/AppInfo';

const RecipeRelationshipsPage = ({recipe, selectedTimelineItem, selectTimelineItem, timelineItemSelectionOptions}) => {
  const dispatch = useDispatch()
  const [, forceRerender] = React.useReducer(x => x + 1, 0);
  
  const [timelineItems, SetTimelineItems] = React.useState([]);
  React.useEffect(() => {
    if (recipe === undefined) 
      return

    if (recipe.timeline_items != null) {
      SetTimelineItems([...recipe.timeline_items])
    }
  }, [recipe])
  const timelineItemSelected = React.useCallback((timelineItem) => {
    if (selectedTimelineItem != timelineItem && selectTimelineItem !== undefined)  {
      selectTimelineItem(timelineItem)
    }
  })
  const onTimelineItemSelectionChanged = React.useCallback((value) => {
    let foundTimelineItem = timelineItems.find(c => value === c.id)
    if (foundTimelineItem !== undefined) {
      timelineItemSelected(foundTimelineItem)
    }
  })

  const recipeSetpointTypes = useSelector(selectAllRecipeSetpointTypes)
  const [availableRelationships, SetAvailableRelationships] = React.useState([
    {
      master_setpoint_types: [{name: "light_intensity", type: null, isDefault: true}, {name: "air_temp", type: null}, {name: "air_flow", type: null}], 
      slave_setpoint_type: {name: "air_co2", type: null}, 
      function: "remap_range"
    },
    {master_setpoint_types: [{name: "air_vpd", type: null}], slave_setpoint_type: {name: "light_intensity", type: null}, function: "remap_range"},
    /*{master_setpoint_types: [{name: "air_vpd", type: null}], slave_setpoint_type: {name: "spray_rate", type: null}, function: "remap_range"},*/
    {master_setpoint_types: [{name: "air_vpd", type: null}], slave_setpoint_type: {name: "air_flow", type: null}, function: "remap_range"},
    {master_setpoint_types: [{name: "air_temp", type: null}], slave_setpoint_type: {name: "water_temp", type: null}, function: "offset"},
  ])

  React.useEffect(() => {
    let changed = false
    for (let availableRelationship of availableRelationships) {
      for (let setpointType of [...availableRelationship.master_setpoint_types, availableRelationship.slave_setpoint_type]) {
        if (setpointType.type === null) {
          let foundSetpointType = recipeSetpointTypes.find((t) => t.name == setpointType.name)
          if (foundSetpointType !== undefined)  {
            setpointType.type = foundSetpointType
            changed = true
          }
        }
      }
    }
    if (changed)  {
      SetAvailableRelationships([...availableRelationships])
    }
  }, [recipeSetpointTypes])


  const activateRelationship = (masterSetpointType, slaveSetpointType, relationshipFunction, values) =>  {
    //Check for setpoint already activated
    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            relationships: [...selectedTimelineItem.item.relationships, {
              master_type_id: masterSetpointType.id,
              slave_type_id: slaveSetpointType.id,
              function: relationshipFunction,
              values: values
            }],
            setpoints: [...selectedTimelineItem.item.setpoints.filter((setpoint) => {
              if (setpoint.type_id === slaveSetpointType.id)  {
                return false
              }
              return true
            })],
            lighting_intensity_setpoints: [...selectedTimelineItem.item.lighting_intensity_setpoints.filter((setpoint) => {
              if (slaveSetpointType.name === "light_intensity")  {
                return false
              }
              return true
            })]
          }
        }
      })]
    }}))
  }

  const deactivateRelationship = (slaveSetpointType) =>  {
    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            relationships: selectedTimelineItem.item.relationships.filter((relationship) => {
              if (relationship.slave_type_id !== slaveSetpointType.id)  {
                return true
              }
              return false
            })
          }
        }
      })]
    }}))
  }

  const modifyRelationship  = (masterSetpointType, slaveSetpointType, relationshipFunction, values) =>  {
    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            relationships: selectedTimelineItem.item.relationships.map((relationship) => {
              if (relationship.slave_type_id !== slaveSetpointType.id)  {
                return relationship
              }
              return {
                master_type_id: masterSetpointType.id,
                slave_type_id: slaveSetpointType.id,
                function: relationshipFunction,
                values: values
              }
            })
          }
        }
      })]
    }}))
  }

  return (
    <>
      <div className="ControlBar_Horizontal">
        <div className="ControlBar_Horizontal-Left ControlBar_Horizontal-Overflow">
        </div>
       
        <div className="ControlBar_Horizontal-Right">
          <DropDownInput 
            uid="timeline_item_select" 
            prefix="Timeline Item: "
            options={timelineItemSelectionOptions} 
            value={(selectedTimelineItem !== undefined ? (selectedTimelineItem.id) : "")}
            onSelectionChange={(value) => {
              onTimelineItemSelectionChanged(value)
            }}/>
        </div>
      </div>
      <div id="Recipe-RelationshipsManager">
        <div id="Recipe-RelationshipsManager-Content">

          <table className="Recipe-RelationshipsManager-Relationships">
            <tbody>
              {availableRelationships.map((relationshipFormat, relationshipIndex) => {

                const isMultiMaster = relationshipFormat.master_setpoint_types.length > 1

                if (relationshipFormat.slave_setpoint_type.type === null)  {
                  return null
                }
                if (!isMultiMaster && relationshipFormat.master_setpoint_types[0].type === null) {
                  return null
                }else if (isMultiMaster)  {
                  let foundMaster = false
                  for (let masterSetpointType of relationshipFormat.master_setpoint_types) {
                    if (masterSetpointType.type)  {
                      foundMaster = true
                    }
                  }
                  if (!foundMaster) {
                    return null
                  }
                }

                //See if the relationship is active
                let activeRelationship = null
                if (selectedTimelineItem) {
                  for (let relationship of selectedTimelineItem.item.relationships) {
                    if (relationship.slave_type_id === relationshipFormat.slave_setpoint_type.type.id)  {
                      activeRelationship = relationship
                    }
                  }
                }

                


                const masterClicked = (e) =>  {
                  if (activeRelationship === null)  {
                    let values = []
                    if (relationshipFormat.function === "remap_range")  {
                      values = [relationshipFormat.master_setpoint_types[0].type.min, relationshipFormat.master_setpoint_types[0].type.max, relationshipFormat.slave_setpoint_type.type.min, relationshipFormat.slave_setpoint_type.type.max]
                    }else if (relationshipFormat.function === "offset")  {
                      values = [0]
                    }
                    activateRelationship(relationshipFormat.master_setpoint_types[0].type, relationshipFormat.slave_setpoint_type.type, relationshipFormat.function, values)
                  }else {
                    deactivateRelationship(relationshipFormat.slave_setpoint_type.type)
                  }
                }

                const masterChanged = (masterType) =>  {
                  let values = [...activeRelationship.values]
                  values[0] = masterType.min
                  values[1] = masterType.max
                  modifyRelationship(masterType, relationshipFormat.slave_setpoint_type.type, relationshipFormat.function, values)
                }

                const valueChanged = (index, newValue) =>  {
                  let values = [...activeRelationship.values]
                  values[index] = newValue
                  let foundMasterSetpointType = recipeSetpointTypes.find((t) => t.id == activeRelationship.master_type_id)
                  modifyRelationship(foundMasterSetpointType, relationshipFormat.slave_setpoint_type.type, relationshipFormat.function, values)
                }

                return (
                  <tr className="Recipe-RelationshipsManager-Relationship" key={relationshipIndex}>
                    {isMultiMaster && 
                      <td className="Recipe-RelationshipsManager-Relationship-MasterOptions" onClick={masterClicked}>
                        {(() => {
                          //Calculate options here
                          let masterOptions = []
                          let selectedMaster = null
                          for (let masterSetpointType of relationshipFormat.master_setpoint_types) {
                            if (masterSetpointType.type)  {
                              masterOptions.push({value: masterSetpointType.type.id, label: masterSetpointType.type.display_name})
                              if (activeRelationship === null && masterSetpointType.isDefault) {
                                selectedMaster = masterSetpointType.type
                              }else if (activeRelationship !== null && activeRelationship.master_type_id === masterSetpointType.type.id)  {
                                selectedMaster = masterSetpointType.type
                              }
                            }
                          }
                          return (
                            <div>
                              <Checkbox
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                checked={activeRelationship !== null}/>
                              <DropDownInput 
                                onContainerClicked={((e) => {e.stopPropagation()})}
                                uid="master_option_select"
                                options={masterOptions} 
                                value={(selectedMaster ? selectedMaster.id : "")}
                                onSelectionChange={(value) => {
                                  for (let masterSetpointType of relationshipFormat.master_setpoint_types) {
                                    if (masterSetpointType.type && masterSetpointType.type.id === value)  {
                                      masterChanged(masterSetpointType.type)
                                    }
                                  }
                                }}/>
                            </div>
                          )
                        })()}
                      </td>
                    }
                    {!isMultiMaster &&
                      <td className="Recipe-RelationshipsManager-Relationship-Master" onClick={masterClicked}>
                        <div>
                          <Checkbox
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                            checked={activeRelationship !== null}/>
                          {relationshipFormat.master_setpoint_types[0].type.display_name}
                        </div>
                      </td>
                    }
                    <td className="Recipe-RelationshipsManager-Relationship-Inputs">
                      <div>
                        {relationshipFormat.function === "remap_range" && 
                          <div className="Recipe-RelationshipsManager-Relationship-Inputs-Range">
                            <div className="Recipe-RelationshipsManager-Relationship-Inputs-Range-Master">
                              <div className="Recipe-RelationshipsManager-Relationship-Inputs-Range-Type">Max</div>
                                {(() => {
                                  let suffix = ""
                                  let min = 0
                                  let max = 0
                                  let stepAmount = 1
                                  if (isMultiMaster)  {
                                    if (activeRelationship !== null)  {
                                      let foundSetpointType = recipeSetpointTypes.find((t) => t.id == activeRelationship.master_type_id)
                                      if (foundSetpointType !== undefined)  {
                                        suffix = foundSetpointType.suffix
                                        stepAmount = foundSetpointType.resolution
                                      }
                                      
                                      min = parseFloat(activeRelationship.values[0])
                                      max = parseFloat(activeRelationship.values[1])
                                    }                                    
                                  }else {
                                    suffix = relationshipFormat.master_setpoint_types[0].type.suffix
                                    stepAmount = relationshipFormat.master_setpoint_types[0].type.resolution
                                    if (activeRelationship === null)  {
                                      min = relationshipFormat.master_setpoint_types[0].type.min
                                      max = relationshipFormat.master_setpoint_types[0].type.max
                                  }else {
                                      min = parseFloat(activeRelationship.values[0])
                                      max = parseFloat(activeRelationship.values[1])
                                    }
                                  }
                                  return (<>
                                    <NumberInput 
                                      value={max} 
                                      suffix={suffix} 
                                      stepAmount={stepAmount}
                                      disabled={activeRelationship === null}
                                      onBlur={(value) => {
                                        valueChanged(1, value)
                                      }}/>
                                    <NumberInput 
                                      value={min} 
                                      suffix={suffix} 
                                      stepAmount={stepAmount}
                                      disabled={activeRelationship === null}
                                      onBlur={(value) => {
                                        valueChanged(0, value)
                                      }}/>
                                  </>)
                                })()}
                                
                                <div className="Recipe-RelationshipsManager-Relationship-Inputs-Range-Type">Min</div>
                            </div>
                            <span>
                              :
                            </span>
                            <div className="Recipe-RelationshipsManager-Relationship-Inputs-Range-Slave">
                              <div className="Recipe-RelationshipsManager-Relationship-Inputs-Range-Type">Max</div>
                              {(() => {
                                let min = 0
                                let max = 0
                                if (activeRelationship === null)  {
                                  min = relationshipFormat.slave_setpoint_type.type.min
                                  max = relationshipFormat.slave_setpoint_type.type.max
                                }else {
                                  min = parseFloat(activeRelationship.values[2])
                                  max = parseFloat(activeRelationship.values[3])
                                }
                                return (<>
                                  <NumberInput 
                                    value={max} 
                                    suffix={relationshipFormat.slave_setpoint_type.type.suffix} 
                                    stepAmount={relationshipFormat.slave_setpoint_type.type.resolution}
                                    disabled={activeRelationship === null}
                                    onBlur={(value) => {
                                      valueChanged(3, value)
                                    }}/>
                                  <NumberInput 
                                    value={min} 
                                    suffix={relationshipFormat.slave_setpoint_type.type.suffix} 
                                    stepAmount={relationshipFormat.slave_setpoint_type.type.resolution}
                                    disabled={activeRelationship === null}
                                    onBlur={(value) => {
                                      valueChanged(2, value)
                                    }}/>
                                </>)
                              })()}
                              <div className="Recipe-RelationshipsManager-Relationship-Inputs-Range-Type">Min</div>
                            </div>
                          </div>
                        }
                        {relationshipFormat.function === "offset" && 
                          <div className="Recipe-RelationshipsManager-Relationship-Inputs-Offset">
                            <NumberInput 
                              value={0} 
                              suffix={relationshipFormat.slave_setpoint_type.type.suffix} 
                              stepAmount={relationshipFormat.slave_setpoint_type.type.resolution}
                              disabled={activeRelationship === null}
                              onBlur={(value) => {
                                valueChanged(0, value)
                              }}/>
                          </div>
                        }
                      </div>
                    </td>
                    <td className="Recipe-RelationshipsManager-Relationship-Slave">
                      <div>
                        {relationshipFormat.slave_setpoint_type.type.display_name}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
} 

export default RecipeRelationshipsPage